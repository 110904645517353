import * as api from '@/store/api/index'
import * as endpoints from '@/store/api/endpoints'
import * as types from "@/store/mutation-types";

const actions = {
  createTemplate({commit}, payload) {
    return api.post(endpoints.IMPORT_CONFIG_TEMPLATE, payload, commit, (response) => {
      commit(types.SET_IMPORT_TEMPLATE, response.data);
    });
  },
  getTemplates({commit}, payload) {
    return api.fetch(endpoints.IMPORT_CONFIG_TEMPLATES, payload, commit,(response) => {
      commit(types.SET_IMPORT_TEMPLATES, response.data.data);
    });
  },
  getTemplate({commit}, payload) {
    return api.fetch(endpoints.IMPORT_CONFIG_TEMPLATES+'/'+payload.uuid, payload, commit,(response) => {
      commit(types.SET_IMPORT_TEMPLATE, response.data);
    });
  },
  getRequiredAttributes({commit}, payload) {
    return api.fetch(endpoints.IMPORT_CONFIG_REQUIRED_ATTRIBUTES, payload, commit,(response) => {
      commit(types.SET_REQUIRED_ATTRIBUTES, response.data);
    });
  },
  updateTemplate({commit}, payload) {
    return api.put(endpoints.IMPORT_CONFIG_TEMPLATES+'/'+payload.uuid, payload, commit, (response) => {
      commit(types.SET_IMPORT_TEMPLATE, response.data);
    });
  },
  deleteTemplate({commit}, payload) {
    return api.remove(endpoints.IMPORT_CONFIG_TEMPLATES+'/'+payload.uuid, payload, commit, (response) => {
      commit(types.SET_IMPORT_TEMPLATE, response.data);
    });
  },

   //import type settings
   getImportConfigurations({commit}, payload) {
    return api.fetch(endpoints.IMPORT_CONFIG_CONFIGURATIONS, payload, commit,(response) => {
      commit(types.SET_IMPORT_CONFIGURATIONS, response.data);
    });
  },
  getImportSourceCredentials({commit}, payload) {
    return api.fetch(endpoints.IMPORT_CONFIG_SOURCE_CREDENTIALS, payload, commit,(response) => {
      commit(types.SET_IMPORT_SOURCE_CREDENTIALS, response.data);
    });
  },
  updateImportConfiguration({commit}, payload) {
    return api.put(endpoints.IMPORT_CONFIG_CONFIGURATIONS, payload, commit, (response) => {
      commit(types.UPDATE_IMPORT_CONFIGURATIONS, response.data);
    });
  },

  //import data validation
  getImportValidatedData({commit}, payload) {
    return api.fetch(endpoints.IMPORT_CONFIG_VALIDATED_DATA, payload, commit,(response) => {
      commit(types.SET_IMPORT_IMPORT_VALIDATED_DATA, response.data);
    });
  },
  getImportValidatedDataSettings({commit}, payload) {
    return api.fetch(endpoints.IMPORT_CONFIG_VALIDATED_DATA_SETTINGS, payload, commit,(response) => {
      commit(types.SET_IMPORT_VALIDATED_DATA_SETTINGS , response.data);
    });
  },
  setImportValidatedDataSettings({commit}, payload) {
    return api.put(endpoints.IMPORT_CONFIG_VALIDATED_DATA_SETTINGS, payload, commit,(response) => {
      commit(types.SET_IMPORT_VALIDATED_DATA_SETTINGS , response.data);
    });
  },
  updateImportValidatedData({commit}, payload) {
    return api.put(endpoints.IMPORT_CONFIG_VALIDATED_DATA, payload, commit, (response) => {
      commit(types.UPDATE_IMPORT_VALIDATED_DATA, response.data);
    });
  },
  transferValidatedDataToLiveData({commit}, payload) {
    return api.post(endpoints.IMPORT_CONFIG_VALIDATED_DATA, payload, commit, (response) => {
    });
  },
  deleteValidatedDataFromLiveData({commit}, payload) {
    return api.remove(endpoints.IMPORT_CONFIG_VALIDATED_DATA_LIVE_DATA, payload, commit, (response) => {
    });
  }
}

export default actions;
