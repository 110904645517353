<!-- template for the modal component -->
<template type="x/template">
  <transition name="modal" id="contact-info-dialog">
    <div class="modal-mask" v-show="show">
      <div class="modal-container" @click.stop>
        <div class="modal-header">
          <h3>{{ title }}</h3>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="form-group col-sm-12 col-lg-8">
              <label class="control-label">{{ $t('user.first_name') }}</label>
              <input type="text" v-model="modalData.first_name"
                     :class="{ 'field-error' : this.$v.modalData.first_name.$error }"
                     :placeholder="$t('user.first_name')"
                     class="form-control"
                     autocomplete="off"/>
            </div>
          </div>
          <div class="row">
            <div class="form-group col-sm-12 col-lg-8">
              <label class="control-label">{{ $t('user.last_name') }}</label>
              <input type="text" v-model="modalData.last_name"
                     :class="{ 'field-error' : this.$v.modalData.last_name.$error }"
                     :placeholder="$t('user.last_name')"
                     class="form-control"
                    autocomplete="off"/>
            </div>
          </div>
          <div class="row">
            <div class="form-group col-sm-12 col-lg-8">
              <label class="control-label">{{ $t('user.email') }}</label>
              <input type="text" v-model="modalData.email"
                     :class="{ 'field-error' : this.$v.modalData.email.$error }"
                     :placeholder="$t('user.email')"
                     class="form-control"
                     autocomplete="off"/>
            </div>
          </div>
          <div class="row">
            <div class="form-group col-sm-12 col-lg-8">
              <label class="control-label">{{ $t('user.phone') }}</label>
              <input type="text" v-model="modalData.phone"
                     :class="{ 'field-error' : this.$v.modalData.phone.$error }"
                     :placeholder="$t('user.phone')"
                     class="form-control"
                     autocomplete="off"/>
            </div>
          </div>
        </div>
        <div class="modal-footer col-lg-11">
          <div class="form-group row justify-content-center">
            <div class="col-lg-4">
              <button class="btn btn-success" @click="save()">{{ $t('common.save') }}</button>
            </div>
            <div class="col-lg-4">
              <button class="modal-default-button btn btn-secondary ml-3" @click="close()">{{ $t('common.cancel') }}</button>
            </div>
            <div class="col-lg-4" v-if="modalData.uuid">
              <button class="modal-default-button btn btn-danger ml-3" @click="deleteItem()">{{ $t('common.delete') }}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
    import { required } from 'vuelidate/lib/validators'

    export default {
        name: 'ContactInfoDialog',
        props: ['show', 'data'],
        data: function () {
            return {
                modalData: this.data,
            };
        },
        computed: {
            title() {
                return this.modalData.uuid ? this.$t('edit_contact_person') : this.$t('new_contact_person');
            }
        },
        methods: {
            close: function () {
                this.$emit('close');
            },
            save () {
                this.$v.modalData.$touch();
                if(!this.$v.modalData.$invalid) {
                    if(!this.modalData.uuid) {
                      this.modalData.uuid = Math.random();
                      this.modalData.new = true;
                    } else {
                        this.modalData.new = false;
                    }

                    this.$emit('onSave',this.modalData);
                    this.close();
                }
            },
            deleteItem() {
                this.$emit('onDelete', this.modalData);
                this.close();
            }
        },
        watch: {
            data: function(newVal) {
                this.modalData = newVal;
            }
        },
        validations() {
            return {
                modalData: {
                    first_name: {
                        required,
                    },
                    last_name: {
                        required,
                    },
                    email: {
                        required,
                    },
                    phone: {
                        required,
                    },
                }
            }
        },
    }
</script>

<style scoped>
  * {
    box-sizing: border-box;
  }
</style>
