<!-- template for the modal component -->
<template type="x/template">
  <transition name="modal" id="contact-info-dialog">
    <div class="modal-mask" v-show="show">
      <div class="modal-container" @click.stop>
        <div class="modal-header">
          <h3>{{ title }}</h3>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="form-group col-sm-12 col-lg-6 text-left">
              <label class="control-label">{{$t("new_password")}}</label>
              <input type="password" v-model="modalData.password"
                     :class="{ 'field-error' : this.$v.modalData.password.$error }"
                     :placeholder='$t("new_password")'
                     class="form-control"/>
            </div>
            <div class="form-group col-sm-12 col-lg-6 text-left">
              <label class="control-label">{{$t("confirm_password")}}</label>
              <input type="password" v-model="modalData.confirm_password"
                     :class="{ 'field-error' : this.$v.modalData.confirm_password.$error }"
                     :placeholder='$t("confirm_password")'
                     class="form-control"/>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-12 text-danger">
              {{ error }}
            </div>
          </div>
        </div>
        <div class="modal-footer col-lg-12">
          <div class="form-group row justify-content-end">
            <div class="col-lg-6">
              <button class="btn btn-outline-success" @click="OK()">{{$t("common.change")}}</button>
            </div>
            <div class="col-lg-6">
              <button class="modal-default-button btn btn-outline-secondary ml-3" @click="close()">{{$t("common.close")}}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
    import { required } from 'vuelidate/lib/validators'

    export default {
        name: 'ChangePasswordDialog',
        props: ['show', 'data'],
        data: function () {
            return {
                modalData: this.data,
                error: '',
            };
        },
        computed: {
            title() {
                return this.$t('change_password') +' "'+this.modalData.name + '"';
            }
        },
        methods: {
            close: function () {
                this.$emit('close');
            },
            OK () {
                this.$v.modalData.$touch();

                if(!this.$v.modalData.$invalid) {
                    if(this.modalData.password === this.modalData.confirm_password) {
                        this.$emit('onPasswordChanged',this.modalData);
                        this.close();
                    } else {
                        this.setErrorMessage(this.$t('passwords_not_match'));
                    }
                } else {
                    this.setErrorMessage(this.$t('fill_out_error'));
                }
            },
            setErrorMessage(msg) {
                this.error = msg;
            }
        },
        watch: {
            data: function(newVal) {
                this.modalData = newVal;
            },
            show: function(newVal) {
                this.setErrorMessage("");
            }
        },
        validations() {
            return {
                modalData: {
                    password: {
                        required,
                    },
                    confirm_password: {
                        required,
                    },
                },
            }
        },
    }
</script>

<style scoped>
  * {
    box-sizing: border-box;
  }
</style>
