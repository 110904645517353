import * as types from "../../mutation-types";

const mutations = {
  [types.SET_WORK_MACHINES]: (state, payload) => {
    state.work_machines = payload;
  },
  [types.SET_WORK_MACHINE]: (state, payload) => {
    state.work_machine = payload;
  },
  [types.SET_WORK_MACHINE_ITEM]: (state, payload) => {
    state.work_machines.splice(
      state.work_machines.indexOf(
        state.work_machines.find(p => p.uuid === payload.uuid)
      ), 1, payload
    );
  },
  [types.SET_WORK_MACHINE_FILTERS]: (state, payload) => {
    state.work_machine_filters = payload;
  },
}

export default mutations;
