import { render, staticRenderFns } from "./ParcelFilters.vue?vue&type=template&id=035b972b&scoped=true"
import script from "./ParcelFilters.vue?vue&type=script&lang=js"
export * from "./ParcelFilters.vue?vue&type=script&lang=js"
import style0 from "./ParcelFilters.vue?vue&type=style&index=0&id=035b972b&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "035b972b",
  null
  
)

export default component.exports