<template>
  <Link :loading="loading" :title="hasShared ? $t('show') : $t('share')" :onClick="onClick" :className="{
    'text-success': hasShared,
    'text-primary': !hasShared
  }">
    <p class="sub" v-if="hasShared">
      <span>{{ item.shared_work_machines.length }} {{ $t('company.departments.name') }}</span>
    </p>
    <shared-work-machines-dialog :show="showSharedWorkMachinesModal"
                                  :data="sharedWorkMachinesData"
                                  @onOK="onDepSelectionApplied"
                                 @close="onClose"
                                 ref="dialog"
    ></shared-work-machines-dialog>
  </Link>
</template>

<script>
import SharedWorkMachinesDialog from "~/pages/works/work-machines/partials/SharedWorkMachinesDialog.vue";
import {errorMessage, errorMessageList, successMessage} from "~/helpers/common";

export default {
  name: 'ShareWorkMachinesTemplate',
  props: ['data'],
  data() {
    return {
      item: this.data ?? {},
      showSharedWorkMachinesModal: false,
      sharedWorkMachinesData: {},
      loading: false,
    }
  },
  components: {
    SharedWorkMachinesDialog,
  },
  computed: {
    title() {
      return '';
    },
    hasShared() {
      return this.data.shared_work_machines && this.data.shared_work_machines.length > 0;
    }
  },
  methods: {
    onClick() {
      let vm = this;
      vm.sharedWorkMachinesData = vm.data;
      vm.showSharedWorkMachinesModal = true;
    },
    onClose() {
        this.showSharedWorkMachinesModal = false;
    },
    onDepSelectionApplied(selectedDeps) {
      let vm = this;
      this.$store.dispatch('works-work-machines/shareWorkMachine', {
        uuid: this.data.uuid,
        department_uuid: Object.assign([], selectedDeps).map((item) => {
          return item.uuid;
        }),
      }).then((resp) => {
          vm.item.shared_work_machines = selectedDeps;
          successMessage(vm, resp.message);
      }).catch((e) => {
        if (e.response.status === 400) {
          errorMessage(vm, e.response.data.errors.error[0]);
        } else {
          errorMessageList(vm, e.errors);
        }
      })
    }
  },
  watch: {
    data: {
      handler: function(newVal) {
          this.item = newVal;
      },
      deep: true,
    },
  }
}
</script>

<style scoped>
.sub {
  font-size: 12px;
}
</style>
