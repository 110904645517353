<template>
  <card :title="$t('roles_permissions')" id="roles-permissions" v-if="canEdit || canDelete" v-cloak>
    <div class="row">
      <div class="col-lg-6">
        <div class="row">
          <div class="col-lg-12">
            <h5>{{$t("roles")}}</h5>
            <hr/>
          </div>
        </div>
        <div class="row">
          <div class="form-group col-sm-9 col-lg-9">
            <label class="control-label">{{$t("role_templates")}}</label>
            <b-form-select v-model="template_uuid" class="form-control"
                           :options="templates" @change="onTemplateChanged"></b-form-select>
          </div>
          <div class="col-lg-3 text-center mt-3 pt-3">
            <button class="btn btn-outline-secondary" @click="newRole" v-if="canEdit">{{$t("new_role")}}</button>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12">
            <role-list :data="role_list" :permissions="permissions" ref="roleList"/>
          </div>
        </div>
      </div>
      <div class="col-lg-6" v-if="false">
        <div class="row">
          <div class="form-group col-sm-12 col-lg-12">
            <h5>Available company permissions to assign</h5>
            <hr/>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12">
            <permission-list :data="permission_list" @onItemRemoved="onPermissionRemoved" mode="edit"/>
          </div>
        </div>
      </div>
    </div>
  </card>
  <div v-else>
    <forbidden></forbidden>
  </div>
</template>

<script>
  import {buildDropdownOptions} from "@/helpers/common";
  import RoleList from "./role/role-list";
  import PermissionList from "./permission/permission-list";
  import { mapGetters, mapActions } from 'vuex'
  import {
    hasPermission,
    permissions,
    actions,
    hasModuleAccess,
    permissionTypes,
    canEdit,
    canDelete
  } from "@/helpers/Permissions";
  import Forbidden from "~/pages/errors/Forbidden.vue";

  export default {
      name: 'RolesPermissions',
      middleware: ['auth', 'check-company-admin'],
      data() {
          return {
              templateList: [],
              template_uuid: '',
              role_list: this.roles ? this.roles : [],
              permission_list: this.permissions ? this.permissions : [],
          }
      },
      components: {
        Forbidden,
          RoleList,
          PermissionList,
      },
      computed: {
          ...mapGetters({
              'roles': 'roles/roles',
              'permissions': 'roles/permissions',
              user: 'auth/user'
          }),
          templates() {
              return buildDropdownOptions(this.templateList, {
                  value: 'uuid',
                  text: 'name',
              }, {
                  value: '',
                  text: '-',
              });
          },
        config() {
          return {
            permission: permissions.ROLES_PERMISSIONS,
            permission_type: permissionTypes.module,
          }
        },
        canEdit() {
          return canEdit(this);
        },
        canDelete() {
          return canDelete(this);
        },
      },
      methods: {
          ...mapActions({
              getRoles: 'roles/getRoles',
              getPermissions: 'roles/getDefaultPermissions',
          }),
          onTemplateChanged(val) {

          },
          onPermissionRemoved(item) {
              if(this.permission_list) {
                  this.permission_list.splice(this.permission_list.indexOf(
                      this.permission_list.find(p => p.uuid === item.uuid)
                  ), 1);
              }
          },
          newRole() {
              let data = {};

              if(this.template_uuid) {
                data = Object.assign({}, this.templateList.find(t => t.uuid === this.template_uuid));
              }

              data.name = '';
              data.uuid = '';

              this.$refs.roleList.showEditDialog(data);
          },
      },
      mounted() {
          this.getRoles();
          this.getPermissions();
      },
      watch: {
          roles: {
              handler: function(newVal) {
                  this.role_list = newVal;
                  this.templateList = this.role_list;
              },
              deep: true,
          },
          permissions: {
              handler: function(newVal) {
                  this.permission_list = Object.assign([], newVal);
              },
              deep: true,
          }
      }
  }
</script>
