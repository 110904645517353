import * as types from "../../mutation-types";

const mutations = {
  [types.SET_CULTURES]: (state, payload) => {
    state.cultures = payload;
  },
  [types.SET_CULTURE]: (state, payload) => {
    state.culture = payload;
  },
  [types.ADD_CULTURE_ITEM]: (state, payload) => {
    state.cultures.push(payload);
  },
  [types.UPDATE_CULTURE_ITEM]: (state, payload) => {
    state.cultures.splice(state.cultures.indexOf(
      state.cultures.find(c => c.uuid === payload.uuid)
    ), 1, payload);
  },
  [types.DELETE_CULTURE_ITEM]: (state, payload) => {
    state.cultures.splice(state.cultures.indexOf(
      state.cultures.find(c => c.uuid === payload.uuid)
    ), 1);
  },
  [types.SET_CULTURE_FILTERS]: (state, payload) => {
    state.culture_filters = payload;
  },
  //varieties
  [types.SET_VARIETIES]: (state, payload) => {
    state.varieties = payload;
  },
  [types.SET_VARIETY]: (state, payload) => {
    state.variety = payload;
  },
  //bbch
  [types.SET_BBCHS]: (state, payload) => {
    state.bbchs = payload;
  },
  [types.SET_BBCH]: (state, payload) => {
    state.bbch = payload;
  },
  [types.ADD_BBCH_ITEM]: (state, payload) => {
    state.bbchs.push(payload);
  },
  [types.UPDATE_BBCH_ITEM]: (state, payload) => {
    state.bbchs.splice(state.bbchs.indexOf(
        state.bbchs.find(c => c.uuid === payload.uuid)
    ), 1, payload);
  },
  [types.DELETE_BBCH_ITEM]: (state, payload) => {
    state.bbchs.splice(state.bbchs.indexOf(
        state.bbchs.find(c => c.uuid === payload.uuid)
    ), 1);
  },
}

export default mutations;
