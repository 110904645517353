const state = () => {
  return {
    servers: [],
    server_filters: {
      status: '',
      type: '',
      page: 1,
    },
  }
}

export default state;
