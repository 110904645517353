<template>
  <div class="filter-actions col-lg-12 filters p-3">
    <div class="form-group row align-items-center">

      <div class="col-lg-3">
        <label class="control-label d-block">{{$t("common.date_from")}}</label>
        <date-picker2
          v-model="filters.creation_date_from"
          :value-type="creation_date_from_input"
          format="YYYY-MM-DD"
          type="date"
          placeholder="yyyy-mm-dd"
          :input-class="['date-picker-style']"
          ref="date_from"
        ></date-picker2>
      </div>

      <div class="col-lg-3">
        <label class="control-label d-block">{{$t("common.date_to")}}</label>
        <date-picker2
          v-model="filters.creation_date_to"
          :value-type="creation_date_to_input"
          format="YYYY-MM-DD"
          type="date"
          placeholder="yyyy-mm-dd"
          :input-class="['date-picker-style']"
          ref="date_from"
        ></date-picker2>
      </div>
    </div>

    <div class="form-group row">
      <div class="col-lg-3">
        <label class="control-label">{{$t('scouting.diseases')}}</label>
        <input v-model="filters.diseases" class="form-control" :placeholder="$t('name')"/>
      </div>

      <div class="col-lg-3" >
        <label class="control-label">{{$t('scouting.engineer_name')}}</label>
        <input v-model="filters.engineer_name" class="form-control" :placeholder="$t('scouting.engineer_name')"/>
      </div>

      <div class="col-lg-3">
        <label class="control-label">{{$t('scouting.parcel_name')}}</label>
        <input v-model="filters.parcel_name" class="form-control" :placeholder="$t('name')"/>
      </div>

      <div class="col-lg-3">
        <label class="control-label">{{$t('scouting.bbch')}}</label>
        <input v-model="filters.bbch" class="form-control" :placeholder="$t('scouting.bbch')"/>
      </div>
    </div>

    <div class="form-group row">
      <div class="col-lg-3">
        <label class="control-label">{{$t('scouting.infection_level')}}</label>
        <b-form-select
          v-model="filters.infection_level"
          class="form-control"
          :options="infection_level_list"></b-form-select>
      </div>

      <div class="col-lg-3">
        <label class="control-label">{{$t('scouting.notes')}}</label>
        <input v-model="filters.notes" class="form-control" :placeholder="$t('scouting.notes')"/>
      </div>

      <div class="col-lg-2 mt-4">
        <button class="btn-sm btn-primary col-lg-12" @click="applyFilters">{{$t("common.show")}}</button>
      </div>
      <div class="col-lg-3 mt-4">
        <button class="btn-sm btn-secondary col-lg-12" @click="resetFilters">{{$t("common.reset_filters")}}</button>
      </div>
    </div>
  </div>
</template>
<script>
import {mapActions, mapGetters} from "vuex";
import DatePicker2 from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import {today} from "@/helpers/dateHelper";
import {convertDropdown} from "~/helpers/common";
import {buildDefaultDropdownList} from "~/helpers/common";

export default {
  name: 'ScoutingFilters',
  data() {
    return {
      filters:  this.scoutingFilters ? this.scoutingFilters : {},
      creation_date_from: null,
      creation_date_from_input: null,
      creation_date_to: null,
      creation_date_to_input: null,
    }
  },
  computed: {
    ...mapGetters({
      scoutingFilters: 'scouting/scoutingFilters',
    }),
    infection_level_list() {
      let list = {
        1:{'uuid':1, 'name':'Low'},
        2:{'uuid':2, 'name':'Medium'},
        3:{'uuid':3, 'name':'High'},
      };
      return buildDefaultDropdownList(list);
    },
  },
  methods: {
    ...mapActions({
      getData: 'scouting/getFilteredScoutings',
    }),
    resetFilters() {
      this.filters = {
        creation_date_from: '',
        creation_date_to: '',
        engineer_name: '',
        bbch: '',
        parcel: '',
        diseases: '',
        notes: '',
        infection_level: '',
      }

      this.creation_date_from = null;
      this.creation_date_to = null;
    },
    applyFilters() {
      this.$emit('onFiltersApplied', this.filters);
      this.getData(this.filters);
    },
  },
  components: {
    DatePicker2,
  },
  mounted(){
    this.resetFilters();
  },
  watch: {
    scoutingFilters: {
      handler: function(newVal) {
        this.filters = newVal;
      },
      deep: true,
    }
  }
}
</script>
