<template>
  <div class="departments" v-if="canEdit || canDelete" v-cloak>
    <card :title="$t('company.departments.name')" class="agx-card" id="departments-manager">
      <agro-filter-container :new-object="newDepartment" :title='$t("company.departments.create_new_department")'>
        <department-filters
            :limit="limit"
            ref="filters"
            @onFiltersApplied="onFiltersApplied"
        />
      </agro-filter-container>
    </card>
    <p class="text-primary pt-4">{{ $t('common.total_results', { count: departments.length ?? 0 }) }}</p>
    <department-list @onDeletePressed="onDeletePressed" :data="dataSource"></department-list>
  </div>
  <div v-else>
    <forbidden></forbidden>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import DepartmentFilters from "./partials/DepartmentFilters.vue";
import DepartmentList from "./partials/DepartmentList.vue";
import {canDelete, canEdit, permissions, permissionTypes} from "~/helpers/Permissions";
import * as actions from "~/helpers/generalActions";
import Forbidden from "~/pages/errors/Forbidden.vue";
import AgroFilterContainer from "@/components/AgroFilter/partials/AgroFilterContainer.vue";

export default {
  name: "departments",
  data() {
    return {
      limit: 25,
      currentPage: 1,
      loading: false,
      showFilters: false,
      dataSource: this.departments ?? [],
    };
  },
  computed: {
    ...mapGetters({
        currentDepartment: "auth/currentDepartment",
        departments: "department/departmentList",
    }),
    config() {
      return {
        permission: permissions.DEPARTMENTS,
        permission_type: permissionTypes.module,
      }
    },
    canEdit() {
      return canEdit(this);
    },
    canDelete() {
      return canDelete(this);
    },
  },
  components: {
    AgroFilterContainer,
    Forbidden,
    DepartmentList,
    DepartmentFilters,
  },
  methods: {
    ...mapActions({
        deleteDepartment: "department/deleteDepartment"
    }),
    onContentReady() {

    },
    onReorder(obj) {
       console.log('onReorder', obj);
    },
    onValueChanged(obj) {
        console.log('onValueChanged', obj);
    },
    onFiltersApplied(filters) {
        this.$set(this, 'currentPage', filters.page);
        this.$set(this, 'loading', true);
    },
    newDepartment() {
      this.$router.push({
        path: '/my-company/departments/add',
      });
    },
    onDeletePressed(data) {
      let vm = this;

      this.$dialog.confirm('Do you really want to delete this "'+data.name+'" unit?', {})
        .then((dialog) => {
          vm.deleteDepartment({
            uuid: data.uuid,
          }).then((response) => {
            dialog.close();
            vm.$bvToast.toast(response.message, {
              title: 'Success',
              variant: 'success',
              solid: true
            });

            //remove deleted from list
            vm.dataSource.splice(vm.dataSource.indexOf(
              vm.dataSource.find(c => c.uuid === response.data.uuid)
            ), 1);

          }).error((response) => {
            dialog.close();
            vm.$bvToast.toast(response.message, {
              title: 'Error',
              variant: 'error',
              solid: true
            });
          });
        });
    }
  },
  mounted() {
      //this.$refs['filters'].applyFiltersClick();
  },
    watch: {
      departments: {
          handler: function(newVal) {
              this.dataSource = newVal;
          },
          deep: true,
      }
    }
};
</script>

<style scoped>
.department-grid-row {
  width: 100%;
  background-color: white;
  display: flex;
  flex-wrap: wrap;
  overflow: auto;
}

.row-info-box {
  float: left;
  margin: 10px;
  font-size: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
