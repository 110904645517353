import * as types from "../../mutation-types";

const mutations = {
  [types.SET_SC_BONUSES]: (state, payload) => {
    state.sc_bonuses = payload;
  },
  [types.SET_SC_BONUS_TYPES]: (state, payload) => {
    state.sc_bonus_types = payload;
  },
  [types.SET_SC_BONUS_CALCULATION_TYPES]: (state, payload) => {
    state.sc_bonus_calculation_types = payload;
  },
  [types.SET_SC_BONUS]: (state, payload) => {
    state.sc_bonus = payload;
  },
  [types.ADD_SC_BONUS_ITEM]: (state, payload) => {
    state.sc_bonuses.push(payload);
  },
  [types.UPDATE_SC_BONUS_ITEM]: (state, payload) => {
    state.sc_bonuses.splice(state.sc_bonuses.indexOf(
      state.sc_bonuses.find(c => c.uuid === payload.uuid)
    ), 1, payload);
  },
  [types.DELETE_SC_BONUS_ITEM]: (state, payload) => {
    state.sc_bonuses.splice(state.sc_bonuses.indexOf(
      state.sc_bonuses.find(c => c.uuid === payload.uuid)
    ), 1);
  },
  [types.SET_SC_BONUS_FILTERS]: (state, payload) => {
    state.sc_bonus_filters = payload;
  },
}

export default mutations;
