import * as types from "../../mutation-types";

const mutations = {
    [types.SET_SM_STOCKS]: (state, payload) => {
        state.sm_stocks = payload;
    },
    [types.SET_SM_STOCK]: (state, payload) => {
        state.sm_stock = payload;
    },
    [types.ADD_SM_STOCK_ITEM]: (state, payload) => {
        state.sm_stocks.push(payload);
    },
    [types.UPDATE_SM_STOCK_ITEM]: (state, payload) => {
        state.sm_stocks.splice(state.sm_stocks.indexOf(
            state.sm_stocks.find(c => c.uuid === payload.uuid)
        ), 1, payload);
    },
    [types.DELETE_SM_STOCK_ITEM]: (state, payload) => {
        state.sm_stocks.splice(state.sm_stocks.indexOf(
            state.sm_stocks.find(c => c.uuid === payload.uuid)
        ), 1);
    },
    [types.SET_SM_STOCK_FILTERS]: (state, payload) => {
        state.sm_stock_filters = payload;
    },
    [types.SET_SM_STOCK_PRODUCT_INFO]: (state, payload) => {
      state.sm_stock_product_info = payload;
    },
}

export default mutations;
