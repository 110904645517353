<!-- template for the modal component -->
<template type="x/template">
  <transition name="modal" id="contact-info-dialog">
    <div class="modal-mask" v-show="show">
      <div class="modal-container" @click.stop>
        <div class="modal-header">
          <h3>{{ title }}</h3>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-lg-12">
              <label class="control-label">
                <h5>{{ $t('role_name') }}</h5>
              </label>
              <input type="text" v-model="modalData.name"
                     :class="{ 'field-error' : this.$v.modalData.name.$error }"
                     class="form-control"/>
            </div>
          </div>
          <div class="row pt-3">
            <div class="col-lg-12">
              <h5>{{ $t('permissions') }}</h5>
              <hr/>
            </div>
            <div class="col-lg-12">
              <role-item-header class-name="mb-2"
                              :data="headerData"
                              @onModuleChanged="onModuleChanged"
                              @onReadChanged="onReadChanged"
                              @onWriteChanged="onWriteChanged"
                              @onExecuteChanged="onExecuteChanged"
              />
              <edit-role-item v-for="item in availablePermissions"
                              :data="item"
                              :checkSelected="check_selected"
                              :key="Math.random()"
                              class="p-1"
                              @onItemChanged="onItemChanged">
              </edit-role-item>
            </div>
          </div>
        </div>
        <div class="modal-footer col-lg-12">
          <div class="form-group row justify-content-end">
            <div class="col-lg-6">
              <button class="btn btn-outline-success" @click="OK()">Save</button>
            </div>
            <div class="col-lg-6">
              <button class="modal-default-button btn btn-outline-secondary ml-3" @click="close()">Close</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
    import { required } from 'vuelidate/lib/validators'
    import EditRoleItem from "./EditRoleItem";
    import {mapGetters} from "vuex";
    import RoleItemHeader from "@/pages/roles-permissions/partials/RoleItemHeader.vue";

    export default {
        name: 'EditRole',
        props: ['show', 'data'],
        data() {
            return {
                modalData: this.data ? this.data : {},
                headerData: {m: false, r: false, w: false, x: false},
                error: '',
                check_selected: {
                  module: false,
                  permission: 'r',
                  value: false,
                },
            };
        },
        components: {
          RoleItemHeader,
            EditRoleItem

        },
        computed: {
            ...mapGetters({
                permissions: 'roles/permissions',
            }),
            availablePermissions() {
                return this.modalData.available_permissions;
            },
            title() {
                return this.modalData.uuid ? this.$t('edit_role') : this.$t('new_role');
            },
        },
        methods: {
            close: function () {
                this.$emit('close');
            },
            OK () {
                this.$v.modalData.$touch();

                if(!this.$v.modalData.$invalid) {

                    this.modalData.permissions = this.buildPermissions();
                    this.$emit('onSaved', this.modalData);
                    this.close();

                } else {
                    this.setErrorMessage(this.$t('fill_out_error'));
                }
            },
            saveAsTemplate() {
                this.$emit('onSaveAsTemplate', this.modalData);
            },
            setErrorMessage(msg) {
                this.error = msg;
            },
            buildPermissions() {
                if(this.modalData && this.modalData.available_permissions) {
                  let permissions = [];

                  Object.assign([] , this.modalData.available_permissions).map((item) => {
                     if(item.selected) {
                         permissions.push({
                             uuid: item.uuid,
                             access: Object.keys(item.access).filter((key) => {
                                 if(item.access[key]  && key.length === 1) {
                                     return key;
                                 }
                             }),
                             code: item.code,
                             name: item.name,
                         });

                     }
                  });

                    return permissions;
                }
            },
            hasPermission(item) {
                if(this.modalData.permissions) {
                    return this.modalData.permissions.find(p => p.code === item.code);
                }
            },
            checkPermissions() {
                let vm = this;
                if(this.modalData.available_permissions) {
                    this.modalData.available_permissions.map((item) => {
                        if(vm.hasPermission(item)) {
                            item.selected = true;

                            let perm = vm.getPermission(item);

                            if(perm && perm.access) {
                                item.access = perm.access;
                            } else {
                                return {r:false, w:false, x:false};
                            }

                        } else {
                          item.access = {r:false, w:false, x:false};
                            item.selected = false;
                            item.access.disable = true;
                            //original inherited access
                        }
                    });
                }
            },
            getPermission(permission_item) {
                if(this.modalData.permissions) {
                    let found = this.modalData.permissions.find(p => p.code == permission_item.code);

                    if(found && found.access) {
                        return found;
                    }
                }
            },
            onItemChanged(item) {
                if(this.modalData.available_permissions) {
                    let found = this.modalData.available_permissions.find(p => p.code == item.code);
                    this.modalData.available_permissions.splice(
                        this.modalData.available_permissions.indexOf(found), 1, item
                    );
                }
            },
            loadPermissions()  {
                this.$store.dispatch('roles/getDefaultPermissions', {});
            },
            onModuleChanged(val) {
              const vm = this;
              this.$set(this.check_selected, 'module', val);

              this.modalData.available_permissions.map((item) => {
                item.selected = val;
                if (!val) {
                  vm.onReadChanged(false);
                  vm.onWriteChanged(false);
                  vm.onExecuteChanged(false);
                }
              });

              this.checkPermissions();
            },
            onReadChanged(val) {
              this.selectAccess('r', val);
            },
            onWriteChanged(val) {
              this.selectAccess('w', val);
            },
            onExecuteChanged(val) {
              this.selectAccess('x', val);
            },
          selectAccess(perm, val) {
            this.$set(this.check_selected, 'permission', perm);
            this.$set(this.check_selected, 'value', val);
          },
        },
        watch: {
            data: function(newVal) {
                this.modalData = newVal;
                this.checkPermissions();
            },
            show: function(newVal) {
                this.setErrorMessage("");
            },
        },
        validations() {
            return {
                modalData: {
                  name: {
                      required
                  },
                },
            }
        },
    }
</script>

<style scoped>
  * {
    box-sizing: border-box;
  }

  .modal-container {
    width: 60% !important;
  }
</style>
