<template>
  <div class="action-buttons pt-4 mt-3 col-lg-8">
    <div class="row form-group text-center text-lg-left pb-2">
      <div class="col-sm mb-3 mb-lg-0 pl-lg-0">
        <button class="btn btn-md btn-secondary btn-secondary col-lg-12" @click="onGotoList">{{ $t('back_to_list') }}</button>
      </div>
      <div class="col-sm mb-3 mb-lg-0" v-if="canEdit">
        <button class="btn btn-md btn-primary col-lg-12" @click="onSave">{{$t("common.save")}}</button>
      </div>
      <div class="col-sm mb-3 mb-lg-0" v-if="canEdit && !data.uuid && saveAndNew">
        <button class="btn btn-md btn-success col-lg-12" @click="onSaveAndNew">{{$t("common.save_and_new")}}</button>
      </div>
      <div class="col-sm" v-if="data.uuid && canDelete">
        <button class="btn btn-md btn-danger col-lg-12" @click="onDelete">{{$t("common.delete")}}</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AgroActionButtons',
  props: {
    data: {
      type: Object,
    },
    gotoList: {
      type: Function|null,
    },
    canEdit: {
      type: Boolean|null,
    },
    canDelete: {
      type: Boolean|null,
    },
    saveAndNew: {
      type: Boolean|null,
    },
  },
  methods: {
    onSave() {
      this.$emit('onSave', this.data);
    },
    onSaveAndNew() {
      this.$emit('onSaveAndNew', this.data);
    },
    onDelete() {
      this.$emit('onDelete', this.data);
    },
    onGotoList() {
      this.$emit('onGotoList', this.data);
    }
  }
}
</script>
