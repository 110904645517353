import * as types from "../mutation-types";

const mutations = {
    [types.SET_DEPARTMENTS]: (state, payload) => {
        state.departments = payload;
    },
    [types.SET_DEPARTMENT_LIST]: (state, payload) => {
      state.department_list = payload;
    },
    [types.SET_DEPARTMENT_ITEM]: (state, payload) => {
        state.departments.splice(state.departments.indexOf(
            state.departments.find(c => c.uuid === payload.uuid)
        ), 1, payload);
    },
    [types.DELETE_DEPARTMENT_ITEM]: (state, payload) => {
      state.departments.splice(state.departments.indexOf(
        state.departments.find(c => c.uuid === payload.uuid)
      ), 1);
    },
    [types.SET_DEPARTMENT]: (state, payload) => {
        state.department = payload;
    },
    [types.SET_PARENT_DEPARTMENTS]: (state, payload) => {
        state.parent_departments = payload;
    },
}

export default mutations;
