<template>
  <div id="file-uploader" class="row mt-3">
    <div class="col-lg-12" v-if="!fileObject">
      <div class="row">
        <div class="col-lg-12">
          <img src="@/assets/images/upload_image.png" class="upl-img"/>
          <input :id="button" type="file" :ref="ref" :accept="ext ? ext : '*/*'" @change="onFileChange" class="d-none">
        </div>
      </div>
      <div class="row pt-3">
        <div class="col-lg-12">
          <button class="btn btn-success" @click="showUploader">{{ $t('common.choose_file_to_upload', { ext: ext }) }}</button>
        </div>
      </div>
    </div>
    <div class="col-lg-12" v-else>
      <div class="row">
        <div class="col-lg-10 text-center remove-holder">
          <img src="~@/assets/images/desktop/icons/controls/check-circle.svg" class="icon-svg text-success sicon"/>
          <div class="filename">{{ filename }}</div>
          <p>
            <button class="btn btn-sm btn-secondary" @click="removeFile">{{ $t('common.remove_file') }}</button>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from 'jquery'

export default {
  name: 'FileLoader',
  props: ['config', 'ext', 'placeholder'],
  data() {
    return {
      fileObject: undefined,
      configData : this.config ? this.config : {},
    }
  },
  computed: {
      filename() {
        return this.fileObject.name;
      },
      ref() {
        return 'file_'+Math.random().toString().replace('.', '');
      },
      button() {
        return 'uploader_input_'+Math.random().toString().replace('.', '');
      }
  },
  methods: {
    onFileChange(e) {
      let files = e.target.files || e.dataTransfer.files;

      if (!files.length)
        return;

      this.fileObject = files[0];
      this.$emit('onFileLoaded', this.fileObject);
    },
    removeFile: function (e) {
      this.fileObject = undefined;
      this.$emit('onFileRemoved');
    },
    showUploader() {
      $('#'+this.button).click();
    }
  },
  watch: {
    config: {
      handler: function(newVal) {
        this.configData = newVal;
      },
      deep: true,
      immediate: true,
    }
  }
}
</script>

<style scoped>
.upl-img {
  width: 100px;
  height: 65px;
}

.sicon {
  max-width: 186px;
  padding-bottom: 10px;
  font-size: 32px;
}

.remove-holder {
  border: 1px #ededed solid;
}
</style>
