<template type="x/template">
  <transition name="modal" id="contact-info-dialog">
    <DxPopup
      class="vertical-center"
      ref="addFitoFertilityPopup"
      :width="600"
      height="60vh"
      :show-title="true"
      :title="title"
      :drag-enabled="false"
      :close-on-outside-click="false"
      :show-close-button="true"
      :visible="showPopup"
      @hidden="$emit('hidden')"
    >
      <template #content>
        <DxDataGrid
          :data-source="dataSourceBatchApplys"
          :show-borders="true"
          key-expr="id"
          @row-updated="rowUpdated"
          :element-attr="gridAttributes"
        >
          <DxColumn data-field="fito.id" caption="Fito or ferti">
            <DxLookup
              :data-source="dsFitos"
              value-expr="id"
              display-expr="name"
            />
          </DxColumn>
          <DxColumn data-field="quantity" data-type="string" />
          <DxColumn data-field="mu" data-type="string" />
          <DxEditing
            :allow-updating="true"
            :allow-adding="true"
            :allow-deleting="true"
            mode="popup"
          >
            <GridPopup
              :show-title="true"
              :width="700"
              :height="525"
              title="Fito and fertility title"
            />
            <DxForm>
              <DxItem
                :col-count="2"
                :col-span="2"
                item-type="group"
              >
                <DxItem data-field="fito.id" caption="Fit or Fert" />
                <DxItem data-field="quantity" />
              </DxItem>
            </DxForm>
          </DxEditing>
        </DxDataGrid>
      </template>
    </DxPopup>
  </transition>
</template>

<script>
import { getTestBatchApplys, getTestFitos } from "./testData.js";

import DxPopup from "devextreme-vue/popup";
import {
  DxDataGrid,
  DxColumn,
  DxEditing,
  DxPopup as GridPopup,
  DxForm,
  DxLookup
} from "devextreme-vue/data-grid";
import { DxItem } from "devextreme-vue/form";

export default {
  name: "AddFitoAndFertility",
  props: ["showPopup", "modelType"],
  data() {
    return {
      dataSourceBatchApplys: [],
      dsFitos: [],
      gridAttributes: {
        class: "agx-grid",
      },
    };
  },
  computed:{
      title(){
          if(this.modelType == "fito"){
          return "Apply fito";
          }
          return "Apply fertility";
      }
  },
  components: {
    DxPopup,
    DxDataGrid,
    DxColumn,
    DxEditing,
    GridPopup,
    DxForm,
    DxItem,
    DxLookup
  },
  methods:{
     rowUpdated(event){
        console.log('updated -> ', event.data);
     }
  },
  mounted() {
    this.dataSourceBatchApplys = getTestBatchApplys();
    this.dsFitos = getTestFitos();
  },
};
</script>