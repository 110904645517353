import Vue from 'vue'
import {convertDecimalHoursToGMT} from "~/helpers/common";

Vue.filter('defaultVal', function (value, default_value) {
  if (!value) return default_value;
  return value.toString();
});

Vue.filter('lowerCase', function (value, default_value) {
  if (!value) return default_value;
  return value.toString().toLowerCase();
});

Vue.filter('lineToUnderscore', function (value, default_value) {
  if (!value) return default_value;
  return value.toString().toLowerCase().replaceAll('-', '_').trim();
});

Vue.filter('toGMT', function (value, default_value) {
  if (!value) return default_value;
  return convertDecimalHoursToGMT(value);
});

Vue.filter('decimal', function (value, default_value) {
  if (!value) return default_value;
  return parseFloat(value).toFixed(2);
});
