<template>
  <div v-if="canEdit || canDelete">
    <div class="row">
      <div class="form-group col-sm-12 col-lg-6 text-left">
        <label class="control-label">{{ $t("common.name") }}</label>
        <input
          type="text"
          v-model="data.name"
          :placeholder="$t('common.name')"
          :class="{ 'field-error': $v.data.name.$error }"
          class="form-control"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-sm-6 col-lg-6 form-group text-left">
        <label class="control-label"> {{ $t("common.unit") }} </label>
        <v-select
          v-model="data.department"
          :options="departmentList"
          :reduce="(option) => option"
          :class="{ 'field-error': $v.data.department_uuid.$error }"
          :placeholder="$t('common.unit')"
          @input="onDepartmentSelected"
          label="name"
          autocomplete="off"
        ></v-select>
      </div>
      <div class="col-sm-6 col-lg-6 form-group text-left">
        <label class="control-label"> {{ $t("season") }} </label>
        <v-select
          v-model="data.season"
          :options="seasonList"
          @input="onSeasonSelected"
          :reduce="(option) => option"
          :placeholder="$t('season')"
          :class="{ 'field-error': $v.data.season_uuid.$error }"
          label="name"
          autocomplete="off"
          :disabled="!data.is_deletable"
        ></v-select>
      </div>
    </div>
    <div class="row mt-1">
      <div class="form-group col-sm-6 col-lg-6 text-left">
        <label class="control-label">{{ $t("works.parcels.surface") }}</label>
        <input
          type="number"
          v-model="data.surface"
          :placeholder="$t('works.parcels.surface')"
          :class="{ 'field-error': $v.data.surface.$error }"
          class="form-control"
        />
      </div>
      <div class="col-sm-12 col-lg-6">
        <div class="form-group text-left mb-0">
          <label class="control-label">{{ $t("works.parcels.culture") }}</label>
          <v-select
            v-model="data.culture"
            :options="culturelList"
            @input="onCultureSelected"
            :reduce="(option) => option"
            :placeholder="$t('works.parcels.culture')"
            label="name"
            autocomplete="off"
          ></v-select>
        </div>
        <div
          class="text-primary fs11"
          v-html="
            $t('works.parcels.could_not_find', { parcel_uuid: data?.uuid })
          "
        ></div>
      </div>
    </div>
    <div class="row mt-1">
      <div class="form-group col-sm-12 col-lg-6 text-left mb-0">
        <Toggle
          :toggleText="$t('works.parcels.is_group')"
          :notToggledText="$t('common.no')"
          :toggledText="$t('common.yes')"
          :isActive="is_group"
          :onChange="onIsGroupChanged"
          :disabled="!data.is_deletable"
        >
        </Toggle>
      </div>
    </div>
    <div class="row" v-if="!is_group">
      <div class="form-group col-sm-12 col-lg-6 text-left">
        <label class="control-label">{{ $t("works.parcels.group") }}</label>
        <v-select
          v-model="data.parcel_group"
          :options="parcelGroupList"
          @input="onParcelGroupSelected"
          :reduce="(option) => option"
          :placeholder="$t('works.parcels.select_group')"
          label="name"
          autocomplete="off"
        ></v-select>
      </div>
    </div>
    <div style="height: 110px" v-if="!isModal"></div>
    <agro-action-buttons
      v-if="!isModal"
      :data="data"
      :can-edit="canEdit"
      :can-delete="canDelete"
      @onSave="save"
      @onSaveAndNew="saveAndNew"
      :save-and-new="true"
      @onDelete="confirmDelete"
      @onGotoList="gotoList"
    ></agro-action-buttons>
  </div>
  <div v-else>
    <forbidden></forbidden>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import required from "vuelidate/src/validators/required";
import {
  canDelete,
  canEdit,
  permissions,
  permissionTypes,
} from "@/helpers/Permissions";
import * as actions from "@/helpers/generalActions";
import AgroActionButtons from "@/components/AgroActionButtons/index";
import vSelect from "vue-select";
import Toggle from "@/components/Toggle.vue";
import Forbidden from "@/pages/errors/Forbidden";

export default {
  name: "ParcelDetailsContent",
  components: {
    AgroActionButtons,
    vSelect,
    Toggle,
    Forbidden,
  },
  props: {
    isModal: {
      type: Boolean,
      default: false,
    },
    defaultNewValues: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      data: {
        uuid: "",
        name: "",
        season_uuid: "",
        culture_uuid: "",
        department_uuid: "",
        parcel_group_uuid: "",
        surface: "",
        is_group: 0,
        is_deletable: true,
      },
      is_group: false,
    };
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
      users: "user/users",
      parcelGroups: "works-parcels/parcelGroups",
      cultures: "works-cultures/cultures",
      departments: "company/departments",
      domain: "auth/domain",
      seasons: "seasons/seasons",
    }),
    name() {
      return this.$t("works.parcels.name");
    },
    config() {
      return {
        permission: permissions.PARCELS,
        permission_type: permissionTypes.module,
        get: this.get,
        create: this.create,
        update: this.update,
        delete: this.delete,
        list_url: "/works/parcels",
        confirm_delete: true,
      };
    },
    actions() {
      return actions;
    },
    canEdit() {
      return canEdit(this);
    },
    canDelete() {
      return canDelete(this) && this.data.is_deletable;
    },
    title() {
      return (
        (this.data?.uuid ? this.$t("common.edit") : this.$t("common.new")) +
        " " +
        this.name
      );
    },
    culturelList() {
      if (this.cultures) {
        return this.cultures.map((item) => ({
          value: item.uuid,
          name: item.name,
        }));
      }
      return [];
    },
    departmentList() {
      if (this.departments) {
        return this.departments.map((item) => ({
          value: item.uuid,
          name: item.name,
        }));
      }
      return [];
    },
    seasonList() {
      if (this.seasons) {
        return this.seasons.map((item) => ({
          value: item.uuid,
          name: item.name,
        }));
      }
      return [];
    },
    parcelGroupList() {
      if (this.parcelGroups) {
        return this.parcelGroups.map((item) => ({
          value: item.uuid,
          name: item.name,
        }));
      }
      return [];
    },
  },
  methods: {
    ...mapActions({
      getCultures: "works-cultures/getCultures",
      get: "works-parcels/getParcel",
      getParcelGroups: "works-parcels/getParcelGroups",
      create: "works-parcels/createParcel",
      update: "works-parcels/updateParcel",
      delete: "works-parcels/deleteParcel",
      getDepartments: "company/getDepartments",
      getSeasons: "seasons/getSeasons",
    }),
    loadData() {
      if (
        this.$route.params?.uuid &&
        this.$route.params?.uuid !== null &&
        this.$route.params?.uuid !== "null"
      ) {
        return this.actions.getData(this, null, () => {
          this.is_group = this.data.is_group === 1;
          this.data.season = this.seasonList.find(
            (season) => season.value === this.data.season_uuid
          );
          this.data.culture = this.culturelList.find(
            (culture) => culture.value === this.data.culture_uuid
          );
          this.loadParcelGroups();
          this.$emit("title");
        });
      }
    },
    gotoList() {
      return actions.gotoList(this);
    },
    confirmDelete() {
      return this.actions.confirmDelete(this);
    },
    save() {
      return this.actions.save(this, this.isModal ? null : this.config.list_url);
    },
    saveAndNew() {
      return this.actions.saveAndNew(this, "add");
    },
    onSeasonSelected(data) {
      this.data.season_uuid = data?.value ?? null;
      this.loadParcelGroups(true);
    },
    onDepartmentSelected(data) {
      this.data.department_uuid = data?.value ?? null;
      this.loadParcelGroups(true);
    },
    onParcelGroupSelected(data) {
      this.data.parcel_group_uuid = data?.value ?? null;
      this.data.parcel_group = data ?? null;
      this.$forceUpdate();
    },
    onIsGroupChanged(val) {
      this.data.is_group = val ? 1 : 0;
      this.is_group = val;

      if (val) {
        this.data.parcel_group_uuid = null;
        this.data.parcel_group = null;
      } else {
        this.loadParcelGroups();
      }
    },
    onCultureSelected(data) {
      if (data) {
        this.data.culture_uuid = data?.value ?? null;
      } else {
        this.data.culture_uuid = null;
      }
      this.$forceUpdate();
    },
    loadParcelGroups(reset_group = false) {
      if (reset_group) {
        this.data.parcel_group_uuid = null;
        this.data.parcel_group = null;
      }

      if (
        this.data.department_uuid &&
        this.data.season_uuid &&
        !this.data.is_group
      ) {
        this.getParcelGroups({
          department_uuid: this.data.department_uuid,
          season_uuid: this.data.season_uuid,
        });
      }
    },
    fillFieldsFromDefaultNewValues() {
      if (this.defaultNewValues && this.data?.uuid === "") {
        Object.keys(this.defaultNewValues).forEach((key) => {
          this.data[key] = this.defaultNewValues[key];
        });

        if(this.defaultNewValues.department_uuid){
          this.data.department = this.departmentList.find(
            (department) => department.value === this.defaultNewValues.department_uuid
          );
        }
        if(this.defaultNewValues.season_uuid){
          this.data.season = this.seasonList.find(
            (season) => season.value === this.defaultNewValues.season_uuid
          );
        }
        if(this.defaultNewValues.culture){
          this.data.culture = this.culturelList.find(
            (culture) => culture.name === this.defaultNewValues.culture
          );
          this.data.culture_uuid = this.data.culture?.value ?? null;
        }

        this.loadParcelGroups(true);
      }
    },
  },
  mounted() {
    this.getDepartments({
      company_uuid: this.domain?.uuid,
    });
    this.getCultures({
      enablePagination: 0,
      limit: 9999,
      is_variety: 0,
    });
    this.getSeasons();
    this.loadData();
    this.actions.handleParams(this);
  },
  watch: {
    $route: {
      handler: function (newVal) {
        this.actions.handleParams(this);
      },
      deep: true,
    },
    permission: {
      handler: function (newVal) {
        this.permissionModule = newVal;
      },
      deep: true,
    },
    defaultNewValues: {
      handler: function (newVal) {
        this.fillFieldsFromDefaultNewValues();
      },
      deep: true,
    },
  },
  validations() {
    return {
      data: {
        name: {
          required,
        },
        department_uuid: {
          required,
        },
        season_uuid: {
          required,
        },
        surface: {
          required,
        },
      },
    };
  },
};
</script>
