import * as types from "../mutation-types";

const mutations = {
  [types.SET_LAYERS]: (state, payload) => {
    state.layers = payload;
  },
  [types.SET_LAYER_WORKS]: (state, payload) => {
    state.layer_works = payload;
  },
  [types.SET_LAYER_FILTERS]: (state, payload) => {
    state.layer_filters = payload;
  },
  [types.SET_LAYER_SEASON]: (state, payload) => {
    state.layer_season_uuid = payload;
  },
  [types.SET_LAYERS_LOADING]: (state, payload) => {
    state.layers_loading = payload;
  },
}

export default mutations;
