<template>
  <div class="multi-row-column" :class="[containerClass, textAlign, visible === false ? 'd-none' : null, infiniteScroll ? 'infiniteStickyTh' : null]">
    <div class="row-title" :class="[title ? 'pb5' : null]">{{ title }}</div>
    <div v-for="item in items" :key="Math.random()" class="" v-if="!infiniteScroll">
      <one-col :value="item.value" :align="align" :date-format="dateFormat" :decimal-precision="decimalPrecision"/>
    </div>
  </div>
</template>

<script>
import OneCol from "~/pages/salary-calculator/sc-work-reports/partials/DailyGrid/Columns/OneCol.vue";

export default {
  name: 'MultiRowColumn',
  components: {OneCol},
  props: [
    'data',
    'title',
    'valign',
    'align',
    'containerClass',
    'dateFormat',
    'infiniteScroll',
    'decimalPrecision'
  ],
  data() {
    return {
      items: this.data ?? [],
      visible: true,
    }
  },
  computed: {
    textAlign() {
      return 'text-'+(this.align === undefined ? 'center' : this.align);
    }
  },
  methods: {
    hide() {
      this.visible = false;
    },
    show() {
      this.visible = true;
    }
  },
  watch: {
    data: {
      handler: function(newVal) {
        this.items = newVal;
      },
      deep: true,
    }
  }
}
</script>

<style lang="scss" scoped>
  .multi-row-column {
    .row-title {
      padding-left: 10px;
      font-size: 14px;
      font-weight: bold;
      min-width: 180px;
    }
  }

  .pb5 {
    padding-bottom: 5px;
  }
</style>
