<template>
  <div class="photo-factory">
    <div class="photo-container">
      <CameraImageList :data="cameraImages"/>
      <div class="camera-image" v-if="cameraImages?.length < maxCameraImages">
        <img src="@/assets/images/icons/add_photo.svg" class="c-pointer" @click="openCamera"/>
      </div>
    </div>
    <CameraViewDialog :show-popup="isCameraOpen"
                      @hidden="closeCamera"
                      @onImageCaptured="onCameraImageCaptured"/>
  </div>
</template>

<script>
import CameraViewDialog from "~/components/Camera/CameraViewDialog.vue";
import {randomID} from "~/helpers/common";
import CameraImageList from "@/components/Camera/CameraImageList.vue";
export default {
  name: 'PhotoFactory',
  props: ['data', 'maxImages'],
  data() {
    return {
      isCameraOpen: false,
      cameraImages: this.data ?? [],
      maxCameraImages: this.maxImages ?? 5,
    }
  },
  components: {
    CameraImageList,
    CameraViewDialog,
  },
  methods: {
    openCamera() {
      this.isCameraOpen = true;
    },
    closeCamera() {
      this.isCameraOpen = false;
    },
    onCameraImageCaptured(image) {
      if (image) {
        this.cameraImages.push({
          id: randomID(),
          image: image,
        });
      }
    },
    getCameraImages() {
      return this.cameraImages;
    }
  },
  watch: {
    data: {
      handler: function(newVal) {
        this.cameraImages = newVal;
      },
      deep: true,
    }
  }
}
</script>

<style scoped lang="scss">
.camera-image {
  display: inline-flex;
  position: relative;
  top: 20px;
  left: -4px;
}
</style>
