<template>
    <DxDropDownBox
        :element-attr="dropBoxAttributes"
        ref="productSelectBox"
        :placeholder="placeholder"
        :value="gridBoxValue"
        :opened="isGridBoxOpened"
        :defer-rendering="false"
        :display-expr="gridBoxDisplayExpr"
        :show-clear-button="false"
        :data-source="comboDataset"
        :remote-operations="false"
        :cache-enabled="true"
        :class="['combo_with_add', {'product_control' : isGridBoxOpened} ,{
        step_control_error: hasError,
        }]"
        @opened="isGridBoxOpened = true"
        @closed="isGridBoxOpened = false"
        drop-down-button-template="imageIcon"
        :disabled="isComboDisabled"
    >
    <template #imageIcon>
        <div>
            <div class="combo_open_button" v-show="!allowGridAddNewProduct && !hasValueSelected">
                <img src="@/assets/images/icons/plus_green.svg"
                class="custom-selector-icon"
            ></div>
            <div class="combo_open_button" v-show="allowGridAddNewProduct">
                <img src="@/assets/images/icons/plus_green.svg"
                class="custom-selector-icon"
                @click="addNewItemInstant"
            ></div>
            <div class="combo_open_button" v-show="hasValueSelected && !allowGridAddNewProduct">
                <img src="@/assets/images/icons/edit.svg"
                class="custom-selector-icon"
                @click="!addNewItemInstant"
            ></div>
        </div>
    </template>
        <template #content>
            <div tabindex="0">
                <DxDataGrid
                    :element-attr="gridAttributes"
                    ref="productGrid"
                    class="product_grid"
                    :no-data-text="addNewItemInfoText"
                    :data-source="comboDataset"
                    :show-column-headers="false"
                    :hover-state-enabled="true"
                    :repaint-changes-only="true"
                    :selected-row-keys="gridBoxValue"
                    @selection-changed="onGridSelectionChanged($event)"
                    @content-ready="onGridContentReady($event)"
                    height="100%"
                    @init-new-row="initNewProduct($event)"
                    @row-inserting="productInserting($event)"
                >

                    <DxColumn data-field="name" caption="Denumire">
                    </DxColumn>
                    <DxSelection mode="single" />
                    <DxFilterRow :visible="true" />
                    <DxScrolling mode="infinite" />

                </DxDataGrid>
            </div>
        </template>
    </DxDropDownBox>
</template>

<script>

import DxDropDownBox from "devextreme-vue/drop-down-box";
import { DxBox, DxItem } from "devextreme-vue/box";
import {
  DxDataGrid,
  DxColumn,
  DxSelection,
  DxPaging,
  DxFilterRow,
  DxScrolling,
  DxEditing,
  DxTexts,
  DxForm,
  DxPopup as DxPopupGrid,
  DxRequiredRule,
  DxToolbar
} from "devextreme-vue/data-grid";

export default {
    name: "DxComboWithAdd",
    props:{
        comboDataset:{
             type: Array,
            default: () => [],
        },
        addNewText:{
             type: String,
            default: () => '',
        },
        hasError:false,
        placeholder: String,
        isComboDisabled: Boolean
    },
    data(){
        return {
            gridBoxValue: [],
            isGridBoxOpened: false,
            allowGridAddNewProduct: false,
            dropBoxAttributes: {
                class: 'drop_down_with_add'
            },
            gridAttributes: {
                class: 'grid_drop_down_with_add'
            }
        }
    },
    watch:{
        isGridBoxOpened(newVal){
            this.isGridBoxOpened = newVal;
            if(this.isGridBoxOpened){
                this.$refs.productGrid.instance.clearFilter();
            }
            this.$set(this, 'allowGridAddNewProduct', false);
        },
    },
    computed: {
        hasValueSelected(){
            return this.gridBoxValue && this.gridBoxValue.length > 0;
        },
        addNewItemInfoText(){
            //return '🛈 ' + this.addNewText + ', apasă ➕ pentru a adăuga';
            return this.addNewText + ', apasă butonul de adăugare!';
        }
    },
    components: {
        DxDropDownBox,
        DxDataGrid,
        DxColumn,
        DxSelection,
        DxPaging,
        DxFilterRow,
        DxScrolling,
        DxEditing,
        DxTexts,
        DxForm,
        DxPopupGrid,
        DxRequiredRule,
        DxToolbar,
        DxBox, DxItem
    },
    methods:{
        gridBoxDisplayExpr(item) {
            if (item) {
                //this.batch.product_uuid = item.uuid;
            }
            return item && item.name;
        },
        onGridSelectionChanged(e) {
            this.gridBoxValue = e.currentSelectedRowKeys;
            this.isGridBoxOpened = false;
            this.$emit('selectionChanged', this.gridBoxValue);
        },
        onGridContentReady(e){
            if(e.component.getVisibleRows().length <= 0){
                this.allowGridAddNewProduct = true;
            }
            else{
                this.allowGridAddNewProduct = false;
            }
        },
        initNewProduct(e){
            let gridFilter = e.component.getCombinedFilter();
            e.data.name = gridFilter && gridFilter.filterValue ? gridFilter.filterValue : "";
        },
         productInserting(e){


        },
        addNewItemInstant(){
            let gridFilter = this.$refs.productGrid.instance.getCombinedFilter();
            let prodName  = gridFilter && gridFilter.filterValue ? gridFilter.filterValue : "";
            this.$emit('addNewItemInstant', prodName);
        },
        updateWithNewItem(newItem){
            if(newItem != null){
                this.$refs.productSelectBox.instance.close();
                this.gridBoxValue = [];
                this.gridBoxValue.push(newItem);
            }
            else {
                this.gridBoxValue = [];
            }
        },
        resetControl(){
            this.gridBoxValue = [];
        }
    }
}
</script>

<style lang="scss">
    @import "@/assets/scss/components/dxComboWithAdd.scss";
</style>
