<template>
  <div class="edit-role-item row">
    <div class="col-lg-4">
      <label class="control-label">
        <input type="checkbox" v-model="selected"/> {{ item.name }}
      </label>
    </div>
    <div class="col-lg-2">
      <label>
        <input type="checkbox" v-model="read" class="mr-2" @change="changeAccess"
               :disabled="!selected"/>{{$t("common.read")}}
      </label>
    </div>
    <div class="col-lg-3">
      <label>
        <input type="checkbox" v-model="write" class="mr-2" @change="changeAccess"
               :disabled="!selected"/>{{$t("common.create")}}/{{$t("common.update")}}
      </label>
    </div>
    <div class="col-lg-2">
      <label>
        <input type="checkbox" v-model="executeDelete" @change="changeAccess"
               class="mr-2" :disabled="!selected"/> {{$t("common.delete")}}
      </label>
    </div>
  </div>
</template>

<script>
  export default {
      name: 'EditRoleItem',
      props: ['data', 'checkSelected', 'moduleCheck'],
      data() {
          return {
              item: this.data ? this.data : {},
              selected: false,
              read: false,
              write: false,
              executeDelete: false,
              check_selected: this.checkSelected ?? {},
          }
      },
      methods: {
          onItemChanged(val) {
            this.item.selected = val;
              if(!val) {
                  this.item.access = {r: false, w:false,  x:false};
                  this.read = false;
                  this.write = false;
                  this.executeDelete = false;
              }

              this.$emit('onItemChanged', this.item);
          },
          changeAccess(val) {
            this.onItemChanged(this.selected);
            this.item.access.r = this.read;
            this.item.access.w = this.write;
            this.item.access.x = this.executeDelete;
          }
      },
      watch: {
          data: {
              handler: function(newVal) {
                  this.item = newVal;
                  this.selected = newVal.selected;
                  this.read = newVal.access.r;
                  this.write = newVal.access.w;
                  this.executeDelete = newVal.access.x;
              },
              deep: true,
              immediate: true,
          },
        selected: function(newVal) {
          this.onItemChanged(newVal);
        },
        checkSelected: {
            //header checkbox check for column selection
            handler: function(newVal) {
              let checkSkip = newVal && newVal.module === false && newVal.permission === 'r' && !newVal.value;

              if (!checkSkip) {
                this.check_selected = newVal;
                this.$set(this, 'selected', newVal.module);

                if (newVal.permission === 'r') {
                  this.item.access.r = newVal.value;
                  this.read = newVal.value;
                }

                if (newVal.permission === 'w') {
                  this.item.access.w = newVal.value;
                  this.write = newVal.value;
                }

                if (newVal.permission === 'x') {
                  this.item.access.x = newVal.value;
                  this.executeDelete = newVal.value;
                }
              }
            },
            deep: true,
            immediate: true,
        },
        moduleCheck: {
            handler: function(newVal) {
              //
            },
          deep: true,
          immediate: true,
        }
      }
  }
</script>
