<template>
  <div class="dynamic-department-filter-types" :class="[containerStyle, colDirection ?? 'col']">
    <div class="row">
      <div v-for="type in list" class="col-lg form-group">
        <label class="control-label">{{ type.name }}</label>
        <b-form-select
          :ref="type.ref"
          v-model="filters[getID(type)]"
          class="form-control"
          @change="(value) => onChange(type, value)"
          :class="{ 'field-error' : $v.filters[getID(type)].$error }"
          :options="type.items"></b-form-select>
        <div class="col-lg form-group">
          <div v-if="filters.department_uuid && filters.department_uuid.length > 0" class="text-primary fs-small">
            {{ $t('item_selected', { count: filters.department_uuid.length}) }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {requiredIf} from "vuelidate/lib/validators";
import {buildDefaultDropdownList} from "~/helpers/common";

export default {
  name: 'UnitSelectorDDL',
  props: ['containerStyle', 'colDirection', 'required', 'inputUUID'],
  data() {
    return {
      _filters: null,
      list: [],
      related: [],
    }
  },
  computed: {
    ...mapGetters({
      domain: 'auth/domain',
      departments: 'company/departments',
      departmentFilterTypes: 'agroFilter/departmentFilterTypes',
    }),
    filters: {
      get: function() {
        if (!this._filters) {
          this._filters = {};
          Object.assign([],this.departmentFilterTypes).forEach((filterType) => {
            this._filters[this.getID(filterType)] = null;
          });
        }
        return this._filters;
      },
      set: function(value) {
        this._filters = value;
      }
    }
  },
  methods: {
    ...mapActions({
      getDepartments: 'company/getDepartments',
      getDepartmentFilterTypes: 'agroFilter/getDepartmentFilterTypes'
    }),
    findParentUUID(prevParentUUID, related = false) {
      let vm = this;
      let parentUUID = prevParentUUID;
      this.departments.forEach((item) => {
        if (item.parent_uuid === prevParentUUID) {
          let found = this.departments.find(d => d.uuid === item.uuid);
          if (related && found) {
            vm.related.push(found);
          }
          vm.findParentUUID(item.uuid, related)
        }
      });
      return parentUUID;
    },
    getChildren(type, prevParentUUID, related = false) {
      this.related = [];
      this.findParentUUID(prevParentUUID, related);
      return this.departments.filter((department) => {
        return department.type === type.code &&
          (department.parent_uuid === prevParentUUID || (related && this.related.find(d => d.uuid === department.uuid)));
      });
    },
    buildList(filteredList) {
      return buildDefaultDropdownList(Object.assign([], filteredList));
    },
    setListItems(type, items) {
      this.list.find(t => t.code === type.code).items = this.buildList(
        items
      );
    },
    resetLists(fromIndex) {
      for (let i = (fromIndex); i < this.departmentFilterTypes.length; i++) {
        let forType = this.departmentFilterTypes[i];
        //reset list
        this.setListItems(forType, []);
        this.filters[this.getID(forType)] = null;
      }
    },
    onChange(type, valueSelectedUUID) {
      this.filters[this.getID(type)] = valueSelectedUUID;
      let currentType = this.departmentFilterTypes.find(ft => ft.code === type.code);
      let index = this.departmentFilterTypes.indexOf(currentType);
      let nextType = this.departmentFilterTypes[index + 1] ?? null; //last ddl, sending filter value as department_uuid
      this.resetLists(index + 1);
      if (nextType) {
        let children = this.getChildren(nextType, valueSelectedUUID);
        this.setListItems(nextType, children);
        if (children.length === 0) {
          for (let i = (index + 1); i < this.departmentFilterTypes.length; i++) {
            let forType = this.departmentFilterTypes[i];
            let forChildren = this.getChildren(forType, valueSelectedUUID, true);
            if (forChildren.length > 0) {
              //fill the next ddl if not empty
              //and add all related children
              this.setListItems(forType, forChildren);
              break;
            }
          }
        }
      }
      this.$emit('onChange', valueSelectedUUID);
      this.onFiltersChanged();
    },
    onFiltersChanged() {
      this.$emit('onFiltersChanged', this.filters);
    },
    getFilters() {
      return this.filters;
    },
    getID(filterType) {
      return filterType.code + '_uuid';
    },
    getType(code) {
      return this.departmentFilterTypes.find(ft => ft.code === code);
    },
    loadFilters() {
      //filters
      let vm = this;
      this.$set(this, 'list', []);
      this.getDepartmentFilterTypes()
        .then((ft) => {
          this.getDepartments({
            company_uuid: this.domain.uuid,
          }).then((dep) => {
            ft.forEach((type) => {
              vm.list.push({
                name: type.name,
                ref: type,
                code: type.code,
                items: buildDefaultDropdownList(
                  dep.filter((depFilter) => {
                    return depFilter.type === type.code;
                  })
                )
              });
            });
          });
        });
    },
  },
  mounted() {
    this.loadFilters();
  },
  watch: {
    inputUUID: function(newVal) {
      console.log('change inputUUID');
    }
  },
  validations() {
    let rules = {};
    this.departmentFilterTypes.forEach((item) => {
      rules[item.code+'_uuid'] = {
        required: requiredIf(function(obj) {
          return this.props.required;
        }),
      }
    });
    return {
      filters: rules,
    }
  },
  }
</script>

