<!-- template for the modal component -->
<template type="x/template">
  <transition name="modal" id="contact-info-dialog">
    <div class="modal-mask" v-show="show">
      <div class="modal-container" @click.stop>
        <div class="modal-header">
          <h3>{{ title }}</h3>
          <p>{{ data.name }} ({{ data.email }})</p>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-lg-6 text-left bb">
              {{ $t('common.unit') }}
            </div>
            <div class="col-lg-4 bb text-left">
              {{ $t('role') }}
            </div>
            <div class="col-lg-2 unit-user-type bb">
              {{ $t('company.departments.user_type') }}
            </div>
          </div>
          <div v-for="unit in modalData.units" class="row pt-3">
            <div class="col-lg-6 text-left">
              {{ unit.name }}
            </div>
            <div class="col-lg-3 text-left">
              <router-link class="text-primary c-pointer"
                          to="/roles-permissions"
                          v-if="unit.role && unit.role.user_role">
                {{ unit.role.user_role.name }}</router-link>
            </div>
            <div class="col-lg-3 unit-user-type d-flex">
              <span v-if="modalData.is_superadmin === 1" class="border-primary text-primary m-1"> {{ $t('user.superadmin') }}</span>
              <span v-if="modalData.is_company_admin === 1" class="border-primary text-primary m-1"> {{ $t('company_admin') }}</span>
              <span v-if="unit.is_department_owner === 1" class="border-success text-success m-1"> {{ $t('department_owner') }}</span>
              <span v-if="unit.is_department_admin === 1" class="border-secondary text-secondary m-1"> {{ $t('company.departments.department_admin') }}</span>
            </div>
          </div>
        </div>
        <div class="modal-footer col-lg-11">
          <div class="form-group row justify-content-end">
            <div class="col-lg-6">
              <button class="modal-default-button btn btn-outline-secondary mr-5" @click="close()">{{$t("common.close")}}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: 'UnitAndRoleDialog',
  props: ['show', 'data'],
  data: function () {
    return {
      modalData: this.data ?? {},
      error: '',
    };
  },
  computed: {
    title() {
      return this.$t('user.units_and_roles');
    }
  },
  methods: {
    ...mapActions({
      getUser: 'user/getUser',
    }),
    close: function () {
      this.$emit('close');
    },
    OK () {
      this.close();
    },
    handleUserDetails() {
      let vm = this;
      this.getUser({
        uuid: this.modalData.uuid
      }).then((resp) => {
        vm.modalData = resp;
      });
    }
  },
  watch: {
    data: function(newVal) {
      this.modalData = newVal;
    },
    show: function (newVal) {
      if (newVal) {
        this.handleUserDetails();
      }
    },
  },
  validations() {
    return {
      modalData: {

      },
    }
  },
}
</script>

<style scoped>
* {
  box-sizing: border-box;
}
.modal-header {
  display: flex;
  flex-direction: column;
}

.modal-container {
  width: 60%;
}

.unit-user-type span {
  padding: 10px;
  border-width: 1px;
  border-style: solid;
}

.bb {
  border-bottom: 1px #ccc solid;
  font-weight: bold;
}
</style>
