<template>
  <card :title="title" id="details" v-if="canEdit || canDelete" v-cloak>
    <span class="action_buttons">
      <img src="~@/assets/images/desktop/icons/controls/plus.svg" class="icon-svg" :title="$t('common.create_new', { name: name })" @click="newObject()"/>
    </span>
    <div class="row">
      <div class="form-group col-sm-12 col-lg-6">
        <label class="control-label">{{ $t('works.cultures.parent_culture')}}</label>
        <v-select
          v-model="data.cname"
          :placeholder='$t("works.cultures.parent_culture")'
          autocomplete="off"
          :options="parentCultureList"
          @option:selected="onCultureSelected"
          taggable></v-select>
      </div>
    </div>

    <div class="row">
      <div class="form-group col-sm-12 col-lg-6 text-left">
        <label class="control-label">{{ $t('works.cultures.variety_name') }}</label>
        <input type="text" v-model="data.vname" :placeholder='$t("common.name")'
               class="form-control"/>
      </div>
    </div>

    <agro-action-buttons
      :data="data"
      :can-edit="canEdit"
      :can-delete="canDelete"
      @onSave="save"
      @onSaveAndNew="saveAndNew"
      :save-and-new="true"
      @onDelete="confirmDelete"
      @onGotoList="gotoList"
    ></agro-action-buttons>

  </card>
  <div v-else>
    <forbidden></forbidden>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import {buildDefaultDropdownList, buildAutocompleteOptions} from '@/helpers/common'
import required from "vuelidate/src/validators/required";
import {canDelete, canEdit, permissions, permissionTypes} from "@/helpers/Permissions";
import * as actions from '@/helpers/generalActions'
import AgroActionButtons from "@/components/AgroActionButtons/index";
import Forbidden from "@/pages/errors/Forbidden";

export default {
  name: 'CultureDetails',
  components: {
    Forbidden,
    AgroActionButtons,
  },
  data() {
    return {
      data: {},
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
      users: 'user/users',
      currentDepartment: 'auth/currentDepartment',
      cultures: 'works-cultures/cultures',
      culture: 'works-cultures/culture',
      variety: 'works-cultures/variety',
    }),
    name() {
      return this.$t('works.cultures.name')
    },
    config() {
      return {
        permission: permissions.CULTURES,
        permission_type: permissionTypes.module,
        get: this.get,
        create: this.create,
        update: this.update,
        delete: this.delete,
        list_url: '/works/cultures',
        confirm_delete: true,
      }
    },
    actions() {
      return actions;
    },
    canEdit() {
      return canEdit(this);
    },
    canDelete() {
      return canDelete(this) && this.data.is_editable;
    },
    title() {
      return (this.data.uuid ? this.$t('common.edit') : this.$t('common.new'))+' '+this.name;
    },
    parentCultureList() {
      return buildAutocompleteOptions(this.cultures, {
        uuid: 'uuid',
        name: 'name',
      });
    },
  },
  methods: {
    ...mapActions({
        getCultures: 'works-cultures/getCultures',
        get: 'works-cultures/getCultureVariety',
        create: 'works-cultures/createCultureVariety',
        update: 'works-cultures/updateCultureVariety',
        delete: 'works-cultures/deleteCultureVariety',
    }),
    loadData() {
      let uuid = this.$route.params.uuid ?? false;
      if (uuid) {
        return this.get({uuid: uuid, vuuid: this.$route.params.vuuid});
      }
      return null;
    },
    gotoList() {
      return actions.gotoList(this);
    },
    confirmDelete() {
      return this.actions.confirmDelete(this);
    },
    save() {
      this.data.department_uuid = this.currentDepartment.department_uuid;
      return this.actions.save(this, this.backPath ?? this.config.list_url);
    },
    saveAndNew() {
      this.data.department_uuid = this.currentDepartment.department_uuid;
      return this.actions.saveAndNew(this, 'add');
    },
    newObject() {
      return this.actions.newObject(this);
    },
    onCultureSelected(data) {
      this.data.parentCulture = {
        uuid: data.value,
        name: data.label,
      };
    },
  },
  mounted() {
    this.getCultures({
      department_uuid: this.currentDepartment.department_uuid,
      enablePagination: 0,
      limit: 9999,
      is_variety: 0,
    });
    this.loadData();
    this.actions.handleParams(this);
  },
  watch: {
    $route: {
      handler: function(newVal) {
        this.actions.handleParams(this);
      },
      deep: true,
    },
    culture: {
      handler: function(newVal) {
        this.data = newVal;
      },
      deep: true,
    },
    variety: {
      handler: function(newVal) {
        this.data = newVal;
      },
      deep: true,
    },
    permission: {
      handler: function(newVal) {
        this.permissionModule = newVal;
      },
      deep: true,
    },
  },
  validations(){
    return {
      data: {
        cname: {
          required,
        },
      }
    }
  }
}
</script>

<style scoped>

</style>
