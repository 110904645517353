<!-- template for the modal component -->
<template type="x/template">
  <transition name="modal" id="grid-filter-dialog">
    <div class="modal-mask" v-show="show">
      <div class="modal-container" @click.stop>
        <div class="modal-header">
          <h3>{{ $t('salary_calculator.reports.filters.column_settings') }}</h3>
        </div>
        <div class="modal-body">
          <div class="row">
            <grid-filter-column-list :data="modalData.headers" @onGridColumnSettingsChanged="onGridColumnSettingsChanged"/>
          </div>
        </div>
        <div class="modal-footer col-lg-12">
          <div class="form-group row justify-content-end">
            <div class="col-lg-8">
              <button class="modal-default-button btn btn-outline-primary mr-5" @click="OK()">{{$t("common.apply")}}</button>
            </div>
            <div class="col-lg-4">
              <button class="modal-default-button btn btn-outline-secondary mr-5" @click="close()">{{$t("common.close")}}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import GridFilterColumnList
  from "~/pages/salary-calculator/sc-work-reports/partials/Filters/Partials/GridFilterColumnList/GridFilterColumnList.vue";
export default {
  name: 'GridColumnListSettingsDialog',
  props: ['show', 'data'],
  data: function () {
    return {
      modalData: this.data,
      error: '',
      columns: [],
    };
  },
  components: {
    GridFilterColumnList,
  },
  methods: {
    close: function () {
      this.$emit('close');
    },
    OK () {
      this.close();
    },
    onGridColumnSettingsChanged(data) {
      let changedItems = Object.assign([], this.modalData.headers);
      changedItems.splice(
        changedItems.indexOf(
          changedItems.find(
            h => h.key === data.key
          )
        ), 1, data
      );

      this.$emit('onGridColumnSettingsChanged', changedItems);
    }
  },
  watch: {
    data: {
      handler: function(newVal) {
        this.modalData = newVal;
      },
      deep: true,
    },
    show: function(newVal) {
      if (newVal) {
        //load selected
      }
    },
  },
}
</script>

<style scoped>
* {
  box-sizing: border-box;
}
.modal-header {
  display: flex;
  flex-direction: column;
}

.modal-container {
  width: 60%;
}

.unit-user-type span {
  padding: 10px;
  border-width: 1px;
  border-style: solid;
}

.bb {
  border-bottom: 1px #ccc solid;
  font-weight: bold;
}
</style>
