const getters = {
    smReceptionTransaction: state => state.sm_reception_transaction,
    smSalesTransaction: state => state.sm_sales_transaction,
    smConsumTransaction: state => state.sm_consum_transaction,
    smTransferTransaction: state => state.sm_transfer_transaction,
    smTransactions: state => state.sm_transactions,
    smStockMovements: state => state.sm_stock_movements,
    smStockMovementFilters: state => state.sm_stock_movement_filters,
    smTransactionFilters: state => state.sm_transaction_filters,
    smTransactionTypes: state => state.sm_transaction_types,
    smStockMovementTypes: state => state.sm_stock_movement_types,
}

export default getters;
