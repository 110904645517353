<template>
  <card :title="title" id="details" v-if="canEdit || canDelete" v-cloak>
    <span class="action_buttons">
      <img src="~@/assets/images/desktop/icons/controls/plus.svg" class="icon-svg" :title="$t('common.create_new', { name: name })" @click="newObject()"/>
    </span>
    <div class="row">
      <div class="form-group col-sm-12 col-lg-8 text-left">
        <label class="control-label">{{ name+' '+$t('common.name') }}</label>
        <input type="text" v-model="data.name" :placeholder='$t("common.name")'
               :class="{ 'field-error' : this.$v.data.name.$error }"
               class="form-control"/>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-8">
        <div class="row">
          <div class="col form-group">
            <label class="control-label">{{$t("salary_calculator.bonus.type")}}</label>
            <b-form-select
              v-model="data.type"
              class="form-control"
              :class="{ 'field-error' : this.$v.data.type.$error }"
              :options="bonus_type_list"></b-form-select>
          </div>
          <div class="form-group col-sm text-left" v-if="conditionalBonus">
            <label class="control-label">{{ conditionalBonus.condition ? conditionalBonus.condition.label : $t('common.condition') }}</label>
            <input type="text" v-model="data.condition" :placeholder="conditionalBonus.condition ? conditionalBonus.condition.placeholder : $t('common.condition')"
                   :class="{ 'field-error' : this.$v.data.condition.$error }"
                   v-on:keydown="onlyNumbers"
                   class="form-control"/>
          </div>
          <div class="form-group col-sm text-left" v-if="conditionalBonus2">
            <label class="control-label">{{ conditionalBonus.condition2 ? conditionalBonus.condition2.label : $t('common.condition') }}</label>
            <input type="text" v-model="data.condition2" :placeholder="conditionalBonus2.condition2 ? conditionalBonus.condition2.placeholder : $t('common.condition')"
                   :class="{ 'field-error' : this.$v.data.condition2.$error }"
                   v-on:keydown="onlyNumbers"
                   class="form-control"/>
          </div>
          <div class="form-group col-sm text-left" v-if="numericBonus">
            <label class="control-label">{{ $t('common.value') }}</label>
            <input type="text" v-model="data.value" :placeholder='$t("common.value")'
                   :class="{ 'field-error' : this.$v.data.value.$error }"
                   v-on:keydown="onlyPrice"
                   class="form-control"/>
          </div>
          <div class="form-group col-sm text-left" v-if="customBonus">
            <label class="control-label">{{ $t('common.type') }}</label>
            <b-form-select
              v-model="data.custom_bonus_type"
              class="form-control"
              :options="custom_bonus_type_list"></b-form-select>
          </div>
        </div>
        <div class="row pt-3">
          <div class="form-group col-lg-4" v-if="calculationTypeHour">
            <b-form-checkbox switch size="lg"
                             v-model="calculation_type_hour"
                             class="mr-3 cbc">
              {{ calculationTypeHour.name }}
            </b-form-checkbox>
          </div>
          <div class="form-group col-lg-4" v-if="calculation_type_salary">
            <b-form-checkbox switch size="lg"
                             class="cbc"
                             v-model="calculation_type_salary">
              {{ calculationTypeSalary.name }}
            </b-form-checkbox>
          </div>
          <div class="form-group col-lg-4">
            <b-form-checkbox switch size="lg"
                             v-model="is_global"
                             @change="onIsGlobalChanged">
              {{ is_global ? $t('salary_calculator.bonus.is_global') : $t('salary_calculator.bonus.is_personal') }}
            </b-form-checkbox>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="form-group col-sm-12 col-lg-8 mt-4">
        <b-form-checkbox switch size="lg"
                         v-model="is_active"
                         @change="onIsActiveChanged">
          {{ is_active ? $t('common.active') : $t('common.inactive') }}
          </b-form-checkbox>
      </div>
    </div>

    <agro-action-buttons
      :data="data"
      :can-edit="canEdit"
      :can-delete="canDelete"
      @onSave="save"
      @onSaveAndNew="saveAndNew"
      :save-and-new="true"
      @onDelete="confirmDelete"
      @onGotoList="gotoList"
    ></agro-action-buttons>

  </card>
  <div v-else>
    <forbidden></forbidden>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import {
  buildDropdownOptions,
  only_price,
  only_numbers
} from '@/helpers/common'
import required from "vuelidate/src/validators/required";
import requiredIf from "vuelidate/src/validators/requiredIf";
import {canDelete, canEdit, permissions, permissionTypes} from "@/helpers/Permissions";
import * as actions from '@/helpers/generalActions'
import AgroActionButtons from "@/components/AgroActionButtons/index";
import Forbidden from "@/pages/errors/Forbidden";

export default {
  name: 'SCBonusDetails',
  components: {Forbidden, AgroActionButtons},
  data() {
    return {
      data: {},
      is_global: true,
      is_active: true,
      bonus_types: this.bonusTypes ? this.bonusTypes : [],
      bonus_calculation_types: this.bonusCalculationTypes ? this.bonusCalculationTypes : [],
      calculation_type_hour: true,
      calculation_type_salary: false
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
      users: 'user/users',
      bonuses: 'scBonus/scBonuses',
      bonusTypes: 'scBonus/scBonusTypes',
      bonusCalculationTypes: 'scBonus/scBonusCalculationTypes'
    }),
    name() {
      return this.$t('salary_calculator.bonus.title')
    },
    config() {
      return {
        permission: permissions.SC_BONUS,
        permission_type: permissionTypes.module,
        get: this.get,
        create: this.create,
        update: this.update,
        delete: this.delete,
        list_url: '/salary-calculator/sc-bonus',
        confirm_delete: true,
      }
    },
    actions() {
      return actions;
    },
    canEdit() {
      return canEdit(this);
    },
    canDelete() {
      return canDelete(this);
    },
    title() {
      return (this.data.uuid ? this.$t('common.edit') : this.$t('common.new'))+' '+this.name;
    },
    bonus_type_list() {
      return buildDropdownOptions(Object.assign([], this.bonus_types), {
        value: 'key',
        text: 'name',
      }, {
        value: null,
        text: '-',
      });
    },
    custom_bonus_type_list() {
      let list = [];
        Object.assign([], this.bonus_types).map((item) => {
          if(item.key === 'fixed' || item.key === 'percent') {
              list.push({
                value: item.key,
                text: item.name
              });
          }
        });

        return list;
    },
    numericBonus() {
      return true;
    },
    customBonus() {
      return this.bonus_types.find(bt => bt.key === this.data.type && bt.custom_type);
    },
    conditionalBonus() {
      return this.bonus_types.find(bt => bt.key === this.data.type && bt.condition);
    },
    conditionalBonus2() {
      return this.bonus_types.find(bt => bt.key === this.data.type && bt.condition2);
    },
    calculationTypeHour() {
      return this.findCalculationType('hour');
    },
    calculationTypeSalary() {
      return this.findCalculationType('salary');
    },
  },
  methods: {
    ...mapActions({
      getBonusTypes: 'scBonus/getSCBonusTypes',
      getBonusCalculationTypes: 'scBonus/getSCBonusCalculationTypes',
      get: 'scBonus/getSCBonus',
      create: 'scBonus/createSCBonus',
      update: 'scBonus/updateSCBonus',
      delete: 'scBonus/deleteSCBonus'
    }),
    findCalculationType(key) {
      return Object.keys(this.bonusCalculationTypes).map((key) => {
        return this.bonusCalculationTypes[key];
      }).find(bt => bt.key === key);
    },
    onlyPrice(event) {
      return only_price(event);
    },
    onlyNumbers(event) {
      return only_numbers(event);
    },
    loadData() {
      let vm = this;
      return this.actions.getData(this, (data) => {
        vm.$set(vm.data, 'type', null);
        vm.$set(vm.data, 'custom_bonus_type', 'percent');
        vm.$set(vm.data, 'calculation_type', vm.calculationTypeHour?.key);
        vm.$set(vm,'is_global', true);
        vm.$set(vm,'is_active', true);
      }, (data) => {
        vm.$set(vm,'is_global', data.is_global === 1);
        vm.$set(vm,'is_active', data.is_active === 1);
        vm.$set(vm, 'calculation_type_hour', data.calculation_type === 'hour');
        vm.$set(vm, 'calculation_type_salary', data.calculation_type === 'salary');
      });
    },
    gotoList() {
      return actions.gotoList(this);
    },
    confirmDelete() {
      return this.actions.confirmDelete(this);
    },
    save() {
      return this.actions.save(this, this.config.list_url);
    },
    saveAndNew() {
        return this.actions.saveAndNew(this, 'add');
    },
    newObject() {
      return this.actions.newObject(this);
    },
    onIsGlobalChanged(val) {
      this.data.is_global = val ? 1 : 0;
    },
    onIsActiveChanged(val) {
      this.data.is_active = val ? 1 : 0;
    },
  },
  mounted() {
    this.getBonusTypes();
    this.getBonusCalculationTypes();
    this.loadData();
    this.actions.handleParams(this);
  },
  watch: {
    bonusTypes: {
      handler: function(newVal) {
        this.bonus_types = Object.keys(newVal).map((key) => {
          return newVal[key];
        });
      },
      deep: true,
    },
    bonusCalculationTypes: {
      handler: function(newVal) {
        this.bonus_calculation_types = Object.keys(newVal).map((key) => {
          return newVal[key];
        });
      },
      deep: true,
    },
    $route: {
      handler: function(newVal) {
        this.actions.handleParams(this);
      },
      deep: true,
    },
    permission: {
      handler: function(newVal) {
        this.permissionModule = newVal;
      },
      deep: true,
    },
    numericBonus: function(newVal) {
      if(!newVal) {
          this.data.value = null;
      }
    },
    calculation_type_hour: function(newVal) {
        this.calculation_type_salary = !newVal;

        if(newVal) {
          this.$set(this.data, 'calculation_type', this.calculationTypeHour.key);
        }
    },
    calculation_type_salary: function(newVal) {
      this.calculation_type_hour = !newVal;

      if(newVal) {
        this.$set(this.data, 'calculation_type', this.calculationTypeSalary.key);
      }
    }
  },
  validations(){
    return {
      data: {
        name: {
          required,
        },
        type: {
          required,
        },
        value: {
          required: requiredIf(function(pw) {
            return this.numericBonus;
          })
        },
        condition: {
          required: requiredIf(function(pw) {
            return this.conditionalBonus;
          })
        },
        condition2: {
          required: requiredIf(function(pw) {
            return this.conditionalBonus2;
          })
        },
      }
    }
  }
}
</script>

<style scoped>
.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: red;
  background-color: red;
}
</style>
