import axios from '@/plugins/axios'
import * as types from "../mutation-types";

const actions = {
  getUsers({commit}, payload) {
    axios.get('/users', {
      params: payload,
    }).then((response) => {
        commit(types.SET_USERS, response.data.data);
        commit("common/"+types.SET_PAGINATION, response.data.meta, { root: true });
      console.log('get user action resp', response.data);
    }).catch((e) => {
        //commit("common/"+types.SET_ERROR_MESSAGE, e, { root: true });
    });
  },
  getUser({commit}, payload) {
      return new Promise((resolve, reject) => {
        axios.get('/users/'+payload.uuid, {
          params: {},
        }).then((response) => {
          commit(types.SET_USER_DATA, response.data.data);
          resolve(response.data.data);
        }).catch((e) => {
          reject(e);
        });
      })
  },
  createUser({commit}, payload) {
      return new Promise((resolve, reject) => {
          axios.post('/users', payload).then((response) => {
            commit(types.SET_USER_DATA, response.data.data);
            resolve(response.data);
          }).catch((e) => {
            reject(e.response.data);
          });
      })
  },
  updateUser({commit}, payload) {
      return new Promise((resolve, reject) => {
          axios.put('/users/'+payload.uuid, payload).then((response) => {
            commit(types.SET_USER_DATA, response.data.data);
            resolve(response.data);
          }).catch((e) => {
            reject(e.response.data);
          });
      })
  },
  changePassword({commit}, payload) {
    return new Promise((resolve, reject) => {
      axios.put('/users/'+payload.uuid+'/password', payload).then((response) => {
        resolve(response.data);
      }).catch((e) => {
        reject(e.response.data);
      });
    })
  },
  deleteUser({commit}, payload) {
      return new Promise((resolve, reject) => {
        axios.delete('/users/'+payload.uuid).then((response) => {
          commit(types.SET_USER_ITEM, response.data.data);
          resolve(response.data);
        }).catch((e) => {
          reject(e);
        });
      })
  },
}

export default actions;
