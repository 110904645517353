import * as api from '../api/index'
import * as endpoints from '../api/endpoints'
import * as types from "../mutation-types";

const actions = {
    getLayers({commit}, payload) {
      commit(types.SET_LAYERS_LOADING, true);
         api.fetch(endpoints.LAYERS, payload, commit,(response) => {
          commit(types.SET_LAYERS, response.data.data);
          commit(types.SET_LAYER_WORKS, response.data.works);
          commit(types.SET_LAYERS_LOADING, true);
          commit("common/"+types.SET_PAGINATION, response.data.meta, { root: true });
        }).then(r => {commit(types.SET_LAYERS_LOADING, false);})
        .catch(e => {commit(types.SET_LAYERS_LOADING, false);})
        .finally(() => {commit(types.SET_LAYERS_LOADING, false);});

    },

    getLayersByWorkAndDate({commit}, payload){
      return api.fetchFull('/work-parcels', payload, commit,(response) => {

      });
    },

    getByUUID({commit}, payload) {
      return api.fetch(endpoints.LAYERS + "/" + payload.uuid, payload,  commit,(response) => {
        
      });
    },

    createLayer({commit}, payload) {
      return api.post(endpoints.LAYERS, payload,  commit,(response) => {
        
      });
    },

    updateLayerCoordinates({commit}, payload) {
      return api.put(endpoints.LAYERS + "/coordinates/" + payload.uuid, payload,  commit,(response) => {
        
      });
    },

    previewParcelName({commit}, payload) {
      return api.fetch(endpoints.PREVIEW_PARCEL_NAME, payload,  commit,(response) => {
        
      });
    },

    getLayerWork({commit}, payload) {
      return api.fetch("/layer-works", payload,  commit,(response) => {
        
      });
    },
}

export default actions;
