<template>
  <div class="edit-role-item row" :class="className">
    <div class="col-lg-4">
      <label class="control-label text-primary">
        <input type="checkbox" v-model="module"/>
      </label>
    </div>
    <div class="col-lg-2">
      <label>
        <input type="checkbox" v-model="read" class="mr-2"
               :disabled="!module"/>
      </label>
    </div>
    <div class="col-lg-3">
      <label>
        <input type="checkbox" v-model="write" class="mr-2"
               :disabled="!module"/>
      </label>
    </div>
    <div class="col-lg-2">
      <label>
        <input type="checkbox" v-model="execute"
               class="mr-2" :disabled="!module"/>
      </label>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RoleItemHeader',
  props: ['data', 'className'],
  data() {
    return {
      module: false,
      read: false,
      write: false,
      execute: false,
    }
  },
  watch: {
    module: function(newVal) {
        this.$emit('onModuleChanged', newVal);
    },
    read: function(newVal) {
      this.$emit('onReadChanged', newVal);
    },
    write: function(newVal) {
      this.$emit('onWriteChanged', newVal);
    },
    execute: function(newVal) {
      this.$emit('onExecuteChanged', newVal);
    },
    data: {
      handler: function(newVal) {
        this.module = newVal.m;
        this.read = newVal.r;
        this.write = newVal.w;
        this.execute = newVal.x;
      },
      deep: true,
    }
  }
}
</script>
