import * as types from "../mutation-types";

const mutations = {
  [types.SET_FERTILITY_PRODUCTS_LIGHT]: (state, payload) => {
    state.fertilityProductsLight = payload;
  },
  
}

export default mutations;
