import * as types from "../../mutation-types";

const mutations = {
    [types.SET_SM_PRODUCT_TYPES]: (state, payload) => {
        state.sm_product_types = payload;
    },
    [types.SET_SM_PRODUCT_TYPE]: (state, payload) => {
        state.sm_product_type = payload;
    },
    [types.ADD_SM_PRODUCT_TYPE_ITEM]: (state, payload) => {
        state.sm_product_types.push(payload);
    },
    [types.UPDATE_SM_PRODUCT_TYPE_ITEM]: (state, payload) => {
        state.sm_product_types.splice(state.sm_product_types.indexOf(
            state.sm_product_types.find(c => c.uuid === payload.uuid)
        ), 1, payload);
    },
    [types.DELETE_SM_PRODUCT_TYPE_ITEM]: (state, payload) => {
        state.sm_product_types.splice(state.sm_product_types.indexOf(
            state.sm_product_types.find(c => c.uuid === payload.uuid)
        ), 1);
    },
    [types.SET_SM_PRODUCT_TYPE_FILTERS]: (state, payload) => {
        state.sm_product_type_filters = payload;
    },
}

export default mutations;
