import * as types from './mutation-types'
import * as api from './api/index'
import * as endpoints from './api/endpoints'

// state
export const state = () => {
    return {
      measureUnits:[]
    }
  }
  
  // getters
  export const getters = {
    measureUnits: state => state.measureUnits,
  }
  
  // mutations
  export const mutations = {
    [types.SET_FITO_MEASURE_UNITS]: (state, payload) => {
        state.measureUnits = payload;
      },
  }
  
  // actions
  export const actions = {
    getFitoMeasureUnits({ commit }, payload) {
        return api.fetch(endpoints.FITO_MEASURE_UNITS, payload, commit, (response) => {
          commit(types.SET_FITO_MEASURE_UNITS, response.data);
        });
      },
  }