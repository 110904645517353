import * as types from "../../mutation-types";

const mutations = {
  [types.SET_SC_HOLIDAYS]: (state, payload) => {
    state.sc_holidays = payload;
  },
  [types.SET_SC_HOLIDAY]: (state, payload) => {
    state.sc_holiday = payload;
  },
  [types.ADD_SC_HOLIDAY_ITEM]: (state, payload) => {
    state.sc_holidays.push(payload);
  },
  [types.UPDATE_SC_HOLIDAY_ITEM]: (state, payload) => {
    state.sc_holidays.splice(state.sc_holidays.indexOf(
      state.sc_holidays.find(c => c.uuid === payload.uuid)
    ), 1, payload);
  },
  [types.DELETE_SC_HOLIDAY_ITEM]: (state, payload) => {
    state.sc_holidays.splice(state.sc_holidays.indexOf(
      state.sc_holidays.find(c => c.uuid === payload.uuid)
    ), 1);
  },
  [types.SET_SC_HOLIDAY_FILTERS]: (state, payload) => {
    state.sc_holiday_filters = payload;
  },
}

export default mutations;
