<template>
  <div class="daily-grid-row" :class="thScrollClass" :id="rowID">
    <div :class="[!thScrollClass ? 'overlay' : null]" class="overlay-container">
      <one-col :infinite-scroll="isInfiniteScrollEnabled" :title="index === 0 ? $t('salary_calculator.reports.table.work_date') : null" :value="workDate" ref="work_date" container-class="sticky-column"/>
      <one-col :infinite-scroll="isInfiniteScrollEnabled" :title="index === 0 ? $t('salary_calculator.reports.table.employee_name') : null" :value="employeeName" ref="employee_name" container-class="sticky-column ml-210"/>
    </div>
    <one-col :infinite-scroll="isInfiniteScrollEnabled" :title="index === 0 ? $t('salary_calculator.reports.table.role') : null" :value="employeeRole" ref="employee_role" container-class="ml-420 sh"/>
    <multi-row-column :infinite-scroll="isInfiniteScrollEnabled" :title="index === 0 ? $t('salary_calculator.reports.table.work_name') : null" :data="works" ref="work_name" container-class="sh"/>
    <multi-row-column :infinite-scroll="isInfiniteScrollEnabled" :title="index === 0 ? $t('salary_calculator.reports.table.work_hours') : null" date-format="HH:mm" :data="workHours" ref="work_hours" container-class="sh"/>
    <multi-row-column :infinite-scroll="isInfiniteScrollEnabled" :title="index === 0 ? $t('salary_calculator.reports.table.work_group') : null" :data="workGroups" ref="work_group_name" container-class="sh"/>
    <multi-row-column :infinite-scroll="isInfiniteScrollEnabled" :title="index === 0 ? $t('salary_calculator.reports.table.hectare') : null" :data="hectareWorked" ref="hectare_worked" :decimal-precision="2" container-class="sh"/>
    <multi-row-column :infinite-scroll="isInfiniteScrollEnabled" :title="index === 0 ? $t('salary_calculator.reports.table.idle_engine_time') : null" date-format="HH:mm" :data="inactiveWorkHours" ref="inactive_hours" container-class="sh"/>
    <multi-row-column :infinite-scroll="isInfiniteScrollEnabled" :title="index === 0 ? $t('salary_calculator.reports.table.tariff') : null" :data="tariff" ref="tariff" container-class="sh"/>
    <multi-row-column :infinite-scroll="isInfiniteScrollEnabled" :title="index === 0 ? $t('salary_calculator.reports.table.cost') : null" :data="cost" ref="cost" container-class="sh"/>
    <one-col :infinite-scroll="isInfiniteScrollEnabled" :title="index === 0 ? $t('salary_calculator.reports.table.work_hours_total') : null" date-format="HH:mm" :value="sumWorkHoursTotal" :decimal-precision="2" ref="work_hours_total" container-class="sh"/>
    <one-col :infinite-scroll="isInfiniteScrollEnabled" :title="index === 0 ? $t('salary_calculator.reports.table.supplimentar_hours') : null" date-format="HH:mm" :value="sumSupplimentarHoursTotal" :decimal-precision="2" ref="supplimentar_hours_total" container-class="sh"/>
    <one-col :infinite-scroll="isInfiniteScrollEnabled" :title="index === 0 ? $t('salary_calculator.reports.table.hectare_data_total') : null" :value="sumHectareWorkedTotal" :decimal-precision="2" ref="hectare_worked_total" container-class="sh"/>
    <one-col :infinite-scroll="isInfiniteScrollEnabled" :title="index === 0 ? $t('salary_calculator.reports.table.holiday_day') : null" :value="isHoliday" ref="is_holiday" container-class="sh"/>
    <one-col :infinite-scroll="isInfiniteScrollEnabled" :title="index === 0 ? $t('salary_calculator.reports.table.check_in') : null" :value="checkInTime" ref="check_in_time" container-class="sh"/>
    <one-col :infinite-scroll="isInfiniteScrollEnabled" :title="index === 0 ? $t('salary_calculator.reports.table.check_out') : null" :value="checkOutTime" ref="check_out_time" container-class="sh"/>
    <one-col :infinite-scroll="isInfiniteScrollEnabled" :title="index === 0 ? $t('salary_calculator.reports.table.total_hours') : null" :value="sumTotalHours" date-format="HH:mm" ref="total_hours" container-class="sh"/>
    <one-col :infinite-scroll="isInfiniteScrollEnabled" :title="index === 0 ? $t('salary_calculator.reports.table.total_inactive_hours') : null" :value="sumTotalNonProductiveHours" date-format="HH:mm" ref="total_non_productive_hours" container-class="sh"/>
    <one-col :infinite-scroll="isInfiniteScrollEnabled" :title="index === 0 ? $t('salary_calculator.reports.table.salary') : null" :value="sumSalaryTotal" :decimal-precision="2" ref="salary_total" container-class="sh"/>
  </div>
</template>

<script>
import OneCol from "~/pages/salary-calculator/sc-work-reports/partials/DailyGrid/Columns/OneCol.vue";
import MultiRowColumn from "~/pages/salary-calculator/sc-work-reports/partials/DailyGrid/Columns/MultiRowColumn.vue";
import {mapGetters} from "vuex";

export default {
  name: 'DailyGridRow',
  components: {MultiRowColumn, OneCol},
  props: [
    'data',
    'index',
    'headers',
    'infiniteScroll',
    'tableID'
  ],
  data() {
    return {
      row: this.data ?? {},
      originalHeaders: [],
      perPage: this.limit ? this.limit : 25,
      is_loading: this.loading ? this.loading : false,
      windowPosition: {top: 0, bottom: 0},
      stickyHeader: false,
      scrollingLoad: false,
      gridCellScrollingPosition: { scrollLeft: 0 },
    }
  },
  computed: {
    ...mapGetters({
        scDailyGridColumns: 'scWorkPivot/scDailyGridColumns',
    }),
    isInfiniteScrollEnabled() {
      return this.infiniteScroll && this.index === 0;
    },
    workDate() {
      return this.row.work_date;
    },
    summary() {
      return this.row.summary;
    },
    employeeName() {
      return this.row.employee_name;
    },
    employeeRole() {
      return this.row.employee_role;
    },
    works() {
      return this.row.works.map((item) => {
        return {
          value: item.work_name,
        }
      })
    },
    workHours() {
      return this.row.works.map((item) => {
        return {
          value: item.calculated_active_work_hours,
        }
      })
    },
    workGroups() {
      return this.row.works.map((item) => {
        return {
          value: item.work_group_name,
        }
      })
    },
    hectareWorked() {
      return this.row.works.map((item) => {
        return {
          value: item.hectare_worked,
        }
      })
    },
    inactiveWorkHours() {
      return this.row.works.map((item) => {
        return {
          value: item.inactive_work_hours,
        }
      })
    },
    tariff() {
      return this.row.works.map((item) => {
        return {
          value: item.tariff,
        }
      })
    },
    cost() {
      return this.row.works.map((item) => {
        return {
          value: item.cost,
        }
      })
    },
    sumWorkHoursTotal() {
      return this.summary.active_work_hours;//calculated_active_work_hours
    },
    sumSupplimentarHoursTotal() {
      return this.summary.total_supplimentar_hours;
    },
    sumHectareWorkedTotal() {
      return this.summary.total_hectare_worked;
    },
    isHoliday() {
      return this.row.is_holiday === 1 ? this.$t('common.yes') : this.$t('common.no');
    },
    checkInTime() {
      return this.summary.check_in_datetime;
    },
    checkOutTime() {
      return this.summary.check_out_datetime;
    },
    sumTotalHours() {
      return this.summary.total_work_hours;
    },
    sumTotalNonProductiveHours() {
      return this.summary.total_non_productive_hours;
    },
    sumSalaryTotal() {
      return this.summary.salary;
    },
    thScrollClass() {
      if(this.stickyHeader && this.index === 0) {
        return [
          'fixed-top',
          'inf-bg-light',
          'mr80',
          'thLeftPad',
          'fixed-header-active'
        ];
      }
    },
    rowID() {
      if (this.isInfiniteScrollEnabled) {
        return 'fixed-header-active';
      }
      return 'row-'+Math.random().toString().replace('.', '');
    },
    rowScrollPosition() {
      let bounds = document.querySelector("#"+this.rowID).getBoundingClientRect();
      return {
        top: bounds.top,
        left: bounds.left,
        bottom: (bounds.bottom + bounds.height),
        height: bounds.height,
      };
    },
    tableScrollPosition() {
      let bounds = document.querySelector("#"+this.tableID).getBoundingClientRect();
      return {
        top: bounds.top,
        left: bounds.left,
        bottom: (bounds.bottom + bounds.height),
        height: bounds.height,
      };
    },
  },
  methods: {
    onScroll(e) {
      this.windowPosition = {
        top: window.top.scrollY, /* or: e.target.documentElement.scrollTop */
        left: window.top.scrollX,
        bottom: window.outerHeight,
      };
    },
    handleAutoLoad() {
      this.$emit('onScroll', {
        windowScrollPosition: this.windowPosition,
        rowScrollPosition: this.rowScrollPosition,
        tableScrollPosition: this.tableScrollPosition,
        scrollableColsPosition: this.gridCellScrollingPosition.scrollLeft,
      });
    },
  },
  mounted() {
    let vm = this;
    window.addEventListener("scroll", this.onScroll, true);

    let scrollableHeader = document.querySelector('.sh');
    const scrollContent = document.querySelector('.daily-grid');
    let gridPad = 420;
    scrollContent.addEventListener('scroll', () => {
      vm.gridCellScrollingPosition = scrollContent;
      let fixedHeaderActive = document.querySelector('.fixed-header-active');

      if (vm.isInfiniteScrollEnabled && fixedHeaderActive) {
        scrollableHeader.setAttribute('style', 'margin-left: '+(gridPad-scrollContent.scrollLeft)+'px !important');
      }

      if (!fixedHeaderActive) {
        scrollableHeader.setAttribute('style', 'margin-left: 420px !important');
      }

    }, {passive: true});
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.onScroll, true);
  },
  watch: {
    data: {
      handler: function(newVal) {
        this.row = newVal;
      },
      deep: true,
      immediate: true,
    },
    headers: {
      handler: function(newVal) {
        this.originalHeaders = newVal;
      },
      deep: true,
      immediate: true,
    },
    scDailyGridColumns: {
      handler: function(newVal) {
        let vm = this;
        this.originalHeaders.map((item) => {
          if (newVal.indexOf(item) === -1) {
            vm.$refs[item.key].hide();
          } else {
            if (vm.$refs[item.key]) {
              vm.$refs[item.key].show();
            }
          }
        });
      },
      deep: true,
      immediate: true,
    },
    windowPosition: {
      handler: function (pos) {
        this.stickyHeader = (this.infiniteScroll && pos.top > 300);

        if(this.stickyHeader && this.rowScrollPosition.top < pos.top) { //and reach bottom of grid
          this.handleAutoLoad();
        }
      },
      deep: true,
    },
  }
}
</script>

<style lang="scss" scoped>
  .daily-grid-row {
    display: flex;
  }

  .overlay {
    background-color: white;
    opacity: 1;
    position: absolute;
    width: 400px;
    z-index: 99;
    height: 164px;
    /* -webkit-box-shadow: 17px 2px 15px -10px rgb(224 224 224 / 53%);*/
    /* box-shadow: 17px 2px 15px -10px rgb(224 224 224 / 53%);*/
  }
</style>
