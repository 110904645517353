import * as types from "../../mutation-types";

const mutations = {
    [types.SET_SM_RECEPTION_TRANSACTION]: (state, payload) => {
        state.sm_reception_transaction = payload;
    },
    [types.SET_SM_SALES_TRANSACTION]: (state, payload) => {
      state.sm_sales_transaction = payload;
    },
    [types.SET_SM_CONSUM_TRANSACTION]: (state, payload) => {
      state.sm_consum_transaction = payload;
    },
    [types.SET_SM_TRANSFER_TRANSACTION]: (state, payload) => {
      state.sm_transfer_transaction = payload;
    },
    //transactions
    [types.SET_SM_TRANSACTIONS]: (state, payload) => {
      state.sm_transactions = payload;
    },
    [types.UPDATE_TRANSACTION]: (state, payload) => {
      let index = state.sm_transactions.findIndex(transaction => transaction.uuid === payload.uuid);
      if(index !== -1)
        state.sm_transactions[index] = payload;
    },
    [types.SET_SM_STOCK_MOVEMENTS]: (state, payload) => {
      state.sm_stock_movements = payload;
    },
    [types.SET_SM_TRANSACTION_FILTERS]: (state, payload) => {
      state.sm_transaction_filters = payload;
    },
    [types.SET_SM_STOCK_MOVEMENT_FILTERS]: (state, payload) => {
      state.sm_stock_movement_filters = payload;
    },
    [types.SET_SM_TRANSACTION_TYPES]: (state, payload) => {
      state.sm_transaction_types = payload;
    },
    [types.SET_SM_STOCK_MOVEMENT_TYPES]: (state, payload) => {
      state.sm_stock_movement_types = payload;
    },
}

export default mutations;
