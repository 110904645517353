import {getRange} from "@/helpers/common";
import store from "@/store";
import moment from 'moment'

export const days = {
    en: [
      '', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday',
    ],
    hu: [
      '', 'Hétfő', 'Kedd', 'Szerda', 'Csütörtök', 'Péntek', 'Szombat', 'Vasárnap',
    ],
    ro: [
      '', 'Luni', 'Marti', 'Miercuri', 'Vineri', 'Sambata', 'Duminica'
    ],
}

export function getDays(localeParam = null) {
  let locale = localeParam !== null ? localeParam : 'en';
  return getRange(1, 7).map((day) => {
    return {
        uuid: day,
        name: days[locale][day]
      };
  });
}

export function getHours() {
  return getRange(0,23);
}

export function getMinutes() {
  return getRange(0,59);
}

export function today() {
  return moment(new Date()).format("YYYY-MM-DD");
}

export function getCalculateDateDaysAgo(days_ago) {
  let today = new Date();
  return new Date(today.setDate(today.getDate() - days_ago));
}

export function convertHoursToTimestamp(work_hours) {
  let hour = 0;
  let minute = 0;

  if(work_hours && work_hours > 0) {
    let hourMinutes = String(work_hours).split('.');

    if(hourMinutes.length === 2) {
      hour = hourMinutes[0];
      let padMinute = String(hourMinutes[1]).padEnd(2, '0');

      if(padMinute > 0) {
        minute = (60 / (100 / padMinute)).toFixed();
      }

    } else {
      hour = hourMinutes[0];
      minute = 0;
    }

    hour = String(hour).padStart(2, '0');
    minute = String(minute).padStart(2, '0');
  }

  return {
    hour: hour,
    minute: minute,
  };
}
