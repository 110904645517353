<template>
  <thead>
    <row v-for="(th, index) in rows"
         :data="th"
         :key="th.uuid"
         :index="index"
         :subtitle="subtitle"
         :infiniteScroll="infiniteScroll"
         @onSortBy="onSortBy"
    ></row>
  </thead>
</template>

<script>
import Row from "./Row";

export default {
  props: ['data', 'infiniteScroll', 'subtitle'],
  name: 'TableHead',
  data() {
    return {
      rows: this.data ? this.data : [],
    }
  },
  components: {
    Row,
  },
  methods: {
    onSortBy(data) {
      this.$emit('onSortBy', data);
    }
  },
  watch: {
    data: {
      handler: function(newVal) {
          this.rows = newVal;
      },
      deep: true,
    },
  }
}
</script>
