import * as types from "../mutation-types";

const mutations = {
  [types.SET_DEPARTMENT_TYPES]: (state, payload) => {
    state.department_types = payload;
  },
  [types.SET_DEPARTMENT_FILTER_TYPES]: (state, payload) => {
    state.department_filter_types = payload;
  },
}

export default mutations;
