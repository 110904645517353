<!-- template for the modal component -->
<template type="x/template">
  <transition name="modal" id="contact-info-dialog">
    <div class="modal-mask" v-show="show">
      <div class="modal-container" @click.stop>
        <div class="modal-header">
          <h3>{{ title }}</h3>
        </div>
        <div class="modal-body">
          <div class="row">
            <department-list :data="departments"
                             :only-view="true"
                             :show-selector="true"
                             :singleSelect="singleSelect"></department-list>
          </div>
        </div>
        <div class="modal-footer col-lg-12">
          <div class="form-group row justify-content-end">
            <div class="col-lg-8">
              <button class="modal-default-button btn btn-outline-primary mr-5" @click="OK()">{{$t("common.apply")}}</button>
            </div>
            <div class="col-lg-4">
              <button class="modal-default-button btn btn-outline-secondary mr-5" @click="close()">{{$t("common.close")}}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import DepartmentList from "~/pages/my-company/departments/partials/DepartmentList.vue";
import {mapActions, mapGetters} from "vuex";
export default {
  name: 'AgroFilterDialog',
  props: ['show', 'data', 'singleSelect'],
  data: function () {
    return {
      modalData: this.data,
      error: '',
    };
  },
  components: {
    DepartmentList
  },
  computed: {
    ...mapGetters({
      departments: "department/departmentList",
      user: 'auth/user',
    }),
    title() {
      return this.$t('department_filter_selector');
    },
    selectedDepartments() {
      return Object.assign([], this.departments).filter((item) => {
        return item.selected;
      })
    }
  },
  methods: {
    ...mapActions({
      getDepartments: "department/getDepartments"
    }),
    close: function () {
      this.$emit('close');
    },
    OK () {
      this.$emit('onOK', this.selectedDepartments);
      this.close();
    },
    selectInputDepartments() {
      let vm = this;
      //shared deps
      this.modalData?.departments.map((shDep => {
        let found = vm.departments.find(d => d.uuid === shDep.uuid);
        let original = Object.assign({}, found);
        found.selected = true;
        found.disabled = !Boolean(Object.assign([], vm.user.units).find(u => u.uuid === found.uuid)) && vm.user.is_supearadmin === 0;

        vm.departments.splice(
          vm.departments.indexOf(
            original
          ),
          found
        )
      }));
    }
  },
  watch: {
    data: {
      handler: function(newVal) {
        this.modalData = newVal;
      },
      deep: true,
    },
    show: function(newVal) {
      if (newVal) {
        this.departments.map((item) => {
          item.selected = false;
        });
        this.selectInputDepartments();
      }
    }
  },
  validations() {
    return {
      modalData: {

      },
    }
  },
}
</script>

<style scoped>
* {
  box-sizing: border-box;
}
.modal-header {
  display: flex;
  flex-direction: column;
}

.modal-container {
  width: 60%;
}

.unit-user-type span {
  padding: 10px;
  border-width: 1px;
  border-style: solid;
}

.bb {
  border-bottom: 1px #ccc solid;
  font-weight: bold;
}
</style>
