<template>
  <div class="camera-image-list">
    <CameraImage v-for="image in images"
                 :data="image"
                 :key="getID()"
                 container-class="image-class"
                @onRemove="onImageRemoved"/>
  </div>
</template>

<script>
import {randomID} from "~/helpers/common";
import CameraImage from "@/components/Camera/CameraImage.vue";

export default {
  name: 'CameraImageList',
  components: {CameraImage},
  props: ['data'],
  data() {
    return {
      images: this.data ?? [],
    }
  },
  methods: {
    onImageRemoved(imageData) {
      this.images.splice(
        this.images.indexOf(
          this.images.find(img => img.id === imageData.id)
        ),1
      );
    },
    getID() {
      return randomID();
    }
  },
  watch: {
    data: {
      handler: function(newVal) {
        this.images = newVal;
      },
      deep: true,
    }
  }
}
</script>

<style scoped lang="scss">
.camera-image-list {
  display: inline-flex;
}

.image-class {
  margin-right: 5px;
}
</style>
