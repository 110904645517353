<template type="x/template">
  <transition name="modal" id="rent-filter-dialog">
    <div>
    <DxPopup
      id="dx-dialog"
      width="100vw"
      height="100vh"
      :close-on-outside-click="false"
      :show-close-button="true"
      :visible="showPopup"
      :resize-enabled="true"
      :darg-enabled="true"
      @hidden="$emit('hidden')"
      :wrapper-attr="popupAttributes"
    >
       <template #title>
        <div class="rent_filter_header">
          <div class="left_side">
            <div @click="backButtonEvent" class="back_button">
              <img src="@/assets/images/icons/arrow-back.svg" />
              <div class="back_button_text">Inapoi</div>
              </div>
          </div>
          <div class="right_side">
            <img class="close_button"
              src="@/assets/images/icons/dialog-close.svg"
              @click="buttonCloseEvent"
            />
          </div>
        </div>
      </template>

      <template #content>
        <div class="rent_filter_content">
           <DxTabPanel
            :element-attr="tabPanelAttributes"
            :data-source="filterSteps"
            :loop="false"
            :selected-item="currentItem"
            :selected-index="currentStep"
            :animation-enabled="true"
            :swipe-enabled="false"
            :show-nav-buttons="false"
            @selection-changed="tabSelectionChanged($event)"
          >
            <template #item="{ data: step }">
              <div class="tab_panels_container">
                <div v-show="step == 1" class="title_text">Filtre disponibile</div>
                <div v-show="step == 2" class="title_text">{{title}}</div>
                <div v-show="step == 1" class="rent_steps">
                  <div class="rent_step_item" @click="mainFilterClick('paid')">Contracte plătite</div>
                  <div class="rent_step_item" @click="mainFilterClick('unpaid')">Contracte neplătite</div>
                  <div class="rent_step_item" @click="mainFilterClick('partially_paid')">Contracte plătite parțial</div>
                </div>
                <div v-if="step == 2" class="step_2_container">
                  <search-input ref="searchInputControl" :parentClass="'rent_filter_search'" placeholder="Caută contract" @search="searchByText"></search-input>
                  <contract-list ref="contractistControl" :filterData="filterData" :fillAtMount="false"></contract-list>
                </div>
              </div>
            </template>
           </DxTabPanel>
        </div>
      </template>
    </DxPopup>
 </div>
  </transition>
</template>

<script>

import { DxPopup, DxPosition } from "devextreme-vue/popup";
import DxTabPanel from "devextreme-vue/tab-panel";
import ContractList from '../partials/contractList.vue'
import  SearchInput  from "../../../components/Mobile/searchInput.vue"
import { DxScrollView } from "devextreme-vue/scroll-view";

export default {
    name: "RentFilter",
    props: {
        showPopup: {
          type: Boolean,
          default: false,
        },
    },
    data(){
      return {
       popupAttributes: {
          class: 'rent_filter_dialog_wrapper'
        },
        tabPanelAttributes: {
            class: 'rent_filter_tabs'
        },
        filterSteps: [1,2],
        currentStep: 0,
        currentItem: 1,
        filterData:{
          contract_status: '',
          general_search: ''
        },
        currentPaidCode: ''
      }
    },
    computed: {
      title(){
        switch(this.currentPaidCode){
          case 'paid':
            return 'Contracte plătite';
          break;
          case 'unpaid':
            return 'Contracte neplătite';
          break;
          case 'partial_paid':
            return 'Contracte plătite parțial';
          break;
          default:
            return 'Contracte';
        }
      }
    },
    components: {
        DxPopup, DxPosition, DxTabPanel,
        ContractList,
        SearchInput,
        DxScrollView
    },
    watch: {
      showPopup(newVal){
        if(newVal == false){
          this.resetForm();
        }
      },
      filterData:{
        handler: function (newVal) {

        },
        deep: true,
      }
    },
    methods: {
        backButtonEvent(){
          if(this.currentItem == 1){
            this.$emit("hidden");
          }
          else{
            this.currentStep = this.currentStep - 1;
          }
        },
        buttonCloseEvent(){
          this.$emit("hidden");
        },
        tabSelectionChanged(e) {
          this.currentItem = e.addedItems[0];
          this.currentStep = this.filterSteps.indexOf(this.currentItem);
        },
        mainFilterClick(code){
          this.currentPaidCode = code;
          this.currentStep = this.currentStep + 1;
          //this.$set(this.filterData, 'contract_status', code);
          setTimeout(this.setContractStatus, 100, code);
        },
        setContractStatus(status_code){
          this.$set(this.filterData, 'contract_status', status_code);
        },
        resetForm(){
          this.currentItem = 1;
          this.currentStep = 0;
          if(this.$refs.contractistControl){
            this.$refs.contractistControl.executeFillContract = false;
          }
          this.filterData.contract_status = '';
          if(this.$refs.contractistControl){
            this.$refs.contractistControl.executeFillContract = false;
          }
          this.filterData.general_search = '';
          if(this.$refs.searchInputControl){
            if(this.$refs.contractistControl){
              this.$refs.contractistControl.executeFillContract = false;
            }
            this.$refs.searchInputControl.resetControl();
          }
        },
        searchByText(searchText){
          this.$set(this.filterData, 'general_search', searchText);
        },

    }
}
</script>

<style lang="scss">
  @import "@/assets/scss/rent/rentFilter.scss";
</style>
