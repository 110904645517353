import Button from "devextreme/ui/button";

export function getValidatorGridColumns(
  createParcelCallback,
  getLookupDataSource,
  parcelFullIdentification
) {

  const columns = [
    {
      dataField: "status.status",
      caption: "status",
      allowEditing: false,
      width: 60,
      headerCellTemplate: function (header, info) {
        $(header).css("text-align", "left");
      },
      alignment: "center",
      cellTemplate: function (container, options) {
        const $container = $(container);
        $("<div>").addClass("status-icon").appendTo($container);
        switch (options.data.status.status) {
          case "OK":
            $("<i>").addClass("dx-icon dx-icon-check").appendTo($container.find(".status-icon"));
            break;
          case "ERROR":
            $("<i>").addClass("dx-icon dx-icon-close validator-status-icon").appendTo($container.find(".status-icon"));
            break;
          case "NEED_TO_CHECK":
            $("<i>").addClass("dx-icon dx-icon-info validator-status-icon").appendTo($container.find(".status-icon"));
            break;
          default:
            $("<i>").addClass("dx-icon dx-icon-help validator-status-icon").appendTo($container.find(".status-icon"));
            break;
        }
        $container.find(".status-icon i").attr("title", options.data.status.status);
      },
    },
    {
      dataField: "registration_number",
      caption: "registration_number",
      validationRules: [{ type: "required" }],
    },
    {
      dataField: "department_uuid",
      caption: "department",
      showInColumnChooser: false,
      lookup: {
        dataSource: [],
        valueExpr: "department_uuid",
        displayExpr: "department",
      },
      validationRules: [{ type: "required" }],
      minWidth: 130
    },
    {
      dataField: "start_date",
      caption: "start_date",
      showInColumnChooser: false,
      dataType: "datetime",
      format: "yyyy-MM-dd HH:mm",
      validationRules: [{ type: "required" }],
    },
    {
      dataField: "end_date",
      caption: "end_date",
      showInColumnChooser: false,
      dataType: "datetime",
      format: "yyyy-MM-dd HH:mm",
      validationRules: [{ type: "required" }],
    },
    {
      dataField: "person",
      caption: "person",
      validationRules: [{ type: "required" }],
    },
    {
      dataField: "work_process",
      caption: "work_process",
      showInColumnChooser: false,
      validationRules: [{ type: "required" }],
    },
  ];

  if (parcelFullIdentification) {
    columns.push({
      dataField: "parcel_uuid",
      showInColumnChooser: false,
      visible: false,
    });
    columns.push({
      dataField: "parcel_name",
      caption: "parcel_name",
      fixed: false,
      width: 250,
      cellTemplate: function (container, options) {
        if (!options.data.parcel_uuid) {
          const $container = $(container);
          $("<div>").addClass("add-parcel-button").appendTo($container);
          new Button($container.find(".add-parcel-button")[0], {
            icon: "add",
            hint: "Add Parcel",
            onClick: function () {
              createParcelCallback(options.data);
            },
          });
          $("<span>")
            .text("" + (options.data.parcel_name ?? ""))
            .appendTo($container);
        } else {
          $("<div>").text(options.data.parcel_name).appendTo(container);
        }
      },
      setCellValue(rowData, value) {
        if (!value) {
          rowData.parcel_uuid = null;
          rowData.parcel_name = "";
        }

        const parcelDataSource = getLookupDataSource("parcel_name");
        if (parcelDataSource) {
          const parcel = parcelDataSource.find((p) => p.uuid === value);
          if (parcel) {
            rowData.parcel_uuid = parcel.uuid;
            rowData.parcel_name = parcel.name;
            rowData.surface = parcel.surface;
            rowData.department_uuid = parcel.department_uuid;
            rowData.season_uuid = parcel.season_uuid;
            rowData.culture = parcel.culture;
          }
        }
      },
      lookup: {
        dataSource: [],
        valueExpr: "uuid",
        displayExpr: function (data) {
          if (!data) return data;
          const prefix = data.level === 0 ? "   **   " : "";
          return prefix + data.name;
        },
        allowClearing: true,
        calculateCellValue: function (data) {
          if (!data) return data;
        },
      },
      headerFilter: {
        visible: true,
        dataSource: function(options) {
          const uniqueParcelNames = [...new Set(options.component.getDataSource().items().map(item => item.parcel_name))];
          options.dataSource = uniqueParcelNames.map(name => ({ text: name, value: name }));
          //options.dataSource = getUniqueParcelNames();
        },
      },
      // filterRow: { },
    });
  } else {
    columns.push({
      dataField: "parcel_name",
      caption: "parcel_name",
    });
  }

  columns.push({
    dataField: "season_uuid",
    caption: "season",
    lookup: {
      dataSource: [],
      valueExpr: "season_uuid",
      displayExpr: "season",
    },
    validationRules: [{ type: "required" }],
    minWidth: 120,
  });
  columns.push({
    dataField: "culture",
    caption: "culture",
  });
  columns.push({
    dataField: "surface",
    caption: "surface",
    dataType: "number",
    //validationRules: [{ type: "range", min: 18, max: 100 }],
    /*fixed: true,
      width: 50,
      fixedPosition: "right",*/
    validationRules: [
      {
        type: "custom",
        validationCallback: function (e) {
          var is_required =
            e.data.parcel_name !== null && e.data.parcel_name !== "";
          return is_required ? e.value !== null && e.value !== "" : true;
        },
        message: "Surface is required if parcel name is not empty.",
      },
    ],
  });
  columns.push({
    dataField: "time_spent_on_parcel",
    caption: "time_spent_on_parcel",
    validationRules: [
      {
        type: "custom",
        validationCallback: function (e) {
          var is_required =
            e.data.parcel_name !== null && e.data.parcel_name !== "";
          return is_required ? e.value !== null && e.value !== "" : true;
        },
        message:
          "Time spent on parcel is required if parcel name is not empty.",
      },
    ],
  });
  columns.push({
    dataField: "location_category",
    caption: "location_category",
  });
  columns.push({
    dataField: "net_surface_worked",
    caption: "net_surface_worked",
    dataType: "number",
  });
  columns.push({
    dataField: "work_machine_name",
    caption: "work_machine_name",
  });
  columns.push({
    dataField: "ignition_time",
    caption: "ignition_time",
  });
  columns.push({
    dataField: "working_time",
    caption: "working_time",
  });
  columns.push({
    dataField: "inactive_time",
    caption: "inactive_time",
  });
  columns.push({
    dataField: "traveled_distance",
    caption: "traveled_distance",
  });
  columns.push({
    dataField: "average_speed",
    caption: "average_speed",
    dataType: "number",
  });
  columns.push({
    dataField: "maximum_speed",
    caption: "maximum_speed",
    dataType: "number",
  });
  columns.push({
    dataField: "estimated_surface",
    caption: "estimated_surface",
    dataType: "number",
  });
  columns.push({
    dataField: "surface_worked_with_overlaps",
    caption: "surface_worked_with_overlaps",
    dataType: "number",
  });
  columns.push({
    dataField: "performance",
    caption: "performance",
    dataType: "number",
  });
  columns.push({
    dataField: "fuel_consumption",
    caption: "fuel_consumption",
    dataType: "number",
  });
  columns.push({
    dataField: "consumption_per_hour",
    caption: "consumption_per_hour",
    dataType: "number",
  });
  columns.push({
    dataField: "consumption_per_ha",
    caption: "consumption_per_ha",
    dataType: "number",
  });
  columns.push({
    dataField: "starting_machine_engine_hours",
    caption: "starting_machine_engine_hours",
    dataType: "number",
  });
  columns.push({
    dataField: "machine_engine_hours",
    caption: "machine_engine_hours",
    dataType: "number",
  });
  columns.push({
    dataField: "fuel_norm",
    caption: "fuel_norm",
    dataType: "number",
  });
  columns.push({
    dataField: "area_correction_factor",
    caption: "area_correction_factor",
    dataType: "number",
  });
  columns.push({
    dataField: "product",
    caption: "product",
  });
  columns.push({
    dataField: "product_quantity",
    caption: "product_quantity",
  });
  columns.push({
    dataField: "product_measure_unit_uuid",
    caption: "product_measure_unit",
    lookup: {
      dataSource: [],
      valueExpr: "product_measure_unit_uuid",
      displayExpr: "product_measure_unit",
    },
    validationRules: [
      {
        type: "custom",
        validationCallback: function (e) {
          var is_required =
            e.data.product !== null &&
            e.data.product !== "" &&
            e.data.product_quantity > 0;
          return is_required ? e.value !== null && e.value !== "" : true;
        },
        message:
          "Measure unit is required if product and product quantity is not empty.",
      },
    ],
  });
  columns.push({
    dataField: "cost",
    caption: "cost",
    dataType: "number",
  });
  columns.push({
    dataField: "cost_category",
    caption: "cost_category",
  });
  columns.push({
    dataField: "notes",
    caption: "notes",
  });
  columns.push({
    dataField: "work_hours",
    caption: "salary_work_hours",
    dataType: "number",
    visible: false,
  });

  return columns;
}
