<template>
  <div class="import-attribute-list">
    <div class="row">
      <label class="control-label col-lg-8" v-if="unfilledFilter">
        <input type="checkbox" v-model="show_unfilled" />
        {{ $t("tools.configurator.show_unfilled") }}
      </label>
      <label
        class="control-label group_by_feature col-lg-4"
        v-if="groupByFeature"
      >
        <input type="checkbox" v-model="is_group_by_active" />
        {{ $t("tools.configurator.group_by_feature") }}
      </label>
    </div>
    <input
      type="text"
      v-model="filter"
      class="form-control mb-2"
      :placeholder="$t('tools.configurator.filter_attributes')"
      v-if="quickFilter"
    />
    <div class="import-attribute-list-content">
      <import-attribute-list-item
        v-for="item in items"
        :data="item"
        :key="item.id"
        :on-move="onMove"
        class="mb-3"
        @onDragChanged="onDragChanged"
        @onMove="onMoveEvent"
        :groupBy="is_group_by_active"
      ></import-attribute-list-item>
    </div>
    <div
      class="filled-success text-center"
      v-if="unfilledFilter && show_unfilled && items.length <= 0"
    >
      <img
        src="~@/assets/images/desktop/icons/controls/check-circle.svg"
        class="icon-svg text-success success-icon"
      />
    </div>
  </div>
</template>
<script>
import ImportAttributeListItem from "@/components/AgroImportMapper/partials/ImportAttributeList/ImportAttributeListItem";
import { eventBus } from "@/plugins/eventBus";

export default {
  name: "ImportAttributeList",
  props: {
    data: {
      type: Array,
      default: [],
    },
    onMove: {
      type: Boolean | null,
      default: false,
    },
    quickFilter: {
      type: Boolean | null,
    },
    unfilledFilter: {
      type: Boolean | null,
    },
    groupByFeature: {
      type: Boolean | null,
    },
  },
  data() {
    return {
      items: this.data ? this.data : [],
      original_items: undefined,
      filter: "",
      show_unfilled: true,
      is_group_by_active: false,
    };
  },
  methods: {
    onDragChanged(val) {
      this.$emit("onDragChanged", val);
    },
    onMoveEvent: function (evt) {
      this.$emit("onMove", evt);
    },
    validate() {
      return (
        this.items.filter((item) => {
          if (item.validate instanceof Function) {
            return !item.validate();
          }
        }).length <= 0
      );
    },
    resetFilter() {
      this.filter = "";
    },
    applyUnfilled(list) {
      if (this.unfilledFilter && this.show_unfilled) {
        return list.filter((item) => {
          return item.items.length <= 0;
        });
      }

      return list;
    },
  },
  components: {
    ImportAttributeListItem,
  },
  mounted() {
    let vm = this;
    this.filter = "";
    this.original_items = this.data;
    eventBus.$on("onTemplateChanged", (response) => {
      vm.filter = "";
    });
  },
  watch: {
    data: {
      handler: function (newVal) {
        this.items = this.applyUnfilled(newVal);
        this.original_items = newVal;
        this.resetFilter();
      },
      deep: true,
      immediate: true,
    },
    filter: function (newVal) {
      if (newVal) {
        this.items = this.original_items.filter((item) => {
          return (
            item.name
              .toString()
              .toLowerCase()
              .indexOf(newVal.toString().toLowerCase()) > -1
          );
        });
      } else {
        this.items = this.original_items;
      }
    },
    show_unfilled: function (newVal) {
      this.resetFilter();
      if (newVal) {
        this.items = this.applyUnfilled(this.items);
      } else {
        this.items = this.applyUnfilled(this.original_items);
      }
    },
  },
};
</script>
<style scoped>
.success-icon {
  font-size: 100px;
  margin-top: 100px;
  opacity: 0.5;
}

.import-attribute-list-content {
  overflow-y: scroll;
  overflow-x: hidden;
  height: 100vh;
}

.import-attribute-list-content::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.import-attribute-list-content::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.import-attribute-list-content::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
.import-attribute-list-content::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.group_by_feature {
  text-align: right;
  padding: 0;
}
</style>
