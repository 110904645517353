import {errorMessage, errorMessageList, successMessage} from "@/helpers/common";
import { canEdit, canDelete } from "@/helpers/Permissions";

export function getData(context, createCallback = undefined, updateCallback = undefined) {

  if(context.$route.params.uuid && context.$route.params.uuid !== 'add') {
    context.config.get({
      uuid: context.$route.params.uuid
    }).then((response) => {
      if(response === null || ( response && !response.is_active && response.deleted_at )) {
        errorMessage(context, context.$t('common.not_available', { name: context.name }));
        setTimeout(function(){
          context.$router.push({
            path: context.config.list_url,
          });
        }, 1000);
      } else {
        context.data = response;

        //after data is loaded
        if(updateCallback instanceof Function) {
          updateCallback(context.data);
        }
      }

    }).catch((e) => {
      console.log('load data ex', e);
      errorMessage(context, context.$t('common.not_found', { name: context.name }));
      setTimeout(function(){
        context.$router.push({
          path: context.config.list_url,
        });
      }, 1000);
    });
  } else {
    //init, while creating new
    if(createCallback instanceof Function) {
      createCallback({});
    }
  }
}

export function deleteAction(context) {
  if(!canDelete(context)) return;

  return new Promise((resolve, reject) => {
    context.config.delete({
      uuid: context.data.uuid,
    }).then((response) => {
      successMessage(context, response.message);
      resolve(response);
    }).catch( function(error) {
      errorMessage(context, error.response.data.message);
      reject(error);
    })
  });
}

export function confirmDelete(context) {
  context.$dialog.confirm(context.$t('common.confirm_delete', { title: context.data.name }))
    .then((dialog) => {
      deleteAction(context).then((resp) => {
        dialog.close();
        setTimeout(function(){
          context.$router.push({
            path: context.config.list_url,
          });
        }, 500);
      }).catch((e) => {
        dialog.close();
      });
    });
}

export function save(context, backPath = undefined) {
  if(!canEdit(context)) return;

  return new Promise((resolve, reject) => {
    context.$v.data.$touch();
    if(!context.$v.data.$invalid) {
      context.$v.data.$reset();

      if(context.data.uuid) {
        context.config.update(context.data).then((response) => {
          successMessage(context, response.message);

          if(backPath) {
            setTimeout(function() {
              context.$router.push({
                path: backPath
              }).catch((e) => {
                window.location.href = backPath
              });
            }, 300);
          }

          resolve(response.data);
        }).catch((e) => {
          if (e.response.status === 400) {
            errorMessage(context, e.response.data.errors.error[0]);
          } else {
            errorMessageList(context, e.errors);
          }
          reject();
        });
      } else {
        if(backPath) {
            context.backPath = backPath;
        }

        context.config.create(context.data).then((response) => {
          successMessage(context, response.message);
          setTimeout(function() {
            if(context.backPath) {
              context.$router.push({
                path: context.backPath,
              }).catch((e) => {
                  window.location.href = context.backPath
              });
            } else {
              /*context.$router.push({
                path: context.config.list_url+'/'+response.data.uuid,
              }).catch((e) => {
                window.location.href = context.config.list_url+'/'+response.data.uuid;
              });*/ // ??
            }
          }, 500);
          resolve(response.data);
        }).catch((e) => {
          if (e.response.status === 400) {
            errorMessage(context, e.response.data.errors.error[0]);
          } else {
            errorMessageList(context, e.errors);
          }
          reject();
        });
      }
    } else {
      errorMessage(context, context.$t('fill_out_error'));
      reject();
    }
  });
}

export function saveAndNew(context, backPath = undefined, default_data = null) {
    return save(context, backPath).then((resp) => {
      if(backPath) {
        context.$router.push(
          backPath
        ).then((navi) => {
          context.data = default_data ?? {};
        }).catch((e) => {
          window.location.href = backPath;
        })
      }
    });
}

export function newObject(context) {
  context.data = {};
  let addUrl = 'add';

  context.$router.push({
    path: addUrl
  }).catch((e)=>{
    window.location.href = addUrl;
  });
}

export function gotoList(context) {
  context.$router.push({
    path: context.config.list_url,
  })
}

export function goto(context, path, name) {
  context.$router.push({
    path: path,
    query: {
      backPath: context.$route.fullPath,
      name: name
    }
  })
}

export function handleParams(context) {
  let route = context.$route;

  if(route.query.backPath) {
    context.backPath = route.query.backPath;
  }

  if(route.query.name) {
    context.data.name = route.query.name;
  }
}

export default {
  getData, deleteAction, confirmDelete, save, newObject, gotoList, handleParams
}
