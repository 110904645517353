<template>
  <div class="department-list">
    <div class="parent-department"
         v-for="department in parentDepartments"
         :key="department.uuid">
      <department-list-item
        container-class="parent"
        @onDeletePressed="onDeletePressed"
        :onlyView="onlyView"
        :showSelector="showSelector"
        :singleSelect="singleSelect"
        @onSelectionChanged="onSelectionChanged"
        @onSelected="onSelected"
        :data="department">
      </department-list-item>
    </div>
  </div>
</template>

<script>
import DepartmentListItem from "~/pages/my-company/departments/partials/DepartmentListItem.vue";

export default {
  name: 'DepartmentList',
  props: ['data', 'onlyView', 'showSelector', 'singleSelect'],
  data() {
    return {
      departments: this.data ?? [],
    }
  },
  components: {
    DepartmentListItem
  },
  computed: {
    parentDepartments() {
      const allDeps = this.$store.getters['department/departmentList'];
      const parents = Object.values(allDeps).filter((item) => {
        return item.parent_uuid === null;
      });

      if (allDeps && allDeps.length > 0 && parents.length === 0) {
        return [allDeps[0].parent_department];
      }

      return parents;
    },
  },
  methods: {
    onDeletePressed(data) {
        this.$emit('onDeletePressed', data);
    },
    onSelectionChanged(data) {
      this.$emit('onSelectionChanged', data);

      this.departments.splice(
        this.departments.indexOf(
          this.departments.find(d => d.uuid === data.uuid)
        ),
        1,
        data
      )
      if (this.singleSelect) {
        this.departments.map((item) => {
          if (item.uuid !== data.uuid && item.selected === true) {
            item.selected = false;
          }
        });
      }
    },
    onSelected(value) {
      this.$emit('onSelected', value);
    },
  },
  watch: {
    data: {
      handler: function(newVal) {
        this.departments = newVal;
      },
      deep: true,
    }
  }
}
</script>
