<template type="x/template">
  <transition name="modal" id="stock-filter-dialog">
    <div>
      <DxPopup
      id="dx-dialog"
      width="100vw"
      height="100vh"
      :close-on-outside-click="false"
      :show-close-button="true"
      :visible="showPopup"
      :resize-enabled="true"
      :darg-enabled="true"
      @hidden="$emit('hidden')"
      :wrapper-attr="popupAttributes"
    >
       <template #title>
        <div class="stock_filter_header">
          <div class="left_side">
            <div @click="backButtonEvent" class="back_button">
              <img src="@/assets/images/icons/arrow-back.svg" />
              <div class="back_button_text">Inapoi</div>
              </div>
          </div>
          <div class="right_side">
            <img class="close_button"
              src="@/assets/images/icons/dialog-close.svg"
              @click="buttonCloseEvent"
            />
          </div>
        </div>
      </template>

      <template #content>
        <div class="stock_filter_content">
           <DxTabPanel
            :element-attr="tabPanelAttributes"
            :data-source="filterSteps"
            :loop="false"
            :selected-item="currentItem"
            :selected-index="currentStep"
            :animation-enabled="true"
            :swipe-enabled="false"
            :show-nav-buttons="false"
            @selection-changed="tabSelectionChanged($event)"
          >
            <template #item="{ data: step }">
              <div class="tab_panels_container">
                <div v-show="step == 1" class="title_text">Filtre disponibile</div>

                <div v-show="step == 1" class="stock_steps">
                  <b-form-checkbox
                          class="stock_checkbox"
                          v-model="localFilterData.has_stock_with_zero"
                          @change="changeZeroStock"
                        >
                          Afișare produse cu stoc 0
                        </b-form-checkbox>
                </div>

              </div>
            </template>
           </DxTabPanel>
        </div>
      </template>
    </DxPopup>
    </div>
  </transition>
</template>

<script>

import { DxPopup, DxPosition } from "devextreme-vue/popup";
import DxTabPanel from "devextreme-vue/tab-panel";

export default {
    name: "StockFilter",
     props: {
        showPopup: {
          type: Boolean,
          default: false,
        },
        filterData: {
            type: Object,
            default: null
        },
    },
    data(){
        return {
             popupAttributes: {
                class: 'stock_filter_dialog_wrapper'
            },
            tabPanelAttributes: {
                class: 'stock_filter_tabs'
            },
            filterSteps: [1],
            currentStep: 0,
            currentItem: 1,
            localFilterData:{
                has_stock_with_zero: false,
            },
        };
    },
    components: {
        DxPopup, DxPosition,
        DxTabPanel
    },
    watch: {
         showPopup: {
             handler: function (oldVal, newVal) {
                if(newVal){
                    this.localFilterData.has_stock_with_zero = this.filterData.has_stock_with_zero == 1;

                }
            }
        },
    },
    methods: {
        backButtonEvent(){
          if(this.currentItem == 1){
            this.$emit("hidden");
          }
          else{
            this.currentStep = this.currentStep - 1;
          }
        },
        buttonCloseEvent(){
          this.$emit("hidden");
        },
        tabSelectionChanged(e) {
          this.currentItem = e.addedItems[0];
          this.currentStep = this.filterSteps.indexOf(this.currentItem);
        },
        changeZeroStock(){
            this.$emit("hideOnZeroChange", this.localFilterData.has_stock_with_zero ? 1 : 0);
        }
    }
}
</script>

<style lang="scss">
  @import "@/assets/scss/stockMobile/stockFilter.scss";
</style>
