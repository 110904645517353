<template type="x/template">
    <transition name="modal" id="layer-detail-dialog">
        <DxPopup
      id="dx-dialog"
      width="100vw"
      height="75vh"
      :close-on-outside-click="false"
      :show-close-button="true"
      :visible="showPopup"
      :resize-enabled="false"
      :darg-enabled="false"
      position="bottom"
      @hidden="$emit('hidden')"
      :wrapper-attr="popupAttributes"
    >
     <template #title>
        <div class="layer-detail-header">
          <div class="left_side">
           <div class="left-side-title">Suprafață parcelă nouă {{(layerAddress.surface/10000).toFixed(2)}} ha</div>
            <div class="back_button" v-if="false" >
              <img src="@/assets/images/icons/arrow-back.svg" />
              <div class="back_button_text">Inapoi</div>
              </div>
          </div>

          <div class="right_side">
            <img class="close_button"
              src="@/assets/images/icons/dialog-close.svg"
              @click="buttonCloseEvent"
            />
          </div>
        </div>
      </template>

       <template #content>
        <div>
            <DxScrollView ref="layerDetailScroll" class="layer-detail-scroll" :use-native="true">
                <div class="layer-detail-content">
                    <div class="ld-input-holder">
                        <div class="input_label">Departament / Partener</div>
                        <div>
                            <dx-combo-with-add ref="departamentComboWithAdd" @addNewItemInstant="addNewDepartamentInstant" @selectionChanged="departamentSelected" addNewText="Partenerul nu există" :comboDataset="departments" placeholder="Alegeți partener"></dx-combo-with-add>
                        </div>
                    </div>
                    <div class="ld-input-holder" v-show="showPartnerID">
                        <div class="input_label">ID partener</div>
                        <div class="input_value" >
                            <DxTextBox placeholder="Introduceți ID" v-model="layerDetailData.partner_ID" valueChangeEvent="keyup" class="global_dx_number_input"/>
                        </div>
                    </div>
                    <div class="ld-input-holder">
                        <div class="input_label">An agricol</div>
                        <div>
                            <dx-combo-with-add ref="seasonComboWithAdd" @addNewItemInstant="addNewSeasonInstant" @selectionChanged="seasonSelected" addNewText="Anul agricol nu există" :comboDataset="seasons" placeholder="Alegeți anul agricol"></dx-combo-with-add>
                        </div>
                    </div>
                    <div class="ld-input-holder">
                        <div class="input_label">Nume parcelă</div>
                        <div class="input_label" v-if="parcelNameGeneratorEnabled">{{generatedParcelname}}</div>
                        <div class="input_value" >
                            <DxTextBox placeholder="Introduceți numele" v-model="layerDetailData.name" valueChangeEvent="keyup" class="global_dx_number_input"/>
                        </div>
                    </div>
                    <div class="ld-input-holder">
                        <div class="input_label">Cultură</div>
                        <div>
                            <dx-combo-with-add ref="cultureComboWithAdd" @addNewItemInstant="addNewCultureInstant" @selectionChanged="cultureSelected" addNewText="Cultura nu există" :comboDataset="cultures" placeholder="Alegeți cultura"></dx-combo-with-add>
                        </div>
                    </div>
                    <div class="ld-input-holder">
                        <div class="input_label">Soi</div>
                        <div>
                            <dx-combo-with-add ref="soiComboWithAdd" @addNewItemInstant="addNewSoiInstant" @selectionChanged="soiSelected" addNewText="Soiul nu există" :comboDataset="subCultures" placeholder="Alegeți soiul"></dx-combo-with-add>
                        </div>
                    </div>
                    <div class="ld-input-holder">
                        <div class="input_label">Observație</div>
                        <div class="input_value">
                            <DxTextArea class="global_dx_number_input" v-model="layerDetailData.notes" />
                        </div>
                    </div>
                    <div class="layer-detail-bottom-spacer"></div>
                </div>
            </DxScrollView>
            <div class="layer-detail-bottom-sticky">
                <div class="sticky_action_button" @click="saveParcelLayer()">Salvează</div>
            </div>
            <toast-message ref="toastMessage"></toast-message>
        </div>
       </template>

    </DxPopup>
    </transition>
</template>

<script>

import { mapGetters, mapActions } from 'vuex'
import { DxPopup, DxPosition } from "devextreme-vue/popup";
import DxComboWithAdd from "@/components/Mobile/DxComboWithAdd/index.vue";
import DxTextBox from 'devextreme-vue/text-box';
import DxTextArea from 'devextreme-vue/text-area';
import { DxScrollView } from "devextreme-vue/scroll-view";
import ToastMessage from "@/components/Mobile/toastMessage.vue"
import { required, helpers } from "vuelidate/src/validators";

const requiredByParam = (param) => (value) => {
  if(param){
    return helpers.req(value) && value.length > 0;
  }
  return true;
};

export default {
    name: "LayerDetailDialog",
    props: {
        showPopup: {
          type: Boolean,
          default: false,
        },
        layerAddress:Object
    },
    data(){
        return {
            popupAttributes: {
                class: 'layer-detail-attribute-wrapper'
            },
            layerDetailData: {
                departament_uuid: '',
                partner_ID: '',
                season_uuid: '',
                season_name: '',
                name: '',
                culture_uuid: '',
                soi_uuid: '',
                notes: ''
            },
            showPartnerID: false,
            cultureTypes: [],
            departments: [],
            seasons: [],
            cultures: [],
            subCultures: [],
            parcelNameGeneratorEnabled: false,
            generatedParcelname: ''
        };
    },
    validations(){
        return {
            layerDetailData: {
                departament_uuid: { required },
                partner_ID:  {requiredByParam: requiredByParam(this.showPartnerID)},
                season_uuid: { required },
                name: { required },
            }
        }
    },
    components: {
        DxPopup, DxPosition,
        DxComboWithAdd,
        DxTextBox,
        DxTextArea,
        DxScrollView,
        ToastMessage
    },
    computed: {
        ...mapGetters({
            currentDepartment: "auth/currentDepartment",
            company: "auth/domain",
            domain: 'auth/domain',
            storedDepartments: 'company/departments',
        }),
    },
    watch:{
        showPopup(newVal){
            this.resetForm();
        },
        showPartnerID(){
            this.layerDetailData.partner_ID = '';
        },
        'layerDetailData.name' : function(newVal, oldVal){
            this.generateParcelname();
        },
         'layerDetailData.partner_ID' : function(newVal, oldVal){
            //this.generateParcelname();
        },

    },
    methods: {
         ...mapActions({
            createDepartamentSimple: "company/createDepartamentSimple",
            getDepartments: 'company/getDepartments',
            createSeason: "seasons/createSeasons",
            getSeasons: "seasons/getSeasons",
            getCultures: "works-cultures/getCultures",
            getCulture: "works-cultures/getCulture",
            createCulture: "works-cultures/createCulture",
            getGroupByCode: 'settings/getGroupByCode',
            previewParcelName: 'layers/previewParcelName'
        }),
        buttonCloseEvent(){
          this.$emit("hidden");
        },
        addNewDepartamentInstant(departmentName){
            this.createDepartamentSimple({name:departmentName}).then(response => {
                this.reloadDepartaments({uuid: response.data.uuid, name: response.data.name});
            });
        },
        departamentSelected(selectedDepartament){
            if(selectedDepartament && selectedDepartament.length > 0){
                this.checkPartnerID(selectedDepartament[0].uuid);
                this.layerDetailData.departament_uuid = selectedDepartament[0].uuid;
                this.generateParcelname();
            }
        },

        addNewSeasonInstant(newSeasonName){
            // Check if seasonName is in the format number/number
            const seasonNamePattern = /^\d+\/\d+$/;
            if (!seasonNamePattern.test(newSeasonName)) {
                this.$refs.toastMessage.showError('Season name must be in the format "number/number" (e.g. 2021/2022)');
                return;
            }

            this.createSeason({name: newSeasonName}).then(response => {
                this.reloadSeasons({uuid: response.data.uuid, name: response.data.name});
            });
        },
        seasonSelected(selectedSeason){
            if(selectedSeason && selectedSeason.length > 0){
                this.layerDetailData.season_uuid = selectedSeason[0].uuid;
                this.layerDetailData.season_name = selectedSeason[0].name;
                this.generateParcelname();
            }
        },

        addNewCultureInstant(newCultureName){
            this.createCulture({name: newCultureName}).then(response => {
                this.reloadCultures({uuid: response.data.uuid, name: response.data.name});
            });
        },
        cultureSelected(selectedCulture){
            this.layerDetailData.culture_uuid = selectedCulture[0].uuid;
            this.reloadSoi(null, this.layerDetailData.culture_uuid);
        },

        addNewSoiInstant(newSoiName){
            if(this.layerDetailData.culture_uuid == ''){
                this.$refs.toastMessage.showError("Mai intâi selectați cultura!");
            }
            else{
                this.createCulture({name: newSoiName, parent_uuid: this.layerDetailData.culture_uuid}).then(response => {
                    this.reloadSoi({uuid: response.data.uuid, name: response.data.name}, this.layerDetailData.culture_uuid);
                });

            }
            //this.$refs.toastMessage.showInfo("Funcția este în curs de dezvoltare!");
        },
        soiSelected(selectedSubCulture){
            this.layerDetailData.soi_uuid = selectedSubCulture[0].uuid;
        },

        async saveParcelLayer(){
            this.$v.layerDetailData.$touch();
            if (!this.$v.layerDetailData.$invalid) {
                 let vm = this;
                 let layerPoints = {
                    name: this.layerDetailData.name,
                    surface: this.layerAddress.surface,
                    coordinates: this.layerAddress.coordinates
                };
                await this.$store.dispatch('layers/createLayer', {
                    department_uuid: vm.layerDetailData.departament_uuid,
                    department_code: vm.layerDetailData.partner_ID,
                    season_uuid: vm.layerDetailData.season_uuid,
                    culture_uuid:  vm.layerDetailData.culture_uuid,
                    variety_uuid:  vm.layerDetailData.soi_uuid,
                    data: { layers: [layerPoints] },
                }).then((resp) => {
                    this.$refs.toastMessage.showSuccess("Parcela a fost salvată");
                    this.reloadDepartaments(null);
                    this.$emit("hidden", true, vm.layerDetailData.departament_uuid, vm.layerDetailData.season_uuid, vm.layerDetailData.season_name);
                }).catch((e) => {
                    this.$refs.toastMessage.showError(e.response.data.message);
                });
            }
            else{
                this.$refs.toastMessage.showError(this.createSaveErrormMssages());
            }
        },

        generateParcelname(){
            if(this.parcelNameGeneratorEnabled){
                if(this.layerDetailData.departament_uuid && this.layerDetailData.season_uuid && this.layerDetailData.name.length > 0){
                    this.previewParcelName(
                        {
                            department_uuid: this.layerDetailData.departament_uuid,
                            season_uuid: this.layerDetailData.season_uuid,
                            layer_name: this.layerDetailData.name,
                            department_code: this.layerDetailData.partner_ID,
                        }
                    ).then(response => {
                        this.generatedParcelname = response;
                    }).catch((e) => {
                        console.log(e.response.data.errors.error);
                        this.$refs.toastMessage.showError(e.response.data.errors.error);
                    });
                }
            }
        },

        createSaveErrormMssages(){
             let errorMessages = [];

            if(this.$v.layerDetailData.departament_uuid.$invalid){
                errorMessages.push("Departamentul / Partenerul este obligatoriu!");
            }
            if(this.$v.layerDetailData.partner_ID.$invalid){
                errorMessages.push("ID partener este obligatoriu!");
            }
             if(this.$v.layerDetailData.season_uuid.$invalid){
                errorMessages.push("Anul agricol este obligatoriu!");
            }
             if(this.$v.layerDetailData.name.$invalid){
                errorMessages.push("Numele parcelei este obligatorie!");
            }

             return errorMessages;
        },
        reloadDepartaments(newItem){
             this.getDepartments({
                company_uuid: this.domain.uuid,
                }).then(response => {
                    this.departments = [];
                    response.forEach(dep => {
                        this.departments.push({uuid: dep.uuid, name: dep.name});
                    });

                    if(newItem){
                        this.$refs.departamentComboWithAdd.updateWithNewItem(newItem);
                    }
            });
        },
        checkPartnerID(department_uuid){
            this.showPartnerID = false;
            for(let i = 0; i < this.storedDepartments.length; i++ ){
                if(this.storedDepartments[i].uuid == department_uuid){
                    this.showPartnerID = !this.storedDepartments[i].code || this.storedDepartments[i].code.length <= 0;
                }
            }
        },
        reloadSeasons(newItem){
            this.getSeasons().then(response => {
                this.seasons = [];
                for(let i = 0; i < response.data.length; i++ ){
                    this.seasons.push({uuid: response.data[i].uuid, name: response.data[i].name});
                }
                if(newItem){
                    this.$refs.seasonComboWithAdd.updateWithNewItem(newItem);
                }
            });
        },
        reloadCultures(newItem){
            this.getCultures({is_variety: false}).then(response => {
                this.cultures = [];
                for(let i = 0; i < response.data.length; i++ ){
                    this.cultures.push({uuid: response.data[i].uuid, name: response.data[i].name});
                }
                if(newItem){
                    this.$refs.cultureComboWithAdd.updateWithNewItem(newItem);
                }
            });
        },
        reloadSoi(newItem, culture_uuid){
            if(culture_uuid && culture_uuid != undefined && culture_uuid.length > 0){
                this.getCulture({ uuid: culture_uuid}).then(response => {
                    this.subCultures = [];
                    if(response.varieties.length > 0){
                        for(let i = 0; i < response.varieties.length; i++ ){
                            this.subCultures.push({uuid: response.varieties[i].uuid, name: response.varieties[i].name});
                        }
                    }
                    if(newItem){
                        this.$refs.soiComboWithAdd.updateWithNewItem(newItem);
                    }
                });
            }
        },
        resetForm(){
            this.layerDetailData = {
                departament_uuid: '',
                season_uuid: '',
                name: '',
                culture_uuid: '',
                soi_uuid: '',
                notes: ''
            };
            if(this.$refs.departamentComboWithAdd){
                this.$refs.departamentComboWithAdd.resetControl();
            }
            if(this.$refs.seasonComboWithAdd){
                this.$refs.seasonComboWithAdd.resetControl();
            }
            if(this.$refs.cultureComboWithAdd){
                this.$refs.cultureComboWithAdd.resetControl();
            }
            if(this.$refs.soiComboWithAdd){
                this.$refs.soiComboWithAdd.resetControl();
            }
            this.generatedParcelname = '';
            this.showPartnerID = false;
        },
        loadSettings(){
            this.getGroupByCode({
                code: 'DIGITAL_AGENDA'
            }).then(response => {
                let _setting = response.items.find(obj => obj.code == 'PARCEL_NAME_GENERATOR_ENABLED');
                this.parcelNameGeneratorEnabled = false;
                if(_setting !== undefined){
                    this.parcelNameGeneratorEnabled = _setting.value == '1'
                }
            });
        }
    },
    mounted(){
       this.reloadDepartaments(null);
       this.reloadSeasons(null);
       this.reloadCultures(null);
       this.reloadSoi(null);
       this.loadSettings();
    }
}
</script>

<style lang="scss">
@import "@/assets/scss/components/layerDetails.scss";
</style>
