<template type="x/template">
  <transition name="modal" id="contact-info-dialog">
    <DxPopup
      class="vertical-center"
      ref="filterPopup"
      :width="400"
      height="calc(100vh - 40px)"
      :show-title="true"
      title="Filters"
      :drag-enabled="false"
      :close-on-outside-click="false"
      :show-close-button="true"
      :visible="showPopup"
      @hidden="$emit('hidden')"
      @showing="popupShowing"
    >
      <template #content>
        <div>
          <div class="popup-property-details scrollable">
            <card title="Departaments" class="filter-card">
              <DxTreeList
                :data-source="testDepartments"
                :show-row-lines="true"
                :show-borders="true"
                :column-auto-width="true"
                :auto-expand-all="true"
                :show-column-headers="false"
                :selected-row-keys="selectedDepartmentKeys"
                key-expr="id"
                parent-id-expr="parent_id"
                :element-attr="treeListAttributes"
              >
                <DxSelection :recursive="true" mode="multiple" />
                <DxColumn data-field="name" />
              </DxTreeList>
            </card>
            <card title="Date" class="filter-card">
              <DxDateBox :value="new Date()" type="date" />
            </card>
            <card title="Works" class="filter-card">
              <DxList
                :search-enabled="true"
                :data-source="filterWorks"
                :show-selection-controls="true"
                selection-mode="all"
                select-all-mode="page"
                displayExpr="name"
                :element-attr="listAttributes"
              />
            </card>
            <card title="Machines" class="filter-card">
              <DxList
                :search-enabled="true"
                :data-source="filterMachines"
                :show-selection-controls="true"
                selection-mode="all"
                select-all-mode="page"
                displayExpr="name"
                :element-attr="listAttributes"
              />
            </card>
          </div>
          <div class="filter-buttons-container">
            <DxButton
              :width="150"
              text="Apply filters"
              type="normal"
              styling-mode="contained"
              icon="filter"
              @click="$emit('hidden')"
            />

            <DxButton
              :width="150"
              text="Clear filters"
              type="normal"
              styling-mode="contained"
              icon="clear"
              @click="$emit('hidden')"
            />
          </div>
        </div>
      </template>
    </DxPopup>
  </transition>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import {
  getTestWorks,
  getTestMachines,
  getTestDepartments,
} from "./testData.js";

import DxButton from "devextreme-vue/button";
import DxPopup from "devextreme-vue/popup";
import DxList from "devextreme-vue/list";
import DxDateBox from "devextreme-vue/date-box";
import { DxTreeList, DxSelection, DxColumn } from "devextreme-vue/tree-list";

export default {
  name: "Filters",
  props: ["showPopup"],
  data() {
    return {
      filterWorks: [],
      filterMachines: [],
      testDepartments: [],
      selectedDepartmentKeys: [],
      treeListAttributes: {
        class: "agx-tree-list",
      },
      listAttributes: {
        class: "agx-list",
      },
    };
  },
  components: {
    DxButton,
    DxPopup,
    DxList,
    DxDateBox,
    DxTreeList,
    DxSelection,
    DxColumn,
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
      domain: "auth/domain",
    }),
    departments() {
      if (this.user && this.user.is_superadmin == 1) {
        return this.$store.getters["company/departments"];
      } else if (this.user && this.user.departments) {
        return this.user.departments;
      } else {
        return [];
      }
    },
  },
  methods: {
    ...mapActions({
      getDepartments: "company/getDepartments",
    }),
    popupShowing(e) {
      console.log("showing", e, this.$refs.filterPopup);
    },
  },
  mounted() {
    this.getDepartments({
      company_uuid: this.domain.uuid,
    });
    this.filterWorks = getTestWorks();
    this.filterMachines = getTestMachines();
    this.testDepartments = getTestDepartments();
  },
};
</script>

<style scoped>
.vertical-center {
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.scrollable {
  height: calc(100vh - 165px);
  overflow-x: auto;
}

.filter-buttons-container{
  padding-top:20px;
}

</style>