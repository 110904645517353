<template>
  <div class="department-list-item card p-2 mt-2 mb-2" :class="containerClass">
    <div class="row">
      <div class="col-lg-4 text-left">
        <label v-if="showSelector">
          <input type="checkbox" v-model="selected" :disabled="department.disabled"/>
        </label>
        <router-link class="text-primary c-pointer" :class="containerClass === 'child' ? 'text-secondary' : ''"
                     :to="!onlyView ? `/my-company/departments/${department.uuid}` : '#'">
          {{ department.name }}
        </router-link>
        <p class="small-text d-block font-weight-italic" v-if="department.parent_department"> {{ department.parent_department.name }} </p>
      </div>
      <div class="col-lg-2">
        {{ department.type_name }}
      </div>
      <div class="col-lg-4">
        <p class="small-text">
          <span class="fw-bold">{{ $t('users') }}</span>: <span :class="['pl-1', department.users_count > 0 ? 'text-danger' : null]"> {{ department.users_count }} </span>
        </p>
        <p class="small-text d-block">
          <span class="fw-bold">{{ $t('company.departments.warehouses') }}</span>: <span :class="['pl-1']"> {{ warehouseList }} </span>
        </p>
      </div>
      <div class="col-lg-2 text-right pr-2" v-show="!onlyView">
        <router-link :to="'/my-company/departments/'+department.uuid" :title="$t('common.edit')">
          <img src="~@/assets/images/desktop/icons/controls/edit.svg" class="icon-svg float-right success-filter c-pointer"/>
        </router-link>
        <a v-on:click.stop.prevent="onDeletePressed(data)" :title="$t('common.delete')" class="c-pointer">
          <img src="~@/assets/images/desktop/icons/controls/delete.svg" class="icon-svg float-right danger-filter c-pointer"/>
        </a>
        <div class="legal-holder">
          <div class="is-legal text-success p-1 text-right">
            {{ department.legal_entity ? 'LEGAL ENTITY' : ''}}
          </div>
        </div>
      </div>
      <department-list-item
        v-if="childDepartments"
        v-for="child in childDepartments"
        container-class="child"
        @onDeletePressed="onDeletePressed"
        :onlyView="onlyView"
        :showSelector="showSelector"
        @onSelectionChanged="onSelectionChanged"
        @onSelected="onSelected"
        :key="Math.random()"
        :data="child">
      </department-list-item>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DepartmentListItem',
  props: ['data', 'containerClass', 'textColor', 'onlyView', 'showSelector'],
  data() {
    return {
      department: this.data ? this.data : {},
      selected: false,
    }
  },
  computed: {
    childDepartments() {
      return this.$store.getters['department/departmentList'].filter((item) => {
        return item.parent_uuid === this.department.uuid;
      })
    },
    warehouseList() {
      return this.department.warehouses.map((item) => {
        return item.name;
      }).join(', ');
    }
  },
  methods: {
    onDeletePressed(data) {
      this.$emit('onDeletePressed', data);
    },
    onSelectionChanged(data) {
      this.$emit('onSelectionChanged', data);
    },
    onSelected(value) {
      this.$emit('onSelected', value);
    },
  },
  watch: {
    data: {
      handler: function(newVal) {
        this.department = newVal;
        this.selected = Boolean(newVal.selected);
      },
      deep: true,
      immediate: true,
    },
    selected: function(newVal) {
      this.department.selected = newVal;
      this.$emit('onSelectionChanged', this.department);
      this.$emit('onSelected', newVal);
    }
  }
}
</script>

<style lang="scss" scoped>
.parent {
  margin-top: 25px !important;
  padding-right: 27px !important;
  background-color: #f9f9f9;
}
.child {
  margin-left: 25px !important;
  border-top: none !important;
  border-right: none !important;
  border-bottom: 1px #ededed solid !important;
  border-top: 1px #ededed solid !important;
  border-left: none !important;
  width: 97.8%;
  padding-right: 20px !important;
}

.small-text {
  font-size: 12px;
  display: inline-flex;
}
</style>
