<template>
  <div id="sc-product-list-filters" class="filter-actions col-lg-12 filters">
    <div class="row">
      <div class="col-lg form-group">
        <label class="control-label">{{$t("stock_management.products.name")}}</label>
        <input v-model="filters.name" class="form-control" :placeholder="$t('stock_management.products.name')"/>
      </div>
      <div class="col form-group">
        <label class="control-label">{{$t("stock_management.products.barcode")}}</label>
        <input v-model="filters.barcode" class="form-control" :placeholder="$t('stock_management.products.barcode')"/>
      </div>
      <div class="col-lg form-group">
        <label class="control-label">{{$t("stock_management.products.code")}}</label>
        <input v-model="filters.code" class="form-control" :placeholder="$t('stock_management.products.code')"/>
      </div>
      <div class="col-lg form-group">
        <label class="control-label">{{$t("stock_management.products.type")}}</label>
        <b-form-select
          v-model="filters.product_type_uuid"
          class="form-control"
          :options="product_type_list"></b-form-select>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-3 mt-3 pt-2">
        <agro-filter @onChange="onDynamicFiltersChanged"/>
      </div>
      <div class="col-lg-2 mt-3 pt-2">
        <button class="btn-sm btn-primary col-lg-12" @click="applyFilters">{{$t("common.show")}}</button>
      </div>
      <div class="col-lg-3 mt-3 pt-2">
        <button class="btn-sm btn-secondary col-lg-12" @click="resetFilters">{{$t("common.reset_filters")}}</button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import * as type from '../../../../store/mutation-types'
import {buildDropdownOptions} from "@/helpers/common";
import DatePicker2 from 'vue2-datepicker';
import AgroFilter from "~/components/AgroFilter/Index.vue";

export default {
  name: 'SMProductFilters',
  props: ["limit"],
  data() {
    return {
      filters:  this.gridFilters ? this.gridFilters : {},
    }
  },
  components: {
    AgroFilter,
    DatePicker2
  },
  computed: {
    ...mapGetters({
        gridFilters: 'smProducts/smProductFilters',
        departments: 'company/departments',
        warehouses: 'smWarehouses/smWarehouses',
        productTypes: 'smProductTypes/smProductTypes',
        domain: 'auth/domain',
        currentDepartment: 'auth/currentDepartment',
    }),
    department_list() {
      return buildDropdownOptions(Object.assign([], this.departments), {
        value: 'uuid',
        text: 'name',
      }, {
        value: null,
        text: '-',
      });
    },
    warehouse_list() {
      return buildDropdownOptions(Object.assign([], this.warehouses), {
        value: 'uuid',
        text: 'name',
      }, {
        value: null,
        text: '-'+this.$t('common.all')+'-',
      });
    },
    product_type_list() {
      return buildDropdownOptions(Object.assign([], this.productTypes), {
        value: 'uuid',
        text: 'name',
      }, {
        value: null,
        text: '-',
      });
    },
  },
  methods: {
    ...mapActions({
        getData: 'smProducts/getSmProducts',
        getDepartments: 'company/getDepartments',
        getWarehouses: 'smWarehouses/getSmWarehouses',
        getProductTypes: 'smProductTypes/searchSmProductTypes'
    }),
    applyFiltersClick() {
      this.$set(this.filters, 'page', 1);
      this.$router.push({
          path: '/stock-management/products',
          params: {
            page: 1,
          }
        }
      );
      this.applyFilters();
    },
    applyFilters() {
      this.$emit('onFiltersApplied', this.filters);
      this.getData(this.filters);
    },
    resetFilters() {
      this.filters = {
        name: '',
        barcode: null,
        department_uuid: this.currentDepartment?.department_uuid ?? null,
        product_type_uuid: null,
        page: 1,
        limit: this.limit,
      };

      this.$store.commit("smProducts/"+type.SET_SM_PRODUCT_FILTERS, this.filters);
      this.applyFilters();
    },
    onDynamicFiltersChanged(selectedUUID) {
      this.filters.department_uuid = selectedUUID
    }
  },
  mounted(){
    this.getDepartments({
      company_uuid: this.domain.uuid,
      enablePagination: 0,
    });

    this.getProductTypes({});

    this.resetFilters();
  },
  watch: {
    gridFilters: {
      handler: function(newVal) {
        this.filters = newVal;
      },
      deep: true,
    },
    departments: {
      handler: function(newVal) {
        this.getWarehouses({
          company_uuid: this.domain.uuid,
          department_uuid: newVal.uuid,
          enablePagination: 0,
        });
      },
      deep: true,
    }
  }
}
</script>
