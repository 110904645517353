<template>
  <div class="daily-grid-table-container">
    <Loader :loading="loading" v-if="loading" class="loader"/>
    <div class="rows grid-table" :id="tableID" v-else>
      <daily-grid-row
                      v-for="(item, index) in items"
                      :data="item"
                      :key="Math.random()"
                      :index="index"
                      :headers="headers"
                      :infinite-scroll="infiniteScroll"
                      :tableID="tableID"
                      @onScroll="onScroll"
      />
    </div>
  </div>
</template>

<script>
import DailyGridRow from "~/pages/salary-calculator/sc-work-reports/partials/DailyGrid/DailyGridRow.vue";
import Loader from "@/components/Loader.vue";
import {mapGetters} from "vuex";

export default {
  name: 'DailyGridTable',
  components: {Loader, DailyGridRow},
  props: [
    'data',
    'headers',
    'showFilters',
    'loading',
    'infiniteScroll'
  ],
  data() {
    return {
      items: this.data ?? [],
      filter: null,
      pager: this.pagination ?? {},
      loadedPages: [],
      currentPage: 1,
    }
  },
  computed: {
    ...mapGetters({
      pagination: 'common/pagination',
    }),
    tableID() {
      return 'table-'+Math.random().toString().replace('.', '');
    },
    filteredItems() {
      if (this.filter) {
        if (this.items) {
          return this.items.filter((item) => {
            if (item[this.filter.col]) {
              return item[this.filter.col].toString().indexOf(
                this.filter.value
              ) > 0;
            }
          });
        }
      } else {
        return this.items;
      }
    }
  },
  methods: {
    onFilterChanged(data) {
      this.filter = data;

      if (this.items) {
        let filtered = this.items.filter((item) => {
          if (item[data.col]) {
            return item[data.col].toString().indexOf(
              data.value
            ) > 0;
          }

          this.$set(this, 'items', filtered);
        });
      }
    },
    onScroll(data) {
      let totalPages = this.pager.last_page;
      let nextPage = this.currentPage + 1;

      let reachPos = (data.tableScrollPosition.height * 0.6);
      if (data.rowScrollPosition.bottom >= reachPos) {
        if (this.loadedPages.indexOf(nextPage) === -1) {
          this.loadedPages.push(nextPage);

          this.$emit('onPaginate', {
            current_page: this.currentPage,
            next_page: nextPage,
            total_pages: totalPages,
          });
        }
      }
    },
  },
  watch: {
    data: {
      handler: function(newVal) {
        this.items = newVal;
        if (this.items && this.items.length > 0 && !this.items[0].added) {
          newVal[0].added = true;
          this.items.unshift(newVal[0]);
        }
      },
      deep: true,
      immediate: true,
    },
    pagination: {
      handler: function(newVal) {
        this.pager = newVal;
        this.currentPage = newVal.current_page;
      },
      deep: true,
      immediate: true,
    },
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/global/global";
.daily-grid-table {
  border: 1px #D9D9D9 solid;
  border-radius: 6px;
}

.loader {
  display: flex;
  justify-content: center;
}
</style>
