import * as api from '../../api/index'
import * as endpoints from '../../api/endpoints'
import * as types from '../../api/../mutation-types'

const actions = {
    //SmProducts
    getSmProducts({commit}, payload) {
          return api.fetch(endpoints.SM_PRODUCTS, payload, commit, (response) => {
            commit(types.SET_SM_PRODUCTS, response.data.data);
              commit("common/"+types.SET_PAGINATION, response.data.pagination, { root: true });
          });
    },
    getSmProduct({commit}, payload) {
      return api.fetch(endpoints.SM_PRODUCTS+'/'+payload.uuid, payload, commit,  (response) => {
        commit(types.SET_SM_PRODUCT, response.data);
      });
    },
    createSmProduct({commit}, payload) {
      return api.post(endpoints.SM_PRODUCTS, payload,  commit,(response) => {
        commit(types.SET_SM_PRODUCT, response.data);
      });
    },
    updateSmProduct({commit}, payload) {
      return api.put(endpoints.SM_PRODUCTS+'/'+payload.uuid, payload, commit, (response) => {
        commit(types.SET_SM_PRODUCT, response.data);
      })
    },
    deleteSmProduct({commit}, payload) {
      return api.remove(endpoints.SM_PRODUCTS+'/'+payload.uuid, payload,  commit,(response) => {
        commit(types.SET_SM_PRODUCT, response.data);
      })
    },
}

export default actions;
