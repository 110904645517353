<template>
  <div class="calculation-formula">
    <h3>{{ $t('tools.configurator.calculation_formula.title') }}</h3>
    <div>{{ $t('tools.configurator.calculation_formula.info') }}</div>
    <br/>
    <div class="formula-holder" v-if="csvAttributes">
      <div class="row">
        <div class="col-lg-12 form-group">
          <label>{{ $t('tools.configurator.calculation_formula.active_hours_formula') }}:</label>
          <formula-tag-input :data="csvAttributes"
                             :initTags="activeFormulaTags"
                             @onChange="onActiveFormulaChanged">
          </formula-tag-input>
          <span class="info text-danger">ex: Total timp parcurs</span>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12 form-group">
          <label>{{ $t('tools.configurator.calculation_formula.inactive_hours_formula') }}:</label>
          <formula-tag-input :data="csvAttributes"
                             :initTags="inactiveFormulaTags"
                             @onChange="onInactiveFormulaChanged">
          </formula-tag-input>
          <span class="info text-danger">ex:  Timp de lucru - Total timp parcurs</span>
        </div>
      </div>
    </div>
    <p class="text-danger" v-else>
      {{ $t('tools.configurator.calculation_formula.warning_sel') }}
    </p>
  </div>
</template>

<script>
import FormulaTagInput from "@/components/AgroImportMapper/partials/FormulaTagInput";
export default {
  name: 'CalculationFormula',
  components: {FormulaTagInput},
  props: {
    csvAttributes: {
      type: Array,
    },
    template: Object|null,
  },
  data() {
    return {
        currentTemplate: this.template ? this.template : {},
    }
  },
  computed: {
    activeFormulaTags() {
        return this.currentTemplate?.active_formula ?? [];
    },
    inactiveFormulaTags() {
      return this.currentTemplate?.inactive_formula ?? [];
    }
  },
  methods: {
    onActiveFormulaChanged(tags) {
      this.$emit('onActiveFieldFormulaChanged', tags);
    },
    onInactiveFormulaChanged(tags) {
      this.$emit('onInactiveFieldFormulaChanged', tags);
    }
  },
  watch: {
    template: {
      handler: function (newVal) {
        this.currentTemplate = newVal;
      },
      deep: true,
      immediate: true,
    },
  }
}
</script>
