import * as api from '../api/index'
import * as endpoints from '../api/endpoints'
import * as types from "../mutation-types";

const actions = {
  getSeasonOnParcelHasCulture({ commit }, payload) {
    return api.fetch(endpoints.SEASON_ON_PARCEL_HAS_CULTURE, payload, commit, (response) => {
      commit(types.SET_SEASON_ON_PARCEL_HAS_CULTURE, response.data);
    });
  },
  getSpcWorkDetails({ commit }, payload) {
    return api.fetch(endpoints.SPC_WORK_DETAILS, payload, commit, (response) => {
      
    });
  },
  createSeasonOnParcelHasCulture({ commit }, payload) {
    return api.post(endpoints.SEASON_ON_PARCEL_HAS_CULTURE, payload, commit, (response) => {
      
    });
  },
  getSpcWorks({ commit }, payload) {
    return api.fetch(payload.endpoint ?? endpoints.MOBILE_SPC_WORKS, payload, commit, (response) => {
      commit(types.SET_SPC_WORKS, response.data);
    });
  },
  nsGetSpcWorksForFilter({ commit }, payload) {
    return api.fetch(endpoints.MOBILE_SPC_WORK_FILTERS, payload, commit);
  },
  getSpcWorkSummary({ commit }, payload) {
    return api.fetch(endpoints.MOBILE_SPC_WORK_SUMMARY, payload, commit, (response) => {
      commit(types.SET_SPC_WORK_SUMMARY, response.data);
    });
  },
  getSpcMachines({ commit }, payload) {
    return api.fetch(endpoints.MOBILE_SPC_MACHINES, payload, commit, (response) => {
      commit(types.SET_SPC_MACHINES, response.data);
    });
  },
  nsGetSpcMachines({ commit }, payload) {
    return api.fetch(endpoints.MOBILE_SPC_MACHINES, payload, commit);
  },
  nsGetSpcMachinesForFilter({ commit }, payload) {
    return api.fetch(endpoints.MOBILE_SPC_MACHINES_FILTER, payload, commit);
  },
  getSpcParcels({ commit }, payload) {
    return api.fetch(endpoints.MOBILE_SPC_PARCELS, payload, commit, (response) => {
      commit(types.SET_SPC_PARCELS, response.data);
    });
  },
  nsGetSpcParcels({ commit }, payload) {
    return api.fetch(endpoints.MOBILE_SPC_PARCELS, payload, commit);
  },
  nsGetSpcParcelsForFilter({ commit }, payload) {
    return api.fetch(endpoints.MOBILE_SPC_PARCEL_FOR_FILTERS, payload, commit);
  },
}

export default actions;
