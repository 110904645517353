<template type="x/template">
  <transition name="modal" id="contact-info-dialog">
    <div>
      <DxPopup
        height="100vh"
        width="100vw"
        :close-on-outside-click="false"
        :show-close-button="true"
        :visible="showPopup"
        @hidden="$emit('hidden')"
        :wrapper-attr="popupAttributes"
      >
        <template #title>
          <div class="add_article_popup_title">
            <div class="left_side">


            </div>
            <div class="right_side">
            <img
              src="@/assets/images/icons/dialog-close.svg"
              @click="closeDialog"
            />
          </div>
            <div class="right_side" v-if="false">inainte</div>
          </div>
        </template>
        <template #content>
          <div
            class="add-article-tabs"
            style="width: 100%; display: flex; flex-direction: column"
          >
            <div class="step_1">
              <div class="step_1_container">
                <div class="step_title">Adaugă</div>

                <DxRadioGroup
                  :items="addActions"
                  item-template="item"
                  class="article_type_selector"
                  v-model="currentAction"
                >
                  <template #item="{ data }">
                    <div
                      :class="[
                        'article_item', 'article_item_selected'
                      ]"
                    >

                      {{ data.name }}
                    </div>
                  </template>
                </DxRadioGroup>
              </div>
            </div>
          </div>
        </template>
      </DxPopup>
    </div>
  </transition>
</template>

<script>
//import Vue from 'vue'
import { mapActions, mapGetters } from "vuex";
import { v4 } from 'uuid';
import DxPopup from "devextreme-vue/popup";
import { DxBox, DxItem } from "devextreme-vue/box";
import DxRadioGroup from "devextreme-vue/radio-group";
import DxSelectBox from "devextreme-vue/select-box";

import DxButton from "devextreme-vue/button";
import DxTabPanel from "devextreme-vue/tab-panel";
import DxDropDownBox from "devextreme-vue/drop-down-box";
import { DxScrollView } from "devextreme-vue/scroll-view";

import {
  DxDataGrid,
  DxColumn,
  DxSelection,
  DxPaging,
  DxFilterRow,
  DxScrolling,
  DxEditing,
  DxTexts,
  DxForm,
  DxPopup as DxPopupGrid,
  DxRequiredRule,
  DxToolbar
} from "devextreme-vue/data-grid";
import { DxToast } from "devextreme-vue/toast";


export default {
  name: "AddActionSelector",
  props: {
    showPopup: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      popupAttributes: {
          id: 'elementId',
          class: 'add-action-selector-popup'
      },
      addActions: [
        { id: 1, name: "Produs", code: "PRODUCT" },
        { id: 2, name: "Lucrare", code: "WORK" }
      ],
      currentAction: null
    };
  },

  components: {
    DxPopup,
    DxBox,
    DxItem,
    DxRadioGroup,
    DxSelectBox,

    DxButton,
    DxTabPanel,
    DxDropDownBox,
    DxDataGrid,
    DxSelection,
    DxPaging,
    DxFilterRow,
    DxScrolling,
    DxToast,
    DxScrollView,
    DxEditing,
    DxColumn,
    DxTexts,
    DxForm,
    DxPopupGrid,
    DxRequiredRule,
    DxToolbar,
  },
  watch:{
    currentAction(newVal){
      if(newVal != null){
       this.persistAction();
      }
    },
    showPopup(){
      this.currentAction = {};
    }
  },
  methods: {
    closeDialog(){
      this.$emit("hidden");
    },
    persistAction(){
      if(this.currentAction && this.currentAction.code == 'PRODUCT'){
        this.$emit('startAddProduct');
        this.$emit("hidden");
      }

      if(this.currentAction && this.currentAction.code == 'WORK'){
        this.$emit('startAddWork', null, null, true);
        this.$emit("hidden");
      }
    }
  },
  mounted() {

  },
};
</script>

<style lang="scss">
  @import "@/assets/scss/digitalAgenda/daAddActionSelector.scss";
</style>
