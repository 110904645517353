<template>
  <div id="sm-movement-filters" class="filter-actions col-lg-12 filters">
    <div class="row">
      <div class="col-sm form-group">
        <label class="control-label">{{$t("stock_management.transactions.product")}}</label>
        <input v-model="filters.product"
               class="form-control"
               :placeholder="$t('stock_management.transactions.name_code_barcode')"/>
      </div>
      <div class="col-sm form-group">
        <label class="control-label">{{$t("stock_management.products.type")}}</label>
        <b-form-select
          v-model="filters.product_type_uuid"
          class="form-control"
          :options="product_type_list"></b-form-select>
      </div>
      <div class="col-sm form-group">
        <label class="control-label">{{$t("stock_management.transactions.transaction_type")}}</label>
        <b-form-select
          v-model="filters.transaction_type"
          class="form-control"
          :options="transaction_type_list"></b-form-select>
      </div>
      <div class="col-sm form-group">
        <label class="control-label">{{$t("stock_management.transactions.movement_type")}}</label>
        <b-form-select
          v-model="filters.movement_type"
          class="form-control"
          :options="movement_type_list"></b-form-select>
      </div>
      <div class="form-group col-sm">
        <label class="control-label">{{$t("common.date_from")}}</label>
        <date-picker2
          v-model="date_from"
          :value-type="date_from_input"
          format="YYYY-MM-DD"
          type="date"
          placeholder="yyyy-mm-dd"
          :input-class="['date-picker-style']"
          @change="onDateFromChanged"
          ref="date_from"
        ></date-picker2>
      </div>
      <div class="form-group col-sm">
        <label class="control-label">{{$t("common.date_to")}}</label>
        <date-picker2
          v-model="date_to"
          :value-type="date_to_input"
          format="YYYY-MM-DD"
          type="date"
          placeholder="yyyy-mm-dd"
          :input-class="['date-picker-style']"
          @change="onDateToChanged"
          ref="date_from"
        ></date-picker2>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-2 form-group">
        <label class="control-label">{{$t("common.warehouse")}}</label>
        <b-form-select
          v-model="filters.warehouse_uuid"
          class="form-control"
          :options="warehouse_list"></b-form-select>
      </div>
      <div class="col-sm-3 mt-4 pt-2">
        <div class="row">
          <agro-filter @onChange="onDynamicFiltersChanged"/>
        </div>
      </div>
      <div class="col-lg-2 mt-3 pt-2">
        <button class="btn-sm btn-primary col-lg-12" @click="applyFiltersClick">{{$t("common.show")}}</button>
      </div>
      <div class="col-lg-3 mt-3 pt-2">
        <button class="btn-sm btn-secondary col-lg-12" @click="resetFilters">{{$t("common.reset_filters")}}</button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import * as type from '../../../../store/mutation-types'
import {buildDefaultDropdownList} from "@/helpers/common";
import DatePicker2 from 'vue2-datepicker';
import AgroFilter from "~/components/AgroFilter/Index.vue";

export default {
  name: 'SMStockMovementFilters',
  props: ["limit"],
  data() {
    return {
      filters:  this.gridFilters ? this.gridFilters : {},
      date_from: null,
      date_to: null,
      date_from_input: null,
      date_to_input: null,
    }
  },
  components: {
    AgroFilter,
    DatePicker2
  },
  computed: {
    ...mapGetters({
      gridFilters: 'smTransactions/smStockMovementFilters',
      departments: 'company/departments',
      warehouses: 'smWarehouses/smWarehouses',
      domain: 'auth/domain',
      productTypes: 'smProductTypes/smProductTypes',
      transactionTypes: 'smTransactions/smTransactionTypes',
      movementTypes: 'smTransactions/smStockMovementTypes'
    }),
    department_list() {
      return buildDefaultDropdownList(Object.assign([], this.departments));
    },
    warehouse_list() {
      return buildDefaultDropdownList(Object.assign([], this.warehouses), {
        value: "uuid",
        text: "name",
        subtext: "department.name",
      });
    },
    product_type_list() {
      return buildDefaultDropdownList(Object.assign([], this.productTypes));
    },
    transaction_type_list() {
      return buildDefaultDropdownList(Object.assign([], this.transactionTypes));
    },
    movement_type_list() {
      return buildDefaultDropdownList(Object.assign([], this.movementTypes));
    },
  },
  methods: {
    ...mapActions({
      getData: 'smTransactions/getSmStockMovements',
      getDepartments: 'company/getDepartments',
      getWarehouses: 'smWarehouses/getSmWarehouses',
      getProductTypes: 'smProductTypes/searchSmProductTypes',
      getTransactionTypes: 'smTransactions/getSmTransactionTypes',
      getMovementTypes: 'smTransactions/getSmStockMovementTypes'
    }),
    applyFiltersClick() {
      this.$set(this.filters, 'page', 1);
      this.$emit('onFilterClickApplied', this.filters);
      this.applyFilters();
    },
    applyFilters() {
      this.$v.filters.$touch();
      if(!this.$v.filters.$invalid) {
        this.$v.filters.$reset();
        this.commitFilters();
        this.$emit('onFiltersApplied', this.filters);
        this.getData(this.filters);
      }
    },
    commitFilters() {
      this.$store.commit("smTransactions/"+type.SET_SM_STOCK_MOVEMENT_FILTERS, this.filters);
    },
    resetFilters() {
      this.filters = {
        name: '',
        product: null,
        product_type_uuid: null,
        warehouse_uuid: null,
        department_uuid: null,
        transaction_type: null,
        movement_type: null,
        page: 1,
        date_from: null,
        date_to: null,
        limit: this.limit,
      };
    },
    onDateFromChanged(date) {
      this.filters.date_from = date;
    },
    onDateToChanged(date) {
      this.filters.date_to = date;
    },
    onDynamicFiltersChanged(selectedUUID) {
      this.filters.department_uuid = selectedUUID;
    }
  },
  mounted(){
    this.getDepartments({
      company_uuid: this.domain.uuid,
      enablePagination: 0,
    });

    this.getTransactionTypes({
      pagination: 0,
    });

    this.getMovementTypes({
      pagination: 0,
    });

    this.getProductTypes({
      pagination: 0,
    });

    this.getWarehouses({
        company_uuid: this.domain.uuid,
        enablePagination: 0,
      }).then((resp) => {
        if(resp.data && resp.data[0]) {
          this.filters.warehouse_uuid = resp.data[0].uuid;
          this.commitFilters();
          this.applyFiltersClick();
        }
      });
  },
  watch: {
    gridFilters: {
      handler: function(newVal) {
        this.filters = newVal;
        this.filters.page = 1;
        this.commitFilters();
      },
      deep: true,
    },
  },
  validations(){
    return {
      filters: {

      }
    }
  }
}
</script>
