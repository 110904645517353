<template>
  <div class="app-header container-fluid">
    <div class="row">
      <div class="col-lg-6 left-side">
        <img src="~@/assets/images/desktop/logo.svg" class="logo"/>
        <div class="version">v{{ version }}</div>
      </div>
      <div class="col-lg-6 right-side text-right" v-if="user && user.uuid">
        <span class="company-name-holder">
          <router-link to="/dashboard" class="company-name secondary-title" v-if="domain && domain.company">
          {{ domain.company }}
        </router-link>
        </span>
        <span class="company-filters">
          <router-link to="/my-company/departments">
            <img src="~@/assets/images/desktop/icons/filters.svg"/>
          </router-link>
        </span>
        <span class="company-filter-separator">
          <img src="~@/assets/images/desktop/icons/vertical-separator.svg"/>
        </span>
        <span class="user-profile">
          <img :src="user && user.photo_url" class="rounded-circle profile-photo">
          <span class="fs-small username c-pointer" @click="() => showAccountDDL = !showAccountDDL"> {{ user && user.name }}</span>
          <img src="~@/assets/images/desktop/icons/arrow-down.svg" class="arrow-icon"/>
            <div id="dropdown-menu" class="dropdown-menu" :class="[showAccountDDL ? 'd-block' : 'd-none']">
            <router-link to="/settings/profile" class="dropdown-item pl-3 fs-small gray80">
              <img src="~@/assets/images/desktop/icons/controls/cog.svg" class="icon-svg"/>
              {{ $t('settings') }}
            </router-link>
            <div class="dropdown-divider"></div>
            <a v-on:click.prevent="logout" class="dropdown-item pl-3 fs-small gray80 c-pointer">
              <img src="~@/assets/images/desktop/icons/controls/logout.svg" class="icon-svg"/>
              {{ $t('logout') }}
            </a>
          </div>
        </span>
      </div>
      <div class="col-lg-6 right-side text-right login" v-else>
        <router-link to="/auth/login" class="company-name secondary-title">
          {{ $t('login') }}
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import {getTitle, isDevelopment, isLocal, isProduction, isStaging} from "~/helpers/envHelper";
import packageJson from '/package.json';

export default {
  name: 'AppHeader',
  components: {},
    middleware: 'auth',

  data() {
      return {
          userData: this.user ? this.user : {},
          showAccountDDL: false,
      }
  },

  computed: {
      ...mapGetters({
           user: 'auth/user',
          domain: 'auth/domain',
          currentDepartment: 'auth/currentDepartment'
      }),
      version() {
          return packageJson.version;
      },
      appName() {
          return this.$t('appname');
      },
      title() {
        return getTitle();
      },
      isDevelopment() {
        return isDevelopment();
      },
      isLocal() {
        return isLocal();
      },
      isStaging() {
        return isStaging();
      },
      isProduction() {
        return isProduction();
      },
      role() {
          if(this.user && this.user.current_role) {
              return this.user.current_role.name;
          }
      },
      rolePermissions() {
          if(this.role &&  this.user.current_role.permissions) {
              return this.$t('your_permissions')+': \n '+this.user.current_role.permissions.map((item) => {
                  return item.name + `(${this.getAccessText(item)})`;
              }).join(' + ');
          }
      },
      department() {
          if(this.currentDepartment)  {
              if(this.currentDepartment != null) {
                  return this.currentDepartment.department_name;
              }
          }

          return 'Select department';
      },
  },
  methods: {
    async logout ({app}) {
      // Log out the user.
      await this.$store.dispatch('auth/logout')

      // Redirect to login.
      this.$router.push({ path: '/' })
    },
      getAccessText(permission) {
          let p = getModuleAccess(this.user, permission.code);
          if(p && p.access) {
              return Object.keys(p.access).filter((k) => {
                return Boolean(p.access[k]);
              }).join();
          }
      }
  },
    watch: {
      user: {
          handler: function(newVal) {
              this.userData = newVal;
          },
          deep: true,
      }
    }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/global/global";

.app-header {
  height: 72px;
  background-color: #FFFFFF;
  box-shadow: 0px 4px 40px 0px rgba(0, 0, 0, 0.15);
  padding-bottom: 24px;

  .left-side {
    padding: 24px;
  }

  .right-side {
    padding: 24px;
    display: flex;
    justify-content: flex-end;
  }

  .company-name {
    text-decoration: none;
    color: $Gray_90;
  }

  .company-filters {
    padding-left: 16px;
  }

  .company-filter-separator {
    padding-left: 24px;
    padding-right: 24px;
  }

  .user-profile {
    position: relative;
    .username {
      padding-left: 4px;
      color: $Gray_80;
    }

    .arrow-icon {
      padding-left: 4px;
    }
  }
}
.profile-photo {
  width: 24px;
  height: 24px;
}

.dropdown-menu {
  margin-top: 7px;
  margin-left: 7px;
  position: absolute;
  top: 40px;
  left: -15px;
}
  .u-role {
    font-size: 10px;
    color: white;
    padding: 0px;
    margin: 0px;
  }

  .dep-icon {
    font-size: 27px;
    margin-left: 10px;
  }

  .sel-dep-lnk  {
    font-size: 21px;
  }

  .version {
    font-size: 9px;
    font-weight: bolder;
    color: #565656;
  }

  .left-side {
    display: flex;
    flex-direction: row;
  }

  .logo {
    width: 134px;
    height: 20px;
    margin-top: 5px;
  }
</style>
