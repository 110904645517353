<template>
  <div class="view work-details">
    <card :title="title" id="details" v-if="canEdit || canDelete">
    <span class="action_buttons">
      <img src="~@/assets/images/desktop/icons/controls/plus.svg" class="icon-svg" :title="$t('common.create_new', { name: name })" @click="newObject()"/>
    </span>
      <div class="row">
        <div class="form-group col-sm-12 col-lg-3 text-left">
          <label class="control-label">{{ $t('salary_calculator.work_group.name') }}</label>
          <b-form-select
            v-model="selectedWorkGroupUUID"
            class="form-control"
            :class="{ 'field-error' : this.$v.data.work_group_uuid.$error }"
            @change="onWorkGroupSelected"
            :options="work_group_list"></b-form-select>
        </div>
      </div>
      <div class="row">
        <div class="form-group col-sm-12 col-lg-3 text-left">
          <label class="control-label">{{ $t('salary_calculator.work_type.name') }}</label>
          <vue-bootstrap-typeahead
            :data="work_list"
            v-model="work_search"
            :class="{ 'field-error' : $v.data.work.name.$error }"
            ref="works"
            :serializer="s => s.name"
            :placeholder="$t('salary_calculator.work.search')"
            @hit="onWorkSelected"
          />
        </div>
        <div class="col-lg-1 form-group pt-5" v-if="suggestWorkType">
          <a v-on:click.prevent="goto('/salary-calculator/sc-work-type/add', data.work.name)" class="c-pointer d-inline-block">
            <img src="~@/assets/images/desktop/icons/controls/plus.svg" class="icon-svg"/>{{ $t('common.add')}}</a>
        </div>
      </div>
      <div class="row">
        <div class="form-group col-sm-12 col-lg-2 text-left" v-if="data.price !== null">
          <label class="control-label">{{ $t('salary_calculator.work.custom_price') }} ({{ $currency.name | lowerCase }})</label>
          <input type="text" v-model="data.price" :placeholder='$t("common.price")'
                 v-on:keydown="onlyPrice"
                 class="form-control"/>
          <div class="subtitle-note" @click="data.price = null">
            {{ $t('salary_calculator.work.inherit_group_price') }}
          </div>
        </div>
        <div class="form-group col-lg-3" v-else>
          <div class="subtitle-note" @click="data.price = 0">{{ $t('salary_calculator.work.add_custom_price') }}</div>
        </div>
      </div>
      <div class="row">
        <div class="form-group col-sm-12 col-lg-3 text-left">
          <label class="control-label">{{ $t('salary_calculator.measure_unit.name') }}</label>
          <b-form-select
            v-model="selectedMeasureUnitUUID"
            class="form-control"
            :class="{ 'field-error' : this.$v.data.measure_unit_uuid.$error }"
            @change="onMeasureUnitSelected"
            :options="measure_unit_list"></b-form-select>
        </div>
      </div>
      <div class="row">
        <div class="form-group col-sm-12 col-lg-3 text-left">
          <label class="control-label">{{ $t('salary_calculator.shift.name') }}</label>
          <b-form-select
            v-model="data.shift_uuid"
            class="form-control"
            :options="shift_list"></b-form-select>
        </div>
      </div>
      <div class="row">
        <div class="form-group col-sm-12 col-lg-3 text-left">
          <label class="control-label">{{ $t('salary_calculator.bonus.name') }}</label>
          <b-form-select
            v-model="data.bonus_uuid"
            class="form-control"
            :options="bonus_list"></b-form-select>
        </div>
      </div>
      <agro-action-buttons
        :data="data"
        :can-edit="canEdit"
        :can-delete="canDelete"
        @onSave="save"
        @onSaveAndNew="saveAndNew"
        :save-and-new="true"
        @onDelete="confirmDelete"
        @onGotoList="gotoList"
      ></agro-action-buttons>
    </card>
    <div v-else>
      <forbidden></forbidden>
    </div>
  </div>
</template>
<script>
import {mapActions, mapGetters} from "vuex";
import * as actions from '@/helpers/generalActions'
import {canDelete, canEdit, permissions, permissionTypes} from "@/helpers/Permissions";
import {
  buildDefaultDropdownList,
  only_price,
} from "@/helpers/common";
import {required} from "vuelidate/lib/validators";
import VueBootstrapTypeahead from 'vue-bootstrap-typeahead'
import validator from 'validator'
import Forbidden from "@/pages/errors/Forbidden";
import AgroActionButtons from "@/components/AgroActionButtons/index";

export default {
  name: 'SCWorkPivotDetails',
  data() {
    return {
      data: this.scWorkPivot ? this.scWorkPivot : {},
      selected_work: null,
      work_search: '',
      work_list: [],
      selectedMeasureUnitUUID: null,
      selectedWorkGroupUUID: null,
      suggestWorkType: undefined,
      redirect: undefined,
      currentWorkGroup: null,
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
      works: 'scWork/scWorks',
      workPivot: 'scWorkPivot/scWorkPivot',
      workGroups: 'scWorkGroup/scWorkGroups',
      measureUnits: 'scMeasureUnit/scMeasureUnits',
      shifts: 'scShift/scShifts',
      bonuses: 'scBonus/scBonuses'
    }),
    name() {
        return this.$t('salary_calculator.work.title');
    },
    config() {
      return {
        permission: permissions.SC_WORK,
        permission_type: permissionTypes.module,
        get: this.get,
        create: this.create,
        update: this.update,
        delete: this.delete,
        list_url: '/salary-calculator/sc-work',
        confirm_delete: true,
      }
    },
    actions() {
      return actions;
    },
    canEdit() {
      return canEdit(this);
    },
    canDelete() {
      return canDelete(this);
    },
    title() {
      return (this.data.uuid ? this.$t('common.edit') : this.$t('common.new'))+' '+this.name;
    },
    work_group_list() {
      return buildDefaultDropdownList(this.workGroups, {
        value: 'uuid',
        text: 'full_name',
      });
    },
    measure_unit_list() {
      return buildDefaultDropdownList(this.measureUnits);
    },
    shift_list() {
      return buildDefaultDropdownList(this.shifts, {
        value: 'uuid',
        text: 'full_name',
      });
    },
    bonus_list() {
      return buildDefaultDropdownList(this.bonuses);
    },
  },
  methods: {
    ...mapActions({
      get: 'scWorkPivot/getSCWorkPivot',
      create: 'scWorkPivot/createSCWorkPivot',
      update: 'scWorkPivot/updateSCWorkPivot',
      delete: 'scWorkPivot/deleteSCWorkPivot',
      getWorks: 'scWork/getSCWorks',
      getWorkGroups: 'scWorkGroup/getSCWorkGroups',
      getMeasureUnits: 'scMeasureUnit/getSCMeasureUnits',
      getShifts: 'scShift/getSCShifts',
      getBonuses: 'scBonus/getSCBonuses'
    }),
    onlyPrice(event) {
      return only_price(event);
    },
    loadData() {
      let vm = this;
      return this.actions.getData(this, (data) => {
        vm.data.measure_unit_uuid = null;
        vm.data.shift_uuid = null;
        vm.data.bonus_uuid = null;

        vm.handleInputParams().then((resp) => {
          vm.$set(vm.data, 'work_group_uuid', resp);
        }).catch((ewg) => {
          vm.$set(vm.data, 'work_group_uuid', null);
        });
      }, (data) => {
        vm.$set(vm, 'selectedMeasureUnitUUID', data.measure_unit_uuid);
        vm.$set(vm, 'selectedWorkGroupUUID', data.work_group_uuid);

        vm.$refs.works.handleInput(
          data.work ? data.work.name : ''
        );

        setTimeout(function() {
          vm.suggestWorkType = false;
        }, 100);
      });
    },
    gotoList() {
      return actions.gotoList(this);
    },
    confirmDelete() {
      return this.actions.confirmDelete(this);
    },
    save() {
      return this.actions.save(this, this.redirect ? this.redirect : this.config.list_url);
    },
    saveAndNew() {
      return this.actions.saveAndNew(this, 'add');
    },
    newObject() {
      return this.actions.newObject(this);
    },
    onWorkSelected(data) {
      this.data.work = {
        uuid: data.uuid,
        name: data.name,
      };
      this.data.work_uuid = data.uuid;
      this.suggestWorkType = false;
    },
    onWorkGroupSelected(data) {
      this.$set(this.data, 'work_group_uuid', data);
    },
    onMeasureUnitSelected(data) {
      this.$set(this.data, 'measure_unit_uuid', data);
      this.$v.data.$reset();
    },
    handleInputParams() {
      let vm = this;
      return new Promise((resolve, reject) => {
        let work_group_uuid = this.$route.query.work_group_uuid;
        let work_uuid = this.$route.query.work_uuid;
        let work_name = this.$route.query.work_name;
        this.redirect = this.$route.query.redirect;

        if(work_group_uuid && validator.isUUID(work_group_uuid)) {
          this.$set(this.data, 'work_group_uuid', work_group_uuid);
          this.$set(this, 'selectedWorkGroupUUID', work_group_uuid);
          resolve(work_group_uuid);
        } else if(work_uuid && validator.isUUID(work_uuid)) {

          this.$refs.works.handleInput(
            work_name ? work_name : ''
          );

          this.onWorkSelected({
            uuid: work_uuid,
            name: work_name,
          });

          setTimeout(function() {
            vm.suggestWorkType = false;
          }, 100);

          resolve(work_uuid);
        } else {
          reject('not valid work_uuid or work_group_uuid');
        }
      });
    },
    goto(path, name) {
      let vm = this;
      this.$router.push({
        path: path,
        query: {
          backPath: vm.$route.fullPath,
          name: name
        }
      })
    },
    //@deprecated
    inheritGroupPrice() {
      let vm  =this;
      this.data.price = null;

      if(vm.workGroups) {
        let found = this.currentWorkGroup = this.workGroups.find(g => g.uuid === this.data.work_group_uuid);
        if(found && this.data.price === null) {
          this.$set(this.data, 'price', found.price);
        }
      }
    }
  },
  components: {
    AgroActionButtons,
    Forbidden,
    VueBootstrapTypeahead
  },
  mounted() {
    this.handleInputParams();
    this.getWorkGroups();
    this.getMeasureUnits();
    this.getShifts();
    this.getBonuses();
    this.loadData();
    this.actions.handleParams(this);
  },
  watch: {
    $route: {
      handler: function(newVal) {
        this.actions.handleParams(this);
      },
      deep: true,
    },
    workPivot: {
      handler: function(newVal) {
        this.data = newVal;
      },
      deep: true,
    },
    work_search: { //work type
      handler: function(newVal) {
        let vm = this;
        this.getWorks({
          name: newVal,
        }).then((resp) => {
          vm.work_list = resp.data;
        });

        let work = vm.work_list.find(c => c.name === newVal);

        if(work) {
          this.$set(this.data, 'work', {
            uuid: work.uuid,
            name: newVal,
          });
          this.suggestWorkType = false;
        } else {
          this.$set(this.data, 'work', {
            name: newVal,
          });
          this.suggestWorkType = !!newVal;
        }
      },
      deep: true,
    },
  },
  validations(){
    let vm = this;
    return {
      data: {
        work_group_uuid: {
          required,
        },
        measure_unit_uuid: {
          required,
        },
        work: {
          name: {
            required,
          }
        },
      }
    }
  }
}
</script>
