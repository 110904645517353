const state = () => {
    return {
      company_filters: {
        name: '',
        email: '',
        is_active: 1,
        is_published: '',
      },
      companies: [],
      company: {},
      parent_companies: [],
      units: [],
      departments: [],
      department_filters: {},
      recently_uploaded_files:  [],
      recently_uploaded_zip_files:  [],
    }
}

export default state;
