<template type="x/template">
  <transition name="modal">
    <div>
      <DxPopup
        id="dx-dialog-cam"
        width="100vw"
        height="100vh"
        :close-on-outside-click="false"
        :show-close-button="true"
        :visible="showPopup"
        :resize-enabled="false"
        :darg-enabled="false"
        position="bottom"
        @hidden="$emit('hidden')"
        :wrapper-attr="popupAttributes"
      >

        <template #content>
          <div class="dialog-cam-holder">
            <Camera @onImageCaptured="onImageCaptured" @onClose="onCameraClosed" :show="showPopup"/>
          </div>
        </template>

      </DxPopup>
      <toast-message ref="toastMessage"></toast-message>
    </div>
  </transition>
</template>

<script>
import { DxPopup, DxPosition } from "devextreme-vue/popup";
import ToastMessage from "../../components/Mobile/toastMessage.vue"
import Camera from "@/components/Camera/Camera.vue";

export default{
  name: "CameraViewDialog",
  props: {
    showPopup: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Object,
      default: null
    },
  },
  data(){
    return {
      popupAttributes: {
        class: 'camera_dialog_wrapper'
      },
      showCamera: true,
    };
  },
  components: {
    Camera,
    DxPopup,
    DxPosition,
    ToastMessage
  },
  computed: {

  },
  watch: {
    showPopup: {
      handler: function (oldVal, newVal) {
        this.showCamera = !newVal;
      }
    },
  },
  methods: {
    buttonCloseEvent(){
      this.$emit("hidden");
    },
    onImageCaptured(image) {
      this.$emit('onImageCaptured', image);
      this.buttonCloseEvent();
    },
    onCameraClosed() {
      this.buttonCloseEvent();
    }
  }
}

</script>

<style lang="scss">
#dx-dialog-cam {
  .dx-popup-content {
    background-color: #000000 !important;
  }
}
</style>
