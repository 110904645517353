const state = () => {
  return {
    parcels: [],
    parcel: {},
    parcel_filters: {},
    parcel_groups: [],
  }
}

export default state;
