<template>
  <div id="filters" class="filters p-3 col-lg-12">
    <div class="row">
      <div class="col-sm-12 col-lg pb-3 pb-lg-0">
        <label class="control-label">{{ $t('common.name')}}</label>
        <input v-model="filters.name" class="form-control"/>
      </div>
      <div class="col-sm-12 col-lg pb-3 pb-lg-0">
        <label class="control-label">{{ $t('common.department')}}</label>
        <b-form-select v-model="filters.department_uuid" class="form-control" :options="departments_list"></b-form-select>
      </div>
      <div class="col-sm-12 col-lg pb-3 pb-lg-0">
        <label class="control-label">{{ $t('common.date_from')}}</label>
        <date-picker2
          v-model="creation_date_from"
          :value-type="creation_date_from_input"
          format="DD/MM/YYYY"
          type="date"
          placeholder="dd/mm/yyyy"
          :input-class="['date-picker-style']"
          @change="onCreationDateFromChanged"
          ref="date_from"
        ></date-picker2>
      </div>
      <div class="col-sm-12 col-lg pb-3 pb-lg-0">
        <label class="control-label d-block">{{ $t('common.date_to')}}</label>
        <date-picker2
          v-model="creation_date_to"
          :value-type="creation_date_to_input"
          format="DD/MM/YYYY"
          type="date"
          placeholder="dd/mm/yyyy"
          :input-class="['date-picker-style']"
          @change="onCreationDateToChanged"
          ref="date_to"
        ></date-picker2>
      </div>
      <div class="col-sm-12 col-lg pt-0 pt-lg-4 mt-0 mt-1">
        <button class="btn btn-primary col-lg-12" @click="applyFiltersClick">{{$t("common.show")}}</button>
      </div>
      <div class="col-sm-12 col-lg pt-0 pt-lg-4 mt-0 mt-1">
        <button class="btn btn-secondary col-lg-12" @click="resetFilters">{{$t("common.reset_filters")}}</button>
      </div>
    </div>
  </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex'
    import * as type from '../../../../store/mutation-types'
    import DatePicker2 from 'vue2-datepicker';
    import 'vue2-datepicker/index.css';
    import * as endpoints from '../../../../store/api/endpoints';
    import {buildDropdownOptions} from "../../../../helpers/common";

    export default {
        name: 'PersonFilters',
        data() {
            return {
                filters:  this.personFilters ? this.personFilters : {},
                creation_date_from: null,
                creation_date_from_input: null,
                creation_date_to: null,
                creation_date_to_input: null,
            }
        },
        components:  {
            DatePicker2,
        },
        computed: {
            ...mapGetters({
                currentDepartment: 'auth/currentDepartment',
                personFilters: 'works-persons/personFilters',
                departments: 'company/departments',
            }),
            departments_list() {
                return buildDropdownOptions(Object.assign([], this.departments), {
                    value: 'uuid',
                    text: 'name',
                }, {
                    value: null,
                    text: this.$i18n.t("common.all"),
                });
            },
        },
        methods: {
            ...mapActions({
                getPersons: 'works-persons/getPersons',
            }),
            applyFiltersClick() {
                this.$set(this.filters, 'page', 1);
                this.applyFilters();
            },
            applyFilters() {
                this.$emit('onFiltersApplied', this.filters);
                this.filters.url =  endpoints.PERSONS;
                this.filters.department_uuid = this.currentDepartment.department_uuid;
                this.getPersons(this.filters);
            },
            resetFilters() {
                this.filters = {
                    name: '',
                    creation_date_from: '',
                    creation_date_to: '',
                    page: 1,
                };

                this.creation_date_from = null;
                this.creation_date_from_input = null;
                this.creation_date_to = null;
                this.creation_date_to_input = null;

                this.$store.commit("works-persons/"+type.SET_PERSON_FILTERS, this.filters);
                this.applyFilters();
            },
            onCreationDateFromChanged(date) {
                this.filters.date_from = date;
            },
            onCreationDateToChanged(date) {
                this.filters.date_to = date;
            },
        },
        mounted(){
            this.resetFilters();
        },
        watch: {
            personFilters: {
                handler: function(newVal) {
                    this.filters = newVal;
                },
                deep: true,
            },
        }
    }
</script>
