<template>
  <div id="sc-work-list-filters" class="filter-actions col-lg-12 filters p-3">
    <div class="row">
      <div class="col-lg-3">
        <label class="control-label">{{$t("name")}}</label>
        <input v-model="filters.name" class="form-control" :placeholder="$t('name')"/>
      </div>
      <div class="form-group col-sm-12 col-lg-3 text-left">
        <label class="control-label">{{ $t('salary_calculator.work_group.name') }}</label>
        <b-form-select
          v-model="selectedWorkGroupUUID"
          class="form-control"
          @change="onWorkGroupSelected"
          :options="work_group_list"></b-form-select>
      </div>
      <div class="col-lg-2 mt-4 pt-1">
        <button class="btn-sm btn-primary col-lg-12" @click="applyFilters">{{$t("common.show")}}</button>
      </div>
      <div class="col-lg-3 mt-4 pt-1">
        <button class="btn-sm btn-secondary col-lg-12" @click="resetFilters">{{$t("common.reset_filters")}}</button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import * as type from '../../../../store/mutation-types'
import {buildDropdownOptions} from "@/helpers/common";

export default {
  name: 'SCBonusFilters',
  props: ["limit"],
  data() {
    return {
      filters:  this.gridFilters ? this.gridFilters : {},
      selectedWorkGroupUUID: null,
    }
  },
  computed: {
    ...mapGetters({
      gridFilters: 'scWorkPivot/scWorkPivotFilters',
      workGroups: 'scWorkGroup/scWorkGroups',
    }),
    work_group_list() {
      return buildDropdownOptions(this.workGroups ? this.workGroups : [], {
        value: 'uuid',
        text: 'name',
      }, {
        value: null,
        text: '-',
      });
    },
  },
  methods: {
    ...mapActions({
      getData: 'scWorkPivot/getSCWorkHierarchy',
      getWorkGroups: 'scWorkGroup/getSCWorkGroups',
    }),
    applyFiltersClick() {
      this.$set(this.filters, 'page', 1);
      this.$router.push({
          path: '/sc/work',
          params: {
            page: 1,
          }
        }
      );
      this.applyFilters();
    },
    applyFilters() {
      this.$emit('onFiltersApplied', this.filters);
      this.getData(this.filters);
    },
    resetFilters() {
      this.filters = {
        name: '',
        page: 1,
        limit: this.limit,
      };

      this.$store.commit("scWorkPivot/"+type.SET_SC_WORK_HIERARCHY_FILTERS, this.filters);
      this.applyFilters();
    },
    onWorkGroupSelected(data) {
      this.$set(this.filters, 'work_group_uuid', data);
    },
  },
  mounted(){
    this.getWorkGroups();
    this.resetFilters();
  },
  watch: {
    gridFilters: {
      handler: function(newVal) {
        this.filters = newVal;
      },
      deep: true,
    }
  }
}
</script>
