import * as api from '../../api/index'
import * as endpoints from '../../api/endpoints'
import * as types from '../../api/../mutation-types'

const actions = {
    //SmWarehouses
    getSmWarehouses({commit}, payload) {
          return api.fetch(endpoints.SM_WAREHOUSES, payload, commit, (response) => {
            commit(types.SET_SM_WAREHOUSES, response.data.data);
              commit("common/"+types.SET_PAGINATION, response.data.pagination, { root: true });
          });
    },
    getSmWarehouse({commit}, payload) {
      return api.fetch(endpoints.SM_WAREHOUSES+'/'+payload.uuid, payload, commit,  (response) => {
        commit(types.SET_SM_WAREHOUSE, response.data);
      });
    },
    createSmWarehouse({commit}, payload) {
      return api.post(endpoints.SM_WAREHOUSES, payload,  commit,(response) => {
        commit(types.SET_SM_WAREHOUSE, response.data);
      });
    },
    updateSmWarehouse({commit}, payload) {
      return api.put(endpoints.SM_WAREHOUSES+'/'+payload.uuid, payload, commit, (response) => {
        commit(types.SET_SM_WAREHOUSE, response.data);
      })
    },
    deleteSmWarehouse({commit}, payload) {
      return api.remove(endpoints.SM_WAREHOUSES+'/'+payload.uuid, payload,  commit,(response) => {
        commit(types.SET_SM_WAREHOUSE, response.data);
      })
    },
}

export default actions;
