import * as api from '../../api/index'
import * as endpoints from '../../api/endpoints'
import * as types from '../../api/../mutation-types'
import {SM_STOCK_PRODUCT_INFO} from "../../api/endpoints";
import {SET_SM_STOCK_PRODUCT_INFO} from "../../api/../mutation-types";

const actions = {
    //SmStocks
    getSmStocks({commit}, payload) {
          return api.fetch(endpoints.SM_STOCKS, payload, commit, (response) => {
            commit(types.SET_SM_STOCKS, response.data.data);
              commit("common/"+types.SET_PAGINATION, response.data.pagination, { root: true });
          });
    },
    getSmStock({commit}, payload) {
      return api.fetch(endpoints.SM_STOCKS+'/'+payload.uuid, payload, commit,  (response) => {
        commit(types.SET_SM_STOCK, response.data);
      });
    },
    createSmStock({commit}, payload) {
      return api.post(endpoints.SM_STOCKS, payload,  commit,(response) => {
        commit(types.SET_SM_STOCK, response.data);
      });
    },
    updateSmStock({commit}, payload) {
      return api.put(endpoints.SM_STOCKS+'/'+payload.uuid, payload, commit, (response) => {
        commit(types.SET_SM_STOCK, response.data);
      })
    },
    deleteSmStock({commit}, payload) {
      return api.remove(endpoints.SM_STOCKS+'/'+payload.uuid, payload,  commit,(response) => {
        commit(types.SET_SM_STOCK, response.data);
      })
    },
    getSmStockProductInfo({commit}, payload) {
      return api.fetch(endpoints.SM_STOCK_PRODUCT_INFO+'/'+payload.product_uuid,
        payload, commit, (response) => {
        commit(types.SET_SM_STOCK_PRODUCT_INFO, response.data);
      });
    },
    getSmStockProductHistory({commit}, payload) {
      return api.fetch(endpoints.SM_STOCK_PRODUCT_HISTORY, payload, commit, (response) => {
        
      });
    },
}

export default actions;
