import * as api from '../api/index'
import * as endpoints from '../api/endpoints'
import * as types from "../mutation-types";

const actions = {
    //reports
    getReportsData({commit}, payload) {
        return api.post(endpoints.CALL_SP+'?name='+payload.name, payload, commit,(response) => {
        });
    },

    getReports({commit}, payload) {
        return api.fetchFull(endpoints.REPORTS, payload, commit,(response) => {
            commit(types.SET_REPORTS, response);
            commit("common/"+types.SET_PAGINATION, response.data.pagination, { root: true });
        });
    },
    getReport({commit}, payload) {
        return api.fetch(endpoints.REPORTS+'/'+payload.id, payload, commit,(response) => {
            commit(types.SET_REPORT, response.data);
        });
    },
    createReport({commit}, payload) {
        return api.post(endpoints.REPORTS, payload,  commit,(response) => {
            commit(types.SET_REPORT, response.data);
        });
    },
    updateReport({commit}, payload) {
        return api.put(endpoints.REPORTS+'/'+payload.id, payload, commit, (response) => {
            commit(types.SET_REPORT, response.data);
        })
    },
    deleteReport({commit}, payload) {
        return api.remove(endpoints.REPORTS+'/'+payload.id, payload,  commit,(response) => {
            commit(types.SET_REPORT, response.data);
        })
    },

    //report groups
    getReportGroups({commit}, payload) {
        return api.fetchFull(endpoints.REPORT_GROUPS, payload, commit,(response) => {
            commit(types.SET_REPORT_GROUPS, response.data.data);
        });
    },
    getReportGroup({commit}, payload) {
        return api.fetch(endpoints.REPORT_GROUPS+'/'+payload.id, payload, commit,(response) => {
            commit(types.SET_REPORT_GROUP, response.data);
        });
    },
    createReportGroup({commit}, payload) {
        return api.post(endpoints.REPORT_GROUPS, payload,  commit,(response) => {
            commit(types.SET_REPORT_GROUP, response.data);
        });
    },
    updateReportGroup({commit}, payload) {
        return api.put(endpoints.REPORT_GROUPS+'/'+payload.id, payload, commit, (response) => {
            commit(types.SET_REPORT_GROUP, response.data);
        })
    },
    deleteReportGroup({commit}, payload) {
        return api.remove(endpoints.REPORT_GROUPS+'/'+payload.id, payload,  commit,(response) => {
            commit(types.SET_REPORT_GROUP, response.data);
        })
    },

    //report filters
    getReportFilters({commit}, payload) {
        return api.fetchFull(endpoints.REPORT_FILTERS, payload, commit,(response) => {
            commit(types.SET_REPORT_FILTERS, response.data.data);
        });
    },
    getReportFilter({commit}, payload) {
        return api.fetch(endpoints.REPORT_FILTERS+'/'+payload.id, payload, commit,(response) => {
            commit(types.SET_REPORT_FILTER, response.data);
        });
    },
    createReportFilter({commit}, payload) {
        return api.post(endpoints.REPORT_FILTERS, payload,  commit,(response) => {
            commit(types.SET_REPORT_FILTER, response.data);
        });
    },
    updateReportFilter({commit}, payload) {
        return api.put(endpoints.REPORT_FILTERS+'/'+payload.id, payload, commit, (response) => {
            commit(types.SET_REPORT_FILTER, response.data);
        })
    },
    deleteReportFilter({commit}, payload) {
        return api.remove(endpoints.REPORT_FILTERS+'/'+payload.id, payload,  commit,(response) => {
            commit(types.SET_REPORT_FILTER, response.data);
        })
    },

    //report columns
    getReportColumns({commit}, payload) {
        return api.fetchFull(endpoints.REPORT_COLUMNS, payload, commit,(response) => {
            commit(types.SET_REPORT_COLUMNS, response.data.data);
        });
    },
    getReportColumn({commit}, payload) {
        return api.fetch(endpoints.REPORT_COLUMNS+'/'+payload.id, payload, commit,(response) => {
            commit(types.SET_REPORT_COLUMN, response.data);
        });
    },
    createReportColumn({commit}, payload) {
        return api.post(endpoints.REPORT_COLUMNS, payload,  commit,(response) => {
            commit(types.SET_REPORT_COLUMN, response.data);
        });
    },
    updateReportColumn({commit}, payload) {
        return api.put(endpoints.REPORT_COLUMNS+'/'+payload.id, payload, commit, (response) => {
            commit(types.SET_REPORT_COLUMN, response.data);
        })
    },
    deleteReportColumn({commit}, payload) {
        return api.remove(endpoints.REPORT_COLUMNS+'/'+payload.id, payload,  commit,(response) => {
            commit(types.SET_REPORT_COLUMN, response.data);
        })
    },

}

export default actions;
