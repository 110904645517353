import * as types from "../mutation-types";

const mutations = {
  //reports
  [types.SET_REPORTS]: (state, payload) => {
    state.reports = payload;
  },
  [types.SET_REPORT]: (state, payload) => {
    state.report = payload;
  },

  //report groups
  [types.SET_REPORT_GROUPS]: (state, payload) => {
    state.report_grouops = payload;
  },
  [types.SET_REPORT_GROUP]: (state, payload) => {
    state.report_group = payload;
  },

  //report filters
  [types.SET_REPORT_FILTERS]: (state, payload) => {
    state.report_filters = payload;
  },
  [types.SET_REPORT_FILTER]: (state, payload) => {
    state.report_filter = payload;
  },

  //report columns
  [types.SET_REPORT_COLUMNS]: (state, payload) => {
    state.report_column = payload;
  },
  [types.SET_REPORT_COLUMN]: (state, payload) => {
    state.report_column = payload;
  },
}

export default mutations;
