<template>
  <li class="sidebar-menu-item">
    <div v-if="visible !== false" class="mi"
         :data-toggle="'collapse'" :data-target="'#subitems_'+id"
         aria-expanded="false" :aria-controls="'subitems_'+id"
    >
      <i :class="item.icon" v-if="iconType === 'fontawesome'"/>
      <img :src="icon" class="icon" v-else/>
      <router-link :to="item.href" class="item-title fs14-700">{{ item.title }}</router-link>
      <img src="~@/assets/images/desktop/icons/arrow-down.svg" class="arrow" v-if="item.child && visible !== false"/>
    </div>
    <div class="subitems" :id="'subitems_'+id" v-if="item.child && visible !== false">
      <sidebar-menu-subitem v-for="subitem in item.child" :data="subitem" :key="Math.random()"/>
    </div>
    <div class="separator"></div>
  </li>
</template>

<script>
import SidebarMenuSubitem from "~/components/Sidebar/SidebarMenuSubitem.vue";
export default {
  name: 'SidebarMenuItem',
  props: ['data', 'iconType', 'visible'],
  data() {
    return {
      item: this.data ?? [],
    }
  },
  components: {
      SidebarMenuSubitem,
  },
  computed: {
    id() {
        return Math.random().toString().replace('.','');
    },
    icon() {
      return require('@/assets/'+this.item.icon);
    }
  },
  methods: {
    onCollapse(item) {
      this.$emit('onCollapse', item);
    }
  },
  watch: {
    data: {
      handler: function(newVal) {
        this.item = newVal;
      },
      deep: true,
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/global/global.scss";

.sidebar-menu-item {
  width: 100%;
  padding-top: 8px;
  background-color: $Gray_5;

  .mi {
    display: flex;
    flex-direction: row;

    .icon {
      display: flex;
      width: 20px;
    }

    .item-title {
      flex: 1;
      padding-left: 8px;
      color: $Gray_80;
    }

    .arrow {
      display: flex;
      cursor: pointer;
    }
  }

  .subitems {
    padding-top: 6px;
  }

  .separator {
    padding-top: 16px;
    margin-bottom: 10px;
    border-bottom: 1.8px solid $Gray_30;
  }
}
</style>
