<template>
    <div>
        <div v-if="false" class="consum_label">Ai selectat parcela <span>{{parcel_name}} ({{parcel_surface}} ha)</span></div>
        <DxScrollView class="parcel_consum_scroll" :use-native="true">
            <div>
                <div v-for="(date, d_idx) in consumList" :key="date.date" class="consum_date_holder">
                    <div class="consum_date">{{date.date}}</div>
                    <div class="product_holder">
                        <div v-for="(product, p_idx) in date.products" :key="product.uuid" class="product_item_holder">
                            <div class="product_item">
                                <div class="product_image">
                                    <img src="@/assets/images/icons/samanta.svg"/>
                                </div>
                                <div class="product_name">{{product.name}}</div>
                                <div class="product_quantity">{{product.total_quantity}} {{product.mu_symbol}}</div>
                                <div class="expand_icon_holder">
                                    <div :class="product.expanded ? 'expand_icon_expanded' : 'expand_icon'" @click="expandProduct(product, d_idx, p_idx)">
                                        <img v-show="product.expanded" src="@/assets/images/icons/arrow_up_green.svg"/>
                                        <img v-show="!product.expanded" src="@/assets/images/icons/arrow_down.svg"/>
                                    </div>
                                </div>
                            </div>
                            <div class="stock_details_holder" v-show="product.expanded">
                                <div class="stock_details_spacer"></div>
                                    <div v-for="warehouse in product.warehouses" :key="warehouse.uuid" class="info_line">
                                        <div class="info_label">Deposit:</div>
                                        <div class="info_value">{{warehouse.name}}</div>
                                        <div class="info_quantity">{{warehouse.quantity}} {{product.mu_symbol}}</div>
                                    </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </DxScrollView>
    </div>
</template>

<script>

import { mapGetters, mapActions } from "vuex";
import { DxScrollView } from "devextreme-vue/scroll-view";
import Vue from 'vue';
import ScrollDiv from 'vue-scroll-div';

Vue.use(ScrollDiv);

export default {
    name: "ParcelConsums",
    props: {
        parcel_uuid: String,
        parcel_name: String,
        parcel_group_parcel_uuids: {
            type: Array,
            default: () => [],
        },
        parcel_surface: String
    },
    data(){
        return {
            consumList: []
        };
    },
    components: {
        DxScrollView,
        ScrollDiv
    },
    watch: {
         parcel_uuid(newVal, oldVal) {
            if(newVal && newVal != oldVal){
                this.loadConsum();
            }
         },
         parcel_group_parcel_uuids(newVal, oldVal) {
            if(newVal && newVal != oldVal){
                this.loadConsum();
            }
         }
    },
    methods: {
        ...mapActions({
            getConsums: 'smTransactions/getConsumTransactions',
        }),
        expandProduct(product, d_idx, p_idx){
            product.expanded = !product.expanded;
            //this.consumList[d_idx].products[p_idx] = product;
            this.$set(this.consumList[d_idx].products, p_idx, product);
        },
        loadConsum(){
            this.getConsums({
                parcel_uuid: (this.parcel_group_parcel_uuids && this.parcel_group_parcel_uuids.length > 0) ? this.parcel_group_parcel_uuids.join() : this.parcel_uuid,
            }).then(response => {
                this.consumList = [];
                response.data.forEach(cons => {

                    let checkConsDate = this.consumList.find(c => c.date == cons.created_at);

                    let consumQuantity = 0;

                    if(cons.consums && cons.consums.length > 0){
                        let parcelConsum = cons.consums.find(c => c.parcel_uuid == this.parcel_uuid);
                        if(parcelConsum){
                            consumQuantity = parcelConsum.quantity;
                        }
                    }

                    if(checkConsDate){

                        let checkConsProduct = checkConsDate.products.find(p => p.uuid == cons.movement.product.uuid);
                        if(checkConsProduct){
                            checkConsProduct.total_quantity = parseFloat(parseFloat(checkConsProduct.total_quantity) + parseFloat(consumQuantity)).toFixed(2);

                            let checkConsWarehouse = checkConsProduct.warehouses.find(w => w.uuid == cons.movement.source_warehouse.uuid);
                            if(checkConsWarehouse){
                                checkConsWarehouse.quantity = parseFloat(parseFloat(checkConsWarehouse.quantity) + parseFloat(consumQuantity)).toFixed(2);
                            }
                            else{
                                checkConsProduct.warehouses.push(
                                    {
                                        uuid: cons.movement.source_warehouse.uuid,
                                        name: cons.movement.source_warehouse.name,
                                        quantity: parseFloat(cons.movement.product.quantity).toFixed(2),
                                    }
                                );
                            }
                        }
                        else{
                            checkConsDate.products.push({
                                uuid: cons.product_uuid,
                                        name: cons.movement.product.name,
                                        total_quantity : parseFloat(consumQuantity).toFixed(2),
                                        mu_symbol: cons.movement.product.measure_unit_name,
                                        expaded: false,
                                        warehouses: [
                                            {
                                                uuid: cons.movement.source_warehouse.uuid,
                                                name: cons.movement.source_warehouse.name,
                                                quantity: parseFloat(consumQuantity).toFixed(2),
                                            }
                                        ]
                            });
                        }
                    }
                    else{
                        this.consumList.push(
                            {
                                date: cons.created_at,
                                products: [
                                    {
                                        uuid: cons.movement.product.uuid,
                                        name: cons.movement.product.name,
                                        total_quantity : parseFloat(consumQuantity).toFixed(2),
                                        mu_symbol: cons.movement.product.measure_unit_name,
                                        expaded: false,
                                        warehouses: [
                                            {
                                                uuid: cons.movement.source_warehouse.uuid,
                                                name: cons.movement.source_warehouse.name,
                                                quantity: parseFloat(consumQuantity).toFixed(2),
                                            }
                                        ]
                                    }
                                ],
                            }
                        );
                    }
                });

            });
        }
    },
    mounted(){
        this.loadConsum();
    }
}
</script>

<style lang="scss">
    @import "@/assets/scss/stockMobile/parcelConsum.scss";
</style>
