import Vue from 'vue'
import Card from '../components/Card'
import Child from '../components/Child'
import Button from '../components/Button'
import Link from "@/components/Link.vue";
import Checkbox from '../components/Checkbox'
import PdfExportCol from "~/pages/stock-management/sm-transactions/partials/Cols/PdfExportCol.vue";
import PdfDownloadCol from "~/pages/stock-management/sm-transactions/partials/Cols/PdfDownloadCol.vue";
import CanceledRow from "~/pages/stock-management/sm-transactions/partials/Cols/CanceledRow.vue";
import UnitAndRoleLink from '~/pages/users/partials/UnitAndRoleLink.vue';
import ScoutingImages from "~/pages/scouting/admin/partials/ScoutingImages.vue";

// Components that are registered globaly.
[
  Card,
  Child,
  Button,
  Link,
  Checkbox,
  PdfExportCol,
  PdfDownloadCol,
  UnitAndRoleLink,
  ScoutingImages,
  CanceledRow,
].forEach(Component => {
  Vue.component(Component.name, Component)
})
