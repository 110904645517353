<template>
  <div>
    <router-link :to="item.href" class="sidebar-menu-subitem fs12-400">
      {{ item.title }}
    </router-link>
  </div>
</template>

<script>
export default {
  name: 'SidebarMenuSubitem',
  props: ['data'],
  data() {
    return {
      item: this.data ?? [],
    }
  },
  methods: {
    onCollapse(item) {
      this.$emit('onCollapse', item);
    }
  },
  watch: {
    data: {
      handler: function(newVal) {
        this.item = newVal;
      },
      deep: true,
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/global/global.scss";

.sidebar-menu-subitem {
  width: 100%;
  text-align: left;
  padding-left: 25px;
  padding-top: 6px;
  padding-bottom: 6px;
  color: $Gray_80;
}
</style>
