import * as types from "../mutation-types";

const mutations = {
    [types.SET_SCOUTINGS]: (state, payload) => {
        state.scoutings = payload;
    },
    [types.SET_SCOUTING]: (state, payload) => {
        state.scouting = payload;
    },
    [types.SET_SCOUTING_FILTERS]: (state, payload) => {
        state.scouting_filters = payload;
    },
}

export default mutations;
