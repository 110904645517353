import * as api from '../../api/index'
import * as endpoints from '../../api/endpoints'
import * as types from "../../mutation-types";

const actions = {
  getSCWorkLogs({commit}, payload) {
    return api.fetch(endpoints.SC_WORK_LOG, payload, commit,(response) => {
      commit(types.SET_SC_WORK_LOGS, response.data.data);
      commit("common/"+types.SET_PAGINATION, response.data.pagination, { root: true });
    });
  },
  getSCWorkLog({commit}, payload) {
    return api.fetch(endpoints.SC_WORK_LOG+'/'+payload.uuid, payload, commit,(response) => {
      commit(types.SET_SC_WORK_LOG, response.data);
    });
  },
  createSCWorkLog({commit}, payload) {
    return api.post(endpoints.SC_WORK_LOG, payload, commit, (response) => {
      commit(types.SET_SC_WORK_LOG, response.data);
    });
  },
  updateSCWorkLog({commit}, payload) {
    return api.put(endpoints.SC_WORK_LOG+'/'+payload.uuid, payload, commit, (response) => {
      commit(types.UPDATE_SC_WORK_LOG_ITEM, response.data);
    });
  },
  deleteSCWorkLog({commit}, payload) {
    return api.remove(endpoints.SC_WORK_LOG+'/'+payload.uuid, payload, commit, (response) => {
      commit(types.DELETE_SC_WORK_LOG_ITEM, response.data);
    });
  },
}

export default actions;
