import * as types from "../../mutation-types";

const mutations = {
  [types.SET_SC_SHIFTS]: (state, payload) => {
    state.sc_shifts = payload;
  },
  [types.SET_SC_SHIFT]: (state, payload) => {
    state.sc_shift = payload;
  },
  [types.ADD_SC_SHIFT_ITEM]: (state, payload) => {
    state.sc_shifts.push(payload);
  },
  [types.UPDATE_SC_SHIFT_ITEM]: (state, payload) => {
    state.sc_shifts.splice(state.sc_shifts.indexOf(
      state.sc_shifts.find(c => c.uuid === payload.uuid)
    ), 1, payload);
  },
  [types.DELETE_SC_SHIFT_ITEM]: (state, payload) => {
    state.sc_shifts.splice(state.sc_shifts.indexOf(
      state.sc_shifts.find(c => c.uuid === payload.uuid)
    ), 1);
  },
  [types.SET_SC_SHIFT_FILTERS]: (state, payload) => {
    state.sc_shift_filters = payload;
  },
}

export default mutations;
