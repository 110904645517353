<template>
  <th
    class="td-text"
    :class="[th.class, alignClass]"
    :width="th.width ? th.width : 'auto'"
    :align="th.align ? th.align : 'left'"
    >
    {{ th.name }}
    <img src="~@/assets/images/desktop/icons/controls/arrow-up.svg" class="icon-svg sort-by primary-filter" :title="$t('common.sort_by', { name: th.name })" v-if="(th.sortable ?? true) && sortDirection === 'ASC'" @click="setSortByFilter()"/>
    <img src="~@/assets/images/desktop/icons/controls/arrow-down.svg" class="icon-svg sort-by primary-filter" :title="$t('common.sort_by', { name: th.name })" v-else-if="(th.sortable ?? true) && sortDirection === 'DESC'" @click="setSortByFilter()"/>
    <img src="~@/assets/images/desktop/icons/controls/expand.svg" class="icon-svg sort-by" :title="$t('common.sort_by', { name: th.name })" v-else-if="(th.sortable ?? true)" @click="setSortByFilter()"/>
    <div class="subtitle_col" v-if="index === 0 && subtitle">{{ subtitle }}</div>
  </th>
</template>

<script>
export default {
  name: 'Row',
  props: ['data', 'index', 'infiniteScroll', 'subtitle'],
  data() {
    return {
      th: this.data ? this.data : [],
      sortDirection: '',
      subtitle_local: this.subtitle ? this.subtitle : null,
    }
  },
  computed: {
    icon() {
      return `~@/assets/images/desktop/icons/controls/${this.arrowDirection}.svg`;
    },
    arrowDirection() {
        switch(this.sortDirection) {
            case 'ASC':
              return 'arrow-up';
            case 'DESC':
              return 'arrow-down';
            default:
              return 'expand';
        }
    },
    alignClass() {
      return this.th.align ? 'text-'+this.th.align : 'text-left';
    },
  },
  methods: {
    setSortByFilter() {
      this.sortDirection = (this.sortDirection === 'ASC' || this.sortDirection === '') ? 'DESC' : 'ASC'
        this.$emit('onSortBy', {
          sortBy: this.th.key ? this.th.key : this.th.col,
          sortDirection: this.sortDirection
        })
    },
    resetSorting() {
      this.sortDirection = '';
      this.sortBy = '';
    }
  },
  watch: {
    data: {
      handler: function(newVal) {
        this.th = newVal;
      },
      deep: true,
    },
    subtitle: {
      handler: function(newVal) {
          this.subtitle_local = newVal;
      },
      deep: true,
      immediate: true,
    }
  }
}
</script>

<style scoped lang="scss">
.subtitle_col {
  font-size: 10px;
  padding: 0px;
  position: absolute;
  top: 36px;
  color: #348afa;
}
.sort-by {
  padding: 0;
  background: transparent;
  width: 14px;
  height: 14px;
}
</style>
