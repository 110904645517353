<template>
    <div class="payment_year_detail">
        <div >
            <div class="contract_val">Valoare contract: <span class="contract_val_bold">{{ formatNumber(contract_value) }}{{contract_value ? ' Ron' : ''}}</span></div>
        </div>
        <div>
            <div class="contract_val">Valoare rămasă: <span class="contract_val_bold">{{ formatNumber(debt) }}{{debt ? ' Ron' : ''}}</span></div>
        </div>
        <div>
            <div class="contract_payment_line" v-for="(payment, idx) in paymentList" :key="payment.uuid">
                <div class="contract_payment_line_header">
                    <DxCheckBox v-if="editSelectionMode || deleteSelectionMode" v-model="payment.selected" @valueChanged="selectPayment($event, payment, idx)" class="select_payment_checkbox"/>
                    <div class="contract_val payment_label">Plata {{idx + 1}}:</div>
                    <div class="contract_val">
                        <div>{{ payment.payment_date }}</div>
                        <div class="contract_val_bold" v-if="payment.products && payment.products.length > 0">
                            <div v-for="product in payment.products">{{ formatNumber(product.product_quantity) }} {{ product.default_measure_unit.name }} {{ product.product_name }}</div>
                        </div>
                        <div class="contract_val_bold" v-else>{{ payment.paid_value }} Ron</div>
                        <div v-if="payment.notes !== null"> {{ payment.notes }} </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import { mapGetters, mapActions } from "vuex";
import { DxCheckBox } from 'devextreme-vue/check-box';

export default {
    name: "PaymentYears",
    data(){
        return {
            paymentList:[],
            dataLoaded:false,
        };
    },
    props:{
        contract_uuid: String,
        owner_uuid: String,
        year_uuid: String,
        contract_value: String|Number,
        debt: Number,
        editSelectionMode: Boolean,
        deleteSelectionMode: Boolean,
        yeareDetailsVisibility: Boolean,
        allPaymentsAreSelected: {
            type: Boolean,
            default: false
        },
        templateData: {
            type: Object,
            default: {}
        },
    },
    components: {
        DxCheckBox
    },
    watch: {
        yeareDetailsVisibility(newVal, oldVal){
            if(newVal && this.dataLoaded == false){
                this.loadData();
            }
        }
    },
    methods: {
        ...mapActions({
            getPayment: "leasecontracts/getPayment",
            getPaymentsByBalance: "leasecontracts/getPaymentsByBalance",
        }),
        formatNumber(value){
            if(value || value == 0){
                return value.toFixed(2).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
            }
            return "Nu se calculeaza"
        },
        loadData(){
            this.dataLoaded = true;
            this.getPaymentsByBalance({balance_uuid: this.year_uuid}).then(response => {
                this.paymentList = response.data.payments;
                this.paymentList.forEach((payment, idx) => {
                    payment.selected = this.allPaymentsAreSelected;
                    payment.contractInfo = this.templateData;
                });
            });
        },
        getSelectedPayments(){
            return this.paymentList.filter(obj => obj.selected == true);
        },
        selectPayment(option, payment, idx){
            this.$set(this.paymentList, idx, payment);
        },
        selectAllPayment(option){
            this.paymentList.forEach((payment, idx) => {
                payment.selected = option;
                this.$set(this.paymentList, idx, payment);
            });
        }
    }
}
</script>

