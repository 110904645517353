<template>
  <div class="filter-actions col-lg-12 filters p-3">
    <div class="form-group row align-items-center">

      <div class="col-lg-3">
        <label class="control-label d-block">{{$t("works.inputs.work_interval")}} - From</label>
        <date-picker2
          v-model="filters.work_min_date"
          :value-type="created_at_to_input"
          format="YYYY-MM-DD"
          type="date"
          placeholder="yyyy-mm-dd"
          :input-class="['date-picker-style']"
          ref="date_from"
        ></date-picker2>
      </div>
      <div class="col-lg-3">
        <label class="control-label d-block">{{$t("To")}}</label>
        <date-picker2
          v-model="filters.work_max_date"
          :value-type="created_at_to_input"
          format="YYYY-MM-DD"
          type="date"
          placeholder="yyyy-mm-dd"
          :input-class="['date-picker-style']"
          ref="date_from"
        ></date-picker2>
      </div>

      <div class="col-lg-3">
        <label class="control-label d-block">{{$t("common.created_at")}}</label>
        <date-picker2
          v-model="filters.created_at"
          :value-type="created_at_to_input"
          format="YYYY-MM-DD"
          type="date"
          placeholder="yyyy-mm-dd"
          :input-class="['date-picker-style']"
          ref="date_from"

        ></date-picker2>
      </div>
      <div class="col-lg-3">
        <label class="control-label">{{$t('common.name')}}</label>
        <input v-model="filters.related_works_names" class="form-control" />
      </div>
    </div>

    <div class="form-group row">
      <div class="col-lg-3">
        <label class="control-label">{{$t('works.inputs.product_name')}}</label>
        <input v-model="filters.product" class="form-control" />
      </div>

      <div class="col-lg-3">
        <label class="control-label">{{$t('works.inputs.quantity')}}</label>
        <input v-model="filters.quantity" class="form-control" />
      </div>
      <div class="col-lg-3">
        <label class="control-label">{{$t('works.inputs.unit')}}</label>
        <input v-model="filters.measure_unit_symbol" class="form-control" />
      </div>
      <div class="col-lg-3">
        <label class="control-label">{{$t('works.inputs.dose_ha')}}</label>
        <input v-model="filters.dose_per_ha" class="form-control" />
      </div>
    </div>
    <div class="form-group row">
      <div class="col-lg-2 mt-4">
        <button class="btn-sm btn-primary col-lg-12" @click="applyFiltersClick">{{$t("common.show")}}</button>
      </div>
      <div class="col-lg-3 mt-4">
        <button class="btn-sm btn-secondary col-lg-12" @click="resetFilters">{{$t("common.reset_filters")}}</button>
      </div>
    </div>
  </div>
</template>
<script>
import {mapActions, mapGetters} from "vuex";
import DatePicker2 from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import * as endpoints from "@/store/api/endpoints";
import * as type from "@/store/mutation-types";

export default {
  name: 'inputFilters',
  data() {
    return {
      filters: this.inputFilters ? this.inputFilters : {},
      created_at_to_input: null,
    }
  },
  computed: {
    ...mapGetters({
      inputFilters: 'works-inputs/inputFilters',
      departments: 'company/departments',
      currentDepartment: 'auth/currentDepartment',
    }),
  },
  methods: {
    ...mapActions({
      getInputs: 'works-inputs/getInputs',
    }),
    resetFilters() {
      this.filters = {
        work_min_date: '',
        work_max_date: '',
        created_at: '',
        related_works_names: '',
        quantity: '',
        measure_unit_symbol: '',
        dose_per_ha:'',
      }
      this.created_at = null;
      this.created_at_to_input = null;
      this.$store.commit("works-inputs/"+type.SET_INPUT_FILTERS, this.filters);
    },
    applyFiltersClick() {
      this.$set(this.filters, 'page', 1);
      this.applyFilters();
    },
    applyFilters() {
      this.$emit('onFiltersApplied', this.filters);
      this.filters.url =  endpoints.BATCH_HEADERS;
      //this.filters.department_uuid = this.currentDepartment.department_uuid;
      this.getInputs(this.filters);
    },
  },
  components: {
    DatePicker2,
  },
  mounted(){
    this.resetFilters();
  },
  watch: {
    inputFilters: {
      handler: function(newVal) {
        this.filters = newVal;
      },
      deep: true,
    }
  }
}
</script>
