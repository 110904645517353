<template>
  <div class="boolean-control">
    <label class="control-label font-weight-bold">{{ data.name }}</label>
    <input type="text" v-model="value" class="form-control"
           :class="{ 'field-error' : this.$v.value.$error }"
           :password="data.type.password" v-on:keydown="onlyNumbers"/>
  </div>
</template>

<script>
import {only_numbers} from "@/helpers/common";
import requiredIf from "vuelidate/lib/validators/requiredIf";

export default {
  name: 'InputControl',
  props: ['data', 'size'],
  data() {
    return {
      value: '',
      controlData: this.data ? this.data : {},
    }
  },
  methods: {
    onlyNumbers(event) {
      if(this.data.type === 'integer') {
        return only_numbers(event);
      }

      return true;
    },
    validate() {
      this.$v.value.$touch();
      return !this.$v.value.$invalid;
    }
  },
  mounted() {
    this.$set(this, 'value', this.data.value);
    this.$set(this.controlData, 'validate', this.validate);
  },
  watch: {
    data: {
      handler: function(newVal) {
        this.controlData = newVal;
      },
      deep: true,
      immediate: true,
    },
    value: function(newVal) {
      this.$emit('onChange', newVal);
    }
  },
  validations() {
    return {
      value: {
        required: requiredIf(function(val) {
          return this.data.is_required === 1;
        })
      },
    }
  },
}
</script>
