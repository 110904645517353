<template>
  <vue-bootstrap-typeahead
    :data="items"
    :value="search"
    :class="{ 'field-error' : $v.value.name.$error }"
    ref="search"
    :serializer="s => s[searchKey]"
    :placeholder="placeholder"
    @hit="onSelected"
    @input="onChange"
  />
</template>

<script>
import VueBootstrapTypeahead from 'vue-bootstrap-typeahead'
import requiredIf from "vuelidate/src/validators/requiredIf";
import Integer from "vuelidate/lib/validators/integer";

export default {
  name: 'AutocompleteSearch',
  props: {
    data: {
      type: Object|String|null
    },
    placeholder: {
      type: String|null,
    },
    required: {
      type: Boolean|null,
    },
    dispatch: {
      type: Function,
    },
    callback: {
      type: Function|null,
    },
    searchKey: {
      type: String|null,
      default: (val) => 'name'
    },
    innerClass: {
      type: String|null
    },
    minChars: {
      type: Integer|null,
      default: (val) => 2
    },
    disabled: {
      type: Boolean|null,
      default:(val) => false,
    }
  },
  data() {
    return {
      items: [],
      search: '',
      value: null,
    }
  },
  components: {
    VueBootstrapTypeahead
  },
  methods: {
    onSelected(data) {
      this.$emit('onSelected', data);
    },
    validate() {
      this.$v.value.$touch();
      return !this.$v.value.$invalid;
    },
    onChange(newVal) {
      let vm = this;
      this.searchKey = this.searchKey ?? 'name';

      let searchCriteria = {};
      searchCriteria[this.searchKey] = newVal;

      let item = null;
      if(newVal && newVal.length >= this.minChars) {
        this.dispatch(searchCriteria).then((resp) => {
          vm.items = resp.data;
          item = vm.items.find(it => it[this.searchKey] === newVal);

          if(item) {
            this.$set(this, 'value', {
              uuid: item.uuid,
              name: newVal,
            });
          } else {
            this.$set(this, 'value', {
              name: newVal,
            });
          }

          this.$v.value.name.$reset();
          this.$emit('onChange', this.value);
        });
      } else {
        vm.items = [];
        this.value = null;
        this.$v.value.name.$reset();
        this.$emit('onReset', null);
        this.$emit('onChange', null);
      }
    },
  },
  watch: {
    data: {
      handler: function(newVal) {
        this.$refs.search.handleInput(
          newVal?.name ?? ''
        );
      },
      deep: true,

    },
    disabled: function(newVal) {
      this.$refs['search'].$refs.input.disabled = Boolean(newVal);
    }
  },
  validations() {
    return {
      value: {
        name: {
          required: requiredIf(function(val) {
            return this.required;
          })
        }
      },
    }
  }
}
</script>
