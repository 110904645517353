<template>
    <div :class="mapVisibility ? 'stock_wrapper_nomargin' : 'stock_wrapper'"
         v-if="canEdit || canDelete" v-cloak>
        <div class="stock_title_holder" v-show="!mapVisibility" >
            <search-input :parentClass="'stock_filter_search'" placeholder="Caută produs" @search="searchByText"></search-input>
        </div>
        <div v-show="!mapVisibility" class="stock_title_holder">
            <div class="stock_title_text">Stoc management</div>
                  <b-button
                    class="stock_filter_icon"
                    variant="outline-secondary"
                    size="sm"
                    pill
                    @click="showFilterDialog"
                    ><img src="@/assets/images/icons/options.svg"
                    /></b-button>

                    <b-button
                    class="stock_edit_icon"
                    variant="outline-secondary"
                    size="sm"
                    pill
                    @click="changeSelectionMode"
                    ><img src="@/assets/images/icons/edit.svg"
                    /></b-button>
                <b-button
                    class="stock_add_icon"
                    variant="outline-secondary"
                    size="sm"
                    pill
                    @click="showTransactions"
                    ><img src="@/assets/images/icons/plus.svg"
                    /></b-button>
        </div>
        <div class="stock-map-wrapper" v-if="mapVisibility">
            <google-map
                ref="map"
                :showMap="mapVisibility"
                :selection_mode="false"
                :parcel_uuid="selected_parcel_uuid_for_map"
                :initMapInMount="true"
                @onParcelClicked="showParcelInfo"
                @showToastMessage="showToastMessage"
                @onMapReady="onMapReady"
                :parcelInfoDialogVisibility="cellInfoVisibility"></google-map>

            <parcel-info-dialog
                :showPopup="cellInfoVisibility"
                @hidden="hideParcellInfo"
                :filters="workListFilters"
                @closeAndSwitchToList="hideParcellInfoAndShowList"
                :season="currentSeason"
                :parcel_uuid="selected_parcel_uuid_for_map"
                :parcel_name="selected_parcel_name_for_map"
                :parcel_surface="selected_parcel_surface_for_map"
                :layer="selected_layer"
            ></parcel-info-dialog>
        </div>
        <stock-list v-show="!mapVisibility" ref="stockList" :filterData="filterData" :hideZeroStock="false"></stock-list>
        <stock-filter ref="stockFilter" :showPopup="stockFilterVisibility" @hidden="hideFilterDialog" @hideOnZeroChange="hideFilterOnStockZeroChange" :filterData="filterData"/>
        <mobile-footer :menuItems="footerMenuItems"></mobile-footer>
        <toast-message ref="toastMessage"></toast-message>
        <transactions :showPopup="transactionsVisibility" @hidden="hideTransactions" @showTransactionMessage="showTransactionMessage"></transactions>
    </div>
  <div v-else>
    <forbidden></forbidden>
  </div>
</template>

<script>

import { mapGetters, mapActions } from 'vuex'
import StockList from './partials/stockList.vue'
import  MobileFooter  from "../../components/Mobile/Footer.vue"
import SearchInput  from "../../components/Mobile/searchInput.vue"
import ToastMessage from "../../components/Mobile/toastMessage.vue"
import Transactions from "./dialogs/transactions.vue";
import GoogleMap from "@/components/Map/GoogleMap.vue";

import ParcelInfoDialog from "../works/applyArticles/dialogs/parcelInfoDialog.vue";
import StockFilter from './dialogs/stockFilter.vue';
import { getSubdomain, getDomain } from "../../helpers/common";
import {canDelete, canEdit, permissions, permissionTypes} from "~/helpers/Permissions";
import Forbidden from "~/pages/errors/Forbidden.vue";

export default {
    layout(context) {
        return "noheader";
    },
    name: "StockMobile",
    data(){
        return {
            transactionsVisibility: false,
            stockFilterVisibility: false,
            currentModuleCode: 'STOCK',
            selectionMode: false,
            footerMenuItems: [
                {
                    code: "STOCK",
                    spacer: false,
                    image: "products_icon_gray.svg",
                    selected_image: "products_icon.svg",
                    text: "Stock",
                    event: this.showStock,
                    selected: true
                },
                {
                    spacer: true,
                    event: ""
                },
                {
                    code: "MAP",
                    spacer: false,
                    image: "pin_inactive.svg",
                    selected_image: "pin.svg",
                    text: "Hartă",
                    event: this.showMap,
                    selected: false
                },
                {
                    spacer: true,
                    event: ""
                },
                {
                    code: "REPORTS",
                    spacer: false,
                    image: "report_icon.svg",
                    selected_image: "report_icon_green.svg",
                    text: "Rapoarte",
                    event: this.showReports,
                    selected:false
                },
            ],
             filterData:{
                name: '',
                has_stock: 1,
            },
            mapVisibility: false,
            currentSeason:'',
            local_seasons: this.seasons ? this.seasons : [],
            selected_parcel_uuid_for_map: null,
            selected_parcel_name_for_map: null,
            selected_parcel_surface_for_map: null,
            selected_layer: null,
            cellInfoVisibility: false,
            workListFilters: {},
            isMapReady: false,
        };
    },
    components: {
      Forbidden,
        StockList,
        MobileFooter,
        SearchInput,
        ToastMessage,
        Transactions,
        GoogleMap,
        ParcelInfoDialog,
        StockFilter
    },
    computed: {
      config() {
        return {
          permission: permissions.STOCK_MANAGEMENT,
          permission_type: permissionTypes.module,
        }
      },
      canEdit() {
        return canEdit(this);
      },
      canDelete() {
        return canDelete(this);
      },
        ...mapGetters({
            seasons: 'seasons/seasons',
            currentDepartment: "auth/currentDepartment",
            departments: "company/departments",
            company: "auth/domain",
        }),
        current_season_getter() {
            return this.currentSeason;
        },

    },
    watch: {
         seasons: {
            handler: function(newVal) {
                this.seasons_local = newVal;
                //this.setCurrentSeason(newVal);
            }
        },
         currentSeason: {
            handler: function(newVal) {
               this.setLayersSeason(newVal);
            }
        },

    },
    methods: {
         ...mapActions({
            getLayers: "layers/getLayers",
            getSeasons: "seasons/getSeasons",
            changeDepartment: "auth/changeDepartment",
            checkDomain: "auth/checkDomain",
            getDepartments: "company/getDepartments",
        }),
        showFilterDialog(){
            this.stockFilterVisibility = true;
        },
        hideFilterDialog(){
            this.stockFilterVisibility = false;
        },
        hideFilterOnStockZeroChange(show_zero_stock){
            this.stockFilterVisibility = false;
            if(show_zero_stock == 1){
                this.$set(this.filterData, 'has_stock_with_zero', show_zero_stock);
                this.$delete(this.filterData, 'has_stock');
            }
            else{
                this.$set(this.filterData, 'has_stock', 1);
                this.$delete(this.filterData, 'has_stock_with_zero');
            }
        },
        async setLayersSeason(season_uuid){
             if(season_uuid && season_uuid.length > 0 && this.$store.state.layers.layer_season_uuid != season_uuid){
                this.$store.commit("layers/SET_LAYER_SEASON", season_uuid);

                if(this.isMapReady) {
                  this.$refs.map.setLoad(true);
                  await this.$store.dispatch('layers/getLayers', {
                    department_uuid: this.$store.getters['auth/currentDepartment'].department_uuid,
                    season_uuid: season_uuid,
                  }).then((resp) => {
                    this.$refs.map.setLoad(false);
                  })
                    .catch((e) => {
                      this.$refs.map.setLoad(false);
                      console.error('Layer load error: ', e);
                    });
                }
            }
        },
        showstockFilter(){
            this.$refs.toastMessage.showInfo('Funcția este în curs de dezvoltare!');
        },
        changeSelectionMode(){
            this.$refs.toastMessage.showInfo('Funcția este în curs de dezvoltare!');
        },
        showStock(){
            this.currentModuleCode = 'STOCK';
            this.setActiveFooterMenu('STOCK');
            this.mapVisibility = false;
        },
        showMap(){
            this.currentModuleCode = 'MAP';
            this.setActiveFooterMenu('MAP');
            this.mapVisibility = true;
            //this.$refs.toastMessage.showInfo('Funcția este în curs de dezvoltare!');
        },
        showReports(){
            // this.currentModuleCode = 'REPORTS';
            // this.setActiveFooterMenu('REPORTS');
            this.$refs.toastMessage.showInfo('Funcția este în curs de dezvoltare!');
        },
        searchByText(searchText){
            this.$set(this.filterData, 'name', searchText);
        },
        showTransactions(){
            this.transactionsVisibility = true;
        },
        hideTransactions(){
            this.transactionsVisibility = false;
        },
        showTransactionMessage(message){
            this.$refs.toastMessage.showSuccess(message);
            this.$refs.stockList.reloadList();
        },
         setActiveFooterMenu(code){
            this.footerMenuItems.forEach((menu_item) => {
                if(menu_item.code == code){
                    menu_item.selected = true;
                }
                else if(!menu_item.spacer){
                    menu_item.selected = false;
                }
            });
        },
        showToastMessage(type, message) {
             this.$refs.toastMessage.showToastMessage(type, message);
        },
         mapSeasonChanged(event){
            // console.log('STOCK SEASON CHANGED ', event);
            // let _currentSeason = event.selectedItem ? event.selectedItem.uuid : '';
            // this.currentSeason = _currentSeason;
            // this.$set(this, 'currentSeason', _currentSeason);
            // console.log('START LAYER LOAD FROM STOCK ', this.currentSeason);
            // this.$refs.map.loadLayers(this.currentSeason);
        },
        setCurrentSeason(seasons){
            if(seasons.length === 0){
                this.currentSeason = seasons[0].uuid;
                this.$set(this, 'currentSeason', seasons[0].uuid);
                return;
            }
            let idxSeason = seasons[0];
            for(let i = 0; i < seasons.length; i++ ){
                if(new Date(seasons[i].created_at) > new Date(idxSeason.created_at)){
                    idxSeason = seasons[i];
                    this.$set(this, 'currentSeason', seasons[i].uuid);
                }
            }
            this.$set(this, 'currentSeason', idxSeason.uuid);
        },
        showParcelInfo(layer){
            if(layer && layer.parcel_uuid) {
                this.selected_parcel_uuid_for_map = layer.parcel_uuid;
                this.selected_parcel_name_for_map = layer.name;
                this.selected_parcel_surface_for_map = layer.surface.toString();
                this.selected_layer = layer;
                this.workListFilters.src = 'map';
                this.cellInfoVisibility = true;
            }
        },
        hideParcellInfo(){
            this.cellInfoVisibility = false;
        },
         hideParcellInfoAndShowList(){
            this.cellInfoVisibility = false;
            this.mapVisibility = false;
            this.setActiveFooterMenu("STOCK");
        },
         selectFirstDepartment(departments) {
            if (departments.length > 0) {
                this.changeDepartment({
                    company_uuid: this.company.uuid,
                    company_name: this.company.company,
                    department_uuid: departments[0].uuid,
                    department_name: departments[0].name,
                }).then((resp) => {
                if (
                    resp.default_department &&
                    resp.default_department.department_uuid === departments[0].uuid
                ) {
                    this.getSeasons({"department_uuid": this.currentDepartment.department_uuid});
                } else {
                    errorMessage(vm, vm.$t("error_changing_department"));
                }
                });
            } else {
                this.$refs.toastMessage.showError("Nu sunt configurate departamentele! Contactați administratorul de sistem!");
            }
        },
      onMapReady(map) {
        this.isMapReady = Boolean(map);
      }
    },
    mounted(){
        this.checkDomain({ domain: getSubdomain() })
        .catch((err) => {
            console.log('Check domain error => ', err);
        })
        .then((data) => {

            this.getDepartments({ company_uuid: this.company.uuid }).then(
            (response) => {
                if (!response.find(obj => obj.uuid === this.currentDepartment?.department_uuid)) {
                    this.selectFirstDepartment(response);
                } else {
                    this.getSeasons().then(seasonResponse => {
                       // this.setCurrentSeason(seasonResponse.data);
                    });
                }
            }
            );
        });
         //this.getSeasons({"department_uuid": this.currentDepartment.department_uuid});
    }
}
</script>

<style lang="scss">
    body {
        background-color: #f5f5f5;
    }
    .toastStyle{
        z-index: 6010;
    }
    @import "@/assets/scss/stockMobile/stock.scss";
</style>
