<template>
  <div class="view" v-if="canEdit || canDelete" v-cloak>
    <card :title="$t('create_user')" id="user-list">
      <agro-filter-container :new-object="newObject">
        <user-list-filters ref="filters" @onFiltersApplied="onFiltersApplied"/>
      </agro-filter-container>
    </card>
    <div class="content-body pt-4">
      <div class="row">
        <div class="col-lg-12">
          <agro-grid
            :headers="headers"
            :rows="items"
            :paginate="true"
            :permission="config"
            :action="onPageSelected"
            :loading="loading"
            @onSortBy="onSortBy"
          >
          </agro-grid>
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <forbidden></forbidden>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import * as type from '../../store/mutation-types'
import AgroGrid from "@/components/AgroGrid/index";
import {canDelete, canEdit, permissions, permissionTypes} from "@/helpers/Permissions";
import UserListFilters from "~/pages/users/partials/UserListFilters.vue";
import UserActionControls from "~/pages/users/partials/UserActionControls.vue";
import Forbidden from "~/pages/errors/Forbidden.vue";
import AgroFilterContainer from "@/components/AgroFilter/partials/AgroFilterContainer.vue";

export default {
  name: 'Users',
  middleware: ['auth', 'check-company-admin'],
  data() {
    return {
      currentPage: 1,
      filters: this.userFilters ? this.userFilters : {},
      items: this.users ? this.users : [],
      pager: this.pagination ? this.pagination : {},
      loading: false,
    }
  },
  components: {
    Forbidden,
    UserListFilters,
    AgroGrid,
    AgroFilterContainer,
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
      userFilters: 'user/userFilters',
      pagination: 'common/pagination',
      users: 'user/users',
    }),
    config() {
      return {
        permission: permissions.USER_MANAGEMENT,
        permission_type: permissionTypes.module,
        edit: {
          url: '/users/{uuid}',
          callback: this.onItemEdited,
        },
        delete: {
          method: this.deleteObject,
          callback: this.onItemDeleted,
        },
        additionalControls: {
          template: UserActionControls,
        },
      };
    },
    canEdit() {
      return canEdit(this);
    },
    canDelete() {
      return canDelete(this);
    },
    headers() {
      return  [
        { name: this.$t('user.name'), col: 'name', width: '22%', sortable: true, },
        { name: this.$t('user.email'), col: 'email',align: 'left', width: '10%', sortable: true },
        {
          name: this.$t('user.units_and_roles'),
          colTemplate: this.unitsAndRoles,
          align: 'center',
          width: '12%',
        },
        { name: this.$t('company_admin'), col: this.isCompanyAdmin, align:'center', width: '14%'},
        { name: this.$t('common.created_at'), col: 'created_at', align:'center', width: '11%', sortable: true },
        { name: this.$t('common.last_update'), col: 'updated_at', align:'center', width: '13%', sortable: true },
      ];
    },
  },
  methods: {
    ...mapActions({
      deleteObject: 'user/deleteUser',
    }),
    onPageSelected(page_id) {
      this.filters.page = page_id;
      this.$store.commit("user/"+type.SET_USER_FILTERS, this.filters);
      this.$refs.filters.applyFilters();
    },
    newObject() {
      this.$router.push({
        path: 'users/add'
      });
    },
    onItemEdited(item) {
      console.log('onItemEdited', item);
    },
    onItemDeleted(item) {
      this.items.splice(
        this.items.indexOf(
          this.items.find(it => it.uuid === item.uuid)
        ), 1
      );
    },
    onFiltersApplied(filters) {
      this.loading = true;
    },
    onSortBy(data) {
      this.userFilters.sortBy = data.sortBy;
      this.userFilters.sortDirection = data.sortDirection;
      this.onPageSelected(this.currentPage);
    },
    unitsAndRoles(col) {
      if ((col.units > 0) || (col.roles > 0)) {
        return 'UnitAndRoleLink';
      }
      return null;
    },
    isCompanyAdmin(col) {
      if (col.is_company_admin === 1) {
        return '<span>'+this.$t('company_admin')+'</span>';
      } else if (col.units.length > 0) {
        return '<span>'+(col.units.length)+' '+this.$t('units')+'</span>';
      } else {
        return '-';
      }
    }
  },
  watch: {
    users: {
      handler: function(newVal) {
        this.items = newVal;
      },
      deep: true,
    },
    pagination: {
      handler: function(newVal) {
        this.pager = newVal;
      },
      deep: true,
    },
  }
}
</script>

<style scoped>

</style>
