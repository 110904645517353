import * as api from '../api/index'
import * as endpoints from '../api/endpoints'
import * as types from "../mutation-types";

const actions = {
    getSeasons({commit}, payload) {
        return api.fetch(endpoints.SEASONS, payload, commit,(response) => {
          commit(types.SET_SEASONS, response.data.data);
          commit("common/"+types.SET_PAGINATION, response.data.meta, { root: true });
        });
    },

    getSeasonsForFilter({commit}, payload) {
      return api.fetch(endpoints.SEASONS_FILTER, payload, commit,(response) => {
        
      });
  },

    createSeasons({commit}, payload) {
      return api.post(endpoints.SEASONS, payload, commit,(response) => {
        //commit(types.SET_SEASON, response.data);
      });
  },
}

export default actions;
