import * as api from '../api/index'
import * as endpoints from '../api/endpoints'
import * as types from "../mutation-types";

const actions = {
    getDepartmentTypes({commit}, payload) {
      return api.fetch(endpoints.DEPARTMENT_TYPES, payload, commit,(response) => {
        commit(types.SET_DEPARTMENT_TYPES, response.data);
      });
    },
    getDepartmentFilterTypes({commit}, payload) {
        return api.fetch(endpoints.DEPARTMENT_FILTER_TYPES, payload, commit,(response) => {
          commit(types.SET_DEPARTMENT_FILTER_TYPES, response.data);
        });
    },
}

export default actions;
