import * as types from "../../mutation-types";

const mutations = {
  [types.SET_SC_WORK_PIVOTS]: (state, payload) => {
    state.sc_work_pivots = payload;
  },
  [types.SET_SC_WORK_COLLECTOR_HECTARE_DATA]: (state, payload) => {
    state.sc_work_collector_hectare_data = payload;
  },
  [types.SET_SC_WORK_COLLECTOR_COLUMN_SETTINGS]: (state, payload) => {
    state.sc_work_collector_column_settings = payload;
  },
  [types.SET_SC_WORK_HIERARCHY]: (state, payload) => {
    state.sc_work_hierarchy = payload;
  },
  [types.SET_SC_WORK_PIVOT]: (state, payload) => {
    state.sc_work_pivot = payload;
  },
  [types.ADD_SC_WORK_PIVOT_ITEM]: (state, payload) => {
    state.sc_work_pivots.push(payload);
  },
  [types.UPDATE_SC_WORK_PIVOT_ITEM]: (state, payload) => {
    state.sc_work_pivots.splice(state.sc_work_pivots.indexOf(
      state.sc_work_pivots.find(c => c.uuid === payload.uuid)
    ), 1, payload);
  },
  [types.DELETE_SC_WORK_PIVOT_ITEM]: (state, payload) => {
    state.sc_work_pivots.splice(state.sc_work_pivots.indexOf(
      state.sc_work_pivots.find(c => c.uuid === payload.uuid)
    ), 1);
  },
  [types.SET_SC_WORK_PIVOT_FILTERS]: (state, payload) => {
    state.sc_work_pivot_filters = payload;
  },
  [types.SET_SC_WORK_HIERARCHY_FILTERS]: (state, payload) => {
    state.sc_work_hierarchy_filters = payload;
  },
  [types.SET_SC_DAILY_GRID_COLUMNS]: (state, payload) => {
    state.sc_daily_grid_columns = payload;
  },
}

export default mutations;
