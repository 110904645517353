<template>
  <div id="document-block" class="pt-3" v-if="canEdit || canDelete" v-cloak>
    <div class="setting form-group row">
      <div class="col-lg-12">
        <div class="row">
          <div class="col-lg-6 form-group">
            <label class="control-label">{{$t("tools.justifying_documents.document_type")}}</label>
            <b-form-select
              v-model="data.type"
              class="form-control"
              @change="onDocumentTypeChanged"
              :class="{ 'field-error' : $v.data.type.$error }"
              :options="document_type_list"></b-form-select>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-6">
            <div class="row">
              <unit-selector-d-d-l :inputUUID="data.department_uuid" @onChange="onDynamicFiltersChanged"/>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-6 form-group" v-if="data.department_uuid">
            <label class="control-label">{{$t("common.warehouse")}}</label>
            <b-form-select
              v-model="data.warehouse_uuid"
              class="form-control"
              :options="warehouse_list"></b-form-select>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-6 form-group">
            <label class="control-label">{{$t("tools.justifying_documents.name")}}</label>
            <input v-model="data.name"
                   :class="{ 'field-error' : $v.data.name.$error }"
                   class="form-control" :placeholder="$t('tools.justifying_documents.name')"/>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-3 form-group">
            <label class="control-label">{{$t("tools.justifying_documents.serie")}}</label>
            <input v-model="data.serie"
                   :class="{ 'field-error' : $v.data.serie.$error }"
                   class="form-control text-uppercase" :placeholder="$t('tools.justifying_documents.serie')"/>
          </div>
          <div class="col-lg-3 form-group">
            <label class="control-label">{{$t("tools.justifying_documents.start_nr")}}</label>
            <input v-model="data.start_number"
                   class="form-control"
                   v-on:keydown="onlyNumbers"
                   :placeholder="$t('tools.justifying_documents.start_nr')"/>
          </div>
        </div>
        <h5 v-if="model">{{ $t('tools.justifying_documents.model', { model: model }) }}</h5>
      </div>
    </div>
    <agro-action-buttons
      :data="data"
      :can-edit="canEdit"
      :can-delete="canDelete"
      @onSave="save"
      @onSaveAndNew="saveAndNew"
      :save-and-new="true"
      @onDelete="confirmDelete"
      @onGotoList="gotoList"
    ></agro-action-buttons>
  </div>
  <div v-else>
    <forbidden></forbidden>
  </div>
</template>

<script>
import ControlContainer from "@/pages/tools/settings/partials/controls/ControlContainer";
import AgroActionButtons from "@/components/AgroActionButtons/index";
import Forbidden from "@/pages/errors/Forbidden";
import { mapGetters, mapActions } from 'vuex'
import {buildDefaultDropdownList, errorMessage, only_numbers, successMessage} from "@/helpers/common";
import required from "vuelidate/src/validators/required";
import * as actions from "~/helpers/generalActions";
import {canDelete, canEdit, permissions, permissionTypes} from "~/helpers/Permissions";
import AgroFilter from "~/components/AgroFilter/Index.vue";
import UnitSelectorDDL from "~/components/AgroFilter/UnitSelectorDDL.vue";

export default {
  name: 'DocumentBlock',
  data() {
    return {
      data: this.documentBlock ? this.documentBlock : {},
      model: '',
    }
  },
  components: {
    UnitSelectorDDL,
    AgroFilter,
    ControlContainer,
    AgroActionButtons,
    Forbidden
  },
  computed: {
    ...mapGetters({
        documentBlock: 'justifyingDocuments/documentBlock',
        departments: 'company/departments',
        warehouses: 'smWarehouses/smWarehouses',
        user: 'auth/user',
        domain: 'auth/domain',
        currentDepartment: 'auth/currentDepartment',
        documentTypes: 'justifyingDocuments/documentTypes',
    }),
    config() {
      return {
        permission: permissions.JUSTIFYING_DOCUMENTS,
        permission_type: permissionTypes.module,
        get: this.get,
        create: this.create,
        update: this.update,
        list_url: '/tools/justifying-documents#'+this.data.type,
      }
    },
    actions() {
      return actions;
    },
    canEdit() {
      return canEdit(this);
    },
    canDelete() {
      return canDelete(this) && this.data.is_deletable === 1;
    },
    department_list() {
      return buildDefaultDropdownList(Object.assign([], this.departments));
    },
    warehouse_list() {
      return buildDefaultDropdownList(Object.assign([], this.warehouses));
    },
    document_type_list() {
      return buildDefaultDropdownList(Object.assign([], this.documentTypes));
    },
    type() {
        return this.documentTypes.find(t => t.uuid === this.data.type);
    },
  },
  methods: {
    ...mapActions({
        get: 'justifyingDocuments/getDocumentBlock',
        create: 'justifyingDocuments/createDocumentBlock',
        update: 'justifyingDocuments/updateDocumentBlock',
        delete: 'justifyingDocuments/deleteDocumentBlock',
        checkDocumentBlock: 'justifyingDocuments/checkDocumentBlock',
        getDepartments: 'company/getDepartments',
        getWarehouses: 'smWarehouses/getSmWarehouses',
        getDocumentTypes: 'justifyingDocuments/getDocumentTypes',
        getDocumentModel: 'justifyingDocuments/getDocumentModel',
    }),
    validateAll() {
      return this.data?.items.filter((item) => {
        return item.validate();
      }).length === this.data?.items.length;
    },
    gotoList() {
      return actions.gotoList(this);
    },
    confirmDelete() {
      return this.actions.confirmDelete(this);
    },
    prepareSave(callback, context, backPath) {
      this.$v.data.$touch();
      let vm = this;

      this.checkDocumentBlock({
        department_uuid: this.data.department_uuid,
        warehouse_uuid: this.data.warehouse_uuid,
        type: this.data.type,
      }).then((bresp) => {
        if(bresp && bresp.uuid) {
          errorMessage(vm, vm.$t('tools.justifying_documents.doc_already_in_use'));
          return false;
        }
      });

      if(!this.$v.data.$invalid) {
        if (typeof callback == 'function') {
          callback(context, backPath);
        }
      }
    },
    save() {
      this.prepareSave(
        this.actions.save, this, this.config.list_url
      )
    },
    saveAndNew() {
      this.prepareSave(
        this.actions.saveAndNew, this, this.config.list_url+'/add', {}
      );
    },
    newObject() {
      return this.actions.newObject(this);
    },
    onlyNumbers(event) {
      return only_numbers(event);
    },
    onDepartmentChanged(val) {
      let vm = this;
      this.getWarehouses({
        company_uuid: this.domain.uuid,
        department_uuid: val,
        enablePagination: 0,
      }).then((resp) => {
        vm.data.warehouse_uuid = null;
      });
    },
    onDynamicFiltersChanged(selectedUUID) {
      this.data.department_uuid = selectedUUID;
      this.onDepartmentChanged(selectedUUID);
    },
    onDocumentTypeChanged(val) {

    },
    handleInputParams() {
      this.getDocumentTypes();
      let type = this.$route.query.type;
      let uuid = this.$route.params.uuid;

      if (uuid && uuid !== 'add') {
        this.get({
          uuid: uuid,
          type: type,
        });
      } else {
        //init
        this.data.department_uuid = null;
        this.data.type = type;
        this.data.start_number = 1;
        this.onDepartmentChanged(
          this.data.department_uuid
        );
      }
    }
  },
  mounted() {
      this.handleInputParams();
  },
  watch: {
    documentBlock: {
      handler: function(newVal) {
        this.data = newVal;
      },
      deep: true,
    },
    data: {
      handler: function(newVal) {
        if (this.data.type && this.data.serie && this.data.start_number) {
          this.getDocumentModel({
            type: this.data.type,
            serie: this.data.serie,
            start_number: this.data.start_number,
          }).then((resp) => {
              this.model = resp;
          });
        }
      },
      deep: true,
    },
  },
  validations() {
    return {
      data: {
        type: {
          required,
        },
        department_uuid: {
          required,
        },
        name: {
          required,
        },
        serie: {
          required,
        },
      }
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
