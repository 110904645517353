<template>
  <div class="configurator">
    <card :title="$t('tools.configurator.template_configurator') + ' (' + $t('tools.configurator.' + lineToUnderscore(type.ref)) + ')'"
      id="configurator" v-if="canEdit || canDelete">
      <div class="row d-flex justify-content-end">
        <button class="btn btn-md btn-secondary"style="width: 250px;" @click="goBackToTypeList">{{ $t('back_to_list') }}</button>
      </div>
      <div class="row mt-3">
        <div class="col-lg-12">
          <agro-import-mapper :ref="this.type.ref" :type="this.type.id" :data="this.type"
            :calculation-formula="this.type.calculation_formula"></agro-import-mapper>
        </div>
      </div>
    </card>
    <div v-else>
      <forbidden></forbidden>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { canDelete, canEdit, permissions, permissionTypes } from "@/helpers/Permissions";
import Forbidden from "@/pages/errors/Forbidden";
import AgroImportMapper from "@/components/AgroImportMapper/index";
import { lineToUnderscore } from "~/helpers/common";
import { types } from "@/helpers/ImportTypes"

export default {
  name: 'TemplateConfigurator',
  data() {
    return {
      type: types.DATA_IMPORT,
    }
  },
  components: {
    AgroImportMapper,
    Forbidden,
  },
  computed: {
    ...mapGetters({
      templates: 'importConfig/importTemplates',
    }),
    config() {
      return {
        permission: permissions.CONFIGURATOR,
        permission_type: permissionTypes.module,
      };
    },
    canEdit() {
      return canEdit(this);
    },
    canDelete() {
      return canDelete(this);
    },
  },
  methods: {
    ...mapActions({
      createTemplate: 'importConfig/createTemplate',
      updateTemplate: 'importConfig/updateTemplate',
      deleteTemplate: 'importConfig/deleteTemplate'
    }),
    handleInputParams() {
      let vm = this;
      setTimeout(function () {
        let import_id = vm.$route.query.import_id;
        if (import_id) {
          let ref = vm.$refs[vm.type.ref];
          ref.getCsvHeadersQuery(import_id);
        }
      }, 100);
    },
    lineToUnderscore(value) {
      return lineToUnderscore(value);
    },
    goBackToTypeList() {
      this.$router.back();
    }
  },
  mounted() {
    if (this.$route.query.import_type_id) {
      let import_type_id = this.$route.query.import_type_id;
      Object.keys(types).map((key) => {
        if (key === import_type_id) {
          let temp_type = types[key];
          temp_type.create = this.createTemplate;
          temp_type.update = this.updateTemplate;
          temp_type.delete = this.deleteTemplate
          this.type = temp_type;
        }
      });
    }
    this.handleInputParams();
  },
  watch: {
    $route: {
      handler: function (newVal) {
        this.handleInputParams();
      },
      deep: true,
      immediate: true,
    }
  }
}
</script>

<style>
#configurator .title {
  padding-bottom: 0px;
}
</style>