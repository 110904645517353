<template>
  <div class="settings">
    <card :title="$t('tools.settings.title')" id="configurator" v-if="canEdit || canDelete">
      <ul class="nav nav-tabs" id="myTab" role="tablist">
        <li class="nav-item t1 gruop" v-for="(group, index) in setting_groups" :key="group.uuid">
          <a class="nav-link"
             :class="[{'active': active_tab?.code === group.code, 'text-danger': group.is_editable === 0}]"
             :id="group.code+'-tab'" data-toggle="tab"
             :href="'#'+group.code" role="tab" :aria-controls="group.code" :aria-selected="index === 0"
             @click="tabChanged(group)">
            {{ group.name }}
          </a>
        </li>
      </ul>
      <div class="tab-content" id="myTabContent">
        <div class="tab-pane fade pt-1"
             :class="[{ 'show': active_tab?.code === group.code }, { 'active': active_tab?.code === group.code }]"
             v-for="(group, index) in setting_groups" :key="group.uuid"
             :id="group.code" role="tabpanel" :aria-labelledby="group.code+'-tab'">
          <setting-group :data="group"></setting-group>
        </div>
      </div>
    </card>
    <div v-else>
      <forbidden></forbidden>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {canDelete, canEdit, permissions, permissionTypes} from "@/helpers/Permissions";
import Forbidden from "@/pages/errors/Forbidden";
import SettingGroup from "@/pages/tools/settings/partials/SettingGroup";

export default {
  name: 'Settings',
  data() {
    return {
      config: {
        permission: permissions.SETTINGS,
        permission_type: permissionTypes.module,
      },
      setting_groups: this.groups ? this.groups : [],
      active_tab: undefined,
    }
  },
  components: {
    SettingGroup,
    Forbidden
  },
  computed: {
    ...mapGetters({
        groups: 'settings/settingGroups',
      }),
    canEdit() {
      return canEdit(this);
    },
    canDelete() {
      return canDelete(this);
    },
  },
  methods: {
    ...mapActions({
        getGroups: 'settings/getSettingGroups'
      }),
      tabChanged(group) {
        this.$set(this, 'active_tab', group);
      },
  },
  mounted() {
    this.getGroups().then((resp) => {
      if(resp && resp.length > 0) {
        this.$set(this, 'active_tab', resp[0]); //first tab activate
      }
    });
  },
  watch: {
    groups: {
      handler: function(newVal) {
          this.setting_groups = newVal;
      },
      deep: true,
    }
  }
}
</script>
