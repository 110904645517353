<template>
    <div class="payment_details_holder">
        <div class="rent_details_spacer"></div>
        <div v-if="paymentYears && paymentYears.length > 0" v-for="(year, idx) in paymentYears" :key="idx" class="payment_detail_item" :selectionMode="createSelectionMode" :paymentMode="paymentMode">
            <div>
                <div style="display: flex; flex-direction: row;">
                    <DxCheckBox v-show="createSelectionMode || editSelectionMode || deleteSelectionMode" v-model="year.selected" @value-changed="yearSelectedEvent($event, year)" class="select_payment_checkbox"/>
                    <div class="payment_years">{{year.year}}</div>
                </div>
                <div @click="showYearDetails(idx)" class="rent_payment_tags">
                    <div :class="[{'warning_payment_tag': year.status != 'paid'}, {'success_payment_tag': year.status == 'paid'}]">{{getStatusName(year.status)}}</div>
                </div>
                <div v-if="year.notes && year.notes.length > 0" class="payment_description">{{year.notes}}</div>
            </div>
            <PaymentYears
                :ref="'paymentYear_' + year.uuid"
                v-show="year.yeareDetailsVisibility" 
                :contract_uuid="templateData.contract.uuid" 
                :owner_uuid="templateData.uuid"

                :year_uuid="year.uuid"
                :contract_value="year.contract_value"
                :debt="year.debt"
                :yeareDetailsVisibility="year.yeareDetailsVisibility"

                :editSelectionMode="editSelectionMode"
                :deleteSelectionMode="deleteSelectionMode"
                :templateData="templateData"
                :allPaymentsAreSelected="allPaymentsAreSelected"></PaymentYears>
        </div>
    </div>
</template>

<script>

import { DxCheckBox } from 'devextreme-vue/check-box';
import PaymentYears from './paymentYears.vue';

export default {
    name: "PaymentDetails",
    data(){
        return {
            paymentYears: [],
            allowParentSelectionHandling: true,
            allPaymentsAreSelected: false,
        };
    },
    props: {
        paymentMode: {
            type: Boolean,
            default: false,
        },
        createSelectionMode: {
            type: Boolean,
            default: false,
        },
        templateData: {
            type: Object,
            default: {}
        },
        editSelectionMode: Boolean,
        deleteSelectionMode:Boolean
    },
    components: {
        DxCheckBox,
        PaymentYears
    },
    methods: {
        getSelectedPayments(){
            
            let paymentArray = [];
            this.paymentYears.forEach((year, idx) => {
                if(this.$refs["paymentYear_" + year.uuid] && this.$refs["paymentYear_" + year.uuid][0]){
                    let tmpArray = this.$refs["paymentYear_" + year.uuid][0].getSelectedPayments();
                    if(tmpArray && tmpArray.length > 0){
                        paymentArray = [...paymentArray, ...tmpArray];
                    }
                }
            });
            
            return paymentArray;
        },
        yearSelectedEvent(option, year){
            if(this.allowParentSelectionHandling){
                this.handleParentSelection(this.templateData.idx_uuid, option.value, year);
            }
             this.allowParentSelectionHandling = true;
        },
        selectAllPaymentYears(select){       
            this.allowParentSelectionHandling = false;     
                this.paymentYears.forEach((year) => {
                    if(year.status != 'paid'){
                        this.selectYear(select, year)
                    }
                });
            this.allowParentSelectionHandling = true;
        },
        selectYear(select, year){
            year.selected = select;
        },
        selectYearPayments(selected, year){
            if(this.$refs["paymentYear_" + year.uuid] && this.$refs["paymentYear_" + year.uuid][0]){
                if(this.$refs["paymentYear_" + year.uuid][0].dataLoaded == false){
                    year.yeareDetailsVisibility = true;
                }
                this.$refs["paymentYear_" + year.uuid][0].selectAllPayment(selected);
            }
        },
        handleParentSelection(idx_uuid, selected, year){
            let hasAnySelected = this.paymentYears.filter(obj => obj.selected == true).length > 0;
            this.allPaymentsAreSelected = selected;
            this.selectYearPayments(selected, year);
            this.$emit('handleParentSelection', hasAnySelected, idx_uuid, selected, year.uuid);

        },
        initYearsFromTemplateData(){
            this.paymentYears = [];
            let templateYears = this.templateData.years ? this.templateData.years : null;
            if(templateYears && templateYears.length > 0){
                templateYears.forEach((iData) => {
                    let year = {
                        selected: false,
                        year: iData.year,
                        contract_value: iData.total_value,
                        debt: iData.debt,
                        status: iData.status,
                        //status: iData.debt == 0 ?  'paid' : (iData.debt < iData.total_value ? 'partially_paid' : 'unpaid'),
                        notes: '',
                        uuid: iData.uuid,
                        yeareDetailsVisibility: false
                    };
                    this.paymentYears.push(year);
                });
            }
        },
        getStatusName(name){
            if(name == 'paid'){
                return "Plătit";
            }
            else if(name == 'partially_paid'){
                return "plătit parțial"
            }
            else if(name == 'unpaid'){
                return "neplătit"
            }
            else{
                return name;
            }
        },
        showYearDetails(idx){
            this.paymentYears[idx].yeareDetailsVisibility = !this.paymentYears[idx].yeareDetailsVisibility;
        }
    },
    mounted(){
        this.initYearsFromTemplateData();

    }
}
</script>