<template>
  <div class="web-camera-container">
    <loader :loading="true" size="sm" v-if="isLoading"></loader>
    <div ref="fullscreenElement">
      <img class="close-cam"
           src="@/assets/images/icons/dialog-close.svg"
           @click="closeCamera"
           v-if="isFullScreen"
      />
      <vue-web-cam ref="webcam"
                   :device-id="deviceId"
                   :height="'100%'"
                   :width="screenWidth"
                   @started="onStarted"
                   @stopped="onStopped"
                   @error="onError"
                   @cameras="onCameras"
                   @camera-change="onCameraChange" />
      <input id="gf-uploader" type="file" ref="ga-uploader" :accept="'.jpg, .jpeg, .png, .heic'" @change="onFileChange" class="d-none">
      <img src="@/assets/images/icons/photo-gallery-icon.svg" class="gallery-icon" @click="showGallery"/>
      <img src="@/assets/images/icons/camera-capture.svg" class="capture-img" :class="[isPhotoTaken ? 'img-taken' : null]" @click="captureImage"/>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { WebCam } from "vue-web-cam";
import $ from "jquery";
Vue.use(WebCam);

export default {
  name: "Camera",
  props: ['show'],
  components: {
    "vue-web-cam": WebCam
  },
  data() {
    return {
      img: null,
      camera: null,
      deviceId: null,
      devices: [],
      isFullScreen: false,
      screenWidth: 0,
      screenHeight: 0,
      isCameraOpen: false,
      isPhotoTaken: false,
      isShotPhoto: false,
      isLoading: false,
      currentCam: null,
      fileObject: undefined,
    };
  },
  computed: {
    currentDevice: function () {
      return this.devices.find(n => n.deviceId === this.deviceId);
    }
  },
  watch: {
    camera: function (id) {
      this.deviceId = id;
    },
    show: function (newVal) {
      this.isPhotoTaken = !newVal;
    },
    isFullScreen: function (newVal) {
      if (!newVal) {
        this.closeCamera();
      }
    },
    devices: function () {
      // Once we have a list select the first one
      console.log('camera devices', this.devices);
      if (this.devices && this.devices.length > 0) {
        let lastDevice = this.devices[this.devices.length -1];
        this.deviceId = lastDevice.deviceId;
        this.camera = lastDevice.deviceId;
        this.currentCam = lastDevice;
        console.log('last device is: ', lastDevice);
      }
    }
  },
  mounted() {
    this.screenWidth = window.innerWidth;
    this.screenHeight = window.innerHeight;
  },
  methods: {
    toggleCamera() {
      if (this.isCameraOpen) {
        this.isCameraOpen = false;
        this.isPhotoTaken = false;
        this.isShotPhoto = false;
        this.onStop();
      } else {
        this.isCameraOpen = true;
        this.onStart();
      }
    },
    captureImage() {
      if (!this.isPhotoTaken) {
        this.isShotPhoto = true;

        const FLASH_TIMEOUT = 50;

        setTimeout(() => {
          this.isShotPhoto = false;
        }, FLASH_TIMEOUT);
      }

      this.isPhotoTaken = !this.isPhotoTaken;

      this.img = this.$refs.webcam.capture();
      this.$emit('onImageCaptured', this.img);
    },
    onStarted(stream) {
      console.log("On Started Event", stream);
    },
    onStopped(stream) {
      console.log("On Stopped Event", stream);
      this.exitFullScreen();
    },
    onStop() {
      this.$refs.webcam.stop();
    },
    onStart() {
      this.isLoading = true;

      const constraints = (window.constraints = {
        audio: false,
        video: true
      });


      navigator.mediaDevices
        .getUserMedia(constraints)
        .then(stream => {
          this.isLoading = false;
          this.$refs.webcam.start();
          //this.enterFullScreen();
        })
        .catch(error => {
          this.isLoading = false;
          alert("May the browser didn't support or there is some errors.");
        });

      /*this.isLoading = true;
      this.$refs.webcam.start();
      this.isLoading = false;*/
    },
    onError(error) {
      console.log("On Error Event", error);
    },
    onCameras(cameras) {
      this.devices = cameras;
      console.log("On Cameras Event", cameras);
    },
    onCameraChange(deviceId) {
      this.deviceId = deviceId;
      this.camera = deviceId;
      console.log("On Camera Change Event", deviceId);
    },
    enterFullScreen() {
      const element = this.$refs.fullscreenElement;
      if (element.requestFullscreen) {
        element.requestFullscreen();
      } else if (element.webkitRequestFullscreen) {
        element.webkitRequestFullscreen();
      } else if (element.msRequestFullscreen) {
        element.msRequestFullscreen();
      }
      this.isFullScreen = true;
    },
    exitFullScreen() {
      if (document.requestExitFullscreen) {
        document.requestExitFullscreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
      this.isFullScreen = false;
    },
    closeCamera() {
      this.$emit('onClose');
    },
    downloadImage() {
      const download = document.getElementById("downloadPhoto");
      const canvas = document.getElementById("photoTaken").toDataURL("image/jpeg")
        .replace("image/jpeg", "image/octet-stream");
      download.setAttribute("href", canvas);
    },
    switchCamera() {
        if (this.devices && this.devices.length > 1) {

          const currDeviceIdx = this.devices.indexOf(
            this.devices.find(d => d.deviceId === this.deviceId)
          );
          let currentDeviceId = this.deviceId;

          if (this.devices[currDeviceIdx + 1]) {
            currentDeviceId = this.devices[currDeviceIdx + 1].deviceId;
          } else {
            currentDeviceId = this.devices[0].deviceId;
          }

          this.camera = currentDeviceId;
          this.deviceId = currentDeviceId;

          console.log('switched camera to: ', this.devices.find(d => d.deviceId === this.deviceId).label);
        }
    },
    onFileChange(e) {
      let files = e.target.files || e.dataTransfer.files;

      if (!files.length)
        return;

      this.img = files[0];

      const reader = new FileReader();
      reader.onload = () => {
        const img = new Image();
        img.src = reader.result;
        img.onload = () => {
          const canvas = document.createElement('canvas');
          canvas.width = img.width;
          canvas.height = img.height;
          const ctx = canvas.getContext('2d');
          ctx.drawImage(img, 0, 0);
          const base64data = canvas.toDataURL('image/jpeg');

          this.$emit('onFileLoaded', base64data);
          this.$emit('onImageCaptured', base64data);

        };
      };
      reader.readAsDataURL(this.img);
    },
    showGallery() {
      //open file uploader
      $('#gf-uploader').click();
    },
  },
};
</script>


<style lang="scss">
@import "@/assets/scss/global/global.scss";

.web-camera-container {
  margin-top: 2rem;
  margin-bottom: 2rem;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  .flex-cont {
    display: flex;
    flex-direction: row;
    background-color: #000000;
  }

  .cam-title {
    width: 100%;
    padding-top: 20px;
    padding-bottom: 16px;
    padding-left: 40px;
    justify-content: center;
    z-index: 99998;
    background-color: #000;
    color: #fff;
    display: flex;
  }

  .gallery-icon {
    filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(53deg) brightness(112%) contrast(101%);
    width: 64px;
    height: 64px;
    position: absolute;
    bottom: 100px;
    left: 16px;
    z-index: 99999;
  }

  .switch-icon {
    filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(53deg) brightness(112%) contrast(101%);
    width: 24px;
    height: 32px;
    margin-right: 18px;
    z-index: 99999;
    display: flex;
    margin-top: 14px;
  }


  @keyframes preload {
    0% {
      opacity: 1
    }

    50% {
      opacity: .4
    }

    100% {
      opacity: 1
    }
  }

  .capture-img {
    filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(53deg) brightness(112%) contrast(101%);
    width: 64px;
    height: 64px;
    position: absolute;
    bottom: 100px;
    left: calc(50% - 39px);
    z-index: 99999;
  }

  .img-taken {
    filter: invert(40%) sepia(94%) saturate(427%) hue-rotate(86deg) brightness(91%) contrast(87%) !important;
  }

  .close-cam {
    filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(53deg) brightness(112%) contrast(101%);
    position: absolute;
    top: 16px;
    right: 16px;
    cursor: pointer;
    width: 24px;
    z-index: 99999;
  }
}

video {
  background-color: #000000 !important;
}
</style>
