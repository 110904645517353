<template>
  <card :title="title" id="details" v-if="canEdit || canDelete">
    <span class="action_buttons">
      <img src="~@/assets/images/desktop/icons/controls/plus.svg" class="icon-svg" :title="$t('common.create_new', { name: name })" @click="newObject()"/>
    </span>
    <div class="row">
      <div class="form-group col-sm-12 col-lg-6 text-left">
        <label class="control-label">{{ $t('common.name') }}</label>
        <input type="text" v-model="data.name" :placeholder='$t("common.name")'
               :class="{ 'field-error' : this.$v.data.name.$error }"
               class="form-control"/>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-8">
        <div class="row">
          <div class="form-group col-sm-12 col-lg-8 mt-4">
            <b-form-checkbox switch size="lg"
                             v-model="is_day_range"
                             @change="isDayRangeChanged">
              {{ is_day_range ? $t('salary_calculator.shift.day_range') : $t('salary_calculator.shift.single_day') }}
            </b-form-checkbox>
          </div>
        </div>
        <div class="row">
          <div class="form-group col-sm-12 col-lg-3 text-left">
            <label class="control-label">{{ $t('salary_calculator.shift.'+(is_day_range ? 'day_from' : 'day')) }}</label>
            <b-form-select
              v-model="data.day_from"
              class="form-control"
              :class="{ 'field-error' : this.$v.data.day_from.$error }"
              :options="day_list"></b-form-select>
          </div>
          <div class="form-group col-sm-12 col-lg-3 text-left" v-if="is_day_range">
            <label class="control-label">{{ $t('salary_calculator.shift.day_to') }}</label>
            <b-form-select
              v-model="data.day_to"
              class="form-control"
              :class="{ 'field-error' : this.$v.data.day_to.$error }"
              :options="day_list"></b-form-select>
          </div>
        </div>
          <div class="row">
            <div class="form-group col-sm-12 col-lg-3 text-left">
              <label class="control-label">{{ $t('salary_calculator.shift.start_hour') }}</label>
              <b-form-select
                v-model="data.start_hour"
                class="form-control"
                :class="{ 'field-error' : this.$v.data.start_hour.$error }"
                :options="hours_list"></b-form-select>
            </div>
            <div class="form-group col-sm-12 col-lg-3 text-left">
              <label class="control-label">{{ $t('salary_calculator.shift.minute') }}</label>
              <b-form-select
                v-model="data.start_minute"
                class="form-control"
                :class="{ 'field-error' : this.$v.data.start_minute.$error }"
                :options="minutes_list"></b-form-select>
            </div>
          </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-8">
        <div class="row">
          <div class="form-group col-sm-12 col-lg-3 text-left">
            <label class="control-label">{{ $t('salary_calculator.shift.end_hour') }}</label>
            <b-form-select
              v-model="data.end_hour"
              class="form-control"
              :class="{ 'field-error' : this.$v.data.end_hour.$error }"
              :options="hours_list"></b-form-select>
          </div>
          <div class="form-group col-sm-12 col-lg-3 text-left">
            <label class="control-label">{{ $t('salary_calculator.shift.minute') }}</label>
            <b-form-select
              v-model="data.end_minute"
              class="form-control"
              :class="{ 'field-error' : this.$v.data.end_minute.$error }"
              :options="minutes_list"></b-form-select>
          </div>
        </div>
      </div>
    </div>
    <agro-action-buttons
      :data="data"
      :can-edit="canEdit"
      :can-delete="canDelete"
      @onSave="save"
      @onSaveAndNew="saveAndNew"
      :save-and-new="true"
      @onDelete="confirmDelete"
      @onGotoList="gotoList"
    ></agro-action-buttons>
  </card>
  <div v-else>
    <forbidden></forbidden>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import {
  convertArrayToNameVal, buildDefaultDropdownList
} from '@/helpers/common'
import required from "vuelidate/src/validators/required";
import requiredIf from "vuelidate/src/validators/requiredIf";
import * as actions from '@/helpers/generalActions'
import {canDelete, canEdit, permissions, permissionTypes} from "@/helpers/Permissions";
import { getDays, getHours, getMinutes } from '@/helpers/dateHelper';
import moment from 'moment'
import Forbidden from "@/pages/errors/Forbidden";
import AgroActionButtons from "@/components/AgroActionButtons/index";

export default {
  name: 'SCShiftDetails',
  components: {AgroActionButtons, Forbidden},
  data() {
    return {
      data: {},
      is_day_range: false,
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
      users: 'user/users',
    }),
    name() {
      return this.$t('salary_calculator.shift.title')
    },
    config() {
      return {
        permission: permissions.SC_SHIFT,
        permission_type: permissionTypes.module,
        get: this.get,
        create: this.create,
        update: this.update,
        delete: this.delete,
        list_url: '/salary-calculator/sc-shift',
        confirm_delete: true,
      }
    },
    actions() {
      return actions;
    },
    canEdit() {
      return canEdit(this);
    },
    canDelete() {
      return canDelete(this);
    },
    title() {
      return (this.data.uuid ? this.$t('common.edit') : this.$t('common.new'))+' '+this.name;
    },
    day_list() {
      return buildDefaultDropdownList(getDays(this.$i18n.locale));
    },
    hours_list() {
      return buildDefaultDropdownList(
          convertArrayToNameVal(getHours(), '0', 2)
      );
    },
    minutes_list() {
      return buildDefaultDropdownList(
        convertArrayToNameVal(getMinutes(), '0', 2)
      );
    }
  },
  methods: {
    ...mapActions({
      get: 'scShift/getSCShift',
      create: 'scShift/createSCShift',
      update: 'scShift/updateSCShift',
      delete: 'scShift/deleteSCShift'
    }),
    loadData() {
      let vm = this;
      return this.actions.getData(this, (data) => {
        vm.$set(vm, 'data', {
          start_hour: '00',
          start_minute: '00',
          end_hour: '00',
          end_minute: '00',
          day_from: null,
          day_to: null
        });
        vm.$set(vm, 'is_day_range', false);
        console.log('load new', vm.data);
      }, (data) => {
        vm.$set(vm,'is_day_range', data.day_to !== null);
        vm.updateHours(data);
      });
    },
    updateHours(data) {
      this.$set(this.data, 'start_minute', new moment(data.start_hour, "HH:mm").format("mm"));
      this.$set(this.data, 'start_hour', new moment(data.start_hour, "HH:mm").format("HH"));

      this.$set(this.data, 'end_minute', new moment(data.end_hour, "HH:mm").format("mm"));
      this.$set(this.data, 'end_hour', new moment(data.end_hour, "HH:mm").format("HH"));
    },
    gotoList() {
      return actions.gotoList(this);
    },
    confirmDelete() {
      return this.actions.confirmDelete(this);
    },
    collectTimes() {
      this.$set(this.data, 'start_hour', (this.data.start_hour += ':'+this.data.start_minute));
      this.$set(this.data, 'end_hour', (this.data.end_hour += ':'+this.data.end_minute));
    },
    save() {
      let vm = this;
      this.collectTimes();
      return this.actions.save(this, this.config.list_url).then((resp) => {
        vm.updateHours(resp);
      });
    },
    saveAndNew() {
      this.collectTimes();
      return this.actions.saveAndNew(this, 'add');
    },
    newObject() {
      return this.actions.newObject(this);
    },
    isDayRangeChanged(val) {
      if(!val) {
        this.data.day_to = null;
      }
      this.data.is_day_range = val ? 1 : 0;
    }
  },
  mounted() {
    this.loadData();
  },
  watch: {
    permission: {
      handler: function(newVal) {
        this.permissionModule = newVal;
      },
      deep: true,
    },
  },
  validations(){
    let vm = this;
    return {
      data: {
        name: {
          required,
        },
        day_from: {
          required,
        },
        day_to: {
          required: requiredIf(function(obj) {
            return vm.is_day_range;
          })
        },
        start_hour: {
          required,
        },
        start_minute: {
          required,
        },
        end_hour: {
          required,
        },
        end_minute: {
          required,
        },
      }
    }
  }
}
</script>
