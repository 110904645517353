export const ConsumTypes = {
    Parcel: "PARCEL",
    Utilaj: "UTILAJ",
    UnitateEconomica: "UNITATE_ECONOMICA",
    
}

export const ConsumOptions = [
    {
        code: ConsumTypes.Parcel,
        title: "Parcele"
    },
    {
        code: ConsumTypes.Utilaj,
        title: "Utilaj"
    },
    {
        code: ConsumTypes.UnitateEconomica,
        title: "Unitate economică"
    },
]

