<template>
  <div class="persons dx-viewport" v-if="canEdit || canDelete" v-cloak>
    <card :title="$t('works.persons.name')" class="agx-card" id="persons-manager">
      <agro-filter-container :new-object="newPerson" :title='$t("works.persons.create_new_person")'>
        <person-filters
            :limit="limit"
            ref="filters"
            @onFiltersApplied="onFiltersApplied"
        />
      </agro-filter-container>
      <DxDataGrid
        :ref="gridRefName"
        :data-source="dataSource"
        :remote-operations="true"
        :allow-column-reordering="true"
        :allow-column-resizing="true"
        :row-alternation-enabled="false"
        :allow-sorting="true"
        :show-borders="true"
        :focused-row-enabled="true"
        key-expr="uuid"
        @content-ready="onContentReady"
        :element-attr="gridAttributes"
        :column-hiding-enabled="true"
      >
        <DxFilterRow :visible="false" :apply-filter="currentFilter" />
        <DxHeaderFilter :visible="false" />

        <DxColumn
          data-field="name"
          data-type="string"
          :caption="$t('common.name')"
          cell-template="personCellTemplate"
          width="70%"
          @value-changed="onValueChanged"
        />
        <DxColumn
          data-field="created_at"
          data-type="date"
          :caption="$t('common.created_at')"
          cell-template="createdAtCellTemplate"
          width="15%"
          alignment="center"
        />
        <DxColumn
          data-field="updated_at"
          data-type="date"
          :caption="$t('common.last_update')"
          cell-template="updatedAtCellTemplate"
          width="15%"
          alignment="center"
        />

        <DxGroupPanel :visible="false" />
        <DxGrouping :auto-expand-all="false" />
        <DxPager
          :visible="true"
          :allowed-page-sizes="pageSizes"
          :show-page-size-selector="true"
        />
        <DxPaging :page-size="limit" />
        <DxSorting mode="multiple"/>
        <template #personCellTemplate="{ data: rowInfo }">
          <div class="person-grid-row">
            <div class="row-info-box">{{ rowInfo.data.name }}</div>
          </div>
        </template>
        <template #createdAtCellTemplate="{ data: rowInfo }">
          <div class="person-grid-row justify-content-center">
            <div class="row-info-box">{{ rowInfo.data.created_at }}</div>
          </div>
        </template>
        <template #updatedAtCellTemplate="{ data: rowInfo }">
          <div class="person-grid-row justify-content-center">
            <div class="row-info-box">{{ rowInfo.data.updated_at }}</div>
          </div>
        </template>
      </DxDataGrid>
    </card>
  </div>
  <div v-else>
    <forbidden></forbidden>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import PersonFilters from "./partials/PersonFilters.vue";
import DxButton from "devextreme-vue/button";
import {
  DxDataGrid,
  DxColumn,
  DxGrouping,
  DxGroupPanel,
  DxPager,
  DxPaging,
  DxHeaderFilter,
  DxFilterRow,
  DxToolbar,
  DxItem,
  DxSorting,
} from "devextreme-vue/data-grid";
import Forbidden from "~/pages/errors/Forbidden.vue";
import {canDelete, canEdit, permissions, permissionTypes} from "~/helpers/Permissions";
import AgroFilterContainer from "@/components/AgroFilter/partials/AgroFilterContainer.vue";

export default {
  name: "persons",
  data() {
    return {
      limit: 25,
      currentPage: 1,
      loading: false,
      dataSource: null,
      currentFilter: "auto",
      pageSizes: [10, 25, 50, 100],
      gridRefName: "gridpersons",
      showFilters: false,
      showAddFitoAndFertility: false,
      gridAttributes: {
        class: "agx-grid",
      },
    };
  },
  computed: {
    ...mapGetters({
        currentDepartment: "auth/currentDepartment",
        persons: "works-persons/persons",
    }),
    config() {
      return {
        permission: permissions.PERSONS,
        permission_type: permissionTypes.module,
      };
    },
    canEdit() {
      return canEdit(this);
    },
    canDelete() {
      return canDelete(this);
    },
  },
  components: {
    AgroFilterContainer,
    Forbidden,
    DxButton,
    DxDataGrid,
    DxColumn,
    DxGrouping,
    DxGroupPanel,
    DxPager,
    DxPaging,
    DxHeaderFilter,
    DxFilterRow,
    DxToolbar,
    DxItem,
    DxSorting,
    PersonFilters,
  },
  methods: {
    ...mapActions({
      getPersons: "works-persons/getPersons",
    }),
    onContentReady() {

    },
    onReorder(obj) {
       console.log('onReorder', obj);
    },
    onValueChanged(obj) {
        console.log('onValueChanged', obj);
    },
    onFiltersApplied(filters) {
        this.$set(this, 'currentPage', filters.page);
        this.$set(this, 'loading', true);
    },
    newPerson() {
      //not implemented
    }
  },
    watch: {
      persons: {
          handler: function(newVal) {
              this.dataSource = newVal;
          },
          deep: true,
      }
    }
};
</script>

<style scoped>
.person-grid-row {
  width: 100%;
  background-color: white;
  display: flex;
  flex-wrap: wrap;
  overflow: auto;
}

.row-info-box {
  float: left;
  margin: 10px;
  font-size: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
