<template>
  <div>
    <div
      slot="header"
      class="btn-group list-group-item"
      role="group"
      :aria-label="name"
    >
      <div class="list-label">{{ name }}</div>
      <div class="subtitle" v-if="subtitle">{{ subtitle }}</div>
    </div>
    <input
      type="text"
      v-model="filter"
      class="form-control mb-2"
      :placeholder="$t('tools.configurator.filter_attributes')"
      v-if="quickFilter"
    />
    <div class="draggable-list" :class="[size]">
      <draggable
        :id="objectID"
        :list="attributes"
        class="list-group"
        draggable=".item"
        group="a"
        @change="onChange"
        :move="onCheckMove"
      >
        <div
          class="list-group-item item c-move source-item"
          v-for="element in attributes"
          :key="element.name"
        >
          {{ element.name }}
        </div>
      </draggable>
    </div>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import { splitGetValue } from "@/helpers/common";

export default {
  name: "DraggableList",
  props: {
    id: {
      type: String,
    },
    name: {
      type: String,
    },
    subtitle: {
      type: String | null,
    },
    data: {
      type: Array,
    },
    dragListChanged: {
      type: Function | null,
    },
    checkMove: {
      type: Function | null,
    },
    size: {
      type: String | null,
    },
    quickFilter: {
      type: Boolean | null,
    },
  },
  components: {
    draggable,
  },
  data() {
    return {
      attributes: this.data ? this.data : [],
      original_attributes: [],
      moveEvent: null,
      filter: "",
    };
  },
  computed: {
    objectID() {
      return this.id
        ? this.id
        : "attributes_" + Math.random().toString().replace(".", "");
    },
  },
  methods: {
    onChange(data) {
      if (this.moveEvent) {
        data.moveEvent = Object.assign(
          {},
          {
            from: splitGetValue(this.moveEvent.from.id, "."),
            to: splitGetValue(this.moveEvent.to.id, "."),
            targetIndex:
              this.moveEvent && this.moveEvent.draggedContext
                ? this.moveEvent.draggedContext.futureIndex
                : null,
          }
        );
      }
      this.moveEvent = null;

      if (this.dragListChanged && this.dragListChanged instanceof Function) {
        return this.dragListChanged(data);
      }
    },
    onCheckMove: function (evt) {
      this.moveEvent = evt;
      if (this.checkMove && this.checkMove instanceof Function) {
        return this.checkMove(this.moveEvent);
      }
    },
    resetFilter() {
      this.filter = "";
    },
  },
  mounted() {
    this.filter = "";
    this.original_attributes = this.data;
  },
  watch: {
    data: {
      handler: function (newVal) {
        this.attributes = newVal;
        this.original_attributes = newVal;
        this.resetFilter();
      },
      deep: true,
      immediate: true,
    },
    filter: function (newVal) {
      if (newVal) {
        this.attributes = this.original_attributes.filter((item) => {
          return (
            item.name
              .toString()
              .toLowerCase()
              .indexOf(newVal.toString().toLowerCase()) > -1
          );
        });
      } else {
        this.attributes = this.original_attributes;
      }
    },
  },
};
</script>

<style scoped>
.list-label {
  font-weight: bold;
}

.source-item {
  border: 1px #ededed solid;
  margin-top: 2px;
}

.subtitle {
  font-size: 12px;
  color: red;
}

.strech {
  overflow-y: scroll;
  overflow-x: hidden;
  height: 100vh;
}

.draggable-list::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.draggable-list::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.draggable-list::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
.draggable-list::-webkit-scrollbar-thumb:hover {
  background: #555;
}
</style>
