import * as api from '../../api/index'
import * as endpoints from '../../api/endpoints'
import * as types from '../../api/../mutation-types'

const actions = {
    //SmProductTypes
    getSmProductTypes({commit}, payload) {
          return api.fetch(endpoints.SM_PRODUCT_TYPES, payload, commit, (response) => {
            commit(types.SET_SM_PRODUCT_TYPES, response.data.data);
              commit("common/"+types.SET_PAGINATION, response.data.pagination, { root: true });
          });
    },
    searchSmProductTypes({commit}, payload) {
      return api.fetch(endpoints.SM_PRODUCT_TYPE_SEARCH, payload, commit, (response) => {
        commit(types.SET_SM_PRODUCT_TYPES, response.data);
      });
    },
    getSmProductType({commit}, payload) {
      return api.fetch(endpoints.SM_PRODUCT_TYPES+'/'+payload.uuid, payload, commit,  (response) => {
        commit(types.SET_SM_PRODUCT_TYPE, response.data);
      });
    },
    createSmProductType({commit}, payload) {
      return api.post(endpoints.SM_PRODUCT_TYPES, payload,  commit,(response) => {
        commit(types.SET_SM_PRODUCT_TYPE, response.data);
      });
    },
    updateSmProductType({commit}, payload) {
      return api.put(endpoints.SM_PRODUCT_TYPES+'/'+payload.uuid, payload, commit, (response) => {
        commit(types.SET_SM_PRODUCT_TYPE, response.data);
      })
    },
    deleteSmProductType({commit}, payload) {
      return api.remove(endpoints.SM_PRODUCT_TYPES+'/'+payload.uuid, payload,  commit,(response) => {
        commit(types.SET_SM_PRODUCT_TYPE, response.data);
      })
    },
}

export default actions;
