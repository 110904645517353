var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "vue-tags-input",
      class: [{ "ti-disabled": _vm.disabled }, { "ti-focus": _vm.focused }]
    },
    [
      _c("div", { staticClass: "ti-input" }, [
        _vm.tagsCopy
          ? _c(
              "ul",
              { staticClass: "ti-tags" },
              [
                _vm._l(_vm.tagsCopy, function(tag, index) {
                  return _c(
                    "li",
                    {
                      key: index,
                      staticClass: "ti-tag",
                      class: [
                        { "ti-editing": _vm.tagsEditStatus[index] },
                        tag.tiClasses,
                        tag.classes,
                        { "ti-deletion-mark": _vm.isMarked(index) }
                      ],
                      style: tag.style,
                      attrs: { tabindex: "0" },
                      on: {
                        click: function($event) {
                          return _vm.$emit("tag-clicked", {
                            tag: tag,
                            index: index
                          })
                        }
                      }
                    },
                    [
                      _c("div", { staticClass: "ti-content" }, [
                        _vm.$scopedSlots["tag-left"]
                          ? _c(
                              "div",
                              { staticClass: "ti-tag-left" },
                              [
                                _vm._t("tag-left", null, {
                                  tag: tag,
                                  index: index,
                                  edit: _vm.tagsEditStatus[index],
                                  performSaveEdit: _vm.performSaveTag,
                                  performDelete: _vm.performDeleteTag,
                                  performCancelEdit: _vm.cancelEdit,
                                  performOpenEdit: _vm.performEditTag,
                                  deletionMark: _vm.isMarked(index)
                                })
                              ],
                              2
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            ref: "tagCenter",
                            refInFor: true,
                            staticClass: "ti-tag-center"
                          },
                          [
                            !_vm.$scopedSlots["tag-center"]
                              ? _c(
                                  "span",
                                  {
                                    class: {
                                      "ti-hidden": _vm.tagsEditStatus[index]
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.performEditTag(index)
                                      }
                                    }
                                  },
                                  [_vm._v(_vm._s(tag.text))]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            !_vm.$scopedSlots["tag-center"]
                              ? _c("tag-input", {
                                  attrs: {
                                    scope: {
                                      edit: _vm.tagsEditStatus[index],
                                      maxlength: _vm.maxlength,
                                      tag: tag,
                                      index: index,
                                      validateTag: _vm.createChangedTag,
                                      performCancelEdit: _vm.cancelEdit,
                                      performSaveEdit: _vm.performSaveTag
                                    }
                                  }
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            _vm._t("tag-center", null, {
                              tag: tag,
                              index: index,
                              maxlength: _vm.maxlength,
                              edit: _vm.tagsEditStatus[index],
                              performSaveEdit: _vm.performSaveTag,
                              performDelete: _vm.performDeleteTag,
                              performCancelEdit: _vm.cancelEdit,
                              validateTag: _vm.createChangedTag,
                              performOpenEdit: _vm.performEditTag,
                              deletionMark: _vm.isMarked(index)
                            })
                          ],
                          2
                        ),
                        _vm._v(" "),
                        _vm.$scopedSlots["tag-right"]
                          ? _c(
                              "div",
                              { staticClass: "ti-tag-right" },
                              [
                                _vm._t("tag-right", null, {
                                  tag: tag,
                                  index: index,
                                  edit: _vm.tagsEditStatus[index],
                                  performSaveEdit: _vm.performSaveTag,
                                  performDelete: _vm.performDeleteTag,
                                  performCancelEdit: _vm.cancelEdit,
                                  performOpenEdit: _vm.performEditTag,
                                  deletionMark: _vm.isMarked(index)
                                })
                              ],
                              2
                            )
                          : _vm._e()
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "ti-actions" },
                        [
                          !_vm.$scopedSlots["tag-actions"]
                            ? _c("i", {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.tagsEditStatus[index],
                                    expression: "tagsEditStatus[index]"
                                  }
                                ],
                                staticClass: "ti-icon-undo",
                                on: {
                                  click: function($event) {
                                    return _vm.cancelEdit(index)
                                  }
                                }
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          !_vm.$scopedSlots["tag-actions"]
                            ? _c("i", {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: !_vm.tagsEditStatus[index],
                                    expression: "!tagsEditStatus[index]"
                                  }
                                ],
                                staticClass: "ti-icon-close",
                                on: {
                                  click: function($event) {
                                    return _vm.performDeleteTag(index)
                                  }
                                }
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.$scopedSlots["tag-actions"]
                            ? _vm._t("tag-actions", null, {
                                tag: tag,
                                index: index,
                                edit: _vm.tagsEditStatus[index],
                                performSaveEdit: _vm.performSaveTag,
                                performDelete: _vm.performDeleteTag,
                                performCancelEdit: _vm.cancelEdit,
                                performOpenEdit: _vm.performEditTag,
                                deletionMark: _vm.isMarked(index)
                              })
                            : _vm._e()
                        ],
                        2
                      )
                    ]
                  )
                }),
                _vm._v(" "),
                _c("li", { staticClass: "ti-new-tag-input-wrapper" }, [
                  _c(
                    "input",
                    _vm._b(
                      {
                        ref: "newTagInput",
                        staticClass: "ti-new-tag-input",
                        class: [
                          _vm.createClasses(
                            _vm.newTag,
                            _vm.tags,
                            _vm.validation,
                            _vm.isDuplicate
                          )
                        ],
                        attrs: {
                          placeholder: _vm.placeholder,
                          maxlength: _vm.maxlength,
                          disabled: _vm.disabled,
                          type: "text",
                          size: "1"
                        },
                        domProps: { value: _vm.newTag },
                        on: {
                          keydown: [
                            function($event) {
                              return _vm.performAddTags(
                                _vm.filteredAutocompleteItems[
                                  _vm.selectedItem
                                ] || _vm.newTag,
                                $event
                              )
                            },
                            function($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                $event.keyCode !== 8
                              ) {
                                return null
                              }
                              return _vm.invokeDelete($event)
                            },
                            function($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                $event.keyCode !== 9
                              ) {
                                return null
                              }
                              return _vm.performBlur($event)
                            },
                            function($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                $event.keyCode !== 38
                              ) {
                                return null
                              }
                              return _vm.selectItem($event, "before")
                            },
                            function($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                $event.keyCode !== 40
                              ) {
                                return null
                              }
                              return _vm.selectItem($event, "after")
                            }
                          ],
                          paste: _vm.addTagsFromPaste,
                          input: _vm.updateNewTag,
                          blur: function($event) {
                            return _vm.$emit("blur", $event)
                          },
                          focus: function($event) {
                            _vm.focused = true
                            _vm.$emit("focus", $event)
                          },
                          click: function($event) {
                            _vm.addOnlyFromAutocomplete
                              ? false
                              : (_vm.selectedItem = null)
                          }
                        }
                      },
                      "input",
                      _vm.$attrs,
                      false
                    )
                  )
                ])
              ],
              2
            )
          : _vm._e()
      ]),
      _vm._v(" "),
      _vm._t("between-elements"),
      _vm._v(" "),
      _vm.autocompleteOpen
        ? _c(
            "div",
            {
              staticClass: "ti-autocomplete",
              on: {
                mouseout: function($event) {
                  _vm.selectedItem = null
                }
              }
            },
            [
              _vm._t("autocomplete-header"),
              _vm._v(" "),
              _c(
                "ul",
                _vm._l(_vm.filteredAutocompleteItems, function(item, index) {
                  return _c(
                    "li",
                    {
                      key: index,
                      staticClass: "ti-item",
                      class: [
                        item.tiClasses,
                        item.classes,
                        { "ti-selected-item": _vm.isSelected(index) }
                      ],
                      style: item.style,
                      on: {
                        mouseover: function($event) {
                          _vm.disabled ? false : (_vm.selectedItem = index)
                        }
                      }
                    },
                    [
                      !_vm.$scopedSlots["autocomplete-item"]
                        ? _c(
                            "div",
                            {
                              on: {
                                click: function($event) {
                                  return _vm.performAddTags(
                                    item,
                                    undefined,
                                    "autocomplete"
                                  )
                                }
                              }
                            },
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s(item.text) +
                                  "\n        "
                              )
                            ]
                          )
                        : _vm._t("autocomplete-item", null, {
                            item: item,
                            index: index,
                            performAdd: function(item) {
                              return _vm.performAddTags(
                                item,
                                undefined,
                                "autocomplete"
                              )
                            },
                            selected: _vm.isSelected(index)
                          })
                    ],
                    2
                  )
                }),
                0
              ),
              _vm._v(" "),
              _vm._t("autocomplete-footer")
            ],
            2
          )
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }