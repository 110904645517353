import * as api from '../../api/index'
import * as endpoints from '../../api/endpoints'
import * as types from "../../mutation-types";

const actions = {
  getSCWorkPivots({commit}, payload) {
    return api.fetch(endpoints.SC_WORK_PIVOT, payload, commit,(response) => {
      commit(types.SET_SC_WORK_PIVOTS, response.data.data);
    });
  },
  getSCWorkCollectorHectareData({commit}, payload) {
    return api.fetch(endpoints.SC_WORK_COLLECTOR_HECTARE_DATA, payload, commit,(response) => {
      commit(types.SET_SC_WORK_COLLECTOR_HECTARE_DATA, response.data);
      commit("common/"+types.SET_PAGINATION, response.data.pagination, { root: true });
    });
  },
  getSCWorkHierarchy({commit}, payload) {
    return api.fetch(endpoints.SC_WORK_HIERARCHY, payload, commit,(response) => {
      commit(types.SET_SC_WORK_HIERARCHY, response.data.data);
      commit("common/"+types.SET_PAGINATION, response.data.pagination, { root: true });
    });
  },
  getSCWorkPivot({commit}, payload) {
    return api.fetch(endpoints.SC_WORK_PIVOT+'/'+payload.uuid, payload, commit,(response) => {
      commit(types.SET_SC_WORK_PIVOT, response.data);
    });
  },
  createSCWorkPivot({commit}, payload) {
    return api.post(endpoints.SC_WORK_PIVOT, payload, commit, (response) => {
      commit(types.SET_SC_WORK_PIVOT, response.data);
    });
  },
  updateSCWorkPivot({commit}, payload) {
    return api.put(endpoints.SC_WORK_PIVOT+'/'+payload.uuid, payload, commit, (response) => {
      commit(types.UPDATE_SC_WORK_PIVOT_ITEM, response.data);
    });
  },
  deleteSCWorkPivot({commit}, payload) {
    return api.remove(endpoints.SC_WORK_PIVOT+'/'+payload.uuid, payload, commit, (response) => {
      commit(types.DELETE_SC_WORK_PIVOT_ITEM, response.data);
    });
  },
  downloadDailyReport({commit}, payload) {
    return api.downloadBlob(payload.url, payload, payload.type, commit,(response) => response.data);
  },
}

export default actions;
