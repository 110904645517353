import * as types from "../../mutation-types";

const mutations = {
  [types.SET_SC_WORKS]: (state, payload) => {
    state.sc_works = payload;
  },
  [types.SET_SC_WORK_COLLECTOR]: (state, payload) => {
    state.sc_work_collector = payload;
  },
  [types.SET_SC_WORK]: (state, payload) => {
    state.sc_work = payload;
  },
  [types.ADD_SC_WORK_ITEM]: (state, payload) => {
    state.sc_works.push(payload);
  },
  [types.UPDATE_SC_WORK_ITEM]: (state, payload) => {
    state.sc_works.splice(state.sc_works.indexOf(
      state.sc_works.find(c => c.uuid === payload.uuid)
    ), 1, payload);
  },
  [types.DELETE_SC_WORK_ITEM]: (state, payload) => {
    state.sc_works.splice(state.sc_works.indexOf(
      state.sc_works.find(c => c.uuid === payload.uuid)
    ), 1);
  },
  [types.SET_SC_WORK_FILTERS]: (state, payload) => {
    state.sc_work_filters = payload;
  },
}

export default mutations;
