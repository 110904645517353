<template>
  <div class="view" v-if="canEdit || canDelete" v-cloak>
    <card :title="$t('stock_management.transactions.title')" id="stock-list" titleBottomPadding="10px">
    <span class="action_buttons">
      <div class="controls text-right pr-3">
        <img src="~@/assets/images/desktop/icons/controls/expand.svg" class="icon-svg collapse-icon d-inline-flex"
             :title='$t("toggle_filters")'
             data-toggle="collapse" data-target="#collapseFilters"
             aria-expanded="false" aria-controls="collapseFilters"
        />
        <stock-actions></stock-actions>
      </div>
    </span>
      <div class="row pb-4 collapse show" id="collapseFilters">
        <s-m-transaction-filters ref="filters"
                                 @onFiltersApplied="onFiltersApplied"
                                 @onFilterClickApplied="onFilterClickApplied"
        />
      </div>
    </card>
    <div class="content-body pt-4">
      <div class="row">
        <div class="col-lg-12">
          <agro-grid
            :headers="headers"
            :rows="items"
            :paginate="true"
            :permission="config"
            :action="onPageSelected"
            :loading="loading"
            @onSortBy="onSortBy"
            ref="grid"
          >
          </agro-grid>
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <forbidden></forbidden>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import * as type from '../../../store/mutation-types'
import AgroGrid from "@/components/AgroGrid/index";
import {canDelete, canEdit, permissions, permissionTypes} from "@/helpers/Permissions";
import SMTransactionFilters from "~/pages/stock-management/sm-transactions/partials/smTransactionFilters.vue";
import StockActions from "~/pages/stock-management/sm-stocks/partials/StockActions.vue";
import Forbidden from "~/pages/errors/Forbidden.vue";

export default {
  name: 'SMTransactions',
  middleware: ['auth', 'check-company-admin'],
  data() {
    return {
      currentPage: 1,
      filters: this.transactionFilters ? this.transactionFilters : {},
      items: this.stock ? this.stock : [],
      pager: this.pagination ? this.pagination : {},
      loading: false,

    }
  },
  components: {
    Forbidden,
    StockActions,
    SMTransactionFilters,
    AgroGrid
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
      transactionFilters: 'smTransactions/smTransactionFilters',
      transactions: 'smTransactions/smTransactions',
      pagination: 'common/pagination',
      users: 'user/users',
    }),
    config() {
      return {
        permission: permissions.SM_TRANSACTIONS,
        permission_type: permissionTypes.module,
      };
    },
    canEdit() {
      return canEdit(this);
    },
    canDelete() {
      return canDelete(this);
    },
    headers() {
      return  [
        {
          name: this.$t('stock_management.transactions.product'),
          key: 'product',
          col: 'product.name',
          subtitle: (item) => {
            return {
              title: item.product.barcode ?? '-',
              link: '/stock-management/sm-products/'+item.product.uuid
            }
          },
          width: '14%',
          sortable: true,
          colTemplate: this.canceledRowTemplate,
        },
        {
          name: this.$t('stock_management.products.code'),
          key: 'productCode',
          col: 'product.code',
          width: '8%',
          sortable: true,
          colTemplate: this.canceledRowTemplate,
        },
        { name: this.$t('stock_management.transactions.transaction_type'), colTemplate: this.canceledRowTemplate, col: 'transaction_type', width: '10%'},
        { name: this.$t('stock_management.transactions.date'), colTemplate: this.canceledRowTemplate, col: 'transaction_date', align:'right', width: '11%', sortable: true },
        { name: this.$t('stock_management.transactions.movement_type'), colTemplate: this.canceledRowTemplate, col: 'type', width: '10%'},
        { name: this.$t('stock_management.products.quantity'), colTemplate: this.canceledRowTemplate, col: 'quantity_mu', key: 'quantity', width: '10%'},
        {
          name: this.$t('stock_management.transactions.source_whs'),
          col: 'source_warehouse.name',
          key: 'source_warehouse_uuid',
          suffix: '',
          width: '10%',
          align: 'center',
          colTemplate: this.canceledRowTemplate,
        },
        {
          name: this.$t('stock_management.transactions.dest_whs'),
          col: 'dest_warehouse.name',
          key: 'dest_warehouse_uuid',
          suffix: '',
          width: '10%',
          align: 'center',
          colTemplate: this.canceledRowTemplate,
        },
        { name: this.$t('stock_management.transactions.total'), colTemplate: this.canceledRowTemplate, col: 'total', suffix: this.$currency.name, align:'center', width: '12%'},
        { name: this.$t('common.created_at'), colTemplate: this.canceledRowTemplate, col: 'created_at', align:'right', width: '9%', sortable: true },
        { name: "", colTemplate: this.actionColTemplate, align: 'center', width: '5%', sortable: false},
        { name: "", colTemplate: this.cancelActionColTemplate, cancelDocument: true, align: 'center', width: '5%', sortable: false }
      ];
    },
  },
  methods: {
    ...mapActions({

    }),
    onPageSelected(page_id) {
      this.filters.page = page_id;
      this.$store.commit("smTransactions/"+type.SET_SM_TRANSACTION_FILTERS, this.filters);
      this.$refs.filters.applyFilters();
    },
    onItemEdited(item) {
      console.log('onItemEdited', item);
    },
    onFiltersApplied(filters) {
      this.loading = true;
    },
    onFilterClickApplied(filters) {
        this.items = [];
    },
    onSortBy(data) {
      this.transactionFilters.sortBy = data.sortBy;
      this.transactionFilters.sortDirection = data.sortDirection;
      this.$refs.filters.applyFiltersClick();
    },
    actionColTemplate(col) {
      //return globally registered component
      if (col.document) {
        return 'pdf-download-col';
      }

      if(col.export_url || !col.canceled_at) {
        return 'pdf-export-col';
      }

      return null;
    },
    cancelActionColTemplate(col) {
      //return globally registered component
      if (col.cancel_document) {
        return 'pdf-download-col';
      }

      if(col.cancel_export_url || !col.canceled_at) {
        return 'pdf-export-col';
      }

      return null;
    },
    canceledRowTemplate(col) {
      return 'CanceledRow';
    },
  },
  watch: {
    transactions: {
      handler: function(newVal) {
        if(this.$refs['grid'].isInfiniteScrollEnabled) {
          newVal.map((item) => {
            this.items.push(item);
          })
        } else {
          this.items = newVal;
        }
        this.loading = false;
      },
      deep: true,
    },
    pagination: {
      handler: function(newVal) {
        this.pager = newVal;
      },
      deep: true,
    },
    transactionFilters: {
      handler: function(newVal) {
        this.filters = newVal;
      },
      deep: true,
    },
  }
}
</script>

<style lang="scss" scoped>
.card-body {
  .controls {
    width: 300px;
  }
  .action_buttons {
    position: absolute;
    top: 10px;
    left: calc(100% - 300px);
  }
}
.dropdown {
  position: relative;
  top: -5px;
}
</style>
