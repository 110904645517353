export function isDevelopment() {
  return window.location.hostname.toString().endsWith('smagria.org');
}
export function isLocal() {
  let local = window.location.hostname.toString().endsWith('.test');
  let ip = window.location.hostname.toString().indexOf('192.168.');
  let eth = window.location.hostname.toString().indexOf('127.0.0.1');

  return local || ip || eth;
}
export function  isStaging() {
  return window.location.hostname.toString().endsWith('smagria.com');
}

export function isProduction() {
  return window.location.hostname.toString().endsWith('smagria.ro');
}

export function getTitle() {
  if(isProduction()) {
    return 'Production';
  }
  else if(isStaging()) {
    return 'Staging'
  }
  else if(isDevelopment()) {
    return 'Development';
  } else if(isLocal()) {
    return 'Local development';
  } else {
    return 'Unknown environment'
  }
}
