<!-- template for the modal component -->
<template type="x/template">
  <transition name="modal" id="contact-info-dialog">
    <div class="modal-mask" v-show="show">
      <div class="modal-container" @click.stop>
        <div class="modal-header">
          <h3>{{ title }}</h3>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-lg-12">
              <h5 class="pb-3">{{ $t('salary_calculator.bonus.available_bonuses_to_apply') }}</h5>
              <div v-for="bonus in modalData" v-if="modalData">
                <label><input type="checkbox"> {{ bonus.name }} - {{ bonus.type }}</label>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer col-lg-12">
          <div class="form-group row justify-content-end">
            <div class="col-lg-4">
              <button class="btn btn-success" @click="finish()">{{$t("common.export")}}</button>
            </div>
            <div class="col-lg-4">
              <button class="modal-default-button btn btn-secondary ml-3" @click="close()">{{$t("common.cancel")}}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'SCWorkLogExportDialog',
  props: ['show', 'data'],
  data: function () {
    return {
      modalData: this.data,
    };
  },
  computed: {
    title() {
      return this.$t('common.export_to_excel');
    }
  },
  methods: {
    close: function () {
      this.$emit('close');
    },
    finish () {
      this.$emit('onOK',this.modalData);
      this.close();
    },

  },
  watch: {
    data: function(newVal) {
      this.modalData = newVal;
    }
  },
  validations() {
    return {
      modalData: {

      }
    }
  },
}
</script>

<style scoped>
* {
  box-sizing: border-box;
}
</style>
