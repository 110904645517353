import {permissions} from "~/helpers/Permissions";
import Vue from 'vue'
let vm = new Vue();

//actions: create, update, delete properties as functions
export const types = {
  DATA_IMPORT: {
    id: 'DATA_IMPORT',
    ref: 'data-import',
    permission: permissions.DATA_IMPORT,
    season_selector: true,
  },
  SALARY_CALCULATOR: {
    id: 'SALARY_CALCULATOR',
    ref: 'salary-calculator',
    permission: permissions.SALARY_CALCULATOR_IMPORT,
    calculation_formula: true,
  },
  SALARY_CALCULATOR_HECTARE: {
    id: 'SALARY_CALCULATOR_HECTARE',
    ref: 'salary-calculator-hectare',
    permission: permissions.SALARY_CALCULATOR_HECTARE_IMPORT,
    calculation_formula: true,
  },
  FACE_CAM_EMAIL: {
    id: 'FACE_CAM_EMAIL',
    ref: 'face-cam-email',
    permission: permissions.FACE_CAM_EMAIL_IMPORT,
  },
  SHAPE_IMPORT: {
    id: 'SHAPE_IMPORT',
    ref: 'shape-import',
    permission: permissions.SHAPE_IMPORT,
    season_selector: true,
  },
  TRACTOR_IMPORT: {
    id: 'TRACTOR_IMPORT',
    ref: 'tractor-import',
    permission: permissions.TRACTOR_IMPORT,
  },
  WORK_TYPE_IMPORT: {
    id: 'WORK_TYPE_IMPORT',
    ref: 'work-type-import',
    permission: permissions.WORK_TYPE_IMPORT,
  },
  LEASE_CONTRACT: {
    id: 'LEASE_CONTRACT',
    ref: 'lease-contract',
    permission: permissions.LEASE_CONTRACT,
  },
  STOCK_PARTNER_IMPORT: {
    id: 'STOCK_PARTNER_IMPORT',
    ref: 'stock-partner-import',
    permission: permissions.STOCK_PARTNER_IMPORT,
  },
  STOCK_PRODUCT_IMPORT: {
    id: 'STOCK_PRODUCT_IMPORT',
    ref: 'stock-product-import',
    permission: permissions.STOCK_PRODUCT_IMPORT,
    warehouse_selector: false,
  },
  STOCK_INITIAL_IMPORT: {
    id: 'STOCK_INITIAL_IMPORT',
    ref: 'stock-initial-import',
    permission: permissions.STOCK_PARTNER_IMPORT,
    warehouse_selector: true,
  },
  SGPS_DASC_IMPORT: {
    id: 'SGPS_DASC_IMPORT',
    ref: 'sgps-dasc-import',
    permission: permissions.SGPS_DASC_IMPORT,
    season_selector: true,
    calculation_formula: true,
    group_by_feature: false,
  },
};

export const importTypeList = Object.keys(types).map((key) => types[key]);