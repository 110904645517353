<template>
  <div class="row table-responsive">
    <div class="col-lg-12">
      <table class="table">
        <th class="text-left">{{ $t("import_type") }}</th>
        <th class="text-left">{{ $t("file") }}</th>
        <th class="text-left">{{ $t("file_size") }}</th>
        <th class="text-left">{{ $t("status") }}</th>
        <th class="text-center">{{ $t("upload_date") }}</th>
        <th class="text-center">{{ $t("processed_at") }}</th>
        <th></th>
        <th></th>
        <tbody>
          <tr v-for="item in items" :key="Math.random()" @mouseover="hoveredRow = item.id"
            @mouseleave="hoveredRow = null">
            <td class="td-text text-left" :data-title="$t('import_type')"
              :class="{ 'selection-row': isHovered(item.id) }">
              {{ item.import_type }}
            </td>
            <td class="td-text text-left" :data-title="$t('file')" :class="{ 'selection-row': isHovered(item.id) }">
              {{ item.filename }}
            </td>
            <td class="td-text text-left" :data-title="$t('file_size')"
              :class="{ 'selection-row': isHovered(item.id) }">
              {{ item.file_size_mb }}
            </td>
            <td class="td-text text-left" :class="[getInfo(item).color, isHovered(item.id) ? 'selection-row' : '']"
              :data-title="$t('status')">
              {{ item.status }}
            </td>
            <td class="td-text text-center" :data-title="$t('upload_date')"
              :class="{ 'selection-row': isHovered(item.id) }">
              {{ item.created_at }}
            </td>
            <td class="td-text text-center" :data-title="$t('processed_at')"
              :class="{ 'selection-row': isHovered(item.id) }">
              {{ item.processed_at }}
            </td>
            <td class="td-text text-center" :class="{ 'selection-row': isHovered(item.id) }">
              <img :src="require('@/assets/images/desktop/icons/controls/edit.svg')"
                :title="$t('tools.import.import_data_validation')" class="status-icon"
                @click="goToImportValidatorPage(item.import_type, item)" v-if="getDataValidationIsAvailable(item)" />
              <img :src="require('@/assets/images/desktop/icons/controls/delete.svg')"
                :title="$t('tools.import.delete_from_live_data')" class="status-icon" @click="showConfirmDelete(item)"
                v-if="getDataValidationIsTransferedToLiveData(item)" />
            </td>
            <td class="td-text text-center" :class="{ 'selection-row': isHovered(item.id) }">
              <img :src="getInfo(item).path" :class="[getInfo(item).icon, getInfo(item).iconColor]"
                :title="getInfo(item).status" class="status-icon" @click="infoActionEvemt(item)" />
              <img src="~@/assets/images/icons/refresh_map.svg" class="refresh-icon primary-filter c-pointer p-2" v-if="
                item.error &&
                item.error.message_type === 'structure_errors' &&
                getInfo(item).retry
              " @click="retry(item)" />
            </td>
          </tr>
        </tbody>
      </table>
      <import-summary-dialog :show="showImportSummaryModal" @close="showImportSummaryModal = false"
        :data="importSummaryModalData" @onOK="showImportSummaryModal = false"></import-summary-dialog>
      <delete-confirmation-dialog :show="showDeleteConfirmationModal" @close="closeConfirmDelete"
        @confirm="deleteLiveDataByImport" :item="itemToDelete" :title="$t('tools.import.delete_from_live_data')"
        :message="$t('tools.import.delete_from_live_data_question')"></delete-confirmation-dialog>
    </div>
  </div>
</template>

<script>
import ImportSummaryDialog from "./ImportSummaryDialog";
import DeleteConfirmationDialog from "@/components/Modals/DeleteConfirmationDialog";
import { infoMessage, successMessage, errorMessageList } from "~/helpers/common";
import { types } from "@/helpers/ImportTypes";
import { mapActions } from "vuex";

export default {
  name: "FileList",
  props: ["data"],
  data() {
    return {
      items: this.data ? this.data : [],
      importSummaryModalData: {},
      showImportSummaryModal: false,
      showDeleteConfirmationModal: false,
      importTypes: types,
      hoveredRow: null,
      itemToDelete: null,
      itemToLiveData: null,
    };
  },
  components: {
    ImportSummaryDialog,
    DeleteConfirmationDialog,
  },
  methods: {
    ...mapActions({
      deleteValidatedDataFromLiveData: 'importConfig/deleteValidatedDataFromLiveData',
    }),
    isHovered(id) {
      return this.hoveredRow === id;
    },
    showConfirmDelete(item) {
      if (this.getDataValidationIsTransferedToLiveData(item)) {
        this.itemToDelete = item;
        this.showDeleteConfirmationModal = true;
      }
    },
    closeConfirmDelete() {
      this.itemToDelete = null;
      this.showDeleteConfirmationModal = false;
    },
    deleteLiveDataByImport() {
      this.showDeleteConfirmationModal = false;
      let vm = this;

      this.deleteValidatedDataFromLiveData({ 
        data_import_id: this.itemToDelete.import_id,
        department_uuid: this.itemToDelete.department_uuid,
        season_uuid: this.itemToDelete.season_uuid
       }).then((response) => {
        successMessage(vm, response.message);
        this.updateProcessedAfterValidation(this.itemToDelete.import_id, 0);
        this.itemToDelete = null;
      }).catch((e) => {
        errorMessageList(vm, e.errors);
        this.itemToDelete = null;
      });
    },
    updateProcessedAfterValidation(import_id, processed_after_validation) {
      const item = this.items.find(
        (importRow) => importRow.import_id === import_id
      );
      if (item) {
        item.processed_after_validation = processed_after_validation;
      }
    },
    getInfo(item) {
      let data = {
        color: "text-secondary",
        iconColor: "secondary-filter",
        icon: "uploaded",
        path: require("@/assets/images/desktop/icons/controls/uploaded.svg"),
        status: item.status,
      };

      if (item.imported === 1) {
        //imported to company
        data = {
          color: "text-primary",
          iconColor: "primary-filter",
          icon: "queue",
          path: require("@/assets/images/desktop/icons/controls/queue.svg"),
          status: item.status,
        };
      }

      if (item.processed === 1) {
        data = {
          color: "text-success",
          iconColor: "success-filter",
          icon: "check-circle",
          path: require("@/assets/images/desktop/icons/controls/check-circle.svg"),
          type: "success",
          status: item.status,
        };
      }

      if (item.processed === 0 && item.error) {
        data = {
          color: "text-danger",
          iconColor: "danger-filter",
          icon: "check-circle",
          path: require("@/assets/images/desktop/icons/controls/alert.svg"),
          type: "error",
          retry: item.import_id,
          message_type: item.error.message_type,
          status: item.status,
        };
      }

      if (this.getDataValidationIsAvailable(item)) {
        data = {
          color: "text-warning",
          iconColor: "primary-filter",
          icon: "transfer",
          path: require("@/assets/images/icons/transfer.svg"),
          status: this.$t('tools.import.transfer_to_live_data'),
        };
      }

      return data;
    },
    infoActionEvemt(item) {
      if (this.getDataValidationIsAvailable(item)) {
        item.uploaded_file_id = item.id;
        this.itemToLiveData = item;
        this.$emit("importValidatedDataEvent", item);
      } else {
        this.showDialog(item);
      }
    },
    showDialog(item) {
      let info = this.getInfo(item);

      if (info.type) {
        this.importSummaryModalData.title =
          info.type === "error"
            ? this.$t("tools.import_console.errors.import_error")
            : this.$t("tools.import_console.processed");
        this.importSummaryModalData.type = info.type;
        this.importSummaryModalData.info = info;
        this.importSummaryModalData.import_id = item.import_id;
        this.importSummaryModalData.import_type = item.import_type;
        this.importSummaryModalData.items =
          info.type === "error" ? item.error : item.success;
        this.showImportSummaryModal = true;
      }
    },
    retry(item) {
      let vm = this;

      if (item.import_id) {
        vm.$store
          .dispatch("common/retryCSVImport", {
            import_id: item.import_id,
          })
          .then((resp) => {
            infoMessage(
              vm,
              vm.$t("tools.configurator.added_to_queue", {
                file: item.filename,
              })
            );
            item.import_id = null;
          });
      }
    },
    goToImportValidatorPage(import_type, item) {
      if (this.getDataValidationIsAvailable(item)) {
        this.$router.push({
          path: "/tools/import-validator",
          query: { import_type: import_type, import_id: item.import_id },
        })
      }
    },
    /**
     * Checks if data validation is available for the given item.
     * @param {Object} item - The item to check.
     * @returns {boolean} - Returns true if data validation is available, false otherwise.
     */
    getDataValidationIsAvailable(item) {
      if ((this.itemToDelete?.import_id ?? 0) != item.import_id && item.import_type === this.importTypes.SGPS_DASC_IMPORT.id &&
        (this.itemToLiveData?.import_id ?? 0) != item.import_id &&
        item.processed === 1 && item.processed_after_validation === 0) {
        return true;
      }
      return false;
    },
    /**
     * Determines if the data validation is transferred to live data.
     * 
     * @param {Object} item - The item to check.
     * @returns {boolean} - True if the data validation is transferred to live data, false otherwise.
     */
    getDataValidationIsTransferedToLiveData(item) {
      if ((this.itemToDelete?.import_id ?? 0) != item.import_id && item.import_type === this.importTypes.SGPS_DASC_IMPORT.id &&
        (this.itemToLiveData?.import_id ?? 0) != item.import_id &&
        item.processed === 1 && item.processed_after_validation === 1) {
        return true;
      }
      return false;
    },
    setItemToLiveData(item) {
      this.itemToLiveData = item;
    },
  },
  mounted() {
  },
  watch: {
    data: {
      handler: function (newVal) {
        this.items = newVal;
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
.status-icon {
  width: 22px;
  height: 22px;
  cursor: pointer;
}

.selection-row {
  background-color: #e6e6e3;
}
</style>
