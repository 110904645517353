<template>
    <div class="add-disease-wrapper">
       <div class="add-disease-title">Adaugă dăunători</div>
       <div class="title-separator"></div>
       <div class="add-disease-input-label" v-if="false">Tipuri de dăunători</div>
       <div class="add-disease-types">
            <div :class="['add-disease-type-button', 'left-button', {'button-selected' : selectedTypeCode == 'BL'}]" @click="selectDiseaseType('BL')">
                <img v-if="selectedTypeCode == 'BL'" src="@/assets/images/icons/ciuperca_white.svg" />
                <img v-else src="@/assets/images/icons/ciuperca.svg" />
            </div>
            <div :class="['add-disease-type-button', {'button-selected' : selectedTypeCode == 'DN'}]"  @click="selectDiseaseType('DN')">
                <img v-if="selectedTypeCode == 'DN'" src="@/assets/images/icons/bug_white.svg" />
                <img v-else src="@/assets/images/icons/bug.svg" />
            </div>
            <div :class="['add-disease-type-button', {'button-selected' : selectedTypeCode == 'BR'}]" @click="selectDiseaseType('BR')">
                <img v-if="selectedTypeCode == 'BR'" src="@/assets/images/icons/fungicid_disease_white.svg" />
                <img v-else src="@/assets/images/icons/fungicid_disease.svg" />
            </div>
            <div :class="['add-disease-type-button', {'button-selected' : selectedTypeCode == 'SM'}]" @click="selectDiseaseType('SM')">
                <img v-if="selectedTypeCode == 'SM'" src="@/assets/images/icons/straturi_white.svg" />
                <img v-else src="@/assets/images/icons/straturi.svg" />
            </div>
            <div :class="['add-disease-type-button', 'right-button', {'button-selected' : selectedTypeCode == 'ALT'}]" @click="selectDiseaseType('ALT')">
                <img v-if="selectedTypeCode == 'ALT'" src="@/assets/images/icons/plus.svg" />
                <img v-else src="@/assets/images/icons/plus_green.svg" />
            </div>
       </div>
       <div class="add-disease-input-label" v-if="false">Dăunători</div>
       <b-input-group class="search_disease">
                      <b-input-group-prepend>
                        <img src="~@/assets/images/icons/search.svg" />
                      </b-input-group-prepend>
                      <b-form-input
                        placeholder="Caută dăunători"
                        v-model="disease_search_text"
                      ></b-form-input>
                      <div class="input-plus-button" v-if="showAddDiseaseButton" @click="addNewDisease">
                        <img src="@/assets/images/icons/plus_green.svg" />
                      </div>
                </b-input-group>
       <DxScrollView ref="add-disease-list" class="add-disease-list" :use-native="true" @reach-bottom="reachBottom">
            <div>

                <div>
                    <b-form-checkbox v-if="d.name.length > 0 && d.name != '-'"
                    class="disease-checkbox-row"
                    v-for="(d, index) in ((disease_list) ? disease_list : []).filter((obj) => {return obj.name
                              .toUpperCase()
                              .includes(disease_search_text.toUpperCase());})"
                    :key="index"
                    v-model="selected_diseases"
                    :value="d.uuid"
                    @change="diseaseSelectChanged"
                    >
                     {{ d.name }}
                </b-form-checkbox>
                </div>

            </div>
        </DxScrollView>
       <div class="add-disease-input-label" v-if="false">Gradul de infectare</div>
       <div class="add-disease-infection-level">
            <div :class="['add-disease-infection-level-item', 'left-button', {'button-selected' : infectionLevel == 1}]" @click="selectInfectionLevel(1)">Scăzut</div>
            <div :class="['add-disease-infection-level-item', {'button-selected' : infectionLevel == 2}]" @click="selectInfectionLevel(2)">Mediu</div>
            <div :class="['add-disease-infection-level-item', 'right-button', {'button-selected' : infectionLevel == 3}]" @click="selectInfectionLevel(3)">Înalt</div>
       </div>
       <toast-message ref="toastMessage"></toast-message>
    </div>
</template>

<script>

import { mapGetters, mapActions } from "vuex";
import { DxScrollView } from "devextreme-vue/scroll-view";
import ToastMessage from "../../../components/Mobile/toastMessage.vue"
import { relativeTimeThreshold } from "moment";

export default {
    name: "AddDisease",
    data(){
        return {
            selectedTypeCode: '',
            infectionLevel: 0,
            disease_list: [],
            disease_pagination: {},
            selected_diseases: [],
            selected_diseases_objects: [],
            disease_search_text: '',
            allow_filter_by_text: true,
            searchTimer: {}
        };
    },
    components: {
        DxScrollView,
        ToastMessage
    },
    computed: {
        showAddDiseaseButton(){
            return this.selectedTypeCode == 'ALT' && this.disease_search_text.length > 0 && ((this.disease_list) ? this.disease_list : []).filter((obj) => {return obj.name
                              .toUpperCase()
                              .includes(this.disease_search_text.toUpperCase());}).length == 0;
        }
    },
    watch: {
        disease_search_text: function (){
            if(this.allow_filter_by_text){
                clearTimeout(this.searchTimer);

                this.searchTimer = setTimeout(() => {
                    this.selectDiseaseType(this.selectedTypeCode, false);
                }, 500);
            }
        }
    },
    methods: {
        ...mapActions({
            getDiseases: "scouting/getDiseases",
            createDiseases: "scouting/createDiseases",
        }),
        addNewDisease(){
            let newDisease = {
                uuid: '',
                name: this.disease_search_text,
                code: 'ALT',
                type: {
                    code: 'ALT'
                }
            };
            this.createDiseases(newDisease);
            this.disease_list = this.disease_list ? this.disease_list : [];
            this.disease_list.push(newDisease);
            this.disease_search_text = '';
        },
        resetForm(){
            this.$refs['add-disease-list'].instance.release(true);
            this.selectedTypeCode = ''
            this.disease_list = [];
            this.selected_diseases = [];
            this.selected_diseases_objects = [];
            this.disease_search_text = '';
            this.infectionLevel = 0;
        },
        selectDiseaseType(typeCode, nextPage = false){
            let pageNumber = 1;
            if(nextPage ){
                pageNumber = this.disease_pagination.next_page;
            }
            if(this.selectedTypeCode != typeCode){
                this.selected_diseases = [];
                this.allow_filter_by_text = false;
                this.disease_search_text = '';
                this.allow_filter_by_text = true;
            }
            this.selectedTypeCode = typeCode;

            this.getDiseases(
                {
                    code:this.selectedTypeCode,
                    name: this.disease_search_text,
                    page: pageNumber
                }).then((response) => {
                if(nextPage){
                    response.data.forEach(element => {
                        let checkDisease = this.disease_list.find(obj => {obj.uuid == element.uuid});
                        if(checkDisease == undefined){
                            this.disease_list.push(element);
                        }
                    });
                }
                else{
                    this.disease_list = response.data;
                }
                this.disease_pagination = response.pagination;
                this.fillCallback();
            });
        },
        selectInfectionLevel(lvl){
            this.infectionLevel = lvl;
        },
        checkIfDataIsValid(){
            if(this.selectedTypeCode == ''){
                this.$refs.toastMessage.showError('Selectați tipul de dăunători!');
            } else if(this.selected_diseases.length == 0){
                this.$refs.toastMessage.showError('Selectați măcar un dăunători!');
            } else if (this.infectionLevel == 0){
                this.$refs.toastMessage.showError('Selectați gradul de infectarei!');
            }
            else{
                return true;
            }
            return false;
        },
        reachBottom(){
            if(this.disease_pagination.current_page < this.disease_pagination.last_page){
                this.selectDiseaseType(this.selectedTypeCode, true);
            }
        },
        fillCallback(){
            if(this.disease_pagination.current_page == this.disease_pagination.last_page || this.disease_list.length == 0){
                this.$refs['add-disease-list'].instance.release(true);
            }
            else{
                this.$refs['add-disease-list'].instance.release(false);
            }
            this.$refs['add-disease-list'].instance.update();
            if(this.disease_pagination.current_page == 1){
                this.$refs['add-disease-list'].instance.scrollTo(0)
            }
        },
        getDataAtApply(){
            return {
                diseases: this.selected_diseases_objects,
                infection_level: this.infectionLevel
            };
        },
        diseaseSelectChanged(options){
            options.forEach(option_element => {
                let checkObj = this.selected_diseases_objects.find(obj => obj.uuid == option_element);
                if(checkObj == undefined){
                    checkObj = this.disease_list.find(obj => obj.uuid == option_element);
                    if(checkObj != undefined){
                        this.selected_diseases_objects.push(checkObj);
                    }
                }
            });
            this.selected_diseases_objects = this.selected_diseases_objects.filter((obj) => {
                return options.find(obj_option => obj_option == obj.uuid) != undefined;
            })
        }
    },
    mounted(){

    }
}

</script>

<style lang="scss">
  @import "@/assets/scss/scoutingMobile/addDisease.scss";
</style>
