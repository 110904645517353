<template>
  <div id="product-filters" class="filters p-3 col-lg-12">
    <div class="row">
      <div class="col-sm-12 col-lg pb-3 pb-lg-0">
        <label class="control-label">{{ $t('common.name')}}</label>
        <input v-model="filters.name" class="form-control"/>
      </div>
      <div class="col-sm-12 col-lg pb-3 pb-lg-0" v-if="false">
        <label class="control-label">{{ $t('works.parcels.identifier')}}</label>
        <input v-model="filters.identifier" class="form-control"/>
      </div>
      <div class="col-sm-6 col-lg form-group">
        <label class="control-label">{{ $t("works.parcels.culture") }}</label>
        <autocomplete-search
          :placeholder="$t('works.parcels.culture')"
          :dispatch="getCulturesWithDep"
          search-key="name"
          ref="culture"
          :required="true"
          :data="cultureName"
          @onSelected="onCultureSelected"
          @onChange="onCultureChanged"
          @onReset="onCultureReset"
        ></autocomplete-search>
      </div>
      <div class="col-sm-12 col-lg pb-3 pb-lg-0">
        <label class="control-label">{{ $t('common.date_from')}}</label>
        <date-picker2
          v-model="creation_date_from"
          :value-type="creation_date_from_input"
          format="DD/MM/YYYY"
          type="date"
          placeholder="dd/mm/yyyy"
          :input-class="['date-picker-style']"
          @change="onCreationDateFromChanged"
          ref="date_from"
        ></date-picker2>
      </div>
      <div class="col-sm-12 col-lg pb-3 pb-lg-0">
        <label class="control-label">{{ $t('common.date_to')}}</label>
        <date-picker2
          v-model="creation_date_to"
          :value-type="creation_date_to_input"
          format="DD/MM/YYYY"
          type="date"
          placeholder="dd/mm/yyyy"
          :input-class="['date-picker-style']"
          @change="onCreationDateToChanged"
          ref="date_to"
        ></date-picker2>
      </div>
    </div>
      <div class="row">
        <div class="col-lg-4">
          <agro-filter @onChange="onDynamicFiltersChanged"/>
        </div>
      </div>
    <div class="row">
      <div class="col-sm-12 col-lg-3 pb-3 pb-lg-0">
        <label class="control-label">{{ $t('season')}}</label>
        <b-form-select v-model="filters.season_uuid" class="form-control" :options="season_list"></b-form-select>
      </div>
      <div class="col-sm-12 col-lg-2 pt-0 pt-lg-4 mt-0 mt-1">
        <button class="btn btn-primary col-lg-12" @click="applyFiltersClick">{{$t("common.show")}}</button>
      </div>
      <div class="col-sm-12 col-lg-2 pt-0 pt-lg-4 mt-0 mt-1">
        <button class="btn btn-secondary col-lg-12" @click="resetFilters">{{$t("common.reset_filters")}}</button>
      </div>
    </div>
  </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex'
    import * as type from '../../../../store/mutation-types'
    import DatePicker2 from 'vue2-datepicker';
    import 'vue2-datepicker/index.css';
    import * as endpoints from '../../../../store/api/endpoints';
    import {buildDefaultDropdownList, buildDropdownOptions} from "~/helpers/common";
    import AutocompleteSearch from "~/components/Common/AutocompleteSearch/Index.vue";
    import AgroFilter from "~/components/AgroFilter/Index.vue";

    export default {
        name: 'ParcelFilters',
        data() {
            return {
                filters:  this.parcelFilters ? this.parcelFilters : {},
                creation_date_from: null,
                creation_date_from_input: null,
                creation_date_to: null,
                creation_date_to_input: null,
                local_seasons: this.seasons ? this.seasons: [],
            }
        },
        components:  {
          AgroFilter,
            DatePicker2,
            AutocompleteSearch
        },
        computed: {
            ...mapGetters({
                currentDepartment: 'auth/currentDepartment',
                parcelFilters: 'works-parcels/parcelFilters',
                departments: 'company/departments',
                seasons: 'seasons/seasons'
            }),
            departments_list() {
                return buildDropdownOptions(Object.assign([], this.departments), {
                    value: 'uuid',
                    text: 'name',
                });
            },
            season_list() {
              return buildDefaultDropdownList(Object.assign([], this.local_seasons));
            },
            cultureName: {
              get() {
                return {
                  uuid: this.filters.culture_uuid,
                  name: this.filters.culture,
                }
              },
              set(val) {
                return {
                  uuid: val.uuid,
                  name: val.name
                }
              }
            },
          department_list() {
            return buildDefaultDropdownList(Object.assign([], this.departments));
          },
        },
        methods: {
            ...mapActions({
                getParcels: 'works-parcels/getParcels',
                getSeasons: 'seasons/getSeasons',
                getCultures: 'works-cultures/getCultures'
            }),
            applyFiltersClick() {
                this.$set(this.filters, 'page', 1);
                this.applyFilters();
            },
            applyFilters() {
                this.$emit('onFiltersApplied', this.filters);
                this.filters.url =  endpoints.PARCELS;
                this.getParcels(this.filters);
            },
            resetFilters(additionalParams = {}) {
                this.filters = {
                    name: '',
                    identifier: '',
                    creation_date_from: '',
                    creation_date_to: '',
                    department_uuid: null,
                    season_uuid: additionalParams ? additionalParams.season_uuid : '',
                    page: 1,
                };

                this.creation_date_from = null;
                this.creation_date_from_input = null;
                this.creation_date_to = null;
                this.creation_date_to_input = null;

                this.$store.commit("works-parcels/"+type.SET_PARCEL_FILTERS, this.filters);
                this.applyFilters();
            },
            onCreationDateFromChanged(date) {
                this.filters.date_from = date;
            },
            onCreationDateToChanged(date) {
                this.filters.date_to = date;
            },
            onDepartmentChanged(val) {
              this.getSeasons({
                department_uuid: val,
                limit: 999,
              });
            },
            onCultureSelected(data) {
              //this.filters.culture_uuid = data.uuid;
              if (data) {
                this.filters.culture = data.name;
              }
            },
            onCultureChanged(data) {
              if (data) {
                this.filters.culture = data.name;
              }
            },
            onCultureReset() {
                this.filters.culture_uuid = null;
                this.filters.culture = null;
            },
            getCulturesWithDep() {
              return new Promise((resolve, reject) => {
                this.getCultures({
                  department_uuid: this.filters.department_uuid,
                  enablePagination: 0,
                  limit: 9999,
                }).then((resp) => {
                  resolve(resp);
                }).catch((e) => {
                  reject(e);
                });
              })
            },
          onDynamicFiltersChanged(selectedUUID) {
            this.filters.department_uuid = selectedUUID;
          },
          selectCurrentSeason() {
              if (this.seasons && this.seasons.length > 0) {
                const reversed = this.seasons.reverse();
                this.filters.season_uuid = reversed[0];
                return this.filters.season_uuid;
              }
          }
        },
        mounted(){
          const vm = this;
            this.getSeasons({
              limit: 999,
            }).then((resp) => {
              const currentSeason = vm.selectCurrentSeason();
              this.resetFilters({
                season_uuid: currentSeason ? currentSeason.uuid : '',
              });
            });
        },
        watch: {
            parcelFilters: {
                handler: function(newVal) {
                    this.filters = newVal;
                },
                deep: true,
            },
            seasons: {
              handler: function(newVal) {
                this.local_seasons = newVal;
              },
              deep: true,
            },
        }
    }
</script>

<style scoped>
.control-label {
  display: block;
}
</style>
