<template>
  <card :title="title" id="user-details">
    <document-block :data="data"></document-block>
  </card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { hasPermission, permissions, actions } from "@/helpers/Permissions";
import DocumentBlock from "~/pages/tools/justifying-documents/controls/DocumentBlock.vue";

export default {
  name: 'JustifyingDocument',
  middleware: 'check-company-admin',
  data() {
    return {
        data: {},
    }
  },
  components: {
    DocumentBlock
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
      roles: 'roles/roles',
      departments: 'company/departments',
      domain: 'auth/domain'
    }),
    title() {
        return this.$t('tools.justifying_documents.' + (this.data.uuid ? 'edit' : 'new'));
    },
    canEdit() {
      return hasPermission(this.user, permissions.JUSTIFYING_DOCUMENTS, actions.EDIT);
    },
    canDelete() {
      return hasPermission(this.user, permissions.JUSTIFYING_DOCUMENTS, actions.DELETE);
    },
  },
}
</script>
