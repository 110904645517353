import * as types from './mutation-types'
import * as api from './api/index'
import * as endpoints from './api/endpoints'

// state
export const state = () => {
    return {
      customProductStatuses:[],
    }
  }
  
  // getters
  export const getters = {
    customProductStatuses: state => state.customProductStatuses,
  }
  
  // mutations
  export const mutations = {
    [types.SET_CUSTOM_PRODUCT_STATUSES]: (state, payload) => {
        state.customProductStatuses = payload;
      },
  }
  
  // actions
  export const actions = {
    getCustomProductStatuses({ commit }, payload) {
        return api.fetch(endpoints.CUSTOM_PRODUCT_STATUS, payload, commit, (response) => {
          commit(types.SET_CUSTOM_PRODUCT_STATUSES, response.data);
        });
      },
  }