import * as types from "../../mutation-types";

const mutations = {
  [types.SET_SC_MEASURE_UNITS]: (state, payload) => {
    state.sc_measure_units = payload;
  },
  [types.SET_SC_MEASURE_UNIT]: (state, payload) => {
    state.sc_measure_unit = payload;
  },
  [types.ADD_SC_MEASURE_UNIT_ITEM]: (state, payload) => {
    state.sc_measure_units.push(payload);
  },
  [types.UPDATE_SC_MEASURE_UNIT_ITEM]: (state, payload) => {
    state.sc_measure_units.splice(state.sc_measure_units.indexOf(
      state.sc_measure_units.find(c => c.uuid === payload.uuid)
    ), 1, payload);
  },
  [types.DELETE_SC_MEASURE_UNIT_ITEM]: (state, payload) => {
    state.sc_measure_units.splice(state.sc_measure_units.indexOf(
      state.sc_measure_units.find(c => c.uuid === payload.uuid)
    ), 1);
  },
  [types.SET_SC_MEASURE_UNIT_FILTERS]: (state, payload) => {
    state.sc_measure_unit_filters = payload;
  },
}

export default mutations;
