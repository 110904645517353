<template>
  <ul class="sidebar">
    <sidebar-menu-item v-for="item in items" :data="item" :key="Math.random()"/>
  </ul>
</template>

<script>
import SidebarMenuItem from "~/components/Sidebar/SidebarMenuItem.vue";
export default {
  name: 'Sidebar',
  props: ['data'],
  data() {
    return {
        items: this.data ?? [],
    }
  },
  components: {
      SidebarMenuItem,
  },
  methods: {
    onCollapse(item) {
      this.$emit('onCollapse', item);
    }
  },
  watch: {
    data: {
      handler: function(newVal) {
        this.items = newVal;
      },
      deep: true,
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/global/global";
.sidebar {
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  position: relative;

  li:first-child {
    padding-top: 0;
  }
}
</style>
