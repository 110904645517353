import axios from '@/plugins/axios'
import Cookies from 'js-cookie'
import * as types from './mutation-types'
import {setSubdomain} from "~/helpers/common";

// state
export const state = () => {
  return {
    user: null,
    token: Cookies.get('token'),
    domain: null,
    current_department: {},
  }
}

// getters
export const getters = {
  user: state => state.user,
  domain(state) {
      return state.domain;
  },
  token: state => state.token,
  check: state => state.user !== null,
  isCompanyAdmin: state => (state.user && state.user.is_company_admin) || (state.user && state.user.is_superadmin),
  currentDepartment(state, getters, rootState) {
    let departments = rootState.company.departments;

    if (departments && departments.length > 0) {
      let department = departments[0];
      department.department_uuid = department.uuid;
      department.domain = getters.domain.domain;
      return department;
    }

    return null;
  }
}

// mutations
export const mutations = {
  [types.SAVE_TOKEN] (state, { token, remember }) {
    state.token = token
    Cookies.set('token', token, { expires: remember ? 365 : null })
  },

  [types.FETCH_USER_SUCCESS] (state, user) {
    state.user = user;
  },

  [types.SET_DOMAIN] (state, domain ) {
    state.domain = domain;
  },

  [types.FETCH_USER_FAILURE] (state, data) {
    state.token = null;
    state.user = null;
    Cookies.remove('token');
  },

  [types.LOGOUT] (state) {
    state.user = null;
    state.token = null;

    Cookies.remove('token');
  },

  [types.UPDATE_USER] (state, payload) {
    state.user = payload;
  },

  [types.SET_CURRENT_DEPARTMENT]: (state, payload) => {
    state.current_department = payload;
  },
}

// actions
export const actions = {
  saveToken ({ commit, dispatch }, payload) {
    commit(types.SAVE_TOKEN, payload)
  },

  login ({ commit }, payload) {

    return new Promise((resolve, reject) => {
        axios.post(process.env.VUE_APP_BASE_URL+'/login', payload).then((resp) => {
          resolve(resp.data);
        }).catch((e) => {
          reject(e);
        })
    });
  },

  fetchUser ({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get('/user').then((response) => {
        commit(types.FETCH_USER_SUCCESS, response.data.data);
        resolve(response.data.data);
      }).catch((e) => {
        commit(types.FETCH_USER_FAILURE, e);
        reject(e);
      });
    })
  },

  checkDomain ({ commit }, payload) {
    let baseURL = setSubdomain(payload.domain, process.env.VUE_APP_BASE_URL);
    axios.interceptors.request.use(
      async config => { config.baseURL = baseURL; return config;
      });

    return new Promise((resolve, reject) => {
      axios.get(baseURL+'/check-domain')
        .then((response) => {
          let data = response.data.data;
          data.domain_path = baseURL;
          commit(types.SET_DOMAIN, data);
          resolve(data);
      }).catch((e) => {
        reject(e.response);
      });
    })
  },

  updateUser ({ commit }, payload) {
    commit(types.UPDATE_USER, payload)
  },

  updateMyAccount({commit}, payload) {
    return new Promise((resolve, reject) => {
      axios.put('/myaccount', payload).then((response) => {
        commit(types.UPDATE_USER, response.data.data);
        resolve(response.data);
      }).catch((e) => {
        reject(e.response.data);
      });
    })
  },
  changeMyPassword({commit}, payload) {
    return new Promise((resolve, reject) => {
      axios.put('/myaccount/password', payload).then((response) => {
        resolve(response.data);
      }).catch((e) => {
        reject(e.response.data);
      });
    })
  },

  async logout ({ commit }) {
    try {
      await axios.post(process.env.VUE_APP_BASE_URL+'/logout')
    } catch (e) { }

    commit(types.LOGOUT)
  },

  async fetchOauthUrl (ctx, { provider }) {
    const { data } = await axios.post(`/oauth/${provider}`)

    return data.url
  },

  changeDepartment({commit}, payload) {
    return new Promise((resolve, reject) => {
      axios.put('/myaccount/change-department', payload)
        .then((response) => {
          commit(types.SET_CURRENT_DEPARTMENT, response.data.data.default_department);
          resolve(response.data.data);
        }).catch((e) => {
        commit("common/"+types.SET_ERROR_MESSAGE, e, { root: true });
        reject(e);
      });
    })
  },
}
