import { render, staticRenderFns } from "./DeleteConfirmationDialog.vue?vue&type=template&id=c44545ae&scoped=true"
import script from "./DeleteConfirmationDialog.vue?vue&type=script&lang=js"
export * from "./DeleteConfirmationDialog.vue?vue&type=script&lang=js"
import style0 from "./DeleteConfirmationDialog.vue?vue&type=style&index=0&id=c44545ae&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c44545ae",
  null
  
)

export default component.exports