import * as api from '../api/index'
import * as endpoints from '../api/endpoints'
import * as types from "../mutation-types";

const actions = {
  getFertilityProducts({ commit }, payload) {
    
    return api.fetch(endpoints.FERTILITY_PRODUCTS, payload, commit, (response) => {
      commit(types.SET_FERTILITY_PRODUCTS, response.data);
    });
  },
  
  getFertilityProductsLight({ commit }, payload) {
    
    return api.fetch(endpoints.FERTILITY_PRODUCTS_LIGHT, payload, commit, (response) => {
      commit(types.SET_FERTILITY_PRODUCTS_LIGHT, response.data);
    });
  },

}

export default actions;
