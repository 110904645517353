<!-- template for the modal component -->
<template type="x/template">
  <transition name="modal" id="contact-info-dialog">
    <div class="modal-mask" v-show="show">
      <div class="modal-container" @click.stop>
        <div class="modal-header">
          <h3 :class="[modalData.info.color]" v-if="modalData && modalData.info">{{ modalData.title }}</h3>
        </div>
        <div class="modal-body">
          <div class="row">
            <table class="table">
              <thead>
              <tr>
                <th class="thumbnail" v-if="modalData && modalData.info" colspan="2">Logs</th>
              </tr>
              </thead>
              <tbody v-if="modalData.info && modalData.info.type === 'error'">
              <div v-if="modalData.items.errors && modalData.items.errors.error">
                {{ modalData.items.errors.error}}
              </div>
              <tr v-for="(item, key) in modalData.items && modalData.items[0]" :key="Math.random()">
                <td class="td-text">
                  <p v-if="Array.isArray(item)" class="rowKey">{{ key}}</p>
                  <p class="text-danger" v-if="modalData.items"> {{ modalData.items.error_title }}</p>
                  <div v-for="(row, rowKey) in item" :key="Math.random()" v-if="Array.isArray(item)">
                    <div v-if="row.template && row.template.name" class="pt-4">
                      <b>Template: {{ row.template.name }}</b>
                    </div>
                    <div v-if="row.columns">
                      Missing columns:
                      <div v-for="col in row.columns">
                          {{ col }}
                      </div>
                    </div>

                    <div v-else>{{ row }}</div>
                  </div>
                  <div v-if="!Array.isArray(item)"><span class="rowKey">{{ key }}:</span> <span class="rowVal">{{ item }}</span></div>
                </td>
              </tr>
              </tbody>

              <tbody v-if="modalData.info && modalData.info.type === 'success'">
              <tr v-for="(row, rowKey) in modalData.items" :key="Math.random()">
                <td class="text-left" align="left">
                  <span class="rowKey text-left">{{ rowKey }}:</span>
                </td>
                <td class="text-left" align="left">
                  <span class="rowVal text-left">{{ row }}</span>
                </td>
              </tr>
              </tbody>

            </table>
            <p v-if="modalData.items
                &&
                (modalData.items.message_type === 'structure_errors' || (modalData.items.message_type === 'validation_errors' && modalData.items.template === ''))"
             class="font-weight-bold">
              {{ $t('tools.import_console.configure_template') }}
            <router-link :to="'/tools/import-configurator/templates?import_id='+modalData.import_id+'&import_type_id='+modalData.import_type">
              {{ $t('tools.import_console.here') }}
            </router-link>
            </p>
          </div>
        </div>
        <div class="modal-footer col-lg-10">
          <div class="form-group row justify-content-end">
            <div class="col-lg-4">
              <button class="btn btn-success" @click="finish()">{{ $t('common.ok') }}</button>
            </div>
            <div class="col-lg-4">
              <button class="modal-default-button btn btn-secondary ml-3" @click="close()">{{ $t('common.close') }}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
    export default {
        name: 'ImportSummaryDialog',
        props: ['show', 'data'],
        data: function () {
            return {
                modalData: this.data ? this.data : {},
            };
        },
        computed: {

        },
        methods: {
            close: function () {
                this.$emit('close');
            },
            finish () {
                this.$emit('onOK',this.modalData);
                this.close();
            },

        },
        watch: {
            data: {
                handler: function(newVal) {
                    this.modalData = newVal;
                },
                deep: true,
            }
        },
        validations() {
            return {
                modalData: {

                }
            }
        },
    }
</script>

<style scoped>
  * {
    box-sizing: border-box;
  }

  .rowKey {
    text-transform: capitalize;
    font-weight: bold;
  }
</style>
