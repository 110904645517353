<!-- template for the modal component -->
<template type="x/template">
  <transition name="modal" id="contact-info-dialog">
    <div class="modal-mask" v-show="show">
      <div class="modal-container" @click.stop>
        <div class="modal-header">
          <h3>{{ dialogTitle }}</h3>
        </div>
        <div class="modal-body">
          <div class="row">

            <table class="table">
              <thead>
              <tr>
                <th class="thumbnail">{{$t("user.name")}}</th>
                <th class="td-text">{{$t("user.email")}}</th>
                <th class="td-text">{{$t("user.phone")}}</th>
                <th class="td-text" v-if="userManagement">{{$t("common.actions")}}</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="item in modalData" :data="item" :key="item.uuid" v-if="!item.deleted">
                <td class="td-text">
                  {{ [item.first_name, item.last_name].join(' ') }}
                </td>
                <td class="td-text">
                  {{ item.email }}
                </td>
                <td class="td-text">
                  {{ item.phone_number }}
                </td>
                <td class="td-text" v-if="userManagement && item.user_uuid">
                  <a :href="getUserLink(item)" target="_blank">Manage</a>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="modal-footer col-lg-12">
          <div class="form-group row justify-content-end">
            <div class="col-lg-4">
              <button class="btn btn-success" @click="finish()">{{$t("common.ok")}}</button>
            </div>
            <div class="col-lg-4">
              <button class="modal-default-button btn btn-secondary ml-3" @click="close()">{{$t("common.close")}}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
    import { required } from 'vuelidate/lib/validators'

    export default {
        name: 'ContactListDialog',
        props: ['show', 'data',   'userManagement', 'title'],
        data: function () {
            return {
                modalData: this.data,
            };
        },
        computed: {
            dialogTitle() {
                return this.title ? this.title : this.$t('contact_list');
            }
        },
        methods: {
            close: function () {
                this.$emit('close');
            },
            finish () {
                this.$emit('onOK',this.modalData);
                this.close();
            },
            getUserLink(item) {
                if(item && item.user_uuid) {
                    return '/users/'+item.user_uuid;
                }
            }
        },
        watch: {
            data: function(newVal) {
                this.modalData = newVal;
            }
        },
        validations() {
            return {
                modalData: {

                }
            }
        },
    }
</script>

<style scoped>
  * {
    box-sizing: border-box;
  }
</style>
