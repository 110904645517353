const state = () => {
  return {
    layers: [],
    layer_works: [],
    layer_filters: {},
    layer_season_uuid: '',
    layers_loading: ''
  }
}

export default state;
