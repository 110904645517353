<template>
  <card :title="$t('dashboard')">
    <div id="dashboard">
      Hello!
    </div>
  </card>
</template>

<script>
  export default {
      name: 'Dashboard',
      middleware: 'auth',
  }
</script>
