<template>
  <div class="row table-responsive">
    <div class="col-lg-12">
      <table class="table">
        <th>{{$t("name")}}</th>
        <th>{{$t("permissions")}}</th>
        <th></th>
        <tbody>
        <role v-for="role in roles" :key="Math.random()"
              @onEdit="onEdit"
              @onRemoveItem="onRemoveItem"
              :data="role" :permissions="role.permissions"/>
        </tbody>
      </table>
    </div>
    <edit-role-dialog :show="showRolesModal" @close="showRolesModal = false"
                      :data="rolesModalData"
                      @onSaved="onSaved"
                      @onSaveAsTemplate="onSaveAsTemplate"
    ></edit-role-dialog>
  </div>
</template>

<script>
  import EditRoleDialog from "../partials/EditRoleDialog";
  import Role from './index'
  import {errorMessage, successMessage} from "../../../helpers/common";
  import { mapActions, mapGetters } from 'vuex'

    export default {
        name: 'RoleList',
        props: ['data', 'permissions'],
        data() {
            return {
                roles: this.data ? this.data : [],
                showRolesModal: false,
                rolesModalData: {},
            }
        },
        components: {
            Role,
            EditRoleDialog,
        },
        methods: {
            ...mapActions({
                createRole: 'roles/createRole',
                updateRole: 'roles/updateRole',
                deleteRole: 'roles/deleteRole',
            }),
            showEditDialog(role) {
                this.rolesModalData = role;
                this.rolesModalData.available_permissions = Object.assign([], this.permissions);
                this.showRolesModal = true;
            },
            onEdit(role) {
                this.showEditDialog(role);
            },
            onRemoveItem(role) {
                let vm = this;
                this.$dialog.confirm(this.$t('remove_role_confirm', { name: role.name }), {})
                    .then((dialog) => {
                        this.deleteRole({
                            uuid: role.uuid
                        }).then((resp) => {
                            if(resp.data.uuid) {
                                successMessage(vm, resp.message);
                            }

                            dialog.close();
                        });
                    });
            },
            onSaveAsTemplate(role) {
                role.saveAsTemplate = 1;
                this.onSaved(role);
            },
            onSaved(role) {
                let vm = this;
                if(role && role.uuid) {
                    this.updateRole(role).then((resp) => {
                        if(resp.data.uuid) {
                            successMessage(vm, resp.message);
                        }
                    });
                } else {
                    this.createRole(role).then((resp) => {
                        if(resp.data.uuid) {
                            successMessage(vm, resp.message);
                        }
                    });
                }
            }
        },
        watch: {
            data: {
                handler: function(newVal) {
                    this.roles = newVal;
                },
                deep: true,
            }
        }
    }
</script>
