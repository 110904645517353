<template>
  <div id="sc-work-log-list-filters" class="filter-actions col-lg-12 filters p-3">
    <div class="row">
      <div class="col-sm-4 col-lg">
        <label class="control-label">{{$t("salary_calculator.employee.name")}}</label>
        <v-select
          v-model="employee"
          class=""
          placeholder="-"
          autocomplete="off"
          @option:selected="onEmployeeSelected"
          taggable
          :options="employee_list"></v-select>
      </div>
      <div class="col-sm-4 col-lg">
        <label class="control-label">{{$t("salary_calculator.employee_role.name")}}</label>
        <b-form-select
          v-model="filters.employee_role_uuid"
          class="form-control"
          :options="employee_role_list"></b-form-select>
      </div>
      <div class="col-sm-4 col-lg">
        <label class="control-label">{{$t("salary_calculator.work_group.name")}}</label>
        <v-select
          v-model="work_group"
          class=""
          placeholder="-"
          autocomplete="off"
          @option:selected="onWorkGroupSelected"
          taggable
          :options="work_group_list"></v-select>
      </div>
      <div class="col-sm-4 col-lg">
        <label class="control-label">{{$t("salary_calculator.work.name")}}</label>
        <v-select
          v-model="work"
          class=""
          placeholder="-"
          autocomplete="off"
          @option:selected="onWorkSelected"
          taggable
          :options="work_list"></v-select>
      </div>
      <div class="form-group col-sm-4 col-lg-2">
        <label class="control-label">{{$t("common.date_from")}}</label>
        <date-picker2
          v-model="work_started_at"
          :value-type="work_started_at_from_input"
          format="YYYY-MM-DD"
          type="date"
          placeholder="yyyy-mm-dd"
          :input-class="['date-picker-style']"
          @change="onWorkStartedAtChanged"
          ref="date_from"
        ></date-picker2>
      </div>
      <div class="form-group col-sm-4 col-lg">
        <label class="control-label">{{$t("common.date_to")}}</label>
        <date-picker2
          v-model="work_ended_at"
          :value-type="work_ended_at_from_input"
          format="YYYY-MM-DD"
          type="date"
          placeholder="yyyy-mm-dd"
          :input-class="['date-picker-style']"
          @change="onWorkEndedAtChanged"
          ref="date_from"
        ></date-picker2>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-2 mt-4">
        <button class="btn-sm btn-primary col-lg-12" @click="applyFilters">{{$t("common.show")}}</button>
      </div>
      <div class="col-lg-3 mt-4">
        <button class="btn-sm btn-secondary col-lg-12" @click="resetFilters">{{$t("common.reset_filters")}}</button>
      </div>
      <div class="col-lg-5 mt-4">
        <button class="btn-sm btn-primary col-sm-6" @click="showExportDialog">
          <img src="~@/assets/images/desktop/icons/controls/excel.svg" class="icon-svg"/>
          <span class="pl-2">{{$t("common.export_to_excel")}}</span>
        </button>
      </div>
    </div>
    <s-c-work-log-export-dialog :show="showExportModal" @close="showExportModal = false"
                                :data="exportModalData"
                                @onOK="onExportPressed"
    ></s-c-work-log-export-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import * as type from '../../../../store/mutation-types'
import {
  buildAutocompleteOptions,
  buildDefaultAutocompleteList, confirmDialog,
  convertDropdown,
  successMessage
} from "@/helpers/common";
import DatePicker2 from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import {SC_WORK_LOG_EXPORT_EXCEL} from "@/store/api/endpoints";
import {today} from "@/helpers/dateHelper";
import SCWorkLogExportDialog from "@/pages/salary-calculator/sc-work-log/partials/SCWorkLogExportDialog";

export default {
  name: 'SCWorkLogFilters',
  props: ["limit"],
  data() {
    return {
      filters:  this.gridFilters ? this.gridFilters : {},
      work_started_at: null,
      work_ended_at: null,
      work_started_at_from_input: null,
      work_ended_at_from_input: null,
      employee: null,
      work_group: null,
      work: null,
      showExportModal: false,
      exportModalData: {},
    }
  },
  computed: {
    ...mapGetters({
      gridFilters: 'scWorkLog/scWorkLogFilters',
      employees: 'scEmployee/scEmployees',
      employeeRoles: 'scEmployeeRole/scEmployeeRoles',
      workGroups: 'scWorkGroup/scWorkGroups',
      works: 'scWorkPivot/scWorkPivots',
      domain: 'auth/domain',
      bonuses: 'scBonus/scBonuses',
      workLogs: 'scWorkLog/scWorkLogs',
    }),
    employee_list() {
      return buildAutocompleteOptions(this.employees, {
        uuid: 'uuid',
        name: 'name',
      }, {
        value: null,
        text: '-',
      });
    },
    employee_role_list() {
      return convertDropdown(this.employeeRoles);
    },
    work_group_list() {
      return buildDefaultAutocompleteList(this.workGroups);
    },
    work_list() {
      return buildDefaultAutocompleteList(this.works);
    },
    unassignedRows() {
      if(this.workLogs) {
        return this.workLogs.filter((item => {
            return (item && item.work_pivot && !item.work_pivot.work_group)
          }
        ))
      }
      return [];
    }
  },
  methods: {
    ...mapActions({
      getData: 'scWorkLog/getSCWorkLogs',
      getEmployees: 'scEmployee/getSCEmployees',
      getEmployeeRoles: 'scEmployeeRole/getSCEmployeeRoles',
      getWorkGroups: 'scWorkGroup/getSCWorkGroups',
      getWorks: 'scWorkPivot/getSCWorkPivots',
      downloadExcel: 'common/downloadExcel',
      getBonuses: 'scBonus/getSCBonuses'
    }),
    applyFiltersClick() {
      this.$set(this.filters, 'page', 1);
      this.$router.push({
          path: '/sc/work-log',
          params: {
            page: 1,
          }
        }
      );
      this.applyFilters();
    },
    applyFilters() {
      this.$emit('onFiltersApplied', this.filters);
      this.getData(this.filters);
    },
    resetFilters() {
      this.filters = {
        name: '',
        employee_uuid: null,
        employee_role_uuid: null,
        work_group_uuid: null,
        work_started_at: null,
        work_ended_at: null,
        page: 1,
        limit: this.limit,
      };

      this.employee = null;
      this.work_group = null;
      this.work = null;
      this.work_started_at = null;
      this.work_ended_at = null;
      this.work_started_at_from_input = null;
      this.work_ended_at_from_input = null;

      this.$store.commit("scWorkLog/"+type.SET_SC_WORK_LOG_FILTERS, this.filters);
      this.applyFilters();
    },
    onWorkStartedAtChanged(date) {
      this.filters.work_started_at = date;
    },
    onWorkEndedAtChanged(date) {
      this.filters.work_ended_at = date;
    },
    onEmployeeSelected(data) {
      this.filters.employee_uuid = data.value;
    },
    onWorkGroupSelected(data) {
      this.filters.work_group_uuid = data.value;
    },
    onWorkSelected(data) {
      this.filters.work_uuid = data.value;
    },
    exportToExcel() {
      this.downloadExcel({
        url: SC_WORK_LOG_EXPORT_EXCEL,
        filename: 'work-logs-'+this.domain.company + '-' + today(),
        params: this.filters,
      });
    },
    showExportDialog() {
      let vm = this;
      this.showUnassignedRowsWarningDialog()
        .then((resp) => {
          vm.getBonuses({ is_global: 0 }).then((resp) => {
            vm.exportModalData = resp.data;
            vm.showExportModal = true;
          });
        });
    },
    showUnassignedRowsWarningDialog() {
      let vm = this;
      return new Promise((resolve, reject) => {
        if(this.unassignedRows && this.unassignedRows.length > 0) {
          confirmDialog(
              vm.$t('salary_calculator.work_log.you_have_unassigned_works', { count: vm.unassignedRows.length })
              +'<br/>'+
              vm.$t('salary_calculator.work_log.errors.it_may_affects_the_results')
              ,
              vm.$t('warning'),
            {
                okText: vm.$t('common.continue'),
                cancelText: vm.$t('common.cancel'),
              }
          ).then((resp) => {
            resolve(resp);
          })
          .catch((e) => {
            reject(e);
          })
        } else {
          resolve();
        }
      });
    },
    onExportPressed(data) {
      this.showExportModal = false;
      this.exportToExcel();
    }
  },
  components: {
    DatePicker2,
    SCWorkLogExportDialog
  },
  mounted(){
    this.getEmployees();
    this.getWorks();
    this.getEmployeeRoles();
    this.getWorkGroups();
    this.resetFilters();
  },
  watch: {
    gridFilters: {
      handler: function(newVal) {
        this.filters = newVal;
      },
      deep: true,
    }
  }
}
</script>
