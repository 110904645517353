<template>
  <div class="view" v-if="canEdit || canDelete" v-cloak>
    <card :title="title" id="work-list">
      <agro-filter-container :new-object="newObject" :title='$t("common.create_new", { name: title })'>
        <s-c-employee-filters ref="filters" @onFiltersApplied="onFiltersApplied"/>
      </agro-filter-container>
    </card>
    <div class="content-body pt-4">
      <div class="row">
        <div class="col-lg-12">
          <agro-grid
            :headers="headers"
            :rows="items"
            :paginate="true"
            :permission="config"
            :action="onPageSelected"
            :loading="loading"
          >
          </agro-grid>
        </div>
      </div>
    </div>
    <s-c-employee-work-export-dialog :show="showExportModal" @close="showExportModal = false"
                                :data="exportModalData"
                                @onOK="onExportPressed"
    ></s-c-employee-work-export-dialog>
  </div>
  <div v-else>
    <forbidden></forbidden>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import * as type from '../../../store/mutation-types'
import AgroGrid from "@/components/AgroGrid/index";
import SCEmployeeFilters from "@/pages/salary-calculator/sc-employee/partials/SCEmployeeFilters";
import {canDelete, canEdit, permissions, permissionTypes} from "@/helpers/Permissions";
import SCEmployeeWorkExportDialog from "@/pages/salary-calculator/sc-employee/partials/SCEmployeeWorkExportDialog";
import {SC_WORK_LOG_EMPLOYEE_EXPORT_EXCEL, SC_WORK_LOG_EXPORT_EXCEL} from "@/store/api/endpoints";
import {today} from "@/helpers/dateHelper";
import {confirmDialog} from "@/helpers/common";
import Forbidden from "~/pages/errors/Forbidden.vue";
import AgroFilterContainer from "@/components/AgroFilter/partials/AgroFilterContainer.vue";

export default {
  name: 'SCEmployeeList',
  middleware: ['auth', 'check-company-admin'],
  data() {
    return {
      currentPage: 1,
      filters: this.scEmployeeFilters ? this.scEmployeeFilters : {},
      items: this.scEmployees ? this.scEmployees : [],
      pager: this.pagination ? this.pagination : {},
      loading: false,
      showExportModal: false,
      exportModalData: {},
    }
  },
  components: {
    AgroFilterContainer,
    Forbidden,
    SCEmployeeWorkExportDialog,
    SCEmployeeFilters,
    AgroGrid,
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
      scEmployeeFilters: 'scEmployee/scEmployeeFilters',
      scEmployees: 'scEmployee/scEmployees',
      pagination: 'common/pagination',
      domain: 'auth/domain',
      users: 'user/users',
    }),
    title() {
        return this.$t('salary_calculator.employee.title');
    },
    config() {
      return {
        permission: permissions.SC_EMPLOYEE,
        permission_type: permissionTypes.module,
        edit: {
          url: '/salary-calculator/sc-employee/{uuid}',
          callback: this.onItemEdited,
        },
        delete: {
          method: this.deleteObject,
          callback: this.onItemDeleted,
        },
        export: {
          method: this.showExportDialog,
          callback: this.onExportPressed
        }
      };
    },
    canEdit() {
      return canEdit(this);
    },
    canDelete() {
      return canDelete(this);
    },
    headers() {
      return  [
        { name: this.$t('common.name'), col: 'name', width: '20%' },
        { name: this.$t('salary_calculator.employee_role.name'), col: 'employee_role.name', width: '15%' },
        { name: this.$t('common.user'), col: 'user.name', width: '11.25%' },
        { name: this.$t('salary_calculator.employee_role.salary'), col: 'salary', suffix: this.$currency.name, width: '11.25%' },
        { name: this.$t('common.created_at'), col: 'created_at', width: '13%' },
        { name: this.$t('common.last_update'), col: 'updated_at', width: '15%' },
      ];
    },
  },
  methods: {
    ...mapActions({
      deleteObject: 'scEmployee/deleteSCEmployee',
      downloadExcel: 'common/downloadExcel',
      getSCWorkLogs: 'scWorkLog/getSCWorkLogs'
    }),
    onPageSelected(page_id) {
      this.filters.page = page_id;
      this.$store.commit("scEmployee/"+type.SET_SC_EMPLOYEE_FILTERS, this.filters);
      this.$refs.filters.applyFilters();
    },
    newObject() {
      this.$router.push({
        path: 'sc-employee/add'
      });
    },
    onItemEdited(item) {
      console.log('onItemEdited', item);
    },
    onItemDeleted(item) {
      console.log('onItemDeleted', item);
    },
    onFiltersApplied(filters) {
      this.loading = true;
    },
    showExportDialog(data) {
      let vm = this;
      vm.exportModalData = {
        employee_uuid: data.uuid
      };
      vm.showExportModal = true;
    },
    showUnassignedRowsWarningDialog(unassignedRowsCount) {
      let vm = this;
      return new Promise((resolve, reject) => {
        if(unassignedRowsCount > 0) {
          confirmDialog(
            vm.$t('salary_calculator.work_log.you_have_unassigned_works', { count: unassignedRowsCount })
            +'<br/>'+
            vm.$t('salary_calculator.work_log.errors.it_may_affects_the_results')
            ,
            vm.$t('warning'),
            {
              okText: vm.$t('common.continue'),
              cancelText: vm.$t('common.cancel'),
            }
          ).then((resp) => {
            resolve(resp);
          })
            .catch((e) => {
              reject(e);
            })
        }
      });
    },
    beforeExport(filters) {
      let vm = this;
      return new Promise((resolve, reject) => {
        this.getSCWorkLogs(filters).then((resp) => {
          if(resp.data) {
            let unassignedWorks = Object.assign([], resp.data).filter((item) => {
              return item.has_unassigned_works;
            });

            if(unassignedWorks && unassignedWorks.length > 0) {
              vm.showUnassignedRowsWarningDialog(unassignedWorks.length)
              .then((r) => {
                resolve(r);
              }).catch((e) => {
                reject(e);
              });
            } else {
              resolve();
            }
          }
        });
      });
    },
    onExportPressed(data) {
      this.showExportModal = false;
      this.exportToExcel(data);
    },
    exportToExcel(filters) {
      this.beforeExport(filters).then((resp) => {
        this.downloadExcel({
          url: SC_WORK_LOG_EMPLOYEE_EXPORT_EXCEL,
          filename: 'employee-works-'+this.domain.company + '-' + today(),
          params: filters,
        });
      });
    },
  },
  watch: {
    scEmployees: {
      handler: function(newVal) {
        this.items = newVal;
      },
      deep: true,
    },
    pagination: {
      handler: function(newVal) {
        this.pager = newVal;
      },
      deep: true,
    },
  }
}
</script>

<style scoped>

</style>
