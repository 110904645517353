<template>
  <div class="view" v-if="canEdit || canDelete" v-cloak>
    <card :title="$t('salary_calculator.bonus.title')" id="bonus-list">
      <agro-filter-container :new-object="newObject">
        <s-c-bonus-filters ref="filters" @onFiltersApplied="onFiltersApplied"/>
      </agro-filter-container>
    </card>
    <div class="content-body pt-4">
      <div class="row">
        <div class="col-lg-12">
          <agro-grid
            :headers="headers"
            :rows="items"
            :paginate="true"
            :permission="config"
            :action="onPageSelected"
            :loading="loading"
            @onSortBy="onSortBy"
          >
          </agro-grid>
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <forbidden></forbidden>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import * as type from '../../../store/mutation-types'
import SCBonusFilters from "@/pages/salary-calculator/sc-bonus/partials/SCBonusFilters";
import AgroGrid from "@/components/AgroGrid/index";
import {canDelete, canEdit, permissions, permissionTypes} from "@/helpers/Permissions";
import Forbidden from "~/pages/errors/Forbidden.vue";
import AgroFilterContainer from "@/components/AgroFilter/partials/AgroFilterContainer.vue";

export default {
  name: 'SCBonusList',
  middleware: ['auth', 'check-company-admin'],
  data() {
    return {
      currentPage: 1,
      filters: this.scBonusFilters ? this.scBonusFilters : {},
      items: this.scBonuses ? this.scBonuses : [],
      pager: this.pagination ? this.pagination : {},
      loading: false,
    }
  },
  components: {
    AgroFilterContainer,
    Forbidden,
    AgroGrid,
    SCBonusFilters,
  },
  computed: {
    ...mapGetters({
        user: 'auth/user',
        scBonusFilters: 'scBonus/scBonusFilters',
        scBonuses: 'scBonus/scBonuses',
        pagination: 'common/pagination',
        users: 'user/users',
    }),
    config() {
        return {
            permission: permissions.SC_BONUS,
            permission_type: permissionTypes.module,
            edit: {
              url: '/salary-calculator/sc-bonus/{uuid}',
              callback: this.onItemEdited,
            },
            delete: {
                method: this.deleteObject,
                callback: this.onItemDeleted,
            }
        };
    },
    canEdit() {
      return canEdit(this);
    },
    canDelete() {
      return canDelete(this);
    },
    headers() {
        return  [
            { name: this.$t('common.name'), col: 'name', width: '16%', sortable: true, },
            { name: this.$t('common.type'), col: 'type_name', width: '15%', sortable: true },
            { name: this.$t('common.value'), col: 'value_text', width: '10%'},
            { name: this.$t('common.condition'), col: 'condition_values', align: 'center', width: '10%'},
            {
              name: this.$t('salary_calculator.bonus.is_global'),
              col: 'is_global_text',
              key: 'is_global',
              width: '8%',
              align: 'center',
              sortable: true
            },
            {
              name: this.$t('common.active'),
              col: 'is_active_text',
              key: 'is_active',
              width: '8%',
              align: 'center',
              sortable: true
            },
            { name: this.$t('common.created_at'), col: 'created_at', width: '12%', sortable: true },
            { name: this.$t('common.last_update'), col: 'updated_at', width: '12%', sortable: true },
        ];
    },
  },
  methods: {
    ...mapActions({
      deleteObject: 'scBonus/deleteSCBonus',
    }),
    onPageSelected(page_id) {
      this.filters.page = page_id;
      this.$store.commit("scBonus/"+type.SET_SC_BONUS_FILTERS, this.filters);
      this.$refs.filters.applyFilters();
    },
    newObject() {
      this.$router.push({
        path: 'sc-bonus/add'
      });
    },
    onItemEdited(item) {
        console.log('onItemEdited', item);
    },
    onItemDeleted(item) {
        console.log('onItemDeleted', item);
    },
    onFiltersApplied(filters) {
        this.loading = true;
    },
    onSortBy(data) {
      this.scBonusFilters.sortBy = data.sortBy;
      this.scBonusFilters.sortDirection = data.sortDirection;
      this.onPageSelected(this.currentPage);
    }
  },
  watch: {
    scBonuses: {
      handler: function(newVal) {
        this.items = newVal;
      },
      deep: true,
    },
    pagination: {
      handler: function(newVal) {
        this.pager = newVal;
      },
      deep: true,
    },
  }
}
</script>

<style scoped>

</style>
