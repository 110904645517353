<template>
  <DxDataGrid
      ref="bbchgrid"
      :data-source="dataSource"
      :show-borders="true"
      :remote-operations="gridRemoteOperations"
  >
    <DxColumn
        dataField="code"
        :caption="$t('works.cultures.bbch.code')"
        :filter-operations="['contains']"
    >
      <DxRequiredRule :message="$t('validation.code_is_required')"/>
    </DxColumn>
    <DxColumn
        dataField="name"
        :caption="$t('works.cultures.bbch.name')"
        :filter-operations="['contains']"
    >
      <DxRequiredRule :message="$t('validation.name_is_required')"/>
    </DxColumn>
    <DxColumn
        dataField="rank"
        :caption="$t('rank')"
        dataType="number"
        alignment="center"
        :allowSorting="true"
        :allowFiltering="false"
    >
      <DxRequiredRule :message="$t('validation.rank_is_required')"/>
      <DxNumberBox
          dataField="rank"
          :show-clear-button="false"
          format="#0"
      />
    </DxColumn>
    <DxColumn
        dataField="created_at"
        :caption="$t('common.created_at')"
        dataType="date"
        format="yyyy-MM-dd"
        alignment="center"
        :filter-operations="['=']"
        :formItem="{
            visible: false
        }"
    />

    <DxColumn
        dataField="updated_at"
        :caption="$t('common.updated_at')"
        dataType="date"
        format="yyyy-MM-dd"
        alignment="center"
        :filter-operations="['=']"
        :formItem="{
            visible: false
        }"
    />
    <DxFilterRow :visible="true"/>
    <DxEditing
        :allow-adding="true"
        :allow-updating="true"
        :allow-deleting="true"
        mode="form"
        :use-icons="true"
    >
      <DxTexts
          :confirm-delete-message="$t('works.cultures.messages.confirm_delete_bbch')"
          :confirm-delete-title="$t('confirm_delete_title')"
      />
    </DxEditing>
  </DxDataGrid>
</template>

<script>
import DxDataGrid, {
  DxColumn,
  DxEditing,
  DxFilterRow,
  DxRequiredRule,
  DxTexts
} from 'devextreme-vue/data-grid';
import {mapActions, mapGetters} from "vuex";
import CustomStore from "devextreme/data/custom_store";
import DataSource from 'devextreme/data/data_source';
import DxNumberBox from "devextreme-vue/number-box";

export default {
  props: {
    data: Object,
  },
  components: {
    DxFilterRow,
    DxNumberBox,
    DxRequiredRule,
    DxTexts,
    DxEditing,
    DxColumn,
    DxDataGrid,
  },
  data() {
    return {
      gridRemoteOperations: {
        filtering : true,
        sorting: true
      },
      cultureList: this.cultures ?? [],
    }
  },
  computed: {
    ...mapGetters({
        cultures: 'works-cultures/cultures',
    }),
    dataSource() {
      return new DataSource({
        store: new CustomStore({
          key: 'uuid',
          load: this.load,
          insert: this.insert,
          update: this.update,
          remove: this.remove
        })
      });
    },
  },
  methods: {
    ...mapActions({
      get: 'works-cultures/getBbchList',
      create: 'works-cultures/createBbch',
      change: 'works-cultures/updateBbch',
      delete: 'works-cultures/deleteBbch',
      getCultures: 'works-cultures/getCultures',
    }),
    async load(data) {
      return await this.get({
        culture_uuid: this.data.uuid,
        filter: data.filter,
        sort: data.sort,
      });
    },
    async insert(data) {
      await this.create(
          Object.assign({}, data, {
            culture_uuid: this.data.uuid,
          })
      )
    },
    async update(key, obj) {
      const updatable = Object.assign(
          {},
          this.dataSource.items().find(item => item.uuid === key),
          obj
      );
      await this.change(
          Object.assign({}, updatable, {
            culture_uuid: this.data.uuid,
          })
      )
    },
    remove(uuid) {
      this.delete({
        uuid: uuid,
      });
    },
  },
  mounted() {
    this.getCultures({
      limit: 9999,
      enablePagination: 0,
    });
  },
  watch: {
    cultures: {
      handler: function(newVal) {
        this.cultureList = newVal;
      },
      deep: true,
    }
  }
}
</script>
