import * as types from "../../mutation-types";

const mutations = {
    [types.SET_SM_PRODUCTS]: (state, payload) => {
        state.sm_products = payload;
    },
    [types.SET_SM_PRODUCT]: (state, payload) => {
        state.sm_product = payload;
    },
    [types.ADD_SM_PRODUCT_ITEM]: (state, payload) => {
        state.sm_products.push(payload);
    },
    [types.UPDATE_SM_PRODUCT_ITEM]: (state, payload) => {
        state.sm_products.splice(state.sm_products.indexOf(
            state.sm_products.find(c => c.uuid === payload.uuid)
        ), 1, payload);
    },
    [types.DELETE_SM_PRODUCT_ITEM]: (state, payload) => {
        state.sm_products.splice(state.sm_products.indexOf(
            state.sm_products.find(c => c.uuid === payload.uuid)
        ), 1);
    },
    [types.SET_SM_PRODUCT_FILTERS]: (state, payload) => {
        state.sm_product_filters = payload;
    },
}

export default mutations;
