<template>
  <div id="app">
    <noheader v-if="layout === 'noheader'"/>
    <Login v-else-if="layout === 'login'"/>
    <main-layout v-else/>
  </div>
</template>

<script>
import DynamicComponent from "@/components/DynamicComponent.vue";
import MainLayout from "@/layouts/default.vue";
import Noheader from "@/layouts/noheader.vue";
import Login from "@/layouts/login.vue";

export default {
  name: 'App',
  metaInfo: {
    title: 'Smagria',
    htmlAttrs: {
      lang: 'en-US'
    },
    meta: [
      { charset: 'utf-8' },
      { name: 'description', content: 'Inovație integrată pentru comunități mai puternice' },
      { name: 'viewport', content: 'width=device-width, initial-scale=1, user-scalable=no' }
    ]
  },
  data() {
    return {
      defaultLayout: 'default',
    }
  },
  components: {
    Noheader,
    Login,
    MainLayout,
    DynamicComponent,
  },
  computed: {
    layout() {
      return this.defaultLayout ?? 'default';
    }
  },
  methods: {
    handleLayout(layout) {
      this.$set(this, 'defaultLayout', layout);
    }
  },
  mounted() {
    this.handleLayout(this.$route.meta.layout);
  },
  watch: {
    '$route': {
      handler: function(newVal) {
        this.handleLayout(newVal.meta.layout);
      },
      deep: true,
      immediate: true,
    },
  }
}
</script>
