import * as types from "@/store/mutation-types";

export default async (route, from, next, params) => {
  const store = params.store;

  try {
    await autoLoginWithToken(route, from, next, params);
    await store.dispatch('auth/fetchUser');
  } catch (e) {
    console.log('unauthorized check-auth', e);
  }
}

async function autoLoginWithToken (route, from, next, params) {
  const router = params.router;
  const store = params.store;
  let token = route.query.authToken;

  //set token if not logged in
  if(!store.getters['auth/user'] && !store.getters['auth/token'] && token) {
      store.commit('auth/'+types.SAVE_TOKEN, { token: token });
      await store.dispatch('auth/fetchUser');
  }

  //redirect to base pat and remove authToken
  if(token) {
    //window.location.href = request.route.path;
    let showTheMap = false;
    if(route.query.showMapp !== undefined && route.query.showMapp == 'true'){
      showTheMap = true;
    }

    router.push({
      path: route.path,
      query: {showMapp: showTheMap}
    }).catch((e) => {
      console.log('redirect checkout auth ex', e.message);
    });

  }
}
