<template>
  <div class="daily-grid" id="daily-grid">
    <daily-grid-table :data="data"
                      :headers="headers"
                      :showFilters="showFilters"
                      :infiniteScroll="infiniteScroll"
                      @onPaginate="onPaginate"
                      :loading="loading"></daily-grid-table>
  </div>
</template>

<script>
import DailyGridTable from "~/pages/salary-calculator/sc-work-reports/partials/DailyGrid/DailyGridTable.vue";

export default {
  name: 'DailyGrid',
  components: {DailyGridTable},
  props: [
    'data',
    'headers',
    'config',
    'showFilters',
    'loading',
    'infiniteScroll',
  ],
  data() {
    return {
      items: this.data ?? [],
    }
  },
  methods: {
    onPaginate(data) {
      this.$emit('onPaginate', data);
    },
  },
  watch: {
    data: {
      handler: function(newVal) {
        this.items = newVal;
      },
      deep: true,
    },
  }
}
</script>

<style lang="scss" scoped>
  .daily-grid {
    width: 100%;
    min-height: 100px;
    overflow-x: auto;
  }
</style>
