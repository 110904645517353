<template>
    <transition name="modal">
        <div>
            <DxPopup
            height="calc(100vh - 140px)"
            width="calc(100vw - 48px)"
            :close-on-outside-click="false"
            :show-close-button="true"
            :show-title="true"
            :visible="showPopup"
            :wrapper-attr="dialogAttribute"
            @hidden="$emit('hidden')"
            :title="popupTitle">
            <template #content>
                <div>
                    <WebDocumentViewer :reportId="templateData.id" />
                </div>
            </template>

            </DxPopup>
        </div>
    </transition>
</template>

<script>

import DxPopup from "devextreme-vue/popup";
import WebDocumentViewer from "@/components/reporting/WebDocumentViewer.vue";
import { mapActions, mapGetters } from "vuex";

export default {
    name: "DocumentViewerDialog",
    props: {
        showPopup: {
            type: Boolean,
            default: false,
        },
        templateData: {
            type: Object,
            default: {},
        }
    },
    data(){
        return {
            dialogAttribute:{
                class: "report-designer-dialog"
            }
        };
    },
    computed: {
        ...mapGetters({
            company: "auth/domain",
            current_user: "auth/user",
            current_token: "auth/token",
        }),
        popupTitle: function(){
            return "Raport " + this.templateData.name;
        }
    },
    components: {
        DxPopup,
        WebDocumentViewer
    }
}

</script>

<style lang="scss">
    @import "@/assets/scss/reporting/reportDesignerDialog.scss";
</style>