import * as types from "../../mutation-types";

const mutations = {
  [types.SET_SC_EMPLOYEE_ROLES]: (state, payload) => {
    state.sc_employee_roles = payload;
  },
  [types.SET_SC_EMPLOYEE_ROLE]: (state, payload) => {
    state.sc_employee_role = payload;
  },
  [types.ADD_SC_EMPLOYEE_ROLE_ITEM]: (state, payload) => {
    state.sc_employee_roles.push(payload);
  },
  [types.UPDATE_SC_EMPLOYEE_ROLE_ITEM]: (state, payload) => {
    state.sc_employee_roles.splice(state.sc_employee_roles.indexOf(
      state.sc_employee_roles.find(c => c.uuid === payload.uuid)
    ), 1, payload);
  },
  [types.DELETE_SC_EMPLOYEE_ROLE_ITEM]: (state, payload) => {
    state.sc_employee_roles.splice(state.sc_employee_roles.indexOf(
      state.sc_employee_roles.find(c => c.uuid === payload.uuid)
    ), 1);
  },
  [types.SET_SC_EMPLOYEE_ROLE_FILTERS]: (state, payload) => {
    state.sc_employee_role_filters = payload;
  },
}

export default mutations;
