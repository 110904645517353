<template>
  <transition name="modal">
    <DxPopup
      class="vertical-center"
      ref="filterPopup"
      height="100vh"
      width="100vw"
      :show-title="true"
      title="Filtre"
      :drag-enabled="false"
      :close-on-outside-click="true"
      :show-close-button="true"
      :visible="showPopup"
      @hidden="$emit('hidden')"
    >
      <template #title>
        <div class="filter_title_template">
          <div class="left_side">
            <img
              src="@/assets/images/icons/arrow-back.svg"
              v-if="currentStep > 0"
              @click="goBackOneStep"
            />
            <span v-if="currentStep > 0" @click="goBackOneStep">Înapoi</span>
          </div>
          <div class="right_side">
            <img
              src="@/assets/images/icons/dialog-close.svg"
              @click="buttonCloseEvent"
            />
          </div>
        </div>
      </template>
      <template #content>
        <div class="filters_tabs">
          <DxTabPanel
            :data-source="filterSteps"
            :selected-index="currentStep"
            :loop="false"
            :show-nav-buttons="false"
            :swipe-enabled="false"
            @selection-changed="filterSelectionChanged($event)"
          >
            <template #title="{ data: step }">
              <div>{{ step }} sdf</div>
            </template>
            <template #item="{ data: step }">
              <div>
                <div
                  v-if="step == 1"
                  class="filter_container"
                  :class="showApplyButton ? 'filter_container_active' : ''"
                  :use-native="true"
                >
                <div class="filter_header">Filtre disponibile <a v-if="showApplyButton" href="#" class="filter_header_reset" @click="resetFilters" >Resetează filtre</a></div>
                <DxScrollView :use-native="true" class="filter_container_scroll">

                   <filter-header
                    title="Departamente"
                    :filterOptions="departments"
                    :selectedOptions="filters.departments"
                    filterTextTemplate1="un departament selectat"
                    filterTextTemplate2=" departamente selectate"
                    swipeOption="departments"
                    @swipeFilter="swipeFilter"  />

                  <div class="main_filter_row" @click="swipeFilter('date')">
                    <div class="first_row">
                      <div class="main_filter_title">Data</div>
                      <img
                        class="main_filter_right_arrow"
                        src="@/assets/images/icons/right_arrow_dg.svg"
                      />
                    </div>
                    <div

                      class="main_filter_selected"
                    >
                      {{ filters.date_from }} {{filters.date_from != '' && filters.date_to != '' && filters.date_from != filters.date_to ? '-' : ''}} {{ filters.date_from != filters.date_to ? filters.date_to : ''}}
                    </div>
                  </div>

                  <filter-header
                    title="Lucrări"
                    :filterOptions="works"
                    :selectedOptions="filters.works"
                    filterTextTemplate1="o lucrare selectată"
                    filterTextTemplate2=" lucrări selectate"
                    swipeOption="works"
                    @swipeFilter="swipeFilter"  />

                  <filter-header
                    title="Tractoare"
                    :filterOptions="workMachines"
                    :selectedOptions="filters.machines"
                    filterTextTemplate1="un tractor selectat"
                    filterTextTemplate2=" tractoare selectate"
                    swipeOption="machines"
                    @swipeFilter="swipeFilter"  />

                  <filter-header
                    title="Parcele"
                    :filterOptions="parcels"
                    :selectedOptions="filters.parcels"
                    filterTextTemplate1="o parcelă selectată"
                    filterTextTemplate2=" parcele selectate"
                    swipeOption="parcels"
                    @swipeFilter="swipeFilter"  />

                   <filter-header
                    title="Articole"
                    :filterOptions="products"
                    :selectedOptions="filters.products"
                    filterTextTemplate1="un articol selectat"
                    filterTextTemplate2=" articole selectate"
                    swipeOption="products"
                    @swipeFilter="swipeFilter"  />

                    <filter-header
                    title="An agricol"
                    :filterOptions="seasons"
                    :selectedOptions="filters.seasons"
                    filterTextTemplate1="un sezon selectat"
                    filterTextTemplate2=" sezoane selectate"
                    swipeOption="seasons"
                    @swipeFilter="swipeFilter"  />
                    </DxScrollView>
                </div>

                <div v-if="step == 2" class="filter_container">
                  <div class="filter_header">{{ currentFilterTitle }}</div>

                  <!-- Departments -->
                  <div v-if="current_filter == 'departments'">
                    <b-input-group class="search_filters" v-show="false">
                      <b-input-group-prepend>
                        <img src="@/assets/images/icons/search.svg" />
                      </b-input-group-prepend>
                      <b-form-input
                        placeholder="Caută departamente"
                        v-model="instantFilters.departaments"
                      ></b-form-input>
                    </b-input-group>
                    <b-form-checkbox v-show="false"
                      class="base_row select_all_link"
                      @change="selectAllDepartments($event)"
                      v-model="selectAll.departaments"
                    >
                      Selectează tot
                    </b-form-checkbox>
                    <div class="filter_separator"></div>
                    <DxScrollView class="row_container" :use-native="true">
                      <div>
                        <DxTreeView
                          ref="departmentTreeControl"
                          :items="departmentHierarchiList"
                          show-check-boxes-mode="selectAll"
                          selection-mode="all"
                          :select-nodes-recursive="true"
                          :select-by-click="true"
                          :select-all="true"
                          @selection-changed="changeDepartmentTreeFilters"
                          :element-attr="treeViewAttributes"
                          :search-enabled="true"
                          search-mode="contains"
                          selectAllText="Selectează tot"
                          :search-editor-options="departmentSearchAttributes"
                          display-expr="name"
                        >
                          <template #item="item">
                            {{ item.data.name }}
                          </template>
                        </DxTreeView>

                        <b-form-checkbox v-show="false"
                          class="checkbox_row"
                          v-for="(departament, index) in (departments ? departments : []).filter((obj) =>
                            obj.name
                              .toUpperCase()
                              .includes(instantFilters.departaments.toUpperCase())
                          )"
                          :key="index"
                          v-model="filters.departments"
                          :value="departament.uuid"
                          @change="changeDepartmentFilters"
                        >
                          {{ departament.name }}
                        </b-form-checkbox>
                        <div v-if="showApplyButton" class="row_container_spacer"></div>
                      </div>
                    </DxScrollView>
                  </div>

                  <!-- Date -->
                  <div v-if="current_filter == 'date'">
                    <div class="row_container">
                      <b-form-radio
                        name="prdefined-dates"
                        value="today"
                        class="radio_row"
                        v-model="selected_range"
                        >Astăzi</b-form-radio
                      >
                      <b-form-radio
                        name="prdefined-dates"
                        value="yesterday"
                        class="radio_row"
                        v-model="selected_range"
                        >Ieri</b-form-radio
                      >
                      <b-form-radio
                        name="prdefined-dates"
                        value="last_7_days"
                        class="radio_row"
                        v-model="selected_range"
                        >Ultimele 7 zile</b-form-radio
                      >
                      <b-form-radio
                        name="prdefined-dates"
                        value="last_30_days"
                        class="radio_row"
                        v-model="selected_range"
                        >Ultimele 30 zile</b-form-radio
                      >
                      <b-form-radio
                        name="prdefined-dates"
                        value="last_year"
                        class="radio_row"
                        v-model="selected_range"
                        >Anul trecut</b-form-radio
                      >
                      <b-button @click="goToRangeSelector" class="row_button"
                        >Interval personalizat</b-button
                      >
                       <b-button @click="resetDateSelection" class="row_button" style="margin-top:12px"
                        >Resetează selecția</b-button
                      >
                    </div>
                  </div>

                  <!-- Works -->
                  <div v-if="current_filter == 'works'">
                    <b-input-group class="search_filters">
                      <b-input-group-prepend>
                        <img src="@/assets/images/icons/search.svg" />
                      </b-input-group-prepend>
                      <b-form-input
                        placeholder="Caută lucrări"
                        v-model="instantFilters.works"
                      ></b-form-input>
                    </b-input-group>
                    <b-form-checkbox
                      class="base_row select_all_link"
                      @change="selectAllWorks($event)"
                      v-model="selectAll.works"
                    >
                      Selectează tot
                    </b-form-checkbox>
                    <div class="filter_separator"></div>
                    <DxScrollView class="row_container" :use-native="true">
                      <div>
                        <b-form-checkbox
                          class="checkbox_row"
                          v-for="(work, index) in (works
                            ? works
                            : []
                          ).filter((obj) =>
                            obj.name
                              .toUpperCase()
                              .includes(instantFilters.works.toUpperCase())
                          )"
                          :key="index"
                          v-model="filters.works"
                          :value="work.uuid"
                          @change="changeWorksFilters"
                        >
                          {{ work.name }}
                        </b-form-checkbox>
                        <div v-if="showApplyButton" class="row_container_spacer"></div>
                      </div>
                    </DxScrollView>
                  </div>

                  <!-- TRACTOARE -->
                  <div v-if="current_filter == 'machines'">
                    <b-input-group class="search_filters">
                      <b-input-group-prepend>
                        <img src="@/assets/images/icons/search.svg" />
                      </b-input-group-prepend>
                      <b-form-input
                        placeholder="Caută tractoare"
                        v-model="instantFilters.machines"
                        @input="testChangeMachineFilter($event)"
                      ></b-form-input>
                    </b-input-group>
                    <b-form-checkbox
                      class="base_row select_all_link"
                      @change="selectAllMachines($event)"
                      v-model="selectAll.machines"
                    >
                      Selectează tot
                    </b-form-checkbox>
                    <div class="filter_separator"></div>

                    <DxScrollView
                      class="row_container"
                      :use-native="true"
                    >
                      <div>
                        <b-form-checkbox
                          class="checkbox_row"
                          v-for="(machine, index) in (workMachines
                            ? workMachines
                            : []
                          ).filter((obj) =>
                            obj.registration_number
                              .toUpperCase()
                              .includes(instantFilters.machines.toUpperCase())
                          )"
                          :key="index"
                          v-model="filters.machines"
                          :value="machine.uuid"
                          @change="changeMachinesFilter"
                        >
                          {{ machine.registration_number }}
                        </b-form-checkbox>
                        <div v-if="showApplyButton" class="row_container_spacer"></div>
                      </div>
                    </DxScrollView>
                  </div>

                  <!-- PARCELS -->
                  <div v-if="current_filter == 'parcels'">
                    <b-input-group class="search_filters">
                      <b-input-group-prepend>
                        <img src="@/assets/images/icons/search.svg" />
                      </b-input-group-prepend>
                      <b-form-input
                        placeholder="Caută parcele"
                        v-model="instantFilters.parcels"
                      ></b-form-input>
                    </b-input-group>
                    <b-form-checkbox
                      class="base_row select_all_link"
                      @change="selectAllParcels($event)"
                      v-model="selectAll.parcels"
                    >
                      Selectează tot
                    </b-form-checkbox>
                    <div class="filter_separator"></div>
                    <DxScrollView class="row_container" :use-native="true">
                      <div>
                        <b-form-checkbox
                          class="checkbox_row"
                          v-for="(parcel, index) in (parcels
                            ? parcels
                            : []
                          ).filter((obj) =>
                            obj.name
                              .toUpperCase()
                              .includes(instantFilters.parcels.toUpperCase())
                          )"
                          :key="index"
                          :id="parcel.uuid"
                          v-model="filters.parcels"
                          :value="parcel.uuid"
                          @change="changeParcelsFilters"
                        >
                          {{ parcel.name }}
                        </b-form-checkbox>
                        <div v-if="showApplyButton" class="row_container_spacer"></div>
                      </div>
                    </DxScrollView>
                  </div>

                  <!-- PRODUCTS -->
                  <div v-if="current_filter == 'products'">
                    <b-input-group class="search_filters">
                      <b-input-group-prepend>
                        <img src="@/assets/images/icons/search.svg" />
                      </b-input-group-prepend>
                      <b-form-input
                        placeholder="Caută produse"
                        v-model="instantFilters.products"
                      ></b-form-input>
                    </b-input-group>
                    <b-form-checkbox
                      class="base_row select_all_link"
                      @change="selectAllProducts($event)"
                      v-model="selectAll.products"
                    >
                      Selectează tot
                    </b-form-checkbox>
                    <div class="filter_separator"></div>
                    <DxScrollView class="row_container" :use-native="true">
                      <div>
                        <b-form-checkbox
                          class="checkbox_row"
                          v-for="(product, index) in (products
                            ? products
                            : []
                          ).filter((obj) =>
                            obj.product_name
                              .toUpperCase()
                              .includes(instantFilters.products.toUpperCase())
                          )"
                          :key="index"
                          :id="product.product_uuid"
                          v-model="filters.products"
                          :value="product.product_uuid"
                          @change="changeProductsFilter"
                        >
                          {{ product.product_name }}
                        </b-form-checkbox>
                        <div v-if="showApplyButton" class="row_container_spacer"></div>
                      </div>
                    </DxScrollView>
                  </div>

                  <!-- SEASONS -->
                  <div v-if="current_filter == 'seasons'">
                    <b-input-group class="search_filters">
                      <b-input-group-prepend>
                        <img src="@/assets/images/icons/search.svg" />
                      </b-input-group-prepend>
                      <b-form-input
                        placeholder="Caută sezoane"
                        v-model="instantFilters.seasons"
                      ></b-form-input>
                    </b-input-group>
                    <b-form-checkbox
                      class="base_row select_all_link"
                      @change="selectAllSeasons($event)"
                      v-model="selectAll.seasons"
                    >
                      Selectează tot
                    </b-form-checkbox>
                    <div class="filter_separator"></div>
                    <DxScrollView class="row_container" :use-native="true">
                      <div>
                        <b-form-checkbox
                          class="checkbox_row"
                          v-for="(season, index) in (seasons
                            ? seasons
                            : []
                          ).filter((obj) =>
                            obj.name
                              .toUpperCase()
                              .includes(instantFilters.seasons.toUpperCase())
                          )"
                          :key="index"
                          :id="season.uuid"
                          v-model="filters.seasons"
                          :value="season.uuid"
                          @change="changeSeasonsFilter"
                        >
                          {{ season.name }}
                        </b-form-checkbox>
                        <div v-if="showApplyButton" class="row_container_spacer"></div>
                      </div>
                    </DxScrollView>
                  </div>

                  <!-- SEASONS -->
                  <div v-if="current_filter == 'seasons'">
                    <b-input-group class="search_filters">
                      <b-input-group-prepend>
                        <img src="@/assets/images/icons/search.svg" />
                      </b-input-group-prepend>
                      <b-form-input
                        placeholder="Caută sezoane"
                        v-model="instantFilters.seasons"
                      ></b-form-input>
                    </b-input-group>
                    <b-form-checkbox
                      class="base_row select_all_link"
                      @change="selectAllSeasons($event)"
                      v-model="selectAll.seasons"
                    >
                      Selectează tot
                    </b-form-checkbox>
                    <div class="filter_separator"></div>
                    <DxScrollView class="row_container" :use-native="true">
                      <div>
                        <b-form-checkbox
                          class="checkbox_row"
                          v-for="(season, index) in (seasons
                            ? seasons
                            : []
                          ).filter((obj) =>
                            obj.name
                              .toUpperCase()
                              .includes(instantFilters.seasons.toUpperCase())
                          )"
                          :key="index"
                          :id="season.uuid"
                          v-model="filters.seasons"
                          :value="season.uuid"
                          @change="changeSeasonsFilter"
                        >
                          {{ season.name }}
                        </b-form-checkbox>
                      </div>
                    </DxScrollView>
                  </div>

                </div>
                <div v-if="step == 3" class="filter_container">
                  <div class="filter_header">{{ currentFilterTitle }}</div>
                  <div v-if="current_filter == 'date'">
                    <functional-calendar
                      :date-format="'yyyy-mm-dd'"
                      :is-date-range="true"
                      v-model="calendar"
                      :change-month-function="true"
                      :change-year-function="true"
                      @choseDay="calendarDayChosen"
                    ></functional-calendar>
                  </div>
                </div>
                <div
                  class="apply_filter_sticky_dialog"
                  v-show="showApplyButton"
                >
                  <b-button
                    class="continue_button"
                    variant="outline-secondary"
                    size="sm"
                    pill
                    @click="applySubfilter"
                    >Aplică</b-button
                  >
                </div>
              </div>
            </template>
          </DxTabPanel>
          <toast-message ref="toastMessage"></toast-message>
        </div>
      </template>
    </DxPopup>
  </transition>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import DxPopup from "devextreme-vue/popup";
import DxTabPanel from "devextreme-vue/tab-panel";
import { FunctionalCalendar } from "vue-functional-calendar";
import moment from "moment";
import DxList from "devextreme-vue/list";
import { DxCheckBox } from "devextreme-vue/check-box";
import { DxScrollView } from "devextreme-vue/scroll-view";
import  FilterHeader  from "./partials/filterHeader.vue"
import  FilterDetails  from "./partials/filterDetails.vue"
import {buildDefaultDropdownList} from "~/helpers/common";
import DxTreeView from 'devextreme-vue/tree-view';
import ToastMessage from "@/components/Mobile/toastMessage.vue"

export default {
  name: "FiltersDialog",
  props: {
    showPopup: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      filterSteps: [1, 2, 3],
      currentStep: 0,
      current_filter: "",
      selected_range: "",
      works: [],
      workMachines:[],
      parcels:[],
      products:[],
      seasons:[],
      filters: {
        departments: [],
        date_from: "",
        date_to: "",
        works: [],
        machines: [],
        parcels: [],
        products: [],
        seasons:[]
      },
      filters_on_open: {},
      calendar: {
        dateRange: {
          start: "",
          end: "",
        },
      },
      activeFilters: {
        hasDepartamentFilters: false,
        hasDateFilters: false,
        hasWorkFilters: false,
        hasMachineFilters: false,
        hasParcelFilters: false,
        hasProductFilter: false,
        hasSeasonFilter: false,
      },
      instantFilters: {
        departaments: "",
        works: "",
        machines: "",
        parcels: "",
        products: "",
        seasons: "",
      },
      selectAll: {
        departments: false,
        works: false,
        machines: false,
        parcels: false,
        products: false,
        seasons: false
      },
      departmentHierarchiList: [],
      treeViewAttributes: {
          class: 'department-tree'
      },
      departmentSearchAttributes:{
        placeholder: "Caută departamente"
      }
    };
  },
  computed: {
    ...mapGetters({
      company: "auth/domain",
      currentDepartment: "auth/currentDepartment",
      departments: "company/departments",
      departmentsList: "department/departmentList",
    }),
    currentFilterTitle() {
      switch (this.current_filter) {
        case "departments":
          return "Departamente";
          break;
        case "date":
          return "Data";
          break;
        case "works":
          return "Lucrări";
          break;
        case "machines":
          return "Tractoare";
          break;
        case "parcels":
          return "Parcele";
          break;
        case "products":
          return "Articole";
          break;
        case "seasons":
          return "An agricol";
          break;
        default:
          return "Filtre";
      }
    },
    showApplyButton() {
      if (this.currentStep == 0) {
        return (
          this.activeFilters.hasDepartamentFilters ||
          this.activeFilters.hasDateFilters ||
          this.activeFilters.hasWorkFilters ||
          this.activeFilters.hasMachineFilters ||
          this.activeFilters.hasParcelFilters ||
          this.activeFilters.hasProductFilter ||
          this.activeFilters.hasSeasonFilter
        );
      }
      if (this.currentStep == 1) {
        switch (this.current_filter) {
          case "departments":
            return this.activeFilters.hasDepartamentFilters;
            break;
          case "date":
            return this.activeFilters.hasDateFilters;
            break;
          case "works":
            return this.activeFilters.hasWorkFilters;
            break;
          case "machines":
            return this.activeFilters.hasMachineFilters;
            break;
          case "parcels":
            return this.activeFilters.hasParcelFilters;
            break;
          case "products":
            return this.activeFilters.hasProductFilter;
            break;
          case "seasons":
            return this.activeFilters.hasSeasonFilter;
            break;
          default:
            return false;
        }
      }
      if (this.currentStep == 2) {
        switch (this.current_filter) {
          case "date":
            return this.activeFilters.hasDateFilters;
            break;
          default:
            return false;
        }
      }
      return false;
    },

  },
  watch: {
    "instantFilters.works"(newVal) {},
    showPopup(newVal) {
      if (newVal == true) {
        console.log('company ', this.company);
        this.getDepartments({company_uuid:this.company.uuid});
        this.listDepartments({company_uuid:this.company.uuid});
        this.filters_on_open = JSON.parse(JSON.stringify(this.filters));
        this.currentStep = 0;
        this.instantFilters.works = "";
        this.instantFilters.machines = "";
        this.instantFilters.parcels = "";
        this.instantFilters.products = "";
        this.instantFilters.seasons = "";
        this.getAvailableWorks();
        this.getAvailableMachines();
        this.getAvailableParcels();
        this.getAvailableProducts();
        this.getAvailableSeasons();
      }
    },
    currentStep(newVal) {
      if (newVal > 0) {
        switch (this.current_filter) {
          case "departments":
            this.changeDepartmentFilters();
            break;
          case "date":
            //TODO implement
            //this.showApplyButton = false;
            break;
          case "works":
            this.changeWorksFilters();
            break;
          case "machines":
            this.changeMachinesFilter();
            break;
          case "parcels":
            this.changeParcelsFilters();
            break;
          case "products":
            this.changeProductsFilter();
            break;
          case "seasons":
            this.changeSeasonsFilter();
            break;
          default:
          //TODO implement
          //this.showApplyButton = false;
        }
      }
    },
    selected_range(newVal) {
      if(newVal && newVal.length > 0){
        let now = new Date();
        if (newVal == "today") {
          this.filters.date_from = now;
          this.filters.date_to = now;
        }
        if (newVal == "yesterday") {
          var date = new Date(now.valueOf());
          this.filters.date_to = this.filters.date_from = new Date(date.setDate(date.getDate() - 1));
        }
        if (newVal == "last_7_days") {
          var date = new Date(now.valueOf());
          this.filters.date_from = new Date(date.setDate(date.getDate() - 7));
          this.filters.date_to = now;
        }
        if (newVal == "last_30_days") {
          var date = new Date(now.valueOf());
          this.filters.date_from = new Date(date.setDate(date.getDate() - 30));
          this.filters.date_to = now;
        }
        if(newVal == "last_year"){
          var lastYear = now.getFullYear() - 1;
          this.filters.date_from = new Date(lastYear, 0, 1);
          this.filters.date_to = new Date(lastYear, 11, 31);
        }
        this.activeFilters.hasDateFilters =
          this.filters.date_from != this.filters_on_open.date_from ||
          this.filters.date_to != this.filters_on_open.date_to;

        if(newVal == "reset"){
          this.calendar.dateRange.start = '';
          this.calendar.dateRange.end = '';
        }
        else{
          this.calendar.dateRange.start = moment(this.filters.date_from).format("YYYY-M-D");
          this.calendar.dateRange.end = moment(this.filters.date_to).format("YYYY-M-D");
        }
      }
    },
    calendar: {
      handler(newVal) {
        if (newVal) {
          this.filters.date_from = newVal.dateRange.start;
          this.filters.date_to = newVal.dateRange.end == "" ? newVal.dateRange.start : newVal.dateRange.end;

          this.activeFilters.hasDateFilters =
            this.filters.date_from !=
              this.filters_on_open.date_from ||
            this.filters.date_to != this.filters_on_open.date_to;

          this.getAvailableWorks();
          this.getAvailableMachines();
          this.getAvailableParcels();
          this.getAvailableProducts();
          this.getAvailableSeasons();
        }
      },
      deep: true,
    }
  },
  components: {
    DxPopup,
    DxTabPanel,
    FunctionalCalendar,
    DxList,
    DxCheckBox,
    DxScrollView,
    FilterHeader,
    FilterDetails,
    DxTreeView,
    ToastMessage
  },
  methods: {
    ...mapActions({
      getDepartments: "company/getDepartments",
      listDepartments: "department/listDepartments",
      getWorkMachines: "works-work-machines/getWorkMachines",
      getParcels: "works-parcels/getParcels",
      nsGetSpcWorksForFilter: "seasonOnParcelHasCulture/nsGetSpcWorksForFilter",
      nsGetSpcMachinesForFilter: "seasonOnParcelHasCulture/nsGetSpcMachinesForFilter",
      nsGetSpcParcelsForFilter: "seasonOnParcelHasCulture/nsGetSpcParcelsForFilter",
      getUsedProducts: "batch/getUsedProducts",
      getSeasons: "seasons/getSeasonsForFilter",
      getDepartmentFilterTypes: 'agroFilter/getDepartmentFilterTypes'
    }),
    goBackOneStep() {
      if (this.currentStep > 0) {
        this.currentStep = this.currentStep - 1;
      }
    },
    buttonCloseEvent() {
      this.$emit("hidden");
      this.filters = JSON.parse(JSON.stringify(this.filters_on_open));
      this.activeFilters.hasDepartamentFilters = false;
      this.activeFilters.hasDateFilters = false;
      this.activeFilters.hasWorkFilters = false;
      this.instantFilters.works = "";
      this.instantFilters.machines = "";
      this.instantFilters.parcels = "";
      this.instantFilters.seasons = "";
    },
    swipeFilter(current_filter) {
      this.current_filter = current_filter;
      this.currentStep = 1;
    },
    goToRangeSelector() {
      this.currentStep = 2;
      // this.selected_range = "";
      // this.filters.date_from = "";
      // this.filters.date_to = "";
    },
    resetDateSelection(){
      this.currentStep = this.currentStep - 1;
      this.selected_range = "reset";
      this.filters.date_from = this.filters_on_open.date_from;
      this.filters.date_to = this.filters_on_open.date_to;
      this.activeFilters.hasDateFilters = false;
    },
    calendarDayChosen(){
      this.selected_range = "";
    },
    getAvailableWorks(){
      this.nsGetSpcWorksForFilter({
        department_uuid: this.filters.departments.join(),
        date_from: this.filters.date_from,
        date_to: this.filters.date_to,
        machine_uuid: this.filters.machines.join(),
        parcel_uuid: this.filters.parcels.join(),
        product_uuid: this.filters.products.join(),
        season_uuid: this.filters.seasons.join()
      }).then((resp) => {
        this.works = resp;

         if(this.filters.works && this.filters.works.length > 0 ){
          this.filters.works = this.filters.works.filter((item, index) => {
            return this.works.find((obj) => obj.uuid == item) != undefined;
          });
         }
        });
    },
    getAvailableMachines(){
      this.nsGetSpcMachinesForFilter({
        department_uuid: this.filters.departments.join(),
        date_from: this.filters.date_from,
        date_to: this.filters.date_to,
        work_uuid: this.filters.works.join(),
        parcel_uuid: this.filters.parcels.join(),
        product_uuid: this.filters.products.join(),
        season_uuid: this.filters.seasons.join()
      }).then((resp) => {
        this.workMachines = resp;

         if(this.filters.machines && this.filters.machines.length > 0 ){
          this.filters.machines = this.filters.machines.filter((item, index) => {
            return this.workMachines.find((obj) => obj.uuid == item) != undefined;
          });
         }
        });
    },
    getAvailableParcels(){
      this.nsGetSpcParcelsForFilter({
        department_uuid: this.filters.departments.join(),
        date_from: this.filters.date_from,
        date_to: this.filters.date_to,
        work_uuid: this.filters.works.join(),
        work_machine_uuid: this.filters.machines.join(),
        product_uuid: this.filters.products.join(),
        season_uuid: this.filters.seasons.join()
      })
      .then((resp) => {
        this.parcels = resp;

         if(this.filters.parcels && this.filters.parcels.length > 0 ){
          this.filters.parcels = this.filters.parcels.filter((item, index) => {
            return this.parcels.find((obj) => obj.uuid == item) != undefined;
          });
         }
        });
    },
    getAvailableProducts(){
      this.getUsedProducts({
        department_uuid: this.filters.departments.join(),
        date_from: this.filters.date_from,
        date_to: this.filters.date_to,
        work_uuid: this.filters.works.join(),
        work_machine_uuid: this.filters.machines.join(),
        parcel_uuid: this.filters.parcels.join(),
        season_uuid: this.filters.seasons.join()
      }).then((response) => {
        this.products = response;

          if(this.filters.products && this.filters.products.length > 0 ){
          this.filters.products = this.filters.products.filter((item, index) => {
            return this.products.find((obj) => obj.product_uuid == item) != undefined;
          });
         }

        });
    },
    getAvailableSeasons(){
      this.getSeasons({
        department_uuid: this.filters.departments.join(),
        date_from: this.filters.date_from,
        date_to: this.filters.date_to,
        work_uuid: this.filters.works.join(),
        work_machine_uuid: this.filters.machines.join(),
        parcel_uuid: this.filters.parcels.join(),
        product_uuid: this.filters.products.join(),
      })
      .then((response) => {
        this.seasons = response;

        if(this.filters.seasons && this.filters.seasons.length > 0 ){
          this.filters.seasons = this.filters.seasons.filter((item, index) => {
            return this.seasons.find((obj) => obj.uuid == item) != undefined;
          });
         }
      });
    },
    filterSelectionChanged(e) {
      this.currentItem = e.addedItems[0];
      this.currentStep = this.filterSteps.indexOf(this.currentItem);
    },

    changeDepartmentFilters() {
      this.getAvailableWorks();
      this.getAvailableMachines();
      this.getAvailableParcels();
      this.getAvailableProducts();
      this.getAvailableSeasons();
      this.activeFilters.hasDepartamentFilters =
        this.filters.departments.sort().join() !=
        this.filters_on_open.departments.sort().join();
    },
    changeDepartmentTreeFilters(e){
      const selectedDeps = e.component.getSelectedNodes()
        .map((node) => node.itemData);
      this.filters.departments = selectedDeps.map(e => e.uuid);

      this.getAvailableWorks();
      this.getAvailableMachines();
      this.getAvailableParcels();
      this.getAvailableProducts();
      this.getAvailableSeasons();

      this.activeFilters.hasDepartamentFilters =
        this.filters.departments.sort().join() !=
        this.filters_on_open.departments.sort().join();

      //this.syncSelection(e.component);
    },
    syncSelection(treeView) {
      const selectedEmployees = treeView.getSelectedNodes()
        .map((node) => node.itemData);
      this.filters.departments = selectedEmployees.map(e => e.uuid).join();
      console.log('all tree ', selectedEmployees.map(e => e.uuid));
    },
    changeWorksFilters() {
      this.getAvailableMachines();
      this.getAvailableParcels();
      this.getAvailableProducts();
      this.getAvailableSeasons();
      this.activeFilters.hasWorkFilters =
        this.filters.works.sort().join() !=
        this.filters_on_open.works.sort().join();
    },
    changeMachinesFilter() {
      this.getAvailableWorks();
      this.getAvailableParcels();
      this.getAvailableProducts();
      this.getAvailableSeasons();
      this.activeFilters.hasMachineFilters =
        this.filters.machines.sort().join() !=
        this.filters_on_open.machines.sort().join();
    },
    changeParcelsFilters() {
      this.getAvailableWorks();
      this.getAvailableMachines();
      this.getAvailableProducts();
      this.getAvailableSeasons();
      this.activeFilters.hasParcelFilters =
        this.filters.parcels.sort().join() !=
        this.filters_on_open.parcels.sort().join();
    },
    changeProductsFilter(){
      this.getAvailableWorks();
      this.getAvailableMachines();
      this.getAvailableParcels();
      this.getAvailableSeasons();
      this.activeFilters.hasProductFilter =
        this.filters.products.sort().join() !=
        this.filters_on_open.products.sort().join();
    },
    changeSeasonsFilter(){
      this.getAvailableWorks();
      this.getAvailableMachines();
      this.getAvailableParcels();
      this.getAvailableProducts();
      this.activeFilters.hasSeasonFilter =
        this.filters.seasons.sort().join() !=
        this.filters_on_open.seasons.sort().join();
    },
    selectAllDepartments(selected) {
      // if (selected) {
      //   this.filters.departments = this.departments.map((w) => w.uuid);
      // } else {
      //   this.filters.departments = [];
      // }
      console.log(this.$refs.departmentTreeControl);
      this.departmentHierarchiList.forEach(d => {
        d.selected = selected;
      });
      //this.$refs.departmentTreeControl.selectAll();
      this.changeDepartmentFilters();
    },
    selectAllWorks(selected) {
      if (selected) {
        this.filters.works = (
          this.works ? this.works : []
        )
          .filter((obj) =>
            obj.name
              .toUpperCase()
              .includes(this.instantFilters.works.toUpperCase())
          )
          .map((w) => w.uuid);
      } else {
        this.filters.works = [];
      }
      this.changeWorksFilters();
    },
    selectAllMachines(selected) {
      if (selected) {
        this.filters.machines = (this.workMachines ? this.workMachines : [])
          .filter((obj) =>
            obj.name
              .toUpperCase()
              .includes(this.instantFilters.machines.toUpperCase())
          )
          .map((w) => w.uuid);
      } else {
        this.filters.machines = [];
      }
      this.changeMachinesFilter();
    },
    selectAllParcels(selected) {
      if (selected) {
        this.filters.parcels = (this.parcels ? this.parcels : [])
          .filter((obj) =>
            obj.name
              .toUpperCase()
              .includes(this.instantFilters.parcels.toUpperCase())
          )
          .map((w) => w.uuid);
      } else {
        this.filters.parcels = [];
      }
      this.changeParcelsFilters();
    },
    selectAllProducts(selected){
      if (selected) {
        this.filters.products = (this.products ? this.products : [])
          .filter((obj) =>
            obj.product_name
              .toUpperCase()
              .includes(this.instantFilters.products.toUpperCase())
          )
          .map((w) => w.uuid);
      } else {
        this.filters.products = [];
      }
      this.changeProductsFilter();
    },
    selectAllSeasons(selected){
      if (selected) {
        this.filters.seasons = (this.seasons ? this.seasons : [])
          .filter((obj) =>
            obj.name
              .toUpperCase()
              .includes(this.instantFilters.seasons.toUpperCase())
          )
          .map((w) => w.uuid);
      } else {
        this.filters.seasons = [];
      }
      this.changeSeasonsFilter();
    },
    applySubfilter() {
      // if (this.currentStep != 0) {
      //   this.currentStep = this.currentStep - 1;
      // } else {
        if(!this.filters.departments || this.filters.departments.length < 1){
          this.$refs.toastMessage.showError("Trebuie selectat măcar un departament!");
        }
        else{
          this.$emit("applyFilters", this.filters);
        }
      // }
    },
    resetFilters(){
      this.setInitFilters({
        departments: [this.currentDepartment.department_uuid],
        date_from: "",
        date_to: "",
        works: [],
        machines: [],
        parcels: [],
        products: [],
        seasons: []
      });
      this.$emit("applyFilters", this.filters);
    },
    setInitFilters(filters) {
      this.filters = filters;
      this.filters_on_open = JSON.parse(JSON.stringify(this.filters));
      this.activeFilters.hasDepartamentFilters = false;
      this.activeFilters.hasDateFilters = false;
      this.activeFilters.hasWorkFilters = false;
      this.activeFilters.hasMachineFilters = false;
      this.activeFilters.hasParcelFilters = false;
      this.activeFilters.hasProductFilter = false;
      this.activeFilters.hasSeasonFilter = false;
    },
    testChangeMachineFilter(param) {
      // console.log("test -> ", param);
      // console.log(
      //   "result -> ",
      //   this.workMachines.filter((obj) =>
      //     obj.name
      //       .toUpperCase()
      //       .includes(this.instantFilters.machines.toUpperCase())
      //   )
      // );
    },
    prepareDepartmentHierarchyList(){
      this.departmentHierarchiList = [];
      let preparedUnitList = [];

      this.prepareParentUnits(preparedUnitList);
      //this.departmentHierarchiList = this.getDepartmentChilds(null, preparedUnitList);
      this.departmentHierarchiList = this.departments;
    },
    prepareParentUnits(preparedUnitList){
      this.departments.forEach(d => {
        preparedUnitList.push(d);
        this.getParentUnit(d, preparedUnitList);
      });
    },
    getParentUnit(unit, hList){
      if(unit.parent_department != null){
        if(!hList.find(d => d.uuid == unit.parent_department.uuid)){
          hList.push(unit.parent_department);
        }
        this.getParentUnit(unit.parent_department, hList);
      }
    },

    getDepartmentChilds(parentDep, sourceList){

      let childList = sourceList.filter(obj =>  obj.parent_uuid == (parentDep ? parentDep.uuid : null));

      if(childList && childList.length > 0){

        childList.forEach(child => {
          child.selected = true;
          this.getDepartmentChilds(child, sourceList);
        });

        if(parentDep != null){
          parentDep.items = childList;
        }

      }

      return childList;
    }
  },
  mounted() {
    this.getDepartments({
      company_uuid: this.company.uuid,
    }).then(response => {
      this.prepareDepartmentHierarchyList();
    });

  },
};
</script>

<style lang="scss">
@import "@/assets/scss/digitalAgenda/daFilters.scss";
</style>
