const state = () => {
  return {
    sc_bonuses: [],
    sc_bonus_types: [],
    sc_bonus_calculation_types: [],
    sc_bonus: {},
    sc_bonus_filters: {},
  }
}

export default state;
