<template>
  <tr
    :class="[td.class, td.rowClass]"
    :width="td.with ? td.width : 'auto'"
  >
    <cell v-for="th in table_headers"
          :key="Math.random()"
          :align="th.align"

          :subtitle="th.subtitle"
          :header="th"
          :cell="td"
          :data="getCellValue(th)" v-if="getCellValue(th)">
    </cell>
    <action-controls
      :permission="permissionModule"
      :data="td"
      v-if="permissionModule">
    </action-controls>
  </tr>
</template>

<script>
import Cell from './Cell'
import ActionControls from "@/components/AgroGrid/TableBody/ActionControls";
import {objectGet} from "@/helpers/common";
import PdfExportCol from "~/pages/stock-management/sm-transactions/partials/Cols/PdfExportCol.vue";

export default {
  name: 'Row',
  props: ['data', 'headers', 'actions', 'permission', 'index'],
  data() {
    return {
      td: this.data ? this.data : [],
      table_headers: this.headers ? this.headers : [],
      action_buttons: this.actions ? this.actions : {},
      permissionModule: this.permission ? this.permission : undefined,
    }
  },
  components: {
    PdfExportCol,
    ActionControls,
    Cell,
  },
  methods: {
    getCellValue(th) {
        let prefix = th.prefix ? ' '+th.prefix : '';
        let suffix = th.suffix ? ' '+th.suffix : '';

        let link = '';

        if(th.subtitle && th.subtitle instanceof Function) {
          let subtitleData = th.subtitle(this.td);

          if(subtitleData instanceof Object) {
            link = '<br><a href="'+subtitleData.link+'">'+subtitleData.title+'</a>';
          } else {
            link = subtitleData;
          }
        }

      //if colTemplate is set as a function. Return value will be rendered as cell (td)

        if(th.colTemplate && th.colTemplate instanceof Function) {
          return th.colTemplate(this.td);
        }

      //if col is a callback function with injected cell value (td)
        if(th.col && th.col instanceof Function) {
            return prefix+th.col(this.td)+suffix+link;
        }

        let rowKey = Object.keys(this.td).find(key => key === th.col);

        if(Object.is(this.td[th.col]) && th.col) {
          let nestedObject =  prefix+objectGet(this.td, th.col)+suffix+link;
          return objectGet(this.td, th.col) ? nestedObject : '-';
        }

        if(rowKey) {
          let tdRowKey = this.td[rowKey];

          if(tdRowKey === null) {
            tdRowKey = '-';
          }
          return prefix+tdRowKey+suffix+link;
        }

        if(th.type === 'ActionControls') {
          return;
        }

        return '-';
    },
  },
  watch: {
    data: {
      handler: function(newVal) {
          this.td = newVal;
      },
      deep: true,
    },
    headers: {
      handler: function(newVal) {
        this.table_headers = newVal;
      },
      deep: true,
    },
    actions: function(newVal) {
      this.action_buttons = newVal;
    }
  }
}
</script>
