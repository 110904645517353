<template>
    <div>
      <WebDocumentViewer />
    </div>
</template>

<script>

import WebDocumentViewer from "@/components/reporting/WebDocumentViewer.vue";

export default {
    name: 'ReportConfig',
    components: {
      WebDocumentViewer
    },
    data(){
        return {

        }
    },
    
    
}

</script>