import * as api from '../../api/index'
import * as endpoints from '../../api/endpoints'
import * as types from '../../api/../mutation-types'

const actions = {
    //SmPartners
    getSmPartners({commit}, payload) {
      return api.fetch(endpoints.SM_PARTNERS, payload, commit, (response) => {
        commit(types.SET_SM_PARTNERS, response.data.data);
          commit("common/"+types.SET_PAGINATION, response.data.pagination, { root: true });
      });
    },
    getSmPartner({commit}, payload) {
      return api.fetch(endpoints.SM_PARTNERS+'/'+payload.uuid, payload, commit,  (response) => {
        commit(types.SET_SM_PARTNER, response.data);
      });
    },
    createSmPartner({commit}, payload) {
      return api.post(endpoints.SM_PARTNERS, payload,  commit,(response) => {
        commit(types.SET_SM_PARTNER, response.data);
      });
    },
    updateSmPartner({commit}, payload) {
      return api.put(endpoints.SM_PARTNERS+'/'+payload.uuid, payload, commit, (response) => {
        commit(types.SET_SM_PARTNER, response.data);
      })
    },
    deleteSmPartner({commit}, payload) {
      return api.remove(endpoints.SM_PARTNERS+'/'+payload.uuid, payload,  commit,(response) => {
        commit(types.SET_SM_PARTNER, response.data);
      })
    },
    //additional
    getSmPartnerTypes({commit}, payload) {
      return api.fetch(endpoints.SM_PARTNER_TYPES, payload, commit, (response) => {
        commit(types.SET_SM_PARTNER_TYPES, response.data);
      });
    },
}

export default actions;
