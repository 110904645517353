<template>
  <div>
    <b-input-group class="search_filters">
      <b-input-group-prepend>
        <img src="@/assets/images/icons/search.svg" />
      </b-input-group-prepend>
      <b-form-input
        :placeholder="searchPlaceholder"
        v-model="instantFilters"
      ></b-form-input>
    </b-input-group>
    <b-form-checkbox
      class="base_row select_all_link"
      @change="selectAllEvent($event)"
      v-model="selectAll"
    >
      Selectează tot
    </b-form-checkbox>
    <div class="filter_separator"></div>
    <DxScrollView class="row_container" :use-native="true">
      <div>
        <b-form-checkbox
          class="checkbox_row"
          v-for="(filter, index) in (filterOptions ? filterOptions : []).filter((obj) =>
            obj.name
              .toUpperCase()
              .includes(instantFilters.toUpperCase())
          )"
          :key="index"
          :id="filter.uuid"
          v-model="selectedOptions"
          :value="filter.uui"
          @change="changeFiltersEvent"
        >
          {{ filter.name }}
        </b-form-checkbox>
      </div>
    </DxScrollView>
  </div>
</template>

<script>
import { DxScrollView } from "devextreme-vue/scroll-view";

export default {
  name: "FilterDetails",
  data() {
    return {};
  },
  props: {
    filterEntity: String,
    searchPlaceholder: String,
    instantFilters: Array,
    selectAll: Boolean,
    filterOptions: Array,
    selectedOptions: Array,
  },
  components: {
    DxScrollView,
  },
  methods: {
    selectAllEvent(selected) {
      this.$emit("selectAll", selected);
    },
    changeFiltersEvent(){
        this.$emit("changeFilters");
    }
  },
};
</script>
