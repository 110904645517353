<template>
  <div id="department-list" class="department-list">
    <p class="font-weight-bold">{{$t("departments")}} ({{ items ? items.length : 0 }})</p>
    <department-item v-for="item in items" :data="item" :user="user" :key="item.uuid"
                     @onDepartmentAssigned="onDepartmentAssigned"
                     @onRoleChanged="onRoleChanged"
                     @onDepartmentRevoked="onDepartmentRevoked"
    ></department-item>
  </div>
</template>

<script>
    import DepartmentItem from "./DepartmentItem";

    export default {
        name: 'DepartmentList',
        props: ['data', 'user'],
        data() {
            return {
                items: this.data ? this.data : {},
            }
        },
        components: {
            DepartmentItem,
        },
        methods: {
            onDepartmentAssigned(data) {
                this.$emit('onDepartmentAssigned', data);
            },
            onDepartmentRevoked(data) {
                this.$emit('onDepartmentRevoked', data);
            },
            onRoleChanged(data) {
              this.$emit('onRoleChanged', data);
            }
        },
        watch: {
            data: {
                handler: function (newVal) {
                    this.items = newVal;
                },
                deep: true,
            }
        }
    }
</script>
