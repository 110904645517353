<template>
    <div class="login-layout">
        <router-view />
    </div>
  </template>

  <script>
  import {eventBus} from "@/plugins/eventBus";
  import {getServerSubdomain, isMobile, redirectToLogin} from "@/helpers/common";

  export default {
    name: "login",
      methods: {
        handleSession() {
          eventBus.$on("session-expired", (errors) => {
            if (this.$router.currentRoute.meta.layout === 'noheader' && isMobile()) {
              let unauthorizedRedirect = getServerSubdomain() + '/session-expired?layout=login';
              window.location.href = unauthorizedRedirect;
            } else {
              redirectToLogin();
            }
          });
        }
      },
      mounted() {
        this.handleSession();
      }
    }
  </script>
