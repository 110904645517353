<template>
  <card :title="$t('sorry')" id="company-list">
    <div id="forbidden" class="row">
      <div class="col-lg-6 align-content-center pt-3">
        <p>
          <img src="~@/assets/images/desktop/icons/controls/alert.svg" class="icon-svg danger-filter"/>
          {{ errorMessage }}
        </p>
      </div>
    </div>
  </card>
</template>

<script>
    import { mapGetters } from 'vuex'

    export default {
        name: 'GeneralError',
        props: ['type', 'message'],
        computed: {
            ...mapGetters({
              user: 'auth/user',
            }),
          errorMessage() {
              return this.message ?? this.$t('error_alert_text');
          }
        },
    }
</script>
