<template type="x/template">
    <transition name="modal" id="product-history-dialog">
        <DxPopup
            id="dx-dialog"
            width="100vw"
            height="100vh"
            :close-on-outside-click="false"
            :show-close-button="true"
            :visible="showPopup"
            :resize-enabled="false"
            :darg-enabled="false"
            @hidden="$emit('hidden')"
            :wrapper-attr="popupAttributes"
        >
            <template #title>
                <div class="product-history-header">
                    <div class="left_side" @click="buttonCloseEvent">
                        <img src="@/assets/images/icons/arrow-back.svg" />
                        <span>Înapoi</span>
                    </div>
                    <div class="right_side">
                        <img
                        src="@/assets/images/icons/dialog-close.svg"
                        @click="buttonCloseEvent"
                        />
                    </div>
                </div>
            </template>

            <template #content>
                <div class="product-history-container">
                    <div class="title-container">
                        <img class="title-image" src="@/assets/images/icons/droplet-simple-green.svg" />
                        <div class="title-text">{{templateData.product.code ? `${templateData.product.name} (${templateData.product.code})` : templateData.product.name}}</div>
                        <div class="subtitle-text"><span>{{productData.quantity.toLocaleString()}} {{productData.mu}}</span> in depozitul <span>{{productData.warehouse_name}}</span> in valoare de <span>{{productData.total_price.toLocaleString()}}</span> Ron</div>
                    </div>
                    <div class="dialog_title_separator"></div>
                        <DxScrollView ref="scrollView" class="product-history-scrollable-container" :element-attr="scrollAttributes" :use-native="true">
                            <div>
                                <div class="movement-container" v-for="movement in historyData" :key="movement.uuid">
                                    <div class="movement-date-container">
                                        <div :class="movementCancelClass(movement)" class="movement-date">{{movement.movement_date}}</div>
                                        <div class="machine-date-line"></div>
                                    </div>
                                    <div class="movement-data">
                                        <div class="movement-image">
                                            <img v-if="movement.transaction_type == 'TRANSFER'" src="@/assets/images/icons/transfer.svg" />
                                            <img v-if="movement.transaction_type == 'RECEPTION'" class="reception_image" src="@/assets/images/icons/receptie.svg" />
                                        </div>
                                        <div class="movement-type-price">
                                            <div :class="movementCancelClass(movement)" class="movement-type">{{transactionName(movement)}}</div>
                                            <div :class="movementCancelClass(movement)" class="movement-product-price">Valoare (Ron): {{movement.total}}</div>
                                        </div>
                                        <div class="movement-quantity">
                                            <div :class="movementCancelClass(movement)" class="movement-quantity-label">Cantitate</div>
                                            <div :class="movementCancelClass(movement)" class="movement-quantity-value">{{movement.quantity.toLocaleString()}} {{productData.mu}}</div>
                                        </div>
                                        <div class="movement-sold">
                                            <div :class="movementCancelClass(movement)" class="movement-sold-label">Sold</div>
                                            <div :class="movementCancelClass(movement)" class="movement-sold-value">{{movement.new_quantity.toLocaleString()}} {{productData.mu}}</div>
                                        </div>
                                    </div>
                                    <div v-if="movement.notes" :class="movementCancelClass(movement)" class="movement-notes">
                                        {{movement.notes}}
                                    </div>
                                    <div :class="movementCancelClass(movement)" class="movement-document" @click="documentClick(movement, false)">{{movement.download_url ? 'Descarcă' : 'Generează'}} document</div>
                                    <div v-if="movement.canceled_quantity > 0" :class="movementCancelClass(movement)" class="movement-document" @click="documentClick(movement, true)">{{movement.cancel_download_url ? 'Descarcă' : 'Generează'}} document de anulare</div>
                                </div>
                                <div class="movement_history_bottom_spacer"></div>
                            </div>
                        </DxScrollView>
                    <toast-message ref="toastMessage"></toast-message>
                </div>
            </template>

        </DxPopup>
    </transition>
</template>


<script>

import { DxPopup } from "devextreme-vue/popup";
import { DxScrollView } from "devextreme-vue/scroll-view";
import { mapGetters, mapActions } from 'vuex'
import ToastMessage from "../../../components/Mobile/toastMessage.vue"

export default {
    name: "ProductHistory",
    props: {
        showPopup: {
            type: Boolean,
            default: false,
        },
        templateData: {
            type: Object,
            default: null,
        },
    },
    data(){
        return {
            popupAttributes: {
              class: 'product_history_dialog_wrapper'
            },
            scrollAttributes: {
                class: 'product-history-scroll'
            },
            historyData: [],
            productData:{
                quantity: 0.00,
                warehouse_name: '',
                total_price: '',
                mu: ''
            }
        };
    },
    components: {
        DxPopup,
        DxScrollView,
        ToastMessage
    },
    watch: {
        showPopup(newVal){
            if(newVal){
                this.getData({
                    product_uuid: this.templateData.product.uuid,
                    warehouse_uuid: this.templateData.warehouse.warehouse_uuid,
                    //department_uuid: this.currentDepartment.department_uuid,
                    limit: 99999
                }).then(response => {
                    this.historyData = response.data;
                });
                this.getProductHistory({
                    product_uuid: this.templateData.product.uuid,
                    warehouse_uuid: this.templateData.warehouse.warehouse_uuid,
                    //department_uuid: this.currentDepartment.department_uuid,
                }).then(response => {
                    this.productData.quantity = response[0].products[0].total_quantity;
                    this.productData.warehouse_name = response[0].name;
                    this.productData.total_price = response[0].products[0].total_price;
                    this.productData.mu = response[0].products[0].measure_unit_name;
                });
            }
        }
    },
    computed: {
        ...mapGetters({
            currentDepartment: "auth/currentDepartment",
        }),
        movementCancelClass() {
            return (movement) => {
                return {
                    'canceled': movement.canceled_quantity > 0
                };
            };
        }
    },
    methods: {
         ...mapActions({
            getProductHistory: 'smStocks/getSmStockProductHistory',
            getData: 'smTransactions/getSmStockMovements',
            getProduct: 'smProducts/getSmProduct',
            getExportInfo: 'smTransactions/getExportInfo',
            getDownloadInfo : 'smTransactions/getDownloadInfo',
            downloadPdf: 'smTransactions/downloadPdf'
        }),
         buttonCloseEvent() {
          this.$emit("hidden");
        },
        transactionName(movement){
            if(movement.transaction_type == 'RECEPTION'){
                return 'Recepție';
            }
            if(movement.transaction_type == 'TRANSFER'){
                if(movement.type = "REMOVE"){
                    if(movement.old_quantity > movement.new_quantity)
                    {
                        return 'Transfer la ' + movement.dest_warehouse.name;
                    }
                    else{
                        return 'Transfer din ' + movement.dest_warehouse.name;
                    }
                }
                return 'Transfer';
            }
            if(movement.transaction_type == 'CONSUM'){
                return 'Consum';
            }
            if(movement.transaction_type == 'INITIAL_IMPORT'){
                return 'Stock inițial';
            }
            if(movement.transaction_type == 'SALES'){
                return 'Vânzare';
            }
            if(movement.transaction_type == 'REQUALIFICATION'){
                return 'Recalificare';
            }
            return movement.transaction_type;
        },
        downloadDocument(movement, is_cancel){
            this.getDownloadInfo({
                    type: movement.transaction_type,
                    url: is_cancel ? movement.cancel_export_url : movement.export_url,
                })
                .then((resp) => {
                    this.downloadPdf({
                        type: is_cancel ? movement.cancel_document_uuid : movement.document_uuid,
                        url: is_cancel ? movement.cancel_download_url : movement.download_url,
                        filename: resp.data.download_filename,
                        response_type: 'application/json'
                    })
                    .then((dl) => {
                        this.downloadPdf({
                            type: is_cancel ? movement.cancel_document_uuid : movement.document_uuid,
                            url: is_cancel ? movement.cancel_download_url : movement.download_url,
                            filename: resp.data.download_filename,
                            response_type: 'blob'
                        })
                    })
                    .catch((e) => {
                        console.log('preview download error: ', e.response);
                        if(e.response.status === 400 || e.response.status === 500) {
                            if(e.response.data.errors && e.response.data.errors.error) {
                                this.$refs.toastMessage.showError('A apărut o erroare la descărcarea documentului! ' + e.response.data.errors.error);
                            } else {
                                this.$refs.toastMessage.showError('A apărut o erroare la descărcarea documentului!');
                            }
                        }
                    });
                })
                .catch((e) => {
                    console.log('check download error: ', e.response);
                    if(e.response.status === 400 || e.response.status === 500) {
                        if(e.response.data.errors && e.response.data.errors.error) {
                            this.$refs.toastMessage.showError('A apărut o erroare la descărcarea documentului! ' + e.response.data.errors.error);
                        } else {
                            this.$refs.toastMessage.showError('A apărut o erroare la descărcarea documentului!');
                        }
                    }
                });
        },
        documentClick(movement, is_cancel){
            if((!is_cancel && movement.download_url && movement.download_url.length > 0) ||
                (is_cancel && movement.cancel_download_url && movement.cancel_download_url.length > 0)
              ){
                this.downloadDocument(movement, is_cancel);
            }
            else{
                this.getExportInfo({
                    type: movement.transaction_type,
                    url: is_cancel ? movement.cancel_export_url : movement.export_url,
                    response_type: 'application/json'
                })
                .then((resp) => {
                  this.updateBatchTransactionExports(resp.data.transaction);
                    this.downloadPdf({
                        type: movement.transaction_type,
                        url: is_cancel ? resp.data.cancel_download_url : resp.data.download_url,
                        filename: resp.data.download_filename,
                        response_type: 'blob'
                    });
                })
                .catch((error) => {
                  console.log('documentClick export error', error);
                  let msg = '';

                  if (error.response.data && error.response.data.errors && error.response.data.errors.error) {
                    msg = error.response.data.errors.error && error.response.data.errors.error.length > 0 ? error.response.data.errors.error[0] : '';
                  }

                    this.$refs.toastMessage.showError('A apărut o erroare la descărcarea documentului!' + msg);
                    console.log('A apărut o erroare la descărcarea documentului! ', error);
                })
            }
        },
        updateBatchTransactionExports(transaction) {
           let vm = this;
          if (transaction.batch_uuid) {
            let batchTransactions = vm.historyData.filter(t => t.batch_uuid === transaction.batch_uuid);

            if (batchTransactions) {
              batchTransactions.forEach((item) => {
                let updated = item;
                updated.canceled_doc_header_uuid = transaction.canceled_doc_header_uuid;
                updated.document = transaction.document;
                updated.document_block = transaction.document_block;
                updated.document_block_uuid = transaction.document_block_uuid;
                updated.document_id = transaction.document_id;
                updated.download_filename = transaction.download_filename;
                updated.download_url = transaction.download_url;
                updated.export_url = transaction.export_url;

                vm.historyData.splice(
                    vm.historyData.indexOf(
                        vm.historyData.find(it => it.uuid === item.uuid)
                    ), updated
                );
              })
            }
          }
        }
    }
}
</script>

<style lang="scss">
    @import "@/assets/scss/stockMobile/productHistory.scss";
</style>
