import * as api from '../api/index'
import * as endpoints from '../api/endpoints'
import * as types from "../mutation-types";

const actions = {
  getFitoProducts({ commit }, payload) {
    
    return api.fetch(endpoints.FITO_PRODUCTS, payload, commit, (response) => {
      commit(types.SET_FITO_PRODUCTS, response.data);
    });
  },

  getFitoProductsLight({ commit }, payload) {
    
    return api.fetch(endpoints.FITO_PRODUCTS_LIGHT, payload, commit, (response) => {
      commit(types.SET_FITO_PRODUCTS_LIGHT, response.data);
    });
  },
  
}

export default actions;
