<template>
  <div id="filters" class="filters p-3 col-lg-12">
    <div class="row">
      <div class="col-sm-12 col-lg pb-3 pb-lg-0">
        <label class="control-label">{{ $t('common.name')}}</label>
        <input v-model="filters.name" class="form-control"/>
      </div>
      <div class="col-lg form-group">
        <label class="control-label">{{ $t('company.departments.unit_type') }}</label>
        <b-form-select
          v-model="filters.type"
          class="form-control"
          :options="type_list"></b-form-select>
      </div>
      <div class="col-sm-12 col-lg pt-0 pt-lg-4 mt-0 mt-1">
        <button class="btn btn-primary col-lg-12" @click="applyFiltersClick">{{$t("common.show")}}</button>
      </div>
      <div class="col-sm-12 col-lg pt-0 pt-lg-4 mt-0 mt-1">
        <button class="btn btn-secondary col-lg-12" @click="resetFilters">{{$t("common.reset_filters")}}</button>
      </div>
    </div>
  </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex'
    import * as type from '../../../../store/mutation-types'
    import {buildDefaultDropdownList} from "~/helpers/common";

    export default {
        name: 'DepartmentFilters',
        data() {
            return {
                filters:  this.departmentFilters ? this.departmentFilters : {},
            }
        },
        computed: {
            ...mapGetters({
                currentDepartment: 'auth/currentDepartment',
                departmentFilters: 'company/departmentFilters',
                departmentFilterTypes: 'agroFilter/departmentFilterTypes',
                domain: 'auth/domain'
            }),
            type_list() {
              return buildDefaultDropdownList(Object.assign(this.departmentFilterTypes));
            },
        },
        methods: {
            ...mapActions({
                getDepartments: 'department/listDepartments',
                getDepartmentFilterTypes: 'agroFilter/getDepartmentFilterTypes'
            }),
            applyFiltersClick() {
                this.$set(this.filters, 'page', 1);
                this.applyFilters();
            },
            applyFilters() {
                this.$emit('onFiltersApplied', this.filters);
                this.getDepartments(this.filters);
            },
            resetFilters() {
                this.filters = {
                    name: '',
                    type: null
                };

                this.$store.commit("company/"+type.SET_DEPARTMENT_FILTERS, this.filters);
                this.applyFilters();
            },
        },
        mounted(){
            this.getDepartmentFilterTypes();
            this.resetFilters();
        },
        watch: {
            departmentFilters: {
                handler: function(newVal) {
                    this.filters = newVal;
                },
                deep: true,
            },
        }
    }
</script>
