<template>
  <div id="user-list-filters" class="filters p-3">
    <div class="row">
      <div class="col-sm-6 col-lg-3">
        <label class="control-label">{{$t("name")}}</label>
        <input v-model="filters.name" class="form-control" autocomplete="off"/>
      </div>
      <div class="col-sm-6 col-lg-2">
        <label class="control-label">{{$t("email")}}</label>
        <input v-model="filters.email" class="form-control" autocomplete="off"/>
      </div>
      <div class="col-sm-6 col-lg-2">
        <label class="control-label">{{$t("state")}}</label>
        <b-form-select v-model="filters.is_active" class="form-control" :options="state_list"></b-form-select>
      </div>
      <div class="xs-12 col-sm-12 col-md-2 col-lg-2 pt-0 pt-lg-4 mt-1">
        <button class="btn btn-outline-primary col-lg-12" @click="applyFilters">{{$t("common.show")}}</button>
      </div>
      <div class="xs-12 col-sm-12 col-md-2 col-lg-3 pt-0 pt-lg-4 mt-1">
        <button class="btn btn-outline-secondary col-lg-12" @click="resetFilters">{{$t("common.reset_filters")}}</button>
      </div>
    </div>
  </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex'
    import * as type from '../../../store/mutation-types'

    export default {
        name: 'UserListFilters',
        data() {
            return {
                state_list: [
                    { value: '', text: '-All-' },
                    { value: 1, text: 'Active' },
                    { value: 0, text: 'Inactive' },
                ],
                filters:  this.userFilters ? this.userFilters : {},
            }
        },
        computed: {
            ...mapGetters({
                users: 'user/users',
                userFilters: 'user/userFilters',
            }),
        },
        methods: {
            ...mapActions({
                getUsers: 'user/getUsers',
            }),
            applyFiltersClick() {
                this.$set(this.filters, 'page', 1);
                this.$router.push({
                        path: '/crm/user-management',
                        params: {
                            page: 1,
                        }
                    }
                );
                this.applyFilters();
            },
            applyFilters() {
                this.getUsers(this.filters);
                this.$emit('onFiltersApplied', this.filters);
            },
            resetFilters() {
                this.filters = {
                    name: '',
                    email: '',
                    is_active: 1,
                    page: 1,
                };

                this.$store.commit("user/"+type.SET_USER_FILTERS, this.filters);
                this.applyFilters();
            },
        },
        mounted(){
            this.resetFilters();
        },
        watch: {
            userFilters: {
                handler: function(newVal) {
                    this.filters = newVal;
                },
                deep: true,
            }
        }
    }
</script>

<style scoped>
  button {

  }
</style>
