<template>
  <div class="sc-work-reports">
    <div class="view" v-if="canEdit || canDelete" v-cloak>
      <card :title="title" id="sc-work-reports">
        <div class="row">
          <div class="col-lg-12">
            <ul class="nav nav-pills mb-3 pt-32" id="pills-tab" role="tablist">
              <li class="nav-item" role="presentation">
                <button class="nav-link active fs14-700"
                        id="pills-daily-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-daily"
                        type="button" role="tab"
                        aria-controls="pills-daily"
                        aria-selected="true">
                  {{ $t('salary_calculator.reports.daily') }}
                </button>
              </li>
              <li class="nav-item" role="presentation">
                <button class="nav-link fs14-700"
                        id="pills-summary-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-summary"
                        type="button" role="tab"
                        aria-controls="pills-summary"
                        aria-selected="false">
                  {{ $t('salary_calculator.reports.summary') }}
                </button>
              </li>
            </ul>
            <div class="tab-content" id="pills-tabContent">
              <div class="tab-pane fade show active" id="pills-daily" role="tabpanel" aria-labelledby="pills-daily-tab">
                <s-c-daily-report :data="dailyGridData" ref="dailyReport" :action-method="getSCWorkCollectorHectareData"/>
              </div>
              <div class="tab-pane fade" id="pills-summary" role="tabpanel" aria-labelledby="pills-summary-tab">
                Summary grid
              </div>
            </div>
          </div>
        </div>
      </card>
    </div>
    <div v-else>
      <forbidden></forbidden>
    </div>
  </div>
</template>

<script>
import Forbidden from "~/pages/errors/Forbidden.vue";
import {mapActions, mapGetters} from "vuex";
import {canDelete, canEdit, permissions, permissionTypes} from "~/helpers/Permissions";
import DailyGrid from "~/pages/salary-calculator/sc-work-reports/partials/DailyGrid/index.vue";
import SCDailyReport from "~/pages/salary-calculator/sc-work-reports/reports/SCDailyReport.vue";

export default {
  name: 'ScWorkReport',
  components: {SCDailyReport, DailyGrid, Forbidden},
  data() {
    return {
      dailyGridData: this.scWorkCollectorHectareData ?? [],
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
      scWorkCollectorHectareData: 'scWorkPivot/scWorkCollectorHectareData',
      pagination: 'common/pagination',
      users: 'user/users',
    }),
    title() {
      return this.$t('salary_calculator.reports.title');
    },
    canEdit() {
      return canEdit(this);
    },
    canDelete() {
      return canDelete(this);
    },
  },
  methods: {
    ...mapActions({
      getSCWorkCollectorHectareData: 'scWorkPivot/getSCWorkCollectorHectareData'
    }),
  },
  mounted() {
    this.$refs.dailyReport.onPageSelected(1);
  },
  watch: {
    scWorkCollectorHectareData: {
      handler: function(newVal) {
        this.dailyGridData = newVal;
      },
      deep: true,
    }
  }
}
</script>
