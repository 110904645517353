<template>
  <div class="view work-type-details" v-if="canEdit || canDelete" v-cloak>
    <agro-editor :config="config" :name="$t('salary_calculator.work_type.title')"></agro-editor>
  </div>
  <div v-else>
    <forbidden></forbidden>
  </div>
</template>
<script>
import AgroEditor from "@/components/AgroEditor/index";
import {mapActions} from "vuex";
import {canDelete, canEdit, permissions, permissionTypes} from "@/helpers/Permissions";
import Forbidden from "~/pages/errors/Forbidden.vue";
export default {
  name: 'SCWorkDetails',
  data() {
    return {

    }
  },
  computed: {
    config() {
      return {
        permission: permissions.SC_WORK,
        permission_type: permissionTypes.module,
        get: this.get,
        create: this.create,
        update: this.update,
        delete: this.delete,
        list_url: '/salary-calculator/sc-work-type',
        confirm_delete: true,
        create_and_new: true,
      }
    },
    canEdit() {
      return canEdit(this);
    },
    canDelete() {
      return canDelete(this);
    },
  },
  methods: {
    ...mapActions({
      get: 'scWork/getSCWork',
      create: 'scWork/createSCWork',
      update: 'scWork/updateSCWork',
      delete: 'scWork/deleteSCWork',
    }),
  },
  components: {
    Forbidden,
    AgroEditor
  }
}
</script>
