export function getTestData() {
  var returnData = [
    {
      "uuid": "621ddcbf05d7ab1db368bd45",
      "work_started_at": "2022-02-09 06:15:01",
      "work_ended_at": "2022-01-11 01:09:44",
      "culture": {
        "name": "Floarea Soarelui"
      },
      "parcel": {
        "name": "Sola P5"
      },
      "work": {
        "name": "Recoltat",
        "color": "#64B5F6"
      },
      "work_mashine": {
        "name": "VN-106-Garoafa"
      }
    },
    {
      "uuid": "621ddcbf7353d1cbc6734a92",
      "work_started_at": "2022-01-08 12:01:26",
      "work_ended_at": "2022-01-17 11:57:18",
      "culture": {
        "name": "Porumb"
      },
      "parcel": {
        "name": "Sola P3"
      },
      "work": {
        "name": "Recoltat",
        "color": "#64B5F6"
      },
      "work_mashine": {
        "name": "VN-106-Garoafa"
      }
    },
    {
      "uuid": "621ddcbf56284a08128850d5",
      "work_started_at": "2022-02-11 03:03:17",
      "work_ended_at": "2022-01-19 05:20:44",
      "culture": {
        "name": "Grau"
      },
      "parcel": {
        "name": "Sola P4"
      },
      "work": {
        "name": "Deplasare utilaje",
        "color": "#FF8A65"
      },
      "work_mashine": {
        "name": "BC-109-Filipesti"
      }
    },
    {
      "uuid": "621ddcbf59a6de3b8415e0cc",
      "work_started_at": "2022-01-12 02:56:07",
      "work_ended_at": "2022-02-12 08:15:27",
      "culture": {
        "name": "Floarea Soarelui"
      },
      "parcel": {
        "name": "Sola P1"
      },
      "work": {
        "name": "Deplasare utilaje",
        "color": "#FF8A65"
      },
      "work_mashine": {
        "name": "VN-106-Garoafa"
      }
    },
    {
      "uuid": "621ddcbfcf62d461d534594d",
      "work_started_at": "2022-01-23 02:20:03",
      "work_ended_at": "2022-01-09 10:34:43",
      "culture": {
        "name": "Floarea Soarelui"
      },
      "parcel": {
        "name": "Sola P2"
      },
      "work": {
        "name": "Recoltat"
      },
      "work_mashine": {
        "name": "VN-106-Garoafa"
      }
    },
    {
      "uuid": "621ddcbf45a8eb022114d6f6",
      "work_started_at": "2022-01-23 06:15:35",
      "work_ended_at": "2022-01-28 12:24:43",
      "culture": {
        "name": "Porumb"
      },
      "parcel": {
        "name": "Sola P1"
      },
      "work": {
        "name": "Deplasare utilaje"
      },
      "work_mashine": {
        "name": "BC-109-Filipesti"
      }
    },
    {
      "uuid": "621ddcbfc9c4e48428b30c8e",
      "work_started_at": "2022-01-12 06:55:22",
      "work_ended_at": "2022-01-29 07:39:19",
      "culture": {
        "name": "Sfecla de zahar"
      },
      "parcel": {
        "name": "Sola P2"
      },
      "work": {
        "name": "Pregatit teren"
      },
      "work_mashine": {
        "name": "BC-109-Filipesti"
      }
    },
    {
      "uuid": "621ddcbf821cbb63cc5fd1e1",
      "work_started_at": "2022-02-09 10:19:33",
      "work_ended_at": "2022-01-08 06:59:05",
      "culture": {
        "name": "Rapita"
      },
      "parcel": {
        "name": "Sola P1"
      },
      "work": {
        "name": "Deplasare utilaje"
      },
      "work_mashine": {
        "name": "BC-109-Filipesti"
      }
    },
    {
      "uuid": "621ddcbf374df1ddd63a6c45",
      "work_started_at": "2022-01-09 10:53:00",
      "work_ended_at": "2022-01-30 12:25:02",
      "culture": {
        "name": "Floarea Soarelui"
      },
      "parcel": {
        "name": "Sola P1"
      },
      "work": {
        "name": "Deplasare utilaje"
      },
      "work_mashine": {
        "name": "VN-106-Garoafa"
      }
    },
    {
      "uuid": "621ddcbfa530dbff80efa8f4",
      "work_started_at": "2022-02-28 01:42:34",
      "work_ended_at": "2022-02-11 11:00:47",
      "culture": {
        "name": "Rapita"
      },
      "parcel": {
        "name": "Sola P3"
      },
      "work": {
        "name": "Deplasare utilaje"
      },
      "work_mashine": {
        "name": "BC-29-CFA"
      }
    },
    {
      "uuid": "621ddcbf76ca2b7cdf01a1df",
      "work_started_at": "2022-01-29 02:50:21",
      "work_ended_at": "2022-01-24 02:06:50",
      "culture": {
        "name": "Floarea Soarelui"
      },
      "parcel": {
        "name": "Sola P2"
      },
      "work": {
        "name": "Deplasare utilaje"
      },
      "work_mashine": {
        "name": "BC-29-CFA"
      }
    },
    {
      "uuid": "621ddcbfadb2aa03c693ca23",
      "work_started_at": "2022-01-26 08:25:13",
      "work_ended_at": "2022-02-28 12:10:52",
      "culture": {
        "name": "Sfecla de zahar"
      },
      "parcel": {
        "name": "Sola P4"
      },
      "work": {
        "name": "Tratament"
      },
      "work_mashine": {
        "name": "BC-109-Filipesti"
      }
    },
    {
      "uuid": "621ddcbf66f0ec409a967293",
      "work_started_at": "2022-01-31 07:51:19",
      "work_ended_at": "2022-02-22 06:52:39",
      "culture": {
        "name": "Grau"
      },
      "parcel": {
        "name": "Sola P4"
      },
      "work": {
        "name": "Recoltat"
      },
      "work_mashine": {
        "name": "VN-106-Garoafa"
      }
    },
    {
      "uuid": "621ddcbfa8fbc742d9e78f24",
      "work_started_at": "2022-02-28 03:21:32",
      "work_ended_at": "2022-01-29 02:58:36",
      "culture": {
        "name": "Sfecla de zahar"
      },
      "parcel": {
        "name": "Sola P3"
      },
      "work": {
        "name": "Pregatit teren"
      },
      "work_mashine": {
        "name": "BC-109-Filipesti"
      }
    },
    {
      "uuid": "621ddcbf98177fc148214e9d",
      "work_started_at": "2022-01-01 10:50:44",
      "work_ended_at": "2022-01-31 11:11:04",
      "culture": {
        "name": "Sfecla de zahar"
      },
      "parcel": {
        "name": "Sola P3"
      },
      "work": {
        "name": "Deplasare utilaje"
      },
      "work_mashine": {
        "name": "BC-109-Filipesti"
      }
    },
    {
      "uuid": "621ddcbfe47aee7f38c08f98",
      "work_started_at": "2022-01-29 06:53:29",
      "work_ended_at": "2022-01-31 06:42:15",
      "culture": {
        "name": "Grau"
      },
      "parcel": {
        "name": "Sola P4"
      },
      "work": {
        "name": "Recoltat"
      },
      "work_mashine": {
        "name": "VN-106-Garoafa"
      }
    },
    {
      "uuid": "621ddcbfe6edfbfa384f7618",
      "work_started_at": "2022-02-17 07:31:46",
      "work_ended_at": "2022-01-24 04:32:25",
      "culture": {
        "name": "Soia"
      },
      "parcel": {
        "name": "Sola P5"
      },
      "work": {
        "name": "Tratament"
      },
      "work_mashine": {
        "name": "VN-106-Garoafa"
      }
    },
    {
      "uuid": "621ddcbfc3692a0bc8197cdb",
      "work_started_at": "2022-02-18 08:03:08",
      "work_ended_at": "2022-01-06 06:13:05",
      "culture": {
        "name": "Sfecla de zahar"
      },
      "parcel": {
        "name": "Sola P1"
      },
      "work": {
        "name": "Tratament"
      },
      "work_mashine": {
        "name": "VN-106-Garoafa"
      }
    },
    {
      "uuid": "621ddcbfc11b00cf169e8d9b",
      "work_started_at": "2022-01-12 05:31:50",
      "work_ended_at": "2022-01-12 03:00:11",
      "culture": {
        "name": "Grau"
      },
      "parcel": {
        "name": "Sola P2"
      },
      "work": {
        "name": "Deplasare utilaje"
      },
      "work_mashine": {
        "name": "VN-106-Garoafa"
      }
    },
    {
      "uuid": "621ddcbf95dbda89a9fd15e7",
      "work_started_at": "2022-01-08 12:19:26",
      "work_ended_at": "2022-01-09 05:04:38",
      "culture": {
        "name": "Grau"
      },
      "parcel": {
        "name": "Sola P1"
      },
      "work": {
        "name": "Deplasare utilaje"
      },
      "work_mashine": {
        "name": "VN-106-Garoafa"
      }
    },
    {
      "uuid": "621ddcbf988ebbcf8a4a473a",
      "work_started_at": "2022-02-14 06:12:00",
      "work_ended_at": "2022-02-04 01:44:35",
      "culture": {
        "name": "Rapita"
      },
      "parcel": {
        "name": "Sola P4"
      },
      "work": {
        "name": "Tratament"
      },
      "work_mashine": {
        "name": "VN-106-Garoafa"
      }
    },
    {
      "uuid": "621ddcbfcefba8a747158036",
      "work_started_at": "2022-01-06 08:38:48",
      "work_ended_at": "2022-02-21 05:57:51",
      "culture": {
        "name": "Floarea Soarelui"
      },
      "parcel": {
        "name": "Sola P1"
      },
      "work": {
        "name": "Deplasare utilaje"
      },
      "work_mashine": {
        "name": "VN-106-Garoafa"
      }
    },
    {
      "uuid": "621ddcbff2f5aa20742f67c7",
      "work_started_at": "2022-01-30 11:12:18",
      "work_ended_at": "2022-01-22 03:13:52",
      "culture": {
        "name": "Sfecla de zahar"
      },
      "parcel": {
        "name": "Sola P1"
      },
      "work": {
        "name": "Recoltat"
      },
      "work_mashine": {
        "name": "BC-29-CFA"
      }
    },
    {
      "uuid": "621ddcbf21413c241d08dc8d",
      "work_started_at": "2022-01-04 06:19:39",
      "work_ended_at": "2022-01-11 08:14:59",
      "culture": {
        "name": "Rapita"
      },
      "parcel": {
        "name": "Sola P5"
      },
      "work": {
        "name": "Deplasare utilaje"
      },
      "work_mashine": {
        "name": "BC-109-Filipesti"
      }
    },
    {
      "uuid": "621ddcbf9fa96b86a8eb5ed2",
      "work_started_at": "2022-01-13 12:51:44",
      "work_ended_at": "2022-02-14 06:52:17",
      "culture": {
        "name": "Rapita"
      },
      "parcel": {
        "name": "Sola P4"
      },
      "work": {
        "name": "Tratament"
      },
      "work_mashine": {
        "name": "VN-106-Garoafa"
      }
    },
    {
      "uuid": "621ddcbfa65d2bd9bc070686",
      "work_started_at": "2022-01-22 02:31:45",
      "work_ended_at": "2022-01-15 12:38:14",
      "culture": {
        "name": "Grau"
      },
      "parcel": {
        "name": "Sola P2"
      },
      "work": {
        "name": "Recoltat"
      },
      "work_mashine": {
        "name": "BC-109-Filipesti"
      }
    },
    {
      "uuid": "621ddcbfad6e245b6b5b388e",
      "work_started_at": "2022-02-19 01:18:20",
      "work_ended_at": "2022-02-27 02:09:12",
      "culture": {
        "name": "Rapita"
      },
      "parcel": {
        "name": "Sola P3"
      },
      "work": {
        "name": "Recoltat"
      },
      "work_mashine": {
        "name": "VN-106-Garoafa"
      }
    },
    {
      "uuid": "621ddcbf83e9c11309a11598",
      "work_started_at": "2022-01-09 01:36:18",
      "work_ended_at": "2022-02-06 08:43:31",
      "culture": {
        "name": "Grau"
      },
      "parcel": {
        "name": "Sola P5"
      },
      "work": {
        "name": "Pregatit teren"
      },
      "work_mashine": {
        "name": "VN-106-Garoafa"
      }
    },
    {
      "uuid": "621ddcbfd0e42a1efb096475",
      "work_started_at": "2022-02-10 03:51:59",
      "work_ended_at": "2022-01-03 06:16:47",
      "culture": {
        "name": "Rapita"
      },
      "parcel": {
        "name": "Sola P1"
      },
      "work": {
        "name": "Tratament"
      },
      "work_mashine": {
        "name": "BC-109-Filipesti"
      }
    },
    {
      "uuid": "621ddcbf833480981ec949f2",
      "work_started_at": "2022-01-04 11:12:58",
      "work_ended_at": "2022-01-02 11:52:36",
      "culture": {
        "name": "Sfecla de zahar"
      },
      "parcel": {
        "name": "Sola P2"
      },
      "work": {
        "name": "Deplasare utilaje"
      },
      "work_mashine": {
        "name": "BC-29-CFA"
      }
    },
    {
      "uuid": "621ddcbfc7b874b65c0e4514",
      "work_started_at": "2022-01-27 05:30:21",
      "work_ended_at": "2022-01-14 10:26:18",
      "culture": {
        "name": "Soia"
      },
      "parcel": {
        "name": "Sola P3"
      },
      "work": {
        "name": "Pregatit teren"
      },
      "work_mashine": {
        "name": "BC-29-CFA"
      }
    },
    {
      "uuid": "621ddcbfb52b311557bf4bb0",
      "work_started_at": "2022-01-29 12:14:52",
      "work_ended_at": "2022-01-15 02:27:53",
      "culture": {
        "name": "Grau"
      },
      "parcel": {
        "name": "Sola P5"
      },
      "work": {
        "name": "Recoltat"
      },
      "work_mashine": {
        "name": "BC-29-CFA"
      }
    },
    {
      "uuid": "621ddcbf825491c8c54be6e8",
      "work_started_at": "2022-02-02 01:51:37",
      "work_ended_at": "2022-01-28 12:24:51",
      "culture": {
        "name": "Porumb"
      },
      "parcel": {
        "name": "Sola P3"
      },
      "work": {
        "name": "Pregatit teren"
      },
      "work_mashine": {
        "name": "VN-106-Garoafa"
      }
    },
    {
      "uuid": "621ddcbf608ff84c172dd5df",
      "work_started_at": "2022-02-10 12:10:10",
      "work_ended_at": "2022-02-02 07:40:01",
      "culture": {
        "name": "Rapita"
      },
      "parcel": {
        "name": "Sola P3"
      },
      "work": {
        "name": "Tratament"
      },
      "work_mashine": {
        "name": "BC-29-CFA"
      }
    },
    {
      "uuid": "621ddcbf65142ccd85d3c91c",
      "work_started_at": "2022-02-04 03:48:41",
      "work_ended_at": "2022-01-02 10:59:13",
      "culture": {
        "name": "Rapita"
      },
      "parcel": {
        "name": "Sola P2"
      },
      "work": {
        "name": "Pregatit teren"
      },
      "work_mashine": {
        "name": "BC-109-Filipesti"
      }
    },
    {
      "uuid": "621ddcbf9ee9877a9af4412e",
      "work_started_at": "2022-02-04 02:39:59",
      "work_ended_at": "2022-02-21 02:16:20",
      "culture": {
        "name": "Rapita"
      },
      "parcel": {
        "name": "Sola P2"
      },
      "work": {
        "name": "Recoltat"
      },
      "work_mashine": {
        "name": "BC-29-CFA"
      }
    },
    {
      "uuid": "621ddcbf81aa7cb7e1aec153",
      "work_started_at": "2022-02-02 01:05:18",
      "work_ended_at": "2022-01-05 04:23:37",
      "culture": {
        "name": "Porumb"
      },
      "parcel": {
        "name": "Sola P3"
      },
      "work": {
        "name": "Recoltat"
      },
      "work_mashine": {
        "name": "BC-29-CFA"
      }
    },
    {
      "uuid": "621ddcbfe69bad84e0367e5f",
      "work_started_at": "2022-02-16 06:12:48",
      "work_ended_at": "2022-01-13 11:34:57",
      "culture": {
        "name": "Grau"
      },
      "parcel": {
        "name": "Sola P4"
      },
      "work": {
        "name": "Tratament"
      },
      "work_mashine": {
        "name": "BC-29-CFA"
      }
    },
    {
      "uuid": "621ddcbf792a928e4c6f2dc6",
      "work_started_at": "2022-01-15 03:25:41",
      "work_ended_at": "2022-01-12 08:33:47",
      "culture": {
        "name": "Sfecla de zahar"
      },
      "parcel": {
        "name": "Sola P3"
      },
      "work": {
        "name": "Pregatit teren"
      },
      "work_mashine": {
        "name": "BC-29-CFA"
      }
    },
    {
      "uuid": "621ddcbf0929dfe2803f73f2",
      "work_started_at": "2022-02-06 11:24:56",
      "work_ended_at": "2022-01-31 10:23:52",
      "culture": {
        "name": "Soia"
      },
      "parcel": {
        "name": "Sola P3"
      },
      "work": {
        "name": "Pregatit teren"
      },
      "work_mashine": {
        "name": "BC-109-Filipesti"
      }
    },
    {
      "uuid": "621ddcbf80a4c4a6698c3829",
      "work_started_at": "2022-01-14 03:17:43",
      "work_ended_at": "2022-02-03 12:42:20",
      "culture": {
        "name": "Rapita"
      },
      "parcel": {
        "name": "Sola P2"
      },
      "work": {
        "name": "Deplasare utilaje"
      },
      "work_mashine": {
        "name": "BC-109-Filipesti"
      }
    },
    {
      "uuid": "621ddcbfac33b4c5b1de6982",
      "work_started_at": "2022-02-11 06:53:18",
      "work_ended_at": "2022-02-20 03:55:16",
      "culture": {
        "name": "Rapita"
      },
      "parcel": {
        "name": "Sola P1"
      },
      "work": {
        "name": "Deplasare utilaje"
      },
      "work_mashine": {
        "name": "BC-29-CFA"
      }
    },
    {
      "uuid": "621ddcbff2913ff822aa8870",
      "work_started_at": "2022-02-19 07:15:31",
      "work_ended_at": "2022-01-30 11:22:31",
      "culture": {
        "name": "Floarea Soarelui"
      },
      "parcel": {
        "name": "Sola P4"
      },
      "work": {
        "name": "Recoltat"
      },
      "work_mashine": {
        "name": "BC-29-CFA"
      }
    },
    {
      "uuid": "621ddcbf8d73243e6c031696",
      "work_started_at": "2022-01-11 03:21:30",
      "work_ended_at": "2022-01-08 03:48:39",
      "culture": {
        "name": "Sfecla de zahar"
      },
      "parcel": {
        "name": "Sola P5"
      },
      "work": {
        "name": "Deplasare utilaje"
      },
      "work_mashine": {
        "name": "BC-29-CFA"
      }
    },
    {
      "uuid": "621ddcbfc3706e328e254e59",
      "work_started_at": "2022-01-22 12:07:58",
      "work_ended_at": "2022-02-05 11:02:17",
      "culture": {
        "name": "Rapita"
      },
      "parcel": {
        "name": "Sola P3"
      },
      "work": {
        "name": "Tratament"
      },
      "work_mashine": {
        "name": "VN-106-Garoafa"
      }
    },
    {
      "uuid": "621ddcbf4bc2e52d63d35b1f",
      "work_started_at": "2022-01-02 11:50:31",
      "work_ended_at": "2022-01-18 11:13:07",
      "culture": {
        "name": "Grau"
      },
      "parcel": {
        "name": "Sola P3"
      },
      "work": {
        "name": "Pregatit teren"
      },
      "work_mashine": {
        "name": "BC-29-CFA"
      }
    },
    {
      "uuid": "621ddcbf56a0a1b8f20db6a1",
      "work_started_at": "2022-01-21 08:33:34",
      "work_ended_at": "2022-02-24 05:30:19",
      "culture": {
        "name": "Floarea Soarelui"
      },
      "parcel": {
        "name": "Sola P1"
      },
      "work": {
        "name": "Deplasare utilaje"
      },
      "work_mashine": {
        "name": "BC-29-CFA"
      }
    },
    {
      "uuid": "621ddcbf61f5c65c056340b5",
      "work_started_at": "2022-01-28 10:48:47",
      "work_ended_at": "2022-02-14 10:01:28",
      "culture": {
        "name": "Soia"
      },
      "parcel": {
        "name": "Sola P1"
      },
      "work": {
        "name": "Tratament"
      },
      "work_mashine": {
        "name": "VN-106-Garoafa"
      }
    },
    {
      "uuid": "621ddcbf0653d45fdd703039",
      "work_started_at": "2022-02-04 07:25:02",
      "work_ended_at": "2022-01-25 08:39:00",
      "culture": {
        "name": "Grau"
      },
      "parcel": {
        "name": "Sola P4"
      },
      "work": {
        "name": "Tratament"
      },
      "work_mashine": {
        "name": "BC-109-Filipesti"
      }
    },
    {
      "uuid": "621ddcbf418cdd1e8ecc8138",
      "work_started_at": "2022-02-14 08:30:37",
      "work_ended_at": "2022-01-19 10:03:50",
      "culture": {
        "name": "Grau"
      },
      "parcel": {
        "name": "Sola P1"
      },
      "work": {
        "name": "Recoltat"
      },
      "work_mashine": {
        "name": "BC-109-Filipesti"
      }
    },
    {
      "uuid": "621ddcbf4c81221828a1593a",
      "work_started_at": "2022-01-15 05:20:05",
      "work_ended_at": "2022-01-30 09:50:27",
      "culture": {
        "name": "Soia"
      },
      "parcel": {
        "name": "Sola P5"
      },
      "work": {
        "name": "Tratament"
      },
      "work_mashine": {
        "name": "BC-109-Filipesti"
      }
    },
    {
      "uuid": "621ddcbf75b3d9cf7a76a757",
      "work_started_at": "2022-02-25 02:49:13",
      "work_ended_at": "2022-02-08 04:19:29",
      "culture": {
        "name": "Floarea Soarelui"
      },
      "parcel": {
        "name": "Sola P4"
      },
      "work": {
        "name": "Recoltat"
      },
      "work_mashine": {
        "name": "VN-106-Garoafa"
      }
    },
    {
      "uuid": "621ddcbfba0b1982d2561555",
      "work_started_at": "2022-02-04 09:48:45",
      "work_ended_at": "2022-01-21 03:37:48",
      "culture": {
        "name": "Soia"
      },
      "parcel": {
        "name": "Sola P2"
      },
      "work": {
        "name": "Recoltat"
      },
      "work_mashine": {
        "name": "BC-109-Filipesti"
      }
    },
    {
      "uuid": "621ddcbf7049a6b9faa20f3d",
      "work_started_at": "2022-02-08 05:06:13",
      "work_ended_at": "2022-02-04 12:02:45",
      "culture": {
        "name": "Soia"
      },
      "parcel": {
        "name": "Sola P1"
      },
      "work": {
        "name": "Tratament"
      },
      "work_mashine": {
        "name": "BC-29-CFA"
      }
    },
    {
      "uuid": "621ddcbf38122f2bf3113538",
      "work_started_at": "2022-02-17 09:17:34",
      "work_ended_at": "2022-01-28 06:17:09",
      "culture": {
        "name": "Rapita"
      },
      "parcel": {
        "name": "Sola P1"
      },
      "work": {
        "name": "Deplasare utilaje"
      },
      "work_mashine": {
        "name": "BC-109-Filipesti"
      }
    },
    {
      "uuid": "621ddcbf972065af539655be",
      "work_started_at": "2022-02-05 07:26:11",
      "work_ended_at": "2022-02-11 05:40:39",
      "culture": {
        "name": "Floarea Soarelui"
      },
      "parcel": {
        "name": "Sola P5"
      },
      "work": {
        "name": "Pregatit teren"
      },
      "work_mashine": {
        "name": "BC-29-CFA"
      }
    },
    {
      "uuid": "621ddcbfc49703d99758a029",
      "work_started_at": "2022-02-16 02:56:58",
      "work_ended_at": "2022-01-01 04:59:35",
      "culture": {
        "name": "Rapita"
      },
      "parcel": {
        "name": "Sola P5"
      },
      "work": {
        "name": "Tratament"
      },
      "work_mashine": {
        "name": "BC-109-Filipesti"
      }
    },
    {
      "uuid": "621ddcbf06b00032250e0aa9",
      "work_started_at": "2022-01-03 03:12:01",
      "work_ended_at": "2022-02-21 12:05:23",
      "culture": {
        "name": "Rapita"
      },
      "parcel": {
        "name": "Sola P5"
      },
      "work": {
        "name": "Recoltat"
      },
      "work_mashine": {
        "name": "BC-29-CFA"
      }
    },
    {
      "uuid": "621ddcbf5f0a32449a2128de",
      "work_started_at": "2022-02-03 11:33:30",
      "work_ended_at": "2022-02-05 03:00:42",
      "culture": {
        "name": "Floarea Soarelui"
      },
      "parcel": {
        "name": "Sola P5"
      },
      "work": {
        "name": "Pregatit teren"
      },
      "work_mashine": {
        "name": "VN-106-Garoafa"
      }
    },
    {
      "uuid": "621ddcbf01735a09be496d28",
      "work_started_at": "2022-02-11 07:14:29",
      "work_ended_at": "2022-02-01 12:41:30",
      "culture": {
        "name": "Grau"
      },
      "parcel": {
        "name": "Sola P3"
      },
      "work": {
        "name": "Deplasare utilaje"
      },
      "work_mashine": {
        "name": "BC-29-CFA"
      }
    },
    {
      "uuid": "621ddcbf537b6d5ca5ed5c33",
      "work_started_at": "2022-02-27 10:43:42",
      "work_ended_at": "2022-02-24 02:38:38",
      "culture": {
        "name": "Soia"
      },
      "parcel": {
        "name": "Sola P3"
      },
      "work": {
        "name": "Recoltat"
      },
      "work_mashine": {
        "name": "BC-109-Filipesti"
      }
    },
    {
      "uuid": "621ddcbf77f7644d5a4d07e5",
      "work_started_at": "2022-01-05 07:59:00",
      "work_ended_at": "2022-01-26 08:29:54",
      "culture": {
        "name": "Soia"
      },
      "parcel": {
        "name": "Sola P3"
      },
      "work": {
        "name": "Pregatit teren"
      },
      "work_mashine": {
        "name": "BC-109-Filipesti"
      }
    },
    {
      "uuid": "621ddcbfcecf67232a2ad5c5",
      "work_started_at": "2022-01-01 07:23:41",
      "work_ended_at": "2022-02-14 04:05:51",
      "culture": {
        "name": "Grau"
      },
      "parcel": {
        "name": "Sola P1"
      },
      "work": {
        "name": "Tratament"
      },
      "work_mashine": {
        "name": "VN-106-Garoafa"
      }
    },
    {
      "uuid": "621ddcbf406f7b33e46f2e31",
      "work_started_at": "2022-02-15 02:33:53",
      "work_ended_at": "2022-02-06 03:06:55",
      "culture": {
        "name": "Floarea Soarelui"
      },
      "parcel": {
        "name": "Sola P3"
      },
      "work": {
        "name": "Tratament"
      },
      "work_mashine": {
        "name": "BC-29-CFA"
      }
    },
    {
      "uuid": "621ddcbff03e3354f59d18cf",
      "work_started_at": "2022-01-20 09:31:05",
      "work_ended_at": "2022-02-08 12:50:17",
      "culture": {
        "name": "Rapita"
      },
      "parcel": {
        "name": "Sola P1"
      },
      "work": {
        "name": "Tratament"
      },
      "work_mashine": {
        "name": "VN-106-Garoafa"
      }
    },
    {
      "uuid": "621ddcbfbb4aac4b806b5c71",
      "work_started_at": "2022-01-15 01:34:11",
      "work_ended_at": "2022-02-10 11:44:41",
      "culture": {
        "name": "Floarea Soarelui"
      },
      "parcel": {
        "name": "Sola P2"
      },
      "work": {
        "name": "Pregatit teren"
      },
      "work_mashine": {
        "name": "VN-106-Garoafa"
      }
    },
    {
      "uuid": "621ddcbf3b9a236f030a3ff1",
      "work_started_at": "2022-01-10 12:16:50",
      "work_ended_at": "2022-01-25 06:29:26",
      "culture": {
        "name": "Floarea Soarelui"
      },
      "parcel": {
        "name": "Sola P3"
      },
      "work": {
        "name": "Pregatit teren"
      },
      "work_mashine": {
        "name": "BC-29-CFA"
      }
    },
    {
      "uuid": "621ddcbf5e7f0a9864a50e6e",
      "work_started_at": "2022-01-13 11:57:10",
      "work_ended_at": "2022-02-22 07:31:06",
      "culture": {
        "name": "Floarea Soarelui"
      },
      "parcel": {
        "name": "Sola P5"
      },
      "work": {
        "name": "Pregatit teren"
      },
      "work_mashine": {
        "name": "VN-106-Garoafa"
      }
    },
    {
      "uuid": "621ddcbfbe37bc46b44d2d77",
      "work_started_at": "2022-01-06 04:08:09",
      "work_ended_at": "2022-01-23 09:44:43",
      "culture": {
        "name": "Floarea Soarelui"
      },
      "parcel": {
        "name": "Sola P5"
      },
      "work": {
        "name": "Tratament"
      },
      "work_mashine": {
        "name": "BC-29-CFA"
      }
    },
    {
      "uuid": "621ddcbf480300bf6f9e8cd0",
      "work_started_at": "2022-01-28 12:21:18",
      "work_ended_at": "2022-02-01 05:36:45",
      "culture": {
        "name": "Grau"
      },
      "parcel": {
        "name": "Sola P3"
      },
      "work": {
        "name": "Pregatit teren"
      },
      "work_mashine": {
        "name": "BC-109-Filipesti"
      }
    },
    {
      "uuid": "621ddcbf221e9ffb0ba1c508",
      "work_started_at": "2022-01-28 11:08:59",
      "work_ended_at": "2022-02-06 03:43:11",
      "culture": {
        "name": "Sfecla de zahar"
      },
      "parcel": {
        "name": "Sola P5"
      },
      "work": {
        "name": "Tratament"
      },
      "work_mashine": {
        "name": "VN-106-Garoafa"
      }
    },
    {
      "uuid": "621ddcbf524e6d4f2478320e",
      "work_started_at": "2022-01-12 12:10:04",
      "work_ended_at": "2022-02-13 01:58:01",
      "culture": {
        "name": "Floarea Soarelui"
      },
      "parcel": {
        "name": "Sola P4"
      },
      "work": {
        "name": "Tratament"
      },
      "work_mashine": {
        "name": "BC-29-CFA"
      }
    },
    {
      "uuid": "621ddcbfd911789bb27990b4",
      "work_started_at": "2022-02-01 01:27:51",
      "work_ended_at": "2022-02-15 10:08:36",
      "culture": {
        "name": "Soia"
      },
      "parcel": {
        "name": "Sola P3"
      },
      "work": {
        "name": "Tratament"
      },
      "work_mashine": {
        "name": "VN-106-Garoafa"
      }
    },
    {
      "uuid": "621ddcbf01683d2989b6c69f",
      "work_started_at": "2022-02-05 02:59:36",
      "work_ended_at": "2022-01-28 12:41:55",
      "culture": {
        "name": "Soia"
      },
      "parcel": {
        "name": "Sola P5"
      },
      "work": {
        "name": "Pregatit teren"
      },
      "work_mashine": {
        "name": "BC-29-CFA"
      }
    },
    {
      "uuid": "621ddcbf62571905bc186467",
      "work_started_at": "2022-02-01 03:54:49",
      "work_ended_at": "2022-02-15 07:47:00",
      "culture": {
        "name": "Rapita"
      },
      "parcel": {
        "name": "Sola P5"
      },
      "work": {
        "name": "Tratament"
      },
      "work_mashine": {
        "name": "BC-29-CFA"
      }
    },
    {
      "uuid": "621ddcbf0be5c2869a9f8acf",
      "work_started_at": "2022-02-10 09:07:56",
      "work_ended_at": "2022-01-13 11:27:03",
      "culture": {
        "name": "Porumb"
      },
      "parcel": {
        "name": "Sola P5"
      },
      "work": {
        "name": "Tratament"
      },
      "work_mashine": {
        "name": "VN-106-Garoafa"
      }
    },
    {
      "uuid": "621ddcbfc710afe0147fc0be",
      "work_started_at": "2022-01-16 04:34:20",
      "work_ended_at": "2022-01-17 01:01:35",
      "culture": {
        "name": "Rapita"
      },
      "parcel": {
        "name": "Sola P4"
      },
      "work": {
        "name": "Recoltat"
      },
      "work_mashine": {
        "name": "VN-106-Garoafa"
      }
    },
    {
      "uuid": "621ddcbfe846ecb45b4703c6",
      "work_started_at": "2022-01-03 04:10:44",
      "work_ended_at": "2022-01-05 03:33:52",
      "culture": {
        "name": "Soia"
      },
      "parcel": {
        "name": "Sola P1"
      },
      "work": {
        "name": "Tratament"
      },
      "work_mashine": {
        "name": "BC-29-CFA"
      }
    },
    {
      "uuid": "621ddcbfdf7b973c8092e61c",
      "work_started_at": "2022-01-05 11:04:59",
      "work_ended_at": "2022-01-04 04:22:36",
      "culture": {
        "name": "Soia"
      },
      "parcel": {
        "name": "Sola P4"
      },
      "work": {
        "name": "Pregatit teren"
      },
      "work_mashine": {
        "name": "BC-109-Filipesti"
      }
    },
    {
      "uuid": "621ddcbf2fb0d54595c3da21",
      "work_started_at": "2022-01-30 06:10:41",
      "work_ended_at": "2022-02-14 02:04:33",
      "culture": {
        "name": "Floarea Soarelui"
      },
      "parcel": {
        "name": "Sola P3"
      },
      "work": {
        "name": "Tratament"
      },
      "work_mashine": {
        "name": "BC-29-CFA"
      }
    },
    {
      "uuid": "621ddcbf12f9af0c05be4348",
      "work_started_at": "2022-02-21 04:13:06",
      "work_ended_at": "2022-01-05 03:57:46",
      "culture": {
        "name": "Floarea Soarelui"
      },
      "parcel": {
        "name": "Sola P2"
      },
      "work": {
        "name": "Pregatit teren"
      },
      "work_mashine": {
        "name": "BC-109-Filipesti"
      }
    },
    {
      "uuid": "621ddcbf5f6758223927fca5",
      "work_started_at": "2022-02-25 02:03:37",
      "work_ended_at": "2022-01-29 04:17:04",
      "culture": {
        "name": "Sfecla de zahar"
      },
      "parcel": {
        "name": "Sola P1"
      },
      "work": {
        "name": "Deplasare utilaje"
      },
      "work_mashine": {
        "name": "BC-29-CFA"
      }
    },
    {
      "uuid": "621ddcbff6d782f187f4416b",
      "work_started_at": "2022-01-07 03:53:11",
      "work_ended_at": "2022-01-24 01:55:06",
      "culture": {
        "name": "Soia"
      },
      "parcel": {
        "name": "Sola P2"
      },
      "work": {
        "name": "Deplasare utilaje"
      },
      "work_mashine": {
        "name": "BC-109-Filipesti"
      }
    },
    {
      "uuid": "621ddcbf2e356bfae3556d59",
      "work_started_at": "2022-02-10 10:30:14",
      "work_ended_at": "2022-01-13 07:20:30",
      "culture": {
        "name": "Porumb"
      },
      "parcel": {
        "name": "Sola P4"
      },
      "work": {
        "name": "Recoltat"
      },
      "work_mashine": {
        "name": "BC-29-CFA"
      }
    },
    {
      "uuid": "621ddcbfc22e7391bd8f4262",
      "work_started_at": "2022-02-02 05:41:16",
      "work_ended_at": "2022-01-06 06:05:43",
      "culture": {
        "name": "Porumb"
      },
      "parcel": {
        "name": "Sola P3"
      },
      "work": {
        "name": "Pregatit teren"
      },
      "work_mashine": {
        "name": "VN-106-Garoafa"
      }
    },
    {
      "uuid": "621ddcbf211696037583d732",
      "work_started_at": "2022-02-15 02:24:20",
      "work_ended_at": "2022-01-26 03:08:20",
      "culture": {
        "name": "Sfecla de zahar"
      },
      "parcel": {
        "name": "Sola P3"
      },
      "work": {
        "name": "Pregatit teren"
      },
      "work_mashine": {
        "name": "BC-29-CFA"
      }
    },
    {
      "uuid": "621ddcbf535d37097e9d0fd4",
      "work_started_at": "2022-01-28 06:25:22",
      "work_ended_at": "2022-01-25 01:23:50",
      "culture": {
        "name": "Rapita"
      },
      "parcel": {
        "name": "Sola P5"
      },
      "work": {
        "name": "Recoltat"
      },
      "work_mashine": {
        "name": "BC-109-Filipesti"
      }
    },
    {
      "uuid": "621ddcbf61092ec68e146f44",
      "work_started_at": "2022-01-16 03:05:25",
      "work_ended_at": "2022-01-15 09:28:41",
      "culture": {
        "name": "Porumb"
      },
      "parcel": {
        "name": "Sola P2"
      },
      "work": {
        "name": "Recoltat"
      },
      "work_mashine": {
        "name": "VN-106-Garoafa"
      }
    },
    {
      "uuid": "621ddcbf3327bbd7655416c8",
      "work_started_at": "2022-01-10 02:40:29",
      "work_ended_at": "2022-02-21 12:52:47",
      "culture": {
        "name": "Sfecla de zahar"
      },
      "parcel": {
        "name": "Sola P3"
      },
      "work": {
        "name": "Tratament"
      },
      "work_mashine": {
        "name": "BC-109-Filipesti"
      }
    },
    {
      "uuid": "621ddcbfe8269f1781078467",
      "work_started_at": "2022-02-07 05:06:44",
      "work_ended_at": "2022-01-07 08:36:43",
      "culture": {
        "name": "Grau"
      },
      "parcel": {
        "name": "Sola P2"
      },
      "work": {
        "name": "Pregatit teren"
      },
      "work_mashine": {
        "name": "VN-106-Garoafa"
      }
    },
    {
      "uuid": "621ddcbf4ed7f0ba5044dc03",
      "work_started_at": "2022-01-31 02:17:29",
      "work_ended_at": "2022-01-05 05:07:37",
      "culture": {
        "name": "Porumb"
      },
      "parcel": {
        "name": "Sola P5"
      },
      "work": {
        "name": "Deplasare utilaje"
      },
      "work_mashine": {
        "name": "BC-109-Filipesti"
      }
    },
    {
      "uuid": "621ddcbfb2bf0b5539f70d4d",
      "work_started_at": "2022-02-04 10:56:51",
      "work_ended_at": "2022-02-28 06:20:57",
      "culture": {
        "name": "Rapita"
      },
      "parcel": {
        "name": "Sola P3"
      },
      "work": {
        "name": "Recoltat"
      },
      "work_mashine": {
        "name": "VN-106-Garoafa"
      }
    },
    {
      "uuid": "621ddcbf6004bc8c0f9cfd5f",
      "work_started_at": "2022-02-01 05:37:18",
      "work_ended_at": "2022-01-04 04:06:36",
      "culture": {
        "name": "Rapita"
      },
      "parcel": {
        "name": "Sola P2"
      },
      "work": {
        "name": "Deplasare utilaje"
      },
      "work_mashine": {
        "name": "BC-109-Filipesti"
      }
    },
    {
      "uuid": "621ddcbf71dfc6fd85d28b90",
      "work_started_at": "2022-01-06 11:07:29",
      "work_ended_at": "2022-02-02 03:20:32",
      "culture": {
        "name": "Rapita"
      },
      "parcel": {
        "name": "Sola P5"
      },
      "work": {
        "name": "Tratament"
      },
      "work_mashine": {
        "name": "BC-109-Filipesti"
      }
    },
    {
      "uuid": "621ddcbfbfb027ab362d76d9",
      "work_started_at": "2022-01-06 10:06:01",
      "work_ended_at": "2022-02-08 07:22:08",
      "culture": {
        "name": "Rapita"
      },
      "parcel": {
        "name": "Sola P1"
      },
      "work": {
        "name": "Tratament"
      },
      "work_mashine": {
        "name": "BC-29-CFA"
      }
    },
    {
      "uuid": "621ddcbfbcb0e0fba22f17f3",
      "work_started_at": "2022-01-10 05:35:48",
      "work_ended_at": "2022-02-04 09:22:01",
      "culture": {
        "name": "Sfecla de zahar"
      },
      "parcel": {
        "name": "Sola P5"
      },
      "work": {
        "name": "Tratament"
      },
      "work_mashine": {
        "name": "VN-106-Garoafa"
      }
    },
    {
      "uuid": "621ddcbfabe08af75caf5a27",
      "work_started_at": "2022-01-16 05:05:00",
      "work_ended_at": "2022-02-28 09:12:12",
      "culture": {
        "name": "Grau"
      },
      "parcel": {
        "name": "Sola P3"
      },
      "work": {
        "name": "Recoltat"
      },
      "work_mashine": {
        "name": "BC-109-Filipesti"
      }
    },
    {
      "uuid": "621ddcbf0afe52ed1cefb1e0",
      "work_started_at": "2022-02-04 02:16:47",
      "work_ended_at": "2022-01-09 06:39:30",
      "culture": {
        "name": "Grau"
      },
      "parcel": {
        "name": "Sola P5"
      },
      "work": {
        "name": "Recoltat"
      },
      "work_mashine": {
        "name": "BC-29-CFA"
      }
    },
    {
      "uuid": "621ddcbf8265210266308fd5",
      "work_started_at": "2022-01-23 03:20:44",
      "work_ended_at": "2022-02-19 12:46:56",
      "culture": {
        "name": "Sfecla de zahar"
      },
      "parcel": {
        "name": "Sola P1"
      },
      "work": {
        "name": "Tratament"
      },
      "work_mashine": {
        "name": "BC-109-Filipesti"
      }
    },
    {
      "uuid": "621ddcbffcd6d5df9faba5c1",
      "work_started_at": "2022-02-18 02:21:02",
      "work_ended_at": "2022-01-25 10:42:13",
      "culture": {
        "name": "Sfecla de zahar"
      },
      "parcel": {
        "name": "Sola P5"
      },
      "work": {
        "name": "Recoltat"
      },
      "work_mashine": {
        "name": "VN-106-Garoafa"
      }
    }
  ];

  return returnData;
}

export function getTestWorks() {
  return [
    {
      name: "Recoltat"
    },
    {
      name: "Tratament"
    },
    {
      name: "Pregatit teren"
    },
    {
      name: "Deplasare utilaje"
    }
  ];
}

export function getTestMachines() {
  return [
    {
      name: "BC-109-Filipesti"
    },
    {
      name: "BC-29-CFA"
    },
    {
      name: "VN-106-Garoafa"
    },
    {
      name: "none"
    }
  ];
}

export function getTestBatchApplys() {
  return [
    {
      id: 1,
      fito: {
        id: 1,
        name: "fito 1"
      },
      quantity: 10,
      mu: "kg"
    },
    {
      id: 2,
      fito: {
        id: 2,
        name: "fito 2"
      },
      quantity: 10,
      mu: "l"
    },
    {
      id: 3,
      fito: {
        id: 3,
        name: "fito 3"
      },
      quantity: 10,
      mu: "kg"
    },
    {
      id: 4,
      fito: {
        id: 4,
        name: "fito 4"
      },
      quantity: 10,
      mu: "kg"
    }
  ];
}

export function getTestFitos() {
  return [
    {
      id: 1,
      name: "fito 1"
    },
    {
      id: 2,
      name: "fito 2"
    },
    {
      id: 3,
      name: "fito 3"
    },
    {
      id: 4,
      name: "fito 4"
    }
  ];
}

export function getTestDepartments() {
  return [
    {
      id: 1,
      name: "Department 1"
    },
    {
      id: 2,
      name: "Department 2"
    },
    {
      id: 3,
      name: "Department 4"
    },
    {
      id: 4,
      name: "Department 4"
    },
    {
      id: 5,
      parent_id: 1,
      name: "Child 1"
    },
    {
      id: 6,
      parent_id: 2,
      name: "Child 1"
    }
  ];
}