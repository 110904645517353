<template>
  <div class="validator-holder" v-if="true">
    <card
      :title="$t('tools.import_validator.title')"
      :title-bottom-padding="'10px'"
      id="import-validator"
    >
      <DxDataGrid
        id="gridContainer"
        ref="dataGrid"
        keyExpr="uuid"
        :data-source="gridData"
        :columns="gridColumns"
        :show-column-lines="true"
        :show-row-lines="true"
        :show-borders="true"
        :filter-row="{ visible: true, applyFilter: 'auto' }"
        :selection="{ mode: 'multiple', showCheckBoxesMode: 'always' }"
        :column-min-width="50"
        :selected-row-keys="selectedRowKeys"
        :allow-column-reordering="true"
        :column-auto-width="true"
        :repaint-changes-only="true"
        :focused-row-enabled="true"
        @selection-changed="onSelectionChanged"
        @saving="onSaving"
        @exporting="onExporting"
        @row-prepared="onRowPrepared"
        @editor-preparing="onEditorPreparing"
        @option-changed="onOptionChanged"
        @initialized="onInitialized"
        @content-ready="onContentReady"
        @focused-row-changing="onFocusedRowChanging"
        @row-validating="onRowValidating"
        :height="750"
      >
        <DxHeaderFilter :visible="true" />
        <DxPaging :enabled="true" :pageSize="100" />
        <DxPager
          :visible="true"
          :showPageSizeSelector="true"
          :allowedPageSizes="[23, 100, 500]"
          :showInfo="true"
        />
        <DxSearchPanel :visible="true" :width="240" />
        <DxSelection
          select-all-mode="allMode"
          show-check-boxes-mode="always"
          mode="multiple"
        />
        <DxEditing
          :allow-updating="true"
          :allow-adding="false"
          :allow-deleting="true"
          :select-text-on-edit-start="true"
          :start-edit-action="'click'"
          mode="batch"
        />
        <DxLoadPanel
          :position="position"
          v-model:visible="isLoading"
          :show-indicator="true"
          :show-pane="true"
          :shading="true"
          :hide-on-outside-click="false"
          :on-shown="onShownLoadPanel"
          :on-hidden="onHiddenLoadPanel"
          shading-color="rgba(0,0,0,0.4)"
          message="Updating..."
        />
        <DxScrolling column-rendering-mode="virtual" />
        <DxGroupPanel :visible="true" />
        <DxExport
          :enabled="true"
          :allow-export-selected-data="true"
          :fileName="'ValidatorDataGridExport'"
        />
        <DxColumnChooser 
          :enabled="true" 
          mode="select"
        />
        <DxColumnFixing :enabled="true" />
        <DxToolbar>
          <DxItem name="groupPanel" />
          <DxItem name="columnChooserButton" />
          <DxItem name="exportButton" />
          <DxItem name="revertButton" />
          <DxItem name="saveButton" />
          <DxItem name="searchPanel" />
          <DxItem
            location="before"
            widget="dxButton"
            :disabled="false"
            :options="{
              icon: 'trash',
              text: 'Delete selected rows (' + selectedCount + ')',
              onClick: confirmDelete,
              height: 30,
            }"
          />
          <DxItem
            location="before"
            widget="dxButton"
            :disabled="false"
            :options="{
              icon: 'edit',
              text: 'Edit selected rows (' + selectedCount + ')',
              onClick: onClickShowEditModal,
              height: 30,
            }"
          />
          <DxItem
            location="before"
            widget="dxButton"
            :disabled="false"
            :options="{
              icon: 'clear',
              text: 'Clear Selection (' + selectedCount + ')',
              onClick: clearSelection,
              height: 30,
            }"
          />
        </DxToolbar>
      </DxDataGrid>
      <div
        class="row"
        style="
          width: 250px;
          margin-top: 35px;
          margin-left: 15px;
          margin-bottom: 15px;
        "
      >
        <button class="btn-default" @click="goBack">
          {{ $t("back_to_list") }}
        </button>
      </div>
    </card>
    <DeleteConfirmationDialog
      :show="showDeleteConfirmationModal"
      @close="showDeleteConfirmationModal = false"
      @confirm="deleteSelectedRows"
      :title="$t('tools.import_validator.title')"
      :message="$t('tools.import_validator.delete_from_validated_data')"
    ></DeleteConfirmationDialog>
    <MultipleRowsEditDialog
      :show="showEditModal"
      @close="showEditModal = false"
      @confirm="editSelectedRows"
      :departments="this.getCellDepartments()"
      :seasons="this.getCellSeasons()"
      :work_process="this.getUniqueWorkProcess()"
      :person="this.getUniquePerson()"
      :batch_measure_units="this.getCellBatchMeasureUnits()"
      :title="$t('tools.import_validator.title')"
    >
    </MultipleRowsEditDialog>
    <CreateParcel
      :isModal="true"
      :show="showParcelDetails"
      :defaultNewValues="defaultValuesForNewParcel"
      @close="showParcelDetails = false"
      @newParcelCreated="newParcelCreatedResult"
    ></CreateParcel>
  </div>
  <div v-else>
    <forbidden></forbidden>
  </div>
</template>

<script>
import {
  DxDataGrid,
  DxScrolling,
  DxLoadPanel,
  DxDataGridTypes,
  DxToolbar,
  DxItem,
  DxPaging,
  DxPager,
  DxSearchPanel,
  DxEditing,
  DxHeaderFilter,
  DxGroupPanel,
  DxExport,
  DxColumnChooser,
  DxColumnFixing,
  DxSelection,
} from "devextreme-vue/data-grid";

import { DxButton } from "devextreme-vue/button";
import { successMessage, errorMessage, errorMessageList } from "~/helpers/common";
import { getValidatorGridColumns } from "./gridColumns";
import { mapActions, mapGetters } from "vuex";
import { format } from "date-fns";
import DeleteConfirmationDialog from "@/components/Modals/DeleteConfirmationDialog";
import MultipleRowsEditDialog from "./MultipleRowsEditDialog";
import CreateParcel from "~/pages/works/parcels/_id.vue";

export default {
  name: "ImportValidator",
  components: {
    DxDataGrid,
    DxScrolling,
    DxLoadPanel,
    DxDataGridTypes,
    DxToolbar,
    DxItem,
    DxButton,
    DxPaging,
    DxPager,
    DxSearchPanel,
    DxEditing,
    DxHeaderFilter,
    DxGroupPanel,
    DxExport,
    DxColumnChooser,
    DxColumnFixing,
    DxSelection,
    DeleteConfirmationDialog,
    MultipleRowsEditDialog,
    CreateParcel,
  },
  data() {
    return {
      selectedRowKeys: [],
      selectedCount: 0,
      isLoading: false,
      gridData: this.importValidatedData ? this.importValidatedData : [],
      showDeleteConfirmationModal: false,
      showEditModal: false,
      gridColumns: [],
      position: { of: '#import-validator' },
      batchMeasureUnits: [],
      showParcelDetails: false,
      defaultValuesForNewParcel: {},
      selectedRowData: {},
      visibilitySettingsLoaded: false,
      visibilitySettingsLoading: false,
      saveColumnVisibilityTimeout: null,
      parcelFilters: {},
    };
  },
  computed: {
    ...mapGetters({
      importValidatedData: "importConfig/importValidatedData",
      seasons: "seasons/seasons",
      departments: "department/departments",
      dxGridHiddenColumns: "dxgrid/dxGridHiddenColumns",
      importValidatedDataSettings: "importConfig/importValidatedDataSettings",
    }),
    dataGridInstance: function () {
      return this.$refs.dataGrid.instance;
    },
  },
  methods: {
    ...mapActions({
      getImportValidatedData: "importConfig/getImportValidatedData",
      updateImportValidatedData: "importConfig/updateImportValidatedData",
      getSeasons: "seasons/getSeasons",
      getDepartments: "department/getDepartments",
      getBatchMeasureUnits: "batch/getBatchMeasureUnits",
      getParcels: 'works-parcels/getParcels',
      getDxGridHiddenColumns: "dxgrid/getDxGridHiddenColumns",
      setDxGridHiddenColumns: "dxgrid/setDxGridHiddenColumns",
      getImportValidatedDataSettings: 'importConfig/getImportValidatedDataSettings',
    }),
    async loadImportValidatedData(data_import_id) {
      this.getImportValidatedData({ data_import_id: data_import_id });
    },
    getGridColumns() {
      const columns = getValidatorGridColumns(
        this.startCreateParcel,
        this.getLookupDataSource,
        this.importValidatedDataSettings['parcel_full_identification'] ?? 0
      );
      columns.map((column) => {
        if (column.caption) {
          column.caption = this.$t("tools.import_validator." + column.caption);
        }
        return column;
      });

      return columns;
    },
    formatDateColumns(changes) {
      changes.forEach((change) => {
        if (change.data) {
          if (change.data.start_date) {
            change.data.start_date = format(
              new Date(change.data.start_date),
              "yyyy-MM-dd HH:mm:ss"
            );
          }
          if (change.data.end_date) {
            change.data.end_date = format(
              new Date(change.data.end_date),
              "yyyy-MM-dd HH:mm:ss"
            );
          }
        }
      });
    },
    async updateImportValidatedDataWithCallback(changes) {
      try {
        const response = await this.updateImportValidatedData({ changes });
        successMessage(this, response.message);
        return true;
      } catch (e) {
        errorMessageList(this, e.errors);
        return false;
      }
    },
    onSaving(e) {
      if (e.changes.length) {
        this.formatDateColumns(e.changes);
        this.updateImportValidatedDataWithCallback(e.changes).then((result) => {
            if (result) {
              e.component.refresh();
            }
        });
      }
    },
    async confirmDelete() {
      if (this.selectedRowKeys?.length > 0) {
        this.showDeleteConfirmationModal = true;
      }
    },
    async deleteSelectedRows() {
      this.showDeleteConfirmationModal = false;
      if (this.selectedRowKeys.length > 0) {
        const changes = [];
        this.selectedRowKeys.forEach((key) => {
          changes.push({ type: "remove", key: key });
        });

        this.updateImportValidatedDataWithCallback(changes).then((result) => {
          if (result) {
            if (this.dataGridInstance) {
              const dataSource = this.dataGridInstance.getDataSource();
              if (dataSource) {
                const store = dataSource.store();
                if (store) {
                  for (const key of this.selectedRowKeys) {
                    try {
                      store.remove(key);
                    } catch (error) {
                      console.error(
                        `Failed to remove item with key ${key}:`,
                        error
                      );
                    }
                  }
                }
                dataSource.reload();
              }
            }
            this.clearSelection();
          }
        });
      }
    },
    onSelectionChanged(e) {
      this.selectedRowKeys = e.selectedRowKeys;
      this.selectedCount = e.selectedRowKeys.length;
    },
    onExporting(e) {
      e.fileName =
        "SGPS_DASC_DataGridExport_" + format(new Date(), "yyyy_MM_dd_hh_mm_ss");
      //const dataRows = e.component.getDataSource().items();
      //e.cancel = true; // Prevent default export
    },
    onRowPrepared(event) {
      if (event.rowType === 'data') {
        const rowData = event.data;
        let backgroundColor;
        if (rowData.status && rowData.status.color) {
          event.rowElement.style.backgroundColor = rowData.status.color;
          backgroundColor = rowData.status.color;
        }
        if(backgroundColor) {
          event.rowElement.style.backgroundColor = backgroundColor;
          event.rowElement.style.color = this.getContrastingTextColor(backgroundColor);
        }
      }
    },
    onEditorPreparing(e) {
      if(this.importValidatedDataSettings['parcel_full_identification'] ?? 0)
      {
          if (e.row && e.row.data && e.dataField === "parcel_name") {
            this.setNewDataSourceBasedOnSelectionForParcel(e.row.data);
          }

          const parcel_fields = ['season_uuid', 'surface', 'culture', 'department_uuid'];
          if(e.row && e.row.data && parcel_fields.includes(e.dataField) && e.row.data.parcel_uuid){
            e.editorOptions.disabled = true;
            errorMessage(this, 'Parcel is already created for this row.');
          }
      }
    },
    onOptionChanged(e) {
      if (e.fullName.startsWith('columns[') && e.fullName.endsWith('].visible') && !this.visibilitySettingsLoading) {
        clearTimeout(this.saveColumnVisibilityTimeout);
        this.saveColumnVisibilityTimeout = setTimeout(() => {
          this.saveColumnVisibilitySettings();
        }, 3000);
      }
    },
    onContentReady(e) {
      if (!this.visibilitySettingsLoaded) {
        this.visibilitySettingsLoading = true;
        setTimeout(() => {
          this.loadColumnVisibilitySettings();
        }, 1000);
        this.visibilitySettingsLoaded = true;
      }
    },
    onFocusedRowChanging(e) {
    },
    onInitialized(e) {
    },
    clearSelection() {
      this.selectedRowKeys = [];
    },
    saveColumnVisibilitySettings() {
      if(this.visibilitySettingsLoading)
        return;

      if (this.dataGridInstance) {
        const allColumns = this.dataGridInstance.getController('columns').getColumns();

        const hiddenColumns = allColumns
          .filter(column => !column.visible)
          .map(column => ( column.dataField ));

        this.setDxGridHiddenColumns({
          grid_identifier: 'import_validator',
          hidden_columns: JSON.stringify(hiddenColumns)
        });
      }
    },
    loadColumnVisibilitySettings() {
      this.getDxGridHiddenColumns({
        grid_identifier: 'import_validator'
        }).then((result) => {          
          if (result && result[0]?.hidden_columns) {
            this.dataGridInstance.beginUpdate();
            result[0].hidden_columns.forEach(dataField => {
              this.dataGridInstance.columnOption(dataField, 'visible', false);
            });
            this.dataGridInstance.endUpdate();
            this.dataGridInstance.refresh();
          }
          this.visibilitySettingsLoading = false;
        });
    },
    startCreateParcel(e_row_data) {
      if(e_row_data.parcel_uuid) {
        this.selectedRowData = null;
        return;
      }

      this.defaultValuesForNewParcel = {
        department_uuid: e_row_data.department_uuid,
        season_uuid: e_row_data.season_uuid,
        name: e_row_data.parcel_name,
        surface: parseFloat(e_row_data.surface ?? 0),
        culture: e_row_data.culture,
      };

      this.selectedRowData = e_row_data;
      this.showParcelDetails = true;
    },
    newParcelCreatedResult(parcel) {
      const rowIndex = this.dataGridInstance.getRowIndexByKey(this.selectedRowData.uuid);
      if (rowIndex !== -1 && this.dataGridInstance) {
        this.dataGridInstance.beginUpdate();
        this.dataGridInstance.cellValue(rowIndex, 'parcel_name', parcel.name);
        this.dataGridInstance.cellValue(rowIndex, 'parcel_uuid', parcel.uuid);
        this.dataGridInstance.cellValue(rowIndex, 'culture', parcel.culture);
        this.dataGridInstance.cellValue(rowIndex, 'surface', parcel.surface);
        this.dataGridInstance.cellValue(rowIndex, 'department_uuid', parcel.department_uuid);
        this.dataGridInstance.cellValue(rowIndex, 'season_uuid', parcel.season_uuid);

        const parcelName = parcel.name.toLowerCase();
        const rowsToUpdate = this.gridData.filter(row => {
          if (row.parcel_name && parcelName && (row.parcel_uuid == null || row.parcel_uuid == '')) {
            const rowParcelName = row.parcel_name.toLowerCase();
            if (rowParcelName == parcelName || rowParcelName.localeCompare(parcelName) === 0) {
              return true;
            }
          }
          return false;
        });
        rowsToUpdate.forEach(row => {
          const rowIndex = this.dataGridInstance.getRowIndexByKey(row.uuid);
          if (rowIndex !== -1) {
            this.dataGridInstance.cellValue(rowIndex, 'parcel_name', parcel.name);
            this.dataGridInstance.cellValue(rowIndex, 'parcel_uuid', parcel.uuid);
            this.dataGridInstance.cellValue(rowIndex, 'culture', parcel.culture);
            this.dataGridInstance.cellValue(rowIndex, 'surface', parcel.surface);
            this.dataGridInstance.cellValue(rowIndex, 'department_uuid', parcel.department_uuid);
            this.dataGridInstance.cellValue(rowIndex, 'season_uuid', parcel.season_uuid);
          }
        });

        this.dataGridInstance.endUpdate();
        this.dataGridInstance.refresh();
      }

      this.showParcelDetails = false;
    },
    goBack() {
      this.$router.back();
    },
    getCellDepartments() {
      return (
        this.departments
          /*.filter(department => department.type === 'company')*/
          .map((value) => ({
            department: value.name,
            department_uuid: value.uuid,
          }))
      );
    },
    getCellSeasons() {
      return this.seasons.map((value) => ({
        season: value.name,
        season_uuid: value.uuid,
      }));
    },
    getCellBatchMeasureUnits() {
      return this.batchMeasureUnits.map((value) => ({
        product_measure_unit: value.name,
        product_measure_unit_uuid: value.uuid,
      }));
    },
    getUniqueWorkProcess() {
      const work_process = [...new Set(this.gridData.map((value) => value.work_process))];
      return work_process.map((process, index) => ({
        value: index + 1,
        label: process,
      }));
    },
    getUniquePerson() {
      const person = [...new Set(this.gridData.map((value) => value.person))];
      return person.map((person, index) => ({
        value: index + 1,
        label: person,
      }));
    },
    getUniqueParcelNames() {
      const parcel_names = [...new Set(this.gridData.map((value) => value.parcel_name))];
      return parcel_names.map((parcel_name, index) => ({
        value: parcel_name,
        text: parcel_name,
      }));
    },
    onClickShowEditModal() {
      if (this.dataGridInstance.getSelectedRowKeys().length > 0) {
        this.showEditModal = true;
      }
    },
    editSelectedRows(department_uuid, season_uuid, work_process, person, product_measure_unit_uuid) {
      this.showEditModal = false;

      const selectedRowKeys = this.dataGridInstance.getSelectedRowKeys();
      const dataSource = this.dataGridInstance.getDataSource();

      if (
        selectedRowKeys.length === 0 ||
        !dataSource ||
        (!department_uuid && !season_uuid && !work_process && !person && !product_measure_unit_uuid)
      ) {
        return;
      }

      const store = dataSource.store();
      if (!store) {
        return;
      }

      const changes = [];
      selectedRowKeys.map((key) => {
        let updateItemData = {};
        if (department_uuid) {
          updateItemData.department_uuid = department_uuid;
        }
        if (season_uuid) {
          updateItemData.season_uuid = season_uuid;
        }
        if (work_process) {
          updateItemData.work_process = work_process;
        }
        if (person) {
          updateItemData.person = person;
        }
        if (product_measure_unit_uuid) {
          updateItemData.product_measure_unit_uuid = product_measure_unit_uuid;
        }
        changes.push({ type: "update", key: key, data: updateItemData });
      });

      this.onShownLoadPanel();

      this.updateImportValidatedDataWithCallback(changes).then((result) => {
        if (result) {
          // Update the data in bulk using the store
          const updatePromises = selectedRowKeys.map((key) => {
            return store.byKey(key).then((rowData) => {
              if (rowData) {
                if (department_uuid) {
                  rowData.department_uuid = department_uuid;
                }
                if (season_uuid) {
                  rowData.season_uuid = season_uuid;
                }
                if (work_process) {
                  rowData.work_process = work_process;
                }
                if (person) {
                  rowData.person = person;
                }
                if (product_measure_unit_uuid) {
                  rowData.product_measure_unit_uuid = product_measure_unit_uuid;
                }
                return store.update(key, rowData);
              }
            });
          });

          // Wait for all updates to complete
          Promise.all(updatePromises)
            .then(() => {
              // Refresh the DataGrid to reflect the changes
              this.dataGridInstance.refresh();
            })
            .catch((error) => {
              console.error("Error updating rows:", error);
            })
            .finally(() => {
              this.onHiddenLoadPanel();
            });
        }
      });
    },
    setNewDataSourceBasedOnSelectionForParcel(selectedRowsData) {
      if (this.parcelFilters.season_uuid == selectedRowsData.season_uuid && 
          this.parcelFilters.department_uuid == selectedRowsData.department_uuid) {
           this.updateLookupDataSource("parcel_name", this.getFilteredPercelsByName(this.getLookupDataSource("parcel_name") ?? [], selectedRowsData.parcel_name));
        return;
      }

      this.parcelFilters.season_uuid = selectedRowsData.season_uuid;
      this.parcelFilters.department_uuid = selectedRowsData.department_uuid;
      this.parcelFilters.limit = 10000;
      this.updateLookupDataSource("parcel_name", []);

      this.getParcels(this.parcelFilters).then((result) => {
        let parcels = result.data;
        if (selectedRowsData.parcel_name && selectedRowsData.parcel_name.trim() !== '') {
          parcels =  this.getFilteredPercelsByName(parcels, selectedRowsData.parcel_name);
        }
        this.updateLookupDataSource("parcel_name", parcels);
      });
    },
    getFilteredPercelsByName(parcels, parcel_name){
      parcel_name = (parcel_name.replace(/(\w+)-\d+,\d+-(.*)/, '$1 $2')).replace(/(\w+)-(.*)/, '$1');
      parcels.map((parcel, key) => {
        if((parcel.name.replace(/(\w+)-\d+,\d+-(.*)/, '$1 $2')).replace(/(\w+)-(.*)/, '$1') == parcel_name){
          parcels[key]['level'] = 0; 
        } else {
          parcels[key]['level'] = 1; 
        }
      });
      return parcels.sort((a, b) => a.level - b.level);
    },
    getContrastingTextColor(backgroundColor) {
      const color = backgroundColor.charAt(0) === '#' ? backgroundColor.substring(1, 7) : backgroundColor;
      const r = parseInt(color.substring(0, 2), 16);
      const g = parseInt(color.substring(2, 4), 16);
      const b = parseInt(color.substring(4, 6), 16);
      const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;
      return luminance > 0.5 ? '#000000' : '#FFFFFF'; // Black text for light backgrounds, white text for dark backgrounds
    },
    getLookupDataSource(dataField) {
      if (this.dataGridInstance) {
        return this.dataGridInstance.columnOption(dataField, "lookup.dataSource");
      }
      return null;
    },
    updateLookupDataSource(datField, newDataSource) {
      if (this.dataGridInstance) {
        this.dataGridInstance.columnOption(datField, "lookup.dataSource", newDataSource);
      }
    },
    async onShownLoadPanel() {
      setTimeout(() => {
        this.isLoading = true;
      }, 10);
    },
    async onHiddenLoadPanel() {
      setTimeout(() => {
        this.isLoading = false;
      }, 10);
    },
    onRowValidating(event) {
      const brokenRules = event.brokenRules;
      if (brokenRules.length > 0) {
          const error_messages = [];
          brokenRules.forEach(rule => {
              if ((rule.type === "required" || rule.type === "custom") && rule.message) {
                if (!error_messages.includes(rule.message)) {
                  error_messages.push(rule.message);
                }
              }
          });

          if (error_messages.length > 0) {
            event.isValid = false;
            errorMessage(this, error_messages.join(", "));
          }
      }
    },
  },
  async mounted() {
    if (this.$route.query.import_id) {
      this.getImportValidatedDataSettings({ data_import_id: this.$route.query.import_id });

      this.getDepartments();
      this.getSeasons();
      this.getBatchMeasureUnits().then(
        (result) => {
          this.batchMeasureUnits = result;
          this.updateLookupDataSource("product_measure_unit_uuid", this.getCellBatchMeasureUnits());
        }
      );
      this.loadImportValidatedData(this.$route.query.import_id);
    }
  },
  watch: {
    importValidatedData: {
      handler: function (newVal, oldVal) {
        this.gridData = newVal;
      },
      deep: true,
    },
    departments: {
      handler: function (newVal, oldVal) {
        this.updateLookupDataSource("department_uuid", this.getCellDepartments());
      },
      deep: true,
    },
    seasons: {
      handler: function (newVal, oldVal) {
        this.updateLookupDataSource("season_uuid", this.getCellSeasons());
      },
      deep: true,
    },
    importValidatedDataSettings: {
      handler: function (newVal, oldVal) {
        this.gridColumns = this.getGridColumns();
      },
      deep: true,
    },
  },
};
</script>

<style>
#gridContainer {
  height: 960px;
}

#import-validator {
  padding: 15px;
}

.btn-default {
  background-color: #eeeded;
  color: #000;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 2px 2px;
  cursor: pointer;
  border: none;
  border-radius: 15px;
  padding: 10px 15px;

  &:hover {
    background-color: rgb(223, 221, 221);
    box-shadow: 0 0 8px rgba(223, 221, 221, 0.3);
  }
}

.selected-data {
  margin-top: 20px;
  padding: 17px;
  background-color: rgba(191, 191, 191, 0.15);
}

​ .selected-data .caption {
  font-weight: bold;
  font-size: 115%;
  margin-right: 4px;
}

.import-action-btn {
  margin-left: 20px;
  margin-top: -6px;
  float: right;
}

.disabled-btn {
  background-color: gray !important;
  cursor: not-allowed;
}

.dx-button-has-icon .dx-button-content {
    padding: 1px;
}

/* Set the row height to 40px */
#gridContainer .dx-datagrid-rowsview .dx-row {
  height: 40px;
}

.add-parcel-button{
  margin-right: 10px;
}

.validator-status-icon {
  font-size: 20px;
  color: #C0C0C0;
}
</style>
