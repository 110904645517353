<template>
  <div>
    <DxPopup
      id="dx-dialog"
      height="60vh"
      :close-on-outside-click="false"
      :show-close-button="true"
      :visible="showPopup"
      @hidden="$emit('hidden')"
    >
      <template #title>
        <div></div>
      </template>
      <template #content>
        <div>
          <div class="container">
            <card title="Selectează un departament">
              <div id="department-selector">
                <div class="justify-content-center">
                  <div
                    class="dep d-inline-block c-pointer p-3 m-2"
                    v-for="department in departments"
                    :key="department.uuid"
                    @click="selectDepartment(department)"
                  >
                    <div class="dep-item">
                      <div class="dep-head">
                        <i
                          class="fas fa-warehouse"
                          :class="{ selected: isCurrentDepartment(department) }"
                        ></i>
                        <p>{{ department.name }}</p>
                      </div>
                      <p class="roles" v-html="getRoles(department)"></p>
                    </div>
                  </div>
                </div>
              </div>
            </card>
          </div>
        </div>
      </template>
    </DxPopup>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import DxPopup from "devextreme-vue/popup";

export default {
  name: "DigitalAgendaDepSelector",
  props: {
    showPopup: {
      type: Boolean,
      default: false,
    },
    departments: {
      type: Array,
      default: [],
    },
    company: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {};
  },
  components: {
    DxPopup,
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
      domain: "auth/domain",
      currentDepartment: "auth/currentDepartment",
    }),
    currentDepartmentUUID() {
      if (this.user && this.currentDepartment) {
        return this.currentDepartment.department_uuid;
      }
    },
  },
  methods: {
    ...mapActions({
      changeDepartment: "auth/changeDepartment",
    }),
    isCurrentDepartment(department) {
      if (department) {
        return this.currentDepartmentUUID === department.uuid;
      }

      return false;
    },
    selectDepartment(dep) {
      let vm = this;
      this.changeDepartment({
        company_uuid: dep.company_uuid,
        company_name: dep.company.name,
        department_uuid: dep.uuid,
        department_name: dep.name,
      }).then((resp) => {
        if (
          resp.default_department &&
          resp.default_department.department_uuid === dep.uuid
        ) {
          this.$emit("hideAndFillList");
        } else {
          errorMessage(vm, vm.$t("error_changing_department"));
        }
      });
    },
    getRoles(department) {
      if (department.users) {
        let found_user = department.users.find(
          (u) => u.user_uuid === this.user.uuid
        );

        if (found_user) {
          if (found_user.is_department_owner == 1) {
            return (
              '<span class="role-name owner">' +
              this.$i18n.t("department_owner") +
              "</span>"
            );
          } else if (found_user.is_department_admin == 1) {
            return (
              '<span class="role-name admin">' +
              this.$i18n.t("department_admin") +
              "</span>"
            );
          } else {
            if (department.roles) {
              let roleIndex = department.roles.map((role, index) => {
                if (role.user_role) {
                  console.log("curr role", role.name);
                  return index;
                }
              });

              console.log("role index:  ", roleIndex);

              if (roleIndex > -1) {
                return (
                  '<span class="role-name current-role">' +
                  department.roles[roleIndex].name +
                  "</span>"
                );
              }
            } else {
              return (
                '<span class="role-name user">' +
                this.$i18n.t("normal_user") +
                "</span>"
              );
            }
          }
        }
      }
    },
  },
};
</script>

<style scoped>
.container {
  min-height: unset !important;
}
</style>