<template>
    <transition name="modal" id="consum-filter-dialog">
        <div>
            <DxPopup
                :height="popupHeight"
                width="100vw"
                :close-on-outside-click="false"
                :show-close-button="true"
                :visible="showPopup"
                position="bottom"
                @hidden="$emit('hidden')"
                :wrapper-attr="popupAttributes"
            >

            <template #title>
                <div class="consum_filter_header">
                <div class="left_side">
                    <div class="left_title">Tip consum</div>
                </div>
                <div class="right_side">
                    <img class="close_button"
                    src="@/assets/images/icons/dialog-close.svg"
                    @click="buttonCloseEvent"
                    />
                </div>
                </div>
            </template>

            <template #content>
                <div class="consum_filter_content">
                    <DxRadioGroup
                        :items="filterActions"
                        v-model="currentAction"
                        :element-attr="radioGroupAttributes"
                    >

                    </DxRadioGroup>
                </div>
            </template>

            </DxPopup>
        </div>
    </transition>
</template>

<script>

import DxPopup from "devextreme-vue/popup";
import DxRadioGroup from "devextreme-vue/radio-group";

export default {
    name: "ConsumFilter",
    props: {
        showPopup: {
            type: Boolean,
            default: false,
        },
        heightDiff: {
            type: Number,
            default: 0,
        },
        filterData: Object
    },
    data(){
        return {
            popupAttributes: {
                class: 'consum-filter-wrapper'
            },
            radioGroupAttributes: {
                class: 'filter_type_selector'
            },
            filterActions: [
                { id: 1, text: "Consum consolidat", code: "consolidate" },
                { id: 2, text: "Produse din agenda digitală", code: "digital_agenda" },
                { id: 3, text: "Produse din Stoc", code: "stock" }
            ],
            currentAction: null
        };
    },
    watch:{
        currentAction(newVal){
            if(newVal != null){
                this.persistAction();
            }
        },
        showPopup(){
            //this.currentAction = {};
        }
    },
    components: {
        DxPopup,
        DxRadioGroup
    },
    computed: {
        popupHeight(){
          return (80 - this.heightDiff).toString() + "vh";
        },
    },
    methods: {
        backButtonEvent(){
            this.$emit("hidden");
        },
        buttonCloseEvent(){
            this.$emit("hidden");
        },
        persistAction(){
            this.$emit("applyFilter", this.currentAction);
            this.$emit("hidden");
        }
    },
    mounted(){
        this.currentAction = this.filterActions[0];
    }
}
</script>

<style lang="scss">
    @import "@/assets/scss/components/consumFilter.scss";
</style>
