export default (route, from, next, params) => {
  const store = params.store;
  const router = params.router;

  if (store.getters['auth/check']) {
    router.push({
      path: '/dashboard',
    });
  }
}
