import * as types from "../mutation-types";

const mutations = {
  [types.SET_SERVERS]: (state, payload) => {
    state.servers = payload;
  },
  [types.SET_SERVER_ITEM]: (state, payload) => {
    state.servers.splice(state.servers.indexOf(
      state.servers.find(c => c.uuid === payload.uuid)
    ), 1, payload);
  },
  [types.SET_SERVER_FILTERS]: (state, payload) => {
      state.server_filters = payload;
  },
}

export default mutations;
