<template>
    <div class="sticky_footer">

      <div v-for="(menu, index) in menuItems" :key="index" :class="menu.spacer ? 'footer_spacer' : 'footer_element'">
        <img v-if="!menu.spacer" :src="getImageUrl(menu.selected ? menu.selected_image : menu.image)" @click="menu.event" style="fill: green;" />
        <div v-if="!menu.spacer" class="footer_text" :class="menu.selected ? 'footer_text_selected' : '' " @click="menu.event(null)">{{menu.text}}</div>
      </div>
    </div>
</template>

<script>
export default {
    name: "MobileFooter",
    props:{
        menuItems:{
            type:Array,
            default: () => [],
        }
    },
    data(){
        return {
            path: document.location.origin
        }
    },
    methods:{
        getImageUrl (img)  {
            return require(`@/assets/images/icons/${img}`);
        }
    },
    mounted(){

    }
}
</script>

<style lang="scss">
    @import "@/assets/scss/components/footer.scss";
</style>
