<template type="x/template">
    <transition name="modal" id="stock-transaction-dialog">
      <DxPopup
      id="dx-dialog"
      width="100vw"
      height="100vh"
      :close-on-outside-click="false"
      :show-close-button="true"
      :visible="showPopup"
      :resize-enabled="false"
      :darg-enabled="false"
      @hidden="$emit('hidden')"
      :wrapper-attr="popupAttributes"
    >
       <template #title>
        <div class="transactions_header">
          <div class="left_side">
            <div @click="backButtonEvent" class="back_button" v-show="currentStep > 0">
              <img src="@/assets/images/icons/arrow-back.svg" />
              <div class="back_button_text">Inapoi</div>
              </div>
          </div>
          <div class="right_side">
            <img class="close_button"
              src="@/assets/images/icons/dialog-close.svg"
              @click="buttonCloseEvent"
            />
          </div>
        </div>
      </template>

        <template #content>
            <div class="transactions_content">
                 <DxTabPanel
                    :element-attr="tabPanelAttributes"
                    :data-source="transactionSteps"
                    :loop="false"
                    :selected-item="currentItem"
                    :selected-index="currentStep"
                    :animation-enabled="true"
                    :swipe-enabled="false"
                    :show-nav-buttons="false"
                    @selection-changed="tabSelectionChanged($event)"
                >

                <template #item="{ data: step }">
                    <div class="transaction_tab_panels_container">
                        <div class="dialog_title_container">
                          <div v-show="step == 1" class="title_text">Tranzacții</div>
                          <div v-show="step > 1" class="title_text" :class=" alignTitleLeft ? 'title_text_left' :  'title_text'">{{currentTransactionTitleText}}</div>
                          <div class="transaction_sub_title_text">{{subtitleText1}}<span style="color: #096c27">{{subtitleText2}}</span></div>
                          <div class="title_right_icon" v-show="showBarcodeIcon" @click="barcodeClick">
                            <img src="@/assets/images/icons/barcode_icon.svg"/>
                          </div>
                          <div>
                            <b-button v-if="addButtonVisibility"
                            class="selector_add_icon"
                            variant="outline-secondary"
                            size="sm"
                            pill
                            @click="detailSelectorAdd"
                            ><img src="@/assets/images/icons/plus.svg"
                            /></b-button>
                          </div>
                        </div>
                        <div v-show="step == 1" class="transaction_step option_selector_container">
                            <div v-for="option in transactionOptions" :key="option.code" class="transaction_selector_option" @click="transactionOptionClick(option)">{{option.title}}</div>
                        </div>
                        <div v-show="step == 2">
                              <reception v-if="currentTransaction.code == transactionActions.Receptie" ref='reception' @goBackParent="goBackParent" @setTitle="setTitle" @changeAddButtonVisibility="changeAddButtonVisibility" @closeTransactions="buttonCloseEvent"></reception>
                              <transfer v-if="currentTransaction.code == transactionActions.Transfer" ref='transfer' @goBackParent="goBackParent" @setTitle="setTitle" @changeAddButtonVisibility="changeAddButtonVisibility" @closeTransactions="buttonCloseEvent"></transfer>
                              <consum v-if="currentTransaction.code == transactionActions.Consum" ref="consum" @goBackParent="goBackParent" @setTitle="setTitle" @closeTransactions="buttonCloseEvent"></consum>
                              <sales v-if="currentTransaction.code == transactionActions.Vanzare" ref="sales" @goBackParent="goBackParent" @setTitle="setTitle" @closeTransactions="buttonCloseEvent"></sales>
                        </div>
                    </div>
                </template>

                 </DxTabPanel>
                 <toast-message ref="toastMessage"></toast-message>
            </div>
        </template>


    </DxPopup>
    </transition>
</template>

<script>

import { DxPopup, DxPosition } from "devextreme-vue/popup";
import DxTabPanel from "devextreme-vue/tab-panel";
import Reception from "./partials/reception.vue"
import Transfer from "./partials/transfer.vue"
import Consum from "./partials/consum.vue"
import Sales from "./partials/sales.vue"
import { TransactionActions, TransactionOptions } from "./transactions"
import ToastMessage from "../../../components/Mobile/toastMessage.vue"

export default {
    name: "Transactions",
     props: {
        showPopup: {
          type: Boolean,
          default: false,
        },
    },
    data(){
        return {
            popupAttributes: {
              class: 'transaction_dialog_wrapper'
            },
             tabPanelAttributes: {
                class: 'transaction_tabs'
            },
            transactionSteps: [1,2],
            currentStep: 0,
            currentItem: 1,
            currentTransaction: {},
            currentTransactionTitleText: '',
            addButtonVisibility: false,
            alignTitleLeft: false,
            subtitleText1: '',
            subtitleText2: ''
        };
    },
    components: {
        DxPopup, DxPosition,
        DxTabPanel,
        Reception,
        ToastMessage,
        Transfer,
        Consum,
        Sales
    },
    watch:{
      showPopup(newVal){
        if(!newVal){
          this.resetForm();
        }
      },
    },
    computed:{
      transactionActions(){
        return TransactionActions;
      },
      transactionOptions(){
        return TransactionOptions;
      },
      showBarcodeIcon(){
        return this.currentTransaction.code == this.transactionActions.Receptie || this.currentTransaction.code == this.transactionActions.Transfer;
      }
    },
    methods:{
         backButtonEvent(){
          if(this.currentItem == 1){
            this.$emit("hidden");
          }
          else{
            if(this.currentTransaction.code == this.transactionActions.Receptie){
              this.$refs.reception.goBack();
            }
            if(this.currentTransaction.code == this.transactionActions.Transfer){
              this.$refs.transfer.goBack();
            }
            if(this.currentTransaction.code == this.transactionActions.Consum){
              this.$refs.consum.goBack();
            }
            if(this.currentTransaction.code == this.transactionActions.Vanzare){
              this.$refs.sales.goBack();
            }
            //this.currentStep = this.currentStep - 1;
          }
        },
        buttonCloseEvent(message){
          this.$emit("hidden");
          if(message && message.length > 0){
            this.$emit('showTransactionMessage', message);
          }
        },
        tabSelectionChanged(e) {
          this.currentItem = e.addedItems[0];
          this.currentStep = this.transactionSteps.indexOf(this.currentItem);
        },
        transactionOptionClick(trans){
          if(trans.code == this.transactionActions.Receptie ||
            trans.code == this.transactionActions.Transfer ||
            trans.code == this.transactionActions.Consum ||
            trans.code == this.transactionActions.Vanzare){
            this.currentTransaction = trans;
            this.currentStep = this.currentStep + 1;
          }
          else{
            this.$refs.toastMessage.showInfo('Funcția este în curs de dezvoltare!');
          }
        },
        barcodeClick(){

          this.$refs.toastMessage.showInfo('Funcția este în curs de dezvoltare!');
          // console.log(this.currentTransaction.code);
          // console.log(this.transactionActions.Receptie);
          // if(this.currentTransaction.code == this.transactionActions.Receptie){
          //   this.$refs.reception.goForward();
          // }
        },
        goBackParent(){
          if(this.currentStep == 1){
           this.currentTransaction = {};
          }
          this.currentStep = this.currentStep - 1;

        },
        setTitle(titleText, alignLeft, subt1 = '', subt2 = ''){
          this.currentTransactionTitleText = titleText;
          this.alignTitleLeft = alignLeft;
          this.subtitleText1 = subt1;
          this.subtitleText2 = subt2;
        },
        detailSelectorAdd(){
          if(this.currentTransaction.code == this.transactionActions.Receptie){
            this.$refs.reception.goForward();
          }
          if(this.currentTransaction.code == this.transactionActions.Transfer){
            this.$refs.transfer.goForward();
          }
        },
        changeAddButtonVisibility(visibility){
          this.addButtonVisibility = visibility;
        },
        resetForm(){
          this.currentStep = 0;
          this.currentTransaction = {};
          this.subtitleText1 = '';
          this.subtitleText2 = '';
        },
        showMessage(message){
          this.$emit('showTransactionMessage', message);
        }
    },
    mounted(){
    }
}
</script>

<style lang="scss">
  @import "@/assets/scss/stockMobile/transactions.scss";
</style>
