const getters = {
  companies: state => state.companies,
  companyFilters: state => state.company_filters,
  company: state => state.company,
  parentCompanies: state => state.parent_companies,
  units: state => state.units,
  departments: state => state.departments,
  departmentFilters: state => state.department_filters,
  recentlyUploadedFiles: state => state.recently_uploaded_files,
  recentlyUploadedZipFiles: state => state.recently_uploaded_zip_files

}

export default getters;
