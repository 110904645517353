const state = {
    sm_reception_transaction: {},
    sm_sales_transaction: {},
    sm_consum_transaction: {},
    sm_transfer_transaction: {},
    sm_transactions: [],
    sm_stock_movements: [],
    sm_transaction_filters: {},
    sm_stock_movement_filters: {},
    sm_transaction_types: [],
    sm_stock_movement_types: [],
}

export default state;
