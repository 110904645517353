<template>
    <div>
        <DxDataGrid 
            :ref="contractItemGridRefKey"
            :data-source="templateData.data.contract_items"
            :show-borders="true"
            :remote-operations="false"
            @row-updating="update"
        >
            <DxColumn
                data-field="owner.uuid"
                data-type="string"
                caption="Proprietar"
                edit-cell-template="dropDownBoxEditor"
            >
            <DxLookup
                :data-source="owners"
                display-expr="name"
                value-expr="uuid"
            />
            </DxColumn>
            <template #dropDownBoxEditor="{ data: cellInfo }">
                <div style="width: 450px;">
                <OwnerEditorTemplate
                :value="cellInfo.value"
                :onValueChanged="cellInfo.setValue"
                :dataSource="owners"
                :loadDataSourceOwners="loadOwners"
                />
                </div>
            </template>
            <DxColumn
                data-field="area.name"
                data-type="string"
                caption="Parcela"
                :allowEditing="false"
            />
            <DxColumn
                data-field="area.owned_surface_absolute"
                data-type="string"
                caption="Suprafata"
            >
            <DxFormat
                                type="number"
                                currency="ha"
                                :precision="2"
                                :formatter="formatSurfacePrice"
                            />
            </DxColumn>
            <DxColumn
                data-field="start_date"
                data-type="string"
                caption="Data inceput"
                :allowEditing="false"
            />
            <DxColumn
                data-field="end_date"
                data-type="string"
                caption="Data sfarsit"
                :allowEditing="true"
            />
            
            <DxPaging :total="templateData.data.contract_items.length" :page-size="15"/>
            <DxPager
                :visible="true"
                :allowed-page-sizes="pageSizes"
                display-mode="compact"
                :show-page-size-selector="true"
                :show-info="true"
                :show-navigation-buttons="true"
            />
            <DxEditing
                refresh-mode="full"
                :allow-adding="false"
                :allow-updating="allowEditing"
                :allow-deleting="false"
                :use-icons="true"
                mode="row"
            />
        </DxDataGrid>
    </div>
</template>
<script>

import { mapActions, mapGetters } from 'vuex'
import * as endpoints from '../../../store/api/endpoints'
import { DxDataGrid, DxColumn, DxPaging, DxPager, DxEditing, DxLookup, DxRequiredRule, DxFormat } from 'devextreme-vue/data-grid';
import CustomStore from 'devextreme/data/custom_store';
import Forbidden from "~/pages/errors/Forbidden.vue";
import * as api from '../../../store/api/index'
import OwnerEditorTemplate from './ownerEditorTemplate.vue'
import moment from "moment"

export default {
    name: "ContractItems",
    middleware: ['auth', 'check-company-admin'],
    data(){
        return {
            contractItemGridRefKey: 'contract-item-grid-' + this.templateData.key,
            
            owners:[],
            productsData: [],
            measureUnitsData: [],
            pageSizes: [15, 25, 100]
        };
    },
    props:{
        templateData: {
            type: Object,
            default: null
        }
    },
    components: {
        DxDataGrid,
        DxColumn,
        DxPaging,
        DxPager,
        DxEditing,
        DxLookup,
        DxRequiredRule,
        DxFormat,
        Forbidden,
        OwnerEditorTemplate
    },
    computed: {
        ...mapGetters({
            user: 'auth/user',
            users: 'user/users'
        }),
        canEdit() {
            return canEdit(this);
        },
        tariffSystemGrid: function() {
            return this.$refs[contractItemGridRefKey].instance;
        },
    },
    methods: {
        ...mapActions({
            getOwners: 'leasecontracts/getOwners',
            updateOwnerAndSurface: 'leasecontracts/updateOwnerAndSurface'
        }),
        formatSurfacePrice(value){
            return value + ' ha';
        },
        update(e){
            let itemToUpdate = {
                contract_item_uuid: e.key.uuid,
                owner_uuid: e.newData.owner?.uuid ? e.newData.owner.uuid : null,
                surface: e.newData.area?.owned_surface_absolute ? e.newData.area.owned_surface_absolute : null
            };
            if(e.newData.end_date){
                itemToUpdate.modification_date = e.newData.end_date;
            }
            this.updateOwnerAndSurface(itemToUpdate).then(response => {

            });
        },
        loadOwners(){
            this.getOwners({limit:999999}).then(response => {
                this.owners = response.data;
            });
        },
        allowEditing(e){
            var currentdate = new Date(); 
            return moment(this.templateData.data.end_date) > currentdate;
        }
    },
    mounted(){
        this.loadOwners();
        
    }
}

</script>