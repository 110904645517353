const getters = {
  reports: state => state.reports,
  report: state => state.report,
  reportGroups: state => state.report_groups,
  reportGroup: state => state.report_group,
  reportFilters: state => state.report_filters,
  reportFilter: state => state.report_filter,
  reportColumns: state => state.report_columns,
  reportColumn: state => state.report_column,
}

export default getters;
