<template>
  <div class="grid-filters-toolbar" :class="containerClass">
    <div class="row">
      <div class="col-lg-2">
        <div class="filters-icon">
          <span class="fs16-700 gray90">{{ $t('salary_calculator.reports.filters.title') }}</span>
          <img src="@/assets/images/desktop/icons/filters-round.svg" class="c-pointer" @click="onFiltersClick"/>
        </div>
      </div>
      <div class="col-lg-2 results-text pt-12">
        <span class="fs12-400" v-html="$t('grid.results', { count: this.resultsCount })"></span>
      </div>
      <div class="col-lg-8 items-center">
        <div class="fs12-400 qf-text ">{{ $t('salary_calculator.reports.filters.quick_filters') }}</div>
        <b-form-checkbox switch size="sm" class="sw"
                         v-model="quickFiltersSwitch">
        </b-form-checkbox>
        <img src="~@/assets/images/desktop/icons/column-settings.svg" class="settings-icon c-pointer" @click="onColumnSettingsClick"/>
        <img src="~@/assets/images/desktop/icons/arrow-down.svg" class="arrow-icon c-pointer" @click="onColumnSettingsClick"/>
      </div>
      <grid-column-list-settings-dialog :show="showGridColumnSettingsDialog"
                          :data="gridColumnSettingsDialogData"
                          @onGridColumnSettingsChanged="onGridColumnSettingsChanged"
                          @close="() => showGridColumnSettingsDialog = false"
                          ref="columnSettingsDialog"
      ></grid-column-list-settings-dialog>
      <grid-filter-dialog :show="showGridFilterDialog"
                                        :data="gridFiltersDialogData"
                                        @onOK="onGridFiltersChanged"
                                        @close="() => showGridFilterDialog = false"
                                        ref="filtersDialog"
      ></grid-filter-dialog>
    </div>
  </div>
</template>

<script>
import GridFilterDialog from "~/pages/salary-calculator/sc-work-reports/partials/Filters/Partials/GridFilterDialog.vue";
import GridColumnListSettingsDialog
  from "~/pages/salary-calculator/sc-work-reports/partials/Filters/Partials/GridColumnListSettingsDialog.vue";
import {mapGetters} from "vuex";

export default {
  name: 'GridFiltersToolbar',
  props: ['containerClass', 'results', 'headers'],
  components: {
    GridColumnListSettingsDialog,
    GridFilterDialog
  },
  data() {
    return {
      grid_headers: this.headers ?? [],
      quickFiltersSwitch: false,
      showGridColumnSettingsDialog: false,
      gridColumnSettingsDialogData: {},
      showGridFilterDialog: false,
      gridFiltersDialogData: {},
      filters: {},
    }
  },
  computed: {
    resultsCount() {
      return this.results ? this.results.length : 0;
    },
  },
  methods: {
    onFiltersClick() {
      this.$emit('onFiltersClick');
      this.gridFiltersDialogData = {
        filters: this.filters,
      };
      this.showGridFilterDialog = true;
    },
    onColumnSettingsClick() {
      this.$emit('onColumnSettingsClick');
      this.gridColumnSettingsDialogData = {
        headers: this.grid_headers,
      };
      this.showGridColumnSettingsDialog = true;
    },
    onGridColumnSettingsChanged(columns) {
      this.$emit('onGridColumnsSettingsChanged', columns);
    },
    onGridFiltersChanged(filters) {
        this.filters = filters;
        this.$emit('onFiltersApplied', filters);
    },
    applyGridHeaderSettings() {
      this.grid_headers = this.headers.map((item) => {
        item.selected = item.show !== false;
        return item;
      });
    }
  },
  mounted() {
    this.applyGridHeaderSettings();
  },
  watch: {
    quickFiltersSwitch: function (newVal) {
      this.$emit('onQuickFilterSwitchChanged', newVal);
    },
    headers: {
      handler: function(newVal) {
        this.grid_headers = newVal;
      },
      deep: true,
      immediate: true,
    }
  }
}
</script>

<style lang="scss" scoped>
  .qf-text {
    margin-right: 16px;
  }

  .sw {
    margin-right: 16px;
    padding-top: 3px;
  }

  .settings-icon {
    width: 15px;
    margin-right: 8px;
  }

  .arrow-icon {
    width: 12px;
  }

  .items-center {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
</style>
