const state = () => {
  return {
    roles: [],
    role: {},
    default_permissions: [],
    permissions: [],
  }
}

export default state;
