<template>
  <div class="work-log-list">
    <work-log-list-item
      v-for="item in items"
      :data="item"
      :key="item.uuid"
      :total-hours="totalHours"
      @onItemRemoved="onItemRemoved"
      @onItemChanged="onItemChanged"
    ></work-log-list-item>
    <img src="~@/assets/images/desktop/icons/controls/plus.svg" class="icon-svg" :title="$t('common.add')" @click="addNew" v-if="totalHours > 0"/>
  </div>
</template>

<script>
import WorkLogListItem from "@/pages/salary-calculator/sc-work-log/partials/WorkLogList/Item";
import {errorMessage} from "@/helpers/common";
export default {
  name: 'WorkLogList',
  components: {WorkLogListItem},
  props: ['data', 'totalHours'],
  data() {
    return {
      items: this.data ? this.data : [],
    }
  },
  methods: {
      addNew() {
        if(this.totalHours > 0) {
          this.items.push({
            work_hours: 0,
          });
        }
      },
      getItems() {
        if(this.validate()) {
            return this.items;
        }

        return [];
      },
      getHours() {
        let totalHours = 0;
          this.items.map((item) => {
              if(item.work_hours && item.work_hours > 0) {
                  totalHours += Number(item.work_hours);
              }
          });

          return totalHours.toFixed(2);
      },
      validate() {
        return this.items.filter((item) => {
          if(item.validate instanceof Function) {
            if(!item.validate()) {
              return item;
            }
          }
        }).length <= 0;
      },
      resetValidation() {
        this.items.map((item) => {
          if(item.resetValidation instanceof Function) {
            item.resetValidation();
          }
        });
      },
      onItemRemoved(item) {
        if(this.items.length <= 1) {
          errorMessage(this, this.$t('salary_calculator.work_log.errors.at_least_one_work'));
          return;
        }

          this.items.splice(
              this.items.indexOf(
                item
              ), 1
          );
      },
      onItemChanged(item) {
          let currentHours = this.getHours();
          this.$emit('onItemChanged', item);
          this.$emit('onWorkHoursChanged', currentHours);
      },
      updateItemWithTimestamp(item) {

      }
  },
  watch: {
      data: {
        handler: function(newVal) {
            this.items = newVal;
        },
        deep: true,
      }
  }
}
</script>
