<template>
  <div class="settings">
    <div class="controls text-right pr-3">
      <img src="~@/assets/images/desktop/icons/controls/plus.svg" class="icon-svg ctrl" :title='$t("tools.justifying_documents.new")' @click="newDocument"/>
    </div>
    <card :title="$t('tools.justifying_documents.title')" id="configurator" v-if="canEdit || canDelete">
      <ul class="nav nav-tabs" id="myTab" role="tablist">
        <li class="nav-item t1 gruop" v-for="(documentType, index) in document_types" :key="documentType.uuid">
          <a class="nav-link"
             :class="[{'active': active_tab?.code === documentType.code, 'text-danger': documentType.is_editable === 0}]"
             :id="documentType.code+'-tab'" data-toggle="tab"
             :href="'#'+documentType.code" role="tab" :aria-controls="documentType.code" :aria-selected="index === 0"
             @click="tabChanged(documentType)">
            {{ documentType.name }}
          </a>
        </li>
      </ul>
      <div class="tab-content" id="myTabContent">
        <div class="tab-pane fade pt-1"
             :class="[{'show': active_tab?.code === documentType.code}, {'active': active_tab?.code === documentType.code}]"
             v-for="(documentType, index) in document_types" :key="documentType.uuid"
             :id="documentType.code" role="tabpanel" :aria-labelledby="documentType.code+'-tab'">
          <DocumentBlockList :data="active_tab"></DocumentBlockList>
        </div>
      </div>
    </card>
    <div v-else>
      <forbidden></forbidden>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {canDelete, canEdit, permissions, permissionTypes} from "@/helpers/Permissions";
import Forbidden from "@/pages/errors/Forbidden";
import DocumentBlock from "~/pages/tools/justifying-documents/controls/DocumentBlock.vue";
import DocumentBlockList from "~/pages/tools/justifying-documents/controls/DocumentBlockList.vue";

export default {
  name: 'Settings',
  data() {
    return {
      config: {
        permission: permissions.JUSTIFYING_DOCUMENTS,
        permission_type: permissionTypes.module,
      },
      document_blocks: this.documentBlocks ? this.documentBlocks : [],
      document_types: this.documentTypes ? this.documentTypes: [],
      active_tab: undefined,
    }
  },
  components: {
    DocumentBlockList,
    DocumentBlock,
    Forbidden
  },
  computed: {
    ...mapGetters({
        documentBlocks: 'justifyingDocuments/documentBlocks',
        documentTypes: 'justifyingDocuments/documentTypes',
        currentDepartment: 'auth/currentDepartment',
    }),
    canEdit() {
      return canEdit(this);
    },
    canDelete() {
      return canDelete(this);
    },
  },
  methods: {
    ...mapActions({
        getDocumentBlocks: 'justifyingDocuments/getDocumentBlocks',
        getDocumentTypes: 'justifyingDocuments/getDocumentTypes',
    }),
    tabChanged(type) {
      type.department_uuid = this.currentDepartment.department_uuid;
      type.warehouse_uuid = null;

      this.getDocumentBlocks({
        type: type.code,
      });

      this.$set(this, 'active_tab', type);
    },
    newDocument() {
      this.$router.push({
        path: '/tools/justifying-documents/add?type='+this.active_tab.code
      });
    },
  },
  mounted() {
    let vm = this;
    this.getDocumentTypes().then((resp) => {

      if(resp && resp.length > 0) {
        vm.tabChanged(resp[0]); //first tab activate
      }
    });
  },
  watch: {
    documentTypes: {
      handler: function(newVal) {
        this.document_types = newVal;
      },
      deep: true,
    }
  }
}
</script>

<style scoped>
.ctrl {
  position: relative;
  top: 50px;
  left: -32px;
}
</style>
