<template>
  <div class="card" v-if="type !== 'simple'">
    <div v-if="title"
         class="card-header flex-row"
         :class="[collapsable ? 'c-pointer' : null]"
         data-toggle="collapse"
         :data-target="`#${id}`"
         :aria-expanded="expanded"
         :aria-controls="id"
         @click="onIconClick">
      <span class="flex-grow flex-column col-title">{{ title }}</span>
      <img src="~@/assets/images/desktop/icons/controls/expand.svg" class="flex-column icon-svg"  v-if="collapsable" :title="collapseText"/>
    </div>
    <div class="card-body collapse" :class="[collapseClass]" :id="id">
      <slot />
    </div>
  </div>
  <div class="card-simple" :class="[containerClass]" v-else>
    <div class="card-slot-cont">
      <span class="fs24-700 flex-grow flex-column title" :style="{'font-size': titleFontSize, 'padding-bottom': titleBottomPadding }">{{ title }}</span>
      <slot/>
    </div>
  </div>
</template>

<script>
import { bottom } from '@popperjs/core';

export default {
  name: 'Card',
  props: {
    title: {
      type: String,
      default: null
    },
    collapsable: {
      type: Boolean,
      default: false,
    },
    expanded: {
      type: Boolean,
      default: true,
    },
    type: {
      type: String,
      default: 'simple'
    },
    titleFontSize: {
      type: Number,
      default: 32
    },
    containerClass: {
      type: String,
      default: ''
    },
    titleBottomPadding: {
      type: String,
      default: '32px',
    },
  },
  data() {
    return {
      show: this.expanded,
    }
  },
  computed: {
    id() {
        return Math.random().toString().replace('.', '').trim();
    },
    collapseText() {
        return !this.show ? this.$t('common.show') : this.$t('common.hide');
    },
    collapseClass() {
      if (!this.collapsable) {
        return 'show';
      }
      return this.show ? 'show' : 'hide';
    }
  },
  methods: {
    onIconClick() {
      this.$emit('onClick', this.show);
      this.show = !this.show;
    }
  },
}
</script>

<style scoped>
.card-simple {
  padding: 32px;
  background-color: white;
  border-radius: 16px;
  box-shadow: -2px 11px 18px -11px rgba(0, 0, 0, 0.15);
}

.title {
  width: 95%;
  display: inline-block;
}

.col-title {
  font-size: 23px;
}
</style>
