const getters = {
  cultures: state => state.cultures,
  culture: state => state.culture,
  cultureFilters: state => state.culture_filters,
  varieties: state => state.varieties,
  variety: state => state.variety,
  bbchs: state => state.bbchs,
  bbch: state => state.bbch,
}

export default getters;
