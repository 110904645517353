import * as api from '../api'
import * as endpoints from '../api/endpoints'
import * as types from "../mutation-types";

const actions = {
    getRoles({commit}, payload) {
        return api.fetch(endpoints.ROLES, payload, commit,(response) => {
          commit(types.SET_ROLES, response.data);
        });
    },
    createRole({commit}, payload) {
      return api.post(endpoints.ROLES, payload, commit, (response) => {
        commit(types.ADD_ROLE, response.data);
      });
    },
    updateRole({commit}, payload) {
      return api.put(endpoints.ROLES+'/'+payload.uuid, payload, commit, (response) => {
        commit(types.UPDATE_ROLE_ITEM, response.data);
      });
    },
    deleteRole({commit}, payload) {
      return api.remove(endpoints.ROLES+'/'+payload.uuid, payload, commit, (response) => {
        commit(types.REMOVE_ROLE_ITEM, response.data);
      });
    },
    getPermissions({commit}, payload) {
      return api.fetch(endpoints.PERMISSIONS, payload, commit, (response) => {
        commit(types.SET_PERMISSIONS, response.data);
      });
    },
    getDefaultPermissions({commit}, payload) {
      if (!payload) {
        payload = {};
      }
      payload.limit = 999;
      return api.fetch(endpoints.DEFAULT_PERMISSIONS, payload, commit, (response) => {
        commit(types.SET_PERMISSIONS, response.data);
      });
    },
}

export default actions;
