import * as types from "../../mutation-types";

const mutations = {
  [types.SET_SC_WORK_LOGS]: (state, payload) => {
    state.sc_work_logs = payload;
  },
  [types.SET_SC_WORK_LOG]: (state, payload) => {
    state.sc_work_log = payload;
  },
  [types.ADD_SC_WORK_LOG_ITEM]: (state, payload) => {
    state.sc_work_logs.push(payload);
  },
  [types.UPDATE_SC_WORK_LOG_ITEM]: (state, payload) => {
    state.sc_work_logs.splice(state.sc_work_logs.indexOf(
      state.sc_work_logs.find(c => c.uuid === payload.uuid)
    ), 1, payload);
  },
  [types.DELETE_SC_WORK_LOG_ITEM]: (state, payload) => {
    state.sc_work_logs.splice(state.sc_work_logs.indexOf(
      state.sc_work_logs.find(c => c.uuid === payload.uuid)
    ), 1);
  },
  [types.SET_SC_WORK_LOG_FILTERS]: (state, payload) => {
    state.sc_work_log_filters = payload;
  },
}

export default mutations;
