import * as api from '../../api/index'
import * as endpoints from '../../api/endpoints'
import * as types from "../../mutation-types";

const actions = {
  getSCEmployees({commit}, payload) {
    if (!payload) {
      payload = {};
    }
    payload.limit = payload.limit ?? 9999;
    return api.fetch(endpoints.SC_EMPLOYEE, payload, commit,(response) => {
      commit(types.SET_SC_EMPLOYEES, response.data.data);
    });
  },
  getSCEmployee({commit}, payload) {
    return api.fetch(endpoints.SC_EMPLOYEE+'/'+payload.uuid, payload, commit,(response) => {
      commit(types.SET_SC_EMPLOYEE, response.data);
    });
  },
  createSCEmployee({commit}, payload) {
    return api.post(endpoints.SC_EMPLOYEE, payload, commit, (response) => {
      commit(types.SET_SC_EMPLOYEE, response.data);
    });
  },
  updateSCEmployee({commit}, payload) {
    return api.put(endpoints.SC_EMPLOYEE+'/'+payload.uuid, payload, commit, (response) => {
      commit(types.UPDATE_SC_EMPLOYEE_ITEM, response.data);
    });
  },
  deleteSCEmployee({commit}, payload) {
    return api.remove(endpoints.SC_EMPLOYEE+'/'+payload.uuid, payload, commit, (response) => {
      commit(types.DELETE_SC_EMPLOYEE_ITEM, response.data);
    });
  },
}

export default actions;
