<template>
    <div>
        <div>SOMET TEXT</div>
      <ReportDesignerComponent />
    </div>
</template>

<script>

import { mapGetters, mapActions } from 'vuex'
import ReportDesignerComponent from "@/components/reporting/ReportDesignerComponent.vue";

export default {
    name: 'ReportDesigner',
    components: {
        ReportDesignerComponent
    },
    data(){
        return {

        }
    },
    methods:{
        ...mapActions({
            getReports: "reporting/getReports",
        }),
    },
    mounted(){
        this.getReports({}).then(response => {
            console.log(response);
        });
    }
    
}

</script>