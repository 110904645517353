import * as types from "~/store/mutation-types";
import axios from '@/plugins/axios'

export function fetch(url, payload, commit, callback = null) {
  return new Promise((resolve, reject) => {
    axios.get(url, { params: payload }).then((response) => {
      resolve(response.data.data);
      if(callback) {
        callback(response.data);
      }
    }).catch((e) => {
      commit("common/"+types.SET_ERROR_MESSAGE, e, { root: true });
      reject(e);
    });
  });
}

export function fetchFull(url, payload, commit, callback = null) {
  return new Promise((resolve, reject) => {
    axios.get(url, { params: payload }).then((response) => {
      resolve(response.data);
      if(callback) {
        callback(response);
      }
    }).catch((e) => {
      commit("common/"+types.SET_ERROR_MESSAGE, e, { root: true });
      reject(e);
    });
  });
}

export function post(url, payload, commit, callback = null) {
  return new Promise((resolve, reject) => {
    axios.post(url, payload).then((response) => {
      resolve(response.data);
      if(callback) {
          callback(response.data);
      }
    }).catch((e) => {
      commit("common/"+types.SET_ERROR_MESSAGE, e, { root: true });
      reject(e);
    });
  });
}

export function put(url, payload, commit, callback = null) {
  return new Promise((resolve, reject) => {
    axios.put(url, payload).then((response) => {
      resolve(response.data);
      if(callback) {
        callback(response.data);
      }
    }).catch((e) => {
      commit("common/"+types.SET_ERROR_MESSAGE, e, { root: true });
      reject(e);
    });
  });
}

export function remove(url, payload, commit, callback = null) {
  return new Promise((resolve, reject) => {
    axios.delete(url, { params: payload }).then((response) => {
      resolve(response.data);
      if(callback) {
        callback(response.data);
      }
    }).catch((e) => {
      commit("common/"+types.SET_ERROR_MESSAGE, e, { root: true });
      reject(e);
    });
  });
}

export function downloadBlob(url, payload, type, commit, callback = null) {
  return new Promise((resolve, reject) => {
    let responseType = payload.response_type ?? 'blob';
    axios({
      url: url,
      method: 'GET',
      responseType: responseType,
      data: payload.filters ,
      params: payload.filters,
    }).then((response) => {
      if(response) {
        if(responseType === 'blob') {
          const url = window.URL.createObjectURL(new Blob([response.data], { type: type }));
          const link = document.createElement('a');
          link.href = url;
          link.download = payload.filename;
          link.target = '_blank';
          link.click();
        }

        resolve(response);
        if(callback) {
          callback(response.data);
        }
      }
    }).catch((e) => {
      reject(e);
    });
  })
}
