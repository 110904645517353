import Vue from 'vue'
import VueMeta from 'vue-meta'
Vue.use(VueMeta)
import App from './App.vue'
import '@/plugins/index';
import router from "@/router";
import store from '@/store'
import i18n from "@/plugins/vue-i18n";
import axios from '@/plugins/axios'
import 'jquery/src/jquery.js'
import '@/assets/scss/main.scss';
import '@/assets/css/main.css';

//RV
import "devextreme/dist/css/dx.light.css";
import "@devexpress/analytics-core/dist/css/dx-analytics.common.css";
import "@devexpress/analytics-core/dist/css/dx-analytics.light.css";
import "devexpress-reporting/dist/css/dx-webdocumentviewer.css";
//RD
import "ace-builds/css/ace.css";
import "ace-builds/css/theme/dreamweaver.css";
import "ace-builds/css/theme/ambiance.css"; 
import "devextreme/dist/css/dx.light.css";
import "@devexpress/analytics-core/dist/css/dx-analytics.common.css";
import "@devexpress/analytics-core/dist/css/dx-analytics.light.css";
import "@devexpress/analytics-core/dist/css/dx-querybuilder.css";
import "devexpress-reporting/dist/css/dx-webdocumentviewer.css";
import "devexpress-reporting/dist/css/dx-reportdesigner.css";

Vue.config.productionTip = false

/* Global error handler */
window.addEventListener('unhandledrejection', function(event) {
  //handle error here
  //event.promise contains the promise object
  //event.reason contains the reason for the rejection
  /*
  event.promise.catch((e) => {
    router.push({
      path: '/general-error'
    })
  })*/
});

new Vue({
  router,
  store,
  i18n,
  axios,
  render: h => h(App)
}).$mount('#app')
