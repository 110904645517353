<template>
  <tbody>
    <row v-for="(td, index) in rows"
         :class="td.class"
         :width="getHeaderWidth(index)"
         :key="Math.random().toString().replace('.', '')"
         :index="index"
         :headers="table_headers"
         :actions="action_buttons"
         :permission="permission"
         :data="td">
    </row>
  </tbody>
</template>

<script>
import Row from './Row'

export default {
  props: ['data', 'headers', 'actions', 'permission', 'infiniteScroll'],
  name: 'TableBody',
  data() {
      return {
        rows: this.data ? this.data : [],
        table_headers: this.headers ? this.headers : [],
        action_buttons: this.actions ? this.actions : {},
      }
  },
  components: {
      Row,
  },
  methods: {
    getHeaderWidth(index) {
        if(this.table_headers && this.table_headers[index]) {
          return this.table_headers[index].width ?? 'auto';
        }

        return 'auto';
    },
  },
  watch: {
    data: {
        handler: function(newVal) {
            this.rows = newVal;
        },
        deep: true,
    },
    headers: {
      handler: function(newVal) {
        this.table_headers = newVal;
      },
      deep: true,
      immediate: true,
    },
    actions: function(newVal) {
        this.action_buttons = newVal;
    }
  }
}
</script>
