<template>
  <card :title="title" id="details" v-if="canEdit || canDelete" v-cloak>
    <span class="action_buttons">
      <img src="~@/assets/images/desktop/icons/controls/plus.svg" class="icon-svg" :title="$t('common.create_new', { name: name })" @click="newObject()"/>
    </span>
    <div class="row">
      <div class="form-group col-sm-12 col-lg-6 text-left">
        <label class="control-label">{{ $t('common.name') }}</label>
        <input type="text" v-model="data.name" :placeholder='$t("common.name")'
               :class="{ 'field-error' : this.$v.data.name.$error }"
               class="form-control"/>
      </div>
    </div>
    <div class="row">
      <div class="form-group col-sm-12 col-lg-6 text-left">
        <label class="control-label">{{ $t('works.work_machines.registration_number') }}</label>
        <input type="text" v-model="data.registration_number"
               class="form-control"/>
      </div>
    </div>
    <div class="row">
      <div class="form-group col-sm-12 col-lg-6 text-left">
        <label class="control-label">{{ $t('works.work_machines.work_type') }}</label>
        <vue-bootstrap-typeahead
          :data="work_list"
          v-model="work_search"
          ref="works"
          :serializer="s => s.name"
          :placeholder="$t('works.work_machines.work_type_search')"
          @hit="onWorkSelected"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-sm-6 col-lg-3">
        <b-form-checkbox switch size="lg"
                         v-model="autopropulsat"
                         @change="onAutopropulsatChanged">{{ $t('works.work_machines.autopropulsat') }}</b-form-checkbox>
      </div>
      <div class="col-sm-6 col-lg-3">
        <b-form-checkbox switch size="lg"
                         v-model="equipment"
                         @change="onEquipmentChanged">{{ $t('works.work_machines.equipment') }}</b-form-checkbox>
      </div>
      <div class="col-sm-6 col-lg-3">
        <b-form-checkbox switch size="lg"
                         v-model="is_dataller"
                         @change="onIsDatallerChanged">{{ $t('works.work_machines.dataller') }}</b-form-checkbox>
      </div>
    </div>

    <agro-action-buttons
      :data="data"
      :can-edit="canEdit"
      :can-delete="canDelete"
      @onSave="save"
      @onSaveAndNew="saveAndNew"
      :save-and-new="true"
      @onDelete="confirmDelete"
      @onGotoList="gotoList"
    ></agro-action-buttons>

  </card>
  <div v-else>
    <forbidden></forbidden>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import VueBootstrapTypeahead from 'vue-bootstrap-typeahead'
import required from "vuelidate/src/validators/required";
import {canDelete, canEdit, permissions, permissionTypes} from "@/helpers/Permissions";
import * as actions from '@/helpers/generalActions'
import AgroActionButtons from "@/components/AgroActionButtons/index";
import Forbidden from "@/pages/errors/Forbidden";

export default {
  name: 'WorkMachineDetails',
  components: {
    Forbidden,
    AgroActionButtons,
    VueBootstrapTypeahead,
  },
  data() {
    return {
      data: {},
      autopropulsat: false,
      equipment: false,
      is_dataller: false,
      work_search: '',
      work_list: [],
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
      users: 'user/users',
      currentDepartment: 'auth/currentDepartment',
      workMachines: 'works-work-machines/workMachines',
      works: 'works-manager/works',
    }),
    name() {
      return this.$t('works.work_machines.name')
    },
    config() {
      return {
        permission: permissions.WORK_MACHINES,
        permission_type: permissionTypes.module,
        get: this.get,
        create: this.create,
        update: this.update,
        delete: this.delete,
        list_url: '/works/work-machines',
        confirm_delete: true,
      }
    },
    actions() {
      return actions;
    },
    canEdit() {
      return canEdit(this);
    },
    canDelete() {
      return canDelete(this) && this.data.is_deletable;
    },
    title() {
      return (this.data.uuid ? this.$t('common.edit') : this.$t('common.new'))+' '+this.name;
    },
  },
  methods: {
    ...mapActions({
      getWorks: 'works-manager/getWorks',
      get: 'works-work-machines/getWorkMachine',
      create: 'works-work-machines/createWorkMachine',
      update: 'works-work-machines/updateWorkMachine',
      delete: 'works-work-machines/deleteWorkMachine',
    }),
    loadData() {
      return this.actions.getData(this, () => {
        this.data.parent_uuid = null;
      });
    },
    gotoList() {
      return actions.gotoList(this);
    },
    confirmDelete() {
      return this.actions.confirmDelete(this);
    },
    save() {
      this.data.department_uuid = this.currentDepartment.department_uuid;
      this.data.autopropulsat = this.autopropulsat ? 1 : 0;
      this.data.equipment = this.equipment ? 1 : 0;
      this.data.is_dataller = this.is_dataller ? 1 : 0;
      return this.actions.save(this, this.config.list_url);
    },
    saveAndNew() {
      return this.actions.saveAndNew(this, 'add');
    },
    newObject() {
      return this.actions.newObject(this);
    },
    onAutopropulsatChanged(val) {
      this.data.autopropulsat = val ? 1 : 0;
    },
    onEquipmentChanged(val) {
      this.data.equipment = val ? 1 : 0;
    },
    onIsDatallerChanged(val) {
      this.data.is_dataller = val ? 1 : 0;
    },
    onWorkSelected(data) {
      this.data.work = {
        uuid: data.uuid,
        name: data.name,
      };
      this.data.work_uuid = data.uuid;
    },
  },
  mounted() {
    this.loadData();
    this.actions.handleParams(this);
  },
  watch: {
    data: {
      handler: function(newVal) {
        this.autopropulsat = Boolean(newVal.autopropulsat);
        this.equipment = Boolean(newVal.equipment);
        this.is_dataller = Boolean(newVal.is_dataller);

      },
      deep: true,
    },
    $route: {
      handler: function(newVal) {
        this.actions.handleParams(this);
      },
      deep: true,
    },
    permission: {
      handler: function(newVal) {
        this.permissionModule = newVal;
      },
      deep: true,
    },
    work_search: { //work type
      handler: function(newVal) {
        let vm = this;
        this.getWorks({
          name: newVal,
        }).then((resp) => {
          vm.work_list = resp.data;
        });

        let work = vm.work_list.find(c => c.name === newVal);

        if(work) {
          this.$set(this.data, 'work', {
            uuid: work.uuid,
            name: newVal,
          });
        } else {
          this.$set(this.data, 'work', {
            name: newVal,
          });
        }
      },
      deep: true,
    },
  },
  validations(){
    return {
      data: {
        name: {
          required,
        },
      }
    }
  }
}
</script>

<style scoped>

</style>
