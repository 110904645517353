<template>
    <transition name="modal" id="contact-info-dialog">
        <div>
            <DxPopup
                :height="popupHeight"
                width="100vw"
                :close-on-outside-click="false"
                :show-close-button="true"
                :visible="showPopup"
                position="bottom"
                @hidden="$emit('hidden')"
                :wrapper-attr="popupAttributes"
            >
            <template #title>
                <div class="map-filter-title">
                    <div class="map-filter-close">
                    <img
                        src="@/assets/images/icons/dialog-close-white.svg"
                        @click="closeDialog"
                    />
                    </div>
                </div>
            </template>

            <template #content>
                <div class="map-filter-container">
                    <DxTabPanel
                        :data-source="filterTabs"
                        :selected-item="currentColorFilterItem"
                        :selected-index="currentColorFilterStep"
                        @selection-changed="mainTabSelectionChanged($event)"
                        :show-nav-buttons="true"
                        class="map-filter-tabs"
                        >

                        <template #item="{data: mainStep}">
                            <div>

                                <DxScrollView v-show="mainStep == 'STRATIFICARE'">
                                    <div class="layer-detail-content">
                                        <div class="ld-input-holder">
                                            <div class="input_label">Stratificare dupa</div>
                                            <div>
                                                <DxSelectBox 
                                                v-model="formData.colorByType"
                                                class="filter-input" 
                                                :items="colorByTypes"
                                                display-expr="name"
                                                value-expr="code"
                                                @value-changed="onColorByTypeChanged"/>
                                            </div>
                                        </div>
                                        <div class="ld-input-holder" v-if="selectedColorByType?.code == 'WORK'">
                                            <div class="input_label">Selectează data</div>
                                            <div>
                                                <functional-calendar
                                                    class="test_calendar_class"
                                                    :date-format="'yyyy-mm-dd'"
                                                    :is-date-range="true"
                                                    v-model="calendar"
                                                    :change-month-function="true"
                                                    :change-year-function="true"
                                                ></functional-calendar>
                                            </div>
                                        </div>
                                        <div class="ld-input-holder" v-if="selectedColorByType?.code">
                                            <div class="input_label">Tip {{ selectedColorByType.name.toLowerCase() }}</div>
                                            <div>
                                                <div v-for="(culture, index) in cultures" :key="index" class="input_color_holder" v-show="selectedColorByType.code == 'CULTURE'">
                                                    <DxCheckBox name="culture"  v-model="culture.selected" :element-attr="checkBoxAttributes"/>
                                                    <div :style="bgColor(culture)" class="input_color_point"></div>
                                                    <div class="input_color_name">{{ culture.name }}</div>
                                                </div>
                                                <div v-show="selectedColorByType.code == 'WORK'">
                                                    <DxRadioGroup ref="workRadio"
                                                        :dataSource="localLayerWorks"
                                                         v-model:value="formData.work_uuid"
                                                        
                                                        display-expr="name"
                                                        :element-attr="radioGroupAttributes"
                                                        item-template="radio"
                                                    >
                                                        <template #radio="{ data }">
                                                            <div class="input_color_holder">
                                                                <div v-if="false" :style="bgColor(data)" class="input_color_point"></div>
                                                                <div class="input_color_name">{{ data.name }}</div>
                                                            </div>
                                                        </template>
                                                    </DxRadioGroup>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="layer-color-filter-bottom-spacer"></div>
                                    </div>
                                </DxScrollView>

                                <div
                                    class="apply_map_filter_sticky_dialog"
                                    >
                                    <b-button
                                        class="continue_button"
                                        variant="outline-secondary"
                                        size="sm"
                                        pill
                                        @click="applyMapColorFilter"
                                        >Aplică</b-button
                                    >
                                </div>

                            </div>

                        </template>

                    </DxTabPanel>
                </div>
            </template>
        </DxPopup>
        <toast-message ref="toastMessage"></toast-message>
        </div>
    </transition>
</template>

<script>
    import { mapActions, mapGetters } from "vuex";
    import DxPopup from "devextreme-vue/popup";
    import DxTabPanel from 'devextreme-vue/tab-panel';
    import { DxScrollView } from "devextreme-vue/scroll-view";
    import DxSelectBox from 'devextreme-vue/select-box';
    import { FunctionalCalendar } from "vue-functional-calendar";
    import { DxCheckBox } from 'devextreme-vue/check-box';
    import DxRadioGroup from 'devextreme-vue/radio-group';
    import ToastMessage from "../../components/Mobile/toastMessage.vue"
    import { required, helpers } from "vuelidate/src/validators"
    
    const requiredDates = (param) => (value) => {
        if(param){
            return helpers.req(value);
        }
        return true;
    };

    const requiredWork = (param) => (value) => {
        if(param){
            return helpers.req(value);
        }
        return true;
    };

    export default {
        name: "LayerColorFilter",
        components:{
            DxPopup,
            DxTabPanel,
            DxScrollView,
            DxSelectBox,
            FunctionalCalendar,
            DxCheckBox,
            DxRadioGroup,
            ToastMessage
        },
        props: {
            showPopup: {
                type: Boolean,
                default: false,
            },
            filterIsActive: {
                type: Boolean,
                default: false,
            },
            heightDiff: {
                type: Number,
                default: 0,
            },
            season_uuid: {
                type: String,
                default: '',
            },
        },
        data(){
            return {
                popupAttributes: {
                    class: 'map-color-filter-attribute-wrapper'
                },
                tabPanelAttributes: {
                    class: 'map-color-filter-steps'
                },
                checkBoxAttributes: {
                    class: 'color-code_checkbox'
                },
                radioGroupAttributes: {
                    class: 'color-code_radiogroup'
                },
                filterTabs: ["STRATIFICARE"],
                currentColorFilterStep: 0,
                currentColorFilterItem: 'STRATIFICARE',
                colorByTypes: [
                    {code: 'WORK', name: 'Lucrare'}, 
                    {code: 'CULTURE', name: 'Cultură'}
                ],
                    selectedColorByType: {},
                calendar: {
                    dateRange: {
                    start: "",
                    end: "",
                    },
                },
                cultures:[],
                localLayerWorks: [],                
                formData: {
                    colorByType: null,
                    dateFrom: null,
                    dateTo: null,
                    work_uuid: null
                },
            };
        },
        watch: {
            showPopup: {
                handler: function (newVal) {
                    if(newVal){
                        this.loadLayerWorks();
                        if(!this.filterIsActive){
                            this.resetForm();
                        }
                    }
                },
                deep: true,
                immediate: true,
            },
            calendar: {
                handler(newVal) {
                    if (newVal) {
                    this.formData.dateFrom = newVal.dateRange.start;
                    this.formData.dateTo = newVal.dateRange.end;
                    }
                },
                deep: true,
            }
        },
        validations(){
            return {
                formData: {
                    colorByType: { required },
                    dateFrom: { requiredDates : requiredDates(this.formData.colorByType == 'WORK') },
                    dateTo: { requiredDates: requiredDates(this.formData.colorByType == 'WORK') }, 
                    work_uuid: { requiredWork: requiredWork(this.formData.colorByType == 'WORK') }, 
                }
            };
        },
        computed: {
            ...mapGetters({
                layers_loading: 'layers/layers_loading',
                layer_season_uuid: 'layers/layer_season_uuid',
                seasons: 'seasons/seasons',
                layer_filters: 'layers/layer_filters',
                departments: 'company/departments',
            }),
            popupHeight(){
                return (80 - this.heightDiff).toString() + "vh";
            },
            layers() {
                let tmplayers = this.$store.getters['layers/layers'];
                return tmplayers;
            },
            layerWorks() {
                let tmplayerWorks = this.$store.getters['layers/layer_works'];
                return tmplayerWorks;
            },
        },
        methods: {
            ...mapActions({
                getLayersByWorkAndDate: 'layers/getLayersByWorkAndDate',
                getLayerWork: 'layers/getLayerWork'
            }),
            resetForm(){
                this.formData = {
                    colorByType: null,
                    dateFrom: null,
                    dateTo: null,
                    work_uuid: null
                };
                this.cultures = [];
                this.localLayerWorks = [];
                this.calendar.dateRange.start = '',
                this.calendar.dateRange.end = ''
            },
            onColorByTypeChanged(e){
                this.selectedColorByType = this.colorByTypes.find(c => c.code == e.value);
                if(this.selectedColorByType.code == 'CULTURE'){
                    let tmpCultures = this.layers.filter(obj => obj.culture != null).map(obj => obj.culture);
                    this.cultures = [];
                    tmpCultures.forEach(element => {
                        if(this.cultures.find(obj => obj.uuid == element.uuid)){}
                        else{
                            element.selected = true;
                            this.cultures.push(element);
                            element.color = this.generateColorCode();
                        }
                    });
                }
                if(this.selectedColorByType.code == 'WORK'){
                   // this.localLayerWorks = this.layerWorks;
                    this.localLayerWorks.forEach(element => {
                        element.selected = false;
                        element.color = "#4ca7c4"; // this.generateColorCode()
                    });
                }
            },
            generateColorCode(){
                let code = '#' + Math.floor(Math.random()*16777215).toString(16);
                if(code == '#ffffff' || code == '#d3d3d3' || code == '#000000' || code.length != 7){
                    code = this.generateColorCode();
                }
                return code;
            },
            closeDialog(){
                this.$emit("hidden");
            },
            mainTabSelectionChanged(e){
                this.currentColorFilterItem = e.addedItems[0];
                this.currentColorFilterStep = this.filterTabs.indexOf(this.currentColorFilterItem);
            },
            validateForm(){
                this.$v.formData.$touch();
                if(this.$v.formData.$invalid){
                    if(this.$v.formData.colorByType.$invalid){
                        this.$refs.toastMessage.showError('Selectează tipul de colorizare!');
                    }
                    else if(this.$v.formData.dateFrom.$invalid){
                        this.$refs.toastMessage.showError('Selectează data!');
                    }
                    else if(this.$v.formData.dateTo.$invalid){
                        this.$refs.toastMessage.showError('Selectează data!');
                    }
                    else if(this.$v.formData.work_uuid.$invalid){
                        this.$refs.toastMessage.showError('Selectează o lucrare!');
                    }
                    else {
                        this.$refs.toastMessage.showError('A apărut o erroare de validare!');
                    }
                }
                return !this.$v.formData.$invalid;
            },
            applyMapColorFilter(){
                if(this.validateForm()){
                    if(this.selectedColorByType.code == 'CULTURE'){
                        this.$emit("applyColorFilterByCulture", this.cultures);
                        this.$emit("hidden");
                    }
                    if(this.selectedColorByType.code == 'WORK'){
                        this.getLayersByWorkAndDate({
                            season_uuid: this.season_uuid,
                            date_from: this.formData.dateFrom,
                            date_to: this.formData.dateTo,
                            work_uuid: this.formData.work_uuid.uuid
                        }).then(response => {
                            if(response.data.length <= 0){
                                this.$refs.toastMessage.showInfo('Căutarea nu a dat rezultat!');
                            }
                            else{
                                this.$emit("applyColorFilterByWork", this.localLayerWorks.find(w => w.uuid = this.formData.work_uuid.uuid), response.data);
                                this.$emit("hidden");
                            }
                        });
                    }
                }
            },
            bgColor(culture) {
                return {
                    'background-color': culture.color
                };
            },
            loadLayerWorks(){
                this.getLayerWork({
                    department_uuid: this.layer_filters?.department_uuids ? this.layer_filters.department_uuids.join(',') : '',
                    season_uuid: this.layer_filters.season_uuid
                }).then(response => {
                    this.localLayerWorks = response;
                });
            }
        }
    }
</script>

<style lang="scss">
    @import "@/assets/scss/components/layerColorFilter.scss";
</style>