import * as types from './mutation-types'
import * as api from './api/index'
import * as endpoints from './api/endpoints'

// state
export const state = () => {
    return {
      customProducts:[],
      customProduct:{}
    }
  }
  
  // getters
  export const getters = {
    customProducts: state => state.customProducts,
    customProduct: state => state.customProduct,
  }
  
  // mutations
  export const mutations = {
    [types.ADD_CUSTOM_PRODUCT]: (state, payload) => {
        state.customProduct = payload;
      },
    [types.SET_CUSTOM_PRODUCTS]: (state, payload) => {
      state.customProducts = payload;
    },
  }
  
  // actions
  export const actions = {
    getCustomProducts({ commit }, payload) {
      return api.fetch(endpoints.CUSTOM_PRODUCT, payload, commit, (response) => {
        commit(types.SET_CUSTOM_PRODUCTS, response.data);
      });
    },

    addCustomProduct({ commit }, payload) {
        return api.post(endpoints.CUSTOM_PRODUCT, payload, commit, (response) => {
          commit(types.ADD_CUSTOM_PRODUCT, response.data);
        });
      },
  }