<template>
  <div></div>
</template>

<script>
export default {
  name: 'Redirect',
  mounted() {
    console.log('fwd: ', this.$route.query);
    if (this.$route.query && this.$route.query.fwd) {
      window.location.href = this.$route.query.fwd;
      /*
      this.$router.push({
        path: this.$route.query.fwd,
      });
       */
    } else {
      this.$router.push({
        path: '/'
      });
    }
  }
}
</script>
