<template>
  <card :title="$t('sorry')" id="company-list" v-if="false">
    <div id="forbidden" class="row">
        <div class="col-lg-6 align-content-center pt-3">
            <p>
              <img src="~@/assets/images/desktop/icons/controls/alert.svg" class="icon-svg text-danger"/>
              {{$t("dont_have_access_to_module")}}
            </p>
        </div>
    </div>
  </card>
  <div v-else>...</div>
</template>

<script>
    import { mapGetters } from 'vuex'

    export default {
        name: 'Forbidden',
        props: ['type'],
        computed: {
            ...mapGetters([
                'user',
            ]),
        },
    }
</script>
