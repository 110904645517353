<template>
    <div class="rent_wrapper" v-if="canEdit || canDelete" v-cloak>
        <Transition name="rent">
            <div class="rent_title_holder_trans" :slide="showQuickSearch">
                <search-input :parentClass="'rent_filter_search'" placeholder="Caută contract" @search="searchByText"></search-input>
            </div>
        </Transition>
        <div class="rent_title_holder" v-if="actionSelectionMode || createSelectionMode || editSelectionMode || deleteSelectionMode">
            <div class="rent_text_button" @click="selectAll">Selectează tot</div>
            <div class="rent_text_button_right" @click="cancelAllSelectionMode">Renunță</div>
        </div>
        <div class="rent_title_holder" v-else>
            <div class="rent_title_text">Contracte</div>
            <div class="filter_icon_holder">
                 <b-button
                    class="rent_filter_icon"
                    variant="outline-secondary"
                    size="sm"
                    pill
                    @click="showRentFilter"
                    ><img src="@/assets/images/icons/options.svg"
                    /></b-button>
            </div>
            <div class="edit_icon_holder" v-if="currentModuleCode == 'PAYMENTS'">
                 <b-button
                    class="rent_edit_icon"
                    variant="outline-secondary"
                    size="sm"
                    pill
                    @click="changeSelectionMode"
                    ><img src="@/assets/images/icons/edit.svg"
                    /></b-button>
            </div>
        </div>

        <contract-list v-show="currentModuleCode == 'CONTRACTS'" ref="contractList" :paymentMode="currentModuleCode == 'PAYMENTS'" :filterData="filterData" @status_filter="searchByStatus" @scrollDown="scrollDown" @scrollUp="scrollUp"></contract-list>
        <contract-payment-list v-show="currentModuleCode == 'PAYMENTS'" ref="contractPaymentList"
            :createSelectionMode="createSelectionMode"
            :editSelectionMode="editSelectionMode"
            :deleteSelectionMode="deleteSelectionMode"

            :paymentMode="currentModuleCode == 'PAYMENTS'" :filterData="filterData" @status_filter="searchByStatus" @scrollDown="scrollDown" @scrollUp="scrollUp"></contract-payment-list>
        <mobile-footer :menuItems="footerMenuItems"></mobile-footer>
        <rent-filter ref="rentFilter" :showPopup="rentFilterVisibility" @hidden="hideRentFilter"/>
        <pay-contract
            :showPopup="payContractVisibility"
            :contracts="selectedContracts"
            :payments="selectedPayments"
            :editMode="paymentIsInEditMode"
            @hidden="hidePayContract"
            @reloadContractPayments="reloadContractPayments"></pay-contract>
        <div class="rent_payment_continue" v-if="actionSelectionMode == true">
            <div class="bottom_button" @click="startCreating">Plătește</div>
            <div class="second_buttons">
                <div class="second_button_left" @click="startEditing">Editează</div>
                <div class="second_button_right" @click="startDeleting">Șterge</div>
            </div>
        </div>
        <div class="rent_action_continue" v-if="createSelectionMode || editSelectionMode || deleteSelectionMode">
            <div class="bottom_button" @click="persistAction">{{ this.continueActionText }}</div>
        </div>
        <toast-message ref="toastMessage"></toast-message>
        <PaymentDeleteConfirm
            :showPopup="paymentDeleteConfirmVisibility"
            @hidden="hidePaymentDeleteConfirm"
            @hideAndRefresh="hidePaymentDeleteConfirmAndRefresh"
            :payments="selectedPayments"></PaymentDeleteConfirm>
    </div>
    <div v-else>
      <forbidden></forbidden>
    </div>
</template>

<script>

import { mapGetters, mapActions } from "vuex";
import ContractList from './partials/contractList.vue'
import ContractPaymentList from './partials/contractPaymentList.vue'
import  MobileFooter  from "../../components/Mobile/Footer.vue"
import RentFilter from './dialogs/rentFilter.vue';
import PayContract from './dialogs/payContract.vue';
import SearchInput  from "../../components/Mobile/searchInput.vue"
import { DxToast } from "devextreme-vue/toast";
import ToastMessage from "../../components/Mobile/toastMessage.vue"
import PaymentDeleteConfirm from "./dialogs/paymentDeleteConfirm.vue"
import {canDelete, canEdit, permissions, permissionTypes} from "~/helpers/Permissions";
import Forbidden from "@/pages/errors/Forbidden.vue";

export default {
    layout(context) {
        return "noheader";
    },
    data(){
        return {
            showQuickSearch: false,
            rentFilterVisibility: false,
            payContractVisibility: false,
            paymentDeleteConfirmVisibility: false,
            currentModuleCode: 'CONTRACTS',
            footerMenuItems: [
                {
                    code: "CONTRACTS",
                    spacer: false,
                    image: "products_icon_gray.svg",
                    selected_image: "products_icon.svg",
                    text: "Contracte",
                    event: this.showContracts,
                    selected: true
                },
                {
                    spacer: true,
                    event: ""
                },
                {
                    code: "PAYMENTS",
                    spacer: false,
                    image: "payment_icon.svg",
                    selected_image: "payment_icon_green.svg",
                    text: "Plată",
                    event: this.showPayments,
                    selected: false
                },
                {
                    spacer: true,
                    event: ""
                },
                {
                    code: "REPORTS",
                    spacer: false,
                    image: "report_icon.svg",
                    selected_image: "report_icon_green.svg",
                    text: "Rapoarte",
                    event: this.showReports,
                    selected:false
                },
            ],
            filterData:{
                contract_status: '',
                general_search: ''
            },
            selectedContracts: [],
            selectedPayments: [],
            actionSelectionMode: false,
            createSelectionMode: false,
            editSelectionMode: false,
            deleteSelectionMode: false,
            paymentIsInEditMode: false
        };
    },
    components:{
      Forbidden,
        ContractList,
        ContractPaymentList,
        MobileFooter,
        RentFilter,
        PayContract,
        SearchInput,
        DxToast,
        ToastMessage,
        PaymentDeleteConfirm
    },
    computed: {
      config() {
        return {
          permission: permissions.ARENDA,
          permission_type: permissionTypes.module,
        }
      },
      canEdit() {
        return canEdit(this);
      },
      canDelete() {
        return canDelete(this);
      },
        ...mapGetters({
            currentDepartment: "auth/currentDepartment"
        }),
        continueActionText(){
            if(this.createSelectionMode){
                return "Continuă plata";
            }
            if(this.editSelectionMode){
                return "Continuă editarea";
            }
            if(this.deleteSelectionMode){
                return "Continuă ștergerea";
            }
        }
    },
    methods:{
         ...mapActions({
            getContracts: "leasecontracts/getContracts",
        }),
        startCreating(){
            this.createSelectionMode = true;
            this.actionSelectionMode = false;
        },
        startEditing(){
            this.editSelectionMode = true;
            this.actionSelectionMode = false;
        },
        startDeleting(){
            this.deleteSelectionMode = true;
            this.actionSelectionMode = false;
        },
        persistAction(){
            if(this.createSelectionMode){
                this.showPayContractForCreate();
            }
            if(this.editSelectionMode){
                this.showPayContractForEdit();
            }
            if(this.deleteSelectionMode){
                this.deleteSelectionMode = false;
                this.showPaymentDeleteConfirm();
            }
        },
        showContracts(){
            this.currentModuleCode = 'CONTRACTS';
            this.$refs.contractList.expandAll(false);
            this.setActiveFooterMenu('CONTRACTS');
        },
        showPayments(){
            this.currentModuleCode = 'PAYMENTS';
            this.$refs.contractList.expandAll(false);
            this.setActiveFooterMenu('PAYMENTS');
        },
        showReports(){
            this.$refs.toastMessage.showInfo('Funcția este în curs de dezvoltare!');
            //this.currentModuleCode = 'REPORTS';
            //this.setActiveFooterMenu('REPORTS');
        },
        hidePaymentDeleteConfirm(){
            this.paymentDeleteConfirmVisibility = false;
        },
        hidePaymentDeleteConfirmAndRefresh(){
            this.hidePaymentDeleteConfirm();
            this.$refs.contractPaymentList.refreshFromOutside();
        },
        showPaymentDeleteConfirm(){
            this.selectedPayments = this.$refs.contractPaymentList.getSelectedPayments();
            this.paymentDeleteConfirmVisibility = true;
        },
        selectAll(){
            this.$refs.contractList.selectAll(true);
        },
        showRentFilter(){
            this.rentFilterVisibility = true;
        },
        hideRentFilter(){
            this.rentFilterVisibility = false;
        },
        showPayContractForCreate(){
            this.selectedContracts = this.$refs.contractPaymentList.getSelectedContracts();
            if(this.selectedContracts.length > 0){
                this.cancelAllSelectionMode();
                this.paymentIsInEditMode = false;
                this.payContractVisibility = true;
            }
            else{
                this.$refs.toastMessage.showError('Trebuie selectat cel puțin un contract!');
            }
        },
        showPayContractForEdit(){
            this.selectedPayments = this.$refs.contractPaymentList.getSelectedPayments();
            this.selectedContracts = [];
            this.selectedPayments.forEach(p => {
                let checkContract = this.selectedContracts.find(c => c.uuid == p.contractInfo.uuid);
                console.log(checkContract);
                if(checkContract === undefined){
                    this.selectedContracts.push(p.contractInfo);
                }
            });
            
            console.log('showPayContractForEdit contracts ', this.selectedContracts);
            console.log('showPayContractForEdit payments ', this.selectedPayments);

            if(this.validatePaymentEdit()){
                this.paymentIsInEditMode = true;
                this.editSelectionMode = false;
                this.payContractVisibility = true;
            }
            else{
                this.$refs.toastMessage.showError('Trebuie selectat plati numai din un an!');
            }
        },
        validatePaymentEdit(){
            let isValid = true;
            // let ownerID;
            // let contractID;
            // let year;
            // this.selectedPayments.forEach(payment => {
            //     console.log('payment validate for edit -> ', payment);
            //     if(
            //         !(
            //             (ownerID === undefined || payment.area_owner_uuid == ownerID) &&
            //             (contractID === undefined || payment.lease_contract_uuid == contractID) &&
            //             (year === undefined || payment.payment_details[0].year == year) && isValid
            //         )
            //     ){

            //         isValid = false;
            //     }
            //     ownerID = payment.area_owner_uuid;
            //     contractID = payment.lease_contract_uuid;
            //     year = payment.payment_details[0].year;
            // });
            return isValid;
        },
        hidePayContract(){
            this.payContractVisibility = false;
        },
        reloadContractPayments(){
            this.$refs.contractPaymentList.fillContracts(1, null, this.$refs.contractPaymentList.fillCallback);
        },
        changeSelectionMode(){
            // this.$refs.toastMessage.showInfo('Funcția este în curs de dezvoltare!');
            this.actionSelectionMode = !this.actionSelectionMode;
        },
        cancelAllSelectionMode(){
            this.actionSelectionMode = false;
            this.createSelectionMode = false;
            this.editSelectionMode = false;
            this.deleteSelectionMode = false;
        },
        setActiveFooterMenu(code){
            this.footerMenuItems.forEach((menu_item) => {
                if(menu_item.code == code){
                menu_item.selected = true;
                }
                else if(!menu_item.spacer){
                menu_item.selected = false;
                }
            });
        },
        searchByText(searchText){
            this.$set(this.filterData, 'general_search', searchText);
        },
        searchByStatus(filter_status){
            this.showRentFilter();
            setTimeout(this.$refs.rentFilter.mainFilterClick, 100, filter_status);

        },
        scrollDown(){
            this.showQuickSearch = true;
        },
        scrollUp(){
            this.showQuickSearch = false;
        },
    }
}
</script>

<style lang="scss">
body {
  background-color: #f5f5f5;
}
.toastStyle{
  z-index: 6010;
}
@import "@/assets/scss/rent/rent.scss";


.rent-enter-active,
.rent-leave-active {

}

.rent-enter-from,
.rent-leave-to {

}




</style>
