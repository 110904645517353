<!-- template for the modal component -->
<template type="x/template">
  <transition name="modal" id="contact-info-dialog">
    <div class="modal-mask" v-show="show">
      <div class="modal-container" @click.stop>
        <div class="modal-header">
          <h3>{{ title }}</h3>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="form-group col-sm-4">
              <label class="control-label">{{$t("common.date_from")}}</label>
              <date-picker2
                v-model="work_started_at"
                :value-type="work_started_at_from_input"
                format="YYYY-MM-DD"
                type="date"
                placeholder="yyyy-mm-dd"
                :input-class="['date-picker-style']"
                :class="{ 'field-error' : this.$v.modalData.work_started_at.$error }"
                @change="onWorkStartedAtChanged"
                ref="date_from"
              ></date-picker2>
            </div>
            <div class="form-group col-sm-4 ml-lg-5">
              <label class="control-label">{{$t("common.date_to")}}</label>
              <date-picker2
                v-model="work_ended_at"
                :value-type="work_ended_at_from_input"
                format="YYYY-MM-DD"
                type="date"
                placeholder="yyyy-mm-dd"
                :input-class="['date-picker-style']"
                :class="{ 'field-error' : this.$v.modalData.work_ended_at.$error }"
                @change="onWorkEndedAtChanged"
                ref="date_from"
              ></date-picker2>
            </div>
          </div>
        </div>
        <div class="modal-footer col-lg-12">
          <div class="form-group row justify-content-end">
            <div class="col-lg-4">
              <button class="btn btn-success" @click="finish()">{{$t("common.export")}}</button>
            </div>
            <div class="col-lg-4">
              <button class="modal-default-button btn btn-secondary ml-3" @click="close()">{{$t("common.cancel")}}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import DatePicker2 from "vue2-datepicker";
import moment from 'moment'
import {today} from "@/helpers/dateHelper";
import required from "vuelidate/src/validators/required";

export default {
  name: 'SCEmployeeWorkExportDialog',
  props: ['show', 'data'],
  data: function () {
    return {
      modalData: this.data,
      work_started_at: null,
      work_ended_at: null,
      work_started_at_from_input: null,
      work_ended_at_from_input: null,
    };
  },
  components: {
    DatePicker2,
  },
  computed: {
    title() {
      return this.$t('common.export_to_excel');
    }
  },
  methods: {
    close: function () {
      this.$emit('close');
    },
    finish () {
      this.$v.modalData.$touch();
      if(!this.$v.modalData.$invalid) {
        this.$emit('onOK', {
          work_started_at: this.work_started_at,
          work_ended_at: this.work_ended_at,
          employee_uuid: this.modalData.employee_uuid,
        });
        this.close();
      }
    },
    onWorkStartedAtChanged(date) {
      this.$set(this.modalData, 'work_started_at', date);
    },
    onWorkEndedAtChanged(date) {
      this.$set(this.modalData, 'work_ended_at', date);
    },

  },
  mounted() {
      this.work_started_at = moment().startOf('month').format('YYYY-MM-DD');
      this.onWorkStartedAtChanged(this.work_started_at);
      this.work_ended_at = moment().endOf('month').format('YYYY-MM-DD');
      this.onWorkEndedAtChanged(this.work_ended_at);
  },
  watch: {
    data: function(newVal) {
      this.modalData = newVal;
      this.onWorkEndedAtChanged(this.work_ended_at);
      this.onWorkStartedAtChanged(this.work_started_at);
    }
  },
  validations() {
    return {
      modalData: {
        work_started_at: {
          required,
        },
        work_ended_at: {
          required,
        }
      }
    }
  },
}
</script>

<style scoped>
* {
  box-sizing: border-box;
}
</style>
