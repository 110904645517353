<template>
  <div class="boolean-control">
    <b-form-checkbox switch :size="size ? size : 'lg'"
                     :class="{ 'field-error' : this.$v.value.$error }"
                     v-model="value">
      {{ data.name }}
    </b-form-checkbox>
  </div>
</template>

<script>
import requiredIf from "vuelidate/lib/validators/requiredIf";

export default {
  name: 'BooleanControl',
  props: ['data', 'size'],
  data() {
    return {
      value: false,
      controlData: this.data ? this.data : {},
    }
  },
  methods: {
      validate() {
        this.$v.value.$touch();
        return !this.$v.value.$invalid;
      },
      getBoolean(val) {
        if(val && val.toString().toLowerCase().trim() === 'true') {
          return true;
        } else if(val && val.toString().toLowerCase().trim() === 'false') {
          return false;
        } else if(val == '1') {
          return true;
        } else if(val == '0') {
          return false;
        } else {
          return Boolean(val);
        }
      }
  },
  mounted() {
      this.$set(this, 'value', this.getBoolean(this.data.value));
      this.$set(this.controlData, 'validate', this.validate);
  },
  watch: {
    data: {
      handler: function(newVal) {
        this.controlData = newVal;
      },
      deep: true,
      immediate: true,
    },
    value: function(newVal) {
      this.$emit('onChange', newVal);
    },
  },
  validations() {
    return {
      value: {
        required: requiredIf(function(val) {
          return this.data.is_required === 1;
        })
      },
    }
  },
}
</script>
