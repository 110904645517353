<template>
  <card :title="title" id="user-details" v-if="canEdit || canDelete" v-cloak>
    <span class="action_buttons">
      <img src="~@/assets/images/desktop/icons/controls/plus.svg" class="icon-svg" :title="$t('create_user')" @click="newUser()"/>
    </span>
    <div class="col-lg-8">
      <div class="row">
        <div class="col-lg-8 pb-3" v-if="data && data.uuid">
          <h5>{{$t("assign_departments")}}</h5>
          <department-list :data="department_list" :user="data"  v-if="!is_company_admin"
                           @onDepartmentAssigned="onDepartmentAssigned"
                           @onRoleChanged="onRoleChanged"
                           @onDepartmentRevoked="onDepartmentRevoked"
          ></department-list>
          <p  class="text-danger" v-else>{{$t("user_marked_as_company_admin")}}</p>
        </div>
        <div class="col-lg-8 pb-3 text-danger" v-else>
          {{ $t('company.departments.unable_to_assign') }}
        </div>
      </div>
      <div class="row" v-if="false">
        <div class="form-group col-sm-12 col-lg-8">
          <label class="control-label">{{$t("unit")}}</label>
          <b-form-select v-model="data.unit_uuid" class="form-control"
                         :options="unit_list" @change="onUnitChanged"></b-form-select>
        </div>
      </div>
      <div class="row">
        <div class="form-group col-sm-12 col-lg-8">
          <label class="control-label">{{ $t('first_name') }}</label>
          <input type="text" v-model="data.first_name"
                 :class="{ 'field-error' : this.$v.data.first_name.$error }"
                 :placeholder='$t("first_name")'
                 class="form-control"/>
        </div>
      </div>
      <div class="row">
        <div class="form-group col-lg-8">
          <label class="control-label">{{ $t('last_name') }}</label>
          <input type="text" v-model="data.last_name"
                 :class="{ 'field-error' : this.$v.data.last_name.$error }"
                 :placeholder='$t("last_name")'
                 class="form-control"/>
        </div>
      </div>
      <div class="row">
        <div class="form-group col-sm-12 col-lg-8">
          <label class="control-label">{{$t("email")}}</label>
          <input type="text" v-model="data.email"
                 :class="{ 'field-error' : this.$v.data.email.$error }"
                 :placeholder='$t("email")'
                 class="form-control"/>
        </div>
      </div>
      <div class="row" v-if="!data.uuid">
        <div class="form-group col-sm-12 col-lg-4">
          <label class="control-label">{{ $t('password') }}</label>
          <input type="password" v-model="data.password"
                 :class="{ 'field-error' : this.$v.data.password.$error }"
                 :placeholder='$t("password")'
                 class="form-control"/>
        </div>
        <div class="form-group col-sm-12 col-lg-4">
          <label class="control-label">{{ $t('confirm_password') }}</label>
          <input type="password" v-model="data.confirm_password"
                 :class="{ 'field-error' : this.$v.data.confirm_password.$error }"
                 :placeholder='$t("confirm_password")'
                 class="form-control"/>
        </div>
      </div>
      <div class="row" v-else>
        <div class="form-group col-sm-12 col-lg-8" v-if="canEdit">
          <a v-on:click.stop.prevent="showPasswordDialog(data)"
             v-if="isCompanyAdmin || (data.uuid === user && user.uuid)"
             class="c-pointer additional">{{ $t('change_password_click') }}</a>
        </div>
      </div>
      <div class="row">
        <div class="form-group col-sm-12 col-lg-8">
          <label class="control-label">{{ $t('phone') }}</label>
          <input type="text" v-model="data.phone_number"
          :placeholder='$t("phone")'
                 class="form-control"/>
        </div>
      </div>
      <div class="row">
        <div class="form-group col-lg-4">
          <b-form-checkbox switch size="lg"
                           v-model="is_company_admin"
                           :disabled="!data.uuid"
                           @change="onCompanyAdminChanged">Company admin</b-form-checkbox>
        </div>
        <div class="form-group col-lg-6">
          <b-form-checkbox switch size="lg"
                           v-model="api_access"
                           @change="onApiAccessChanged"
                           :disabled="!data.uuid"
          >Smagria App access</b-form-checkbox>
        </div>
      </div>

    </div>
    <div class="col-lg-12 action-buttons pt-4">
      <div class="row form-group">
        <div class="col-lg-8 text-center text-lg-left">
          <div class="row">
            <div class="col-lg-6">
              <button class="btn btn-md btn-secondary btn-secondary" @click="gotoList">{{ $t('back_to_user_list') }}</button>
            </div>
            <div class="col-lg-3" v-if="canEdit">
              <button class="btn btn-md btn-primary" @click="save">{{ $t('save') }}</button>
            </div>
            <div class="col-lg-3" v-if="data.uuid && canDelete">
              <button class="btn btn-md btn-danger" @click="confirmDelete">{{ $t('delete') }}</button>
            </div>
            <change-password-dialog :show="showPasswordModal" @close="showPasswordModal = false"
                                    :data="passwordModalData"
                                    @onPasswordChanged="onPasswordChanged"
            ></change-password-dialog>
          </div>
        </div>
      </div>
    </div>
  </card>
  <div v-else>
    <forbidden></forbidden>
  </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex'
    import * as type from '../../store/mutation-types'
    import {successMessage, errorMessage, buildDropdownOptions, errorMessageList} from '~/helpers/common'
    import required from "vuelidate/src/validators/required";
    import requiredIf from "vuelidate/src/validators/requiredIf";
    import ChangePasswordDialog from "./partials/ChangePasswordDialog";
    import {hasPermission, permissions, actions, permissionTypes, canEdit, canDelete} from "~/helpers/Permissions";
    import DepartmentList from "./partials/DepartmentList";
    import Forbidden from "~/pages/errors/Forbidden.vue";
    import UserActionControls from "~/pages/users/partials/UserActionControls.vue";
    import Integer from "vuelidate/lib/validators/integer";

    export default {
        name: 'userDetails',
        middleware: 'check-company-admin',
        data() {
            return {
                initialUser: {
                    uuid: null,
                    unit_uuid: '',
                },
                imageData: {},
                image_removed: false,
                showModal: false,
                modalData: {},
                showPasswordModal: false,
                passwordModalData: {},
                _units: this.units ? this.units : [],
                is_company_admin: false,
                department_list: this.departments ? this.departments : [],
                units: [], //
                api_access: false,
            }
        },
        components: {
          Forbidden,
            DepartmentList,
            ChangePasswordDialog,
        },
        computed: {
          config() {
            return {
              permission: permissions.USER_MANAGEMENT,
              permission_type: permissionTypes.module,
            };
          },
            ...mapGetters({
                user: 'auth/user',
                userData: 'user/userData',
                isCompanyAdmin: 'auth/isCompanyAdmin',
                roles: 'roles/roles',
                departments: 'company/departments',
                domain: 'auth/domain'
            }),
            data: {
                get: function() {
                    return this.userData ? this.userData : this.initialUser;
                },
                set: function(newVal) {
                    return newVal;
                }
            },
            server() {
                return window.location.host;
            },
            title() {
                return this.data.uuid ? this.$t('edit_user') : this.$t('create_user');
            },
            unit_list() {
                let units = Object.assign([], this._units);

                if(!this.companies) {
                    units = [];
                }

                return buildDropdownOptions(units, {
                    value: 'uuid',
                    text: 'name',
                }, {
                    value: null,
                    text: '-',
                });
            },
            role_list() {
                let roles = Object.assign([], this.roles);

                if(!this.roles) {
                    roles = [];
                }

                return buildDropdownOptions(roles, {
                    value: 'uuid',
                    text: 'name',
                }, {
                    value: null,
                    text: '-',
                });
            },
            canEdit() {
                return canEdit(this);
            },
            canDelete() {
                return canDelete(this);
            },
        },
        methods: {
            ...mapActions({
                getUser: 'user/getUser',
                createUser: 'user/createUser',
                updateUser: 'user/updateUser',
                deleteUser: 'user/deleteUser',
                changePassword: 'user/changePassword',
                getRoles: 'roles/getRoles',
                getDepartments: 'department/getDepartments'
            }),
            getUserData() {
                let vm = this;

                if(this.$route.params.uuid && this.$route.params.uuid !== 'add') {
                    this.$store.dispatch('user/getUser', {
                        uuid: this.$route.params.uuid
                    }).then((response) => {

                        if(response === null || ( response && !response.is_active && response.deleted_at )) {
                            errorMessage(vm, vm.$t('user_not_available'));
                            setTimeout(function(){
                                vm.$router.push({
                                    path: '/users',
                                });
                            }, 2000);
                        } else {
                          //loaded
                          vm.getDepartments({
                            user_filter_uuid: response.uuid
                          });
                        }
                    }).catch((e) => {
                        errorMessage(vm, vm.$t('user_not_found'));
                        setTimeout(function(){
                            vm.$router.push({
                                path: '/users',
                            });
                        }, 2000);
                    });
                } else {
                    this.$store.commit("user/"+type.SET_USER_DATA, this.initialUser);
                }
            },
            confirmDelete() {
                let vm = this;

                this.$dialog.confirm('Do you really want to delete this "'+this.data.name+'" user?', {})
                    .then((dialog) => {
                        vm.deleteUser({
                            uuid: this.data.uuid,
                        }).then((response) => {
                            dialog.close();
                            successMessage(vm, response.message);
                            setTimeout(function(){
                                vm.$router.push({
                                    path: '/users',
                                });
                            }, 2000);
                        }).catch((response) => {
                            dialog.close();
                            errorMessage(vm, response.message);
                        });
                    });
            },
            save() {
                let vm = this;
                this.$v.data.$touch();
                if(!this.$v.data.$invalid) {

                    if(this.data.image && this.data.image.uuid){
                        this.data.image = null;
                    }

                    if(this.data.password !== this.data.confirm_password) {
                        errorMessage(vm, vm.$t('passwords_not_match'));
                        return;
                    }

                    if (this.data.is_company_admin === 1) {
                      this.data.units = [];
                      this.data.roles = [];
                    }

                    if(this.data.uuid) {
                        this.updateUser(this.data).then((response) => {
                            successMessage(vm, response.message);
                        }).catch((e) => {
                            errorMessageList(vm, e.errors);
                        });
                    } else {
                        this.createUser(this.data).then((response) => {
                            successMessage(vm, response.message);
                            vm.$router.push({
                                path: '/users/'+response.data.uuid,
                            });
                        }).catch((e) => {
                            errorMessageList(this, e.errors);
                        });
                    }

                } else {
                    errorMessage(this, this.$t('fill_out_error'));
                }
            },
            newUser() {
                this.$store.commit('user/'+type.SET_USER_DATA, this.initialUser);

                try {
                    this.$router.push({
                        path: 'add'
                    }).catch((e) => {});
                }
                catch(e) {
                    window.location.href = '/users/add';
                }
            },
            gotoList() {
                this.$router.push({
                    path: '/users',
                })
            },
            showPasswordDialog(item) {
                this.passwordModalData = item;
                this.showPasswordModal = true;
            },
            onPasswordChanged(data) {
                let vm  = this;
                this.changePassword(data).then((response) => {
                    successMessage(vm, response.message);
                    vm.data.password = null;
                    vm.data.confirm_password = null;
                })
            },
            onUnitChanged(val) {

            },
            onRoleChanged(val) {
              if(this.data.units && this.data.units instanceof Number) {
                  this.data.units = [];
              }

                this.data?.units.splice(
                  this.data?.units.indexOf(
                    this.data?.units.find(d => d.uuid === val.uuid)
                  ), 1, val
                )
            },
            onCompanyAdminChanged(val) {
                this.data.is_company_admin = val ? 1 : 0;
            },
            onDepartmentAssigned(data) { //companies[0] => departments
              if (!this.data?.units.find(d => d.uuid === data.uuid)) {
                this.data.units.push(data);
              }
            },
            onDepartmentRevoked(data) {
              this.data?.units.splice(
                this.data?.units.indexOf(
                  this.data?.units.find(d => d.uuid === data.uuid)
                ), 1
              );
            },
          onApiAccessChanged(val) {
            this.data.api_access = val  ? 1 : 0;
          },
        },
        mounted() {
            this.getRoles({});
            this.getUserData();
        },
        watch: {
            userData: {
                handler: function(newVal) {
                    this.data = newVal;
                    this.is_company_admin = (newVal && newVal.is_company_admin === 1);
                    this.api_access = (newVal && newVal.api_access === 1);
                },
                deep: true,
            },
            units: {
                handler: function(newVal) {
                    this._units = newVal;
                }
            },
            departments: {
                handler: function(newVal) {
                    this.department_list = newVal;
                },
                deep: true,
              immediate: true,
            }
        },
        validations() {
            let vm = this;
            return {
                data: {
                    first_name: {
                        required,
                    },
                    last_name: {
                        required,
                    },
                    email: {
                        required,
                    },
                    password: {
                        required: requiredIf(function(pw) {
                            return vm.data.uuid === null;
                        })
                    },
                    confirm_password: {
                        required: requiredIf(function(pw) {
                            return vm.data.uuid === null;
                        }),
                    }

                },
            }
        },
    }
</script>
