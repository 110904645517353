<template>
  <div id="sc-holiday-list-filters" class="filter-actions col-lg-12 filters p-3">
    <div class="row">
      <div class="col-lg-4">
        <label class="control-label">{{$t("name")}}</label>
        <input v-model="filters.name" class="form-control" :placeholder="$t('name')"/>
      </div>
      <div class="col-lg-2 mt-4">
        <button class="btn-sm btn-primary col-lg-12" @click="applyFilters">{{$t("common.show")}}</button>
      </div>
      <div class="col-lg-3 mt-4">
        <button class="btn-sm btn-secondary col-lg-12" @click="resetFilters">{{$t("common.reset_filters")}}</button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import * as type from '../../../../store/mutation-types'

export default {
  name: 'SCBonusFilters',
  props: ["limit"],
  data() {
    return {
      filters:  this.gridFilters ? this.gridFilters : {},
    }
  },
  computed: {
    ...mapGetters({
      gridFilters: 'scHoliday/scHolidayFilters',
    }),
  },
  methods: {
    ...mapActions({
      getData: 'scHoliday/getSCHolidays',
    }),
    applyFiltersClick() {
      this.$set(this.filters, 'page', 1);
      this.$router.push({
          path: '/sc/holiday',
          params: {
            page: 1,
          }
        }
      );
      this.applyFilters();
    },
    applyFilters() {
      this.$emit('onFiltersApplied', this.filters);
      this.getData(this.filters);
    },
    resetFilters() {
      this.filters = {
        name: '',
        page: 1,
        limit: this.limit,
      };

      this.$store.commit("scHoliday/"+type.SET_SC_HOLIDAY_FILTERS, this.filters);
      this.applyFilters();
    },
  },
  mounted(){
    this.resetFilters();
  },
  watch: {
    gridFilters: {
      handler: function(newVal) {
        this.filters = newVal;
      },
      deep: true,
    }
  }
}
</script>
