<template>
    <DxToast
      class="toastStyle"
      ref="toastControl"
      :visible="push_notification.is_visible"
      :close-on-outside-click="true"
      type=""
      position="top"
      :display-time="3000"
      :width="350"
       :wrapper-attr="toastAttributes"
    >
      <template #content>
        <div
          :class="[
            { toast_alert_message: true },
            { toast_error_message: push_notification.type == 'error' },
            { toast_info_message: push_notification.type == 'info' },
            { toast_success_message: push_notification.type == 'success' },
          ]"
        >
          <div><img /></div>
          <div class="alert_message_text">
            <span v-if="push_notification.message && push_notification.message.length > 0">{{ push_notification.message }}</span>
            <p v-for="(msg, idx) in push_notification.messages" :key="idx">{{msg}}</p>
          </div>
        </div>
      </template>
    </DxToast>
</template>

<script>

import { DxToast } from "devextreme-vue/toast";


export default {
    name: "ToastMessage",
    data(){
        return {
            push_notification: {
                is_visible: false,
                message: "Selectează o lucrare!",
                messages: {},
                type: "error",
            },
            toastAttributes: {
                class: 'toast-wrapper-attr'
            }
        };
    },
    components: {
        DxToast
    },
    methods: {
        showError(message){
            this.showToastMessage('error', message);
        },
        showInfo(message){
            this.showToastMessage('info', message);
        },
        showSuccess(message){
            this.showToastMessage('success', message);
        },
        showToastMessage(type, message) {

          if(message === undefined) {
            return;
          }

            if(Array.isArray(message)){
              this.push_notification.messages = message;
              this.push_notification.message = '';
            }
            else{
              this.push_notification.message = message;
              this.push_notification.messages = [];
            }
            this.push_notification.type = type;
            this.$refs.toastControl.instance.show();
        },

    }
}
</script>

<style lang="scss">
    @import "@/assets/scss/components/toastMessage.scss";
</style>
