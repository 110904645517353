<template>
  <div>
    <div v-if="data.canceled_at" class="cancel-text">
      {{ content }}
    </div>
    <div v-else>
      {{ content }}
    </div>
    <div v-html="subtitle"></div>
  </div>
</template>
<script>
export default {
  components: {},
  props: ["data", "header"],
  name: "CanceledRow",
  data() {
    return {};
  },
  computed: {
    content() {
      if (this.header.col && this.header.col) {
        const getNestedProperty = (obj, path) => {
          return path.split(".").reduce((acc, part) => acc && acc[part], obj);
        };

        const value = getNestedProperty(this.data, this.header.col);

        if (this.header.suffix) {
          return `${value} ${this.header.suffix}`;
        }

        if (value) {
          return value;
        }
      }

      return "";
    },
    subtitle() {
      let link = "";

      if (this.header.subtitle && this.header.subtitle instanceof Function) {
        let subtitleData = this.header.subtitle(this.data);

        if (subtitleData instanceof Object) {
          link =
            '<a href="' + subtitleData.link + '">' + subtitleData.title + "</a>";
        } else {
          link = subtitleData;
        }
      }

      return link;
    },
  },
  methods: {},
};
</script>

<style scoped>
.cancel-text {
  color: #ff0015;
  border-radius: 5px;
}
</style>
