<template>
  <div class="view" v-if="canEdit || canDelete" v-cloak>
    <card :title="title" id="sc-work-list">
      <agro-filter-container :new-object="newObject" :title='$t("common.create_new", { name: title })'>
        <s-c-work-type-filters ref="filters" @onFiltersApplied="onFiltersApplied"/>
      </agro-filter-container>
    </card>
    <div class="unassigned-alert alert alert-warning mt-2" v-if="unassignedWorks.length > 0">
      <img src="~@/assets/images/desktop/icons/controls/alert.svg" class="icon-svg text-dark pr-2"/>
      {{ $t('salary_calculator.work_log.you_have_unassigned_works', { count: unassignedWorks.length }) }}
    </div>
    <div class="content-body pt-4">
      <div class="row">
        <div class="col-lg-12">
          <agro-grid
            :headers="headers"
            :rows="items"
            :paginate="true"
            :permission="config"
            :action="onPageSelected"
            :loading="loading"
          >
          </agro-grid>
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <forbidden></forbidden>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import * as type from '../../../store/mutation-types'
import SCWorkTypeFilters from "@/pages/salary-calculator/sc-work-type/partials/SCWorkTypeFilter";
import AgroGrid from "@/components/AgroGrid/index";
import {canDelete, canEdit, permissions, permissionTypes} from "@/helpers/Permissions";
import Forbidden from "~/pages/errors/Forbidden.vue";
import AgroFilterContainer from "@/components/AgroFilter/partials/AgroFilterContainer.vue";

export default {
  name: 'SCWorkTypeList',
  middleware: ['auth', 'check-company-admin'],
  data() {
    return {
      currentPage: 1,
      filters: this.scWorkTypeFilters ? this.scWorkTypeFilters : {},
      items: this.scWorks ? this.scWorks : [],
      pager: this.pagination ? this.pagination : {},
      loading: false,
    }
  },
  components: {
    AgroFilterContainer,
    Forbidden,
    AgroGrid,
    SCWorkTypeFilters,
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
      scWorkTypeFilters: 'scWork/scWorkFilters',
      scWorks: 'scWork/scWorks',
      pagination: 'common/pagination',
      users: 'user/users',
    }),
    title() {
      return this.$t('salary_calculator.work_type.title');
    },
    config() {
      return {
        permission: permissions.SC_WORK,
        permission_type: permissionTypes.module,
        edit: {
          url: '/salary-calculator/sc-work-type/{uuid}',
          callback: this.onItemEdited,
        },
        delete: {
          method: this.deleteObject,
          callback: this.onItemDeleted,
          disableIf: this.hasAssignedWorks,
          disableMessage: this.$t('common.delete_disabled')
        }
      };
    },
    canEdit() {
      return canEdit(this);
    },
    canDelete() {
      return canDelete(this);
    },
    headers() {
      return  [
        { name: this.$t('common.name'), col: 'name', width: '40%', subtitle: this.nameSubtitle },
        { name: this.$t('salary_calculator.work_type.assigned_to'), suffix: this.$t('salary_calculator.work.plural'), col: 'assigned_count', width: '20%' },
        { name: this.$t('common.created_at'), col: 'created_at', width: '20%', rowClass:'bg-danger' },
        { name: this.$t('common.last_update'), col: 'updated_at', width: '20%' },
      ];
    },
    unassignedWorks() {
        if(this.items) {
          return this.items.filter((item) => {
            return item.has_unassigned_works;
          });
        }

        return [];
    },
  },
  methods: {
    ...mapActions({
        deleteObject: 'scWork/deleteSCWork',
    }),
    onPageSelected(page_id) {
      this.filters.page = page_id;
      this.$store.commit("scWork/"+type.SET_SC_WORK_FILTERS, this.filters);
      this.$refs.filters.applyFilters();
    },
    newObject() {
      this.$router.push({
        path: 'sc-work-type/add'
      });
    },
    onItemEdited(item) {
      console.log('onItemEdited', item);
    },
    onItemDeleted(item) {
      console.log('onItemDeleted', item);
    },
    onFiltersApplied(filters) {
      this.loading = true;
    },
    hasAssignedWorks(item) {
      return item.assigned_count > 0;
    },
    nameSubtitle(item) {
      if(item.has_unassigned_works) {
        return {
          title: this.$t('salary_calculator.work_type.assign_to_group'),
          link: '/salary-calculator/sc-work/add?work_uuid='+item.uuid+'&work_name='+item.name,
        };
      }

      return '';
    }
  },
  watch: {
    scWorks: {
      handler: function(newVal) {
        this.items = newVal;
      },
      deep: true,
    },
    pagination: {
      handler: function(newVal) {
        this.pager = newVal;
      },
      deep: true,
    },
  }
}
</script>

<style scoped>

</style>
