<template>
  <div class="view">
    <card :title="$t('works.name')" id="batch-header-list">
      <agro-filter-container :hide-add="true" :title='title'>
        <work-input-filters
            :limit="limit"
            ref="filters"
            @onFiltersApplied="onFiltersApplied"
        />
      </agro-filter-container>
    </card>
    <div class="content-body pt-4">
      <div class="row">
        <div class="col-lg-12">
          <div class="table-responsive">
            <table class="table">
              <thead>
              <tr>
                <th class="thumbnail">{{$t('works.inputs.work_interval')}}</th>
                <th class="td-text">{{$t('common.created_at')}}</th>
                <th class="td-text">{{$t('common.name')}}</th>
                <th class="td-text">{{$t('works.inputs.product_name')}}</th>
                <th class="td-text">{{$t('works.inputs.quantity')}}</th>
                <th class="td-text">{{$t('works.inputs.unit')}}</th>
                <th class="td-text">{{$t('works.inputs.dose_ha')}}</th>
                <th class="td-text">{{$t('common.actions')}}</th>
              </tr>
              </thead>
              <tbody>
              <template v-for="(td, index) in items">
                <tr class="constant-tr">
                  <td>{{td.work_min_date}} - {{td.work_max_date}}</td>
                  <td>{{td.created_at}}</td>
                  <td>
                    {{td.related_works_names}}
                  </td>
                  <td>{{td.product}}</td>
                  <td>{{td.quantity}}</td>
                  <td>{{td.measure_unit_symbol}}</td>
                  <td>{{td.dose_per_ha}}</td>
                  <td>
                    <a v-on:click.stop.prevent="onDeletePressed(td)" :title="$t('common.delete')" class="c-pointer">
                      <img src="~@/assets/images/desktop/icons/controls/delete.svg" class="icon-svg danger-filter">
                    </a>
                  </td>
                </tr>
                <tr class="toggle-tr">
                  <td :colspan="4">
                    <table class="embedded-tbl">
                      <tr>
                        <th :colspan="3" class="toggle-header">
                          Parcells
                          <span @click="toggleParcells(index)">
                            <i v-if="!opened.includes(index)" class="fas fa-sort-down"> Open &#x25BE; </i>
                            <i v-if="opened.includes(index)" class="fas fa-sort-up"> Close &#x25B4; </i>

                          </span>
                        </th>
                      </tr>
                      <tr v-if="opened.includes(index)">
                        <th class="toggle-header"> {{$t('common.name')}} </th>
                        <th class="toggle-header"> {{$t('works.inputs.surface')}} </th>
                        <th class="toggle-header"> {{$t('works.inputs.quantity')}} </th>
                      </tr>
                      <tbody v-if="opened.includes(index)">
                        <tr v-for="(item, index) in td.affected_parcels">
                          <td> {{item.parcel.name}} </td>
                          <td class="d-flex justify-content-center"> {{item.parcel.surface}} </td>
                          <td class="justify-content-center"> {{td.dose_per_ha * item.parcel.surface}} </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </template>
              </tbody>
            </table>
          </div>
          <div class="row mt-3" v-show="items && items.length > 0">
            <b-pagination
                v-model="currentPage"
                :total-rows="pager.total"
                :per-page="perPage"
                aria-controls="items"
                align="left"
                class="pl-3 pb-3"
                @change="onPageSelected"
            ></b-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import WorkInputFilters from "~/pages/works/inputs/partials/WorkInputFilters.vue";
import * as type from "~/store/mutation-types";
import InputActionsTemplate from "~/pages/works/inputs/partials/InputActionsTemplate.vue";
import {canDelete, permissions, permissionTypes} from "~/helpers/Permissions";
import AgroFilterContainer from "@/components/AgroFilter/partials/AgroFilterContainer.vue";
import {errorMessage, successMessage} from "@/helpers/common";

export default {
  name: 'WorkInputList',
  middleware: ['auth', 'check-company-admin'],
  data() {
    return {
      filters: this.inputFilters ? this.inputFilters : {},
      currentPage: 1,
      limit: 25,
      perPage: this.limit ? this.limit : 25,
      items: this.inputs ? this.inputs : [],
      pager: this.pagination ? this.pagination : {},
      loading: false,
      opened: [],
    }
  },
  components: {
    AgroFilterContainer,
    WorkInputFilters,
  },
  computed: {
    ...mapGetters({
      inputs: 'works-inputs/inputs',
      inputFilters: 'works-inputs/inputFilters',
      pagination: 'common/pagination',
    }),
    config() {
      return {
        permission: permissions.WORKS,
        permission_type: permissionTypes.module,
        delete: {
          callback: this.onItemDeleted,
          method: this.deleteObject,
          disableIf: this.disableDeleteIf,
          disableMessage: this.$t('works.inputs.cannot_be_deleted')
        },
      };
    },
    title() {
      return this.$t('work.title');
    },
    canDelete() {
      return true;
      // return canDelete(this) && this.data.is_deletable;
    },
  },
  methods: {
    ...mapActions({
      deleteInput: 'works-inputs/deleteInput',
    }),
    toggleParcells(id) {
      const index = this.opened.indexOf(id);
      if (index > -1) {
        this.opened.splice(index, 1)
      } else {
        this.opened.push(id)
      }
    },
    onPageSelected(page_id) {
      this.filters.page = page_id;
      this.$store.commit("works-inputs/"+type.SET_INPUT_FILTERS, this.filters);
      this.$refs.filters.applyFilters();
    },
    onSortBy(data) {
      this.inputFilters.sortBy = data.sortBy;
      this.inputFilters.sortDirection = data.sortDirection;
      this.onPageSelected(this.currentPage);
    },
    onFiltersApplied(filters) {
      this.loading = true;
    },
    actionColTemplate(col) {
      return InputActionsTemplate;
    },
    onItemDeleted(item) {
      this.items.splice(
          this.items.indexOf(
              this.items.find(it => it.uuid === item.uuid)
          ), 1
      );
    },

    onDeletePressed(item) {
      let vm = this;

      this.$dialog.confirm('Do you really want to delete?', {title:'Test'})
          .then((dialog) => {
            vm.deleteInput({
              uuid: item.uuid,
              batch_uuid: item.batch_uuid,
              department_uuid: item.department_uuid,
            }).then((response) => {
              dialog.close();
              vm.$bvToast.toast(response.message, {
                title: 'Success',
                variant: 'success',
                solid: true
              });
              //remove deleted from list
              this.onItemDeleted(item)
            }).error((response) => {
              dialog.close();
              vm.$bvToast.toast(response.message, {
                title: 'Error',
                variant: 'error',
                solid: true
              });
            });

            dialog.close();
          });

    },
  },
  watch: {
    inputs: {
      handler: function(newVal) {
        this.items = newVal;
      },
      deep: true,
    },
    pagination: {
      handler: function(newVal) {
        this.pager = newVal;
      },
      deep: true,
      immediate: true,
    },
  },
  mounted(){
    this.onPageSelected(1);
  }
}

</script>

<style scoped>

.toggle-header {
  font-size: 14px!important;
  background: none!important;
  border-bottom: 1px solid gray;
}

.embedded-tbl tr th {
  font-size: 10px;
  border-top: none;
  border-bottom: none;
}
.embedded-tbl tr td {
  font-size: 14px;
  border-top: none;
}

.toggle-tr {
  border-bottom: 3px solid #e2e3e5;
}

</style>
