import * as api from '../../api/index'
import * as endpoints from '../../api/endpoints'
import * as types from "../../mutation-types";

const actions = {
  getSCEmployeeRoles({commit}, payload) {
    return api.fetch(endpoints.SC_EMPLOYEE_ROLE, payload, commit,(response) => {
      commit(types.SET_SC_EMPLOYEE_ROLES, response.data.data);
      commit("common/"+types.SET_PAGINATION, response.data.pagination, { root: true });
    });
  },
  getSCEmployeeRole({commit}, payload) {
    return api.fetch(endpoints.SC_EMPLOYEE_ROLE+'/'+payload.uuid, payload, commit,(response) => {
      commit(types.SET_SC_EMPLOYEE_ROLE, response.data);
    });
  },
  createSCEmployeeRole({commit}, payload) {
    return api.post(endpoints.SC_EMPLOYEE_ROLE, payload, commit, (response) => {
      commit(types.SET_SC_EMPLOYEE_ROLE, response.data);
    });
  },
  updateSCEmployeeRole({commit}, payload) {
    return api.put(endpoints.SC_EMPLOYEE_ROLE+'/'+payload.uuid, payload, commit, (response) => {
      commit(types.UPDATE_SC_EMPLOYEE_ROLE_ITEM, response.data);
    });
  },
  deleteSCEmployeeRole({commit}, payload) {
    return api.remove(endpoints.SC_EMPLOYEE_ROLE+'/'+payload.uuid, payload, commit, (response) => {
      commit(types.DELETE_SC_EMPLOYEE_ROLE_ITEM, response.data);
    });
  },
}

export default actions;
