<template>
  <div class="login-row">
    <div class="login-form">
      <div class="login-container">
        <div class="img-container">
          <img src="~@/assets/images/smagria_text.svg" @error="onLogoLoadError" class="company-logo w-100 text-center"/>
        </div>
        <div v-if="domain" class="domain-container">{{ domain.company }}</div>
        <div class="card">
          <div class="card-body">
            <p class="text-danger text-center" v-if="error">{{ error }}</p>
            <div class="form-group">
              <label class="w-100 text-left">{{ $t('email') }}</label>
              <input v-model="data.email" :placeholder="$t('email')"
                     :class="{ 'field-error' : this.$v.data.email.$error }"
                     class="form-control" type="email" name="email" v-on:keyup.enter="signIn">
            </div>
            <div class="form-group">
              <label>{{ $t('password') }}</label>
              <input v-model="data.password" :placeholder="$t('password')"
                     :class="{ 'field-error' : this.$v.data.password.$error }"
                     class="form-control" type="password" name="password" v-on:keyup.enter="signIn">
            </div>
          </div>
          <div class="card-footer">
            <div class="login-footer">
              <v-button :loading="data.busy" @click="signIn" class="w-100 login-button">{{ $t('login') }}</v-button>
              <router-link :to="{ path: 'password-request' }" class="forgot">
                {{ $t('forgot_password') }}
              </router-link>
            </div>
          </div>
        </div>
      </div>


      <div class="col-lg-8 m-auto text-center" v-if="false">

        <div class="row">
          <div class="col-lg-12 mt-4 pb-3 mx-auto text-center" v-if="domain">
            <img src="~@/assets/images/smagria_text.svg" @error="onLogoLoadError" class="company-logo"/>
            <h2 class="p-2">{{ domain.company }}</h2>
          </div>
        </div>
        <card>
          <div class="form-group row">
            <label class="col-md-3 col-form-label text-right">{{ $t('email') }}</label>
            <div class="col-md-7">
              <input v-model="data.email"
                     :class="{ 'field-error' : this.$v.data.email.$error }"
                     class="form-control" type="email" name="email" v-on:keyup.enter="signIn">
            </div>
          </div>
          <div class="form-group row">
            <label class="col-md-3 col-form-label text-right">{{ $t('password') }}</label>
            <div class="col-md-7">
              <input v-model="data.password"
                     :class="{ 'field-error' : this.$v.data.password.$error }"
                     class="form-control" type="password" name="password" v-on:keyup.enter="signIn">
            </div>
          </div>
          <div class="form-group row">
            <div class="col-md-3" />
            <div class="col-md-7 d-flex">
              <checkbox v-model="remember" name="remember">
                {{ $t('remember_me') }}
              </checkbox>

              <router-link :to="{ path: 'password-request' }" class="small ml-auto my-auto">
                {{ $t('forgot_password') }}
              </router-link>
            </div>
          </div>

          <div class="form-group row mt-3">
            <div class="col-md-7 offset-md-3 d-flex">
              <!-- Submit Button -->
              <v-button :loading="data.busy" @click="signIn">
                {{ $t('login') }}
              </v-button>
            </div>
          </div>

        </card>
      </div>

    </div>
    <div class="login-image"></div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Cookies from 'js-cookie'
import required from "vuelidate/src/validators/required";
import {errorMessage, errorMessageList} from "@/helpers/common";
import Logo from '@/assets/images/smagria_icon.png'

export default {
  layout(context) {
    return "noheader";
  },
  middleware: 'guest',

  metaInfo () {
    return { title: this.$t('login') }
  },

  data() {
    return {
      data: {
        email: '',
        password: ''
      },
      remember: false,
      domain: this.domainName ? this.domainName  : undefined,
      companyLogo: undefined,
      error: '',
    }
  },
  computed: {
    ...mapGetters({
      domainName: 'auth/domain',
      user: 'auth/user',
    }),
    baseURL() {
      return this.domain.domain_path;
    },
    companyImageSRC: {
      get: function() {
        if(this.companyLogo) {
          return this.companyLogo;
        }
        return this.baseURL+'/company-image/'+this.domain.uuid+'?height=120';
      },
      set: function(value) {
        this.companyLogo = value;
      }
    }
  },
  methods: {
    ...mapActions({
      login: 'auth/login',
      logout: 'auth/logout',
    }),
    async signIn () {
      let vm = this;
      this.$v.data.$touch();
      if(!this.$v.data.$invalid) {
        this.login(this.data).then((response) => {
          vm.$store.dispatch('auth/saveToken', {
            token: response.token,
            remember: vm.remember
          });

          vm.$store.dispatch('auth/fetchUser').then((resp) => {
            this.redirect();
          });

        }).catch((e) => {
          if (e.response.data && e.response.data.errors && e.response.data.errors.error[0]) {
            vm.$set(vm, 'error', e.response.data.errors.error[0]);
          } else {
            vm.$set(vm, 'error', e.response.data.message);
          }
        });
      }
    },
    onLogoLoadError(val) {
      this.companyImageSRC = Logo;
    },
    redirect () {
      const intendedUrl = Cookies.get('intended_url')

      if (intendedUrl !== undefined) {
        Cookies.remove('intended_url')
        this.$router.push({ path: intendedUrl })
      } else {
        this.$router.push({ path: '/dashboard' })
      }
    }
  },
  async mounted() {
    await this.$store.dispatch('auth/fetchUser');
    if (this.$store.getters['auth/user']) {
      await this.$router.push({
        path: '/dashboard',
      })
    }
  },
  watch: {
    domainName: function(newVal){
      this.domain = newVal;
    }
  },
  validations() {
    return {
      data: {
        email: {
          required,
        },
        password: {
          required,
        },
      },
    }
  },
}
</script>

<style scoped>
.company-logo {
  max-height: 100px;
  max-width: 133px;
}


@import "@/assets/scss/login.scss";

</style>

