<template type="x/template">
  <transition name="modal" id="contact-info-dialog">
    <div>
      <DxPopup
        :height="popupHeight"
        width="100vw"
        :close-on-outside-click="false"
        :show-close-button="true"
        :visible="showPopup"
        position="bottom"
        @hidden="$emit('hidden')"
        :wrapper-attr="popupAttributes"
        :drag-enabled="false"
      >
        <template #title>
          <div class="map-filter-title">
            <div class="map-filter-close">
              <img
                src="@/assets/images/icons/dialog-close-white.svg"
                @click="closeDialog"
              />
            </div>
          </div>
        </template>
        <template #content>
          <div class="map-filter-container">
            <DxTabPanel
            :data-source="filterTabs"
            :selected-item="currentSmavertizareItem"
            :selected-index="currentSmavertizareStep"
            @selection-changed="mainTabSelectionChanged($event)"
            :show-nav-buttons="false"
            class="map-filter-tabs"
            tabs-position="bottom"
            >

              <template #item="{data: mainStep}">
                <div>
                   <DxTabPanel v-show="mainStep == 'FILTRARE'"
                    :element-attr="tabPanelAttributes"
                    :data-source="mapFilterSteps"
                    :loop="false"
                    :selected-item="currentItem"
                    :selected-index="currentStep"
                    :animation-enabled="false"
                    :swipe-enabled="false"
                    :show-nav-buttons="false"
                    @selection-changed="tabSelectionChanged($event)"
                    :drag-enabled="false"
                    tabs-position="bottom"
                   >
                      <template #item="{data: step}">
                        <div>
                          <DxScrollView class="map-filter-main-scroll" :use-native="true">
                            <div v-show="step == 1">
                              <div class="filter-item" @click="swipeFilterData('DEPARTMENT')">
                                <div class="first-row">
                                  <div class="main_filter_title">Departament / Partener</div>
                                  <div class="main_filter_count">
                                    {{ localDepartments ? localDepartments.length : 0 }}
                                  </div>
                                  <img
                                    class="main_filter_right_arrow"
                                    src="@/assets/images/icons/right_arrow_dg.svg"
                                  />
                                </div>
                                <div v-if="localDepartments && localDepartments.length > 0"
                                  class="main_filter_selected">
                                  {{ departmentFilterText }}
                                </div>
                              </div>

                              <div class="filter-item">
                                <div class="filter-text">An agricol</div>
                                <div>
                                  <DxSelectBox
                                    v-model="filterData.season_uuid"
                                    :search-enabled="false"
                                    search-mode="contains"
                                    search-expr="name"
                                    class="filter-input"
                                    :data-source="seasons"
                                    display-expr="name"
                                    value-expr="uuid"
                                    placeholder=""
                                    :show-clear-button="false"
                                    drop-down-button-template="imageIcon"
                                    @selection-changed="mapSeasonChanged($event)"
                                  >
                                  <template #imageIcon>
                                    <img
                                      src="@/assets/images/icons/arrow_down.svg"
                                      class="custom-selector-icon"
                                    >
                                  </template>
                                  </DxSelectBox>
                                </div>
                              </div>

                               <div class="filter-item">
                                <div class="filter-text">Nume parcelă</div>
                                <div>
                                  <DxTextBox  v-model="filterData.name" class="global_dx_number_input"/>
                                </div>
                              </div>

                              <div class="filter-item" @click="swipeFilterData('CULTURE')">
                                <div class="first-row">
                                  <div class="main_filter_title">Cultură</div>
                                  <div class="main_filter_count">
                                    {{ localCultures ? localCultures.length : 0 }}
                                  </div>
                                  <img
                                    class="main_filter_right_arrow"
                                    src="@/assets/images/icons/right_arrow_dg.svg"
                                  />
                                </div>
                                <div v-if="localCultures && localCultures.length > 0"
                                  class="main_filter_selected">
                                  {{ cultureFilterText }}
                                </div>
                              </div>

                             <div class="filter-item" @click="swipeFilterData('SUB_CULTURE')">
                                <div class="first-row">
                                  <div class="main_filter_title">Soi</div>
                                  <div class="main_filter_count">
                                    {{ subCultureCount }}
                                  </div>
                                  <img
                                    class="main_filter_right_arrow"
                                    src="@/assets/images/icons/right_arrow_dg.svg"
                                  />
                                </div>
                                <div v-if="localSubcultureList && localSubcultureList.length > 0"
                                  class="main_filter_selected">
                                  {{ subCultureFilterText }}
                                </div>
                              </div>
                            </div>

                            <div v-show="step == 2">
                                <div class="current-filter-container">
                                    <div class="current-filter-back">
                                       <img
                                        src="@/assets/images/icons/arrow-back.svg"
                                        @click="goBackOneStep"
                                      />
                                      <span @click="goBackOneStep">Înapoi</span>
                                    </div>
                                    <div class="current-filter-title">{{currentFilterTitle}}</div>
                                    <div class="current-filter-search"></div>
                                    <div class="current-filter-select-all"></div>
                                    <div class="current-filter-list">

                                      <!-- Departments -->
                                      <div v-if="current_filter_code == 'DEPARTMENT'">
                                        <b-input-group class="search_filters">
                                          <b-input-group-prepend>
                                            <img src="~@/assets/images/icons/search.svg" />
                                          </b-input-group-prepend>
                                          <b-form-input
                                            placeholder="Caută departamente"
                                            v-model="instantFilters.departaments"
                                            class="mt-0"
                                          ></b-form-input>
                                        </b-input-group>
                                        <b-form-checkbox
                                          class="base_row select_all_link"
                                          @change="selectAllDepartments($event)"
                                          v-model="selectAll.departments"
                                        >
                                          Selectează tot
                                        </b-form-checkbox>
                                        <div class="filter_separator"></div>
                                        <DxScrollView class="row_container" :use-native="true">
                                          <div>
                                            <b-form-checkbox
                                              class="checkbox_row"
                                              v-for="(departament, index) in (localDepartments ? localDepartments : [])
                                              .filter((obj) =>
                                                !instantFilters ||
                                                !instantFilters.departaments ||
                                                obj.name
                                                  .toUpperCase()
                                                  .includes(instantFilters.departaments.toUpperCase())
                                              )"
                                              :key="index"
                                              v-model="filterData.department_uuids"
                                              :value="departament.uuid"
                                              @change="changeDepartmentFilters()"
                                            >
                                              {{ departament.name }}
                                            </b-form-checkbox>
                                            <div v-if="showApplyButton" class="row_container_spacer"></div>
                                          </div>
                                        </DxScrollView>
                                      </div>

                                      <!-- Culture -->
                                      <div v-if="current_filter_code == 'CULTURE'">
                                        <b-input-group class="search_filters">
                                          <b-input-group-prepend>
                                            <img src="~@/assets/images/icons/search.svg" />
                                          </b-input-group-prepend>
                                          <b-form-input
                                            placeholder="Caută cultură"
                                            v-model="instantFilters.cultures"
                                          ></b-form-input>
                                        </b-input-group>
                                        <b-form-checkbox
                                          class="base_row select_all_link"
                                          @change="selectAllCultures($event)"
                                          v-model="selectAll.cultures"
                                        >
                                          Selectează tot
                                        </b-form-checkbox>
                                        <div class="filter_separator"></div>
                                        <DxScrollView class="row_container" :use-native="true">
                                          <div>
                                            <b-form-checkbox
                                              class="checkbox_row"
                                              v-for="(culture, index) in (localCultures ? localCultures : [])
                                              .filter((obj) =>
                                                !instantFilters ||
                                                !instantFilters.cultures ||
                                                obj.name
                                                  .toUpperCase()
                                                  .includes(instantFilters.cultures.toUpperCase())
                                              )"
                                              :key="index"
                                              v-model="filterData.culture_uuids"
                                              :value="culture.uuid"
                                              @change="changeCultureFilters($event)"
                                            >
                                              {{ culture.name }}
                                            </b-form-checkbox>
                                            <div v-if="showApplyButton" class="row_container_spacer"></div>
                                          </div>
                                        </DxScrollView>
                                      </div>

                                      <!-- SUB Culture -->
                                      <div v-if="current_filter_code == 'SUB_CULTURE'">
                                        <b-input-group class="search_filters">
                                          <b-input-group-prepend>
                                            <img src="~@/assets/images/icons/search.svg" />
                                          </b-input-group-prepend>
                                          <b-form-input
                                            placeholder="Caută soi"
                                            v-model="instantFilters.sub_cultures"
                                          ></b-form-input>
                                        </b-input-group>
                                        <b-form-checkbox
                                          class="base_row select_all_link"
                                          @change="selectAllSubCultures($event)"
                                          v-model="selectAll.sub_cultures"
                                        >
                                          Selectează tot
                                        </b-form-checkbox>
                                        <div class="filter_separator"></div>
                                        <DxScrollView class="row_container" :use-native="true">
                                          <div>
                                            <div v-for="(culture, idx) in (localSubcultureList ? localSubcultureList : [])" :key="idx">

                                              <div class="cbx_title" v-show="(culture.varieties ? culture.varieties : []).filter((obj) =>
                                                !instantFilters ||
                                                !instantFilters.sub_cultures ||
                                                obj.name
                                                  .toUpperCase()
                                                  .includes(instantFilters.sub_cultures.toUpperCase())
                                              ).length > 0">{{culture.name}}</div>

                                               <b-form-checkbox
                                                class="checkbox_row"
                                                v-for="(vary, index) in (culture.varieties ? culture.varieties : [])
                                                .filter((obj) =>
                                                !instantFilters ||
                                                !instantFilters.sub_cultures ||
                                                obj.name
                                                  .toUpperCase()
                                                  .includes(instantFilters.sub_cultures.toUpperCase())
                                              )"
                                                :key="index"
                                                v-model="filterData.sub_culture_uuids"
                                                :value="vary.uuid"
                                                @change="changeSubCultureFilters($event)"
                                              >
                                                {{ vary.name }}
                                              </b-form-checkbox>
                                            </div>

                                            <div v-if="showApplyButton" class="row_container_spacer"></div>
                                          </div>
                                        </DxScrollView>
                                      </div>

                                    </div>
                                </div>
                            </div>
                            <div style="height:80px"></div>
                          </DxScrollView>

                        </div>
                      </template>
                   </DxTabPanel>

                   <DxScrollView v-show="mainStep == 'SMAVERTIZARE'" :use-native="true" class="scouting_filter_scoll">
                    <div>
                      <div style="height: 20px;"></div>
                      <div>
                        <b-form-checkbox
                              class="checkbox_row"
                              v-model="scoutingFilterData.show_scouting"
                              @change="changeShowScouting()"
                            >
                              Arată avertizările
                            </b-form-checkbox>
                      </div>
                      <functional-calendar
                      class="test_calendar_class"
                                :date-format="'yyyy-mm-dd'"
                                :is-date-range="true"
                                v-model="calendar"
                                :change-month-function="true"
                                :change-year-function="true"
                            ></functional-calendar>
                          <div class="current-filter-title">Urgență</div>
                          <div>
                            <b-form-checkbox
                                              class="checkbox_row"
                                              v-for="(prio, index) in (priorities ? priorities : [])"
                                              :key="index"
                                              v-model="scoutingFilterData.priorities"
                                              :value="prio"
                                              @change="changePriority()"
                                            >
                                              {{ getPriorityText(prio) }}
                                            </b-form-checkbox>
                          </div>
                          <div style="height:80px"></div>
                    </div>
                  </DxScrollView>
                  <div
                              class="apply_map_filter_sticky_dialog"
                              v-show="showApplyButton"
                            >
                              <b-button
                                class="continue_button"
                                variant="outline-secondary"
                                size="sm"
                                pill
                                @click="applyMapFilter"
                                >Aplică</b-button
                              >
                            </div>
                </div>
              </template>
            </DxTabPanel>
          </div>
        </template>
      </DxPopup>
    </div>
  </transition>
</template>

<script>
//import Vue from 'vue'
import { mapActions, mapGetters } from "vuex";
import { v4 } from 'uuid';
import DxPopup from "devextreme-vue/popup";
import DxTabPanel from 'devextreme-vue/tab-panel';
import DxSelectBox from "devextreme-vue/select-box";
import integer from 'vuelidate/lib/validators/integer';
import { DxScrollView } from "devextreme-vue/scroll-view";
import DxTextBox from 'devextreme-vue/text-box';
import * as type from '../../store/mutation-types'
import { FunctionalCalendar } from "vue-functional-calendar";
import moment from "moment";


export default {
  name: "MapFilterDialog",
  props: {
    showPopup: {
      type: Boolean,
      default: false,
    },
    heightDiff: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
       popupAttributes: {
          class: 'map-filter-attribute-wrapper'
      },
      tabPanelAttributes: {
          class: 'map-filter-steps'
      },
      filterTabs: ["FILTRARE", "SMAVERTIZARE"],
      smavertizareSteps: [1, 2],
      currentSmavertizareStep: 0,
      currentSmavertizareItem: 'FILTRARE',
      mapFilterSteps: [1, 2],
      currentStep: 0,
      currentItem: 1,
      currentAction: null,
      filterData: {
        name:'',
        department_uuids: [],
        season_uuid:'',
        season_name: '',
        culture_uuids:[],
        sub_culture_uuids:[]
      },
      scoutingFilterData: {
        show_scouting: false,
        date_from: '',
        date_to: '',
        priorities: []
      },
      current_filter_code:'',
      localDepartments: [],
      localCultures:[],
      instantFilters: {
        departments: '',
        cultures: '',
        sub_cultures: ''
      },
      selectAll: {
        departments: true,
        cultures: false,
        sub_cultures: false
      },
      showApplyButton: true,
      localSubcultureList: [],
      calendar: {
        dateRange: {
          start: "",
          end: "",
        },
      },
      priorities:[0,1,2,3]
    };
  },
  watch: {
    filterData: {
      handler: function (newVal, oldVal) {
      },
      deep: true,
      immediate: true,
    },
    calendar: {
      handler(newVal) {
        if (newVal) {
          this.scoutingFilterData.date_from = newVal.dateRange.start;
          this.scoutingFilterData.date_to = newVal.dateRange.end;
        }
      },
      deep: true,
    }
  },
  components: {
    DxPopup,
    DxTabPanel,
    DxSelectBox,
    DxScrollView,
    DxTextBox,
    FunctionalCalendar
  },
  computed: {
    ...mapGetters({
      company: "auth/domain",
      departments: 'company/departments',
      seasons: 'seasons/seasons',
      cultures: "works-cultures/cultures",
      layer_season_uuid: 'layers/layer_season_uuid',
      layer_filters: 'layers/layer_filters',
    }),
    popupHeight(){
      return (80 - this.heightDiff).toString() + "vh";
    },

    currentFilterTitle(){
      switch(this.current_filter_code){
        case 'DEPARTMENT' :
          return 'Departamente';
        case 'CULTURE' :
          return 'Culturi';
        case 'SUB_CULTURE' :
          return 'Soiuri';
        default:
          return 'Filtre';
      }
    },
    departmentFilterText(){
      if(this.filterData.department_uuids && this.filterData.department_uuids.length == 1){
        return "un departament selectat";
      }
      if(this.filterData.department_uuids && this.filterData.department_uuids.length > 1){
        if(Array.isArray(this.filterData.department_uuids)){
          return this.filterData.department_uuids.length + " departamente selectate";
        }
        else{
          return "un departament selectat";
        }
      }
    },
    cultureFilterText(){
      if(this.filterData.culture_uuids && this.filterData.culture_uuids.length == 1){
        return "o cultură selectată";
      }
      if(this.filterData.culture_uuids && this.filterData.culture_uuids.length > 1){
        if(Array.isArray(this.filterData.culture_uuids)){
          return this.filterData.culture_uuids.length + " culturi selectate";
        }
        else{
          return "o cultură selectată";
        }
      }
    },
    subCultureFilterText(){
      if(this.filterData.sub_culture_uuids && this.filterData.sub_culture_uuids.length == 1){
        return "un soi selectat";
      }
      if(this.filterData.sub_culture_uuids && this.filterData.sub_culture_uuids.length > 1){
        return this.filterData.sub_culture_uuids.length + " soiuri selectate";
      }
    },
    subCultureCount(){
      let sub_count = 0;
      this.localSubcultureList.forEach(c => {
        sub_count = sub_count + c.varieties.length;
      });
      return sub_count;
    }
  },
  methods: {
     ...mapActions({
        getDepartments: 'company/getDepartments',
        getCultures: "works-cultures/getCultures",
        getCulture: "works-cultures/getCulture",
    }),
    changePriority(e){},
    changeShowScouting(e){
      if(this.scoutingFilterData.show_scouting == false){
        this.scoutingFilterData.date_from = '';
        this.scoutingFilterData.date_to = '';
        this.scoutingFilterData.priorities = [];
        this.calendar = {
        dateRange: {
          start: "",
          end: "",
        }
        };
      }
    },
    getPriorityText(priorityNumber){
            if(priorityNumber == 0){
                return "OK"
            }
            if(priorityNumber == 1){
                return "Scăzut"
            }
            if(priorityNumber == 2){
                return "Mediu"
            }
            if(priorityNumber == 3){
                return "Înalt"
            }
        },
    closeDialog(){
      this.$emit("hidden");
    },
    setSeason(season_uuid, season_name){
      this.filterData.season_uuid = season_uuid;
      this.filterData.season_name = season_name;
    },
    mapSeasonChanged(event){
      this.filterData.season_name = event.selectedItem ? event.selectedItem.name : '';
      this.$emit("seasonChanged", event);
    },
    reloadDepartaments(){
        this.localDepartments = [];
        this.departments.forEach(dep => {
            this.localDepartments.push({uuid: dep.uuid, name: dep.name, selected: false});
        });
    },
    reloadCultures(){
      this.localCultures = [];
         this.getCultures({is_variety: false}).then(response => {
            for(let i = 0; i < response.data.length; i++ ){
                    this.localCultures.push({uuid: response.data[i].uuid, name: response.data[i].name, selected: false});
                }
            });

    },
    reloadSubCultures(removeAll = false){
      if(this.filterData.culture_uuids && this.filterData.culture_uuids.length > 0){
        if(Array.isArray(this.filterData)){
          for(let i = 0; i < this.filterData.culture_uuids.length; i++ ){
              //let tmp_culture_uuid = this.filterData.culture_uuids[i];
              this.persistReloadSubcultures(this.filterData.culture_uuids[i]);
          }
        }
        else{
          this.persistReloadSubcultures(this.filterData.culture_uuids);
        }
      }
      if(this.localSubcultureList && this.localSubcultureList.length > 0 && !removeAll){
        for(let i = 0; i < this.localSubcultureList.length; i++ ){
          if(!this.filterData.culture_uuids.find(obj => obj == this.localSubcultureList[i].culture_uuid))
            this.localSubcultureList.splice(i, 1);
            break;
        }
      }
      if(removeAll){
        this.localSubcultureList = [];
        this.filterData.sub_culture_uuids = [];
      }
    },
    persistReloadSubcultures(tmp_culture_uuid){
      if(!this.localSubcultureList.find(obj => obj.culture_uuid == tmp_culture_uuid)){
        this.getCulture({uuid: tmp_culture_uuid}).then(response => {
          if(response.varieties.length > 0){
              let tmp_sub_cultures = []
              for(let i = 0; i < response.varieties.length; i++ ){
                  tmp_sub_cultures.push({uuid: response.varieties[i].uuid, name: response.varieties[i].name, selected:false});
              }
              this.localSubcultureList.push({culture_uuid: tmp_culture_uuid, name: response.name, varieties: tmp_sub_cultures })
          }
        });
      }
    },
    swipeFilterData(filterCode){
      this.current_filter_code = filterCode;
      this.currentStep++;
    },
    goBackOneStep(){
      this.current_filter_code = '';
      this.currentStep--;
    },
    changeDepartmentFilters(){
    },
    changeCultureFilters(data){
      this.reloadSubCultures();
    },
     changeSubCultureFilters(data){
      this.reloadSubCultures();
    },
    applyMapFilter(){
      this.$store.commit("layers/"+type.SET_LAYER_FILTERS, this.filterData);
      this.$emit("applyMapFilters", this.filterData, this.scoutingFilterData);
    },
    resetFilters(){

    },
    applyMapFilterAtMapReady(){
      if(this.departments.length == 0){
        this.getDepartments({ company_uuid: this.company.uuid }).then(resp => {
          this.reloadDepartaments();
          this.selectAll.departments = true;
          this.selectAllDepartments(true);
          this.filterData.season_uuid = this.layer_season_uuid;
          this.reloadCultures();
          this.applyMapFilter();
        });
      }
      else{
        this.applyMapFilter();
      }
    },
    tabSelectionChanged(e){
      this.currentItem = e.addedItems[0];
      this.currentStep = this.mapFilterSteps.indexOf(this.currentItem);
    },
    mainTabSelectionChanged(e){
      this.currentSmavertizareItem = e.addedItems[0];
      this.currentSmavertizareStep = this.filterTabs.indexOf(this.currentSmavertizareItem);

    },
     selectAllDepartments(selected) {
      if (selected) {
        this.filterData.department_uuids = this.departments.map((w) => w.uuid);
      } else {
        this.filterData.department_uuids = [];
      }
    },
    selectAllCultures(selected){
      if (selected) {
        this.filterData.culture_uuids = this.localCultures.map((w) => w.uuid);
      } else {
        this.filterData.culture_uuids = [];
      }
      this.reloadSubCultures(!selected);
    },
    selectAllSubCultures(selected){
      this.filterData.sub_culture_uuids = [];
      if (selected) {
        this.localSubcultureList.forEach(c => {
          if(c.varieties && c.varieties.length > 0){
            c.varieties.forEach(v => {
               this.filterData.sub_culture_uuids.push(v.uuid);
            });
          }
        });
      };
    }
  },
  async mounted() {
    await this.reloadDepartaments();
    this.selectAll.departments = true;
    await this.selectAllDepartments(true);
    this.filterData.season_uuid = this.layer_season_uuid;
    this.reloadCultures();
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/components/mapFilters.scss";
</style>
