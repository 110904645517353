<template>
  <div class="agro-filter-container">
    <span class="action_buttons">
      <b-img v-b-toggle="'collapse-2'" :src="require('@/assets/images/desktop/icons/controls/expand.svg')" class="icon-svg"
           :title='$t("toggle_filters")'
           data-toggle="collapse" data-target="#collapseFilters"
           aria-expanded="false" aria-controls="collapseFilters" v-if="!hideExpand"/>
      <img src="~@/assets/images/desktop/icons/controls/plus.svg" :title="title" class="icon-svg" @click="onClick" v-if="!hideAdd"/>
    </span>
    <b-collapse id="collapse-2" class="pb-4" v-model="isVisible">
      <slot></slot>
    </b-collapse>
  </div>
</template>
<script>
import {isVisible} from "bootstrap/js/src/util";

export default {
  name: 'AgroFilterContainer',
  props: ['title', 'newObject', 'hideExpand', 'hideAdd', 'visible'],
  data() {
    return {
      isVisible: true
    }
  },
  methods: {
    onClick() {
      if (this.newObject && this.newObject instanceof Function) {
        this.newObject();
      }
    }
  },
  watch: {
    visible: function (newVal) {
      this.isVisible = newVal;
    }
  }
}
</script>
