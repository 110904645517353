<template>
  <div @onDeleteInput="onDeleteInput" class="delete-mouse-pointer">
    <img src="~@/assets/images/desktop/icons/controls/delete.svg" aria-hidden="true" class="icon-svg danger-filter sicon"/>
  </div>
</template>

<script>


import {mapActions} from "vuex";

export default {
  name: 'InputActionsTemplate',
  props: ['data'],
  data() {
    return {
      input: this.data ?? {},
      loading: false,
    }
  },
  computed: {
    title() {
      return '';
    },
  },
  methods: {
    ...mapActions({
      deleteInput: 'works-inputs/deleteInput',
    }),
    onDeleteInput(data) {

      this.$emit('onDeleteInput', data)
    }
  },
  watch: {
    data: {
      handler: function(newVal) {
        this.item = newVal;
      },
      deep: true,
    },
  }
}
</script>

<style scoped>
.delete-mouse-pointer {
  cursor: pointer;
}
</style>
