<template>
  <td class="action-buttons text-lg-right pr-3" :data-title='$t("common.actions")'>
    <div v-if="additionalActionControlsHtml" v-html="additionalActionControlsHtml"
         :style="permissionModule.additionalControls && permissionModule.additionalControls.style"
         :class="permissionModule.additionalControls && permissionModule.additionalControls.class"
    />
    <component :is="permissionModule.additionalControls.template"
               v-bind="{data: row}" v-if="permissionModule.additionalControls && permissionModule.additionalControls.template">
    </component>
    <router-link
      :to="editUrl ? editUrl : '#'"
      :title='$t("common.edit")'
      @click="onEdit"
      v-if="canEdit && permissionModule.edit">
      <img src="~@/assets/images/desktop/icons/controls/edit.svg" class="icon-svg"/>
    </router-link>
    <a v-on:click.stop.prevent="confirmDelete()"
       :title='$t("common.delete")'
       class="c-pointer"
       v-if="canDelete && permissionModule.delete">
      <img src="~@/assets/images/desktop/icons/controls/delete.svg" class="icon-svg" :class="{'danger-filter': (hasDeleteAccess() && !disableDeleteCondition)
      || (canDelete && !disableDeleteCondition),  'text-secondary': (!hasDeleteAccess() || disableDeleteCondition)
      }"/>
    </a>
    <a v-on:click.prevent="onExport()"
      :title='$t("common.export")'
      v-if="permissionModule.export">
      <img src="~@/assets/images/desktop/icons/controls/pdf.svg" class="icon-svg danger-filter" v-if="permissionModule.export.icon === 'pdf'"/>
      <img src="~@/assets/images/desktop/icons/controls/excel.svg" class="icon-svg success-filter" v-else/>
    </a>
  </td>
</template>

<script>
import {mapGetters} from "vuex";
import {actions, hasModuleAccess, hasPermission, permissionTypes} from "@/helpers/Permissions";
import {replaceParams} from "@/store/api/endpoints";
import {successMessage} from "@/helpers/common";

export default {
  props: ['data', 'permission'],
  name: 'ActionControls',
  data() {
    return {
        permissionModule: this.permission ? this.permission : {},
        row: this.data ? this.data : {},
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
    }),
    additionalActionControlsHtml() {
        if (this.permissionModule && this.permissionModule.additionalControls
          && this.permissionModule.additionalControls.html instanceof Function) {
            return this.permissionModule.additionalControls.html(this.row);
        }
    },
    canEdit() {
      if(this.permissionModule) {
        if (this.permissionModule.permission_type && this.permissionModule.permission_type === permissionTypes.module) {
          return hasModuleAccess(this.user, this.permissionModule.permission);
        } else {
          return hasPermission(this.row, this.user, this.permissionModule.permission, actions.EDIT);
        }
      }
      return true; //be able to edit, if permission is not set
    },
    canDelete() {
      if(this.permissionModule) {
        if (this.permissionModule.permission_type && this.permissionModule.permission_type === permissionTypes.module) {
          return hasModuleAccess(this.user, this.permissionModule.permission);
        } else {
          return hasPermission(this.row, this.user, this.permissionModule.permission, actions.DELETE);
        }
      }
      return true; //be able to delete if permission is not set
    },
    editUrl() {
        if (this.permissionModule.edit.url) {
          return replaceParams(this.permissionModule.edit.url, this.row);
        }
    },
    disableDeleteCondition() {
      if(this.permissionModule.delete.disableIf instanceof Function) {
          return this.permissionModule.delete.disableIf(this.row);
      }
    },
    confirmDeleteMessage() {
        if(this.permissionModule.delete.confirmDeleteMessage) {
          return this.permissionModule.delete.confirmDeleteMessage;
        }

        return this.$t('common.confirm_delete', { title: this.row.name });
    }
  },
  methods: {
    onEdit() {
        this.$emit('onEdit', this.row);
        if(this.permissionModule.edit && this.permissionModule.edit.method instanceof Function) {
          this.permissionModule.edit.method(this.row);
        }
    },
    onDelete() {
        this.$emit('onDelete', this.row);
    },
    onExport() {
      this.$emit('onExport', this.row);
      if(this.permissionModule.export && this.permissionModule.export.method instanceof Function) {
        this.permissionModule.export.method(this.row);
      }
    },
    hasDeleteAccess() {
      return ((this.user && this.user.is_superadmin) || (this.user && this.user.is_company_admin));
      //check rights?
    },
    confirmDelete() {
      let vm = this;

      if(this.disableDeleteCondition) {
        this.$dialog.alert(this.permissionModule.delete.disableMessage).then(function(dialog) {
          dialog.close();
        });
        return;
      }

      //if no delete access
      if(!this.hasDeleteAccess(this.row) && !this.canDelete) return;

      //if delete method is not set
      if(!this.permissionModule.delete && this.permissionModule.delete.method !== Function) return;

      //if confirm dialog is not allowed
      if(this.permissionModule.delete && this.permissionModule.confirm === false) return;

      this.$dialog.confirm(this.confirmDeleteMessage)
        .then((dialog) => {
          vm.permissionModule.delete.method({
            uuid: vm.row.uuid,
          }).then((response) => {
            dialog.close();
            successMessage(vm, response.message);

            //regular callback event
            vm.onDelete();

            //custom callback event (if passed)
            if(vm.permissionModule.delete.callback) {
              vm.permissionModule.delete.callback(response.data);
            }
          }).error((response) => {
            dialog.close();
            vm.$bvToast.toast(response.message, {
              title: vm.$t('error'),
              variant: 'error',
              solid: true,
              autoHideDelay: 5000
            });
          });
        });
    },
  },
  watch: {
    data: {
      handler: function(newVal) {
          this.row = newVal;
      },
      deep: true,
      immediate: true,
    }
  }
}
</script>
