import $ from 'jquery'
import Vue from 'vue'
import {eventBus} from "~/plugins/eventBus";
import moment from "moment";

let vm = new Vue();

export function isMobile() {
  if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
    return true
  }
  return false
}

export function isBase64(str) {
  if ( str ==='' || str.trim() ==='' ) { return false; }
  try {
    return btoa(atob(str)) == str;
  } catch (err) {
    return false;
  }
}

export function errorMessageList(context, messages) {
  if(messages) {
    let items = Object.keys(messages).map((key) => [messages[key][0]]);
    items.map((item) => {
        errorMessage(context, item);
    })
  }
}

export function errorMessage(context, body, exception = null) {
  eventBus.$emit("onToastMessage", {
    type: 'error',
    message: body,
  });

  if(exception) {
    console.log('error exception: ', exception);
  }
}

export function successMessage(context, body) {
  eventBus.$emit("onToastMessage", {
    type: 'success',
    message: body,
  });
}

export function infoMessage(context, body) {
  eventBus.$emit("onToastMessage", {
    type: 'info',
    message: body,
  });
}


export function buildAutocompleteOptions(rows, config, inital_obj = undefined)
{
  let list = [];

  if (inital_obj !== undefined) {
    list.push({
      value: inital_obj.value,
      text: inital_obj.text
    });
  }

  if (rows) {
    rows.map((item) => {
      list.push({
        value: item[config.uuid],
        label: item[config.name]
      });
    });
  }

  return list;
}
/* Build dropdown options */
export function buildDropdownOptions(rows, config, inital_obj = undefined) {
  let list = [];

  if(inital_obj) {
    list.push({
      value: inital_obj.value,
      text: inital_obj.text
    });
  }

  if(rows) {
    Object.assign([], rows).map((item) => {
      list.push({
        value: item[config.value],
        text: config.subtext && item[config.subtext.split('.')[0]] && item[config.subtext.split('.')[0]][config.subtext.split('.')[1]] 
          ? item[config.text] + ' (' + item[config.subtext.split('.')[0]][config.subtext.split('.')[1]] + ')' 
          : item[config.text]
      });
    });
  }

  return list;
}

/* Simplify dropdown list while building with params
* Only data - rows param is required
* */
export function buildDefaultDropdownList(rows, config = undefined, initial_obj = undefined) {
  let localConfig = config;
  let localInitialObj = initial_obj;

  if(!config) {
    localConfig = {
      value: 'uuid',
      text: 'name',
      subtext: null,
    };
  }

  if(!initial_obj) {
    localInitialObj = {
      value: null,
      text: '-',
      subtext: null,
    };
  }

  return buildDropdownOptions(Object.assign([], rows), localConfig, localInitialObj);
}

/* Simplify autocomplete list while building with params
* Only data - rows param is required
* */

export function buildDefaultAutocompleteList(rows, config = undefined, initial_obj = undefined) {

  let localConfig = config;
  let localInitialObj = initial_obj;

  if(!config) {
    localConfig = {
      uuid: 'uuid',
      name: 'name',
    };
  }

  if(!initial_obj) {
    localInitialObj = {
      value: null,
      text: '-',
    };
  }

  return buildAutocompleteOptions(Object.assign([], rows), localConfig, localInitialObj);
}

export function convertDropdown(list, value = 'uuid', text = 'name') {
  return buildDropdownOptions(Object.assign([], list), {
    value: value,
    text: text,
  }, {
    value: null,
    text: '-',
  });
}

export function setSubdomain(subdomain, domain) {
  if(domain) {
      let part = domain.split('://');

      if(part && part.length >= 2) {
        return part[0]+'://'+subdomain+'.'+part[1];
      }
  }

  return domain;
}

export function getDomain() {
  let spl = window.location.host.split('.');
  spl.splice(0, 1);
  return spl.join('.');
}

export function getSubdomain() {
  let spl = window.location.host.split('.');
  return spl[0];
}

export function getClientDomain(extraPath = '') {
  return setSubdomain(getSubdomain(), getDomain())+extraPath;
}

export function getServerSubdomain() {
  let spl = window.location.host.split('.');
  let server = process.env.VUE_APP_BROWSER_BASE_URL.split('://');
  return server[0]+'://'+spl[0]+'.'+server[1]+'/api';
}

export function findUnitByUUID(data, uuid) {
  if(data) {
    for(let i = 0; i < data.length; i++) {
      if (data[i].uuid === uuid) {
        return data[i];
      } else if (data[i].child_units && data[i].child_units.length && typeof data[i].child_units === "object") {
        findUnitByUUID(data[i].child_units, uuid);
      }
    }
  }
}

export function generateUUID() {
  return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
    (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
  );
}

export function objectGet(obj, path) {
  try{
    return new Function('_', 'return _.' + path)(obj);
  }
  catch(e) {
      console.warn('Object property not found in results set: ', path);
      return '-';
  }
}

export function createSlug(str) {
    return str.replace(/[^A-Z0-9]/ig, "_").toString().toLowerCase();
}

export function only_numbers(event)
{
  let e = event || window.event; //window.event is safer, thanks @ThiefMaster
  let charCode = e.which || e.keyCode;

  //point 190, backspace, shift, tab =9, comma 188
  if (charCode == 190 || charCode == 188 || charCode == 8 || charCode == 37 || charCode == 39 || charCode == 189  ||  charCode == 9
    || (e.key >= 0 && e.key <= 9 && e.key !== ' ')) {

    if(charCode == 188) { //if commma, replace with point
      event.target.value = event.target.value.replace(',', '.');
    }

    return true;
  }

  event.preventDefault();
}

export function only_company_identification_number(event)
{
  let e = event || window.event; //window.event is safer, thanks @ThiefMaster
  let charCode = e.which || e.keyCode;

  //point, backspace, shift, tab =9
  if (charCode == 190 || charCode == 8 || charCode == 37 || charCode == 39 || charCode == 189  ||  charCode == 9
    || charCode == 82 || charCode == 79
    || (e.key >= 0 && e.key <= 9 && e.key !== ' ')) {
    return true;
    //RO
  }

  event.preventDefault();
}

export function only_company_nr_reg_number(event)
{
  let e = event || window.event; //window.event is safer, thanks @ThiefMaster
  let charCode = e.which || e.keyCode;

  //point, backspace, shift, tab =9
  if (charCode == 190 || charCode == 8 || charCode == 37 || charCode == 39 || charCode == 189  ||  charCode == 9
    || charCode == 191
    || charCode == 74
    || (e.key >= 0 && e.key <= 9 && e.key !== ' ')) {
    return true;
    //J /
  }

  event.preventDefault();
}

export function only_price(event)
{
  let e = event || window.event; //window.event is safer, thanks @ThiefMaster
  let charCode = e.which || e.keyCode;
  if (event.target.value && (event.target.value.toString().indexOf('.') > -1 && charCode == 46 )) {
    event.preventDefault();
  }
  //190: point, backspace, shift, tab = 9, 188 vesszo
  if (charCode == 190 || charCode == 188 || charCode == 8 || charCode == 37 || charCode == 39 || charCode == 189 ||  charCode == 9
    || charCode == 46 || (e.key >= 0 && e.key <= 9 && e.key !== ' ')) {
    return true;
  }

  event.preventDefault();
}

export function only_decimal(event)
{
  return only_price(event);
}

export function getRange(min, max) {
  return Array.from({ length: max - min + 1 }, (_, i) => min + i);
}

export function convertArrayToNameVal(array, padChar = null, padCount = null) {
    return array.map((item) => {
      let uuid = item;
      let name = item;

      if(Array.isArray(item) && item.length >= 2) {
          uuid = item[0];
          name = item[1];
      }

      if(padChar && padCount) {
          uuid = String(uuid).padStart(padCount, padChar);
          name = String(name).padStart(padCount, padChar);
      }
        return {
          uuid: uuid,
          name: name
        };
    });
}

export function splitGet(string, char, getIndex = 0) {
  if(string && char) {
      let split = string.split(char);

      if(split.length > getIndex) {
        return split[getIndex];
      }
  }

  return null;
}

export function splitGetValue(string, char) {
  let value = splitGet(string, char, 1);
    return value ? value : splitGet(string, char, 0);
}

export function getHtmlElementAttribute(html, attribute) {
  let elements = $(html);
  if(elements) {
    return elements.attr(attribute);
  }

  return undefined;
}

export function confirmDialog(message, title = null, options = {}) {
  return new Promise((resolve, reject) => {
    let localOptions = {
      html: true,
      okText: options.okText ? options.okText : 'Ok',
      cancelText: options.cancelText ? options.cancelText : 'Cancel',
    };

    let localMessage = {
      title: title ? title : null,
      body: message,
    };

    vm.$dialog.confirm(
      localMessage,
      localOptions
    ).then((dialog) => {
        dialog.close();
        resolve(dialog);
    }).catch((e) => {
      reject(e);
    });
  });
}

export function copyArray(array) {
  return JSON.parse(JSON.stringify(array));
}

export function splitArrayToChunks(array, chunkSize) {
  let countPerChunk = Math.round(array.length / chunkSize);
  if(array.length <= chunkSize) {
    countPerChunk = chunkSize;
  }

  let results = [];
  for (let i = 0; i < array.length; i += countPerChunk) {
    results.push(
      array.slice(i, i + countPerChunk)
    );
  }

  return results;
}

export function lineToUnderscore(value) {
  return value.toString().toLowerCase().replaceAll('-', '_').trim();
}

export function getStringOrJoinArray(source){
  if(Array.isArray(source)){
    return source.join();
  }
  else{
    return [source];
  }
}

export function getObjectKey(obj) {
  return Object.keys(obj)[0] ?? null;
}

export function randomID() {
  return Math.random().toString().replace('.', '');
}

export function convertDecimalHoursToGMT(hours, format = 'HH:mm') {
  return moment.utc(moment.duration(hours, "hours").asMilliseconds()).format(format);
}

export function diffHours(startDateTime, endDateTime) {
  let durationEnd = moment.duration(moment(endDateTime).diff(moment(startDateTime)));
  return durationEnd.asHours();
}

export function formatFileSize(size) {
  if (isNaN(size)) return "Unknown";
  if (size < 1024) return size + " Bytes";
  if (size < 1024 * 1024) return (size / 1024).toFixed(2) + " KB";
  if (size < 1024 * 1024 * 1024) return (size / (1024 * 1024)).toFixed(2) + " MB";
  if (size < 1024 * 1024 * 1024 * 1024) return (size / (1024 * 1024 * 1024)).toFixed(2) + " GB";
  return (size / (1024 * 1024 * 1024 * 1024)).toFixed(2) + " TB";
}

export function redirectToSessionExpiredStatus(context) {
  context.$router.push({
    path: '/session-expired?status=401',
  })
}

export async function handleSession(context) {
  const user = await this.$store.dispatch('auth/fetchUser');

  if (!user && !this.$store.getters['auth/token']) {
    if (isMobile()) {
      //redirect to 401 status code
      redirectToSessionExpiredStatus(context);
    } else {
      //redirect desktop login
      await redirectToLogin();
    }
  }
}

export async function redirectToLogin(){
  getClientDomain('/auth/login');
}
