<template>
  <div></div>
</template>

<script>
export default {
  props: {
    data: Object,
  },
  components: {
  },
  data() {
    return {
    }
  },
}
</script>
