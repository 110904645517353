<template>
    <div class="consolidate_consum_holder">
        <div class="parcel_info_text">Parcela <span class="parcel_info_text_dark">{{ parcel_name}}</span> ({{ parcel_surface }}ha) cu un cost total de <span class="parcel_info_text_dark">{{ totalCost }}</span> Ron insemnând <span class="parcel_info_text_dark">{{totalCostPerHa}}</span> Ron/ha.</div>
        <div class="consum_title_holder">

            <div class="consum_title">Produse</div>
            <div class="filter_icon_holder">
                 <b-button
                    class="rent_filter_icon"
                    variant="outline-secondary"
                    size="sm"
                    pill
                    @click="showConsumFilter"
                    ><img src="@/assets/images/icons/options.svg"
                    /></b-button>
            </div>
        </div>
        <DxScrollView ref="scrollView" :elementAttr="scrollListAttributes" :use-native="true" @reach-bottom="reachBottom" @scroll="scroll" reachBottomText="Se încarcă..." >
            <div >
                <div class="no_dataLabel" v-if="noListData">Nu există consum</div>
                <div v-for="(data, d_idx) in consum_list_data" :key="data.data_idx_uuid" >
                    <div class="product_date">{{data.date}}</div>
                    <div class="con_consum_list_holder">
                        <div v-for="(product, p_idx) in data.products" :key="product.idx_uuid">
                            <div  class="product_item">
                                <div class="product_main_icon">
                                    <div class="product_main_icon_file">
                                        <img src="@/assets/images/icons/droplet.svg"/>
                                    </div>
                                </div>
                                <div class="product_main_info">
                                    <div class="product_name">{{product.name}}</div>
                                    <div class="source_holder">
                                        <div class="source">{{getSource(product.source)}}</div>
                                    </div>
                                </div>
                                <div class="dose_info">{{product.dose}} {{ product.mu }}/ha</div>
                                <div class="expand_icon_holder">
                                    <div :class="product.expanded ? 'expand_icon_expanded' : 'expand_icon'" @click="expandProduct(product, d_idx, p_idx)">
                                        <img v-if="product.expanded" src="@/assets/images/icons/arrow_up_green.svg"/>
                                        <img v-else src="@/assets/images/icons/arrow_down.svg"/>
                                    </div>
                                </div>
                            </div>
                            <consum-details :templateData="product" v-show="product.expanded" ></consum-details>
                        </div>
                    </div>
                </div>
            </div>
        </DxScrollView>
        <consum-filter :show-popup="consumFilterVisibility" @hidden="hideConsumFilter" @applyFilter="applyFilter"></consum-filter>
    </div>
</template>

<script>

import { mapGetters, mapActions } from "vuex";
import { DxScrollView } from "devextreme-vue/scroll-view";
import { v4 } from 'uuid';
import ConsumDetails from './consumDetails.vue';
import ConsumFilter from "./consumFilter.vue"

export default {
    name: "ConsolidateConsum",
    props: {
        parcel_uuid: {
            type: String,
            default: '',
        },
        parcel_name: String,
        parcel_group_parcel_uuids: {
            type: Array,
            default: () => [],
        },
        parcel_surface: String
    },
    data(){
        return {
            scrollListAttributes: {
                class: 'con_consum_list_scroller'
            },
            pagination: {},
            scrollOffset_top: 0,
            consum_list_data:{},
            noListData: false,
            consumFilterVisibility: false,
            consumType: 'consolidate',
            totalCost: 0,
            totalCostPerHa: 0
        };
    },
    components: {
        DxScrollView,
        ConsumDetails,
        ConsumFilter
    },
    watch:{
        parcel_uuid(newVal, oldVal){
            this.fillConsum(1, null, this.fillCallback);
        },
        consumType(){
            this.fillConsum(1, null, this.fillCallback);
        },
        parcel_group_parcel_uuids(newVal, oldVal){
            this.fillConsum(1, null, this.fillCallback);
        },
    },
    methods: {
        ...mapActions({
            getParcelConsum: 'smTransactions/getParcelConsum',
            getParcelConsumSummary: 'smTransactions/getParcelConsumSummary',
        }),
        getSource(source){
            if(source == 'digital_agenda'){
                return 'Agenda digitală';
            }
            else if(source == 'stock'){
                return 'Stoc';
            }
            else{
                return source;
            }
        },
        fillConsum(page_number, filter = null, callback = null){
            filter = filter ?? {};
            filter.page = page_number;
            if(page_number == 1){
                this.$refs['scrollView'].instance.release(false);
            }
            this.getParcelConsumSummary(
                {
                parcel_uuid: (this.parcel_group_parcel_uuids && this.parcel_group_parcel_uuids.length > 0) ? this.parcel_group_parcel_uuids.join() : this.parcel_uuid,
                type: this.consumType,
            }
            ).then(response => {
                this.totalCost = response;
                this.totalCostPerHa = (parseFloat(this.totalCost.replace(',', '')) / parseFloat(this.parcel_surface)).toFixed(2);
            });
            this.getParcelConsum({
                parcel_uuid: (this.parcel_group_parcel_uuids && this.parcel_group_parcel_uuids.length > 0) ? this.parcel_group_parcel_uuids.join() : this.parcel_uuid,
                type: this.consumType,
                page: page_number,
            }).then((response) => {
                if(page_number == 1){
                    this.consum_list_data = [];
                    this.$set(this.consum_list_data, [] );
                }
                response.data.forEach(d => {
                    d.data_idx_uuid = v4();
                    d.products.forEach(p => {
                        p.idx_uuid = v4();
                        p.expanded = false;
                    })
                    this.consum_list_data.push(d);
                    this.$set(this.consum_list_data, this.consum_list_data.length - 1, d);
                });
                this.pagination = response.pagination;
                this.noListData = this.consum_list_data.length == 0;
                if(callback != null){
                    callback();
                }
            });
        },
        fillCallback(){
            if(this.pagination.current_page == this.pagination.last_page || this.rent_list_data.length == 0){
                this.$refs['scrollView'].instance.release(true);
            }
            else{
                this.$refs['scrollView'].instance.release(false);
            }
            this.$refs['scrollView'].instance.update();
            if(this.pagination.current_page == 1){
                this.$refs['scrollView'].instance.scrollTo(0)
            }
        },
        reachBottom(){
            if(this.pagination.current_page < this.pagination.last_page){
                this.fillConsum(this.pagination.current_page + 1, null, this.fillCallback);
            }
        },
        scroll(event){
            this.scrollOffset_top = event.scrollOffset.top;
        },
        expandProduct(product, d_idx, p_idx){
            product.expanded = !product.expanded;
        },
        showConsumFilter(){
            this.consumFilterVisibility = true;
        },
        hideConsumFilter(){
            this.consumFilterVisibility = false;
        },
        applyFilter(currentType){
            this.consumType = currentType.code;
        }
    },
    mounted(){
        this.fillConsum(1, null, this.fillCallback);
    }
}
</script>

<style lang="scss">
    @import "@/assets/scss/components/consolidateConsum.scss";
</style>
