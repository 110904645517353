<template>
  <div>
    <div v-for="permission in permissions" :key="permission.uuid" id="permissions" class="overflow-y-auto row">
      <permission :data="permission" @onItemRemoved="onItemRemoved"/>
    </div>
    <div class="row pt-3">
      <div class="col-lg-6" v-if="hasUpdatingItems()">
        <button class="btn-sm btn-outline-secondary" @click="save">Save permissions</button>
      </div>
    </div>
  </div>
</template>

<script>
    import Permission from './index'

    export default {
        name: 'PermissionList',
        props: ['data'],
        data() {
            return {
                permissions: this.data ? this.data : [],
                has_removed: false,
            }
        },
        components: {
          Permission,
        },
        methods: {
            addPermission() {
                this.permissions.push({
                    uuid: Math.random(),
                    name: '',
                    access: [],
                    mode: 'edit',
                    edit: false,
                    editing: true,
                    new: true,
                });
            },
            onItemRemoved(item) {
                this.$emit('onItemRemoved', item);
                this.has_removed = true;
            },
            hasUpdatingItems() {
                if(this.permissions) {
                    let changes = this.permissions.filter((item) => {
                        return item.editing || item.new;
                    });

                    return changes.length > 0 || this.has_removed;
                }
            },
            save() {

            }
        },
        watch: {
            data: {
                handler: function(newVal) {
                    this.permissions = newVal;
                },
                deep: true,
            }
        }
    }
</script>
