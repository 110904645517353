import * as api from '../../api/index'
import * as endpoints from '../../api/endpoints'
import * as types from "../../mutation-types";

const actions = {
    getWorksHasWorkMachines({commit}, payload) {
        return api.fetch(endpoints.WORKS_HAS_WORK_MACHINES + '/byMachine', payload, commit,(response) => {
          
        });
    },
    
}

export default actions;
