<template>
  <div id="document-block-list" class="pt-3">
    <div class="block-list form-group row">
      <div class="col-lg-12">
        <agro-grid
          :headers="headers"
          :rows="document_blocks"
          :paginate="true"
          :permission="config"
          :action="onPageSelected"
          :loading="loading"
          @onSortBy="onSortBy"
        >
        </agro-grid>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import ControlContainer from "@/pages/tools/settings/partials/controls/ControlContainer";
import {permissions, permissionTypes} from "~/helpers/Permissions";
import AgroGrid from "@/components/AgroGrid/index";
import * as type from "~/store/mutation-types";

export default {
  name: 'DocumentBlockList',
  props: ['data'],
  data() {
    return {
      document_blocks: this.documentBlocks ? this.documentBlocks : [],
      blockData: this.data ? this.data : {},
      document_block_filters: this.documentBlockFilters ? this.documentBlockFilters : {},
      loading: false,
    }
  },
  components: {
    ControlContainer,
    AgroGrid,
  },
  computed: {
    ...mapGetters({
        documentBlocks: 'justifyingDocuments/documentBlocks',
        documentBlockFilters: 'justifyingDocuments/documentBlockFilters',
        user: 'auth/user',
    }),
    hasAccess() {
      return this.user && this.user.id > 0;
    },
    config() {
      return {
        permission: permissions.JUSTIFYING_DOCUMENTS,
        permission_type: permissionTypes.module,
        edit: {
          url: '/tools/justifying-documents/{uuid}',
          callback: this.onItemEdited,
        },
        delete: {
          method: this.deleteObject,
          callback: this.onItemDeleted,
          disableDeleteCondition: () => this.data.is_deletable === 0
        }
      };
    },
    headers() {
      return  [
        { name: this.$t('tools.justifying_documents.name'), col: 'name', width: '20%', sortable: true, },
        { name: this.$t('tools.justifying_documents.document_model'), col: 'model', width: '10%'},
        {
          name: this.$t('tools.justifying_documents.department'),
          col: 'department.name',
          subtitle: this.getDepartmentParents,
          key: 'department_name',
          align: 'center',
          width: '13%',
          sortable: true
        },
        {
          name: this.$t('tools.justifying_documents.warehouse'),
          col: 'warehouse.name',
          key: 'warehouse_name',
          align: 'center',
          width: '17%',
          sortable: true
        },
        { name: this.$t('common.created_by'), col: 'created_by.name', key: 'created_by', align:'center', width: '10%' },
        { name: this.$t('common.created_at'), col: 'created_at', align:'right', width: '12%', sortable: true },
        { name: this.$t('common.last_update'), col: 'updated_at', align:'right', width: '15%', sortable: true },
      ];
    },
  },
  methods: {
    ...mapActions({
      'deleteObject': 'justifyingDocuments/deleteDocumentBlock'
    }),
    onPageSelected(page_id) {
      this.filters.page = page_id;
      this.$store.commit("justifyingDocuments/"+type.SET_DOCUMENT_BLOCK_FILTERS, this.filters);
      this.$refs.filters.applyFilters();
    },
    editItem() {
      console.log('edit click');
    },
    onItemEdited(item) {
      console.log('onItemEdited', item);
    },
    onItemDeleted(item) {
      this.document_blocks.splice(
        this.document_blocks.indexOf(
          this.document_blocks.find(it => it.uuid === item.uuid)
        ), 1
      );
    },
    onSortBy(data) {
      this.document_block_filters.sortBy = data.sortBy;
      this.document_block_filters.sortDirection = data.sortDirection;
      this.onPageSelected(this.currentPage);
    },
    getDepartmentParents(cell) {
        if (cell.department) {
          let parents = this.getParent(cell.department);

          if (parents.length > 1) {
            parents = parents.splice(1, parents.length - 1);
          }

          return '<br/><span style="font-size: 11px; color: #35495e">'+parents.join('/')+'</span>';
        }
    },
    getParent(department, parents = []) {
      if (department.parent) {
        parents.push(department.name);
        this.getParent(department.parent, parents);
      } else {
        parents.push(department.name);
      }

      return parents;
    }
  },
  watch: {
    documentBlocks: {
      handler: function(newVal) {
        this.document_blocks = newVal;
      },
      deep: true,
    },
    data: {
      handler: function(newVal) {
        this.block = newVal;
      },
      deep: true,
    },
    pagination: {
      handler: function(newVal) {
        this.pager = newVal;
      },
      deep: true,
    },
  }
}
</script>

<style lang="scss" scoped>

</style>
