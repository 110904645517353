<template>
    <DxDropDownBox
      ref="dropDownBoxRef"
      
      :input-attr="{ 'aria-label': 'Owner' }"
      :data-source="dataSource"
      v-model:value="currentValue"
      display-expr="name"
      value-expr="uuid"
      content-template="contentTemplate"
    >
      <template #contentTemplate="{}">
        <DxDataGrid
          :data-source="ownerDataSource"
          :remote-operations="true"
          :height="250"
          :selected-row-keys="[currentValue]"
          :hover-state-enabled="true"
          :on-selection-changed="onSelectionChanged"
          :focused-row-enabled="true"
          :focused-row-key="currentValue"
        >
          <DxColumn 
            data-field="name"
            caption="Nume proprietar"
            >
            <DxRequiredRule message="Numele proprietarului este obligatorie!"/>
            </DxColumn>
          <DxColumn 
            data-field="id_number"
            caption="CNP"
            >
            <DxRequiredRule message="CNP este obligatoriu!"/>
            </DxColumn>

          <DxEditing
              :allow-adding="true"
              :allow-updating="true"
              :allow-deleting="false"
              :use-icons="true"
              mode="row"
          >
              <DxTexts
                  confirm-delete-message="Sigur vreți să ștergeți produsul?",
                  confirm-delete-title="Confirmare ștergere"
              />
          </DxEditing>
          <DxPaging
            :enabled="true"
            :page-size="10"
          />
          <DxScrolling mode="virtual"/>
          <DxSelection mode="single"/>
          <DxFilterRow :visible="true" apply-filter="auto"/>
        </DxDataGrid>
      </template>
    </DxDropDownBox>
  </template>
  <script>
  
  import { mapActions, mapGetters } from 'vuex'
  import { DxDataGrid, DxPaging, DxSelection, DxScrolling, DxColumn, DxDataGridTypes, DxEditing, DxTexts, DxRequiredRule, DxFilterRow } from 'devextreme-vue/data-grid';
  import DxDropDownBox from 'devextreme-vue/drop-down-box';
  import CustomStore from 'devextreme/data/custom_store';
  import * as api from '../../../store/api/index';
  import * as endpoints from '../../../store/api/endpoints'
  import {getFilterDataByName, getSortByProperty, getSortDirection} from "@/helpers/dxGridHelper";
  
export default {
    name: "OwnerEditorTemplate",
    props:{
        value: {
            type: String
        },
        onValueChanged: {
            type: Function
        },
        loadDataSourceOwners:{
            type: Function
        },
        dataSource: {
            type: Array
        }
    },
    computed: {},
    components: {
      DxDropDownBox,
      DxDataGrid,
      DxPaging,
      DxSelection,
      DxScrolling,
      DxColumn,
      DxDataGridTypes,
      DxEditing, 
      DxTexts,
      DxRequiredRule,
      DxFilterRow
    },
    data(){
        return {
            dropDownOptions: { width: 750 },
            currentValue: 'some text',
            ownerDataSource: new CustomStore({
              key: 'uuid',
              load: this.loadOwners,
              insert: this.insert,
              update: this.update,
              async remove(key){},
              byKey: (key) => {
                    
                }
            })
        };
    },
    methods: {
      ...mapActions({
            getOwners: 'leasecontracts/getOwners',
            getOwnerByID: 'leasecontracts/getOwnerByID',
            createOwner: 'leasecontracts/createOwner',
            updateOwner: 'leasecontracts/updateOwner',
            
        }),
        async loadOwners(loadOptions){
            let pageLimit = loadOptions.take;
            let pageNumber = (loadOptions.skip + loadOptions.take)/pageLimit;
            
            let dataSource =  {
                data:[],
                totalCount:0
            };
            let payload = {
                page: pageNumber,
                limit: pageLimit,
                sortBy: getSortByProperty(loadOptions) ?? 'name',
                sortDirection: getSortDirection(loadOptions) ?? 'DESC',
                name: getFilterDataByName(loadOptions, "name"),
            }
            //payload = this.collectFilters(loadOptions, payload);
            await  api.fetchFull(endpoints.LC_OWNER, payload, null,(response) => {
                dataSource.data = response.data.data;
                dataSource.totalCount = response.data.pagination.total
            });

            return dataSource;
        },
        async insert(obj){
            await this.createOwner(obj).then(reponse => {
                    this.loadDataSourceOwners();
                }); ;
        },
        async update(key, obj){
            let oldObj = {
                uuid: key,
                name: '',
                id_number: ''
            };
            await  this.getOwnerByID({uuid: key}).then(async response => {
                oldObj.name = obj.name ? obj.name : response.data.name;
                oldObj.id_number = obj.id_number ? obj.id_number : response.data.id_number;
                await this.updateOwner(oldObj).then(reponse => {
                    this.loadDataSourceOwners();
                }); 
            });
        },
        onSelectionChanged(e) {
            console.log('onSelectionChanged -> ', e); 
            this.currentValue = e.selectedRowKeys[0];
            this.onValueChanged(this.currentValue);
  
            if (e.selectedRowKeys.length > 0) {
                this.$refs['dropDownBoxRef'].instance?.close();
            }
        },
    },
    mounted(){
        this.currentValue = this.value;
    }
  };

  </script>
  