import * as types from "../../mutation-types";

const mutations = {
  [types.SET_STATUS]: (state, payload) => {
    state.statuses = payload;
  },
  [types.SET_CRITERIAL_BUILDER_COLUMNS]: (state, payload) => {
    state.criteria_builder_columns = payload;
  },
  [types.UPDATE_STATUS]: (state, payload) => {
    state.statuses.splice(
      state.statuses.indexOf(
        state.statuses.find((c) => c.uuid === payload.uuid)
      ),
      1,
      payload
    );
  },
};

export default mutations;
