import * as api from '../../api/index'
import * as endpoints from '../../api/endpoints'
import * as types from "../../mutation-types";

const actions = {
    getSCHolidays({commit}, payload) {
        return api.fetch(endpoints.SC_HOLIDAY, payload, commit,(response) => {
          commit(types.SET_SC_HOLIDAYS, response.data.data);
          commit("common/"+types.SET_PAGINATION, response.data.pagination, { root: true });
        });
    },
    getSCHoliday({commit}, payload) {
      return api.fetch(endpoints.SC_HOLIDAY+'/'+payload.uuid, payload, commit,(response) => {
        commit(types.SET_SC_HOLIDAY, response.data);
      });
    },
    createSCHoliday({commit}, payload) {
      return api.post(endpoints.SC_HOLIDAY, payload, commit, (response) => {
        commit(types.SET_SC_HOLIDAY, response.data);
      });
    },
    updateSCHoliday({commit}, payload) {
      return api.put(endpoints.SC_HOLIDAY+'/'+payload.uuid, payload, commit, (response) => {
        commit(types.UPDATE_SC_HOLIDAY_ITEM, response.data);
      });
    },
    deleteSCHoliday({commit}, payload) {
      return api.remove(endpoints.SC_HOLIDAY+'/'+payload.uuid, payload, commit, (response) => {
        commit(types.DELETE_SC_HOLIDAY_ITEM, response.data);
      });
    },
}

export default actions;
