<template>
  <div id="setting-group" class="pt-3">
    <p class="text-danger"
       v-if="user.is_administrator && !Boolean(group.is_editable)">
      {{ $t('tools.settings.system_admin_info')}} - {{ group.name }}
    </p>
    <div class="setting form-group row" v-for="setting in group.items" :key="setting.uuid">
      <div class="col-lg-12 form-group pb-0 mb-0">
        <control-container :data="setting"
                           @onSettingChanged="onSettingChanged"
                           control-class="col-lg-8"></control-container>
      </div>
    </div>
    <div class="col-xs-12 col-sm-6 col-md-6 col-lg-3 mb-3" v-if="group && group.items && group.items.length > 0">
      <button class="btn btn-primary w-100" @click="save">Save</button>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import ControlContainer from "@/pages/tools/settings/partials/controls/ControlContainer";
import {successMessage} from "@/helpers/common";

export default {
  name: 'SettingGroup',
  props: ['data'],
  data() {
    return {
      group: this.data ? this.data : {},
    }
  },
  components: {
    ControlContainer

  },
  computed: {
    ...mapGetters({
        settings: 'settings/groupSettings',
        user: 'auth/user'
    }),
    hasAccess() {
      return this.user && this.user.id > 0;
    },
  },
  methods: {
    ...mapActions({
        updateSettings: 'settings/updateGroupSettings',
      }),
    validateAll() {
        return this.group?.items.filter((item) => {
          return item.validate();
        }).length === this.group?.items.length;
    },
    save() {
      if(this.validateAll()) {
        this.updateSettings(this.group).then((response) => {
          successMessage(this, response.message);
        });
      }
    },
    onSettingChanged(setting) {

    }
  },
  watch: {
    data: {
      handler: function(newVal) {
        this.group = newVal;
      },
      deep: true,
    },
  }
}
</script>

<style lang="scss" scoped>

</style>
