import * as types from "../mutation-types";

const mutations = {
  [types.SET_SEASONS]: (state, payload) => {
    state.seasons = payload;
  },
  [types.SET_SEASON_FILTERS]: (state, payload) => {
    state.season_filters = payload;
  },
  [types.SET_SEASON]: (state, payload) => {
    state.season = payload;
  },
}

export default mutations;
