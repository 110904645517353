<template>
  <div id="sc-bonus-list-filters" class="filter-actions col-lg-12 filters p-3">
    <div class="row">
      <div class="col-lg-2 form-group">
        <label class="control-label">{{$t("name")}}</label>
        <input v-model="filters.name" class="form-control" :placeholder="$t('name')"/>
      </div>
      <div class="col-sm-6 col-lg-2 form-group">
        <label class="control-label">{{$t("salary_calculator.bonus.type")}}</label>
        <b-form-select
          v-model="filters.type"
          class="form-control"
          :options="bonus_type_list"></b-form-select>
      </div>
      <div class="col-sm-6 col-lg-2 form-group">
        <label class="control-label">{{ $t("salary_calculator.bonus.is_global") +'/'+$t("salary_calculator.bonus.is_personal") }}</label>
        <b-form-select v-model="filters.is_global" class="form-control" :options="global_list"></b-form-select>
      </div>
      <div class="col-sm-6 col-lg-2 form-group">
        <label class="control-label">{{$t("common.active")}}</label>
        <b-form-select
          v-model="filters.is_active"
          class="form-control"
          :options="active_list"></b-form-select>
      </div>
      <div class="col-lg-2 mt-3">
        <button class="btn-sm btn-primary col-lg-12 mt-3" @click="applyFilters">{{$t("common.show")}}</button>
      </div>
      <div class="col-lg-2 mt-3">
        <button class="btn-sm btn-secondary col-lg-12 mt-3" @click="resetFilters">{{$t("common.reset_filters")}}</button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import * as type from '../../../../store/mutation-types'
import {buildDropdownOptions} from "@/helpers/common";

export default {
  name: 'SCBonusFilters',
  props: ["limit"],
  data() {
    return {
      filters:  this.gridFilters ? this.gridFilters : {},
      global_list: [
        { value: '', text: this.$t('common.all') },
        { value: 1, text: this.$t('salary_calculator.bonus.is_global') },
        { value: 0, text: this.$t('salary_calculator.bonus.is_personal') },
      ],
      active_list: [
        { value: '', text: this.$t('common.all') },
        { value: 1, text: this.$t('common.active') },
        { value: 0, text: this.$t('common.inactive') },
      ],
    }
  },
  computed: {
    ...mapGetters({
        gridFilters: 'scBonus/scBonusFilters',
        bonusTypes: 'scBonus/scBonusTypes'
    }),
    bonus_type_list() {
      let vm = this;
      return buildDropdownOptions(Object.keys(this.bonusTypes).map((key) => {
        return vm.bonusTypes[key];
      }), {
        value: 'key',
        text: 'name',
      }, {
        value: null,
        text: '-',
      });
    },
  },
  methods: {
    ...mapActions({
        getData: 'scBonus/getSCBonuses',
        getBonusTypes: 'scBonus/getSCBonusTypes'
    }),
    applyFiltersClick() {
      this.$set(this.filters, 'page', 1);
      this.$router.push({
          path: '/sc/bonus',
          params: {
            page: 1,
          }
        }
      );
      this.applyFilters();
    },
    applyFilters() {
      this.$emit('onFiltersApplied', this.filters);
      this.getData(this.filters);
    },
    resetFilters() {
      this.filters = {
        name: '',
        type: null,
        is_global: '',
        is_active: '',
        page: 1,
        limit: this.limit,
      };

      this.$store.commit("scBonus/"+type.SET_SC_BONUS_FILTERS, this.filters);
      this.applyFilters();
    },
  },
  mounted(){
    this.getBonusTypes();
    this.resetFilters();
  },
  watch: {
    gridFilters: {
      handler: function(newVal) {
        this.filters = newVal;
      },
      deep: true,
    }
  }
}
</script>
