<template>
  <div>
    <router-link :to="url" class="text-primary c-pointer" :class="className" v-if="url && !onClick">{{ title }}</router-link>
    <span :class="className" class="text-primary c-pointer" v-else @click="onClickHandler">
      <loader :loading="loading" size="sm"></loader> {{ title }}
    </span>
    <slot></slot>
  </div>
</template>

<script>
import Loader from "@/components/Loader.vue";

export default {
  name: 'Link',
  components: {Loader},
  props: ['data', 'title', 'url', 'onClick', 'className', 'loading'],
  methods: {
    onClickHandler() {
      if (this.onClick instanceof Function) {
        this.onClick();
      }
    }
  },
  watch: {
    props: {
      handler: function(newVal) {
        console.log('watch props', newVal);
      }
    },
    deep: true,
  }
}
</script>
