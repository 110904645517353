<template>
  <div class="dynamic-department-filter-types" :class="[containerStyle, colDirection ?? 'col']">
    <div class="row">
      <div class="col-lg form-group">
        <img src="@/assets/images/icons/options.svg" class="c-pointer filter-icon" @click="showAgroFilterDialogUnits"/>
        <agro-filter-dialog :show="showAgroFilterDialog"
                                     :data="agroFilterDialogData"
                                     :singleSelect="singleSelect"
                                     @onOK="onDepSelectionApplied"
                                     @close="onClose"
                                     ref="dialog"
        ></agro-filter-dialog>
        <span class="c-pointer" @click="showAgroFilterDialogUnits" v-if="(!hasFilter && singleSelect) || !singleSelect">
          <span v-if="(!hasInputItems && singleSelect) || !singleSelect">
            {{ title ?? $t('units_filter') }}
          </span>
        </span>
        <div v-if="hasFilter && !singleSelect" class="text-primary fs-small">
          {{ $t('item_selected', { count: filters.department_uuid.length}) }}
        </div>
        <div v-if="hasFilter && singleSelect" class="text-primary fs-small single-select-dep">
          {{ filters.department_uuid[0].name }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import AgroFilterDialog from "@/components/AgroFilter/AgroFilterDialog.vue";

export default {
  name: 'AgroFilter',
  props: ['containerStyle', 'colDirection', 'required', 'inputUUID', 'singleSelect', 'title', 'selectedItems'],
  data() {
    return {
      filters: {
        department_uuid: [],
      },
      showAgroFilterDialog: false,
      agroFilterDialogData: {},
    }
  },
  components: {
    AgroFilterDialog,
  },
  computed: {
    ...mapGetters({
      domain: 'auth/domain',
      departments: 'department/departmentList',
      departmentFilterTypes: 'agroFilter/departmentFilterTypes',
    }),
    hasFilter() {
      return this.filters.department_uuid.length > 0 && this.filters.department_uuid[0] && this.filters.department_uuid[0] !== null;
    },
    hasInputItems() {
      return this.selectedItems && this.selectedItems.length > 0 && this.selectedItems[0] && this.selectedItems[0].uuid;
    }
  },
  methods: {
    ...mapActions({
      listDepartments: 'department/listDepartments',
      getDepartmentFilterTypes: 'agroFilter/getDepartmentFilterTypes'
    }),
    onClose() {
      this.showAgroFilterDialog = false;
    },
    onDepSelectionApplied(selectedDeps) {
      const pluck = Object.values(Object.assign([], selectedDeps).map((item) => {
        return item.uuid;
      }));
      this.$emit('onDepSelectionApplied', pluck.join());
      this.$emit('onChange', pluck.join());

      if (this.singleSelect) {
        if (pluck && pluck[0]) {
          this.filters.department_uuid = [this.departments.find(d => d.uuid == pluck)];
        } else {
          this.filters.department_uuid = [];
        }
      } else {
        this.filters.department_uuid = pluck;
      }
    },
    showAgroFilterDialogUnits() {
      //filters
      let vm = this;
        this.showAgroFilterDialog = true;
        this.agroFilterDialogData.departments = this.departments.filter((item) => {
          if (vm.hasInputItems) {
            let selectedFound = vm.selectedItems.find(si => si.uuid === item.uuid);

            if (selectedFound) {
              item.selected = true;
              return item;
            }
          }

          return vm.filters.department_uuid.indexOf(item.uuid) > -1;
        });
    },
  },
  mounted() {
    this.listDepartments({
      company_uuid: this.domain.uuid,
    });
  },
  watch: {
    selectedItems: {
      handler: function(newVal) {
        if (newVal && newVal.length > 0) {
          if(!this.filters.department_uuid) {
            this.filters.department_uuid = []
          }

          this.filters.department_uuid.push(newVal[0]);
        }
      },
      deep: true,
      immediate: true,
    }
  }
}
</script>
<style scoped>
.filter-icon {
  margin-right: 5px;
}

.single-select-dep {
  display: inline;
}
</style>
