<template>
    <div v-if="show" class="modal">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header d-flex justify-content-between align-items-center">
                    <h5 class="modal-title">{{ title }}</h5>
                    <button type="button" class="close rounded-close" @click="$emit('close')">
                        <span>&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <p>{{ message }}</p>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary mr-3" @click="$emit('close')">{{ $t("cancel") }}</button>
                    <button type="button" class="btn btn-success" @click="$emit('confirm')">{{ $t("ok") }}</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        show: {
            type: Boolean,
            required: true,
        },
        item: {
            type: Object,
            required: false,
        },
        title: {
            type: String,
            default: 'Confirm',
        },
        message: {
            type: String,
            default: 'Are you sure?',
        },
    },
};
</script>

<style scoped>
.modal {
    display: block;
    background: rgba(0, 0, 0, 0.5);
}

.modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
}

.close {
    border: none;
    background: none;
    font-size: 1.5rem;
    cursor: pointer;
    float: right;
}

.rounded-close {
    border-radius: 50%;
    padding: 0.25rem 0.5rem;
}

.modal-footer .btn-secondary {
    margin-right: 1rem;
}
</style>