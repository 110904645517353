export const DetailCodes = {
    Products: "PROD",
    Partners: "PART",
    Warehouse: "WARE",
}

export const InputDetailCodes = {
    ProductType: "PROD_TYPE",
    MeasureUnit: "MU",
    Partner: "PART",
}

