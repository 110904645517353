<template>
   <DxTextBox
    v-model="inputText"
    value="password"
    styling-mode="filled"
    :placeholder="placeholder"
    :class="(hasValue ? 'custom_search_input_with_close' : 'custom_search_input') + (parentClass && parentClass.length > 0 ? ' ' + parentClass : '' )"
    value-change-event="keyup"
    @value-changed="handleTextChange($event)"
    >
    <DxTextBoxButton
        :options="passwordButton"
        name="password"
        location="before"
        :element-attr="buttonAttributes"
    />
    </DxTextBox>
</template>

<script>

import { DxTextBox, DxButton as DxTextBoxButton } from 'devextreme-vue/text-box';


export default {
    name: "SearchInput",
    props: {
        placeholder: {
          type: String,
          default: 'Caută',
        },
        parentClass: {
            type: String,
            default: '',
        }
    },
    data(){
        return {
            fireTextChange: true,
            searchTimer: {},
            searchTimerInterval: 500,
            inputText:'',
            hasValue: false,
            passwordButton: {
                onClick: () => {
                    if(this.inputText.length > 0){
                        this.inputText = '';
                    }
                },
            },
             buttonAttributes: {
                class: 'custom-search-button'
            }
        }
    },
    components: {
        DxTextBox,
        DxTextBoxButton,
    },
    watch: {
        inputText(newVal){
            this.hasValue = newVal && newVal.length > 0;
        }
    },
    methods: {
        handleTextChange(e){
            if(this.fireTextChange){
                clearTimeout(this.searchTimer);
                this.filterTimer = setTimeout(this.persistTextChange, this.searchTimerInterval, e.value);
            }
            this.fireTextChange = true;
        },
        persistTextChange(searchText){
            this.$emit('search', searchText);
        },
        resetControl(){
            this.fireTextChange = false;
            this.inputText = '';
        }
    }

}
</script>

<style lang="scss">
    @import "@/assets/scss/components/searchInput.scss";
</style>
