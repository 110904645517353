<template>
    <div>
        <card :title="$t('reporting.management.title')"></card>
        <br/>
        <div v-show="reportGridViewVisibility"><b>{{ selectedReport.name }}</b></div>
        <div v-show="!reportGridViewVisibility" class="content-body pt-4">
            <div class="row">
                <div class="col-lg-12">
                    <DxDataGrid 
                        :data-source="reportDataSurce"
                        :show-borders="false"
                        :remote-operations="gridRemoteOperations"
                        :element-attr="gridAttributes"
                    >
                        <DxColumn
                            data-field="name"
                            data-type="string"
                            caption="Denumire"
                            :editor-options="columnNameEditorOptions"
                        >
                            <DxStringLengthRule :min="1" message="Numele raportului este obligatorie!"/>
                        </DxColumn>
                        <DxColumn
                            :visible="false"
                            data-field="code"
                            data-type="string"
                            caption="Cod"
                            :editor-options="columnCodeEditorOptions"
                        >
                            <DxStringLengthRule :min="1" message="Codul raportului este obligatoriu!"/>
                        </DxColumn>
                        <DxColumn
                            data-field="group_id"
                            caption="Grupa"
                        >
                            <DxLookup
                                :data-source="reportGroups"
                                value-expr="id"
                                display-expr="name"
                                />
                                <DxStringLengthRule :min="1" message="Grupa raportului este obligatorie!"/>
                        </DxColumn>
                        <DxColumn
                            data-field="datasource_SP"
                            data-type="string"
                            caption="Datasource"
                            :editor-options="columnDatasourceEditorOptions"
                        >
                            <DxStringLengthRule :min="1" message="Sursa raportului este obligatorie!"/>
                        </DxColumn>
                        <DxColumn
                            data-field="is_standalone"
                            caption="Individual"
                            data-type="number"
                            :visible="false"
                        >
                            <DxFormItem
                                :col-span="2"
                                editor-type="dxCheckBox"
                            />
                        </DxColumn>
                        <DxColumn
                            data-field="description"
                            caption="Descriere"
                            data-type="string"
                        >
                            <DxFormItem
                                :col-span="2"
                                :editor-options="{ height: 100 }"
                                editor-type="dxTextArea"
                            />
                        </DxColumn>
                        <DxColumn
                            type="buttons"
                            :width="110"
                        >
                            <DxButton
                                name="edit"
                                :visible="hasReportAdminRole"
                            />
                            <DxButton
                                hint="Designer"
                                icon="preferences"
                                :onClick="openReportDesigner"
                                :visible="hasReportAdminRole"
                            />
                            <DxButton
                                hint="Report"
                                icon="doc"
                                :onClick="openReportViewer"
                            />
                            <DxButton
                                hint="Arata raport"
                                icon="smalliconslayout"
                                :onClick="showReportGridView"
                            />
                        </DxColumn>
                        <DxPaging :total="report_list.length" :page-size="15"/>
                        <DxPager
                            :visible="true"
                            :allowed-page-sizes="pageSizes"
                            display-mode="compact"
                            :show-page-size-selector="true"
                            :show-info="true"
                            :show-navigation-buttons="true"
                        />
                        <DxEditing
                            :allow-adding="true"
                            :allow-updating="true"
                            :allow-deleting="false"
                            :use-icons="true"
                            mode="form"
                        >
                            <DxTexts
                                confirm-delete-message="Sigur vreți să ștergeți raportul?",
                                confirm-delete-title="Confirmare ștergere"
                            />
                        </DxEditing>
                        <DxHeaderFilter :visible="true" />
                        <DxFilterRow :visible="true" apply-filter="auto"/>
                        <DxSelection mode="single" />
                    </DxDataGrid>
                </div>
            </div>
        </div>  
        <div v-show="reportGridViewVisibility" class="content-body pt-4">
            <div class="row">
                <div class="col-lg-12">
                    <ReportGridView  :templateData="selectedReport" :showModule="reportGridViewVisibility" @hideReportGridView="hideReportGridView"></ReportGridView>
                </div>
            </div>
        </div>  
        <ReportDesignerDialog v-if="reportDesignerVisibility" :templateData="selectedReport" :showPopup="reportDesignerVisibility" @hidden="closeReportDesigner"></ReportDesignerDialog>
        <DocumentViewerDialog v-if="reportViewerVisibility" :templateData="selectedReport" :showPopup="reportViewerVisibility" @hidden="closeReportViewer"></DocumentViewerDialog>
        
    </div>
</template>

<script>

import { mapGetters, mapActions } from 'vuex';
import { DxDataGrid, DxColumn, DxPaging, DxPager, DxEditing, DxTexts, DxLookup, DxRequiredRule, DxStringLengthRule, DxHeaderFilter, DxFilterRow, DxSelection, DxButton, DxFormItem } from 'devextreme-vue/data-grid';
import ReportDesignerDialog from './designer.vue'
import DocumentViewerDialog from './viewer.vue'
import ReportGridView from '../partials/reportGridView.vue'
import {permissions, hasRole} from "@/helpers/Permissions";
import Forbidden from "~/pages/errors/Forbidden.vue";
import CustomStore from 'devextreme/data/custom_store';
import * as api from '../../../store/api/index'
import * as endpoints from '../../../store/api/endpoints'
import {getFilterDataByName, getSortByProperty, getSortDirection} from "@/helpers/dxGridHelper";

export default {
    name: 'ReportManagement',
    data(){
        return {
            reportDesignerVisibility: false,
            reportViewerVisibility: false,
            gridAttributes:{
                class: 'admin-grid'
            },
            gridRemoteOperations: {
                paging: true,
                filtering : true,
                sorting: true
            },
            reportGridViewVisibility: false,
            selectedReport: {},
            columnNameEditorOptions: { showClearButton: true, placeholder: "Denumire raport" },
            columnCodeEditorOptions: { showClearButton: true, placeholder: "Cod raport" },
            columnDatasourceEditorOptions: { showClearButton: true, placeholder: "Datasource raport" },
            pageSizes: [15, 25, 100],
            report_list: [],
            reportGroups:[],
            reportDataSurce: new CustomStore({
                key: 'id',
                async load(loadOptions){

                    let pageLimit = loadOptions.take;
                    let pageNumber = (loadOptions.skip + loadOptions.take)/pageLimit;
                    
                    let dataSource =  {
                        data:[],
                        totalCount:0
                    };
                    let payload = {
                        page: pageNumber,
                        limit: pageLimit,
                        sortBy: getSortByProperty(loadOptions) ?? 'name',
                        sortDirection: getSortDirection(loadOptions) ?? 'ASC',
                        name: getFilterDataByName(loadOptions, "name")
                    }
                    await api.fetchFull(endpoints.REPORTS, payload, null,(response) => {
                        dataSource.data = response.data.data;
                        dataSource.totalCount = response.data.pagination.total
                    });

                    return dataSource;
                },
                insert: this.insert,
                update: this.update,
                remove: this.deleteReportGroup
            }),
        }
    },
    components: {
        DxDataGrid, DxColumn, DxPaging, DxPager, DxEditing, DxTexts, DxLookup, DxRequiredRule, DxStringLengthRule, DxHeaderFilter, DxFilterRow, DxSelection, DxButton, DxFormItem,
        ReportDesignerDialog, DocumentViewerDialog,
        ReportGridView
    },
    methods:{
        ...mapActions({
            
            getReportByUUID: 'reporting/getReportByUUID',
            createReport: 'reporting/createReport',
            updateReport: 'reporting/updateReport',
            deleteReport: 'reporting/deleteReport',
            getReports: "reporting/getReports",
            getReportsData: "reporting/getReportsData",
            getReportGroups: 'reporting/getReportGroups',
        }),
        openReportDesigner(e){
            this.selectedReport = e.row.data;
            this.reportDesignerVisibility = true;
        },
        openReportViewer(e){
            this.selectedReport = e.row.data;
            this.reportViewerVisibility = true;
        },
        closeReportDesigner(){
            this.reportDesignerVisibility = false;
        },
        closeReportViewer(){
            this.reportViewerVisibility = false;
        },
        showReportGridView(e){
            this.selectedReport = e.row.data;
            this.reportGridViewVisibility = true;
        },
        hideReportGridView(e){
            this.reportGridViewVisibility = false;
        },
        hasReportRole() {
            return hasRole(this, permissions.REPORTS);
        },
        hasReportAdminRole() {
            return hasRole(this, permissions.REPORT_ADMIN);
        },
        loadReportGroups(){
            this.getReportGroups().then(response => {
                console.log(response);
                this.reportGroups = response.data;
            });
        },
        async insert(obj){
            obj.is_standalone = obj.is_standalone ? 1 : 0;
            await this.createReport(obj);
        },
        async update(key, obj){
            console.log(obj);
            let oldObj = {
                id: key,
                name: '',
                code: ''
            }
            obj.uuid = key;
            await this.getReportByUUID({id: key}).then(async response => {
                oldObj.name = obj.name ? obj.name : response.data.name;
                oldObj.code = obj.code ? obj.code : response.data.code;
                await this.updateReport(oldObj);
            });
            
        }
    },
    mounted(){
        this.loadReportGroups();
        this.getReports({}).then(response => {
            this.report_list = response;
        });
        console.log('has report role -> ', this.hasReportRole());
        console.log('has report admin role -> ', this.hasReportAdminRole());

    }
    
}

</script>