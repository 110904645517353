import Cookies from 'js-cookie'
import {getSubdomain} from "@/helpers/common";

export default async (route, from, next, params) => {
  const router = params.router;
  const store = params.store;
  Cookies.set('domain', getSubdomain());
  store.dispatch('auth/checkDomain', {
    domain: Cookies.get('domain'),
  }).catch((e) => {
    console.log('check domain ex', e);
    if(e && e.status) {
      if(e.status === 404) {
        router.push({ path: '/errors/company-not-found' });
      } else {
        router.push({ path: '/errors/general-error' });
      }
    }
  });
}
