import axios from '@/plugins/axios'
import * as api from '../api/index'
import * as types from "../mutation-types";
import {RECENTLY_UPLOADED_FILES, RECENTLY_UPLOADED_ZIP_FILES, replaceParams} from "~/store/api/endpoints";

const actions = {
  getCompany({commit}, payload) {
      return new Promise((resolve, reject) => {
        axios.get('/current-company', {
          params: {},
        }).then((response) => {
          commit(types.SET_COMPANY, response.data.data);
          resolve(response.data.data);
        }).catch((e) => {
          commit("common/"+types.SET_ERROR_MESSAGE, e, { root: true });
          reject(e);
        });
      })
  },
  updateCompany({commit}, payload) {
      return new Promise((resolve, reject) => {
          axios.put('/current-company', payload).then((response) => {
            commit(types.SET_COMPANY, response.data.data);
            resolve(response.data);
          }).catch((e) => {
            reject(e.response.data);
          });
      })
  },
  getParentCompanies({commit}, payload) {
    return new Promise((resolve, reject) => {
      axios.get('/parent-companies', {
        params: payload,
      }).then((response) => {
        commit(types.SET_PARENT_COMPANIES, response.data.data);
        resolve(response.data.data);
      }).catch((e) => {
        commit("common/"+types.SET_ERROR_MESSAGE, e, { root: true });
        reject(e);
      });
    })
  },
  getPublicParentCompanies({commit}, payload) {
    return new Promise((resolve, reject) => {
      axios.get('/public/parent-companies', {
        params: payload,
      }).then((response) => {
        commit(types.SET_PARENT_COMPANIES, response.data.data);
        resolve(response.data.data);
      }).catch((e) => {
        commit("common/"+types.SET_ERROR_MESSAGE, e, { root: true });
        reject(e);
      });
    })
  },
  getDepartments({commit}, payload) {
    return new Promise((resolve, reject) => {
      axios.get('/companies/'+payload.company_uuid+'/departments', {
        params: payload,
      }).then((response) => {
        commit(types.SET_DEPARTMENTS, response.data.data);
        resolve(response.data.data);
      }).catch((e) => {
        commit("common/"+types.SET_ERROR_MESSAGE, e, { root: true });
        reject(e);
      });
    })
  },
  createDepartamentSimple({commit}, payload) {
    return api.post('/current-company/simple-department', payload, commit,(response) => {

    });
  },

  //files
  getRecentlyUploadedFiles({commit}, payload) {
    return new Promise((resolve, reject) => {
      axios.get(replaceParams(RECENTLY_UPLOADED_FILES, {
        company_uuid: payload.company_uuid
      }), {
        params: payload,
      }).then((response) => {
        commit(types.SET_RECENTLY_UPLOADED_FILES, response.data.data.data);
        resolve(response.data.data.data);
      }).catch((e) => {
        commit("common/"+types.SET_ERROR_MESSAGE, e, { root: true });
        reject(e);
      });
    })
  },
  //zip files
  getRecentlyUploadedZipFiles({commit}, payload) {
    return new Promise((resolve, reject) => {
      axios.get(replaceParams(RECENTLY_UPLOADED_ZIP_FILES, [{
        company_uuid: payload.company_uuid
      }]), {
        params: payload,
      }).then((response) => {
        commit(types.SET_RECENTLY_UPLOADED_ZIP_FILES, response.data.data.data);
        resolve(response.data.data.data);
      }).catch((e) => {
        commit("common/"+types.SET_ERROR_MESSAGE, e, { root: true });
        reject(e);
      });
    })
  },
}

export default actions;
