<template>
  <div class="view" v-if="canEdit || canDelete" v-cloak>
    <card :title="title" id="scouting-list">
      <agro-filter-container :hide-add="true" :title='title'>
        <scouting-filters
            ref="filters"
            @onFiltersApplied="onFiltersApplied"
        />
      </agro-filter-container>
    </card>
    <div class="content-body pt-4">
      <div class="row">
        <div class="col-lg-12">
          <agro-grid
              :headers="headers"
              :rows="scoutingItems"
              :paginate="true"
              :permission="config"
              :action="onPageSelected"
              :loading="loading"
              @onSortBy="onSortBy"
          >
          </agro-grid>
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <forbidden></forbidden>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import Forbidden from "@/pages/errors/Forbidden.vue";
import AgroGrid from "@/components/AgroGrid/index";
import WrapNotes from "~/pages/scouting/admin/partials/WrapNotes.vue";
import ScoutingFilters from "~/pages/scouting/admin/partials/scoutingFilters.vue";
import ScoutingImages from "@/pages/scouting/admin/partials/ScoutingImages.vue";
import * as type from "~/store/mutation-types";
import {canDelete, canEdit, permissions, permissionTypes} from "~/helpers/Permissions";
import AgroFilterContainer from "@/components/AgroFilter/partials/AgroFilterContainer.vue";

export default {
  name: 'ScoutingsList',
  middleware: ['auth', 'check-company-admin'],
  data() {
    return {
      filters: this.scoutingFilters ? this.scoutingFilters : {},
      scoutingItems: this.scoutings ? this.scoutings : [],
      pager: this.pagination ? this.pagination : {},
      loading: false,
    }
  },
  components: {
    AgroFilterContainer,
    ScoutingFilters,
    AgroGrid,
    Forbidden,
  },
  computed: {
    config() {
      return {
        permission: permissions.SCOUTINGS,
        permission_type: permissionTypes.module,
        delete: {
          method: this.deleteObject,
          callback: this.onItemDeleted,
        }
      };
    },
    canEdit() {
      return canEdit(this);
    },
    canDelete() {
      return canDelete(this);
    },
    headers() {
      return  [
        { name: this.$t('scouting.date'), col: 'reported_date', width: '12%', sortable: true, align: 'center'},
        { name: this.$t('scouting.engineer_name'), col: 'engineer_name', width: '14%', sortable: true, align: 'center'},
        { name: this.$t('scouting.parcel_name'), col: 'parcel_name', width: '12%', sortable: true, align: 'center'},
        { name: this.$t('scouting.bbch'), col: 'scoutingBBCH', width: '8%', align: 'center'},
        { name: this.$t('scouting.diseases'), col: 'disease_names', width: '13%', align: 'center'},
        { name: this.$t('scouting.infection_level'), col: "infection_levels", width: '15%', align: 'center'},
        { name: this.$t('scouting.notes'), colTemplate: this.wrapNotes, width: '14%', align: 'center'},
        {
          name: this.$t('scouting.images'),
          colTemplate: this.getScoutingPictures,
          width: '12%',
          sortable: false,
          align: 'center'
        },
      ];
    },
    title() {
      return this.$t('scouting.title');
    },
    ...mapGetters({
      scoutingFilters: 'scouting/scoutingFilters',
      scoutings: 'scouting/scoutings',
      pagination: 'common/pagination',
    })
  },
  methods: {
    ...mapActions({
      deleteObject: 'scouting/deleteScouting',
    }),
    // check if bbchTo exists, concatenate them and assign to a new variable in every object
    formatBbchValues() {
      this.scoutingItems.forEach(function(item) {
        item.scoutingBBCH = item.bbch_value_from.name;
        item.bbch_value_to !== null ? item.scoutingBBCH += ' - ' + item.bbch_value_to.name : item.scoutingBBCH;
      });
    },
    // get disease names and put it into a new variable
    getDiseasesName() {
      this.scoutingItems.forEach(function(scouting) {
        if (scouting.diseases.length !== 0) {
          scouting.disease_names = "";
          scouting.diseases.forEach(function(disease) {
            scouting.disease_names += '<div>' + disease.name + '</div>';
          });
        }
      });
    },
    //transform endpoint 1,2,3 values to low,medium, high
    getDiseasesInfectionLevel() {
      this.scoutingItems.forEach(function(scouting) {
        if (scouting.diseases.length !== 0) {
          scouting.infection_levels = "";
          scouting.diseases.forEach(function(disease) {
            if (disease.infection_level === 1) {
              disease.string_infection_level = "Low";
            }
            if (disease.infection_level === 2) {
              disease.string_infection_level = "Medium";
            }
            if (disease.infection_level === 3) {
              disease.string_infection_level = "High";
            }
            scouting.infection_levels += '<div>' + disease.string_infection_level + '</div>';
          });
        }
      })
    },
    wrapNotes(col) {
      return WrapNotes;
    },
    getScoutingPictures(col) {
      return ScoutingImages;
    },
    onFiltersApplied(filters) {
      this.loading = true;
    },
    onPageSelected(page_id) {
      this.filters.page = page_id;
      this.$store.commit("scouting/"+type.SET_SCOUTING_FILTERS, this.filters);
      this.$refs.filters.applyFilters();
    },
    onSortBy(data) {
      this.scoutingFilters.sortBy = data.sortBy;
      this.scoutingFilters.sortDirection = data.sortDirection;
      this.onPageSelected(this.currentPage);
    },
    onItemDeleted(item) {
      console.log(item);
      this.scoutingItems.splice(
          this.scoutingItems.indexOf(
              this.scoutingItems.find(it => it.uuid === item.uuid)
          ), 1
      );
    },
  },
  watch: {
    scoutings: {
      handler: function(newVal) {
        this.scoutingItems = newVal;
        this.formatBbchValues();
        this.getDiseasesName();
        this.getDiseasesInfectionLevel();
      },
      deep: true,
    },
    pagination: {
      handler: function(newVal) {
        this.pager = newVal;
      },
      deep: true,
      immediate: true,
    },
  },
  mounted(){
    this.onPageSelected(1);
  }
}
</script>
