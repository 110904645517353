<template>
  <DxTabPanel
      :height="420"
      :animation-enabled="true"
      :swipe-enabled="true"
  >
    <DxItem :title="$t('reporting.tabs.filters.title')">
      <template #default="{ data: tab }">
        <ReportFilterTemplate
            :data="data.data"
        />
      </template>
    </DxItem>
    <DxItem :title="$t('reporting.tabs.columns.title')">
      <template #default="{ data: tab }">
        <ReportColumnConfiguratorTemplate
            :data="data.data"
        />
      </template>
    </DxItem>
  </DxTabPanel>
</template>

<script>
import DxTabPanel, {DxItem} from "devextreme-vue/tab-panel";
import ReportColumnConfiguratorTemplate from "@/pages/reporting/reports/partials/ReportColumnConfiguratorTemplate.vue";
import ReportFilterTemplate from "@/pages/reporting/reports/partials/ReportFilterTemplate.vue";

export default {
  props: {
    data: Object,
  },
  components: {
    ReportFilterTemplate,
    ReportColumnConfiguratorTemplate,
    DxTabPanel, DxItem,
  },
}
</script>
