<template>
  <div id="sc-employee-list-filters" class="filter-actions col-lg-12 filters p-3">
    <div class="row">
      <div class="col-lg-4">
        <label class="control-label">{{$t("name")}}</label>
        <input v-model="filters.name" class="form-control" :placeholder="$t('name')"/>
      </div>
      <div class="col-sm-4 col-lg-2">
        <label class="control-label">{{$t("salary_calculator.employee_role.name")}}</label>
        <b-form-select
          v-model="filters.employee_role_uuid"
          class="form-control"
          :options="employee_role_list"></b-form-select>
      </div>
      <div class="col-lg-2 mt-4 pt-1">
        <button class="btn-sm btn-primary col-lg-12" @click="applyFilters">{{$t("common.show")}}</button>
      </div>
      <div class="col-lg-2 mt-4 pt-1">
        <button class="btn-sm btn-secondary col-lg-12" @click="resetFilters">{{$t("common.reset_filters")}}</button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import * as type from '../../../../store/mutation-types'
import {buildDropdownOptions} from "@/helpers/common";

export default {
  name: 'SCEmployeeFilters',
  props: ["limit"],
  data() {
    return {
      filters:  this.gridFilters ? this.gridFilters : {},
    }
  },
  computed: {
    ...mapGetters({
        gridFilters: 'scEmployee/scEmployeeFilters',
        employeeRoles: 'scEmployeeRole/scEmployeeRoles'
    }),
    employee_role_list() {
      return buildDropdownOptions(Object.assign([], this.employeeRoles), {
        value: 'uuid',
        text: 'name',
      }, {
        value: null,
        text: '-',
      });
    },
  },
  methods: {
    ...mapActions({
      getData: 'scEmployee/getSCEmployees',
      getEmployeeRoles: 'scEmployeeRole/getSCEmployeeRoles'
    }),
    applyFiltersClick() {
      this.$set(this.filters, 'page', 1);
      this.$router.push({
          path: '/sc/employee',
          params: {
            page: 1,
          }
        }
      );
      this.applyFilters();
    },
    applyFilters() {
      this.$emit('onFiltersApplied', this.filters);
      this.getData(this.filters);
    },
    resetFilters() {
      this.filters = {
        name: '',
        employee_role_uuid: null,
        page: 1,
        limit: this.limit,
      };

      this.$store.commit("scEmployee/"+type.SET_SC_EMPLOYEE_FILTERS, this.filters);
      this.applyFilters();
    },
  },
  mounted(){
    this.getEmployeeRoles();
    this.resetFilters();
  },
  watch: {
    gridFilters: {
      handler: function(newVal) {
        this.filters = newVal;
      },
      deep: true,
    }
  }
}
</script>
