<template>
  <button :type="nativeType" :disabled="loading" :class="{
  [`btn-${type}`]: true,
    'btn-block': block,
    'btn-lg': large,
    'btn-loading': loading
  }" class="btn"
      @click="onClick"
  >
    <slot />
  </button>
</template>

<script>
export default {
  name: 'VButton',

  props: {
    click: {
        type: Function,
    },
    type: {
      type: String,
      default: 'outline-primary'
    },

    nativeType: {
      type: String,
      default: 'button'
    },

    loading: {
      type: Boolean,
      default: false
    },

    block: {
      type: Boolean,
      default: false
    },

    large: {
      type: Boolean,
      default: false
    }
  },
    methods: {
      onClick() {
          this.$emit('click', this.click);
      }
    }
}
</script>
