
<template>
    <transition name="modal" id="contact-info-dialog">
        <div>
            <DxPopup
            height="140px"
            width="calc(100vw - 48px)"
            :close-on-outside-click="false"
            :show-close-button="true"
            :show-title="false"
            :visible="showPopup"
            :wrapper-attr="dialogAttribute"
            @hidden="$emit('hidden')">
            <template #content>
                <div class="confirm-button-holder">
                    <div class="delete-button" @click="cancelPayments">Șterge</div>
                    <div class="cancel-button" @click="$emit('hidden')">Renunță</div>
                </div>
            </template>

            </DxPopup>
        </div>
    </transition>
</template>

<script>

import DxPopup from "devextreme-vue/popup";
import { mapGetters, mapActions } from "vuex";

export default {
    name: "PaymentDeleteConfirm",
    props: {
        showPopup: {
            type: Boolean,
            default: false,
        },
        payments:{
            type: Array,
            default() {
                return []
            }
        },
    },
    data(){
        return {
            dialogAttribute:{
                class: "payment-delete-confirm-dialog"
            }
        };
    },
    components:{
        DxPopup
    },
    methods:{
        ...mapActions({
            cancelPayment: "leasecontracts/cancelPayment",
        }),
        cancelPayments(){
            console.log(this.payments);
            if(this.payments && this.payments.length > 0){
                this.payments.forEach(p => {
                    this.cancelPayment({payment_uuid:p.uuid});
                });
                this.$emit('hideAndRefresh');
            }
        }
    }
}
</script>

<style lang="scss">
  @import "@/assets/scss/rent/paymentDeleteConfirm.scss";
</style>
