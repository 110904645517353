<template>
    <div>
        <card :title="$t('reporting.groups.title')"></card>
        <div class="content-body pt-4">
            <div class="row">
                <div class="col-lg-12">
                    <DxDataGrid 
                        
                        :data-source="reportGroupDataSurce"
                        :show-borders="false"
                        :remote-operations="gridRemoteOperations"
                        :element-attr="gridAttributes"
                    >
                        <DxColumn
                            data-field="name"
                            data-type="string"
                            caption="Denumire"
                            :editor-options="columnNameEditorOptions"
                        >
                            <DxStringLengthRule :min="1" message="Numele este obligatoriu!"/>
                        </DxColumn>
                        <DxColumn
                            data-field="code"
                            data-type="string"
                            caption="Cod"
                            :editor-options="columnCodeEditorOptions"
                        >
                            <DxRequiredRule message="Codul este obligatorie!"/>
                        </DxColumn>
                        <DxPaging :total="3500" :page-size="15"/>
                        <DxPager
                            :visible="true"
                            :allowed-page-sizes="pageSizes"
                            display-mode="compact"
                            :show-page-size-selector="true"
                            :show-info="true"
                            :show-navigation-buttons="true"
                        />
                        <DxEditing
                            :allow-adding="true"
                            :allow-updating="true"
                            :allow-deleting="true"
                            :use-icons="true"
                            mode="row"
                        >
                            <DxTexts
                                confirm-delete-message="Sigur vreți să ștergeți grupul?",
                                confirm-delete-title="Confirmare ștergere"
                            />
                        </DxEditing>
                        <DxHeaderFilter :visible="true" />
                        <DxFilterRow :visible="true" apply-filter="auto"/>
                        <DxSelection mode="single" />
                    </DxDataGrid>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import { mapActions, mapGetters } from 'vuex'
import { DxDataGrid, DxColumn, DxPaging, DxPager, DxEditing, DxTexts, DxLookup, DxRequiredRule, DxStringLengthRule, DxHeaderFilter, DxFilterRow, DxSelection } from 'devextreme-vue/data-grid';
import Forbidden from "~/pages/errors/Forbidden.vue";
import CustomStore from 'devextreme/data/custom_store';
import * as api from '../../../store/api/index'
import * as endpoints from '../../../store/api/endpoints'
import {getFilterDataByName, getSortByProperty, getSortDirection} from "@/helpers/dxGridHelper";

export default {
    name: "ReportGroups",
    data(){
        return {
            gridAttributes:{
                class: 'admin-grid'
            },
            gridRemoteOperations: {
                paging: true,
                filtering : true,
                sorting: true
            },
            columnNameEditorOptions: { showClearButton: true, placeholder: "Denumire grup raport" },
            columnCodeEditorOptions: { showClearButton: true, placeholder: "Cod grup raport" },
            pageSizes: [15, 25, 100],
            reportGroupDataSurce: new CustomStore({
                key: 'id',
                async load(loadOptions){

                    let pageLimit = loadOptions.take;
                    let pageNumber = (loadOptions.skip + loadOptions.take)/pageLimit;
                    
                    let dataSource =  {
                        data:[],
                        totalCount:0
                    };
                    let payload = {
                        page: pageNumber,
                        limit: pageLimit,
                        sortBy: getSortByProperty(loadOptions) ?? 'uuid',
                        sortDirection: getSortDirection(loadOptions) ?? 'ASC',
                        name: getFilterDataByName(loadOptions, "name")
                    }
                    await api.fetchFull(endpoints.REPORT_GROUPS, payload, null,(response) => {
                        dataSource.data = response.data.data;
                        dataSource.totalCount = response.data.pagination.total
                    });

                    return dataSource;
                },
                insert: this.insert,
                update: this.update,
                remove: this.deleteReportGroup
            }),
        };
    },
    components: {
        DxDataGrid,
        DxColumn,
        DxPaging,
        DxPager,
        DxEditing,
        DxTexts,
        DxLookup,
        DxRequiredRule,
        DxStringLengthRule,
        DxHeaderFilter,
        DxFilterRow,
        DxSelection,
        Forbidden
    },
    methods: {
        ...mapActions({
            getReportGroups: 'reporting/getReportGroups',
            getReportGroupByUUID: 'reporting/getReportGroupByUUID',
            createReportGroup: 'reporting/createReportGroup',
            updateReportGroup: 'reporting/updateReportGroup',
            deleteReportGroup: 'reporting/deleteReportGroup',
        }),
        async insert(obj){
            await this.createReportGroup(obj);
        },
        async update(key, obj){
            let oldObj = {
                id: key,
                name: '',
                code: ''
            }
            obj.uuid = key;
            await this.getReportGroupByUUID({id: key}).then(async response => {
                oldObj.name = obj.name ? obj.name : response.data.name;
                oldObj.code = obj.code ? obj.code : response.data.code;
                await this.updateReportGroup(oldObj);
            });
            
        }
    }
}

</script>