
<template>
    <transition name="modal" id="contact-info-dialog">
        <div>
            <DxPopup
            height="140px"
            width="calc(100vw - 48px)"
            :close-on-outside-click="false"
            :show-close-button="true"
            :show-title="false"
            :visible="showPopup"
            :wrapper-attr="dialogAttribute"
            @hidden="$emit('hidden')">

            <template #title>
                <div>
                    delte title
                </div>
            </template>

            <template #content>
                <div class="confirm-button-holder">
                    <div class="delete-button" @click="deleteDisease">Șterge</div>
                    <div class="cancel-button" @click="$emit('hidden')">Renunță</div>
                </div>
            </template>

            </DxPopup>
        </div>
    </transition>
</template>

<script>

import DxPopup from "devextreme-vue/popup";
import { mapGetters, mapActions } from "vuex";

export default {
    name: "DeleteDiseaseConfirm",
    props: {
        showPopup: {
            type: Boolean,
            default: false,
        },
        disease_uuid:{
            type: String,
            default() {
                return '';
            }
        },
    },
    data(){
        return {
            dialogAttribute:{
                class: "payment-delete-confirm-dialog"
            }
        };
    },
    components:{
        DxPopup
    },
    methods:{
        ...mapActions({
            cancelPayment: "leasecontracts/cancelPayment",
        }),
        deleteDisease(){
            this.$emit("hidden");
            this.$emit("persistDeleteDisease", this.disease_uuid);
        }
    }
}
</script>

<style lang="scss">
  @import "@/assets/scss/rent/paymentDeleteConfirm.scss";
</style>
