<template>
  <div id="sc-bonus-list-filters" class="filter-actions col-lg-12 filters p-3">
    <div class="row">
      <div class="col-lg-2 form-group">
        <label class="control-label">{{$t("name")}}</label>
        <input v-model="filters.name" class="form-control" :placeholder="$t('name')"/>
      </div>
      <agro-filter @onChange="onDynamicFiltersChanged" class="mt-4 pt-2"/>
      <div class="col-lg-2 mt-3 pt-2">
        <button class="btn-sm btn-primary col-lg-12" @click="applyFilters">{{$t("common.show")}}</button>
      </div>
      <div class="col-lg-3 mt-3 pt-2">
        <button class="btn-sm btn-secondary col-lg-12" @click="resetFilters">{{$t("common.reset_filters")}}</button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import * as type from '../../../../store/mutation-types'
import DatePicker2 from 'vue2-datepicker';
import {buildDropdownOptions} from "~/helpers/common";
import AgroFilter from "~/components/AgroFilter/Index.vue";

export default {
  name: 'SMWarehouseFilters',
  props: ["limit"],
  data() {
    return {
      filters: this.gridFilters ? this.gridFilters : {},
    }
  },
  components: {
    AgroFilter,
    DatePicker2
  },
  computed: {
    ...mapGetters({
      gridFilters: 'smWarehouses/smWarehouseFilters',
      departments: 'company/departments',
      domain: 'auth/domain',
    }),
    department_list() {
      let vm = this;
      return buildDropdownOptions(this.departments, {
        value: 'uuid',
        text: 'name',
      }, {
        value: null,
        text: '-',
      });
    }
  },
  methods: {
    ...mapActions({
      getData: 'smWarehouses/getSmWarehouses',
      getDepartments: 'company/getDepartments',
    }),
    applyFiltersClick() {
      this.$set(this.filters, 'page', 1);
      this.$router.push({
          path: '/stock-management/warehouses',
          params: {
            page: 1,
          }
        }
      );
      this.applyFilters();
    },
    applyFilters() {
      this.$emit('onFiltersApplied', this.filters);
      this.filters.show_all = 1;
      this.getData(this.filters);
    },
    resetFilters() {
      this.filters = {
        name: '',
        department_uuid: null,
        page: 1,
        limit: this.limit,
      };

      this.$store.commit("smWarehouses/" + type.SET_SM_WAREHOUSE_FILTERS, this.filters);
      this.applyFilters();
    },
    onDynamicFiltersChanged(selectedUUID) {
      this.filters.department_uuid = selectedUUID;
    }
  },
  mounted() {
    this.resetFilters();
    this.getDepartments({
      company_uuid: this.domain.uuid,
    });
  },
  watch: {
    gridFilters: {
      handler: function (newVal) {
        this.filters = newVal;
      },
      deep: true,
    }
  }
}
</script>
