var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.canEdit || _vm.canDelete)?_c('div',{staticClass:"view"},[_c('card',{attrs:{"title":_vm.$t('works.cultures.name'),"title-bottom-padding":'10px',"id":"cultures"}},[_c('DxDataGrid',{attrs:{"show-borders":true,"data-source":_vm.dataSource,"remote-operations":_vm.gridRemoteOperations,"width":'100%',"height":600},scopedSlots:_vm._u([{key:"departmentSelectorTemplate",fn:function({ data }){return [_c('agro-filter',{attrs:{"selectedItems":[data.data.department],"singleSelect":true,"title":_vm.$t('department_selector')},on:{"onChange":(val) => _vm.onUnitSelectorChanged(val, data)}})]}},{key:"cultureDetailTemplate",fn:function({ data: culture }){return [_c('culture-details-template',{attrs:{"data":culture}})]}}],null,false,3697716338)},[_c('DxColumn',{attrs:{"dataField":"name","caption":_vm.$t('works.cultures.culture'),"width":'20%',"filter-operations":['contains']}},[_c('DxRequiredRule',{attrs:{"message":_vm.$t('validation.name_is_required')}})],1),_c('DxColumn',{attrs:{"dataField":"display_name","caption":_vm.$t('display_name'),"width":'20%',"filter-operations":['contains']}}),_c('DxColumn',{attrs:{"dataField":"department_name","caption":_vm.$t('department'),"width":'15%',"formItem":{
              visible: false
          },"filter-operations":['contains']}}),_c('DxColumn',{attrs:{"dataField":"department_uuid","edit-cell-template":"departmentSelectorTemplate","width":'15%',"visible":false,"formItem":{
              visible: true
          }}}),_c('DxColumn',{attrs:{"dataField":"created_at","caption":_vm.$t('common.created_at'),"dataType":"date","format":"yyyy-MM-dd","width":'20%',"alignment":"center","formItem":{
              visible: false
          },"filter-operations":['=']}}),_c('DxColumn',{attrs:{"dataField":"updated_at","caption":_vm.$t('common.updated_at'),"dataType":"date","format":"yyyy-MM-dd","width":'20%',"alignment":"center","formItem":{
              visible: false
          },"filter-operations":['=']}}),_c('DxMasterDetail',{attrs:{"enabled":true,"template":"cultureDetailTemplate"}}),_c('DxFilterRow',{attrs:{"visible":true}}),_c('DxScrolling',{attrs:{"mode":"infinite"}}),_c('DxEditing',{attrs:{"allow-adding":_vm.allowEditing,"allow-updating":_vm.allowEditing,"allow-deleting":_vm.allowDeleting,"mode":"form","use-icons":true}})],1),_c('div',{staticClass:"total-results mt-2"},[_vm._v(" "+_vm._s(_vm.$t('common.total_results', {count: _vm.total}))+" ")])],1)],1):_c('div',[_c('forbidden')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }