const state = () => {
    return {
      seasonOnParcelHasCulture: [],
      spcWorks:[],
      spcWorkSummary:[],
      spcMachines:[],
      spcParcels:[]
    }
  }
  
  export default state;
  