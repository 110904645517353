<template>
  <div class="selected-file-progress" v-if="file">
    <div class="info">
      <div class="mt-3 fn">{{ file ? file.name : '' }} <span class="fs" v-if="size">{{ size }}</span></div>
      <div class="percent">{{ progressValue +'%'}}</div>
    </div>
    <b-progress :value="progressValue" :max="100" class="mb-3 pb" :variant="variant"></b-progress>
    <div class="text-success msg" v-if="success">{{ success }}</div>
    <div class="text-danger msg" v-if="error">{{ error }}</div>
  </div>
</template>

<script>
import {formatFileSize} from "@/helpers/common";

export default {
  name: 'SelectedFileProgress',
  props: ['file', 'progressValue', 'error', 'success'],
  computed: {
    size() {
      if (this.file) {
        return formatFileSize(this.file.size);
      }

      return null;
    },
    variant() {
      if (this.success) {
        return 'success';
      } else if (this.error) {
        return 'danger';
      } else {
        return 'primary';
      }
    }
  },
}
</script>

<style scoped>
.fn {
  font-size: 13px;
  padding-bottom: 5px;
}

.fs {
  font-weight: bold;
}

.pb {
  height: 3px;
}

.msg {
  font-size: 13px;
}

.info {
  display: flex;
  flex-direction: row;

  .percent {
    display: flex;
    flex-grow: 1;
    justify-content: flex-end;
    align-items: end;
    font-size: 13px;
    padding-bottom: 5px;
  }
}
</style>
