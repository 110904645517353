import * as api from "../../api/index";
import * as endpoints from "../../api/endpoints";
import * as types from "../../api/../mutation-types";

const actions = {
  getStatuses({ commit }, payload) {
    return api.fetch(endpoints.STATUSES, payload, commit, (response) => {
      commit(types.SET_STATUS, response.data);
    });
  },
  getStatusCriteriaBuilderColumns({ commit }, payload) {
    return api.fetch(endpoints.STATUSES_CRITERIA_COLUMNS, payload, commit, (response) => {
      commit(types.SET_CRITERIAL_BUILDER_COLUMNS, response.data);
    });
  },
  updateStatus({ commit }, payload) {
    return api.put(
      endpoints.STATUSES + "/" + payload.uuid,
      payload,
      commit,
      (response) => {
        commit(types.UPDATE_STATUS, response.data);
      }
    );
  },
};

export default actions;
