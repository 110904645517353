import Vue from 'vue'
import * as VueGoogleMaps from 'gmap-vue'

Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyCUlCJ5zBWAb5Lc5SfVfAeikX7Rxiq7Afs",
    libraries: ["core", "places", "geometry", "drawing"] // necessary for places input
  },
  installComponents: true,
});
