<template>
  <div class="view" v-if="canEdit || canDelete" v-cloak>
    <card v-show="!reportGridViewVisibility"
        :title="$t('reporting.list.title')"
        :title-bottom-padding="'10px'"
        id="reports"
    >
      <DxDataGrid
          :show-borders="true"
          :element-attr="gridAttributes"
          :data-source="dataSource"
          :remote-operations="gridRemoteOperations"
          :width="'100%'"
          :height="600"
          ref="rptgrid"
      >
        <DxColumn
            dataField="name"
            data-type="string"
            :caption="$t('reporting.columns.name')"
            :width="'20%'"
            :filter-operations="['contains']"
        >
          <DxRequiredRule :min="1" :max="45" :message="$t('reporting.validation.name_is_required')"/>
        </DxColumn>

        <DxColumn
            dataField="code"
            :caption="$t('reporting.columns.code')"
            :width="'15%'"
            :filter-operations="['contains']"
            :visible="false"
        >
          <DxRequiredRule :min="1" :max="45" :message="$t('reporting.validation.code_is_required')"/>
        </DxColumn>

        <DxColumn
            data-field="group_id"
            dataType="number"
            alignment="left"
            :caption="$t('reporting.columns.report_group')"
            :width="'15%'"
            calculate-sort-value="group_name"
            :filter-operations="['=']">
          <DxLookup
              :data-source="reportGroups"
              value-expr="id"
              display-expr="name"
          />
          <DxRequiredRule :message="$t('reporting.validation.group_is_required')"/>
        </DxColumn>

        <DxColumn
            dataField="datasource_SP"
            :caption="$t('reporting.columns.datasource_sp')"
            :width="'10%'"
            :filter-operations="['contains']"
        />

        <DxColumn
            data-field="is_standalone"
            dataType="number"
            alignment="center"
            :caption="$t('reporting.columns.is_standalone')"
            :width="'10%'"
            :filter-operations="['=']">
          <DxLookup
              :data-source="standaloneList"
              value-expr="value"
              display-expr="text"
          />
          <DxRequiredRule :message="$t('reporting.validation.standalone_is_required')"/>
          <DxFormItem
              :col-span="2"
              :editor-options="isStandaloneColumnOptions"
          />
        </DxColumn>

        <DxColumn
            dataField="description"
            :caption="$t('reporting.columns.description')"
            :width="'20%'"
            :filter-operations="['contains']"
            :allowSorting="false"
        >
          <DxRequiredRule :min="1" :max="191" :message="$t('reporting.validation.description_is_required')"/>
          <DxFormItem
              :col-span="2"
              :editor-options="descriptionColumnOptions"
              editor-type="dxTextArea"
          />
        </DxColumn>

        

        <DxColumn
            dataField="layout_data"
            datatype="textarea"
            :caption="$t('reporting.columns.layout_data')"
            :width="'15%'"
            :allowFiltering="false"
            :allowSorting="false"
        >
        <DxFormItem
              :col-span="2"
              :editor-options="descriptionColumnOptions"
              editor-type="dxTextArea"
          />
        </DxColumn>

        

        

        <DxColumn
            dataField="created_at"
            :caption="$t('common.created_at')"
            dataType="date"
            format="yyyy-MM-dd"
            :width="'10%'"
            alignment="center"
            :formItem="{
                visible: false
            }"
            :filter-operations="['=']"
        />

        <DxColumn
            type="buttons"
            :width="160"
        >
        <DxButton
              hint="Designer"
              icon="preferences"
              :onClick="openReportDesigner"
              :visible="hasReportAdminRole"
          />
          <DxButton
              hint="Report"
              icon="doc"
              :onClick="openReportViewer"
          />
          <DxButton
              hint="Arata raport"
              icon="smalliconslayout"
              :onClick="showReportGridView"
          />
          <DxButton name="edit"/>
          <DxButton name="delete"/>
        </DxColumn>

        <DxMasterDetail
            :enabled="true"
            template="reportTabsTemplate"
        />
        <template #reportTabsTemplate="{ data: report }">
          <report-tabs-template :data="report"></report-tabs-template>
        </template>

        <DxFilterRow :visible="true"/>
        <DxScrolling mode="infinite"/>
        <DxEditing
            :allow-adding="allowEditing()"
            :allow-updating="allowEditing()"
            :allow-deleting="allowDeleting"
            mode="form"
            :use-icons="true"
        />
      </DxDataGrid>
      <div class="total-results mt-2">
        {{ $t('common.total_results', {count: total}) }}
      </div>
    </card>
    <card v-show="reportGridViewVisibility"
        :title="$t('reporting.list.title')"
        :title-bottom-padding="'20px'"
        id="reports"
    >
      <ReportGridView  :templateData="selectedReport" :showModule="reportGridViewVisibility" @hideReportGridView="hideReportGridView"></ReportGridView>
    </card>
    <ReportDesignerDialog v-if="reportDesignerVisibility" :templateData="selectedReport" :showPopup="reportDesignerVisibility" @hidden="closeReportDesigner"></ReportDesignerDialog>
        <DocumentViewerDialog v-if="reportViewerVisibility" :templateData="selectedReport" :showPopup="reportViewerVisibility" @hidden="closeReportViewer"></DocumentViewerDialog>
  </div>
  <div v-else>
    <forbidden></forbidden>
  </div>
</template>

<script>
import {
  DxDataGrid,
  DxColumn,
  DxEditing,
  DxFilterRow,
  DxScrolling,
  DxMasterDetail,
  DxRequiredRule,
  DxLookup,
  DxButton,
  DxFormItem
} from 'devextreme-vue/data-grid';
import { mapActions } from "vuex";
import Forbidden from "@/pages/errors/Forbidden.vue";
import CustomStore from "devextreme/data/custom_store";
import DataSource from "devextreme/data/data_source";
import ReportTabsTemplate from "@/pages/reporting/reports/partials/ReportTabsTemplate.vue";
import {canDelete, canEdit, permissions, permissionTypes, hasRole} from "@/helpers/Permissions";
import ReportDesignerDialog from './partials/designer.vue'
import DocumentViewerDialog from './partials/viewer.vue'
import ReportGridView from '../partials/reportGridView.vue'


export default {
  name: "ImportValidator",
  components: {
    ReportTabsTemplate,
    Forbidden,
    DxDataGrid,
    DxColumn,
    DxEditing,
    DxFilterRow,
    DxScrolling,
    DxMasterDetail,
    DxRequiredRule,
    DxLookup,
    DxButton,
    DxFormItem,
    ReportDesignerDialog, DocumentViewerDialog,
        ReportGridView
  },
  data() {
    return {
      gridRemoteOperations: {
        filtering : true,
        sorting: true
      },
      reportTabSelectedIndex: 0,
      reportGroups: [],
      reportDesignerVisibility: false,
      gridAttributes:{
        class: 'grid'
      },
      selectedReport: {},
      reportGridViewVisibility: false,
      reportViewerVisibility: false,
      isStandaloneColumnOptions: { width: 'calc(50% - 69px)'},
      descriptionColumnOptions: {height: 100}
    };
  },
  computed: {
    dataSource() {
      return new DataSource({
        store: new CustomStore({
          key: 'id',
          load: this.load,
          insert: this.create,
          update: this.update,
          remove: this.remove
        })
      });
    },
    standaloneList() {
      return [
        {value: 1, text: this.$t('common.yes')},
        {value: 0, text: this.$t('common.no')}
      ];
    },
    total() {
      return this.$store.getters['common/pagination']?.total;
    },
    config() {
      return {
        permission: permissions.REPORTS,
        permission_type: permissionTypes.module,
      }
    },
    canEdit() {
      return canEdit(this);
    },
    canDelete() {
      return canDelete(this);
    },
  },
  methods: {
    ...mapActions({
      getReportGroups: 'reporting/getReportGroups',
      get: 'reporting/getReports',
      create: 'reporting/createReport',
      change: 'reporting/updateReport',
      delete: 'reporting/deleteReport',
    }),
    async load(data) {
      let dataSource =  {
        data:[],
        totalCount:0
      };

      await this.get({
        filter: data.filter,
        sort: data.sort,
      }).then((resp) => {
        dataSource.data = resp.data;
        dataSource.totalCount = resp.pagination.total;
      });

      return dataSource;
    },
    async update(key, obj) {
      const updatable = Object.assign(
          {},
          this.dataSource.items().find(item => item.id === key),
          obj
      );

      await this.change(
          Object.assign({}, updatable, {
            id: key,
          })
      )
    },
    async remove(id) {
      if (this.dataSource.items().find(item => item.id === id).is_deletable === 0) return;

      await this.delete({
        id: id,
      });
    },
    allowEditing(e) {
      return this.canEdit;
    },
    allowDeleting(e) {
      return this.canDelete && e.row.data.is_deletable === 1;
    },
    openReportDesigner(e){
      this.selectedReport = e.row.data;
      this.reportDesignerVisibility = true;
    },
    closeReportDesigner(){
      this.reportDesignerVisibility = false;
    },  
    showReportGridView(e){
        this.selectedReport = e.row.data;
        this.reportGridViewVisibility = true;
    },
    hideReportGridView(e){
        this.reportGridViewVisibility = false;
    },
    hasReportAdminRole() {
          return hasRole(this, permissions.REPORT_ADMIN);
    },
    openReportViewer(e){
        this.selectedReport = e.row.data;
        this.reportViewerVisibility = true;
    },
    closeReportViewer(){
        this.reportViewerVisibility = false;
    },
  },
  async mounted() {
    const vm = this;
    this.getReportGroups({
      limit: 999,
    }).then((resp) => {
      vm.reportGroups = resp.data;
    });
  }
};
</script>
