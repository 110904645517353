
import * as api from './api/index'
import * as endpoints from './api/endpoints'

// actions
export const actions = {
    getBatches({ commit }, payload) {
        return api.fetch(endpoints.BATCH, payload, commit);
    },

    getUsedProducts({ commit }, payload) {
        return api.fetch(endpoints.BATCH_USED_PRODUCTS, payload, commit);
    },

    getBatchHeaderSummary({ commit }, payload) {
        return api.fetch(endpoints.BATCH_HEADER_SUMMARY, payload, commit);
    },

    getBatchMeasureUnits({ commit }, payload) {
        return api.fetch(endpoints.BATCH_MEASURE_UNITS, payload, commit);
    },

    createBatch({ commit }, payload) {
        return api.post(endpoints.CREATE_BATCH, payload, commit);
    },
}