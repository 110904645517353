<template>
  <component :is="comp"></component>
</template>

<script>
import {defineAsyncComponent} from "vue";

export default {
  name: "DynamicComponent",
  props: {
    componentName:{
      type: String,
      required: true
    }
  },
  computed: {
    comp () {
      return defineAsyncComponent(() => import(`../layouts/${this.componentName}.vue`))
    }
  }
}
</script>
