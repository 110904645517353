<template>
  <div class="scouting-div">
    <div v-for="(image, index) in images" :key="index">
      <div class="scouting-img">

        <div @click="populateModal(image.image_url)">
          <img :src="image.image_url" alt="Scouting Image">
        </div>
      </div>
    </div>

    <b-modal ref="image-modal" hide-footer>
      <div>
        <a target="_blank" :href="this.imageSrc">
        <img width="100%" height="100%" id="image-modal-img" :src="this.imageSrc">
        </a>
      </div>
    </b-modal>

  </div>
</template>

<script>

export default {
    name: 'ScoutingImage',
    props: ['data'],
    data() {
        return {
            scoutingData: this.data,
            images: [],
            imageSrc: '',
        }
    },
    components: {

    },
    computed: {
        title() {
          return this.scoutingData.uuid;
        }
    },
    methods: {
        populateImages() {
          this.scoutingData.images.forEach((item) => {
            this.images.push(item);
          });
        },
        populateModal(src) {
          this.imageSrc = src;
          this.$refs["image-modal"].show();
        }
    },
    mounted () {
      this.populateImages();
    }
}
</script>

<style scoped>
  .scouting-div {
    min-width: 168px;
  }
  .scouting-img {
    float: left;
    width: 52px;
    height: 50px;
    border: 1px solid lightgray;
    border-radius: 3px;
    margin: 2px;
    background-color: #becad7;
  }
  .scouting-img img{
    width: 100%;
    height: 100%;
  }

  .scouting-img i {
    padding-top: 32%
  }
</style>
