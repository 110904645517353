<template>
  <card :title="title" id="details" v-if="canEdit || canDelete">
    <span class="action_buttons">
      <img src="~@/assets/images/desktop/icons/controls/plus.svg" class="icon-svg" :title="$t('common.create_new', { name: name })" @click="newObject()"/>
    </span>
    <div class="row">
      <div class="form-group col-sm-12 col-lg-8 text-left">
        <label class="control-label">{{ name+' '+$t('common.name') }}</label>
        <input type="text" v-model="data.name" :placeholder='$t("common.name")'
               :class="{ 'field-error' : this.$v.data.name.$error }"
               class="form-control"/>
      </div>
    </div>
    <div class="row">
      <div class="form-group col-sm-4 col-lg-8">
        <div class="form-group control-label">{{$t("salary_calculator.holiday.date")}}</div>
        <date-picker2
          v-model="holiday_date"
          :value-type="holiday_date_input"
          format="YYYY-MM-DD"
          type="date"
          placeholder="yyyy-mm-dd"
          :class="{ 'field-error' : this.$v.data.date.$error }"
          :input-class="['date-picker-style']"
          @change="onHolidayDateChanged"
          ref="work_date"
        ></date-picker2>
      </div>
    </div>
    <agro-action-buttons
      :data="data"
      :can-edit="canEdit"
      :can-delete="canDelete"
      @onSave="save"
      @onSaveAndNew="saveAndNew"
      :save-and-new="true"
      @onDelete="confirmDelete"
      @onGotoList="gotoList"
    ></agro-action-buttons>
  </card>
  <div v-else>
    <forbidden></forbidden>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import {only_price} from '@/helpers/common'
import required from "vuelidate/src/validators/required";
import {canDelete, canEdit, permissions, permissionTypes} from "@/helpers/Permissions";
import * as actions from '@/helpers/generalActions'
import DatePicker2 from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import Forbidden from "@/pages/errors/Forbidden";
import AgroActionButtons from "@/components/AgroActionButtons/index";

export default {
  name: 'SCHolidayDetails',
  data() {
    return {
      data: {},
      backPath: undefined,
      holiday_date: null,
      holiday_date_input: null,
    }
  },
  components: {
    AgroActionButtons,
    Forbidden,
    DatePicker2,
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
      users: 'user/users',
      holidays: 'scHoliday/scHolidays',
    }),
    name() {
      return this.$t('salary_calculator.holiday.title')
    },
    config() {
      return {
        permission: permissions.SC_HOLIDAY,
        permission_type: permissionTypes.module,
        get: this.get,
        create: this.create,
        update: this.update,
        delete: this.delete,
        list_url: '/salary-calculator/sc-holiday',
        confirm_delete: true,
      }
    },
    actions() {
      return actions;
    },
    canEdit() {
      return canEdit(this);
    },
    canDelete() {
      return canDelete(this);
    },
    title() {
      return (this.data.uuid ? this.$t('common.edit') : this.$t('common.new'))+' '+this.name;
    },
  },
  methods: {
    ...mapActions({
      get: 'scHoliday/getSCHoliday',
      create: 'scHoliday/createSCHoliday',
      update: 'scHoliday/updateSCHoliday',
      delete: 'scHoliday/deleteSCHoliday'
    }),
    onlyPrice(event) {
      return only_price(event);
    },
    loadData() {
      let vm = this;
      return this.actions.getData(this, () => {},(data) => {
        vm.$set(vm, 'holiday_date', data.date);
      });
    },
    gotoList() {
      return actions.gotoList(this);
    },
    confirmDelete() {
      return this.actions.confirmDelete(this);
    },
    save() {
      return this.actions.save(this, this.config.list_url);
    },
    saveAndNew() {
      return this.actions.saveAndNew(this, 'add');
    },
    newObject() {
      return this.actions.newObject(this);
    },
    onHolidayDateChanged(date) {
      this.$set(this.data, 'date', date);
    },
  },
  mounted() {
    this.loadData();
    this.actions.handleParams(this);
  },
  watch: {
    $route: {
      handler: function(newVal) {
        this.actions.handleParams(this);
      },
      deep: true,
    },
    permission: {
      handler: function(newVal) {
        this.permissionModule = newVal;
      },
      deep: true,
    }
  },
  validations(){
    let vm = this;
    return {
      data: {
        name: {
          required,
        },
        date: {
          required,
        }
      }
    }
  }
}
</script>
