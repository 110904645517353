<template>
  <div class="statuses">
    <card :title="$t('tools.statuses.title')" id="statuses" v-if="canEdit">
      <div class="row d-flex justify-content-end">
        <button class="btn btn-md btn-secondary"style="width: 250px;" @click="goBackToTypeList">{{ $t('back_to_list') }}</button>
      </div>
      <div class="row table-responsive mt-3">
        <div class="col-lg-12">
          <table class="table">
            <th class="text-left">{{ $t('name') }}</th>
            <th class="text-left">{{ $t('status') }}</th>
            <th class="text-left">{{ $t('tools.statuses.type') }}</th>
            <th class="text-left">{{ $t('common.updated_at') }}</th>
            <th></th>
            <tbody>
              <tr v-for="status in statuses" :key="Math.random()"
                @mouseover="hoveredRow = status.uuid" @mouseleave="hoveredRow = null">
                <td class="td-text text-left" :title="status.name" :class="{ 'selection-row': isHovered(status.uuid) }">
                  {{ status.name }}
                </td>
                <td class="td-text text-left" :title="status.status" :class="{ 'selection-row': isHovered(status.uuid) }">
                    <span :style="{ backgroundColor: status.color, width: '15px', height: '15px', display: 'inline-block', borderRadius: '50%', border: '1px solid gray' }"></span>
                    {{ status.status }}
                </td>
                <td class="td-text text-left" :title="status.type" :class="{ 'selection-row': isHovered(status.uuid) }">
                  {{ status.type }}
                </td>
                <td class="td-text text-left" :title="status.type" :class="{ 'selection-row': isHovered(status.uuid) }">
                  {{ status.updated_at }}
                </td>
                <td class="td-text text-center" :class="{ 'selection-row': isHovered(status.uuid) }" >
                  <img v-if="status.status != 'UNKNOWN'" :src="require('@/assets/images/desktop/icons/controls/edit.svg')"
                    :class="['edit', 'primary-filter']" :title="$t('tools.statuses.edit_status')" class="action-icon"
                    @click="criteriaBuilderModal(status)" />
                </td>
              </tr>
            </tbody>
          </table>
          <CriteriaBuilderDialog 
            :show="showCriteriaBuilderModal" 
            @close="showCriteriaBuilderModal = false"
            :columns="criteriaBuilderValues"
            :status="selectedStatusForEdit"
            @onOK="handleCriteriaBuilderOK">
          </CriteriaBuilderDialog>
        </div>
      </div>
    </card>
    <div v-else>
      <forbidden></forbidden>
    </div>
  </div>
</template>

<script>
import { canEdit, permissions, permissionTypes } from "@/helpers/Permissions";
import Forbidden from "@/pages/errors/Forbidden";
import CriteriaBuilderDialog from './CriteriaBuilderDialog.vue';
import { mapActions, mapGetters } from "vuex";

export default {
  name: 'Statuses',
  data() {
    return {
      hoveredRow: null,
      showCriteriaBuilderModal: false,
      status_type : null,
      criteriaBuilderValues: [],
      selectedStatusForEdit: {},
      test: "(surface > work_process * (5 / 100)) AND (work_process != '?') AND (culture = '23') OR (ignition_time > 5)"
    }
  },
  components: {
    Forbidden,
    CriteriaBuilderDialog,
  },
  computed: {
    ...mapGetters({
      criteriaBuilderColumns: "statuses/criteriaBuilderColumns",
      statuses: "statuses/statuses",
    }),
    config() {
      return {
        permission: permissions.CONFIGURATOR,
        permission_type: permissionTypes.module,
      };
    },
    canEdit() {
      return canEdit(this);
    },
  },
  methods: {
    ...mapActions({
      getStatuses: "statuses/getStatuses",
      getStatusCriteriaBuilderColumns: "statuses/getStatusCriteriaBuilderColumns",
    }),
    isHovered(uuid) {
      return this.hoveredRow === uuid;
    },
    criteriaBuilderModal(status) {
      this.selectedStatusForEdit = status;
      this.showCriteriaBuilderModal = true;
    },
    handleCriteriaBuilderOK() {
      this.showCriteriaBuilderModal = false
    },
    goBackToTypeList() {
      this.$router.back();
    },
    loadStatuses() {
      this.getStatuses({ type: this.status_type });
    },
    loadStatusesColumns() {
      this.getStatusCriteriaBuilderColumns({ type: this.status_type });
    }
  },
  mounted() {
    if (this.$route.query.type) {
      this.status_type = this.$route.query.type;
      this.loadStatuses();
      this.loadStatusesColumns();
    }
  },
  watch: {
    criteriaBuilderColumns: {
      handler: function (val) {
        this.criteriaBuilderValues = val.map(column => ({
          ...column,
          label: this.$t('tools.import_validator.' + column.value.replace(/_minute$/, '')) ?? column.value
        }));
      },
      deep: true
    }
  }
}
</script>

<style>
#statuses .title {
  padding-bottom: 0px !important;
}

.action-icon {
  width: 22px;
  height: 22px;
  cursor: pointer;
}

.selection-row {
  background-color: #e6e6e3;
}
</style>