<template>
  <b-dropdown id="dropdown-right" :text="$t('common.transactions')" class="m-md-2 pt-2">
    <b-dropdown-item to="/stock-management/sm-stocks/reception">{{ $t('stock_management.reception.title') }}</b-dropdown-item>
    <b-dropdown-item to="/stock-management/sm-stocks/sales">{{ $t('stock_management.sales.title') }}</b-dropdown-item>
    <b-dropdown-item to="/stock-management/sm-stocks/transfer">{{ $t('stock_management.transfer.transfer_between') }}</b-dropdown-item>
    <b-dropdown-item to="/stock-management/sm-stocks/consum" active>{{ $t('stock_management.consum.title') }}</b-dropdown-item>
  </b-dropdown>
</template>

<script>
export default {
  name: 'StockActions',
}
</script>

<style scoped>
.dropdown-right {
  display: flex;
  flex-direction: row-reverse;
  margin-top: 20px;
}
</style>
