import * as types from "../mutation-types";

const mutations = {
  [types.SET_FITO_PRODUCTS]: (state, payload) => {
    state.fitoProducts = payload;
  },
  [types.SET_FITO_PRODUCTS_LIGHT]: (state, payload) => {
    state.fitoProductsLight = payload;
  }
  
}

export default mutations;
