import * as types from "../mutation-types";

const mutations = {
  [types.SET_COMPANIES]: (state, payload) => {
    state.companies = payload;
  },
  [types.SET_COMPANY_FILTERS]: (state, payload) => {
    state.company_filters = payload;
  },
  [types.SET_COMPANY_ITEM]: (state, payload) => {
    state.companies.splice(state.companies.indexOf(
      state.companies.find(c => c.uuid === payload.uuid)
    ), 1, payload);
  },
  [types.SET_COMPANY]: (state, payload) => {
    state.company = payload;
  },
  [types.SET_PARENT_COMPANIES]: (state, payload) => {
    state.parent_companies = payload;
  },
  [types.SET_UNITS]: (state, payload) => {
    state.units = payload;
  },
  [types.SET_DEPARTMENTS]: (state, payload) => {
    state.departments = payload;
  },
  [types.SET_DEPARTMENT_FILTERS]: (state, payload) => {
    state.department_filters = payload;
  },
  [types.ADD_UNIT_ITEM]: (state, payload) => {
    if(payload.parent) {
      if(!payload.parent.child_units) {
        payload.parent.child_units = [];
      }
      payload.parent.child_units.push(payload.item);
    } else {
      state.units.push(payload.item);
    }
  },
  [types.UPDATE_UNIT_ITEM]: (state, payload) => {
    state.units.splice(
      state.units.indexOf(
        state.units.find(it => it.uuid === payload.item.uuid)
      ),1, payload.item
    );
  },
  [types.DELETE_UNIT_ITEM]: (state, payload) => {
    state.units.splice(
      state.units.indexOf(
        state.units.find(it => it.uuid === payload.uuid)
      ),1
    );
  },
  //files
  [types.SET_RECENTLY_UPLOADED_FILES]: (state, payload) => {
    state.recently_uploaded_files = payload;
  },
  //zip files
  [types.SET_RECENTLY_UPLOADED_ZIP_FILES]: (state, payload) => {
    state.recently_uploaded_zip_files = payload;
  },
}

export default mutations;
