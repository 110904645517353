<template>
  <div class="agro-editor-view">
    <card :title="title" id="details" v-if="canEdit || canDelete">
    <span class="action_buttons">
      <img src="~@/assets/images/desktop/icons/controls/plus.svg" class="icon-svg" :title="$t('common.create_new', { name: name })" @click="newObject()"/>
    </span>
      <div class="row">
        <div class="form-group col-sm-12 col-lg-8 text-left">
          <label class="control-label">{{ name+' '+$t('common.name') }}</label>
          <input type="text" v-model="data.name" :placeholder='$t("common.name")'
                 :class="{ 'field-error' : this.$v.data.name.$error }"
                 class="form-control"/>
        </div>
      </div>
      <agro-action-buttons
          :data="data"
          :can-edit="canEdit"
          :can-delete="canDelete"
          @onSave="save"
          @onSaveAndNew="saveAndNew"
          :save-and-new="config.create_and_new"
          @onDelete="confirmDelete"
          @onGotoList="gotoList"
      ></agro-action-buttons>
    </card>
    <div v-else>
      <forbidden></forbidden>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import required from "vuelidate/src/validators/required";
import {canDelete, canEdit} from "@/helpers/Permissions";
import * as actions from '@/helpers/generalActions'
import AgroActionButtons from "@/components/AgroActionButtons/index";
import Forbidden from "@/pages/errors/Forbidden";

export default {
  name: 'AgroEditor',
  props: ['name', 'config'],
  data() {
    return {
      data: {},
    }
  },
  components: {
    AgroActionButtons,
    Forbidden
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
    }),
    actions() {
      return actions;
    },
    canEdit() {
      return canEdit(this);
    },
    canDelete() {
      return canDelete(this);
    },
    title() {
      return (this.data.uuid ? this.$t('common.edit') : this.$t('common.new'))+' '+this.name;
    },
  },
  methods: {
    loadData() {
      return this.actions.getData(this);
    },
    gotoList() {
      return actions.gotoList(this);
    },
    confirmDelete() {
      return this.actions.confirmDelete(this);
    },
    save() {
      return this.actions.save(this, this.config.list_url);
    },
    saveAndNew() {
      return this.actions.saveAndNew(this, 'add');
    },
    newObject() {
      return this.actions.newObject(this);
    },
  },
  mounted() {
    this.actions.handleParams(this);
    this.loadData();
  },
  watch: {
    $route: {
      handler: function(newVal) {
        this.actions.handleParams(this);
      },
      deep: true,
    },
    permission: {
        handler: function(newVal) {
            this.permissionModule = newVal;
        },
        deep: true,
    }
  },
  validations(){
    return {
      data: {
        name: {
          required,
        },
      }
    }
  }
}
</script>
