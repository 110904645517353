<template>
  <tr>
    <td width="35%">{{ role.name }}</td>
    <td width="55%" class="text-success c-pointer" @click="showEditDialog">
      {{ role.permissions.length }} {{ $t('permissions')}}
    </td>
    <td width="10%">
      <a v-on:click.stop.prevent="showEditDialog()" title="Edit" class="c-pointer" v-if="canEdit">
        <img src="~@/assets/images/desktop/icons/controls/edit.svg" class="icon-svg success-filter sicon"/>
      </a>
      <a v-on:click.stop.prevent="removeItem()" title="Remove" class="c-pointer" v-if="canDelete">
        <img src="~@/assets/images/desktop/icons/controls/delete.svg" class="icon-svg danger-filter sicon"/>
      </a>
    </td>

  </tr>
</template>

<script>
import {hasPermission, permissions, actions, hasModuleAccess} from "../../../helpers/Permissions";
    import { mapGetters } from 'vuex'

  export default {
      name: 'Role',
      props: ['data', 'permissions'],
      middleware: ['auth', 'check-company-admin'],
      data() {
          return {
              role: this.data ? this.data : [],
          }
      },
      computed: {
          ...mapGetters({
             user: 'auth/user',
          }),
          canEdit() {
              return hasModuleAccess(this.user, permissions.ROLES_PERMISSIONS);
          },
          canDelete() {
              return hasModuleAccess(this.user, permissions.ROLES_PERMISSIONS);
          }
      },
      methods: {
          showEditDialog() {
              this.$emit('onEdit', this.role);
          },
          removeItem() {
              this.$emit('onRemoveItem', this.role);
          }
      },
      watch: {
          data: {
              handler: function(newVal) {
                  this.role = newVal;
              },
              deep: true,
          }
      }
  }
</script>
