export const SALARY_CALCULATOR = {
  CODE: 'SALARY_CALCULATOR',
  MULTI_DAY_FUNCTION: 'MULTI_DAY_FUNCTION',
  WORK_TIME_EVIDENCE_CLOSING: 'WORK_TIME_EVIDENCE_CLOSING',
  AUTOMATIC_WORK_TIME_FULFILMENT: 'AUTOMATIC_WORK_TIME_FULFILMENT',
  DYNAMIC_START_END_TIME: 'DYNAMIC_START_END_TIME',
  DYNAMIC_SHIFT_TIME_RELATION: 'DYNAMIC_SHIFT_TIME_RELATION',
}

export const settings = {
  SALARY_CALCULATOR: SALARY_CALCULATOR,
}

/* Calculation formula */
export const CC_DEFAULT_ARITM = [
  { value: '+', text: '+' },
  { value: '-', text: '-' },
  { value: '*', text: '*' },
  { value: '/', text: '/' },
];

export const CALCULATION_FORMULA = {
  DEFAULT_ARITM: CC_DEFAULT_ARITM
};
