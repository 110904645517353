<template>
    <div class="col-lg-12">
                    <DxDataGrid 
                        :allow-column-reordering="true"
                        :ref="reportGridRefKey"
                        :data-source="report_dataset"
                        :show-borders="false"
                        :element-attr="gridAttributes"
                        :columns="gridColumns"
                        @exporting="onExporting"
                        :column-auto-width="true"
                    >
                        
                        <DxPaging :total="report_dataset.length" :page-size="15"/>
                        <DxPager
                            :visible="true"
                            :allowed-page-sizes="pageSizes"
                            display-mode="compact"
                            :show-page-size-selector="true"
                            :show-info="true"
                            :show-navigation-buttons="true"
                        />
                        <DxEditing
                            :allow-adding="false"
                            :allow-updating="false"
                            :allow-deleting="false"
                            :use-icons="true"
                            mode="row"
                        >
                            <DxTexts
                                confirm-delete-message="Sigur vreți să ștergeți raportul?",
                                confirm-delete-title="Confirmare ștergere"
                            />
                        </DxEditing>
                        <DxHeaderFilter :visible="true" />
                        <DxFilterRow :visible="true" apply-filter="auto"/>
                        <DxSelection mode="multiple" />
                        <DxColumnChooser :enabled="true"></DxColumnChooser>
                        <DxExport
                                :enabled="true"
                                :formats="['xlsx','pdf']"
                                :allow-export-selected-data="true"
                            />
                        <DxToolbar>
                            <DxItem
                                location="before"
                                template="backTemplate"
                            />
                            <DxItem
                                location="before"
                                name="groupPanel"
                            />
                            <DxItem
                                location="after"
                                template="refreshTemplate"
                            />
                            <DxItem
                                location="after"
                                name="exportButton"
                            />
                            <DxItem
                                location="after"
                                name="columnChooserButton"
                            />
                            
                        </DxToolbar>

                        <template #refreshTemplate>
                        <DxButton
                            icon="refresh"
                            @click="refreshDataGrid"
                        />
                        
                        </template>
                        <template #backTemplate>
                            <DxButton
                                icon="arrowleft"
                                @click="backToList"
                            />
                        </template>
                        <DxGroupPanel :visible="true"/>
                        <DxGrouping :auto-expand-all="true"/>
                        <DxScrolling column-rendering-mode="virtual"/>
                        <DxSummary>
                            <DxTotalItem v-for="item in aggregatedColumns.filter(g => g.show_aggregate_in_footer)"
                                :column="item.column"
                                :summary-type="item.summary_type"
                                :value-format="item.value_format"
                            ></DxTotalItem>
                            <DxGroupItem v-for="item in aggregatedColumns.filter(g => g.show_aggregate_in_group)"
                                :column="item.column"
                                :summary-type="item.summary_type"
                                :value-format="item.value_format"
                                :show-in-group-footer="true"
                            ></DxGroupItem>
                        </DxSummary>
                    </DxDataGrid>
                </div>
</template>

<script>

import { mapGetters, mapActions } from 'vuex';
import { DxDataGrid, DxColumn, DxPaging, DxPager, DxEditing, DxTexts, DxLookup, DxRequiredRule, 
        DxStringLengthRule, DxHeaderFilter, DxFilterRow, DxSelection, DxColumnChooser, DxExport, 
        DxToolbar, DxItem, DxGrouping, DxScrolling,
        DxGroupPanel, DxSummary, DxTotalItem, DxGroupItem,
        DxSummaryTexts} from 'devextreme-vue/data-grid';
import { DxButton } from 'devextreme-vue/button';
import { jsPDF } from 'jspdf';
import { exportDataGrid as exportDataGridToPdf} from 'devextreme/pdf_exporter';
import { exportDataGrid } from 'devextreme/excel_exporter';
import 'jspdf-autotable';
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver-es';

export default {
    name: 'ReportGridView',
    props: {
        showModule: {
            type: Boolean,
            default: false,
        },
        templateData: {
            type: Object,
            default: {},
        }
    },
    data(){
        return {
            reportGridRefKey: 'report-grid',
            gridAttributes:{
                class: 'admin-grid'
            },
            selectedReport: {},
            columnEditorOptions: { showClearButton: true, placeholder: "Denumire raport" },
            pageSizes: [15, 25, 100],
            report_dataset: [],
            gridColumns: [
                {
                    dataField: 'department_name',
                    caption: 'Departament',
                    width: '150px'
                },
                {
                    dataField: 'parcel_name',
                    caption: 'Parcela',
                    
                },
                {
                    dataField: 'product_name',
                    caption: 'Product',
                    
                }
            ],
            aggregatedColumns: []
        }
    },
    watch: {
        showModule: {
            handler: function (newVal) {
                
                if(newVal){
                    this.loadReportData();
                }
            },
            deep: true,
        },
    },
    components: {
        DxDataGrid, DxColumn, DxPaging, DxPager, DxEditing, DxTexts, DxLookup, DxRequiredRule, 
        DxStringLengthRule, DxHeaderFilter, DxFilterRow, DxSelection, DxColumnChooser, DxExport, 
        DxButton, DxToolbar, DxItem, DxGrouping, DxScrolling, DxGroupPanel, DxSummary, DxTotalItem,
        DxGroupItem
    },
    computed:{
        reportGrid: function() {
            return this.$refs[this.reportGridRefKey].instance;
        },
    },
    methods:{
        ...mapActions({
            getReports: "reporting/getReports",
            getReportsData: "reporting/getReportsData",
            getReportColumns: "reporting/getReportColumns",
        }),
        getCurrentDate() {
            const date = new Date();
            const year = date.getFullYear();
            const month = ('0' + (date.getMonth() + 1)).slice(-2); // Hónap 0-tól indul, ezért +1
            const day = ('0' + date.getDate()).slice(-2);
            const hours = ('0' + date.getHours()).slice(-2);
            const minutes = ('0' + date.getMinutes()).slice(-2);
            const seconds = ('0' + date.getSeconds()).slice(-2);   

            return `${year}_${month}_${day}_${hours}_${minutes}_${seconds}`;   
        },

        onExporting(e){
            if (e.format === 'xlsx') {
                const workbook = new Workbook();
                const worksheet = workbook.addWorksheet('Report');
                exportDataGrid({
                    component: e.component,
                    worksheet,
                    autoFilterEnabled: true,
                }).then(() => {
                    workbook.xlsx.writeBuffer().then((buffer) => {
                    saveAs(new Blob([buffer], { type: 'application/octet-stream' }), this.templateData.code + '_' + this.getCurrentDate() + '.xlsx');
                    });
                });
            } 
            else if (e.format === 'pdf') {
                const doc = new jsPDF();
                exportDataGridToPdf({
                    jsPDFDocument: doc,
                    component: e.component
                }).then(() => {
                    doc.save(this.templateData.code + '_' + this.getCurrentDate() + '.pdf');
                })
            }
        },
        loadReportData(){
            this.getReportColumns({report_id: this.templateData.id}).then(response => {
                this.gridColumns = [];
                this.aggregatedColumns = [];
                response.data.data.sort((a, b) => a.order - b.order).forEach(column => {
                   let colSetting = {
                       dataField: column.field_name,
                       dataType: column.data_type,
                       caption: column.title,
                       width: column.width,
                       visible: column.visible == 1,
                       format: column.format_type
                   } 
                   this.gridColumns.push(colSetting);
                   if(column.aggregate_type && column.aggregate_type != 'none'){
                       this.aggregatedColumns.push({
                           column: column.field_name,
                           summary_type: column.aggregate_type,
                           value_format: column.format_type,
                           show_aggregate_in_footer: column.show_aggregate_in_footer,
                           show_aggregate_in_group: column.show_aggregate_in_group
                       });
                   }
                });
            });
            this.getReportsData({name: this.templateData.datasource_SP, args: []}).then(response => {
                this.report_dataset =  response;
            });
        },
        refreshDataGrid(){
            this.loadReportData();
            this.reportGrid.refresh();
        },
        backToList(){
            this.$emit('hideReportGridView');
        }

    },
    mounted(){
        
    }
    
}

</script>