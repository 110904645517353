import * as types from "../../mutation-types";

const mutations = {
    [types.SET_INPUTS]: (state, payload) => {
        state.inputs = payload;
    },
    [types.SET_INPUT]: (state, payload) => {
        state.input = payload;
    },
    [types.SET_INPUT_FILTERS]: (state, payload) => {
      state.input_filters = payload;
    },
}

export default mutations;
