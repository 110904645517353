import * as types from "../../mutation-types";

const mutations = {
    [types.SET_DOCUMENT_BLOCKS]: (state, payload) => {
        state.document_blocks = payload;
    },
    [types.SET_DOCUMENT_BLOCK]: (state, payload) => {
        state.document_block = payload;
    },
    [types.SET_DOCUMENT_TYPES]: (state, payload) => {
      state.document_types = payload;
    },
    [types.ADD_DOCUMENT_BLOCK_ITEM]: (state, payload) => {
        state.document_blocks.push(payload);
    },
    [types.UPDATE_DOCUMENT_BLOCK_ITEM]: (state, payload) => {
        state.document_blocks.splice(state.document_blocks.indexOf(
            state.document_blocks.find(c => c.uuid === payload.uuid)
        ), 1, payload);
    },
    [types.DELETE_DOCUMENT_BLOCK_ITEM]: (state, payload) => {
        state.document_blocks.splice(state.document_blocks.indexOf(
            state.document_blocks.find(c => c.uuid === payload.uuid)
        ), 1);
    },
    [types.SET_DOCUMENT_BLOCK_FILTERS]: (state, payload) => {
        state.document_block_filters = payload;
    },
}

export default mutations;
