import * as types from "../../mutation-types";

const mutations = {
  [types.SET_SC_EMPLOYEES]: (state, payload) => {
    state.sc_employees = payload;
  },
  [types.SET_SC_EMPLOYEE]: (state, payload) => {
    state.sc_employee = payload;
  },
  [types.ADD_SC_EMPLOYEE_ITEM]: (state, payload) => {
    state.sc_employees.push(payload);
  },
  [types.UPDATE_SC_EMPLOYEE_ITEM]: (state, payload) => {
    state.sc_employees.splice(state.sc_employees.indexOf(
      state.sc_employees.find(c => c.uuid === payload.uuid)
    ), 1, payload);
  },
  [types.DELETE_SC_EMPLOYEE_ITEM]: (state, payload) => {
    state.sc_employees.splice(state.sc_employees.indexOf(
      state.sc_employees.find(c => c.uuid === payload.uuid)
    ), 1);
  },
  [types.SET_SC_EMPLOYEE_FILTERS]: (state, payload) => {
    state.sc_employee_filters = payload;
  },
}

export default mutations;
