<template>
  <div class="parcel_container" :class="{ bottom_radius_parcel: last_machine }">
    <div class="top_parcel_border"></div>
    <div
      v-for="(parcel, index) in parcelList"
      :key="index"
      class="parcel_row"
      :class="[
        { parcel_selected: parcel.selected },
        { bottom_radius: last_machine && parcelList.length == index + 1 },
      ]"
    >
      <div>
        <b-form-checkbox
          class="select_row_checkbox"
          size="sm"
          v-if="start_mode == true"
          v-model="parcel.selected"
          @change="parcel_selected_event($event, index, parcel)"
        >
        </b-form-checkbox>
      </div>
      <div :class="(checkIfHasLayer(parcel.uuid) && usage_mode != 'LIGHT') ? 'parcel_name_link' : 'parcel_name'" @click="showMapForParcel(parcel.uuid, parcel.season_uuid)">
        {{ parcel.name }}
      </div>
      <div v-if="false" class="surface_info">
        {{ parcel.surface_worked }} ha din {{ parcel.total_surface }}
      </div>
      <div v-if="false" class="percent_info">
        {{ ((parcel.surface_worked / parcel.total_surface) * 100).toFixed(2) }}%
      </div>
      <div class="percent_info">{{ parcel.surface_worked.toFixed(2) }} ha</div>
    </div>
  </div>
</template>


<script>
import { mapActions, mapGetters } from "vuex";
import { DxDataGrid, DxColumn, DxSelection } from "devextreme-vue/data-grid";

export default {
  name: "WorksOnParcels",
  props: {
    templateData: {
      type: Object,
      default: () => {},
    },
    testprop: {
      type: Object,
      default: () => {},
    },
    workListFilters:{
      type:Object,
      default: () => {},
    },
    department_uuid: "",
    usage_mode: "",
    work_uuid: "",
    date_from: "",
    date_to: "",
    start_mode: false,
    parent_expanded: false,
    parent_selected: false,
    last_machine: false
  },
  data() {
    return {
      parcelList: [],
      contentReady: false,
      selectAllAfterContentReady: false,
      deselectAllAfterContentReady: false,
    };
  },
  computed: {
    ...mapGetters({
      layers: 'layers/layers',
      currentDepartment: "auth/currentDepartment",
      departments: "company/departments",
    }),
    allParcelAreUnchecked() {
      return this.parcelList.find((p) => p.selected) == undefined;
    },
  },
  watch: {
    parent_expanded(newVal, oldVal) {
      if (newVal == true && this.parcelList.length <= 0) {
        this.getParcels();
      }
    },
  },
  components: {
    DxDataGrid,
    DxColumn,
    DxSelection,
  },
  methods: {
    ...mapActions({
      getSpcParcels: "seasonOnParcelHasCulture/getSpcParcels",
    }),
    checkIfHasLayer(parcel_uuid){
      return this.layers.find((obj) => obj.parcel_uuid == parcel_uuid) != null;

    },
    showMapForParcel(parcel_uuid, season_uuid){
      //if(this.checkIfHasLayer(parcel_uuid) && this.usage_mode != 'LIGHT'){
        this.$emit("showMapForParcel", parcel_uuid, season_uuid);
      //}
    },
    parcel_selected_event(selected, index, parcel) {
      let checkObj = this.parcelList.find((x) => x.uuid == parcel.uuid);
      checkObj.selected = selected;
      this.$set(this.parcelList, index, checkObj);
      this.$emit(
        "selectParcel",
        selected,
        checkObj,
        this.allParcelAreUnchecked
      );
    },
    selectAllParcels() {
      this.parcelList.forEach((p, index) => {
        p.selected = true;
        this.$set(this.parcelList, index, p);
      });
    },
    deselectAllParcels() {
      this.parcelList.forEach((p, index) => {
        p.selected = false;
        this.$set(this.parcelList, index, p);
      });
    },
    getParcels() {
      let localDepartmentFilter = []
        if(this.workListFilters && this.workListFilters.departments){
          this.workListFilters.departments.forEach(d => {
            if(this.departments.find(dep => dep.uuid == d)){
              localDepartmentFilter.push(d);
            }
          });
        }

      this.parcelList = [];
      this.getSpcParcels({
        department_uuid: localDepartmentFilter.join(),
        work_uuid: this.work_uuid,
        work_machine_uuid: this.templateData.data.uuid,
        date_from: this.date_from,
        date_to: this.date_to,
        parcel_uuid: this.workListFilters && this.workListFilters.parcels ? this.workListFilters.parcels.join() : null,
        product_uuid: this.workListFilters && this.workListFilters.products ? this.workListFilters.products.join() : null,
        season_uuid: this.workListFilters && this.workListFilters.seasons ? this.workListFilters.seasons.join() : null,
      }).then((response) => {
        if (response && response.length > 0) {
          response.forEach((parcel) => {
            parcel.department_uuid = this.department_uuid;
            parcel.work_uuid = this.work_uuid;
            parcel.machine_uuid = this.templateData.data.uuid;
            parcel.date = this.date_from;
            parcel.selected = this.parent_selected;
            this.parcelList.push(parcel);
          });
        }
      });
    },
  },
  mounted() {},
};
</script>