<template>
  <div class="work-log-item">
    <div class="row">
      <div class="form-group col-sm-12 col-lg-8 text-left">
        <label class="control-label">{{ $t('salary_calculator.work.title') }}</label>
        <v-select
          v-model="work"
          :class="{ 'field-error' : this.$v.item.work.uuid.$error }"
          :placeholder="$t('salary_calculator.work.search')"
          autocomplete="off"
          @option:selected="onWorkSelected"
          ref="works"
          taggable
          :disabled="totalHours <= 0"
          :options="work_list"></v-select>
      </div>
      <div class="form-group col-sm-3 text-left position-relative">
        <label class="control-label">{{ $t('salary_calculator.work_log.work_hours') }}</label>
        <input type="text" v-model="item.work_hours"
               :class="{ 'field-error' : this.$v.item.work_hours.$error }"
               v-on:keydown="onlyDecimal"
               :disabled="totalHours <= 0"
               maxlength="5"
               class="form-control whinput"/>
        <span class="timestamp">{{ timestamp.hour }}:{{ timestamp.minute }}</span>
      </div>
      <div class="col-lg-1 form-group pt-5" v-if="suggestWork">
        <a v-on:click.prevent="goto('/salary-calculator/sc-work/add', item.work.name)" class="c-pointer d-inline-block">
          <img src="~@/assets/images/desktop/icons/controls/plus.svg" class="icon-svg"/>{{ $t('common.add')}}</a>
      </div>
      <div class="col-lg-1 form-group">
        <img src="~@/assets/images/desktop/icons/controls/delete.svg" class="icon-svg"
             :class="{
          'danger-filter': totalHours > 0,
          'secondary-filter': totalHours <= 0
        }" @click="removeItem" :title="$t('common.remove')"/>
      </div>
    </div>
  </div>
</template>

<script>
import {required} from "vuelidate/lib/validators";
import {mapGetters} from "vuex";
import {buildDefaultAutocompleteList, only_decimal} from "@/helpers/common";
import * as actions from "@/helpers/generalActions";
import {convertHoursToTimestamp} from "@/helpers/dateHelper";

export default {
  name: 'WorkLogListItem',
  props: ['data', 'totalHours'],
  data() {
    return {
        suggestWork: false,
        work : undefined,
        item: this.data ? this.data : {
          work_hours: 0,
        },
        total_hours: this.totalHours ? this.totalHours : 0,
    }
  },
  computed: {
    ...mapGetters({
        works: 'scWorkPivot/scWorkPivots'
    }),
    work_list() {
      return buildDefaultAutocompleteList(this.works);
    },
    timestamp() {
      return convertHoursToTimestamp(this.item.work_hours);
    }
  },
  methods: {
    onWorkSelected(data) {
      this.$set(this.item, 'work', {
        uuid: data.value,
        name: data.label,
      });
      this.$set(this.item, 'work_uuid', data.value);
      this.suggestWork = false;
    },
    goto(path, name) {
      return actions.goto(this, path, name);
    },
    onlyDecimal(event) {
        only_decimal(event);
    },
    onWorkHoursChanged(val) {
        this.$emit('onWorkHoursChanged', val);
    },
    removeItem() {
      if(this.totalHours > 0) {
        this.$emit('onItemRemoved', this.item);
        this.$emit('onItemChanged', this.item);
        this.onWorkHoursChanged(this.item.work_hours);
      }
    },
    validate() {
      this.$v.item.$touch();
      return !this.$v.item.$invalid;
    },
    resetValidation() {
      this.$v.item.$reset();
    }
  },
  watch: {
    data: {
      handler: function(newVal) {
        this.item = newVal;
        this.item.validate = this.validate;
        this.item.resetValidation = this.resetValidation;
      },
      deep: true,
      immediate: true,
    },
    work: {
      handler: function(newVal) {
        if(!newVal) {
          this.$set(this.item, 'work', null);
          this.$set(this.item, 'work_uuid', null);
        }
      },
      deep: true,
    },
    item: {
      handler: function(newVal) {
          newVal.timestamp = this.timestamp;
          this.$emit('onItemChanged', newVal);
          this.onWorkHoursChanged(newVal.work_hours);
      },
      deep: true,
    },
    totalHours: {
      handler: function(newVal) {
        this.total_hours = newVal;
      },
      immediate: true,
    },
  },
  validations() {
    const greaterThanZero = (value) => value > 0;
    return {
      item: {
          work: {
              uuid: {
                required,
              }
          },
          work_hours: {
            minValue: greaterThanZero,
          }
      }
    }
  }
}
</script>

<style scoped>
  .del {
    font-size: 20px;
    margin-top: 40px;
    cursor: pointer;
  }
  .whinput {
    position: relative;
  }
  .timestamp {
    font-size: 10px;
    color: red;
    position: absolute;
    top: 35px;
    right: 25px;
  }
</style>
