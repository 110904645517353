<template>
    <div class="consum_details_holder">
        <div class="consum_details_spacer"></div>
        <div class="info_line">
            <div class="info_label">Cost:</div>
            <div class="info_value">
                <div>{{templateData.cost}} Ron</div>
                <div class="cost_for_ha">({{templateData.cost_per_ha}} Ron/ha)</div>
            </div>
        </div>
        <div class="info_line">
            <div class="info_label">Cantitate:</div>
            <div class="info_value">{{templateData.quantity}} {{templateData.mu }}</div>
        </div>
        <div class="info_line">
            <div class="info_label">Observații:</div>
            <div class="info_value">{{templateData.notes}}</div>
        </div>
    </div>
</template>

<script>
export default {
    name: "ConsumDetails",
    props: {
        templateData: {
            type: Object,
            default: {}
        }
    },
}
</script>