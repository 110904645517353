import Vue from 'vue'
import routes from './routes'
import store from "@/store";
import VueRouter from 'vue-router'
Vue.use(VueRouter)
import packages from '../../package.json';
import VueMeta from 'vue-meta'
Vue.use(VueMeta)

// The middleware for every page of the application.
const globalMiddleware = ['check-auth', 'check-subdomain-existance'] //check domain

// Load middleware modules dynamically.
const routeMiddleware = resolveMiddleware(
    require.context('~/middleware', false, /.*\.js$/)
)

const router = new VueRouter({
    scrollBehavior,
    mode: 'history',
    routes
});

router.beforeEach(beforeEach);
router.afterEach(afterEach);
export default router;

/**
 * Global router guard.
 *
 * @param {Route} to
 * @param {Route} from
 * @param {Function} next
 */
async function beforeEach (to, from, next) {
    let components = []

    try {
        // Get the matched components and resolve them.
        components = await resolveComponents(
            router.getMatchedComponents({ ...to })
        );

        if (components && components.length > 0) {
            components = components.filter((item) => {
                return item !== undefined;
            });
        }

        document.querySelector("[name='viewport']")
            .setAttribute("content", 'width=device-width,initial-scale=1,user-scalable=no');

    } catch (error) {
        if (/^Loading( CSS)? chunk (\d)+ failed\./.test(error.message)) {
            window.location.reload(true)
            return
        }
    }

    if (components.length === 0) {
        return next()
    }

    // Start the loading bar.
    const currComponent = components.length > 0 ? components[components.length - 1] : components[0] ?? null;
    if (currComponent && currComponent.loading !== false) {
        //router.app.$nextTick(() => router.app.$loading.start())
        //console.log('Loading started..', currComponent);

        if (currComponent.layout) {
            let currentLayout = null;
            if (currComponent.layout instanceof Function) {
                currentLayout = currComponent.layout();
            } else {
                currentLayout = currComponent.layout;
            }
        }
    }

    try {
        // Get the middleware for all the matched components.
        const middlewares = getMiddleware(components);

        // Call each middleware.
        middlewares.forEach((middleware) => {
            callMiddleware(middleware, to, from, (...args) => {
                next(...args)
            })
        });
    } catch(e) {
       console.error('before each exception router: ', e);
    }

    next();
}

/**
 * Global after hook.
 *
 * @param {Route} to
 * @param {Route} from
 * @param {Function} next
 */
async function afterEach (to, from, next) {
    await router.app.$nextTick()

    //router.app.$loading.finish()
    //console.log('Loading finished');
}

/**
 * Call each middleware.
 *
 * @param {String} middlewareItem
 * @param {Route} to
 * @param {Route} from
 * @param {Function} next
 */
function callMiddleware (middlewareItem, to, from, next) {
    const _next = (...args) => {
        const { middleware, params } = parseMiddleware(middlewareItem)

        if (typeof middleware === 'function') {
            middleware(to, from, _next, params)
        } else if (routeMiddleware[middleware]) {
            routeMiddleware[middleware](to, from, _next, params)
        } else {
            throw Error(`Undefined middleware [${middleware}]`)
        }
    }

    _next()
}

/**
 * @param  {String|Function} middleware
 * @return {Object}
 */
function parseMiddleware (middleware) {
    if (typeof middleware === 'function') {
        return { middleware }
    }

    const [name, params] = middleware.split(':');

    const injectedParams = {
        router: router,
        store: store,
    };

    return { middleware: name, params: injectedParams }
}

/**
 * Resolve async components.
 *
 * @param  {Array} components
 * @return {Array}
 */
function resolveComponents (components) {
    return Promise.all(components.map(component => {
        return typeof component === 'function' ? component() : component
    }))
}

/**
 * Merge the the global middleware with the components middleware.
 *
 * @param  {Array} components
 * @return {Array}
 */
function getMiddleware (components) {
    const middleware = [...globalMiddleware];

    components.filter(c => c.middleware).forEach(component => {
        if (Array.isArray(component.middleware)) {
            middleware.push(...component.middleware)
        } else {
            middleware.push(component.middleware)
        }
    })

    return middleware
}

/**
 * Scroll Behavior
 *
 * @link https://router.vuejs.org/en/advanced/scroll-behavior.html
 *
 * @param  {Route} to
 * @param  {Route} from
 * @param  {Object|undefined} savedPosition
 * @return {Object}
 */
function scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
        return savedPosition
    }

    if (to.hash) {
        return { selector: to.hash }
    }

    const [component] = router.getMatchedComponents({ ...to }).slice(-1)

    if (component && component.scrollToTop === false) {
        return {}
    }

    return new Promise((resolve, reject) => {
        setTimeout(() => {
            resolve({ x: 0, y: 0 })
        }, 190)
    })
}

/**
 * @param  {Object} requireContext
 * @return {Object}
 */
function resolveMiddleware (requireContext) {
    return requireContext.keys()
        .map(file =>
            {
                return [file.replace(/(^.\/)|(\.js$)/g, ''), requireContext(file)];
            }
        )
        .reduce((guards, [name, guard]) => (
            { ...guards, [name]: guard.default }
        ), {})
}
