import * as api from '../../api/index'
import * as endpoints from '../../api/endpoints'
import * as types from "../../mutation-types";

const actions = {
    getWorks({commit}, payload) {
        return api.fetch(endpoints.WORKS, payload, commit,(response) => {
          commit(types.SET_WORKS, response.data);
        });
    },
    createWork({commit}, payload) {
      return api.post(endpoints.WORKS, payload, commit,(response) => {
        
      });
  },
}

export default actions;
