import * as types from "../mutation-types";

const mutations = {
  [types.SET_USERS]: (state, payload) => {
    state.users = payload;
  },
  [types.SET_USER_FILTERS]: (state, payload) => {
    state.user_filters = payload;
  },
  [types.SET_USER_ITEM]: (state, payload) => {
    state.users.splice(state.users.indexOf(
      state.users.find(c => c.uuid === payload.uuid)
    ), 1, payload);
  },
  [types.SET_USER_DATA]: (state, payload) => {
    state.user_data = payload;
  },
}

export default mutations;
