<template>
  <div class="main-layout">
    <div class="container-fluid">
      <div
        class="no-margin row"
        :class="{
          'justify-content-center':
            !user || (user && user.default_department == null),
        }"
      >
        <div class="full-width" :class="authenticated ? rClass : loginClass">
          <router-view />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "devextreme/dist/css/dx.light.css";
import {isMobile} from "@/helpers/common";
import { mapGetters } from "vuex";
import {
  permissions,
  actions,
  getPerm,
  hasPermission,
} from "../helpers/Permissions";
import {eventBus} from "@/plugins/eventBus";
import {getServerSubdomain} from "@/helpers/common";

export default {
  name: "noheader",

  data() {
    return {
      menu_collapsed: true,
    };
  },
  computed: {
    ...mapGetters({
      authenticated: "auth/check",
      user: "auth/user",
      currentDepartment: "auth/currentDepartment",
       departments: 'company/departments',
      domain: 'auth/domain'
    }),
    permissionControlledMenu() {
      if (this.menu) {
        return this.menu.filter((item) => {
          if (item.permission) {
            return this.hasPermission(
              item.permission.code,
              item.permission.action
            );
          } else {
            if (item.child) {
              item.child = item.child.filter((subitem) => {
                if (subitem.permission) {
                  return this.hasPermission(
                    subitem.permission.code,
                    subitem.permission.action
                  );
                } else {
                  return true;
                }
              });

              if (item.child && item.child.length <= 0) {
                item.child = null;
              }
            }

            if (item.child === null) {
              return false;
            } else {
              return true;
            }
          }
        });
      }

      return [];
    },
    menu() {
      return [
        {
          title: this.$store.getters["auth/domain"].company,
          header: true,
          hiddenOnCollapse: true,
        },
        {
          href: "/dashboard",
          title: this.$t("dashboard"),
          icon: "fas fa-tachometer-alt",
        },
        {
          href: "/my-company",
          title: this.$t("company_management"),
          icon: "fa fa-building",
          permission: getPerm(permissions.COMPANY_MANAGEMENT, actions.r),
          child: [
            {
              href: "/my-company",
              title: this.$t("my_company"),
              icon: "fa fa-building",
              permission: getPerm(permissions.COMPANY_MANAGEMENT, actions.r),
            },
          ],
        },
        {
          href: "/users",
          title: this.$t("security"),
          icon: "fas fa-user-lock",
          child: [
            {
              href: "/users",
              title: this.$t("user_management"),
              icon: "fa fa-user",
              permission: getPerm(permissions.USER_MANAGEMENT, actions.r),
            },
            {
              href: "/roles-permissions",
              title: this.$t("roles_permissions"),
              icon: "fas fa-user-tag",
              permission: getPerm(permissions.ROLES_PERMISSIONS, actions.r),
            },
          ],
        },
        {
          href: "/works",
          title: this.$t("works.name"),
          icon: "fas fa-tractor",
          child: [
            {
              href: "/works/manager",
              title: this.$t("works.manager.name"),
              icon: "fa fa-tractor",
              permission: getPerm(permissions.IMPORT, actions.r),
            },
            {
              href: "/works/applyArticles",
              title: "Apply articles",
              icon: "fa fa-leaf",
              permission: getPerm(permissions.IMPORT, actions.r),
            },
          ],
        },
        {
          href: "/tools",
          title: this.$t("tools.name"),
          icon: "fas fa-tools",
          child: [
            {
              href: "/tools/import",
              title: this.$t("import"),
              icon: "fa fa-file-import",
              permission: getPerm(permissions.IMPORT, actions.r),
            },
            {
              href: '/tools/import-shape',
              title: this.$t('import_shape_file'),
              icon: 'fa fa-file-import',
              permission: getPerm(permissions.IMPORT, actions.r),
            },
          ],
        },
      ];
    },
    scClass() {
      return {
        "sd-cp": this.menu_collapsed,
        "sd-exp": !this.menu_collapsed,
      };
    },
    rClass() {
      return {
        "r-cp": this.menu_collapsed,
        "r-exp": !this.menu_collapsed,
        "sm-hide": !this.menu_collapsed,
      };
    },
    loginClass() {
      return ["col-12"];
    },
  },
  methods: {
    onToggleCollapse(collapsed) {
      this.menu_collapsed = collapsed;
    },
    isMobile() {
      return isMobile();
    },
    hasPermission(type, action) {
      return hasPermission(this.user, type, action);
    },
    handleDepartmentSelector() {
      if (
        !this.user ||
        (this.user && this.currentDepartment.department_uuid == null)
      ) {
        // this.$router.push({
        //   path: "/dashboard/departmentselector",
        // });
      }
    },
    handleSession() {
      let vm = this;
      eventBus.$on("session-expired", (errors) => {
        let unauthorizedRedirect = getServerSubdomain()+'/session-expired?layout=noheader';
        window.location.href = unauthorizedRedirect;
      });
    }
  },
  mounted() {
    this.handleSession();
    //this.handleDepartmentSelector();
  },
};
</script>

<style lang="scss" scoped>

.container-fluid{
    padding:0px !important;
}

.no-margin{
    margin: 0px !important;
}

.full-width{
    width: 100% !important;
    padding-right: 0px !important;
    padding-left: 0px !important;
}

.sd-cp {
  width: 70px;
}

.sd-exp {
  width: 350px;
}

.r-cp {
  width: calc(100% - 93px);
}

.r-exp {
  width: calc(100% - 390px);
}
</style>
