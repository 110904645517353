import * as types from "@/store/mutation-types";

const mutations = {
  [types.SET_IMPORT_TEMPLATE]: (state, payload) => {
    state.import_template = payload;
  },
  [types.SET_IMPORT_TEMPLATES]: (state, payload) => {
    state.import_templates = payload;
  },
  [types.SET_REQUIRED_ATTRIBUTES]: (state, payload) => {
    state.required_attributes = payload;
  },
  [types.SET_IMPORT_CONFIGURATIONS]: (state, payload) => {
    state.import_configurations = payload;
  },
  [types.UPDATE_IMPORT_CONFIGURATIONS]: (state, payload) => {
    payload.forEach(updateItemConfig => {
      const itemIndex = state.import_configurations.findIndex(config => config.uuid === updateItemConfig.uuid);
      if (itemIndex !== -1) {
        state.import_configurations[itemIndex] = { ...state.import_configurations[itemIndex], ...updateItemConfig };
      } else {
        state.import_configurations.push(updateItemConfig);
      }
    });
  },
  [types.SET_IMPORT_SOURCE_CREDENTIALS]: (state, payload) => {
    state.import_source_credentials = payload;
  },
  [types.UPDATE_IMPORT_VALIDATED_DATA]: (state, payload) => {
    payload.forEach(updateValidatorItem => {
      const itemIndex = state.import_validated_data.findIndex(config => config.uuid === updateValidatorItem.uuid);
      if (itemIndex !== -1) {
        if (updateValidatorItem.deleted_at !== null) {
          state.import_validated_data.splice(itemIndex, 1);
        } else {
          state.import_validated_data[itemIndex] = { ...state.import_validated_data[itemIndex], ...updateValidatorItem };
        }
      } else {
        state.import_validated_data.push(updateValidatorItem);
      }
    });
  },
  [types.SET_IMPORT_IMPORT_VALIDATED_DATA]: (state, payload) => {
    state.import_validated_data = payload;
  },
  [types.SET_IMPORT_VALIDATED_DATA_SETTINGS]: (state, payload) => {
    state.import_validated_data_settings = payload;
  },
}

export default mutations;
