<template>
  <card :title="title" id="department-details">
    <span class="action_buttons">
      <img src="~@/assets/images/desktop/icons/controls/plus.svg" class="icon-svg" :title="$t('company.departments.create_new_department')" @click="newDepartment()"/>
    </span>
    <div class="row">
      <div class="col-sm-4 col-lg-4">
        <card :title="$t('company.departments.unit')">
          <div class="row">
            <div class="form-group col-sm-12 col-lg-8">
              <label class="control-label">{{ $t('company.departments.parent_department')}}</label>
              <b-form-select v-model="data.parent_uuid"
                             class="form-control"
                             @change="onParentDepartmentChanged"
                             :options="parent_departments"></b-form-select>
            </div>
            <div class="form-group col-sm-12 col-lg-8">
              <label class="control-label">{{ $t('company.departments.unit_type')}}</label>
              <b-form-select v-model="data.type"
                             class="form-control"
                             :class="{ 'field-error' : this.$v.data.type.$error }"
                             :options="department_type_list"></b-form-select>
            </div>
          </div>
          <div class="row" v-if="data.uuid && data.company">
            <div class="form-group col-sm-12 col-lg-8">
              <label class="control-label">{{ $t('company.departments.company_name') }}</label>
              <a  :href="companyDomainPath"  target="_blank">{{  data.company.name }}</a>
            </div>
          </div>
          <div class="row">
            <div class="form-group col-sm-12 col-lg-8">
              <label class="control-label">{{ $t('company.departments.name') }}</label>
              <input type="text" v-model="data.name"
                     :class="{ 'field-error' : this.$v.data.name.$error }"
                     :placeholder="$t('company.departments.name')"
                     class="form-control"/>
            </div>
          </div>
          <div class="row">
            <div class="form-group col-sm-12 col-lg-8">
              <label class="control-label">{{ $t('company.departments.code') +' ('+$t('not_required') }})</label>
              <input type="text" v-model="data.code"
                     :placeholder="$t('company.departments.code')"
                     class="form-control"/>
            </div>
          </div>
          <div class="row">
          </div>
          <div class="row">
            <div class="form-group col-sm-12 col-lg-8">
              <label class="control-label">{{ $t('company.departments.latitude')}}</label>
              <input type="text" v-model="data.latitude"
                     placeholder="00.00000"
                     class="form-control"/>
            </div>
          </div>
          <div class="row">
            <div class="form-group col-sm-12 col-lg-8">
              <label class="control-label">{{ $t('company.departments.longitude')}}</label>
              <input type="text" v-model="data.longitude"
                     placeholder="00.00000"
                     class="form-control"/>
            </div>
          </div>
          <div class="row">
            <div class="form-group col-sm-12 col-lg-8">
              <label class="control-label">{{ $t('company.departments.website')}}</label>
              <input type="text" v-model="data.website"
                     :placeholder="$t('company.departments.website')"
                     class="form-control"/>
            </div>
          </div>
          <div class="row">
            <div class="form-group col-sm-12 col-lg-8">
              <label class="control-label">{{ $t('company.departments.logo')}}</label>
              <image-uploader :data="imageData"
                              @onImageLoaded="onImageLoaded"
                              @onImageRemoved="onImageRemoved"
                              :placeholder="$t('company.departments.logo')"

              />
            </div>
          </div>
        </card>
      </div>
      <div class="col-sm-4 col-lg-4">
        <card :title="$t('company.departments.department_owner') + ' (' + $t('common.not_required') + ')'" :collapsable="true" :expanded="data.main_user && data.main_user.uuid !== undefined" v-if="data.main_user">
          <a v-on:click.prevent="manageUsers()" class="text-primary c-pointer" v-if="data.uuid">{{ $t('company.departments.manage_department_users') }} ({{ users.length }})</a>
          <div class="row" :class="{ 'pt-3': data.uuid }">
            <div class="form-group col-sm-12 col-lg-8">
              <label class="control-label">{{ $t('user.first_name') }}</label>
              <input type="text" v-model="data.main_user.first_name"
                     :class="{ 'field-error' : this.$v.data.main_user.first_name.$error }"
                     :placeholder="$t('user.first_name')"
                     class="form-control"/>
            </div>
          </div>

          <div class="row">
            <div class="form-group col-sm-12 col-lg-8">
              <label class="control-label">{{ $t('user.last_name') }}</label>
              <input type="text" v-model="data.main_user.last_name"
                     :class="{ 'field-error' : this.$v.data.main_user.last_name.$error }"
                     :placeholder="$t('user.last_name')"
                     class="form-control"/>
            </div>
          </div>
          <div class="row">
            <div class="form-group col-sm-12 col-lg-8">
              <label class="control-label">{{ $t('user.email') }}</label>
              <input type="text" v-model="data.main_user.email"
                     :class="{ 'field-error' : this.$v.data.main_user.email.$error }"
                     :placeholder="$t('user.email')"
                     class="form-control"/>
            </div>
          </div>
          <div class="row">
            <div class="form-group col-sm-12 col-lg-8">
              <label class="control-label">{{ $t('user.phone') }}</label>
              <input type="text" v-model="data.main_user.phone"
                     :placeholder="$t('user.phone')"
                     class="form-control"/>
            </div>
          </div>
          <div  id="change-pw-panel" class="change-pw-panel"  v-if="(data.main_user && !data.main_user.uuid) || showPasswordFields">
            <div class="row">
              <div class="form-group col-sm-12 col-lg-8">
                <label class="control-label">{{ $t('user.password') }}</label>
                <input type="password" v-model="data.main_user.password"
                       :class="{ 'field-error' : this.$v.data.main_user.password.$error }"
                       :placeholder="$t('user.password')"
                       class="form-control"/>
              </div>
            </div>
            <div class="row">
              <div class="form-group col-sm-12 col-lg-8">
                <label class="control-label">{{ $t('user.confirm_password') }}</label>
                <input type="password" v-model="data.main_user.confirm_password"
                       :class="{ 'field-error' : this.$v.data.main_user.confirm_password.$error }"
                       :placeholder="$t('user.confirm_password')"
                       class="form-control"/>
              </div>
            </div>
          </div>
          <div class="change-pw-text"  v-else>
            <a v-on:click.prevent="showPasswordFields = true" class="text-primary c-pointer">{{$t("user.click_to_change_password")}}</a>
          </div>
          <div class="row mt-3">
            <div class="form-group col-sm-12 col-lg-9">
              <b-form-checkbox switch size="lg"
                               v-model="api_access"
                               @change="onApiAccessChanged"
              >Smagria App access</b-form-checkbox>
            </div>
          </div>
        </card>
      </div>
      <div class="col-sm-4 col-lg-4">

        <card :title="$t('company.departments.legal_entity.name')" :collapsable="true" :expanded="true">
        <div class="row">
          <div class="form-group col-lg-12">
            <label class="control-label">{{$t("company.departments.legal_entity.name")}}</label>
            <input type="text" v-model="data.legal_entity.name"
                   :class="{ 'field-error' : $v.data.legal_entity.name.$error }"
                   placeholder=''
                   class="form-control"/>
          </div>
        </div>
        <div class="row">
          <div class="form-group col-lg-12">
            <label class="control-label">{{$t("company.departments.legal_entity.id_number")}}</label>
            <input type="text" v-model="data.legal_entity.id_number"
                   :class="{ 'field-error' : $v.data.legal_entity.id_number.$error }"
                   placeholder=''
                   class="form-control"/>
          </div>
        </div>
        <div class="row">
          <div class="form-group col-lg-12">
            <label class="control-label">{{$t("company.departments.legal_entity.nr_reg")}}</label>
            <input type="text" v-model="data.legal_entity.nr_reg"
                   :class="{ 'field-error' : $v.data.legal_entity.nr_reg.$error }"
                   placeholder=''
                   class="form-control"/>
          </div>
        </div>
        <div class="row">
          <div class="form-group col-lg-12">
            <label class="control-label">{{$t("company.departments.legal_entity.address")}}</label>
            <input type="text" v-model="data.legal_entity.address"
                   :class="{ 'field-error' : $v.data.legal_entity.address.$error }"
                   placeholder=''
                   class="form-control"/>
          </div>
        </div>
          <div class="row">
            <div class="form-group col-lg-12">
              <label class="control-label">{{$t("company.departments.legal_entity.iban")}}</label>
              <input type="text" v-model="data.legal_entity.iban"
                     :class="{ 'field-error' : $v.data.legal_entity.address.$error }"
                     placeholder=''
                     class="form-control"/>
            </div>
          </div>
        </card>

        <card :title="$t('contacts') + ' (' + $t('common.not_required') + ')'" :collapsable="true" :expanded="data.contacts && data.contacts.length > 0">
          <div class="row pt-2" v-for="item in data.contacts" :key="item.uuid" v-if="!item.deleted">
            <div class="col-lg-4">
              {{ [item.first_name, item.last_name].join(' ') }}
            </div>
            <div class="col-lg-6">
              {{ item.email }}
            </div>
            <div class="col-lg-2">
              <a v-on:click.stop.prevent="showContactsDialog(item)" :title="$t('common.edit')" class="c-pointer">
                <img src="~@/assets/images/desktop/icons/controls/check-circle.svg" class="icon-svg success-filter sicon"/>
              </a>
              <a v-on:click.stop.prevent="onDeleteContact(item)" title="Remove" class="c-pointer">
                <img src="~@/assets/images/desktop/icons/controls/delete.svg" class="icon-svg danger-filter sicon"/>
              </a>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-8 pt-3">
              <button class="btn-sm btn btn-primary" @click="showContactsDialog({})">{{ $t('company.departments.new_contact') }}</button>
            </div>
          </div>
        </card>
      </div>
    </div>
    <div class="action-buttons pt-4">
      <div class="row form-group">
        <div class="col-lg-6 text-center mx-auto">
          <div class="row">
            <div class="col">
              <button class="btn btn-md btn-secondary btn-secondary" @click="gotoList">{{ $t('common.back_to_list') }}</button>
            </div>
            <div class="col">
              <button class="btn btn-md btn-primary" @click="save">{{ isCompanyCreator ? $t('company.departments.finish_and_save') : $t('common.save') }}</button>
            </div>
            <div class="col" v-if="data.uuid">
              <button class="btn btn-md btn-danger" @click="confirmDelete">{{ $t('common.delete') }}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ConcatInfoDialog :show="showModal" @close="showModal = false"
                      :data="modalData"
                      @onSave="onSaveContact"
                      @onDelete="onDeleteContact"
    ></ConcatInfoDialog>
    <DepartmentUsersDialog :show="showDepartmentUsersModal" @close="showDepartmentUsersModal = false"
                           :data="departmentUsersModalData"
                           @onOK="showDepartmentUsersModal = false"
                           :user-management="true"
                           :title="$t('company.departments.department_users')"
    ></DepartmentUsersDialog>
  </card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import * as type from '../../../store/mutation-types'
import {
  successMessage,
  errorMessage,
  buildDropdownOptions,
  errorMessageList,
  infoMessage, buildDefaultDropdownList
} from '~/helpers/common'
import ImageUploader from "../../../components/ImageUploader";
import ConcatInfoDialog from "./partials/ContactInfoDialog";
import required from "vuelidate/src/validators/required";
import requiredIf from "vuelidate/src/validators/requiredIf";
import DepartmentUsersDialog from "./partials/ContactListDialog";

export default {
  name: 'DepartmentDetails',
  data() {
    return {
      initialDepartment: {
        uuid: null,
        parent_uuid: null,
        company_uuid: this.$route.params.company_uuid,
        type: null,
        main_user: {
          company_uuid: null,
          department_uuid: null,
          first_name: null,
          last_name: null,
          email: null,
          phone: null,
          phone_number: null,
          password: null,
          confirm_password: null,
        },
        legal_entity: {
          name: null,
          id_number: null,
          nr_reg: null,
          address: null,
        },
        contacts: [],
      },
      latitude: null,
      longitude: null,
      imageData: {},
      image_removed: false,
      showModal: false,
      modalData: {},
      showPasswordFields: false,
      showDepartmentUsersModal: false,
      departmentUsersModalData: {},
      api_access: false,
    }
  },
  components: {
    ImageUploader,
    ConcatInfoDialog,
    DepartmentUsersDialog
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
      department: 'department/department',
      parentDepartments: 'department/departments',
      departmentTypes: 'agroFilter/departmentTypes'
    }),
    isCompanyCreator() {
      return (this.$route.query && this.$route.query.cc == 1);
    },
    data: {
      get: function() {
        return this.department ? this.department : this.initialDepartment;
      },
      set: function(newVal) {
        return newVal;
      }
    },
    server() {
      return process.env.VUE_APP_BROWSER_BASE_URL;
    },
    companyDomainPath() {
      if(this.data.company) {
        return '//'+this.data.company.domain+'.'+window.location.host;
      }

      return '#';
    },
    title() {
      if(this.data.uuid) {
        return this.$t('company.departments.edit_department', { unit: this.data.type_name });
      } else {
        return this.$t('company.departments.create_new_department');
      }
    },
    parent_departments() {
      return buildDefaultDropdownList(Object.assign([], this.parentDepartments).filter((cp) => {
        return cp.uuid !== this.data.uuid;
      }));
    },
    users() { //department users count
      return this.data.users ? this.data.users : [];
    },
    department_type_list() {
      return buildDefaultDropdownList(
        Object.assign([], this.departmentTypes), {
          value: 'code',
          text: 'name',
        }
      );
    },
  },
  methods: {
    ...mapActions({
      getParentDepartments: 'department/getDepartments',
      getDepartment: 'department/getDepartment',
      createDepartment: 'department/createDepartment',
      updateDepartment: 'department/updateDepartment',
      deleteDepartment: 'department/deleteDepartment',
      getCompany: 'company/getCompany',
      getDepartmentTypes: 'agroFilter/getDepartmentTypes'
    }),
    getDepartmentData() {
      let vm = this;

      vm.getParentDepartments({
        company_uuid: this.$route.params.company_uuid,
      });

      if(this.$route.params.uuid && this.$route.params.uuid !== 'add') {
        this.$store.dispatch('department/getDepartment', {
          company_uuid: this.$route.params.company_uuid,
          uuid: this.$route.params.uuid
        }).then((response) => {
          if(response === null || ( response && !response.is_active && response.deleted_at )) {
            errorMessage(vm, vm.$t('company.department_not_available'));
            setTimeout(function(){
              vm.$router.push({
                path: '/my-company/departments',
              });
            }, 2000);
          } else {
            if(response.image_uuid) {
              vm.imageData = { image: this.server+'/api/companies/'+response.company_uuid+'/departments/'+response.uuid+'/images/image?height=85' };
            }

            if(!vm.data.main_user) {
              vm.$set(vm.data, 'main_user', vm.initialDepartment.main_user);
            }
          }
        }).catch((e) => {
          errorMessage(vm, vm.$t('company.department_not_found'));
          console.log('department load catch? ', e);

          setTimeout(function(){
            vm.$router.push({
              path: '/my-company/departments',
            });
          }, 2000);
        });
      } else {
        this.$store.commit("department/"+type.SET_DEPARTMENT, this.initialDepartment);
      }
    },
    confirmDelete() {
      let vm = this;

      this.$dialog.confirm('Do you really want to delete this "'+this.data.name+'" unit?', {})
        .then((dialog) => {
          vm.deleteDepartment({
            company_uuid: this.data.company_uuid,
            uuid: this.data.uuid,
          }).then((response) => {
            dialog.close();
            successMessage(vm, response.message);
            setTimeout(function() {
              vm.$router.push({
                path: '/my-company/departments',
              });
            }, 500);
          }).error((response) => {
            dialog.close();
            errorMessage(vm, response.message);
          });
        });
    },
    onImageLoaded(imageObject) {
      this.$emit('onImageLoaded', imageObject);
      this.data.image = imageObject.data;
      this.imageData.image = imageObject.data;
      this.image_removed = false;
    },
    onImageRemoved() {
      this.image_removed = true;
      this.data.image = null;
      this.imageData.image = null;
    },
    save() {
      let vm = this;

      if (this.data.legal_entity && !this.data.legal_entity.name && !this.data.legal_entity.id_number) {
        this.data.legal_entity = {};
      }

      this.$v.data.$touch();

      if(!this.$v.data.$invalid) {

        if(this.data.image && this.data.image.uuid){
          this.data.image = null;
        }
        this.data.image_removed = this.image_removed;

        if (this.checkMainUserIsSet()) {
          if(this.data.main_user && this.data.main_user.password) {
            if(this.data.main_user.password !== this.data.main_user.confirm_password) {
              errorMessage(this, this.$t('user.password_not_match'));
              return;
            }
          }

          //additional info
          this.data.main_user.company_uuid = this.$route.params.company_uuid;
          this.data.main_user.department_uuid = this.$route.params.uuid;
          this.data.main_user.phone_number = this.data.main_user.phone;
        } else {
          this.data.main_user = null;
        }

        if(this.data.uuid) {
          this.updateDepartment(this.data).then((response) => {
            if(!vm.data.main_user) {
              vm.$set(vm.data, 'main_user', vm.initialDepartment.main_user);
            }
            successMessage(vm, response.message);
          }).catch((e) => {
            errorMessageList(vm, e.errors);
          });
        } else {
          this.createDepartment(this.data).then((response) => {
            successMessage(vm, response.message);
            vm.$router.push({
              path: '/my-company/departments/'+response.data.uuid,
              params: {
                uuid: response.data.uuid,
              }
            });
          }).catch((e) => {
            errorMessageList(this, e.errors);
          });
        }

      } else {
        errorMessage(this, this.$t('fill_out_error'));
      }
    },
    onSaveContact(data) {
      if(!data.edit) {
        data.department_uuid = this.data.uuid;
        this.data.contacts.push(data);
      } else {
        this.data.contacts.splice(this.data.contacts.indexOf(
          this.data.contacts.find(c => c.uuid === data.uuid)
        ), 1, data);
      }
    },
    onDeleteContact(data) {
      this.data.contacts.splice(this.data.contacts.indexOf(
        this.data.contacts.find(c => c.uuid === data.uuid)
      ), 1);
    },
    showContactsDialog(contact) {
      if(contact.uuid) {
        contact.edit = true;
      }

      this.showModal = true;
      this.modalData = contact;
    },
    newDepartment() {
      this.$store.commit('company/'+type.SET_COMPANY, this.initialCompany);

      this.$router.push({
        path: 'add'
      }).catch((e)=>{
        window.location.href = '/my-company/departments/add';
      });
    },
    gotoList() {
      this.$router.push({
        path: '/my-company/departments/',
      })
    },
    manageUsers() {
      this.departmentUsersModalData = this.data.users;
      this.showDepartmentUsersModal = true;
    },
    onParentDepartmentChanged(data) {

    },
    onApiAccessChanged(val) {
      this.data.main_user.api_access = val  ? 1 : 0;
    },
    checkMainUserIsSet() {
      let vm = this;
      if (this.data.main_user) {
        return Object.keys(this.data.main_user).filter((key) => {
          return vm.data.main_user[key] !== null && vm.data.main_user[key] !== '' && vm.data.main_user[key] !== undefined;
        }).length > 0;
      }

      return false;
    }
  },
  mounted() {
    this.getDepartmentData();
    this.getDepartmentTypes();
  },
  watch: {
    department: {
      handler: function(newVal) {
        this.data = newVal;

        if (!this.data.legal_entity) {
          this.data.legal_entity = {

          };
        }

        if(this.data.main_user) {
          this.api_access = Boolean(this.data.main_user.api_access);
        }
      },
      deep: true,
    },
  },
  validations() {
    return {
      data: {
        name: {
          required,
        },
        type: {
          required,
        },
        main_user: {
          first_name: {
            required: requiredIf(obj => obj.email !== '' && obj.email !== null),
          },
          last_name: {
            required: requiredIf(obj => obj.first_name !== '' && obj.first_name !== null),
          },
          email: {
            required: requiredIf(obj => obj.last_name !== '' && obj.last_name !== null),
          },
          password: {
            required: requiredIf(obj => obj.email !== '' && obj.email !== null && !obj.uuid),
          },
          confirm_password: {
            required: requiredIf(obj => obj.password !== null && obj.password !== '' && !obj.uuid),
          }
        },
        legal_entity: {
          name: {
            required: requiredIf(obj => obj.id_number || obj.nr_reg || obj.address || obj.uuid),
          },
          id_number: {
            required: requiredIf(obj => obj.name || obj.nr_reg || obj.address || obj.uuid),
          },
          nr_reg: {
            required: requiredIf(obj => obj.id_number || obj.name || obj.address || obj.uuid),
          },
          address: {
            required: requiredIf(obj => obj.id_number || obj.nr_reg || obj.name || obj.uuid),
          }
        }
      }
    }
  },
}
</script>
