<template>
  <div id="department-item" class="department-item">
    <div class="row">
      <div class="col-lg-4">
        - {{ item.name }}
      </div>
      <div class="col-lg-8" v-if="item.is_department_owner">
        <span class="text-danger">{{$t("department_owner_danger")}}</span>
      </div>
      <div class="col-lg-12 dep-sep" v-else>
        <div class="row">
          <div class="col-lg-4">
            <b-form-checkbox switch size="sm" v-model="allowAccess" :disabled="disabled">{{$t("allow_access")}}</b-form-checkbox>
          </div>
          <div class="col-lg-4" v-show="false">
            <b-form-checkbox @change="isDepartmentAdminChanged" switch size="sm" v-model="isDepartmentAdmin" v-if="allowAccess" :disabled="disabled">{{$t("set_as_department_admin")}}</b-form-checkbox>
          </div>
          <div class="col-lg-4">
            <div class="role" v-if="allowAccess && !isDepartmentAdmin">
              <label class="control-label">{{$t("role")}}</label>
              <b-form-select v-model="role_uuid" class="form-control"
                             :options="role_list"
                             @change="onRoleChanged"></b-form-select>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
    import {buildDropdownOptions} from "~/helpers/common";
    import {mapGetters} from "vuex";
    import Integer from "vuelidate/lib/validators/integer";

    export default {
        name: 'DepartmentItem',
        props: ['data', 'disabled', 'user', 'company'],
        data() {
            return {
                item: this.data ? this.data : {},
                companyData: this.company ? this.company : {},
                currentDepartment: null,
                allowAccess: false,
                role_uuid: null,
                isDepartmentAdmin: false,

            }
        },
        computed: {
            ...mapGetters({
                roles: 'roles/roles',
            }),
            role_list() {
                return buildDropdownOptions(Object.assign([], this.roles), {
                    value: 'uuid',
                    text: 'name',
                }, {
                    value: null,
                    text: this.$i18n.t("assign_role_combo"),
                });
            },
            domain()  {
                return this.$store.getters['auth/domain'];
            }
        },
        methods: {
            handleUser() {
              this.allowAccess = Boolean(this.currentDepartment);
              this.role_uuid = this.currentDepartment?.role?.role_uuid;
              this.isDepartmentAdmin = Boolean(this.currentDepartment?.is_department_admin);
            },
            onRoleChanged(uuid) {
              if(this.item.is_department_admin) {
                  this.$set(this.item, 'role_uuid', null);
              } else {
                this.$set(this.item, 'role_uuid', uuid);
              }
              this.$emit('onRoleChanged', this.item);
            },
            isDepartmentAdminChanged(val) {
                this.item.is_department_admin = val ? 1 : 0;
                this.currentDepartment.is_department_admin = val ? 1 : 0;
            }
        },
        mounted() {
          this.currentDepartment = this.user.units?.find(u => u.uuid === this.data.uuid);
            this.handleUser();
        },
        watch: {
            data: {
                handler: function (newVal) {
                    this.item = newVal;
                },
                deep: true,
            },
            disabled: function(newVal) {
                if(newVal) {
                    this.currentDepartment.is_department_admin = false;
                }
            },
            company: {
                handler: function (newVal) {
                    this.companyData = newVal;
                },
                deep: true,
            },
            role_uuid: {
              handler: function (newVal) {
                this.$set(this.item, 'role_uuid', newVal);
              },
              deep: true,
            },
            allowAccess: {
              handler: function (newVal) {
                let currentUserUnit = this.user.units?.find(u => u.uuid === this.data.uuid);
                if (newVal) {
                  this.$emit('onDepartmentAssigned', this.item);
                  this.currentDepartment = currentUserUnit ?? this.item;
                } else {
                  this.$emit('onDepartmentRevoked', this.item);
                  this.currentDepartment = null;
                }
              },
              deep: true,
            },
        }
    }
</script>

<style lang="scss" scoped>
  .department-item {
    padding: 6px;
  }

  .dep-sep {
    border-bottom: 1px #ededed solid;
    padding-bottom: 20px;
  }
</style>
