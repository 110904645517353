<template>
    <div ref="viewer" style="height: 100vh;"></div>
  </template>
  <script>
    import 'devexpress-reporting/dx-webdocumentviewer';
    import { DxReportViewer } from 'devexpress-reporting/dx-webdocumentviewer';
    import * as ko from 'knockout';
    import { mapActions, mapGetters } from "vuex";
    import 'whatwg-fetch';


    export default {
      name: "WebDocumentViewer",
      props: {
        reportId: {
            type: Number,
            default: 0,
        },
      },
      computed:{
        ...mapGetters({
            company: "auth/domain",
            current_token: "auth/token",
        }),
      },
      methods: {
        loadViewer(){
          var self = this;
          const reportUrl = ko.observable(self.reportId + '#' + self.company.uuid);
          const viewerRef = this.$refs.viewer;
          const requestOptions = {
            host: process.env.VUE_APP_REPORTING_URL,
            //invokeAction: "DXXRDV/CustomInvoke?token=" + (this.company ? this.company.uuid : '') + "&authtoken=" + this.current_token
            invokeAction: "DXXRDV"
          };
          const viewer = new DxReportViewer(viewerRef, {
            reportUrl,
            requestOptions
          });
          viewer.render();
        },
        async authorizeUser(){
          let url = process.env.VUE_APP_REPORTING_URL + 'Security/Authorize?token=' + (this.company ? this.company.uuid : '') + "&authtoken=" + this.current_token;
          let response = await fetch(url, {method: 'POST'});
          if(response?.status){
            if(response.status == 200){
              this.loadViewer();
            }
          }
          
        }
      },
      mounted() {
        this.authorizeUser();
        
      },
      beforeUnmount() {
        ko.cleanNode(this.$refs.viewer);
      }
    };
  </script>