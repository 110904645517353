<template>
  <span class="user-action-controls">
    <i :title="$t('user.has_api_access')" class="fas fa-mobile-alt text-dark p-1" v-if="data.api_access === 1"></i>
    <a v-on:click.stop.prevent="showPasswordDialog(data)" :title='$t("change_password")'
       class="c-pointer p-1"
       v-if="canEdit">
      <img src="~@/assets/images/desktop/icons/controls/plus.svg" class="primary-filter icon-svg"/>
    </a>
    <change-password-dialog :show="showPasswordModal" @close="showPasswordModal = false"
                            :data="passwordModalData"
                            @onPasswordChanged="onPasswordChanged"
    ></change-password-dialog>
  </span>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {errorMessageList, successMessage} from "~/helpers/common";
import ChangePasswordDialog from "~/pages/users/partials/ChangePasswordDialog.vue";
import {hasPermission, permissions, actions} from "~/helpers/Permissions";

export default {
  name: 'UserActionControl',
  components: {ChangePasswordDialog},
  props: ['data'],
  data() {
    return {
      passwordModalData: {},
      showPasswordModal: false,
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
    }),
    canEdit() {
      return hasPermission(this.user, permissions.USER_MANAGEMENT, actions.EDIT);
    },
    canDelete() {
      return hasPermission(this.user, permissions.USER_MANAGEMENT, actions.DELETE);
    }
  },
  methods: {
    ...mapActions({
      changePassword: 'user/changePassword',
    }),
    showPasswordDialog(item) {
      item.password = '';
      item.confirm_password = '';
      this.passwordModalData = item;
      this.showPasswordModal = true;
      console.log('pwd modal', item);
    },
    onPasswordChanged(data) {
      let vm  = this;
      this.changePassword(data).then((response) => {
        successMessage(vm, response.message);
      }).catch((e) => {
        errorMessageList(vm, e.errors);
      })
    },
  }
}
</script>
