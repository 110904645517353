<template>
  <div class="general-file-uploader">
    <b-form-file
        v-model="file"
        :state="Boolean(file)"
        :accept="config.allowed_extensions"
        placeholder="Choose a file or drop it here..."
        drop-placeholder="Drop file here..."
    ></b-form-file>
    <selected-file-progress :file="file" :progress-value="progressValue" :error="errorMessage" :success="successMessage"/>
  </div>
</template>

<script>
import SelectedFileProgress from "@/modules/FileUploader/partials/SelectedFileProgress.vue";
import axios from "@/plugins/axios";

export default {
  name: 'FileUploader',
  components: {SelectedFileProgress},
  props: ['config', 'params', 'headers'],
  data() {
    return {
      file: null,
      progressValue: 100,
      errorMessage: null,
      successMessage: null,
      requestParams: this.params ?? {},
    }
  },
  computed: {
    requestHeaders() {
      return {
        headers: this.headers ?? {},
        onUploadProgress: progressEvent => this.onUploadProgress(progressEvent)
      };
    }
  },
  methods: {
    clearSelectedFiles() {
      this.file = null;
      this.reset();
    },
    reset() {
      this.progressValue = 0;
      this.errorMessage = null;
      this.successMessage = null;
      this.requestParams = null;
    },
    upload(requestParams = {}) {
      let vm = this;
      let formData = new FormData();
      formData.append('file', this.file, this.file.name);

      Object.keys(requestParams).forEach((key) => {
        formData.append(key, requestParams[key]);
      })

      axios.post(this.config.upload_url, formData, this.requestHeaders)
          .then(response => {
            vm.$emit('onFileUploaded', response.data.data);
            vm.successMessage = response.data.data.message;
          })
          .catch(error => {
            vm.$emit('onFileUploadError', error);
            if (error.response.data && error.response.data.message) {
              vm.errorMessage = error.response.data.message;
            } else {
              vm.errorMessage = error;
            }

            console.error('onFileUploadError: ', error);
          });
    },
    onUploadProgress(e) {
      this.progressValue = Math.round( (e.loaded * 100) / e.total );
    },
    removeFile() {
      this.clearSelectedFiles();
    }
  },
  mounted() {
    document.querySelector('.b-form-file input').style.display = 'none';
  },
  watch: {
    file: function(file) {
      this.reset();
      if (file) {
        this.$emit('onFileSelected', file)
      } else {
        this.$emit('onFileRemoved', file)
      }
    },
    params: {
      handler: function (newVal) {
        this.requestParams = newVal;
      },
      deep: true,
    }
  }
}
</script>

<style scoped>

</style>
