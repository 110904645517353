<template>
  <div class="scouting-camera-image" :class="containerClass">
    <img src="@/assets/images/icons/close-red.svg" class="remove-icon" @click="remove"/>
    <img :src="data.image" v-if="data.image" @click="show" class="image-icon"/>
  </div>
</template>

<script>
export default {
  name: 'CameraImage',
  props: ['data', 'containerClass'],
  data() {
    return {
      imageData: this.data ?? null,
    }
  },
  methods: {
    remove() {
      this.$emit('onRemove', this.imageData);
    },
    show() {
      this.$emit('onShow');
    }
  },
  watch: {
    data: {
      handler: function(newVal) {
        this.imageData = newVal;
      },
      deep: true,
    }
  }
}
</script>

<style scoped lang="scss">
.scouting-camera-image {
  position: relative;
  width: 48px;
  height: 48px;
  margin-right: 20px;

  .remove-icon {
    position: absolute;
    top: -7px;
    right: -7px;
    cursor: pointer;
    z-index: 99;
  }

  .image-icon {
    width: 48px;
    height: 48px;
    border-radius: 6px;
    z-index: 98;
    position: relative;
  }
}
</style>
