<!-- template for the modal component -->
<template type="x/template">
  <transition name="modal" id="import-settings-dialog">
    <div class="modal-mask" v-show="show">
      <div class="modal-container criteria-modal-container" @click.stop>
        <div class="modal-header criteria-modal-header">
          <h2>{{ $t("tools.statuses.edit_status") }}</h2>
        </div>
        <div class="modal-body criteria-modal-body">
          <div class="form-group col-lg-4">
            <label for="statusName">{{
              $t("tools.statuses.criteria_builder.status_name")
            }}</label>
            <input
              type="text"
              class="form-control"
              id="statusName"
              v-model="statusName"
              placeholder="Enter status name"
            />
          </div>
          <h3>{{ $t("tools.statuses.criteria_builder.title") }}</h3>
          <form @submit.prevent="preventCriteria">
            <div
              v-for="(group, groupIndex) in criteriaGroups"
              :key="groupIndex"
              class="criteria-group"
            >
              <h4>
                {{ $t("tools.statuses.criteria_builder.group") }}
                {{ groupIndex + 1 }}
              </h4>
              <div
                v-for="(criterion, index) in group.criteria"
                :key="index"
                class="row criterion margin_criteria"
              >
                <div class="col-lg-3" style="padding-left: 0;">
                  <multiselect
                    v-model="criterion.column"
                    :options="columns"
                    placeholder="Select column"
                    label="label"
                    track-by="value"
                    :reduce="(column) => column.value"
                  ></multiselect>
                </div>
                <div class="col-lg-2">
                  <multiselect
                    v-model="criterion.operator"
                    :options="operators"
                    placeholder="Select operator"
                    label="label"
                    track-by="value"
                    :reduce="(operator) => operator.value"
                  ></multiselect>
                </div>
                <div v-if="!criterion.isPercentageComparison" class="col-lg-4">
                  <input
                    class="form-control"
                    v-model="criterion.value"
                    placeholder="Enter value"
                    :type="
                      criterion.column?.value_type === 'number'
                        ? 'number'
                        : 'text'
                    "
                  />
                </div>
                <div v-if="criterion.isPercentageComparison" class="col-lg-4">
                  <div class="row">
                    <div class="col-lg-8" style="padding-top: 6px">
                      <multiselect
                        v-model="criterion.compareColumn"
                        :options="columns"
                        placeholder="Select column to compare"
                        label="label"
                        track-by="value"
                        :reduce="(column) => column.value"
                      ></multiselect>
                    </div>
                    <div class="col-lg-4">
                      <div class="row">
                        <div class="col-lg-10">
                          <input
                            class="form-control"
                            v-model="criterion.percentage"
                            placeholder="Enter percentage"
                            type="number"
                          />
                        </div>
                        <div
                          class="col-lg-2"
                          style="padding-left: 0px; padding-top: 16px"
                        >
                          <label>%</label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-2">
                  <div class="row">
                    <div class="col-lg-5">
                      <button
                        class="btt_remove"
                        @click="removeCriterion(groupIndex, index)"
                      >
                        {{
                          $t("tools.statuses.criteria_builder.remove_criteria")
                        }}
                      </button>
                    </div>
                    <div class="col-lg-7">
                      <multiselect
                        v-if="index < group.criteria.length - 1"
                        v-model="criterion.logicalOperator"
                        :options="logicalOperators"
                        placeholder="Select logical operator"
                        label="value"
                        track-by="value"
                        :reduce="(logicalOperator) => logicalOperator.value"
                      ></multiselect>
                    </div>
                  </div>
                </div>
              </div>
              <button
                @click="addCriterion(groupIndex)"
                class="btt_add margin_criteria"
              >
                {{ $t("tools.statuses.criteria_builder.add_criteria") }}
              </button>
              <div class="row">
                <div class="form-inline" style="margin-top: 20px; width: 250px">
                  <button class="btt_remove btt_add_group" @click="removeGroup(groupIndex)">
                    {{ $t("tools.statuses.criteria_builder.remove_group") }}
                  </button>
                </div>
                <div class="form-inline multiselect-width">
                  <multiselect
                    v-if="groupIndex < criteriaGroups.length - 1"
                    v-model="group.logicalOperator"
                    :options="logicalOperators"
                    placeholder="Select logical operator"
                    label="value"
                    track-by="value"
                    :reduce="(logicalOperator) => logicalOperator.value"
                    class="group_logical_operator"
                  ></multiselect>
                </div>
              </div>
            </div>
            <button class="btt_add btt_add_group" @click="addGroup">
              {{ $t("tools.statuses.criteria_builder.add_group") }}
            </button>
          </form>
          <div class="mt-3"></div>
          <div style="width: 80%">
            <h3>
              {{ $t("tools.statuses.criteria_builder.generated_criteria") }}
            </h3>
            <button style="margin-right: 10px" @click="toggleCriteriaString">
              {{ showCriteriaString ? "Hide" : "Show" }}
            </button>
            <label v-if="showCriteriaString">{{ criteriaString }}</label>
          </div>
          <div style="height: 80px"></div>
        </div>
        <div class="modal-footer criteria-modal-footer">
          <div class="justify-content-end" style="margin-bottom: 0px">
            <button
              class="btn btn-success"
              style="margin-right: 15px"
              @click="saveCriteria()"
            >
              {{ $t("common.save") }}
            </button>
            <button
              class="modal-default-button btn btn-secondary ml-3"
              @click="close()"
            >
              {{ $t("common.close") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import {
  successMessage,
  errorMessageList,
  errorMessage,
} from "~/helpers/common";
import { mapActions } from "vuex";
import Multiselect from "vue-multiselect";

export default {
  components: { Multiselect },
  props: {
    columns: {
      type: Array,
      required: true,
    },
    status: {
      type: Object,
      default: null,
    },
    show: {
      type: Boolean,
      required: true,
    },
  },
  name: "CriteriaBuilderDialog",
  data() {
    return {
      statusName: "",
      operators: [
        { value: ">", label: "Greater than" },
        { value: "<", label: "Less than" },
        { value: "==", label: "Equal to" },
        { value: "!=", label: "Not equal to" },
        { value: ">=", label: "Greater than or equal to" },
        { value: "<=", label: "Less than or equal to" },
        { value: "contains", label: "Contains", isStringComparison: true },
        {
          value: "not_contains",
          label: "Not contains",
          isStringComparison: true,
        },
        {
          value: ">",
          label: "Greater than by percentage",
          isPercentageComparison: true,
        },
      ],
      logicalOperators: [
        { value: "AND", label: "AND" },
        { value: "OR", label: "OR" },
      ],
      showCriteriaString: false,
      criteriaGroups: [],
    };
  },
  computed: {
    criteriaString() {
      return this.criteriaGroups
        .map((group, groupIndex) => {
          const groupString = group.criteria
            .map((criterion, index) => {
              let expression;
              if (criterion.operator.value === "contains") {
                expression = `(str_contains(strtolower($${criterion.column.value}), strtolower('${criterion.value}')))`;
              } else if (criterion.operator.value === "not_contains") {
                expression = `(!str_contains(strtolower($${criterion.column.value}), strtolower('${criterion.value}')))`;
              } else if (criterion.isPercentageComparison) {
                expression = `($${criterion.column.value} ${criterion.operator.value} $${criterion.compareColumn.value} * (${criterion.percentage} / 100))`;
              } else {
                const value =
                  criterion?.column?.value_type === "number"
                    ? criterion.value
                    : `'${criterion.value}'`;
                expression = `($${criterion.column.value} ${criterion.operator.value} ${value})`;
              }
              if (index < group.criteria.length - 1) {
                return `${expression} ${criterion.logicalOperator.value}`;
              }
              return expression;
            })
            .join(" ");
          if (groupIndex < this.criteriaGroups.length - 1) {
            return `(${groupString})${group.logicalOperator.value}`;
          }
          return `(${groupString})`;
        })
        .join("");
    },
  },
  methods: {
    ...mapActions({
      updateStatus: "statuses/updateStatus",
    }),
    addGroup() {
      this.criteriaGroups.push({
        criteria: [],
        logicalOperator: this.logicalOperators[0],
      });
    },
    addCriterion(groupIndex) {
      this.criteriaGroups[groupIndex].criteria.push({
        column: "",
        operator: "",
        value: "",
        logicalOperator: this.logicalOperators[0],
        isPercentageComparison: false,
        compareColumn: "",
        percentage: 0,
      });
    },
    removeCriterion(groupIndex, index) {
      this.criteriaGroups[groupIndex].criteria.splice(index, 1);
    },
    removeGroup(groupIndex) {
      this.criteriaGroups.splice(groupIndex, 1);
    },
    preventCriteria() {
      // Prevent the form from being submitted
    },
    toggleCriteriaString() {
      this.showCriteriaString = !this.showCriteriaString;
    },
    async saveCriteria() {
      const new_criteria = this.criteriaString;
      if (new_criteria.includes(undefined) || new_criteria.includes(null)) {
        errorMessage(this, "Criteria contains invalid values.");
        return false;
      }

      const changes = {
        uuid: this.status.uuid,
        criteria: new_criteria,
        name: this.statusName,
      };

      try {
        const response = await this.updateStatus(changes);
        successMessage(this, response.message);
        this.status.criteria = new_criteria;
        this.status.name = this.statusName;
        this.close();
        return true;
      } catch (e) {
        errorMessageList(this, e.errors);
        return false;
      }
    },
    parseCriteriaString(criteriaString) {
      const criteria = [];
      const stack = [];
      let currentExpression = "";
      let currentLogicalOperator = null;
      let depth = 0;

      for (let i = 0; i < criteriaString.length; i++) {
        const char = criteriaString[i];

        if (char === "(") {
          if (depth === 0 && currentExpression) {
            if (currentLogicalOperator && stack.length) {
              stack[stack.length - 1].logicalOperator = currentLogicalOperator;
            }
            currentExpression = "";
            currentLogicalOperator = null;
          }
          depth++;
          currentExpression += char;
        } else if (char === ")") {
          depth--;
          currentExpression += char;
          if (depth === 0) {
            stack.push({
              expression: currentExpression.trim(),
              logicalOperator: currentLogicalOperator,
            });
            currentExpression = "";
            currentLogicalOperator = null;
          }
        } else if (
          depth === 0 &&
          (criteriaString.slice(i, i + 3) === "AND" ||
            criteriaString.slice(i, i + 2) === "OR")
        ) {
          currentLogicalOperator =
            criteriaString.slice(i, i + 3) === "AND" ? "AND" : "OR";
          i += currentLogicalOperator.length - 1;
        } else {
          currentExpression += char;
        }
      }

      if (currentExpression) {
        stack.push({
          expression: currentExpression.trim(),
          logicalOperator: currentLogicalOperator,
        });
      }

      stack.forEach(({ expression, logicalOperator }) => {
        const innerMatch = expression.match(/^\((.*)\)$/);
        if (innerMatch) {
          expression = innerMatch[1];
        }

        let columnValue,
          operatorValue,
          rawValue,
          compareColumnValue,
          percentage;
        let isPercentageComparison = false;

        if (expression.includes("* (")) {
          // Percentage comparison
          isPercentageComparison = true;
          const percentageMatch = expression.match(
            /([^\s]+)\s+([^\s]+)\s+([^\s]+)\s+\*\s+\(([^\s]+)\s+\/\s+100\)/
          );
          if (percentageMatch) {
            [columnValue, operatorValue, compareColumnValue, percentage] =
              percentageMatch.slice(1);
          } else {
            console.error(
              "Failed to parse percentage comparison expression:",
              expression
            );
            errorMessage(
              this,
              "Failed to parse percentage comparison expression."
            );
            return [];
          }
        } else if (expression.includes("!str_contains")) {
          // String comparison (not contains)
          const containsMatch = expression.match(
            /str_contains\(strtolower\(([^)]+)\),\s*strtolower\('([^']+)'\)\)/
          );
          if (containsMatch) {
            [columnValue, rawValue] = containsMatch.slice(1);
            operatorValue = "not_contains";
          } else {
            console.error("Failed to parse contains expression:", expression);
            errorMessage(this, "Failed to parse contains expression.");
            return [];
          }
        } else if (expression.includes("str_contains")) {
          // String comparison (contains)
          const containsMatch = expression.match(
            /str_contains\(strtolower\(([^)]+)\),\s*strtolower\('([^']+)'\)\)/
          );
          if (containsMatch) {
            [columnValue, rawValue] = containsMatch.slice(1);
            operatorValue = "contains";
          } else {
            console.error("Failed to parse contains expression:", expression);
            errorMessage(this, "Failed to parse contains expression.");
            return [];
          }
        } else {
          // Regular comparison
          const match = expression.match(/([^\s]+)\s+([^\s]+)\s+(.+)/);
          if (match) {
            [columnValue, operatorValue, rawValue] = match.slice(1);
          } else {
            console.error("Failed to parse expression:", expression);
            errorMessage(this, "Failed to parse expression.");
            return [];
          }
        }

        if (columnValue.startsWith("$")) {
          columnValue = columnValue.slice(1);
        }
        if (compareColumnValue && compareColumnValue.startsWith("$")) {
          compareColumnValue = compareColumnValue.slice(1);
        }

        const column = this.columns.find((col) => col.value === columnValue);
        const operator = this.operators.find(
          (op) =>
            op.value === operatorValue &&
            (op.isPercentageComparison ?? false) == isPercentageComparison
        );
        const value =
          rawValue && rawValue.startsWith("'") && rawValue.endsWith("'")
            ? rawValue.slice(1, -1)
            : rawValue;
        const logicalOperatorObj =
          this.logicalOperators.find((op) => op.value === logicalOperator) ||
          this.logicalOperators[0];

        criteria.push({
          column,
          operator,
          value,
          logicalOperator: logicalOperatorObj,
          isPercentageComparison,
          compareColumn: isPercentageComparison
            ? this.columns.find((col) => col.value === compareColumnValue)
            : "",
          percentage: isPercentageComparison ? parseFloat(percentage) : 0,
        });
      });

      return criteria;
    },
    parseCriteriaGroups(criteriaString) {
      if (criteriaString === null || criteriaString.trim() === "") {
        return [];
      }

      const groups = [];
      const splitCriteria = criteriaString.split(/(\)OR\(|\)AND\()/);
      splitCriteria.forEach((part, index) => {
        // This is a criteria group
        if (index % 2 === 0) {
          if (splitCriteria.length > 1) {
            if (index === 0) {
              part = part.replace(/^\(/, "");
            } else if (index === splitCriteria.length - 1) {
              part = part.replace(/\)$/, "");
            }
          } else {
            part = part.replace(/^\(/, "").replace(/\)$/, "");
          }

          const criteria = this.parseCriteriaString(part);
          let logicalOperatorObj = null;
          if (splitCriteria[index + 1]) {
            logicalOperatorObj =
              this.logicalOperators.find(
                (op) =>
                  op.value === splitCriteria[index + 1].replace(/[()]/g, "")
              ) || null;
          }

          groups.push({
            criteria,
            logicalOperator: logicalOperatorObj,
          });
        }
      });

      return groups;
    },
    close: function () {
      document.body.style.overflow = "";
      this.$emit("close");
      this.criteriaGroups = [];
      this.statusName = "";
    },
    setCriteria(newStatus) {
      if (newStatus && newStatus.criteria) {
        this.criteriaGroups = this.parseCriteriaGroups(newStatus.criteria);
      } else {
        this.criteriaGroups = [];
      }

      if (newStatus && newStatus.name) {
        this.statusName = newStatus.name;
      } else {
        this.statusName = "";
      }
    },
  },
  watch: {
    criteriaGroups: {
      handler(newCriteriaGroups) {
        newCriteriaGroups.forEach((criteriaGroup) => {
          criteriaGroup.criteria.forEach((criterion) => {
            criterion.isPercentageComparison =
              criterion.operator && criterion.operator.isPercentageComparison;
          });
        });
      },
      deep: true,
    },
    status: {
      handler(newStatus) {
        this.setCriteria(newStatus);
      },
      deep: true,
    },
    show(newVal) {
      if (newVal) {
        document.body.style.overflow = "hidden";
        this.setCriteria(this.status);
      }
    },
  },
};
</script>

<style scoped>
@import "vue-multiselect/dist/vue-multiselect.min.css";

.criterion {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.criterion > * {
  margin-right: 10px;
}

.criteria-group {
  margin-bottom: 20px;
}

.group_logical_operator {
  margin-top: 20px;
}

.margin_criteria {
  margin-left: 30px !important;
}

.btt_add_group {
  width: 160px;
}

.criteria-modal-container {
  display: flex;
  flex-direction: column;
  max-height: 800px;
  height: 90%;
  width: 90%;
  min-width: 950px;
}

.criteria-modal-header,
.criteria-modal-footer {
  height: 50px;
}

.criteria-modal-body {
  flex-grow: 1;
  overflow-y: auto;
  overflow-x: hidden;
}

.multiselect-width {
  width: 250px;
}

.btt_add {
  background-color: #4caf50;
  border: none;
  color: white;
  padding: 10px 24px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
}

.btt_remove {
  background-color: #f44336;
  border: none;
  color: white;
  padding: 10px 24px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  cursor: pointer;
}
</style>
