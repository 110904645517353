<template>
  <div class="view work-details">
    <card :title="title" id="details" v-if="canEdit || canDelete">
    <span class="action_buttons">
      <img src="~@/assets/images/desktop/icons/controls/plus.svg" class="icon-svg" :title="$t('common.create_new', { name: name })" @click="newObject()"/>
    </span>
      <div class="row mb-3">
        <div class="form-group col-sm-12 col-lg-3 text-left">
          <label class="control-label">{{ $t('salary_calculator.employee.title') }}</label>
          <v-select
            v-model="employee"
            :class="{ 'field-error' : this.$v.data.employee.uuid.$error }"
            :placeholder="$t('salary_calculator.employee.search')"
            autocomplete="off"
            @option:selected="onEmployeeSelected"
            ref="employees"
            taggable
            :options="employee_list"></v-select>
        </div>
        <div class="col-lg-1 form-group pt-5" v-if="suggestEmployee">
          <a v-on:click.prevent="goto('/salary-calculator/sc-employee/add', data.employee.name)" class="c-pointer d-inline-block">
            <img src="~@/assets/images/desktop/icons/controls/plus.svg" class="icon-svg"/>{{ $t('common.add')}}</a>
        </div>
      </div>
      <div class="card panel mb-3 col-lg-6 p-0">
        <div class="card-header">
          <div class="row">
            <div class="col-lg-6">
              {{ $t('salary_calculator.work_log.main_shift') }}
            </div>
            <div class="col-lg-6 text-right pr-2 text-secondary">
              {{ shiftTimestamp.hour}}:{{ shiftTimestamp.minute }}
            </div>
          </div>
        </div>
        <div class="card-body text-secondary">
          <div class="row">
            <div class="form-group" :class="pickerStyle">
              <div class="form-group control-label">
                {{$t("common.work_date")}}
                <p v-if="isMultipleDateSelector" class="subtitle">
                  {{ $t('salary_calculator.work_log.select_multiple_dates') }}
                </p>
              </div>
              <date-picker2
                v-model="work_date"
                :value-type="work_date_input"
                format="YYYY-MM-DD"
                type="date"
                :multiple="isMultipleDateSelector"
                placeholder="yyyy-mm-dd"
                :class="{ 'field-error' : this.$v.data.work_date.$error }"
                :input-class="['date-picker-style']"
                @change="onWorkDateChanged"
                ref="work_date"
              ></date-picker2>
            </div>
            <div class="form-group col-sm-6">
              <label class="control-label pb-2">{{$t("salary_calculator.work_log.fill_with_shift")}}</label>
              <v-select
                v-model="shift"
                class=""
                placeholder="-"
                autocomplete="off"
                taggable
                :options="shift_list"></v-select>
            </div>
          </div>
          <div class="row">
            <div class="form-group col-lg-2 text-left">
              <label class="control-label">{{ $t('salary_calculator.shift.start_hour') }}</label>
              <v-select
                v-model="start_hour"
                class=""
                placeholder="-"
                autocomplete="off"
                :class="{ 'field-error' : this.$v.data.start_hour.$error }"
                @option:selected="onStartHourSelected"
                taggable
                :options="hours_list"></v-select>
            </div>
            <div class="form-group col-lg-2 text-left">
              <label class="control-label">{{ $t('salary_calculator.shift.minute') }}</label>
              <v-select
                v-model="start_minute"
                placeholder="-"
                autocomplete="off"
                :class="{ 'field-error' : this.$v.data.start_minute.$error }"
                @option:selected="onStartMinuteSelected"
                taggable
                :options="minutes_list"></v-select>
            </div>
          </div>
          <div class="row">
            <div class="form-group col-lg-2 text-left">
              <label class="control-label">{{ $t('salary_calculator.shift.end_hour') }}</label>
              <v-select
                v-model="end_hour"
                class=""
                placeholder="-"
                autocomplete="off"
                :class="{ 'field-error' : this.$v.data.end_hour.$error }"
                @option:selected="onEndHourSelected"
                taggable
                :options="hours_list"></v-select>
            </div>
            <div class="form-group col-lg-2 text-left">
              <label class="control-label">{{ $t('salary_calculator.shift.minute') }}</label>
              <v-select
                v-model="end_minute"
                placeholder="-"
                autocomplete="off"
                :class="{ 'field-error' : this.$v.data.end_minute.$error }"
                @option:selected="onEndMinuteSelected"
                taggable
                :options="minutes_list"></v-select>
            </div>
          </div>
        </div>
      </div>

      <div class="single-work-panel" v-if="data.uuid">
        <div class="row">
          <div class="form-group col-lg-6 pr-0 text-left">
            <label class="control-label">{{ $t('salary_calculator.work.title') }}</label>
            <v-select
              v-model="work"
              :class="{ 'field-error' : this.$v.data.work.uuid.$error }"
              :placeholder="$t('salary_calculator.work.search')"
              autocomplete="off"
              @option:selected="onWorkSelected"
              ref="works"
              taggable
              :options="work_ddl_list"></v-select>
          </div>
        </div>
      </div>
      <div class="card panel mb-3 mt-4 col-lg-6 p-0" v-else>
        <div class="card-header">
          <div class="row">
            <div class="col">
              Add works
            </div>
            <div class="col text-right">
              <span :class="{
                'text-danger': (currentHours < totalHours) || currentHours === 0 && totalHours === 0,
                'text-success': currentHours === totalHours
              }">
                {{ currentHours }}
              </span>/
              <span :class="{'text-success': totalHours > 0, 'text-danger': totalHours === 0}">{{ totalHours }}</span>
              <span class="text-secondary">{{ $t('common.hoursm') | lowerCase }}</span>
            </div>
          </div>
          <div class="row" v-if="totalHours <= 0">
            <div class="col-lg-12">
              <span class="text-danger fs10">{{ $t('salary_calculator.work_log.errors.select_shift_first') }}</span>
            </div>
          </div>
        </div>
        <div class="card-body text-secondary">
          <work-log-list
            :data="work_list"
            ref="workLogList"
            @onWorkHoursChanged="onWorkHoursChanged"
            @onItemChanged="onItemChanged"
            :total-hours="totalHours"
          ></work-log-list>
        </div>
      </div>

      <agro-action-buttons
        :data="(data && Array.isArray(data) ? data[0] : data)"
        :can-edit="canEdit"
        :can-delete="canDelete"
        @onSave="save"
        @onSaveAndNew="saveAndNew"
        :save-and-new="true"
        @onDelete="confirmDelete"
        @onGotoList="gotoList"
      ></agro-action-buttons>
    </card>
    <div v-else>
      <forbidden></forbidden>
    </div>
  </div>
</template>
<script>
import {mapActions, mapGetters} from "vuex";
import {canDelete, canEdit, permissions, permissionTypes} from "@/helpers/Permissions";
import {
  buildAutocompleteOptions,
  buildDefaultAutocompleteList,
  convertArrayToNameVal,
} from "@/helpers/common";
import {required, maxLength, maxValue, requiredIf} from "vuelidate/lib/validators";
import VueBootstrapTypeahead from 'vue-bootstrap-typeahead'
import DatePicker2 from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import {convertHoursToTimestamp, getHours, getMinutes, today} from '@/helpers/dateHelper';
import moment from 'moment'
import AgroActionButtons from "@/components/AgroActionButtons/index";
import Forbidden from "@/pages/errors/Forbidden";
import * as actions from "@/helpers/generalActions";
import WorkLogList from "@/pages/salary-calculator/sc-work-log/partials/WorkLogList/List";
import {settings} from "@/helpers/settings";

export default {
  name: 'SCWorkLogDetails',
  data() {
    return {
      data: this.scWorkLog ? this.scWorkLog : {},
      selectedMeasureUnitUUID: null,
      selectedWorkGroupUUID: null,
      work_date: null,
      work_date_input: null,
      employee: undefined,
      work: undefined,
      start_hour: '',
      end_hour: '',
      start_minute: '',
      end_minute: '',
      suggestEmployee: false,
      suggestWork: false,
      shift: undefined,
      total_hours: 0,
      current_hours: 0,
      dates: undefined,
      settings: this.groupSettings ? this.groupSettings: {},
      setting_code: settings.SALARY_CALCULATOR.CODE,
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
      workLog: 'scWorkLog/scWorkLog',
      employees: 'scEmployee/scEmployees',
      works: 'scWorkPivot/scWorkPivots',
      shifts: 'scShift/scShifts',
      groupSettings: 'settings/groupSettings'
    }),
    name() {
      return this.$t('salary_calculator.work_log.title');
    },
    config() {
      return {
        permission: permissions.SC_WORK_LOG,
        permission_type: permissionTypes.module,
        get: this.get,
        create: this.create,
        update: this.update,
        delete: this.delete,
        list_url: '/salary-calculator/sc-work-log',
        confirm_delete: true,
      }
    },
    actions() {
      return actions;
    },
    canEdit() {
      return canEdit(this);
    },
    canDelete() {
      return canDelete(this);
    },
    title() {
      return (this.data.uuid ? this.$t('common.edit')+' '+this.name : this.$t('common.add_new')+' '+this.name);
    },
    employee_list() {
      return buildDefaultAutocompleteList(this.employees);
    },
    work_ddl_list() {
      return buildDefaultAutocompleteList(this.works);
    },
    work_list() {
        if(this.data.works && this.data.works.length > 0) {
          return this.data.works;
        }

        return [{ work_hours: 0 }];
    },
    hours_list() {
      return buildAutocompleteOptions(
        convertArrayToNameVal(getHours(), '0', 2),
        {
          uuid: 'uuid',
          name: 'name',
        }, {
          value: null,
          text: '0:00',
        });
    },
    minutes_list() {
      return buildAutocompleteOptions(
        convertArrayToNameVal(getMinutes(), '0', 2),
        {
          uuid: 'uuid',
          name: 'name',
        }, {
          value: null,
          text: '0:00',
        });
    },
    shift_list() {
      return buildDefaultAutocompleteList(this.shifts);
    },
    currentHours() {
        return this.current_hours;
    },
    totalHours() {
        return this.total_hours;
    },
    times() {
      return `${this.start_hour}|${this.start_minute}|${this.end_hour}|${this.end_minute}`;
    },
    shiftTimestamp() {
      return convertHoursToTimestamp(this.totalHours);
    },
    isMultipleDateSelector() {
      if(this.data.uuid) return false;

      return this.groupSettings?.items?.find(s => s.code ===
          settings.SALARY_CALCULATOR.MULTI_DAY_FUNCTION
      ).value === '1';
    },
    pickerStyle(){
      if(this.isMultipleDateSelector) {
          return [
            'col-sm-12',
            'col-lg-12',
            'datepicker-size'
          ]
      }

      return [
        'col-sm-6',
        'col-lg-4'
      ]
    }
  },
  methods: {
    ...mapActions({
      get: 'scWorkLog/getSCWorkLog',
      create: 'scWorkLog/createSCWorkLog',
      update: 'scWorkLog/updateSCWorkLog',
      delete: 'scWorkLog/deleteSCWorkLog',
      getEmployees: 'scEmployee/getSCEmployees',
      getWorks: 'scWorkPivot/getSCWorkPivots',
      getShifts: 'scShift/getSCShifts',
      getGroupByCode: 'settings/getGroupByCode'
    }),
    loadData() {
      let vm = this;
      return this.actions.getData(this, () => {}, (response) => {
        vm.employee = response.employee ? {
          label: response.employee.name
        } : '';

        vm.work = response.work ? {
          label: response.work.name,
        } : '';

        vm.work_date = response.work_date;
        vm.fillHours(response.work_started_at, response.work_ended_at);

            setTimeout(function() {
              vm.suggestEmployee = false;
              vm.suggestWork = false;
            }, 100);
          });
    },
    fillHours(work_started_at, work_ended_at) {
      let vm = this;
      vm.start_hour = String(
        moment(work_started_at).format("H")
      ).padStart(2, '0');
      vm.onStartHourSelected({ label: vm.start_hour, value: vm.start_hour });

      vm.start_minute = String(
        moment(work_started_at).format("m")
      ).padStart(2, '0');
      vm.onStartMinuteSelected({ label: vm.start_minute, value: vm.start_minute });

      vm.end_hour = String(
        moment(work_ended_at).format("H")
      ).padStart(2, '0');
      vm.onEndHourSelected({ label: vm.end_hour, value: vm.end_hour });

      vm.end_minute = String(
        moment(work_ended_at).format("m")
      ).padStart(2, '0');
      vm.onEndMinuteSelected({ label: vm.end_minute, value: vm.end_minute });
    },
    collectDates() {
        let startDate = undefined;
        let endDate = undefined;

        if(Array.isArray(this.data.work_date) && this.data.work_date.length > 0) {
          this.data.work_dates = this.data.work_date;
          this.data.work_date = this.data.work_date[0];
        }

        if(this.data.work_date && this.data.start_hour && this.data.start_minute) {
          startDate = this.data.work_date+' '+this.data.start_hour+':'+this.data.start_minute;
        }

        if(this.data.work_date && this.data.end_hour && this.data.end_minute) {
          endDate = this.data.work_date+' '+this.data.end_hour+':'+this.data.end_minute;
        }

        if(startDate && endDate) {
          return {
            start_date: startDate,
            end_date: endDate,
          };
        }

        return null;
    },
    calculateHours() {
        let dates = this.collectDates();
        this.dates = dates;

        if(dates) {
          let duration = moment.duration(moment(dates.end_date).diff(
            moment(dates.start_date)
          ));

          this.total_hours = (duration.asMinutes() / 60).toFixed(2);
          return this.total_hours;
        }

        this.total_hours = 0;
        return 0;
    },
    gotoList() {
      return actions.gotoList(this);
    },
    confirmDelete() {
      return this.actions.confirmDelete(this);
    },
    save() {
      this.$v.data.$touch();
      if(this.data.uuid) {
        return this.actions.save(this, this.config.list_url);
      } else {
        if(this.$refs['workLogList'].validate()) {
          this.$set(this.data, 'works', this.$refs['workLogList'].getItems());
          this.$refs['workLogList'].resetValidation();

          if(this.data.work_dates && Array.isArray(this.data.work_dates)) {
              this.data.work_date = this.data.work_dates.join();
          }

          return this.actions.save(this, this.config.list_url);
        }
      }
    },
    saveAndNew() {
      this.$v.data.$touch();
      if(this.data.uuid) {
        return this.actions.saveAndNew(this, 'add');
      } else {
        if(this.$refs['workLogList'].validate()) {
          this.$set(this.data, 'works', this.$refs['workLogList'].getItems());
          this.$refs['workLogList'].resetValidation();

          if(this.data.work_dates && Array.isArray(this.data.work_dates)) {
            this.data.work_date = this.data.work_dates.join();
          }

          return this.actions.saveAndNew(this, 'add');
        }
      }
    },
    newObject() {
      return this.actions.newObject(this);
    },
    goto(path, name) {
      return actions.goto(this, path, name);
    },
    onEmployeeSelected(data) {
      this.$set(this.data, 'employee', {
        uuid: data.value,
        name: data.label,
      });
      this.$set(this.data, 'employee_uuid', data.value);
      this.suggestEmployee = false;
    },
    onWorkSelected(data) {
      this.$set(this.data, 'work', {
        uuid: data.value,
        name: data.label,
      });
      this.$set(this.data, 'work_uuid', data.value);
      this.suggestWork = false;
    },
    onWorkDateChanged(date) {
      this.$set(this.data, 'work_date', date);
      this.calculateHours();
    },
    onStartHourSelected(data) {
      this.data.start_hour = this.findUUID(data, this.hours_list);
      this.calculateHours();
    },
    onStartMinuteSelected(data) {
      this.data.start_minute = this.findUUID(data, this.minutes_list);
      this.calculateHours();
    },
    onEndHourSelected(data) {
      this.data.end_hour = this.findUUID(data, this.hours_list);
      this.calculateHours();
    },
    onEndMinuteSelected(data) {
      this.data.end_minute = this.findUUID(data, this.minutes_list);
      this.calculateHours();
    },
    findUUID(data, list) {
      if(data.label && !data.value) {
        let found = list.find(h => h.label === data.label);

        if(found) {
          return found.value;
        }
      }

      return data.value;
    },
    onWorkHoursChanged(hours) {
        this.current_hours = hours;
    },
    onItemChanged(item) {

    },
    loadSettings() {
        this.getGroupByCode({
          code: this.setting_code
        })
    }
  },
  components: {
    WorkLogList,
    VueBootstrapTypeahead,
    DatePicker2,
    AgroActionButtons,
    Forbidden
  },
  mounted() {
    this.loadSettings();
    this.getEmployees({
      limit: 9999,
    });
    this.getWorks({
      limit: 9999,
      show_all: true,
    });
    this.getShifts({
      limit: 9999
    });
    this.loadData();
  },
  watch: {
    employee: {
      handler: function(newVal) {
        if(!newVal) {
          this.$set(this.data, 'employee', null);
          this.$set(this.data, 'employee_uuid', null);
        }
      },
      deep: true,
    },
    workLog: {
      handler: function(newVal) {
        this.data = newVal;
      },
      deep: true,
    },
    shift: {
      handler: function(newVal) {
        if(newVal) {
          let shift = Object.assign([], this.shifts).find(s => s.uuid === newVal.value);

          if(shift) {
            this.fillHours(today()+' '+shift.start_hour, today()+' '+shift.end_hour);
          }
        }
      },
      deep: true,
    },
    work: {
      handler: function(newVal) {
        if(!newVal) {
          this.data.work = null;
          this.data.work_uuid = null;
        }
      },
      deep: true,
    },
    start_hour: function(newVal) {
        this.data.start_hour = (newVal && newVal.value) ? newVal.value : newVal;
      this.calculateHours();
    },
    start_minute: function(newVal) {
      this.data.start_minute = (newVal && newVal.value) ? newVal.value : newVal;
      this.calculateHours();
    },
    end_hour: function(newVal) {
      this.data.end_hour = (newVal && newVal.value) ? newVal.value : newVal;
      this.calculateHours();
    },
    end_minute: function(newVal) {
      this.data.end_minute = (newVal && newVal.value) ? newVal.value : newVal;
      this.calculateHours();
    },
    groupSettings: {
      handler: function(newVal) {
        this.settings = newVal;
      },
      deep: true,
      immediate: true,
    },
  },
  validations(){
    let vm = this;
    return {
      data: {
        work_date: {
          required,
        },
        start_hour: {
          required,
          maxLengthValue: maxLength(2),
          maxValue: maxValue(23)
        },
        start_minute: {
          required,
          maxLengthValue: maxLength(2),
          maxValue: maxValue(59)
        },
        end_hour: {
          required,
          maxLengthValue: maxLength(2),
          maxValue: maxValue(23)
        },
        end_minute: {
          required,
          maxLengthValue: maxLength(2),
          maxValue: maxValue(59)
        },
        employee: {
          uuid: {
            required,
          },
        },
        work: {
          uuid: {
            required: requiredIf(function(obj) {
              return vm.uuid;
            })
          },
        },
      }
    }
  }
}
</script>

<style>
.mx-datepicker {
  width: 100%;
}
.subtitle {
  font-size: 12px;
  color: #333;
}
</style>
