<template>
  <div class="control-container">
    <div class="controls" :class="[controlClass]">
      <boolean-control :data="setting"
                       v-if="isBooleanType(setting)"
                       class="ml-lg-3"
                       ref="control"
                       @onChange="onSettingChanged"></boolean-control>
      <input-control :data="setting"
                     class="col-lg-6"
                     v-if="isInputType(setting)"
                     ref="control"
                     @onChange="onSettingChanged"></input-control>
      <list-control :data="setting"
                    class="col-lg-6"
                    v-if="isListType(setting)"
                    ref="control"
                    @onChange="onSettingChanged"></list-control>
    </div>
    <p v-if="setting.description"
       class="text-secondary setting-description ml-lg-3 pl-lg-3 pt-0">{{ setting.description }}</p>
  </div>
</template>

<script>
import BooleanControl from "@/pages/tools/settings/partials/controls/BooleanControl";
import InputControl from "@/pages/tools/settings/partials/controls/InputControl";
import ListControl from "@/pages/tools/settings/partials/controls/ListControl";

export default {
  name: 'ControlContainer',
  props: ['data', 'controlClass'],
  data() {
    return {
      input_types: ['string', 'integer', 'password', 'email', 'domain'],
      boolean_types: ['boolean'],
      list_types: ['select'],
      setting: this.data ? this.data : {},
    }
  },
  components: {
    ListControl,
    InputControl,
    BooleanControl
  },
  methods: {
    isBooleanType(setting) {
      return this.boolean_types.includes(setting.type);
    },
    isInputType(setting) {
      return this.input_types.includes(setting.type);
    },
    isListType(setting) {
      return this.list_types.includes(setting.type);
    },
    onSettingChanged(val) {
      this.setting.value = val;
      this.$emit('onSettingChanged', this.setting);
    },
    onChanged(val) {
      this.setting.value = val;
      this.$emit('onChanged', this.setting);
    },
    validate() {
      if(this.setting.validate instanceof Function) {
        return this.setting.validate();
      }
    }
  },
  watch: {
    data: {
      handler: function(newVal) {
        this.setting = newVal;
      },
      deep: true,
      immediate: true,
    },
  }
}
</script>

<style lang="scss" scoped>
  .setting-description {
    font-size: 12px;
  }
</style>
