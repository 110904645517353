<template>
    <div>
        <card title="Produse"></card>
        <div class="content-body pt-4">
            <div class="row">
                <div class="col-lg-12">
                    <DxDataGrid 
                        :ref="productGridRefKey"
                        :data-source="prodDataSurce"
                        :show-borders="false"
                        :remote-operations="gridRemoteOperations"
                        :element-attr="gridAttributes"
                    >
                        <DxColumn
                            data-field="name"
                            data-type="string"
                            caption="Denumire"
                            :editor-options="columnEditorOptions"
                        >
                            <DxStringLengthRule :min="1" message="Numele produsului este obligatoriu!"/>
                        </DxColumn>
                        <DxColumn
                            data-field="default_measure_unit.uuid"
                            caption="Unitate de masura"
                            :allowFiltering="false"
                            :allowSorting="false"
                        >
                            <DxLookup
                                :data-source="measureUnitsData"
                                value-expr="uuid"
                                display-expr="name"
                            />
                            <DxRequiredRule message="Unitatea de măsură este obligatorie!"/>
                        </DxColumn>
                        <DxPaging :total="3500" :page-size="15"/>
                        <DxPager
                            :visible="true"
                            :allowed-page-sizes="pageSizes"
                            display-mode="compact"
                            :show-page-size-selector="true"
                            :show-info="true"
                            :show-navigation-buttons="true"
                        />
                        <DxEditing
                            :allow-adding="true"
                            :allow-updating="true"
                            :allow-deleting="true"
                            :use-icons="true"
                            mode="row"
                        >
                            <DxTexts
                                confirm-delete-message="Sigur vreți să ștergeți produsul?",
                                confirm-delete-title="Confirmare ștergere"
                            />
                        </DxEditing>
                        <DxHeaderFilter :visible="true" />
                        <DxFilterRow :visible="true" apply-filter="auto"/>
                        <DxSelection mode="single" />
                    </DxDataGrid>
                </div>
            </div>
        </div>    
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import * as endpoints from '../../../store/api/endpoints'
import { DxDataGrid, DxColumn, DxPaging, DxPager, DxEditing, DxTexts, DxLookup, DxRequiredRule, DxStringLengthRule, DxHeaderFilter, DxFilterRow, DxSelection } from 'devextreme-vue/data-grid';
import {canDelete, canEdit, permissions, permissionTypes} from "@/helpers/Permissions";
import Forbidden from "~/pages/errors/Forbidden.vue";
import CustomStore from 'devextreme/data/custom_store';
import * as api from '../../../store/api/index'
import {getFilterDataByName, getSortByProperty, getSortDirection} from "@/helpers/dxGridHelper";

export default {
    name: "ArendaProducts",
    middleware: ['auth', 'check-company-admin'],
    data(){
        return {
            gridAttributes:{
                class: 'admin-grid'
            },
            productGridRefKey: 'product-grid',
            prodDataSurce: new CustomStore({
                key: 'uuid',
                async load(loadOptions){

                    let pageLimit = loadOptions.take;
                    let pageNumber = (loadOptions.skip + loadOptions.take)/pageLimit;
                    
                    let dataSource =  {
                        data:[],
                        totalCount:0
                    };
                    let payload = {
                        page: pageNumber,
                        limit: pageLimit,
                        sortBy: getSortByProperty(loadOptions) ?? 'uuid',
                        sortDirection: getSortDirection(loadOptions) ?? 'ASC',
                        name: getFilterDataByName(loadOptions, "name")
                    }
                    await api.fetchFull(endpoints.LEASE_CONTRACT_PRODUCTS, payload, null,(response) => {
                        dataSource.data = response.data.data;
                        dataSource.totalCount = response.data.pagination.total
                    });

                    return dataSource;
                },
                insert: this.insert,
                update: this.updateProduct,
                remove: this.deleteProduct
            }),
            gridRemoteOperations: {
                paging: true,
                filtering : true,
                sorting: true
            },
            products: [],
            pageSizes: [15, 25, 100],
            columnEditorOptions: { showClearButton: true, placeholder: "Denumire produs" },
            measureUnitsData: []
        };
    },
    components: {
        DxDataGrid,
        DxColumn,
        DxPaging,
        DxPager,
        DxEditing,
        DxTexts,
        DxLookup,
        DxRequiredRule,
        DxStringLengthRule,
        DxHeaderFilter,
        DxFilterRow,
        DxSelection,
        Forbidden
    },
    computed: {
        ...mapGetters({
            user: 'auth/user',
            users: 'user/users',
        }),
        canEdit() {
            return canEdit(this);
        },
        productGrid: function() {
            return this.$refs[productGridRefKey].instance;
        },
    },
    methods: {
        ...mapActions({
            getProducts: 'leasecontracts/getProducts',
            getProductByUUID: 'leasecontracts/getProductByUUID',
            createProduct: 'leasecontracts/createProduct',
            updateProductS: 'leasecontracts/updateProduct',
            deleteProduct: 'leasecontracts/deleteProduct',
            geMeasureUnits: 'leasecontracts/geMeasureUnits',
        }),
        async insert(obj){
            obj.default_measure_unit_uuid = obj.default_measure_unit.uuid;
            await this.createProduct(obj);
        },
        async updateProduct(key, obj){
            let oldObj = {
                uuid: key,
                name: '',
                default_measure_unit_uuid:''
            }
            obj.uuid = key;
            await this.getProductByUUID({product_uuid: obj.uuid}).then(async response => {
                oldObj.name = obj.name ? obj.name : response.data.name;
                oldObj.default_measure_unit_uuid = obj.default_measure_unit ? obj.default_measure_unit.uuid : response.data.default_measure_unit.uuid;
                await this.updateProductS(oldObj);
            });
            
        }
    },
    mounted(){
        this.geMeasureUnits().then(response => {
            this.measureUnitsData = response.data;
        });
    }
}

</script>


<style lang="scss">
    @import "@/assets/scss/components/dxAdminDatagrid.scss";
</style>
