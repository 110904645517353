<template>
  <DxTabPanel
      :height="420"
      :animation-enabled="true"
      :swipe-enabled="true"
  >
    <DxItem :title="$t('works.cultures.varieties')">
      <template #default="{ data: tab }">
        <VarietyGridTemplate
            :data="data.data"
        />
      </template>
    </DxItem>
    <DxItem :title="$t('works.cultures.bbch.name')">
      <template #default="{ data: tab }">
        <bbch-grid-template
            :data="data.data"
        />
      </template>
    </DxItem>
  </DxTabPanel>
</template>

<script>
import DxTabPanel, {DxItem} from "devextreme-vue/tab-panel";
import VarietyGridTemplate from "@/pages/works/cultures/partials/VarietyGridTemplate.vue";
import BbchGridTemplate from "@/pages/works/cultures/partials/BbchGridTemplate.vue";

export default {
  props: {
    data: Object,
  },
  components: {
    BbchGridTemplate,
    VarietyGridTemplate,
    DxTabPanel, DxItem,
  },
}
</script>
