const getters = {
  scWorkPivots: state => state.sc_work_pivots,
  scWorkCollectorHectareData: state => state.sc_work_collector_hectare_data,
  scWorkCollectorColumnSettings: state => state.sc_work_collector_column_settings,
  scWorkHierarchy: state => state.sc_work_hierarchy,
  scWorkPivot: state => state.sc_work_pivot,
  scWorkPivotFilters: state => state.sc_work_pivot_filters,
  scDailyGridColumns: state => state.sc_daily_grid_columns,
}

export default getters;
