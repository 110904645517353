import * as api from '../../api/index'
import * as endpoints from '../../api/endpoints'
import * as types from "../../mutation-types";

const actions = {
  getSCWorkGroups({commit}, payload) {
    return api.fetch(endpoints.SC_WORK_GROUP, payload, commit,(response) => {
      commit(types.SET_SC_WORK_GROUPS, response.data.data);
      commit("common/"+types.SET_PAGINATION, response.data.pagination, { root: true });
    });
  },
  getSCWorkGroup({commit}, payload) {
    return api.fetch(endpoints.SC_WORK_GROUP+'/'+payload.uuid, payload, commit,(response) => {
      commit(types.SET_SC_WORK_GROUP, response.data);
    });
  },
  createSCWorkGroup({commit}, payload) {
    return api.post(endpoints.SC_WORK_GROUP, payload, commit, (response) => {
      commit(types.SET_SC_WORK_GROUP, response.data);
    });
  },
  updateSCWorkGroup({commit}, payload) {
    return api.put(endpoints.SC_WORK_GROUP+'/'+payload.uuid, payload, commit, (response) => {
      commit(types.UPDATE_SC_WORK_GROUP_ITEM, response.data);
    });
  },
  deleteSCWorkGroup({commit}, payload) {
    return api.remove(endpoints.SC_WORK_GROUP+'/'+payload.uuid, payload, commit, (response) => {
      commit(types.DELETE_SC_WORK_GROUP_ITEM, response.data);
    });
  },
}

export default actions;
