<template>
  <card :title="title" id="details" v-if="canEdit || canDelete">
    <span class="action_buttons">
      <img src="~@/assets/images/desktop/icons/controls/plus.svg" class="icon-svg" :title="$t('common.create_new', { name: name })" @click="newObject()"/>
    </span>
    <div class="row">
      <div class="form-group col-sm-12 col-lg-8 text-left">
        <label class="control-label">{{ name+' '+$t('common.name') }}</label>
        <input type="text" v-model="data.name" :placeholder='$t("common.name")'
               :class="{ 'field-error' : this.$v.data.name.$error }"
               class="form-control"/>
      </div>
    </div>
    <div class="row">
      <div class="form-group col-sm-6 col-lg-2">
        <label class="control-label">{{$t("salary_calculator.employee_role.name")}}</label>
        <b-form-select
          v-model="data.employee_role_uuid"
          class="form-control"
          :options="employee_role_list"></b-form-select>
      </div>
    </div>
    <div class="row">
      <div class="form-group col-sm-12 col-lg-2 text-left">
        <label class="control-label">{{ $t('salary_calculator.employee_role.salary') }} ({{ $currency.name | lowerCase }})</label>
        <input type="text" v-model="data.salary" :placeholder='$t("salary_calculator.employee_role.salary")'
               v-on:keydown="onlyPrice"
               class="form-control"/>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-6 col-lg-2">
        <label class="control-label">{{$t("common.user")}}</label>
        <b-form-select
          v-model="data.user_uuid"
          class="form-control"
          :options="user_list"></b-form-select>
      </div>
    </div>
    <agro-action-buttons
      :data="data"
      :can-edit="canEdit"
      :can-delete="canDelete"
      @onSave="save"
      @onSaveAndNew="saveAndNew"
      :save-and-new="true"
      @onDelete="confirmDelete"
      @onGotoList="gotoList"
      ></agro-action-buttons>
  </card>
  <div v-else>
    <forbidden></forbidden>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import {buildDefaultDropdownList, buildDropdownOptions, only_price} from '@/helpers/common'
import required from "vuelidate/src/validators/required";
import {canDelete, canEdit, permissions, permissionTypes} from "@/helpers/Permissions";
import * as actions from '@/helpers/generalActions'
import AgroActionButtons from "@/components/AgroActionButtons/index";
import Forbidden from "@/pages/errors/Forbidden";

export default {
  name: 'SCEmployeeDetails',
  components: {Forbidden, AgroActionButtons},
  data() {
    return {
      data: {
        backPath: undefined,
      },
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
      users: 'user/users',
      employeeRoles: 'scEmployeeRole/scEmployeeRoles',
    }),
    name() {
        return this.$t('salary_calculator.employee.title')
    },
    config() {
      return {
        permission: permissions.SC_EMPLOYEE,
        permission_type: permissionTypes.module,
        get: this.get,
        create: this.create,
        update: this.update,
        delete: this.delete,
        list_url: '/salary-calculator/sc-employee',
        confirm_delete: true,
      }
    },
    actions() {
      return actions;
    },
    canEdit() {
      return canEdit(this);
    },
    canDelete() {
      return canDelete(this);
    },
    title() {
      return (this.data.uuid ? this.$t('common.edit') : this.$t('common.new'))+' '+this.name;
    },
    user_list() {
      return buildDefaultDropdownList(this.users);
    },
    employee_role_list() {
      return buildDefaultDropdownList(this.employeeRoles);
    },
  },
  methods: {
    ...mapActions({
      getUsers: 'user/getUsers',
      getEmployeeRoles: 'scEmployeeRole/getSCEmployeeRoles',
      get: 'scEmployee/getSCEmployee',
      create: 'scEmployee/createSCEmployee',
      update: 'scEmployee/updateSCEmployee',
      delete: 'scEmployee/deleteSCEmployee'
    }),
    onlyPrice(event) {
      return only_price(event);
    },
    loadData() {
      let vm = this;
      return this.actions.getData(this, (data) => {
        vm.$set(vm.data, 'employee_role_uuid', null);
        vm.$set(vm.data, 'user_uuid', null);
      });
    },
    gotoList() {
      return actions.gotoList(this);
    },
    confirmDelete() {
      return this.actions.confirmDelete(this);
    },
    save() {
      return this.actions.save(this, this.config.list_url);
    },
    saveAndNew() {
      return this.actions.saveAndNew(this, 'add');
    },
    newObject() {
      return this.actions.newObject(this);
    }
  },
  mounted() {
    this.getEmployeeRoles();
    this.getUsers();
    this.loadData();
    this.actions.handleParams(this);
  },
  watch: {
    $route: {
      handler: function(newVal) {
        this.actions.handleParams(this);
      },
      deep: true,
    },
    permission: {
      handler: function(newVal) {
        this.permissionModule = newVal;
      },
      deep: true,
    }
  },
  validations(){
    let vm = this;
    return {
      data: {
        name: {
          required,
        },
      }
    }
  }
}
</script>
