import Vue from 'vue'
import PolygonLabelComponent from "@/components/Map/PolygonLabel";
const PolygonLabelConstructor = Vue.extend(PolygonLabelComponent);

export function getIcon(layer) {
  const polygonLabelComponent = new PolygonLabelConstructor({
    propsData: { fieldName: layer.name },
  });

  polygonLabelComponent.$mount();

  const polygonLabelString = new XMLSerializer().serializeToString(
    polygonLabelComponent.$el
  );

  return "data:image/svg+xml;charset=UTF-8;base64," +
    btoa(polygonLabelString);
}

export const mapOptions = {
    zoomControl: true,
    mapTypeControl: false,
    fullscreenControl: false,
    disableDefaultUI: true,
}

export const layerOptions = {
  fillColor: '#626765',
  strokeColor: '#000000',
  strokeWeight: 2,
  strokeOpacity: 1,
  fillOpacity: 0.8,
}

export const hasWorksOptions = {
  ...layerOptions, ...{ fillColor: '#3A8A21', strokeColor: '#0ADC20'}
}

export const hasParcelOptions = {
  ...layerOptions, ...{ fillColor: '#FF0000', strokeColor: '#ECA513'}
}

export const selectedlayerOtions ={
  ...layerOptions, ...{ fillColor: 'yellow', strokeColor: '#FF0000'}
}

export const scoutinglayerOtions ={
  ...layerOptions, ...{ fillColor: '#0000FF', strokeColor: '#000080'}
}

export const infoWindow = {
  options: null,
  position: null,
  opened: false,
  content: null,
};
