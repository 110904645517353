import * as types from "../mutation-types";

const mutations = {
  [types.SET_SEASON_ON_PARCEL_HAS_CULTURE]: (state, payload) => {
    state.seasonOnParcelHasCulture = payload;
  },
  [types.SET_SPC_WORKS]: (state, payload) => {
    state.spcWorks = payload;
  },
  [types.SET_SPC_WORK_SUMMARY]: (state, payload) => {
    state.spcWorkSummary = payload;
  },
  [types.SET_SPC_MACHINES]: (state, payload) => {
    state.spcMachines = payload;
  },
  [types.SET_SPC_PARCELS]: (state, payload) => {
    state.spcParcels = payload;
  },
}

export default mutations;
