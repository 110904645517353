import * as api from '../../api/index'
import * as endpoints from '../../api/endpoints'
import * as types from "../../mutation-types";

const actions = {
    getSCShifts({commit}, payload) {
        return api.fetch(endpoints.SC_SHIFT, payload, commit,(response) => {
          commit(types.SET_SC_SHIFTS, response.data.data);
          commit("common/"+types.SET_PAGINATION, response.data.pagination, { root: true });
        });
    },
    getSCShift({commit}, payload) {
      return api.fetch(endpoints.SC_SHIFT+'/'+payload.uuid, payload, commit,(response) => {
        commit(types.SET_SC_SHIFT, response.data);
      });
    },
    createSCShift({commit}, payload) {
      return api.post(endpoints.SC_SHIFT, payload, commit, (response) => {
        commit(types.SET_SC_SHIFT, response.data);
      });
    },
    updateSCShift({commit}, payload) {
      return api.put(endpoints.SC_SHIFT+'/'+payload.uuid, payload, commit, (response) => {
        commit(types.UPDATE_SC_SHIFT_ITEM, response.data);
      });
    },
    deleteSCShift({commit}, payload) {
      return api.remove(endpoints.SC_SHIFT+'/'+payload.uuid, payload, commit, (response) => {
        commit(types.DELETE_SC_SHIFT_ITEM, response.data);
      });
    },
}

export default actions;
