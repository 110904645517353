import * as api from '../../api/index'
import * as endpoints from '../../api/endpoints'
import * as types from '../../api/../mutation-types'
import {DELETE_BATCH_HEADERS} from "../../api/endpoints";

const actions = {
  getInputs({commit}, payload) {
    return api.fetch(endpoints.BATCH_HEADERS, payload, commit,(response) => {
      commit(types.SET_INPUTS, response.data.data);
      commit("common/"+types.SET_PAGINATION, response.data.pagination, { root: true });
    });
  },

  deleteInput({commit}, payload) {
    return api.remove(endpoints.BATCH+'/'+payload.batch_uuid+'?department_uuid='+payload.department_uuid, payload,  commit,(response) => {
      //commit(types.SET_INPUTS, response.data);
    })
  },
}

export default actions;
