import Cookies from 'js-cookie'

export default async (route, from, next, params) => {
  const router = params.router;
  const store = params.store;

  if (store.getters['auth/check']) {
    Cookies.set('intended_url', route.path)
  }
}
