<template>
  <card :title="title" id="details" v-if="canEdit || canDelete">
    <span class="action_buttons">
      <img src="~@/assets/images/desktop/icons/controls/plus.svg" class="icon-svg" :title="$t('common.create_new', { name: name })" @click="newObject()"/>
    </span>
    <div class="row">
      <div class="form-group col-sm-12 col-lg-8 text-left">
        <label class="control-label">{{ $t('common.name') }}</label>
        <input type="text" v-model="data.name" :placeholder='$t("common.name")'
               :class="{ 'field-error' : this.$v.data.name.$error }"
               class="form-control"/>
      </div>
    </div>
    <div class="row">
      <div class="form-group col-sm-12 col-lg-8 text-left">
        <label class="control-label">{{ $t('common.slug') }} (kg, l, g)</label>
        <input type="text" v-model="data.slug" :placeholder='$t("common.slug")'
               :class="{ 'field-error' : this.$v.data.name.$error }"
               class="form-control"/>
      </div>
    </div>
    <agro-action-buttons
      :data="data"
      :can-edit="canEdit"
      :can-delete="canDelete"
      @onSave="save"
      @onSaveAndNew="saveAndNew"
      :save-and-new="true"
      @onDelete="confirmDelete"
      @onGotoList="gotoList"
    ></agro-action-buttons>
  </card>
  <div v-else>
    <forbidden></forbidden>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import {
  createSlug,
  buildDefaultDropdownList
} from '@/helpers/common'
import * as actions from '@/helpers/generalActions'
import {canDelete, canEdit, permissions, permissionTypes} from "@/helpers/Permissions";
import required from "vuelidate/src/validators/required";
import Forbidden from "@/pages/errors/Forbidden";
import AgroActionButtons from "@/components/AgroActionButtons/index";

export default {
  name: 'SCMeasureUnitDetails',
  components: {AgroActionButtons, Forbidden},
  data() {
    return {
      data: {},
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
      users: 'user/users',
    }),
    name() {
      return this.$t('salary_calculator.measure_unit.title')
    },
    config() {
      return {
        permission: permissions.SC_MEASURE_UNIT,
        permission_type: permissionTypes.module,
        get: this.get,
        create: this.create,
        update: this.update,
        delete: this.delete,
        list_url: '/salary-calculator/sc-measure-unit',
        confirm_delete: true,
      }
    },
    actions() {
      return actions;
    },
    canEdit() {
      return canEdit(this);
    },
    canDelete() {
      return canDelete(this);
    },
    title() {
      return (this.data.uuid ? this.$t('common.edit') : this.$t('common.new'))+' '+this.name;
    },
    user_list() {
      return buildDefaultDropdownList(this.users);
    },
  },
  methods: {
    ...mapActions({
      getUsers: 'user/getUsers',
      get: 'scMeasureUnit/getSCMeasureUnit',
      create: 'scMeasureUnit/createSCMeasureUnit',
      update: 'scMeasureUnit/updateSCMeasureUnit',
      delete: 'scMeasureUnit/deleteSCMeasureUnit'
    }),
    loadData() {
      let vm = this;
      return this.actions.getData(this, (data) => {
        vm.$set(vm.data, 'shift_uuid', null);
      });
    },
    gotoList() {
      return actions.gotoList(this);
    },
    confirmDelete() {
      return this.actions.confirmDelete(this);
    },
    save() {
      return this.actions.save(this, this.config.list_url);
    },
    saveAndNew() {
      return this.actions.saveAndNew(this, 'add');
    },
    newObject() {
      return this.actions.newObject(this);
    }
  },
  mounted() {
    this.getUsers();
    this.loadData();
  },
  watch: {
    permission: {
      handler: function(newVal) {
        this.permissionModule = newVal;
      },
      deep: true,
    },
    'data.name': function(newVal) {
        if(!this.data.slug && newVal) {
            this.data.slug = createSlug(newVal);
        }
    }
  },
  validations(){
    let vm = this;
    return {
      data: {
        name: {
          required,
        },
        slug: {
          required,
        }
      }
    }
  }
}
</script>
