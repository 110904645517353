import * as types from "../mutation-types";

const mutations = {
  [types.SET_ROLES]: (state, payload) => {
    state.roles = payload;
  },
  [types.SET_ROLE]: (state, payload) => {
    state.role = payload;
  },
  [types.ADD_ROLE]: (state, payload) => {
    state.roles.push(payload);
  },
  [types.UPDATE_ROLE_ITEM]: (state, payload) => {
    state.roles.splice(
      state.roles.indexOf(
        state.roles.find(p => p.uuid === payload.uuid)
      ), 1, payload
    );
  },
  [types.REMOVE_ROLE_ITEM]: (state, payload) => {
    state.roles.splice(
      state.roles.indexOf(
        state.roles.find(p => p.uuid === payload.uuid)
      ), 1
    );
  },
  [types.SET_PERMISSIONS]: (state, payload) => {
    state.permissions = payload;
  },
  [types.SET_DEFAULT_PERMISSIONS]: (state, payload) => {
    state.default_permissions = payload;
  },
  [types.SET_PERMISSION]: (state, payload) => {
      state.permission = payload;
  },
  [types.UPDATE_PERMISSION_ITEM]: (state, payload) => {
    state.permissions.splice(
      state.permissions.indexOf(
        state.permissions.find(p => p.uuid === payload.uuid)
      ), 1, payload
    );
  },
  [types.REMOVE_PERMISSION_ITEM]: (state, payload) => {
    state.permissions.splice(
      state.permissions.indexOf(
        state.permissions.find(p => p.uuid === payload.uuid)
      ), 1
    );
  },
}

export default mutations;
