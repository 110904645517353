<template>
  <svg
    :width="fieldName.length * 7 + 2"
    height="30"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:svg="http://www.w3.org/2000/svg"
    style="border:0px solid black"
  >
    <g class="layer">
      <title>Polygon</title>
      <text
        style="
          font-family: Arial, Helvetica, sans-serif;
          font-weight: 500;
          text-shadow: -1px -1px 0 #ffffff, 1px -1px 0 #ffffff,
            -1px 1px 0 #ffffff, 1px 1px 0 #ffffff;
          font-size: 12px;
        "
        fill="#ffffff"
        class="pltext"
        text-anchor="middle"
        transform="matrix(0.9827 0 0 1 3.23004 0)"
        :x="fieldName.length * 7 / 2 - 3"
         
        y="50%"
        dominant-baseline="middle"
      >
        {{ fieldName }}
      </text>
    </g>
  </svg>
</template>

<script>
export default {
  name: "PolygonLabelComponent",
  props: ["fieldName"],
};
</script>
