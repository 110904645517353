import * as api from '../../api/index'
import * as endpoints from '../../api/endpoints'
import * as types from "../../mutation-types";

const actions = {
    getPersons({commit}, payload) {
        return api.fetch(endpoints.PERSONS, payload, commit,(response) => {
          commit(types.SET_PERSONS, response.data.data);
          commit("common/"+types.SET_PAGINATION, response.data.meta, { root: true });
        });
    },
}

export default actions;
