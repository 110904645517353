<template>
  <div>
    <div class="wrap-text" v-if="data.value_text">
      {{ data.value_text }}
    </div>
    <div v-if="data.value">
      <img src="~@/assets/images/desktop/icons/controls/check-circle.svg" class="icon-svg success-filter" />
    </div>
  </div>
</template>

<script>

export default {
  name: 'IsGroupComponent',
  props: ['data'],
  data() {
    return {
    }
  },
  methods: {
  },
  mounted() {
  }
}
</script>

<style scoped>
.wrap-text {
  width: 100%;
  word-break: break-all;
}
</style>