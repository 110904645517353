const state = () => {
  return {
    reports: [],
    report: {},
    reportGroups: [],
    reportGroup: {},
    reportFilters: [],
    reportFilter: {},
    reportColumns: [],
    reportColumn: {},
  }
}

export default state;
