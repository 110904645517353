<template type="x/template">
    <transition name="modal">
        <div>
            <DxPopup
                id="dx-dialog"
                width="100vw"
                height="100vh"
                :close-on-outside-click="false"
                :show-close-button="true"
                :visible="showPopup"
                :resize-enabled="false"
                :darg-enabled="false"
                @hidden="$emit('hidden')"
                :wrapper-attr="popupAttributes"
                >

                <template #title>
                    <div class="scouting-details-header">
                    <div class="left_side">
                    <div class="left-side-title">Notificare</div>
                    <div class="back_button" v-if="false" >
                        <img src="@/assets/images/icons/arrow-back.svg" />
                        <div class="back_button_text">Inapoi</div>
                        </div>
                    </div>

                    <div class="right_side">
                        <img class="close_button"
                        src="@/assets/images/icons/dialog-close.svg"
                        @click="buttonCloseEvent"
                        />
                    </div>
                    </div>
                </template>

                <template #content>
                    <div>
                        <DxScrollView class="scouting-details-scroll" :use-native="true">
                            <div>
                            <div class="scouting-details-wrapper" v-if="scouting_data_loaded">
                                <div class="scouting-details-big-title">Parcela {{ scouting_data.parcel_name }}</div>
                                <div class="scouting-details-title">Data</div>
                                <div class="scouting-details-value">{{ scouting_data.reported_at }}</div>
                                <div class="scouting-details-title">Stadiu BBCH</div>
                                <div class="scouting-details-value-bbch">
                                <div> {{ scouting_data.bbch_value_from.name }} </div>
                                <img v-if="scouting_data.bbch_value_to"  src="@/assets/images/icons/arrow-right-long-solid.svg"/>
                                <div v-if="scouting_data.bbch_value_to"> {{ scouting_data.bbch_value_to.name }} </div>
                                </div>
                                <div class="scouting-details-title">
                                    <div class="scouting-details-title-wrapper">
                                        <div>Dăunători</div>
                                        <div>Rata de infectare</div>
                                    </div>
                                </div>
                                <div class="scouting-disease-item-wrapper">
                                    <div v-for="(d) in scouting_data.diseases" class="scouting-disease-item">
                                        <div>{{ d.name }}</div>
                                        <div>{{ getPriorityText(d.infection_level) }}</div>
                                    </div>
                                </div>
                                <div class="scouting-details-title">Urgență</div>
                                <div class="scouting-details-value">{{ getPriorityText(scouting_data.priority) }}</div>
                                <div class="scouting-details-title">Observații</div>
                                <div class="scouting-details-value">{{ scouting_data.notes }}</div>
                                <div class="scouting-details-title">Fotografii</div>
                                <div class="scouting-details-value" v-if="scouting_data.images && scouting_data.images.length > 0">
                                    <div v-for="(img, idx) in scouting_data.images" class="scouting-image">
                                        <img :src="img.image_url"/>
                                    </div>
                                </div>
                                <div class="scouting-details-value" v-else>-</div>
                            </div>
                            </div>
                        </DxScrollView>
                    </div>
                </template>

            </DxPopup>

            <toast-message ref="toastMessage"></toast-message>
        </div>
    </transition>
</template>

<script>

import { mapGetters, mapActions } from "vuex";
import { DxPopup, DxPosition } from "devextreme-vue/popup";
import { DxScrollView } from "devextreme-vue/scroll-view";
import ToastMessage from "../../../components/Mobile/toastMessage.vue"

export default {
    name: "ScoutingDetails",
    props: {
        showPopup: {
          type: Boolean,
          default: false,
        },
        scouting_uuid: {
            type: String,
            default: ''
        },
    },
    data(){
        return {
            popupAttributes: {
                class: 'scouting_details_dialog_wrapper'
            },
            scouting_data: {},
            scouting_data_loaded: false
        }
    },
    components: {
        DxPopup,
        DxScrollView,
        ToastMessage
    },
    watch: {
         showPopup: {
             handler: function (newVal, oldVal) {
                if(newVal){
                    this.readScoutingData();
                }
                else{
                    this.scouting_data_loaded = false;
                }
            }
        },
    },
    methods: {
        ...mapActions({
            getScoutingByUUID: "scouting/getScoutingByUUID",
        }),
        buttonCloseEvent(){
            this.$emit("hidden");
        },
        readScoutingData(){
            this.getScoutingByUUID({scouting_uuid: this.scouting_uuid}).then(response => {
                this.scouting_data = response;
                this.scouting_data_loaded = true;
            });
        },
        getPriorityText(priorityNumber){
            if(priorityNumber == 1){
                return "Scăzut"
            }
            if(priorityNumber == 2){
                return "Mediu"
            }
            if(priorityNumber == 3){
                return "Înalt"
            }
        },
    }
}

</script>

<style lang="scss">
    @import "@/assets/scss/scoutingMobile/scoutingDetails.scss";
</style>
