<template>
  <div class="wrap-text">
    {{data.notes}}
  </div>
</template>

<script>

export default {
    name: 'ScoutingImage',
    props: ['data'],
    data() {
        return {
            scoutingData: this.data,
        }
    },
    components: {

    },
    computed: {
        title() {
          return this.scoutingData.uuid;
        }
    },
    methods: {
    },
    mounted () {
    }
}
</script>

<style scoped>
.wrap-text {
  width: 100%;
  word-break: break-all;
}
</style>

