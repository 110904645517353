<template>
  <div id="product-filters" class="filters p-3 col-lg-12">
    <div class="row">
      <div class="col-lg form-group">
        <label class="control-label">{{ $t('common.name')}}</label>
        <input v-model="filters.name" class="form-control"/>
      </div>
      <div class="col-lg form-group">
        <label class="control-label">{{ $t('works.work_machines.registration_number_short')}}</label>
        <input v-model="filters.registration_number" class="form-control"/>
      </div>
      <div class="col-lg form-group">
        <label class="control-label">{{ $t('works.work_machines.autopropulsat')}}</label>
        <b-form-select v-model="filters.autopropulsat" class="form-control" :options="autopropulsat_list"></b-form-select>
      </div>
      <div class="col-lg form-group">
        <label class="control-label">{{ $t('common.date_from')}}</label>
        <date-picker2
          v-model="creation_date_from"
          :value-type="creation_date_from_input"
          format="DD/MM/YYYY"
          type="date"
          placeholder="dd/mm/yyyy"
          :input-class="['date-picker-style']"
          @change="onCreationDateFromChanged"
          ref="date_from"
        ></date-picker2>
      </div>
      <div class="col-lg form-group">
        <label class="control-label d-block">{{ $t('common.date_to')}}</label>
        <date-picker2
          v-model="creation_date_to"
          :value-type="creation_date_to_input"
          format="DD/MM/YYYY"
          type="date"
          placeholder="dd/mm/yyyy"
          :input-class="['date-picker-style']"
          @change="onCreationDateToChanged"
          ref="date_to"
        ></date-picker2>
      </div>
    </div>
    <div class="row">
      <agro-filter @onChange="onDynamicFiltersChanged"/>
      <div class="col-lg-2 mt-3 pt-3">
        <button class="btn btn-primary col-lg-12" @click="applyFiltersClick">{{$t("common.show")}}</button>
      </div>
      <div class="col-lg-3 mt-3 pt-3">
        <button class="btn btn-secondary col-lg-12" @click="resetFilters">{{$t("common.reset_filters")}}</button>
      </div>
    </div>
  </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex'
    import * as type from '../../../../store/mutation-types'
    import DatePicker2 from 'vue2-datepicker';
    import 'vue2-datepicker/index.css';
    import * as endpoints from '../../../../store/api/endpoints';
    import {buildDropdownOptions} from "../../../../helpers/common";
    import AgroFilter from "~/components/AgroFilter/Index.vue";

    export default {
        name: 'WorkMachineFilters',
        data() {
            return {
                filters:  this.workMachineFilters ? this.workMachineFilters : {},
                creation_date_from: null,
                creation_date_from_input: null,
                creation_date_to: null,
                creation_date_to_input: null,
                autopropulsat_list: [
                    { value: '', text: this.$t("common.all") },
                    { value: 1, text: this.$t("common.yes") },
                    { value: 0, text: this.$t("common.no") },
                ],
            }
        },
        components:  {
          AgroFilter,
            DatePicker2,
        },
        computed: {
            ...mapGetters({
                currentDepartment: 'auth/currentDepartment',
                workMachineFilters: 'works-work-machines/workMachineFilters',
                departments: 'company/departments',
            }),
            departments_list() {
                return buildDropdownOptions(Object.assign([], this.departments), {
                    value: 'uuid',
                    text: 'name',
                }, {
                    value: null,
                    text: this.$i18n.t("common.all"),
                });
            },
        },
        methods: {
            ...mapActions({
                getWorkMachines: 'works-work-machines/getWorkMachines',
            }),
            applyFiltersClick() {
                this.$set(this.filters, 'page', 1);
                this.applyFilters();
            },
            applyFilters() {
                this.$emit('onFiltersApplied', this.filters);
                this.getWorkMachines(
                  this.filters
                )
            },
            resetFilters() {
                this.filters = {
                    name: '',
                    autopropulsat: '',
                    registration_number: '',
                    creation_date_from: '',
                    creation_date_to: '',
                    department_uuid: null,
                    page: 1,
                };

                this.creation_date_from = null;
                this.creation_date_from_input = null;
                this.creation_date_to = null;
                this.creation_date_to_input = null;

                this.$store.commit("works-work-machines/"+type.SET_WORK_MACHINE_FILTERS, this.filters);
                this.applyFilters();
            },
            onCreationDateFromChanged(date) {
                this.filters.date_from = date;
            },
            onCreationDateToChanged(date) {
                this.filters.date_to = date;
            },
            onDynamicFiltersChanged(selectedUUID) {
              this.filters.department_uuid = selectedUUID;
            }
        },
        mounted(){
            this.resetFilters();
        },
        watch: {
            workMachineFilters: {
                handler: function(newVal) {
                    this.filters = newVal;
                },
                deep: true,
            },
        }
    }
</script>
