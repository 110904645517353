<template>
  <div id="permission" class="permission col-lg-12" v-if="canEdit || canDelete" v-cloak>
    <div class="row">
      <div class="col-lg-4">
        <label v-if="mode !== 'edit'">
          {{ permission.name }}
        </label>
        <div class="pt-3 pb-3" v-else>
          <label class="control-label">
            <h5>Permission name</h5>
          </label>
          <input type="text" v-model="permission.name" class="form-control"/>
        </div>
      </div>

      <div class="col-lg-2" :class="editClass">
        <label>
          <input type="checkbox" v-model="permission.access.r" :checked="permission.access.r" class="mr-2" :class="editClass" :disabled="!isEditing()"/>Read
        </label>
      </div>
      <div class="col-lg-3" :class="editClass">
        <label>
          <input type="checkbox" v-model="permission.access.w" class="mr-2" :class="editClass" :disabled="!isEditing()"/>Create/Update
        </label>
      </div>
      <div class="col-lg-2" :class="editClass">
        <label>
          <input type="checkbox" v-model="permission.access.x" class="mr-2" :class="editClass" :disabled="!isEditing()"/>Delete
        </label>
      </div>
      <div class="col-lg-2" :class="editClassTrash" v-if="isEditing()">
        <a v-on:click.stop.prevent="showEdit()" :title='$t("edit")' class="c-pointer">
          <img src="~@/assets/images/desktop/icons/controls/check-circle.svg" class="icon-svg success-filter sicon"/>
        </a>
        <a v-on:click.stop.prevent="removeItem()" :title='$t("remove")' class="c-pointer">
          <img src="~@/assets/images/desktop/icons/controls/delete.svg" class="icon-svg danger-filter sicon"/>
        </a>
      </div>
      <div class="col-lg-2" :class="editClassTrash" v-if="isEditing()">
        <a v-on:click.stop.prevent="cancelEdit()" :title='$t("cancel")' class="c-pointer">
          <img src="~@/assets/images/desktop/icons/controls/cancel.svg" class="icon-svg danger-filter sicon"/>
        </a>
      </div>
    </div>
  </div>
  <div v-else>
    <forbidden></forbidden>
  </div>
</template>

<script>
import {canDelete, canEdit, permissions, permissionTypes} from "~/helpers/Permissions";
import Forbidden from "~/pages/errors/Forbidden.vue";

export default {
        name: 'Permission',
  components: {Forbidden},
        props: ['data'],
        data() {
            return {
                permission: this.data ? this.data : [],
            }
        },
        computed: {
          config() {
            return {
              permission: permissions.PERSONS,
              permission_type: permissionTypes.module,
            }
          },
          canEdit() {
            return canEdit(this);
          },
          canDelete() {
            return canDelete(this);
          },
            mode() {
                return this.permission ? this.permission.mode : '';
            },
            editClass() {
                if(this.mode === 'edit') {
                    return ['pt-3',' mt-4'];
                }
            },
            editClassTrash() {
                if(this.mode === 'edit') {
                    return ['pt-2',' mt-5'];
                }
            },
        },
        methods: {
            removeItem() {
                this.$emit('onItemRemoved', this.permission);
            },
            showEdit() {
                this.$set(this.permission, 'mode', 'edit');
                this.$set(this.permission, 'editing', true);
            },
            cancelEdit() {
                this.$set(this.permission, 'mode', '');
                this.$set(this.permission, 'editing', false);

                if(this.permission.new && !this.permission.name) {
                    this.removeItem();
                }
            },
            isEditing() {
                return this.permission.mode === 'edit' && this.permission.editing;
            }
        },
        watch: {
            data: {
                handler: function(newVal) {
                    this.permission = Object.assign({}, newVal);
                },
                deep: true,
            }
        }
    }
</script>
