<template>
  <div class="grid-filters-column-list">
    <grid-filter-column-list-item v-for="item in items" :data="item" :key="Math.random()" @onChange="onChange"/>
  </div>
</template>

<script>
import GridFilterColumnListItem
  from "~/pages/salary-calculator/sc-work-reports/partials/Filters/Partials/GridFilterColumnList/GridFilterColumnListItem.vue";

export default {
  name: 'GridFilterColumnList',
  props: ['data'],
  components: {GridFilterColumnListItem},
  data() {
    return {
      items: this.data ?? [],
    }
  },
  methods: {
    onChange(data) {
      this.$emit('onGridColumnSettingsChanged', data);
    },
  },
  watch: {
    data: {
      handler: function(newVal) {
        this.items = newVal;
      },
      deep: true,
    }
  }
}
</script>
