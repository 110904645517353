import * as types from "../../mutation-types";

const mutations = {
  [types.SET_DXGRID_HIDDEN_COLUMNS]: (state, payload) => {
    state.hidden_columns = payload;
  },
  [types.UPDATE_DXGRID_HIDDEN_COLUMNS]: (state, payload) => {
    state.hidden_columns.splice(
      state.hidden_columns.indexOf(
        state.hidden_columns.find((c) => c.uuid === payload.uuid)
      ),
      1,
      payload
    );
  },
};

export default mutations;
