import * as types from "../../mutation-types";

const mutations = {
    [types.SET_SM_WAREHOUSES]: (state, payload) => {
        state.sm_warehouses = payload;
    },
    [types.SET_SM_WAREHOUSE]: (state, payload) => {
        state.sm_warehouse = payload;
    },
    [types.ADD_SM_WAREHOUSE_ITEM]: (state, payload) => {
        state.sm_warehouses.push(payload);
    },
    [types.UPDATE_SM_WAREHOUSE_ITEM]: (state, payload) => {
        state.sm_warehouses.splice(state.sm_warehouses.indexOf(
            state.sm_warehouses.find(c => c.uuid === payload.uuid)
        ), 1, payload);
    },
    [types.DELETE_SM_WAREHOUSE_ITEM]: (state, payload) => {
        state.sm_warehouses.splice(state.sm_warehouses.indexOf(
            state.sm_warehouses.find(c => c.uuid === payload.uuid)
        ), 1);
    },
    [types.SET_SM_WAREHOUSE_FILTERS]: (state, payload) => {
        state.sm_warehouse_filters = payload;
    },
}

export default mutations;
