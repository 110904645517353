<template>
  <div class="sc-daily-report">
    <div class="row">
      <div class="col-lg-12 scroll-x">
        <grid-filters-toolbar
          @onQuickFilterSwitchChanged="onQuickFilterSwitchChanged"
          @onGridColumnsSettingsChanged="onGridColumnsSettingsChanged"
          @onFiltersApplied="onFiltersApplied"
          :results="items"
          :headers="headers"
          container-class="pt-16 pb-8 pl-3"></grid-filters-toolbar>
        <daily-grid :headers="headers"
                    :data="items"
                    :config="config"
                    :showFilters="showFilters"
                    :infinite-scroll="infiniteScroll"
                    @onPaginate="onPaginate"
                    :loading="is_loading"/>
        <div class="text-center infinity-loading" v-if="is_infinity_loading">
          {{ $t('common.please_wait')}}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DailyGrid from "~/pages/salary-calculator/sc-work-reports/partials/DailyGrid/index.vue";
import {permissions, permissionTypes} from "~/helpers/Permissions";
import GridFiltersToolbar from "~/pages/salary-calculator/sc-work-reports/partials/Filters/GridFiltersToolbar.vue";
import {mapGetters} from "vuex";
import * as mutationTypes from '~/store/mutation-types';

export default {
  name: 'SCDailyReport',
  props: ['data', 'actionMethod'],
  data() {
    return {
      showFilters: false,
      filteredHeaders: this.headers ?? [],
      currentPage: 1,
      perPage: 25,
      pager: this.pagination ?? {},
      filters: {},
      is_loading: false,
      is_infinity_loading: false,
      infiniteScroll: true,
      items: this.data && this.data.items ? this.data.items : [],
      summary: this.data && this.data.summary ? this.data.summary : {},
    }
  },
  components: {GridFiltersToolbar, DailyGrid},
  computed: {
    ...mapGetters({
        pagination: 'common/pagination',
        scWorkCollectorHectareData: 'scWorkPivot/scWorkCollectorHectareData',
    }),
    config() {
      return {
        permission: permissions.SC_REPORTS,
        permission_type: permissionTypes.module,
      };
    },
    headers() {
      return  [
        //show: false optional
        { name: this.$t('salary_calculator.reports.table.employee_name'), key: 'employee_name',sortable: true, disabledSelection: true, },
        { name: this.$t('salary_calculator.reports.table.work_date'), key: 'work_date',align: 'center', disabledSelection: true,},
        { name: this.$t('salary_calculator.reports.table.role'), key: 'employee_role', align: 'center'},
        { name: this.$t('salary_calculator.reports.table.work_name'), key: 'work_name', align:'center', sortable: true },
        { name: this.$t('salary_calculator.reports.table.work_hours'), key: 'work_hours', align:'center', sortable: true },
        { name: this.$t('salary_calculator.reports.table.work_group'), key: 'work_group_name', sortable: true },
        { name: this.$t('salary_calculator.reports.table.hectare'), key: 'hectare_worked', align: 'center'},
        { name: this.$t('salary_calculator.reports.table.idle_engine_time'), key: 'inactive_hours', align: 'center'},
        { name: this.$t('salary_calculator.reports.table.tariff'), key: 'tariff', align:'center',  sortable: true },
        { name: this.$t('salary_calculator.reports.table.cost'), key: 'cost', align:'center', sortable: true },
        { name: this.$t('salary_calculator.reports.table.work_hours_total'), key: 'work_hours_total', sortable: true },
        { name: this.$t('salary_calculator.reports.table.supplimentar_hours'), key: 'supplimentar_hours_total', align: 'center'},
        { name: this.$t('salary_calculator.reports.table.hectare_data_total'), key: 'hectare_worked_total', align: 'center'},
        { name: this.$t('salary_calculator.reports.table.holiday_day'), key: 'is_holiday', align:'center', sortable: true },
        { name: this.$t('salary_calculator.reports.table.check_in'), key: 'check_in_time', align:'center', sortable: true },
        { name: this.$t('salary_calculator.reports.table.check_out'), key: 'check_out_time', sortable: true },
        { name: this.$t('salary_calculator.reports.table.total_hours'), key: 'total_hours', align: 'center'},
        { name: this.$t('salary_calculator.reports.table.total_inactive_hours'), key: 'total_non_productive_hours', align: 'center'},
        { name: this.$t('salary_calculator.reports.table.salary'), key: 'salary_total', align:'center', sortable: true },
      ];
    },
  },
  methods: {
    onFiltersApplied(filters) {
      let vm = this;
      if (this.actionMethod instanceof Function) {
        this.filters = filters;
        this.actionMethod(filters).finally( () => {
          vm.is_loading = false; this.is_infinity_loading = false;
        });
      }
    },
    onPageSelected(page_id) {
      this.onPaginate({
        next_page: page_id,
      })
    },
    onPaginate(pagination) {
      this.filters.page = pagination.next_page;

      if (pagination.next_page === 1) {
        this.is_loading = true;
        this.onFiltersApplied(this.filters);
      } else {
        if (pagination.current_page <= pagination.total_pages) {
          this.is_infinity_loading = true;
          this.onFiltersApplied(this.filters);
        }
      }
    },
    onQuickFilterSwitchChanged(val) {
        this.showFilters = val;
    },
    onGridColumnsSettingsChanged(columns) {
      this.handleLoadHeaders();
    },
    handleLoadHeaders() {
      let filteredHeaders = this.headers.filter((header) => {
        return header.selected !== false;
      });

      this.$store.commit('scWorkPivot/'+mutationTypes.SET_SC_DAILY_GRID_COLUMNS, filteredHeaders);
    }
  },
  mounted() {
      this.handleLoadHeaders();
  },
  watch: {
    data: {
      handler: function(newVal) {
        if (this.infiniteScroll && this.filters.page > 1) {
          this.items = [...this.items, ...newVal.data];
        } else {
          this.items = newVal.data;
        }
      },
      deep: true,
    },
    pagination: {
      handler: function(newVal) {
        this.pager = newVal;
      },
      deep: true,
    },
  }
}
</script>
