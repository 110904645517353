<template>
  <card :title="title" id="details" v-if="canEdit || canDelete" v-cloak>
    <div class="row">
      <div class="form-group col-sm-12 col-lg-3 text-left">
        <label class="control-label">{{
          $t("stock_management.transfer.date")
        }}</label>
        <date-picker2
          v-model="data.transfer_date"
          :value-type="transfer_date_input"
          format="YYYY-MM-DD"
          type="date"
          :input-class="['date-picker-style']"
          :class="{ 'field-error': this.$v.data.transfer_date.$error }"
          ref="transfer_date"
        ></date-picker2>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12 col-lg-4 form-group">
        <label class="control-label">{{$t("stock_management.transfer.sender_warehouse")}}</label>
        <b-form-select
          v-model="data.warehouse_uuid"
          class="form-control"
          :class="{ 'field-error' : $v.data.warehouse_uuid.$error }"
          :options="warehouse_list"></b-form-select>
      </div>
    </div>
    <div class="row">
      <div class="form-group col-sm-12 col-lg-4 text-left">
        <label class="control-label">{{ $t('stock_management.sales.product_name') }}</label>
        <span class="fyi-price text-primary" v-if="data.product.uuid && stockProductInfo && stockProductInfo.price_net > 0">
            {{ $t('stock_management.sales.stored_price', { price: (stockProductInfo?.price_net ?? 0).toFixed(2) +' '+ $currency.name }) }}
          </span>
        <v-select
          v-model="productNameSearch"
          :class="{ 'field-error' : this.$v.data.product.name.$error }"
          :placeholder="$t('stock_management.products.search_by_name')"
          autocomplete="off"
          @option:selected="onProductNameSelected"
          ref="product_name"
          :options="product_name_list"></v-select>
      </div>
    </div>

    <div class="row">
      <div class="form-group col-sm-12 col-lg-4 text-left">
        <label class="control-label">
          <span>{{ $t('stock_management.products.quantity') }}</span>
          <span class="fyi-price text-primary" v-if="productNameSearch && productNameSearch.value">
          {{ $t('stock_management.products.available_quantity') }}:
          {{ this.getStockCount(this.data.warehouse_uuid) }}
            <span v-if="data.product && data.product.measure_unit">
              {{ data.product.measure_unit.name }}
            </span>
        </span>
        </label>
        <span class="mu" v-if="data.product.measure_unit">{{ data.product.measure_unit ? data.product.measure_unit.name : ''}} </span>
        <input v-model="data.product.quantity"
               class="form-control upper"
               :class="{ 'field-error' : $v.data.product.quantity.$error }"
               v-on:keydown="onlyNumbers"
               placeholder="0.00"/>
        <span class="text-danger small-font">{{ maxQtyError }}</span>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12 col-lg-4 form-group">
        <label class="control-label">{{$t("stock_management.transfer.receiver_warehouse")}}</label>
        <b-form-select
          v-model="data.dest_warehouse_uuid"
          class="form-control"
          :class="{ 'field-error' : $v.data.dest_warehouse_uuid.$error }"
          :options="dest_warehouse_list"></b-form-select>
      </div>
    </div>
    <div class="row">
      <div class="form-group col-sm-12 col-lg-6 text-left">
        <label class="control-label">{{ $t("stock_management.notes") }}</label>
        <textarea
          v-model="data.notes"
          class="form-control textarea-2-lines"
          :maxlength="185"
        ></textarea>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6">
        <label class="control-label font-weight-bold">{{$t("common.total")}}</label>
        <div>{{ total +' '+ $currency.name }}</div>
      </div>
    </div>

    <agro-action-buttons
      :data="data"
      :can-edit="canEdit"
      :can-delete="canDelete"
      @onSave="save"
      @onSaveAndNew="saveAndNew"
      :save-and-new="true"
      @onDelete="confirmDelete"
      @onGotoList="gotoList"
    ></agro-action-buttons>

  </card>
  <div v-else>
    <forbidden></forbidden>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import {
  buildDefaultAutocompleteList,
  buildDefaultDropdownList, only_numbers, only_price
} from '@/helpers/common'
import required from "vuelidate/src/validators/required";
import {canDelete, canEdit, permissions, permissionTypes} from "@/helpers/Permissions";
import * as actions from '@/helpers/generalActions'
import AgroActionButtons from "@/components/AgroActionButtons/index";
import Forbidden from "@/pages/errors/Forbidden";
import VueBootstrapTypeahead from 'vue-bootstrap-typeahead'
import AutocompleteSearch from "~/components/Common/AutocompleteSearch/Index.vue";
import CreatePartnerDialog from "~/pages/stock-management/sm-stocks/partials/CreatePartnerDialog.vue";
import DatePicker2 from "vue2-datepicker";
import moment from "moment";

export default {
  name: 'Transfer',
  components: {
    CreatePartnerDialog,
    AutocompleteSearch,
    Forbidden,
    AgroActionButtons,
    VueBootstrapTypeahead,
    DatePicker2
  },
  data() {
    return {
      data: { product: {}, transfer_date: null },
      productNameSearch: '',
      maxQtyError: '',
      transfer_date_input: null,
      allow_stock_movement_only_in_legal_entity: false,
      dest_warehouses: [],
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
      users: 'user/users',
      domain: 'auth/domain',
      products: 'smProducts/smProducts',
      warehouses: 'smWarehouses/smWarehouses',
      stockProductInfo: 'smStocks/smStockProductInfo',
    }),
    title() {
      return this.$t('stock_management.transfer.transfer_between')
    },
    config() {
      return {
        permission: permissions.SM_STOCK,
        permission_type: permissionTypes.module,
        get: this.get,
        create: this.create,
        list_url: '/stock-management/sm-stocks',
      }
    },
    actions() {
      return actions;
    },
    canEdit() {
      return canEdit(this);
    },
    canDelete() {
      return canDelete(this);
    },
    product_name_list() {
      return buildDefaultAutocompleteList(this.products);
    },
    warehouse_list() {
      return buildDefaultDropdownList(Object.assign([], this.warehouses));
    },
    dest_warehouse_list() {
      return buildDefaultDropdownList(Object.assign([], this.dest_warehouses).filter((whs) => {
        return whs.uuid !== this.data.warehouse_uuid;
      }));
    },
    productBarcode() {
      return {
        uuid: this.data.product?.uuid,
        name: this.data.product?.barcode
      }
    },
    productName() {
      return {
        uuid: this.data.product?.uuid,
        name: this.data.product?.name
      }
    },
    total() {
      if (this.data.product.quantity > 0 && this.stockProductInfo) {
        return (this.stockProductInfo.total_price ?? 0).toFixed(2);
      }
      return (0).toFixed(2);
    }
  },
  methods: {
    ...mapActions({
      create: 'smTransactions/createTransferTransaction',
      getProducts: 'smProducts/getSmProducts',
      getWarehouses: 'smWarehouses/getSmWarehouses',
      getStockProductInfo: 'smStocks/getSmStockProductInfo',
      getGroupByCode: 'settings/getGroupByCode',
    }),
    loadData() {
      let vm = this;
      return this.actions.getData(this, (data) => {
        vm.$set(vm.data, 'product', {
        });
        vm.$set(vm.data, 'warehouse_uuid', null);
        vm.$set(vm.data, 'dest_warehouse_uuid', null);
      }, (data) => {
      });
    },
    gotoList() {
      return actions.gotoList(this);
    },
    confirmDelete() {
      return this.actions.confirmDelete(this);
    },
    prepareSave(callback, context, backPath) {
      this.$v.data.$touch();

      let max = this.getStockCount(this.data.warehouse_uuid);
      this.data.product.total = this.total;

      if (parseFloat(this.data.product.quantity) <= 0) {
        this.maxQtyError = this.$t('stock_management.errors.quantity_cannot_be_zero')
        return false;
      } else if (parseFloat(this.data.product.quantity) > parseFloat(max)) {
        this.maxQtyError = this.$t('stock_management.errors.quantity_cannot_be_greater', { max: max })
        return false;
      } else {
        this.maxQtyError = '';
      }

      if(!this.$v.data.$invalid) {
        if (typeof callback == 'function') {
          callback(context, backPath);
        }
      }
    },
    save() {
      this.prepareSave(
        this.actions.save, this, this.config.list_url
      )
    },
    saveAndNew() {
      this.prepareSave(
        this.actions.saveAndNew, this, this.config.list_url+'/transfer', {
          product: {

          },
        }
      );
    },
    newObject() {
      return this.actions.newObject(this);
    },
    onlyNumbers(event) {
      return only_numbers(event);
    },
    onProductNameSelected(data) {
      if(data && data.value) {
        this.data.product = this.products.find(p => p.uuid === data.value);
      }
    },
    getStockCount(warehouse_uuid) {
      let stockItem = (this.data.product && this.data.product.stock_list)
        ? this.data.product.stock_list.find(whs => whs.warehouse_uuid === warehouse_uuid) : null;

      if(stockItem) {
        return stockItem.quantity;
      }

      return 0;
    },
    getSettings() {
      this.getGroupByCode({
        code: 'STOCK_MANAGEMENT'
      }).then(response => {
        let _setting_le = response.items.find(obj => obj.code == 'ALLOW_STOCK_MOVEMENT_ONLY_IN_LEGAL_ENTITY');
        this.allow_stock_movement_only_in_legal_entity = false;
        if(_setting_le !== undefined){
          this.allow_stock_movement_only_in_legal_entity = _setting_le.value == '1'
        }
      });
    },
  },
  mounted() {
    this.getSettings();
    this.data.transfer_date = moment().format("YYYY-MM-DD");
    this.getWarehouses({
      enablePagination: 0,
    });
    this.loadData();
    this.actions.handleParams(this);
  },
  watch: {
    $route: {
      handler: function(newVal) {
        this.actions.handleParams(this);
      },
      deep: true,
    },
    permission: {
      handler: function(newVal) {
        this.permissionModule = newVal;
      },
      deep: true,
    },
    'data.product.quantity': {
      handler: function(newVal) {
        if(this.data.product.uuid && this.data.warehouse_uuid && this.data.product.quantity > 0) {
          this.getStockProductInfo({
            product_uuid: this.data.product.uuid,
            warehouse_uuid: this.data.warehouse_uuid,
            quantity: this.data.product.quantity
          });
        }
      }
    },
    'data.warehouse_uuid': {
      handler: function(newVal) {
        this.getProducts({
          warehouse_uuid: newVal,
          limit: 999,
          has_stock: 1,
          enablePagination: 0,
        });

        //update dest whs
        const currentWhs = this.warehouses.find(w => w.uuid === newVal)

        //filter only in the same legal entity
        this.dest_warehouses = this.warehouses.filter((dwhs) => {
          if (this.allow_stock_movement_only_in_legal_entity) {
            return currentWhs && dwhs.legal_entity_uuid === currentWhs.legal_entity_uuid && dwhs.uuid !== newVal;
          }

          return dwhs;
        });
      },
      deep: true,
    },
  },
  validations(){
    const greaterThanZero = (value) => value > 0;

    return {
      data: {
        product: {
          name: {
            required,
          },
          quantity: {
            maxValue: greaterThanZero
          },
        },
        warehouse_uuid: {
          required,
        },
        dest_warehouse_uuid: {
          required,
        },
        transfer_date: {
          required,
        },
      }
    }
  }
}
</script>

<style scoped>
.textarea-2-lines {
  height: calc(1.5em * 2 + 0.75em + 2px);
}

.a-icon {
  position: relative;
  top: -26px;
  left: +40px;
}

.fyi-price {
  font-size: 10px;
}

.mu {
  position: relative;
  left: 40%;
  top: 39px;
}

.small-font {
  font-size: 12px;
}
</style>

