<template>
  <div v-if="show" class="modal">
    <div class="modal-dialog">
      <div class="modal-content">
        <div
          class="modal-header d-flex justify-content-between align-items-center"
        >
          <h5 class="modal-title">{{ title }}</h5>
          <button type="button" class="close rounded-close" @click="close">
            <span>&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-lg-6">
              <div class="form-group">
                <label for="departmentSelect">{{
                  $t("tools.import_validator.department")
                }}</label>
                <v-select
                  v-model="selected_department_uuid"
                  :reduce="(option) => option.department_uuid"
                  label="department"
                  autocomplete="off"
                  :options="departments"
                ></v-select>
              </div>
              <div class="form-group">
                <label for="workProcessSelect">{{
                  $t("tools.import_validator.work_process")
                }}</label>
                <v-select
                  v-model="selected_work_process"
                  :reduce="(option) => option.label"
                  taggable
                  autocomplete="off"
                  :options="work_process"
                ></v-select>
              </div>
              <div class="form-group">
                <label for="productMeasureUnitSelect">{{
                  $t("tools.import_validator.product_measure_unit")
                }}</label>
                <v-select
                  v-model="product_measure_unit_uuid"
                  :reduce="(option) => option.product_measure_unit_uuid"
                  label="product_measure_unit"
                  autocomplete="off"
                  :options="batch_measure_units"
                ></v-select>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="form-group">
                <label for="seasonSelect">{{ $t("season") }}</label>
                <v-select
                  v-model="selected_season_uuid"
                  :reduce="(option) => option.season_uuid"
                  label="season"
                  autocomplete="off"
                  :options="seasons"
                ></v-select>
              </div>              
              <div class="form-group">
                <label for="workProcessSelect">{{
                  $t("tools.import_validator.person")
                }}</label>
                <v-select
                  v-model="selected_person"
                  :reduce="(option) => option.label"
                  taggable
                  autocomplete="off"
                  :options="person"
                ></v-select>
              </div>              
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary mr-3" @click="close">
            {{ $t("cancel") }}
          </button>
          <button
            type="button"
            class="btn btn-success"
            @click="
              $emit(
                'confirm',
                selected_department_uuid,
                selected_season_uuid,
                selected_work_process,
                selected_person,
                product_measure_unit_uuid
              );
              selected_department_uuid = null;
              selected_season_uuid = null;
              selected_work_process = null;
              selected_person = null;
              product_measure_unit_uuid = null;
            "
          >
            {{ $t("apply_to_selected_rows") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ImportValidator",
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    item: {
      type: Object,
      required: false,
    },
    title: {
      type: String,
      default: "Validator",
    },
    departments: {
      type: Array,
      required: true,
    },
    work_process: {
      type: Array,
      required: true,
    },
    person: {
      type: Array,
      required: true,
    },
    seasons: {
      type: Array,
      required: true,
    },
    batch_measure_units: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      selected_department_uuid: null,
      selected_season_uuid: null,
      selected_work_process: null,
      selected_person: null,
      product_measure_unit_uuid: null,
    };
  },
  methods: {
    close() {
      this.selected_department_uuid = null;
      this.selected_season_uuid = null;
      this.selected_work_process = null;
      this.selected_person = null;
      this.product_measure_unit_uuid = null;
      this.$emit("close");
    },
  },
};
</script>

<style scoped>
.modal {
  display: block;
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  z-index: 1050;
}

.modal-dialog {
  margin: 1.75rem auto;
  left: -150px;
  transform: translateY(40%);
}

.modal-content {
  width: 850px;
}

.close {
  border: none;
  background: none;
  font-size: 1.5rem;
  cursor: pointer;
  float: right;
}

.rounded-close {
  border-radius: 50%;
  padding: 0.25rem 0.5rem;
}

.modal-footer .btn-secondary {
  margin-right: 1rem;
}
</style>
