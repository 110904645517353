const state = () => {
  return {
    sc_works: [],
    sc_work: {},
    sc_work_filters: {},
    sc_work_collector: [],
  }
}

export default state;
