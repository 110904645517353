<template>
    <div>
        <div v-if="company && company.uuid" ref="designer" style="width: 100%; height:calc(100vh - 220px) " data-bind="dxReportDesigner: $data"></div>
        <div v-else>NO COMPANY DETECTED</div>
        <div id="open" class="dxrd-reportdialog-content">
            <div style="margin-bottom: 10px;" data-bind="dxTextBox: { height: 36, value: inputValue, valueChangeEvent: 'keyup', placeholder: 'Enter text to search...', showClearButton: true }"></div>
            <div class="dx-default-border-style dxd-border-secondary" data-bind="dxList: {
                    dataSource: categories,
                    height: '260px',
                    grouped: true,
                    searchExpr: 'text',
                    searchValue: inputValue,
                    displayExpr: 'displayName',
                    keyExpr: 'text',
                    collapsibleGroups: true,
                }"></div>
        </div>
        <div class="dxrd-reportdialog-content" id="custom-save-as-dialog">
            <div style="margin-bottom: 10px;"
                data-bind="dxTextBox: {
                    height: 36,
                    value: inputValue,
                    valueChangeEvent: 'keyup',
                    onValueChanged: onReportNameChanged,
                    placeholder: 'Enter a report name to save...',
                    showClearButton: true
            }"></div>
            <div style="margin-bottom: 10px;"
                data-bind="dxSelectBox: {
                    height: 36,
                    dataSource: categories,
                    value: categoryName,
                    valueExpr: 'key',
                    displayExpr: categoryDisplayExpr,
                    acceptCustomValue: true,
                    placeholder: 'Select a category...',
                    onCustomItemCreating: onCustomCategoryCreating
            }"></div>
            <div class="dx-default-border-style dxd-border-secondary"
                data-bind="dxList: {
                    dataSource: categories,
                    height: '260px',
                    grouped: true,
                    displayExpr: 'displayName',
                    keyExpr: 'text',
                    collapsibleGroups: true,
            }"></div>
        </div>
    </div>
</template>

<script>
import ko from "knockout";
import "devexpress-reporting/dx-reportdesigner";
import { mapActions, mapGetters } from "vuex";

export default {
    name: "ReportDesignerComponent",
    props: {
        reportId: {
            type: Number,
            default: 0,
        },
    },
    data(){
        return {
            reportDesignerControl: null,
            designerLoaded: false,
            reportUrl: ko.observable("")
        }
    },
    computed:{
        ...mapGetters({
            company: "auth/domain",
            current_token: "auth/token",
        }),
    },
    watch: {
        company: {
            handler: function(newVal) {
                if(this.designerLoaded == false && newVal && newVal.uuid){
                    this.loadDesigner();
                }
            },
            deep: true, 
        }
    },
    methods: {
        ...mapActions({
            getReports: "reporting/getReports",
        }),
        loadDesigner(){
            var self = this;
            self.reportUrl = ko.observable("");
            function addReport(url, category, categoryArray, categoryName, reportName, value, koCategory) {
                if(category.length === 0) {
                    categoryArray.push({
                        key: categoryName, items: [
                            {
                                text: value.Key, displayName: reportName,
                                onClick: function () { 
                                    url(value.key); 
                                    koCategory && koCategory(categoryName); 
                                }
                            }
                        ]
                    });
                } else {
                    category[0].items.push({
                        text: value.Key, displayName: reportName,
                        onClick: function () { 
                            url(value.key); 
                            koCategory && koCategory(categoryName); 
                        }
                    });
                }
            }

            function updateCategories(url, categories, koCategory) {
                self.reportDesignerControl.ReportStorageGetUrls().done(function(result){
                    self.getReports().then(response => {
                        result = [];
                        if(response && response.length > 0){
                            response.forEach(rpt => {
                                result.push({key: rpt.id, Value: rpt.name});
                            });
                        }

                        var categoryArray = [{ key: "none", items: [] }];

                        for(var i = 0; i < result.length; i++) {
                            var parts = result[i].Value.split('\\');
                            var folder = parts[0];
                            var reportName = parts[1];
                            if(parts.length === 1) {
                                reportName = parts[0];
                                folder = "Common";
                            } else if (parts.length > 2) {
                                reportName = parts.pop();
                                folder = parts.join('\\');
                            }
                            var category = categoryArray.filter(function(item) { return item.key === folder; });
                            addReport(url, category, categoryArray, folder, reportName, result[i], koCategory);
                        };
                        categories(categoryArray);
                    });
                });
            
            }

            var designerOptions = {
                reportUrl: self.reportUrl,
                requestOptions: {
                    host: process.env.VUE_APP_REPORTING_URL,
                    getDesignerModelAction: "DXXRD/GetDesignerModel?token=" + (this.company ? this.company.uuid : '') + '&reportToken=' + this.reportId + "&authtoken=" + this.current_token,
                },
                callbacks: {
                    // CustomizeMenuActions: function (s,e) {
                    //     // For demonstration purposes. Get the "Exit" action and hide it. 
                    //     var exitAction = e.GetById(DevExpress.Reporting.Designer.Actions.ActionId.Exit);
                    //     if (exitAction)
                    //         exitAction.visible = false;
                    // },
                    CustomizeOpenDialog: function(s,e){
                        self.reportDesignerControl = s;
                        //console.log('CustomizeOpenDialog ', s, e);
                        e.Popup.width("700px");
                        e.Popup.height("476px");
                        e.Popup.title = "Open report";
                        var categories = ko.observableArray([]);
                        var koUrl = ko.observable("");
                        var koInput = ko.observable("");
                        updateCategories(koUrl, categories);

                        var model = {
                            categories: categories,
                            //reportUrl: koUrl,
                            inputValue: koInput, 
                            setUrl: function(url) {
                                koUrl(url);
                            },
                            getUrl: function() {
                                return koUrl();
                            },
                            onShow: function(tab) {
                                updateCategories(koUrl, categories);
                            },
                            popupButtons: [
                                {
                                    toolbar: 'bottom', location: 'after', widget: 'dxButton', options: {
                                        text: 'Open', onClick: function() {
                                            console.log('open url -> ', koUrl() + '#' + self.company.uuid);
                                            e.Popup.open(koUrl() + '#' + self.company.uuid);
                                        }
                                    }
                                },
                                {
                                    toolbar: 'bottom', location: 'after', widget: 'dxButton', options: {
                                        text: 'Cancel', onClick: function() {
                                            e.Popup.cancel();
                                        }
                                    }
                                }
                            ]
                        }
                        e.Customize("open", model)
                    },

                    CustomizeSaveAsDialog(s, e) {
                        //console.log('CustomizeSaveAsDialog ', s, e);
                        e.Popup.width("700px");
                        e.Popup.height("522px");
                        e.Popup.title = "Save";
                        var categories = ko.observableArray([]);
                        var koUrl = ko.observable("");
                        var koInput = ko.observable("");
                        var koCategory = ko.observable("");

                        koUrl.subscribe(function(newVal) {
                            console.log('kourl subscribe newVal -> ', typeof newVal)
                            if(typeof newVal === 'number'){
                                //newVal = newVal.toString();
                            }
                            
                            newVal = newVal.replace('/', '\\');
                            var paths = newVal.split('\\');
                            var fileName = paths.pop();
                            koInput(fileName);
                            var catName = paths.join('\\');
                            koCategory(catName);
                        });
                        var updateReportName = function(reportName) {
                            //koUrl(koCategory() ? (koCategory() + '\\' + reportName) : reportName);
                            console.log('report name before set at update -> ', koUrl());
                            koUrl(reportName);
                            console.log('report name after set at update -> ', koUrl());
                        };

                        koCategory.subscribe(function(newVal) {
                            newVal = newVal.replace('/', '\\');
                            updateReportName(koInput());
                        });
                        updateCategories(koUrl, categories);

                        var onCustomCategoryCreating = function(data) {
                            if(!data.text || data.text === "none") {
                                data.customItem = null;
                                return;
                            }
                            data.customItem = { key: data.text, items: [] };
                            categories.push(data.customItem);
                            koUrl(data.text + '\\' + koInput());
                        }

                        var model = {
                            categories: categories,
                            categoryName: koCategory,
                            //reportUrl: koUrl,
                            onReportNameChanged: function(e) {
                                updateReportName(e.value);
                            },
                            onCustomCategoryCreating: onCustomCategoryCreating,
                            inputValue: koInput,
                            categoryDisplayExpr: function(item) {
                                return item && item.key || "none";
                            },
                            setUrl: function(url) {
                                koUrl(url);
                            },
                            getUrl: function() {
                                return koUrl();
                            },
                            onShow: function(tab) {
                                koInput("");
                                updateCategories(koUrl, categories, koCategory);
                            },
                            popupButtons: [
                                {
                                    toolbar: 'bottom', location: 'after', widget: 'dxButton', options: {
                                        text: 'Save', onClick: function() {
                                            if(!koInput()) return;
                                            console.log('save -> ', koUrl() + '#' + self.company.uuid);
                                            e.Popup.save(koUrl() + '#' + self.company.uuid);
                                        }
                                    }
                                },
                                {
                                    toolbar: 'bottom', location: 'after', widget: 'dxButton', options: {
                                        text: 'Cancel', onClick: function() {
                                            e.Popup.cancel();
                                        }
                                    }
                                }
                            ]
                        }
                        e.Customize("custom-save-as-dialog", model);
                    }
                    
                },
            };

            if(self.company && self.company.uuid && this.$refs.designer){
                ko.applyBindings(designerOptions, this.$refs.designer);
                self.designerLoaded = true;
            }
        }
    },
    mounted() {
        this.loadDesigner();
    },
    beforeUnmount() {
        ko.cleanNode(this.$refs.designer);
    }
};
</script>