<template>
  <div class="formula-tags-input">
    <vue-tags-input
      v-model="tag"
      :tags="tags"
      :autocomplete-items="filteredItems"
      @tags-changed="onChange"
      :add-only-from-autocomplete="true"
      :placeholder="$t('tools.configurator.calculation_formula.add_field')"
    />
  </div>
</template>

<script>
import VueTagsInput from '@johmun/vue-tags-input';
import {CALCULATION_FORMULA} from "@/helpers/settings";

export default {
  name: 'FormulaTagInput',
  props: ['data', 'initTags'],
  components: {
    VueTagsInput,
  },
  data() {
    return {
      tag: '',
      tags: [],
      currentTemplate: this.template ? this.template : {},
    };
  },
  computed: {
    autocompleteItems() {
        return [...Object.assign([], this.data).map((item) => {
          return {
            text: item.name,
            value: item.field ?? item.uuid,
          }
        }), ...CALCULATION_FORMULA.DEFAULT_ARITM];
    },
    filteredItems() {
      return this.autocompleteItems.filter(i => {
        return i.text.toLowerCase().indexOf(this.tag.toLowerCase()) !== -1;
      });
    },
  },
  methods: {
    onChange(newTags) {
      this.$emit('onChange', newTags);
      this.tags = newTags;
    }
  },
  watch: {
    initTags: {
      handler: function (newVal) {
        this.tags = Object.assign([], newVal).map((item) => {
          return {
            text: Object.assign([], this.data.find(d => d.field === item)).name ?? item,
            value: item,
          };
        });
      },
      deep: true,
      immediate: true,
    },
  }
};
</script>

<style scoped>

</style>
