import * as types from "../../mutation-types";

const mutations = {
  [types.SET_PERSONS]: (state, payload) => {
    state.persons = payload;
  },
  [types.SET_PERSON_FILTERS]: (state, payload) => {
    state.person_filters = payload;
  },
}

export default mutations;
