<template>
  <div>
    <transition name="modal" v-if="isModal">
      <div class="modal-mask" v-show="show">
        <div class="modal-wrapper">
          <div class="modal-container">
            <div class="modal-header d-flex justify-content-between align-items-center">
              <h5 class="modal-title">{{ title }}</h5>
              <button type="button" class="close rounded-close" @click="closeModal">
                <span>&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div class="row col-lg-11">
                <ParcelDetailsContent ref="details" :defaultNewValues="defaultNewValues" :isModal="isModal" @title="updateTitle" />
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary close_modal" @click="closeModal">
                {{ $t("cancel") }}
              </button>
              <button type="button" class="btn btn-success" @click="saveModal">
                {{ $t("save") }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </transition>
    <div v-else>
      <card :title="title" id="details" v-cloak>
        <div class="row col-lg-8">
        <ParcelDetailsContent ref="details" :defaultNewValues="defaultNewValues" :isModal="isModal" @title="updateTitle" />
        </div>
      </card>
    </div>
  </div>
</template>
<script>
import ParcelDetailsContent from "./partials/ParcelDetails.vue";

export default {
  name: "ParcelDetails",
  components: {
    ParcelDetailsContent,
  },
  props: {
    isModal: {
      type: Boolean,
      default: false,
    },
    show: {
      type: Boolean,
      default: false,
    },
    defaultNewValues: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      title: "",
    };
  },
  methods: {
    updateTitle() {
      if (this.$refs.details) {
        this.title = this.$refs.details.title;
      }
    },
    saveModal() {
      this.$refs.details.save().then((data) => {
        this.closeModal();
        this.$emit('newParcelCreated', data);
      });
    },
    closeModal() {
      this.$emit("close");
    },
  },
  mounted() {
    this.updateTitle();
  },
};
</script>

<style scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 800px;
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
}

.modal-header h5 {
  margin-top: 0;
  color: #42b983;
}

.modal-footer {
  margin-top: 20px;
  text-align: right;
}

.modal-default-button {
  background-color: #42b983;
  border: none;
  padding: 10px 20px;
  color: #fff;
  cursor: pointer;
  border-radius: 2px;
}

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.close {
  border: none;
  background: none;
  font-size: 1.5rem;
  cursor: pointer;
  float: right;
}

.rounded-close {
  border-radius: 50%;
  padding: 0.25rem 0.5rem;
}

.close_modal {
  margin-right: 25px;
}
</style>
