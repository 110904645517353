import HomePage from '@/layouts/default.vue';
import Dashboard from "@/pages/dashboard/index.vue";
import Login from "@/pages/auth/login.vue";
import Users from "@/pages/users/index.vue";
import UserDetails from "@/pages/users/_id.vue";
import RolesPermissions from "@/pages/roles-permissions/index.vue";
import ScEmployee from "@/pages/salary-calculator/sc-employee/index.vue";
import ScEmployeeDetails from "@/pages/salary-calculator/sc-employee/_id.vue";
import ScEmployeeRole from "@/pages/salary-calculator/sc-employee-role/index.vue";
import ScEmployeeRoleDetails from "@/pages/salary-calculator/sc-employee-role/_id.vue";
import ScBonus from "@/pages/salary-calculator/sc-bonus/index.vue";
import ScBonusDetails from "@/pages/salary-calculator/sc-bonus/_id.vue";
import ScShift from "@/pages/salary-calculator/sc-shift/index.vue";
import ScShiftDetails from "@/pages/salary-calculator/sc-shift/_id.vue";
import ScWork from "@/pages/salary-calculator/sc-work/index.vue";
import ScWorkDetails from "@/pages/salary-calculator/sc-work/_id.vue";
import ScWorkGroup from "@/pages/salary-calculator/sc-work-group/index.vue";
import ScWorkGroupDetails from "@/pages/salary-calculator/sc-work-group/_id.vue";
import ScWorkType from "@/pages/salary-calculator/sc-work-type/index.vue";
import ScWorkTypeDetails from "@/pages/salary-calculator/sc-work-type/_id.vue";
import ScMeasureUnit from "@/pages/salary-calculator/sc-measure-unit/index.vue";
import ScMeasureUnitDetails from "@/pages/salary-calculator/sc-measure-unit/_id.vue";
import ScHoliday from "@/pages/salary-calculator/sc-holiday/index.vue";
import ScHolidayDetails from "@/pages/salary-calculator/sc-holiday/_id.vue";
import ScWorkLog from "@/pages/salary-calculator/sc-work-log/index.vue";
import ScWorkLogDetails from "@/pages/salary-calculator/sc-work-log/_id.vue";
import SmProducts from "@/pages/stock-management/sm-products/index.vue";
import SmProductDetails from "@/pages/stock-management/sm-products/_id.vue";
import SmPartners from "@/pages/stock-management/sm-partners/index.vue";
import SmPartnerDetails from "@/pages/stock-management/sm-partners/_id.vue";
import SmStocks from "@/pages/stock-management/sm-stocks/index.vue";
import SmTransactions from "@/pages/stock-management/sm-transactions/index.vue";
import SmWarehouses from "@/pages/stock-management/sm-warehouses/index.vue";
import SmWarehouseDetails from "@/pages/stock-management/sm-warehouses/_id.vue";
import Cultures from "@/pages/works/cultures/index.vue";
import CultureDetails from "@/pages/works/cultures/_id.vue";
import CultureVarietyDetails from "@/pages/works/cultures/varieties/_id.vue";
import WorkMachines from "@/pages/works/work-machines/index.vue";
import WorkMachineDetails from "@/pages/works/work-machines/_id.vue";
import WorkInputs from "@/pages/works/inputs/index.vue";
import Persons from "@/pages/works/persons/index.vue";
//import Person from "@/pages/works/persons/_id.vue";
import JustifyingDocuments from "@/pages/tools/justifying-documents/index.vue";
import JustifyingDocumentDetails from "@/pages/tools/justifying-documents/_id.vue";
import Settings from "@/pages/tools/settings/index.vue";
import Parcels from "@/pages/works/parcels/index.vue";
import ParcelDetails from "@/pages/works/parcels/_id.vue";
import Manager from "@/pages/works/manager/index.vue";
import ScWorkReports from "@/pages/salary-calculator/sc-work-reports/index.vue";
import ApplyArticles from "@/pages/works/applyArticles/index.vue";
import Rent from "@/pages/rent/index.vue";
import StockMobile from "@/pages/stock-mobile/index.vue";
import Departments from "@/pages/my-company/departments/index.vue";
import DepartmentDetails from "@/pages/my-company/departments/DepartmentDetails.vue";
import Profile from "@/pages/settings/profile.vue";
import ScoutingList from "@/pages/scouting/admin/index.vue"
import ArendaProducts from "@/pages/arenda-management/products/index.vue"
import ArendaProductPrices from "@/pages/arenda-management/product-prices/index.vue"
import ArendaTariffSystems from "@/pages/arenda-management/tariff-systems/index.vue"
import ContractAdminList from "@/pages/arenda-management/contracts/index.vue"
import Import from "@/pages/tools/import/index.vue";
import Configurator from "@/pages/tools/import-configurator/index.vue";
import SmMovements from "@/pages/stock-management/sm-movements/index.vue";
import Reception from "@/pages/stock-management/sm-stocks/Reception.vue";
import Sales from "@/pages/stock-management/sm-stocks/Sales.vue";
import Transfer from "@/pages/stock-management/sm-stocks/Transfer.vue";

import ReportConfig from "@/pages/reporting/configuration/index.vue";
import ReportDesigner from "@/pages/reporting/configuration/designer.vue";
import ReportManagement from "@/pages/reporting/report-management/index.vue";
import ReportList from "@/pages/reporting/reports/index.vue";
import ReportGroups from "@/pages/reporting/report-groups/index.vue";

import GeneralError from "@/pages/errors/general-error.vue";
import Redirect from "@/pages/redirect.vue";
import ConfiguratorTemplates from "@/pages/tools/import-configurator/templates/index.vue";
import ImportHistory from "@/pages/tools/import-history/index.vue";
import ImportValidator from "@/pages/tools/import-validator/index.vue";
import CriteriaBuilder from "@/pages/tools/statuses/index.vue";

export default [
    { path: '*' },
    { path: '/', name: 'welcome', component: HomePage, meta: {  } },
    { path: '/general-error', name: 'general-error', component: GeneralError, meta: {  } },
    { path: '/redirect', name: 'redirect', component: Redirect, meta: {  } },
    { path: '/dashboard', name: 'dashboard', component: Dashboard, meta: {  } },
    { path: '/auth/login', name: 'login', component: Login, meta: { layout: 'login'  } },
    { path: '/users', name: 'users', component: Users, meta: {  } },
    { path: '/roles-permissions', name: 'roles-permissions', component: RolesPermissions, meta: {  } },
    { path: '/users/:uuid', name: 'user-details', component: UserDetails, meta: {  } },
    { path: '/my-company/departments', name: 'departments', component: Departments, meta: {  } },
    { path: '/my-company/departments/:uuid', name: 'department-details', component: DepartmentDetails, meta: {  } },
    { path: '/settings/profile', name: 'user-profile', component: Profile, meta: {  } },
    { path: '/salary-calculator/sc-employee', name: 'sc-employee-list', component: ScEmployee, meta: {  } },
    { path: '/salary-calculator/sc-employee/:uuid', name: 'sc-employee-details', component: ScEmployeeDetails, meta: {  } },
    { path: '/salary-calculator/sc-employee-role', name: 'sc-employee-role-list', component: ScEmployeeRole, meta: {  } },
    { path: '/salary-calculator/sc-employee-role/:uuid', name: 'sc-employee-role-details', component: ScEmployeeRoleDetails, meta: {  } },
    { path: '/salary-calculator/sc-bonus', name: 'bonus-list', component: ScBonus, meta: {  } },
    { path: '/salary-calculator/sc-bonus/:uuid', name: 'sc-bonus-details', component: ScBonusDetails, meta: {  } },
    { path: '/salary-calculator/sc-shift', name: 'shift-list', component: ScShift, meta: {  } },
    { path: '/salary-calculator/sc-shift/:uuid', name: 'sc-shift-details', component: ScShiftDetails, meta: {  } },
    { path: '/salary-calculator/sc-work', name: 'work-list', component: ScWork, meta: {  } },
    { path: '/salary-calculator/sc-work/:uuid', name: 'sc-work-details', component: ScWorkDetails, meta: {  } },
    { path: '/salary-calculator/sc-work-group', name: 'work-group-list', component: ScWorkGroup, meta: {  } },
    { path: '/salary-calculator/sc-work-group/:uuid', name: 'sc-work-group-details', component: ScWorkGroupDetails, meta: {  } },
    { path: '/salary-calculator/sc-work-type', name: 'work-type-list', component: ScWorkType, meta: {  } },
    { path: '/salary-calculator/sc-work-type/:uuid', name: 'sc-work-type-details', component: ScWorkTypeDetails, meta: {  } },
    { path: '/salary-calculator/sc-measure-unit', name: 'measure-unit-list', component: ScMeasureUnit, meta: {  } },
    { path: '/salary-calculator/sc-measure-unit/:uuid', name: 'sc-measure-unit-details', component: ScMeasureUnitDetails, meta: {  } },
    { path: '/salary-calculator/sc-holiday', name: 'holiday-list', component: ScHoliday, meta: {  } },
    { path: '/salary-calculator/sc-holiday/:uuid', name: 'sc-holiday-details', component: ScHolidayDetails, meta: {  } },
    { path: '/salary-calculator/sc-work-log', name: 'sc-work-log-list', component: ScWorkLog, meta: {  } },
    { path: '/salary-calculator/sc-work-log/:uuid', name: 'sc-work-log-details', component: ScWorkLogDetails, meta: {  } },
    { path: '/salary-calculator/reports', name: 'sc-reports', component: ScWorkReports, meta: {  } },
    { path: '/stock-management/sm-products', name: 'sm-product-list', component: SmProducts, meta: {  } },
    { path: '/stock-management/sm-products/:uuid', name: 'sm-product-details', component: SmProductDetails, meta: {  } },
    { path: '/stock-management/sm-partners', name: 'sm-partner-list', component: SmPartners, meta: {  } },
    { path: '/stock-management/sm-partners/:uuid', name: 'sm-partner-details', component: SmPartnerDetails, meta: {  } },
    { path: '/stock-management/sm-stocks', name: 'sm-stock-list', component: SmStocks, meta: {  } },
    { path: '/stock-management/sm-transactions', name: 'sm-transaction-list', component: SmTransactions, meta: {  } },
    { path: '/stock-management/sm-movements', name: 'sm-movements-list', component: SmMovements, meta: {  } },
    { path: '/stock-management/sm-stocks/reception', name: 'sm-reception-add', component: Reception, meta: {  } },
    { path: '/stock-management/sm-stocks/sales', name: 'sm-sales-add', component: Sales, meta: {  } },
    { path: '/stock-management/sm-stocks/transfer', name: 'sm-transfer-add', component: Transfer, meta: {  } },
    { path: '/stock-management/sm-warehouses', name: 'sm-warehouse-list', component: SmWarehouses, meta: {  } },
    { path: '/stock-management/sm-warehouses/:uuid', name: 'sm-warehouse-details', component: SmWarehouseDetails, meta: {  } },
    { path: '/works/cultures', name: 'culture-list', component: Cultures, meta: {  } },
    { path: '/works/cultures/:uuid', name: 'culture-details', component: CultureDetails, meta: {  } },
    { path: '/works/cultures/:uuid/varieties/:vuuid', name: 'culture-variety-add-item', component: CultureVarietyDetails, meta: {  } },
    { path: '/works/cultures/varieties/:vuuid', name: 'culture-variety-add', component: CultureVarietyDetails, meta: {  } },
    { path: '/works/work-machines', name: 'work-machine-list', component: WorkMachines, meta: {  } },
    { path: '/works/work-machines/:uuid', name: 'work-machine-details', component: WorkMachineDetails, meta: {  } },
    { path: '/works/inputs', name: 'work-input-list', component: WorkInputs, meta: {  } },
    { path: '/works/persons', name: 'person-list', component: Persons, meta: {  } },
    //{ path: '/works/persons/:uuid', name: 'person-details', component: PersonDetails, meta: {  } },
    { path: '/works/parcels', name: 'parcels-list', component: Parcels, meta: {  } },
    { path: '/works/parcels/:uuid', name: 'parcels-details', component: ParcelDetails, meta: {  } },
    { path: '/works/manager', name: 'work-manager', component: Manager, meta: {  } },
    { path: '/works/applyArticles', name: 'apply-articles', component: ApplyArticles, meta: { layout: 'noheader' } },
    { path: '/rent', name: 'rent', component: Rent, meta: { layout: 'noheader' } },
    { path: '/stock-mobile', name: 'stock-mobile', component: StockMobile, meta: { layout: 'noheader' } },
    { path: '/tools/justifying-documents', name: 'justifying-document-list', component: JustifyingDocuments, meta: {  } },
    { path: '/tools/justifying-documents/:uuid', name: 'justifying-document-details', component: JustifyingDocumentDetails, meta: {  } },
    { path: '/tools/settings', name: 'setting-list', component: Settings, meta: {  } },
    { path: '/tools/import', name: 'import-list', component: Import, meta: {  } },
    { path: '/tools/import-history', name: 'import-history', component: ImportHistory, meta: {  } },
    { path: '/tools/configurator', name: 'import-configurator', component: Configurator, meta: {  } },
    { path: '/tools/import-configurator/templates', name: 'import-configurator-templates', component: ConfiguratorTemplates, meta: {  } },
    { path: '/tools/import-validator', name: 'import-validator', component: ImportValidator, meta: {  } },
    { path: '/tools/statuses', name: 'statuses', component: CriteriaBuilder, meta: {  } },
    { path: '/scouting/admin', name: 'scouting-admin', component: ScoutingList, meta: {  } },
    { path: '/arenda-management/products', name: 'arenda-products', component: ArendaProducts, meta: {  } },
    { path: '/arenda-management/product-prices', name: 'arenda-product-prices', component: ArendaProductPrices, meta: {  } },
    { path: '/arenda-management/tariff-systems', name: 'arenda-tariff-systems', component: ArendaTariffSystems, meta: {  } },
    { path: '/arenda-management/contracts', name: 'ContractAdminList', component: ContractAdminList, meta: {  } },
    { path: '/reporting/configuration', name: 'reporting-config', component: ReportConfig, meta: {  } },
    { path: '/reporting/designer', name: 'reporting-designer', component: ReportDesigner, meta: {  } },
    { path: '/reporting/report-management', name: 'report-management', component: ReportManagement, meta: {  } },
    { path: '/reporting/reports', name: 'report-list', component: ReportList, meta: {  } },
    { path: '/reporting/report-groups', name: 'report-goups', component: ReportGroups, meta: {  } },
]
