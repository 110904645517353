<template>
  <div class="product_lis_dialog">
    <DxPopup
      id="dx-dialog"
      height="100vh"
      width="100vw"
      :close-on-outside-click="false"
      :show-close-button="true"
      :visible="showPopup"
      @hidden="$emit('hidden')"
    >
      <template #title>
        <div class="add_article_title_template">
          <div class="left_side" v-if="false">
            <img src="@/assets/images/icons/arrow-back.svg" />
            <span>Înapoi</span>
          </div>
          <div class="right_side">
            <img
              src="@/assets/images/icons/dialog-close.svg"
              @click="buttonCloseEvent"
            />
          </div>
        </div>
      </template>
      <template #content>
        <div>
          <div class="header_container">
            <div class="dialog_title_image">
              <img src="@/assets/images/icons/droplet.svg" alt="Articole" />
            </div>
            <div class="dialog_title">Articole</div>
            <div class="dialog_subtitle subtitle_3">{{templateData.work_name}} {{templateData.work_date}}</div>
            <div class="dialog_subtitle">
              <span class="subtitle_2">Se afișează {{
                  templateData.total_surface_worked % 1 > 0
                    ? templateData.total_surface_worked.toFixed(2)
                    : templateData.total_surface_worked
                }} hectare cu</span>
              <span class="subtitle_1">
                <img src="@/assets/images/icons/parcel2.svg" />
                {{
                  templateData.parcel_count
                }}
                </span
              >
              <span class="subtitle_2">parcele</span>
            </div>
            <div class="title_separatot"></div>
            <DxScrollView class="input-container-scroll" :use-native="true">
              <div>
                <div class="articles_container_bl" v-for="(products, index) in used_products" :key="index">
                  <div class="articles_top_lines"></div>
                  <div class="sub_container">
                    <div class="parcels">
                      <img src="@/assets/images/icons/parcel2.svg" />
                      <div>
                        {{ products.parcel_names }}
                      </div>
                    </div>
                  </div>
                  <div class="sub_container">
                    <div class="articles">
                      <img src="@/assets/images/icons/simple-droplet.svg" />
                      <div>
                        <div v-for="(product, idx) in products.val" :key="idx">
                          {{product.product_name}} <span class="articles_dose">({{parseFloat(product.item_quantity) % 1 > 0 ? parseFloat(product.item_quantity).toFixed(2) : parseFloat(product.item_quantity)}}{{product.mu}} / ha) total {{ product.total_used_quantity.toFixed(2) }} {{product.mu}}</span><span v-if="(idx + 1) != products.val.length">,</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </DxScrollView>
          </div>
        </div>
      </template>
    </DxPopup>
  </div>
</template>

<script>
import DxPopup from "devextreme-vue/popup";
import { mapActions, mapGetters } from "vuex";
import { DxScrollView } from "devextreme-vue/scroll-view";

export default {
  name: "BatchProductList",
  props: {
    showPopup: {
      type: Boolean,
      default: false,
    },
    templateData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      used_products:[]
    };
  },
  computed:{
    ...mapGetters({
      currentDepartment: "auth/currentDepartment",
    }),
  },
  components: {
    DxPopup,
    DxScrollView
  },
  watch:{
    showPopup(newVal) {
      if (newVal == true) {
        this.getBatchHeaderSummary({
          //department_uuid: this.currentDepartment.department_uuid,
          work_uuid: this.templateData.work_uuid,
          work_date: this.templateData.work_date
        })
        .then((response) => {
          this.used_products = [];
          response.forEach((obj) => {
            let check_obj = this.used_products.find(k => k.key == obj.parcel_uuids);
            if(check_obj === undefined){
              this.used_products.push({key: obj.parcel_uuids, parcel_names: obj.parcel_names, val: [obj]});
            }
            else{
              check_obj.val.push(obj)
            }
          });
        });
      }
      else{
        this.used_products = [];
      }
    },
  },
  methods: {
     ...mapActions({
      getBatchHeaderSummary: "batch/getBatchHeaderSummary",
    }),
    buttonCloseEvent() {
      this.$emit("hidden");
    },
  },
};
</script>


<style lang="scss">
@import "@/assets/scss/digitalAgenda/daBatchProductList.scss";
</style>
