<template>
  <div class="one-col-column" :class="[containerClass, visible === false ? 'd-none' : null, infiniteScroll ? 'infiniteStickyTh' : null]">
    <div v-if="title" class="row-title">{{ title }}</div>
    <div class="inner-container" :class="[border]" :style="flexAlign" v-if="!infiniteScroll">
      <div class="value" :class="textClass">{{ formattedValue ?? '-' }}</div>
      <slot></slot>
    </div>
  </div>
</template>

<script>
import {convertDecimalHoursToGMT} from "~/helpers/common";

export default {
  name: 'OneCol',
  props: [
    'title',
    'value',
    'valign',
    'align',
    'containerClass',
    'textClass',
    'noBorder',
    'dateFormat',
    'infiniteScroll',
    'decimalPrecision'
  ],
  data() {
    return {
      visible: true,
    }
  },
  computed: {
    border() {
      if (this.noBorder !== false) {
        return 'inner-cont-border';
      }
    },
    flexAlign() {
      switch(this.align) {
        case 'left':
          return 'justify-content: flex-start';
        case 'right':
          return 'justify-content: flex-end';
        default:
          return 'justify-content: center';
      }
    },
    textAlign() {
      return 'text-'+(this.align ?? 'center');
    },
    formattedValue() {
      if (this.dateFormat && this.value) {
        return convertDecimalHoursToGMT(this.value, this.dateFormat);
      }

      if (this.decimalPrecision && this.value) {
        return parseFloat(this.value.toString()).toFixed(this.decimalPrecision);
      }

      return this.value ?? '-';
    }
  },
  methods: {
    hide() {
      this.visible = false;
    },
    show() {
      this.visible = true;
    },
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/components/desktop/components";
  .one-col-column {
    display: flex;
    flex-direction: column;
    margin: 0 10px 10px 10px;
    min-width: 180px;

    .inner-container {
      display: flex;
      flex-grow: 1;
      padding: 10px;
    }
  }

  .inner-cont-border {
    border: 1px #ededed solid;
    border-radius: 6px;
  }

  .row-title {
    padding-left: 10px;
    font-size: 14px;
    font-weight: bold;
    padding-bottom: 5px;
  }
</style>
