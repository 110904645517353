const state = () => {
  return {
    sc_work_pivots: [],
    sc_work_collector_hectare_data: [],
    sc_work_collector_column_settings: [],
    sc_work_hierarchy: [],
    sc_work_pivot: {},
    sc_work_pivot_filters: {},
    sc_daily_grid_columns: [],
  }
}

export default state;
