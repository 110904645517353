export function getFilterDataByName(loadOptions, paramName, isDate = false) {
    if(loadOptions?.filter)   {
        if(loadOptions.filter.length == 3 && loadOptions.filter[0] ==  paramName && loadOptions.filter.filterValue){
            if(isDate){
                return loadOptions.filter.filterValue.getDate();    
            }
            return loadOptions.filter.filterValue;
        }
        else{
            let checkObject = loadOptions.filter.find(f => f[0] == paramName );
            if(checkObject){
                if(isDate){
                    return checkObject.filterValue.getDate();    
                }
                return checkObject.filterValue;
            }
        }
    } 
    return null;
}

export function getSortByProperty(loadOptions, attributeOptions){
    if(loadOptions?.sort && loadOptions?.sort.length > 0 ){
        let sortByField = loadOptions.sort[0].selector
        if(attributeOptions){
            sortByField = attributeOptions.find(a => a.field == sortByField).sortField;
        }
        return sortByField;
    }
    return null;
}

export function getSortDirection(loadOptions){
    if(loadOptions?.sort && loadOptions?.sort.length > 0 ){
        return loadOptions.sort[0].desc ? 'DESC' : 'ASC';
    }
    return null;
}