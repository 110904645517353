<template>
  <div class="grid-filters-column-list-item">
    <label>
      <input type="checkbox" class="" v-model="selected" :disabled="item.disabledSelection"/>
      <span>{{ item.name }}</span>
    </label>
  </div>
</template>

<script>
export default {
  name: 'GridFilterColumnListItem',
  props: ['data'],
  data() {
    return {
      item: this.data ?? [],
      selected: false,
    }
  },
  watch: {
    data: {
      handler: function(newVal) {
        this.item = newVal;
        this.selected = newVal.selected !== false;
        this.$emit('onChange', this.item);
      },
      deep: true,
      immediate: true,
    },
    selected: function(newVal) {
      this.item.selected = !!newVal;
      this.$emit('onChange', this.item);
    }
  }
}
</script>
