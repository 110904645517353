import Vue from 'vue'
import VuejsDialog from 'vuejs-dialog';
import 'vuejs-dialog/dist/vuejs-dialog.min.css';
Vue.use(VuejsDialog, {
  html: true,
  loader: true,
  okText: 'Yes',
  cancelText: 'Cancel',
  animation: 'bounce'
});
