<template>
  <div class="view" v-if="canEdit || canDelete" v-cloak>
    <card
        :title="$t('works.cultures.name')"
        :title-bottom-padding="'10px'"
        id="cultures"
    >
      <DxDataGrid
          :show-borders="true"
          :data-source="dataSource"
          :remote-operations="gridRemoteOperations"
          :width="'100%'"
          :height="600"

      >
        <DxColumn
            dataField="name"
            :caption="$t('works.cultures.culture')"
            :width="'20%'"
            :filter-operations="['contains']"
        >
          <DxRequiredRule :message="$t('validation.name_is_required')"/>
        </DxColumn>

        <DxColumn
            dataField="display_name"
            :caption="$t('display_name')"
            :width="'20%'"
            :filter-operations="['contains']"
        />

        <DxColumn
            dataField="department_name"
            :caption="$t('department')"
            :width="'15%'"
            :formItem="{
                visible: false
            }"
            :filter-operations="['contains']"
        />

        <DxColumn
            dataField="department_uuid"
            edit-cell-template="departmentSelectorTemplate"
            :width="'15%'"
            :visible="false"
            :formItem="{
                visible: true
            }"
        />

        <template #departmentSelectorTemplate="{ data }">
          <agro-filter @onChange="(val) => onUnitSelectorChanged(val, data)"
                       :selectedItems="[data.data.department]"
                       :singleSelect="true" :title="$t('department_selector')"/>
        </template>

        <DxColumn
            dataField="created_at"
            :caption="$t('common.created_at')"
            dataType="date"
            format="yyyy-MM-dd"
            :width="'20%'"
            alignment="center"
            :formItem="{
                visible: false
            }"
            :filter-operations="['=']"
        />

        <DxColumn
            dataField="updated_at"
            :caption="$t('common.updated_at')"
            dataType="date"
            format="yyyy-MM-dd"
            :width="'20%'"
            alignment="center"
            :formItem="{
                visible: false
            }"
            :filter-operations="['=']"
        />

        <DxMasterDetail
            :enabled="true"
            template="cultureDetailTemplate"
        />
        <template #cultureDetailTemplate="{ data: culture }">
          <culture-details-template :data="culture"></culture-details-template>
        </template>


        <DxFilterRow :visible="true"/>
        <DxScrolling mode="infinite"/>
        <DxEditing
            :allow-adding="allowEditing"
            :allow-updating="allowEditing"
            :allow-deleting="allowDeleting"
            mode="form"
            :use-icons="true"
        />
      </DxDataGrid>
      <div class="total-results mt-2">
        {{ $t('common.total_results', {count: total}) }}
      </div>
    </card>
  </div>
  <div v-else>
    <forbidden></forbidden>
  </div>
</template>

<script>
import {
  DxDataGrid,
  DxColumn,
  DxEditing,
  DxFilterRow,
  DxScrolling,
  DxMasterDetail,
  DxRequiredRule,
} from 'devextreme-vue/data-grid';
import { mapActions } from "vuex";
import Forbidden from "@/pages/errors/Forbidden.vue";
import CustomStore from "devextreme/data/custom_store";
import DataSource from "devextreme/data/data_source";
import AgroFilter from "@/components/AgroFilter/Index.vue";
import CultureDetailsTemplate from "@/pages/works/cultures/partials/CultureDetailsTemplate.vue";
import {canDelete, canEdit} from "@/helpers/Permissions";


export default {
  name: "ImportValidator",
  components: {
    CultureDetailsTemplate,
    AgroFilter,
    Forbidden,
    DxDataGrid,
    DxColumn,
    DxEditing,
    DxFilterRow,
    DxScrolling,
    DxMasterDetail,
    DxRequiredRule,
  },
  data() {
    return {
      gridRemoteOperations: {
        filtering : true,
        sorting: true
      },
      cultureTabSelectedIndex: 0,
    };
  },
  computed: {
    dataSource() {
      return new DataSource({
        store: new CustomStore({
          key: 'uuid',
          load: this.load,
          insert: this.create,
          update: this.update,
          remove: this.remove
        })
      });
    },
    total() {
      return this.$store.getters['common/pagination']?.total;
    },
    canEdit() {
      return canEdit(this);
    },
    canDelete() {
      return canDelete(this);
    },
  },
  methods: {
    ...mapActions({
        get: 'works-cultures/getCultures',
        create: 'works-cultures/createCulture',
        change: 'works-cultures/updateCulture',
        delete: 'works-cultures/deleteCulture',
    }),
    async load(data) {
      let dataSource =  {
        data:[],
        totalCount:0
      };

      await this.get({
        filter: data.filter,
        sort: data.sort,
      }).then((resp) => {
        dataSource.data = resp.data;
        dataSource.totalCount = resp.pagination.total;
      });

      return dataSource;
    },
    async update(key, obj) {
      const updatable = Object.assign(
          {},
          this.dataSource.items().find(item => item.uuid === key),
          obj
      );
      await this.change(
         updatable
      )
    },
    async remove(uuid) {
      if (this.dataSource.items().find(item => item.uuid === uuid).is_deletable === 0) return;

      await this.delete({
        uuid: uuid,
      });
    },
    allowEditing(e) {
      return this.canEdit;
    },
    allowDeleting(e) {
      return this.canDelete && e.row.data.is_deletable === 1;
    },
    onUnitSelectorChanged(value, data) {
      data.setValue(value);
    },
  },
};
</script>
