<template>
  <div>
    <Link :title="$t('common.show')" :onClick="onClick">
      <p class="sub">
        <span v-if="data.units > 0">{{ data.units }} {{ $t('user.units') }}</span>
        <span v-if="data.roles > 0">{{ data.roles }} {{ $t('user.roles') }}</span>
      </p>
      <unit-and-role-dialog :show="showUnitsAndRoleModal" @close="showUnitsAndRoleModal = false"
                            :data="unitsAndRoleData"
                            @onOK="showUnitsAndRoleModal = false"
      ></unit-and-role-dialog>
    </Link>
  </div>
</template>

<script>
import UnitAndRoleDialog from "~/pages/users/partials/UnitsAndRoleDialog.vue";

export default {
  name: 'UnitAndRoleLink',
  props: ['data'],
  data() {
    return {
      showUnitsAndRoleModal: false,
      unitsAndRoleData: {},
    }
  },
  components: {
    UnitAndRoleDialog
  },
  computed: {
      title() {
        return '';
      }
  },
  methods: {
    onClick() {
      this.unitsAndRoleData = this.data;
      this.showUnitsAndRoleModal = true;
    }
  },
}
</script>

<style scoped>
.sub {
  font-size: 12px;
}
</style>
