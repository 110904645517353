import axios from '@/plugins/axios'
import * as types from "../mutation-types";

const actions = {
    getServers({commit}, payload) {
        axios.get('/client/servers', {
          params: payload,
        }).then((response) => {
            commit(types.SET_SERVERS, response.data.data);
            commit("common/"+types.SET_PAGINATION, response.data.meta, { root: true });
        }).catch((e) => {
            commit("common/"+types.SET_ERROR_MESSAGE, e, { root: true });
        });
    },
    deleteServer({commit}, payload) {
        return new Promise((resolve, reject) => {
            axios.delete('/client/servers/'+payload.uuid).then((response) => {
                commit(types.SET_SERVER_ITEM, response.data.data);
                resolve(response.data);
            }).catch((e) => {
                reject(e);
            });
        })
    }
}

export default actions;
