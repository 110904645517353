import * as types from "../../mutation-types";

const mutations = {
    [types.SET_MEASURE_UNITS]: (state, payload) => {
        state.measure_units = payload;
    },
    [types.SET_MEASURE_UNIT]: (state, payload) => {
        state.measure_unit = payload;
    },
    [types.SET_MEASURE_UNIT_FILTERS]: (state, payload) => {
        state.measure_unit_filters = payload;
    },
}

export default mutations;
