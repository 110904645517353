var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.scope.edit
    ? _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.scope.tag.text,
            expression: "scope.tag.text"
          }
        ],
        staticClass: "ti-tag-input",
        attrs: { maxlength: _vm.scope.maxlength, type: "text", size: "1" },
        domProps: { value: _vm.scope.tag.text },
        on: {
          input: [
            function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.$set(_vm.scope.tag, "text", $event.target.value)
            },
            function($event) {
              return _vm.scope.validateTag(_vm.scope.index, $event)
            }
          ],
          blur: function($event) {
            return _vm.scope.performCancelEdit(_vm.scope.index)
          },
          keydown: function($event) {
            return _vm.scope.performSaveEdit(_vm.scope.index, $event)
          }
        }
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }