import axios from 'axios'
import {errorMessage, errorMessageList, getServerSubdomain, isMobile, setSubdomain} from "~/helpers/common";
import Cookies from "js-cookie";
import {eventBus} from "@/plugins/eventBus";
import Vue from 'vue'
import store from '@/store'
import router from '@/router'
// Request interceptor

let vm = new Vue();
axios.interceptors.request.use(req => {
  try {
    let domain = Cookies.get('domain');

    if(store.getters['auth/domain']) {
      domain = store.getters['auth/domain'].domain;
    }

    req.baseURL = setSubdomain(domain, process.env.VUE_APP_BASE_URL);
  }
  catch (e) {
    console.log('Unable to se domain for request', e);
  }

  const token = store.getters['auth/token'];

  if (token) {
    req.headers.Authorization = `Bearer ${token}`
  }

  // request.headers['X-Socket-Id'] = Echo.socketId()

  return req
});

// Response interceptor
axios.interceptors.response.use(response => response, error => {
  const { status } = error.response ? error.response : error;
  //itt err undefined error response data
  console.log('error interceptor', error);
  if (error.response.data && error.response.data.errors && error.response.data.errors.error) {
    if (error.response.data.errors.error[0] === 'user.notifications.password_has_to_be_changed') {
      router.push({
        path: '/settings/password?change-pass=1',
      })
    }
  }

  if(status === 400 && error.response.data.errors) {
    if (error.response.data.errors && error.response.data.errors.error && router.currentRoute.path.includes('/login')) {
      eventBus.$emit("onToastMessage", {
        type: 'error',
        message: error.response.data.errors.error,
      });
    } else {
      eventBus.$emit("validation-errors", error.response.data.errors);
    }
  }

  if (store.getters['auth/user'] && status === 401 && !router.currentRoute.path.includes('/login')) {
    console.log('401 error ', error.response.data.errors.error);
    if(error.response.data.errors.error) {
      eventBus.$emit("session-expired", error.response.data.errors);
    }

    store.commit("auth/LOGOUT");

  } else if (status === 403) {
    router.push({
      path: '/errors/forbidden',
    });
  }
  else if (status === 401 && !router.currentRoute.path.includes('/login')) {
    if (router.currentRoute.meta.layout === 'noheader' && isMobile()) {
      window.location.href = getServerSubdomain()+'/session-expired?src=axios&status=401';
    } else {
      router.push({
        path: '/auth/login?status=401',
      });
    }

  }
  else {
    if (error.response.status >= 500 && !router.currentRoute.path.includes('/login')) {
      if (router.currentRoute.path.includes("applyArticles")) {
        // router.push({
        //   path: '/errors/digital-agenda-error',
        // });
      }
      else {

        /*
        router.push({
          path: '/errors/general-error',
        });
         */
        if(error && error.response && error.response.data) {
          errorMessage(vm, error.response.data.message);
          console.log('data 1: ', error.response.data.message);
        } else {
          errorMessage(vm, vm.$t('error_alert_text'));
          console.log('data 2 ', 'general error text: ', vm.$t('error_alert_text'));
        }
      }
    } else {
      console.log('ere login?');
    }
  }


  console.log('reject error?', error);
  return Promise.reject(error)
});

export default axios;
