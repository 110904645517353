import * as api from '../../api/index'
import * as endpoints from '../../api/endpoints'
import * as types from '../../api/../mutation-types'

const actions = {
    //Sm transactions
    createReceptionTransaction({commit}, payload) {
      return api.post(endpoints.SM_RECEPTION_TRANSACTION, payload,  commit,(response) => {
        commit(types.SET_SM_RECEPTION_TRANSACTION, response.data);
      });
    },
    createSalesTransaction({commit}, payload) {
      return api.post(endpoints.SM_SALES_TRANSACTION, payload,  commit,(response) => {
        commit(types.SET_SM_SALES_TRANSACTION, response.data);
      });
    },
    createConsumTransaction({commit}, payload) {
      return api.post(endpoints.SM_CONSUM_TRANSACTION, payload,  commit,(response) => {
        commit(types.SET_SM_CONSUM_TRANSACTION, response.data);
      });
    },
    createTransferTransaction({commit}, payload) {
      return api.post(endpoints.SM_TRANSFER_TRANSACTION, payload,  commit,(response) => {
        commit(types.SET_SM_TRANSFER_TRANSACTION, response.data);
      });
    },

    //regular transactions
    getSmTransactions({commit}, payload) {
      return api.fetch(endpoints.SM_TRANSACTIONS, payload, commit, (response) => {
        if(!payload.no_commit) {
          commit(types.SET_SM_TRANSACTIONS, response.data.data);
          commit("common/"+types.SET_PAGINATION, response.data.pagination, { root: true });
        }
      });
    },
    cancelSmTransaction({commit}, payload) {
      return api.remove(endpoints.SM_TRANSACTIONS_CANCEL, payload, commit, (response) => {
        commit(types.UPDATE_TRANSACTION, response.data.data);
      });
    },
    getSmTransactionTypes({commit}, payload) {
      return api.fetch(endpoints.SM_TRANSACTION_TYPES, payload, commit, (response) => {
        commit(types.SET_SM_TRANSACTION_TYPES, response.data);
      });
    },
    getSmStockMovements({commit}, payload) {
      return api.fetch(endpoints.SM_STOCK_MOVEMENTS, payload, commit, (response) => {
        commit(types.SET_SM_STOCK_MOVEMENTS, response.data.data);
        commit("common/"+types.SET_PAGINATION, response.data.pagination, { root: true });
      });
    },
    getSmStockMovementTypes({commit}, payload) {
      return api.fetch(endpoints.SM_STOCK_MOVEMENT_TYPES, payload, commit, (response) => {
        commit(types.SET_SM_STOCK_MOVEMENT_TYPES, response.data);
      });
    },
    getConsumTransactions({commit}, payload) {
      return api.fetch(endpoints.SM_CONSUM_TRANSACTION, payload,  commit,(response) => {

      });
    },
    getParcelConsum({commit}, payload) {
      return api.fetch(endpoints.PARCEL_CONSUM + "/" + payload.parcel_uuid + "?type=" + payload.type, payload,  commit,(response) => {

      });
    },
    getParcelConsumSummary({commit}, payload) {
      return api.fetch(endpoints.PARCEL_CONSUM_SUMMARY + "/" + payload.parcel_uuid + "?type=" + payload.type, payload,  commit,(response) => {

      });
    },
    exportPdf({commit}, payload) {
      return api.downloadBlob(payload.url, payload, payload.type, commit,(response) => response.data);
    },
    downloadPdf({commit}, payload) {
      return api.downloadBlob(payload.url, payload, payload.type, commit,(response) => response.data);
    },
    getDownloadInfo({commit}, payload) {
      return api.fetchFull(payload.url, payload,  commit,(response) => {

      });
    },
    getExportInfo({commit}, payload) {
      return api.fetchFull(payload.url, payload,  commit,(response) => {

      });
    },

}

export default actions;
