import * as api from '../../api/index'
import * as endpoints from '../../api/endpoints'
import * as types from '../../api/../mutation-types'
import {DELETE_DOCUMENT_BLOCK_ITEM} from "../../api/../mutation-types";
import {IS_DOCUMENT_BLOCK_EXISTS} from "../../api/endpoints";

const actions = {
    //DocumentBlocks
    getDocumentBlocks({commit}, payload) {
          return api.fetch(endpoints.DOCUMENT_BLOCKS, payload, commit, (response) => {
            commit(types.SET_DOCUMENT_BLOCKS, response.data.data);
              commit("common/"+types.SET_PAGINATION, response.data.pagination, { root: true });
          });
    },
    getDocumentTypes({commit}, payload) {
      return api.fetch(endpoints.DOCUMENT_TYPES, payload, commit, (response) => {
        commit(types.SET_DOCUMENT_TYPES, response.data);
      });
    },
    getDocumentBlock({commit}, payload) {
      return api.fetch(endpoints.DOCUMENT_BLOCKS+'/'+payload.uuid, payload, commit,  (response) => {
        commit(types.SET_DOCUMENT_BLOCK, response.data);
      });
    },
    createDocumentBlock({commit}, payload) {
      return api.post(endpoints.DOCUMENT_BLOCKS, payload,  commit,(response) => {
        commit(types.SET_DOCUMENT_BLOCK, response.data);
      });
    },
    updateDocumentBlock({commit}, payload) {
      return api.put(endpoints.DOCUMENT_BLOCKS+'/'+payload.uuid, payload, commit, (response) => {
        commit(types.SET_DOCUMENT_BLOCK, response.data);
      })
    },
    deleteDocumentBlock({commit}, payload) {
      return api.remove(endpoints.DOCUMENT_BLOCKS+'/'+payload.uuid, payload,  commit,(response) => {
        commit(types.DELETE_DOCUMENT_BLOCK_ITEM, response.data);
      })
    },
    checkDocumentBlock({commit}, payload) {
      return api.fetch(endpoints.DOCUMENT_BLOCK_CHECK, payload, commit);
    },
    getDocumentModel({commit}, payload) {
      return api.fetch(endpoints.DOCUMENT_BLOCK_MODEL, payload, commit);
    },
    isDocumentBlockExists({commit}, payload) {
      return api.post(endpoints.IS_DOCUMENT_BLOCK_EXISTS, payload, commit);
    },
}

export default actions;
