<template>
        <DxScrollView ref="scrollView" :elementAttr="scrollListAttributes" :use-native="true" @reach-bottom="reachBottom" @scroll="scroll" reachBottomText="Se încarcă..." >
            <div class="rent_list_holder">
                <div class="no_dataLabel" v-if="noContractData">Nu există nici un contract</div>
                <div v-for="(rent_data, r_idx) in rent_list_data" :key="rent_data.idx_uuid">
                    <div  class="rent_item" :selected="rent_data.selected">
                        <div class="rent_main_icon">
                            <div class="rent_main_icon_file" :selected="rent_data.selected">
                                <img src="@/assets/images/icons/file.svg"/>
                            </div>
                        </div>
                        <div class="rent_main_info">
                            <div class="company_name">{{rent_data.contract_items[0].owner.name}}
                                <span class="more_contracts" v-if="rent_data.contract_items && rent_data.contract_items.length > 1"> + {{rent_data.contract_items.length - 1}} more</span>
                            </div>
                            <div class="rent_tags">
                                <div @click="expandContract(rent_data, r_idx)" :class="[{'warning_rent_tag': rent_data.contract_status != 'paid'}, {'success_rent_tag': rent_data.contract_status == 'paid'}]" :selected="rent_data.selected">{{getStatusName(rent_data.contract_status)}}</div>
                            </div>
                            <div class="contract_holder">
                                <div class="contract">Nr. contract: {{rent_data.contract_number}}</div>
                                <div v-show="false" class="more_contracts" v-if="rent_data.contracts && rent_data.contracts.length > 1"> + {{rent_data.contracts.length - 1}} more</div>
                            </div>
                        </div>
                        <div class="expand_icon_holder">
                            <div :class="rent_data.expanded ? 'expand_icon_expanded' : 'expand_icon'" @click="expandContract(rent_data, r_idx)" :selected="rent_data.selected">
                                <img v-if="rent_data.expanded" src="@/assets/images/icons/arrow_up_green.svg"/>
                                <img v-else src="@/assets/images/icons/arrow_down.svg"/>
                            </div>
                        </div>
                    </div>
                    <contract-details :ref="'contractDetails_' +  r_idx" v-show="rent_data.expanded" :paymentMode="paymentMode" :templateData="rent_data"></contract-details>
                </div>
            </div>
        </DxScrollView>
</template>

<script>

import { mapGetters, mapActions } from "vuex";
import Vue from "vue";
import infiniteScroll from "vue-infinite-scroll";
Vue.use(infiniteScroll);
import ContractDetails from './contractDetails.vue';
import { DxCheckBox } from 'devextreme-vue/check-box';
import { DxScrollView } from "devextreme-vue/scroll-view";
import { v4 } from 'uuid';

export default {
    name: "ContractList",
    props: {
        paymentMode: {
            type: Boolean,
            default: false,
        },
         fillAtMount: {
            type: Boolean,
            default: true,
        },
        filterData: {
            type: Object,
            default: null
        }
    },
    data(){
        return {
            noContractData: false,
            executeFillContract: true,
            filterTimer: {},
            filterTimerInterval: 500,
            rent_list_data: [],
            pagination: {},
            scrollOffset_top: 0,
            scrollListAttributes: {
                class: 'rent_list_scroller'
            },
        };
    },
    computed: {
        ...mapGetters({
            currentDepartment: "auth/currentDepartment"
        }),
    },
    watch: {
        filterData: {
             handler: function (oldVal, newVal) {
                if(newVal != null){
                    this.fillContracts(1, newVal, this.fillCallback);
                }
            },
            deep: true,
        },
        scrollOffset_top:{
            handler: function(newVal, oldVal){
                if(newVal < oldVal){
                    this.$emit('scrollDown');
                }
                if(newVal > oldVal){
                    this.$emit('scrollUp');
                }
            }
        }
    },
    components: {
        ContractDetails,
        DxCheckBox,
        DxScrollView
    },
    methods: {
         ...mapActions({
            getContracts: "leasecontracts/getContracts",
        }),
        selectAll(select){
            this.rent_list_data.forEach((obj) => {
                obj.selected = select;
            });
        },
        expandAll(expand){
            this.rent_list_data.forEach((obj, idx) => {
                obj.expanded = expand;
                 if(!expand){
                this.$refs['contractDetails_' + idx][0].expanded = false;
            }
            });
        },

         expandContract(rent_data, idx){
            rent_data.expanded = !rent_data.expanded;
            if(this.paymentMode){
                this.$refs['contractDetails_' + idx][0].expandPyamentDetails();
            }
            if(!rent_data.expanded){
                this.$refs['contractDetails_' + idx][0].expanded = false;
            }
        },
        selectContract(selected, rent_data, r_idx){

        },
        fillContracts(page_number, filter = null, callback = null){
            if(this.executeFillContract){
                filter = filter ?? {};
                filter.page = page_number;
                this.getContracts(filter).then((response) => {
                    if(page_number == 1){
                        this.rent_list_data = [];
                        //this.$set(this.rent_list_data, [] );
                    }
                    response.data.forEach((contract) => {
                        contract.selected = false;
                        contract.expanded = false;
                        contract.idx_uuid = v4();
                        this.rent_list_data.push(contract);
                        //this.$set(this.rent_list_data, this.rent_list_data.length - 1, contract );
                    });
                    this.pagination = response.pagination;
                    this.noContractData = this.rent_list_data.length == 0;
                    if(callback != null){
                        callback();
                    }
                });
            }
            this.$set(this, 'executeFillContract', true);
        },
        fillCallback(){
            if(this.pagination && this.pagination.current_page == this.pagination.last_page || this.rent_list_data.length == 0){
                this.$refs['scrollView'].instance.release(true);
            }
            else{
                this.$refs['scrollView'].instance.release(false);
            }
            this.$refs['scrollView'].instance.update();

            if(this.pagination && this.pagination.current_page == 1){
                this.$refs['scrollView'].instance.scrollTo(0)
            }
        },
        reachBottom(){
            if(this.pagination && this.pagination.current_page < this.pagination.last_page){
                this.fillContracts(this.pagination.current_page + 1, null, this.fillCallback);
            }
        },
        scroll(event){
            this.scrollOffset_top = event.scrollOffset.top;
        },
        pullDown(event){
             event.component.release();
        },
        statusTagClick(status_filter){
            this.$emit('status_filter', status_filter);
        },
        getStatusName(name){
            if(name == 'paid'){
                return "Plătit";
            }
            else if(name == 'partially_paid'){
                return "plătit parțial"
            }
            else if(name == 'unpaid'){
                return "neplătit"
            }
            else{
                return name;
            }
        }
    },
    mounted(){
        if(this.fillAtMount){
            this.fillContracts(1, null, this.fillCallback);
        }
    }
}
</script>
