export const TransactionActions = {
    Receptie: "RECEPTIE",
    Transfer: "TRANSFER",
    Consum: "CONSUM",
    Vanzare: "VANZARE",
    Recalificare: "RECALIFICARE",
    Casare: "CASARE",
}

export const TransactionOptions = [
    {
        code: TransactionActions.Receptie,
        title: "Recepție"
    },
    {
        code: TransactionActions.Transfer,
        title: "Transfer"
    },
    {
        code: TransactionActions.Consum,
        title: "Consum"
    },
    {
        code: TransactionActions.Vanzare,
        title: "Vânzare"
    },
    {
        code: TransactionActions.Recalificare,
        title: "Recalificare"
    },
    {
        code: TransactionActions.Casare,
        title: "Casare"
    },
]

