<template>
  <card :title="$t('your_info')">
      <!-- Name -->
      <div class="form-group row" v-if="user">
        <div class="col-md-4">
          <label class="control-label">{{ $t('first_name') }}</label>
          <input v-model="user.first_name"
                 :class="{ 'field-error' : this.$v.user.first_name.$error }"
                 class="form-control" type="text">
        </div>
        <div class="col-md-4">
          <label class="control-label">{{ $t('last_name') }}</label>
          <input v-model="user.last_name"
                 :class="{ 'field-error' : this.$v.user.last_name.$error }"
                 class="form-control" type="text" name="last_name">
        </div>
      </div>

      <!-- Email -->
      <div class="form-group row" v-if="user">
        <div class="col-md-8">
          <label class="col-md-3">{{ $t('email') }}</label>
          <input v-model="user.email"
                 :class="{ 'field-error' : this.$v.user.email.$error }"
                 disabled
                 class="form-control" type="email" name="email">
        </div>
      </div>

      <!-- Submit Button -->
      <div class="form-group row">
        <div class="col-md-9 ml-md-auto">
          <v-button :loading="busy" type="outline-primary" @click="update">
            {{ $t('update') }}
          </v-button>
        </div>
      </div>
  </card>
</template>

<script>
import { mapGetters } from 'vuex'
import {errorMessage, successMessage} from "@/helpers/common";
import required from "vuelidate/src/validators/required";

export default {
  name: 'Profile',
  scrollToTop: false,

  metaInfo () {
    return { title: this.$t('settings') }
  },

  data() {
      return {
          user: {},
        busy: false,
      }
  },

  computed: mapGetters({
    userData: 'auth/user'
  }),

  methods: {
    async update () {
        let vm = this;
        this.$v.user.$touch();
        if(!this.$v.user.$invalid) {
            this.$store.dispatch('auth/updateMyAccount', this.user).then((response) => {
                successMessage(vm, response.message);
                vm.user = response.data;
            }).catch((e) => {
                errorMessage(vm, e.message);
            });
        }
    }
  },
    mounted() {
        this.user = this.userData;
    },
  validations() {
      return {
          user: {
              first_name: {
                  required,
              },
              last_name: {
                  required,
              },
              email: {
                  required,
              },
          },
      }
  },
}
</script>
