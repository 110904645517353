import * as api from '../../api/index'
import * as endpoints from '../../api/endpoints'
import * as types from "../../mutation-types";

const actions = {
  getSCMeasureUnits({commit}, payload) {
    return api.fetch(endpoints.SC_MEASURE_UNIT, payload, commit,(response) => {
      commit(types.SET_SC_MEASURE_UNITS, response.data.data);
      commit("common/"+types.SET_PAGINATION, response.data.pagination, { root: true });
    });
  },
  getSCMeasureUnit({commit}, payload) {
    return api.fetch(endpoints.SC_MEASURE_UNIT+'/'+payload.uuid, payload, commit,(response) => {
      commit(types.SET_SC_MEASURE_UNIT, response.data);
    });
  },
  createSCMeasureUnit({commit}, payload) {
    return api.post(endpoints.SC_MEASURE_UNIT, payload, commit, (response) => {
      commit(types.SET_SC_MEASURE_UNIT, response.data);
    });
  },
  updateSCMeasureUnit({commit}, payload) {
    return api.put(endpoints.SC_MEASURE_UNIT+'/'+payload.uuid, payload, commit, (response) => {
      commit(types.UPDATE_SC_MEASURE_UNIT_ITEM, response.data);
    });
  },
  deleteSCMeasureUnit({commit}, payload) {
    return api.remove(endpoints.SC_MEASURE_UNIT+'/'+payload.uuid, payload, commit, (response) => {
      commit(types.DELETE_SC_MEASURE_UNIT_ITEM, response.data);
    });
  },
}

export default actions;
