<template>
  <div class="works dx-viewport">
    <card :title="$t('works.manager.name')" class="agx-card" id="works-manager">
      <filters
        ref="filterPopup"
        :showPopup="showFilters"
        @hidden="hideFiltersPopup"
      ></filters>
      <add-fito-and-fertility
        :modelType="addFitoAndFertilityModelType"
        :showPopup="showAddFitoAndFertility"
        @hidden="hideAddFitoAndFertilityPopup"
      ></add-fito-and-fertility>
      <DxDataGrid
        :ref="gridRefName"
        :data-source="dataSource"
        :remote-operations="false"
        :allow-column-reordering="true"
        :row-alternation-enabled="false"
        :show-borders="true"
        :focused-row-enabled="true"
        key-expr="uuid"
        @content-ready="onContentReady"
        :element-attr="gridAttributes"
        :column-hiding-enabled="true"
      >
        <DxFilterRow :visible="false" :apply-filter="currentFilter" />
        <DxHeaderFilter :visible="false" />

        <DxColumn
          data-field="parcel.name"
          data-type="string"
          caption="Parcel"
          cell-template="parcelCellTemplate"
        />
        <DxColumn
          data-field="work.name"
          data-type="string"
          caption="Work"
          cell-template="workCellTemplate"
        />
        <DxColumn
          data-field="work_mashine.name"
          data-type="string"
          caption="Machine"
          cell-template="machineCellTemplate"
        />
        <DxColumn
          data-field="work_started_at"
          data-type="date"
          caption="Started at"
          cell-template="startedCellTemplate"
        />
        <DxColumn
          data-field="work_ended_at"
          data-type="date"
          caption="Ended at"
          cell-template="endedCellTemplate"
        />

        <DxGroupPanel :visible="true" />
        <DxSearchPanel :visible="true" :highlight-case-sensitive="true" />
        <DxGrouping :auto-expand-all="true" />
        <DxPager
          :visible="true"
          :allowed-page-sizes="pageSizes"
          :show-page-size-selector="true"
        />
        <DxPaging :page-size="10" />
        <DxToolbar>
          <DxItem location="before" template="filterTemplate" />
          <DxItem location="after" template="addFitoTemplate" />
          <DxItem location="after" template="addFertyTemplate" />
          <DxItem location="after" template="refreshTemplate" />
        </DxToolbar>
        <template #parcelCellTemplate="{ data: rowInfo }">
          <div class="work-grid-row">
            <div
              class="row-color-box"
              :style="{ 'background-color': rowInfo.data.work.color }"
            ></div>
            <div
              style="
                display: flex;
                flex-direction: column;
                align-content: center;
                justify-content: center;
              "
            >
              <div class="row-info-box">{{ rowInfo.data.parcel.name }}</div>
              <div class="row-info-box" style="display: none">
                {{ rowInfo.data.work.name }}
              </div>
            </div>
          </div>
        </template>
        <template #workCellTemplate="{ data: rowInfo }">
          <div class="work-grid-row">
            <div class="row-info-box">{{ rowInfo.data.work.name }}</div>
          </div>
        </template>
        <template #machineCellTemplate="{ data: rowInfo }">
          <div class="work-grid-row">
            <div class="row-info-box">{{ rowInfo.data.work_mashine.name }}</div>
          </div>
        </template>
        <template #startedCellTemplate="{ data: rowInfo }">
          <div class="work-grid-row">
            <div class="row-info-box">{{ rowInfo.data.work_started_at }}</div>
          </div>
        </template>
        <template #endedCellTemplate="{ data: rowInfo }">
          <div class="work-grid-row">
            <div class="row-info-box">{{ rowInfo.data.work_ended_at }}</div>
          </div>
        </template>
        <template #filterTemplate>
          <DxButton
            icon="filter"
            text="Filters"
            class="grid-toolbar-button"
            @click="showFiltersPopup"
          />
        </template>
        <template #addFitoTemplate>
          <DxButton
            icon="add"
            text="Add fito"
            class="grid-toolbar-button"
            @click="showAddFitoAndFertilityPopup('fito')"
          />
        </template>
        <template #addFertyTemplate>
          <DxButton
            icon="add"
            text="Add ferty"
            class="grid-toolbar-button"
            @click="showAddFitoAndFertilityPopup('ferty')"
          />
        </template>
        <template #refreshTemplate>
          <DxButton
            icon="refresh"
            text="Refresh"
            class="grid-toolbar-button"
            @click="refreshDataGrid"
          />
        </template>
      </DxDataGrid>
    </card>
  </div>
</template>

<script>
import { generateUUID } from "../../../helpers/common";
import * as api from "../../../store/api/endpoints";
import { mapActions, mapGetters } from "vuex";
import { getTestData } from "./testData.js";
import Filters from "./filters.vue";
import AddFitoAndFertility from "./addFitoAndFertility.vue";
import DxButton from "devextreme-vue/button";
import {
  DxDataGrid,
  DxColumn,
  DxGrouping,
  DxGroupPanel,
  DxPager,
  DxPaging,
  DxSearchPanel,
  DxHeaderFilter,
  DxFilterRow,
  DxToolbar,
  DxItem,
} from "devextreme-vue/data-grid";

export default {
  name: "Works",
  data() {
    return {
      dataSource: null,
      currentFilter: "auto",
      pageSizes: [10, 25, 50, 100],
      gridRefName: "gridWorks",
      showFilters: false,
      showAddFitoAndFertility: false,
      addFitoAndFertilityModelType: "fito",
      gridAttributes: {
        class: "agx-grid",
      },
    };
  },
  computed: {
    ...mapGetters({
      company: "auth/domain",
      works: "works-manager/works",
      seasonOnParcelHasCulture: "seasonOnParcelHasCulture/seasonOnParcelHasCulture"
    }),
  },
  components: {
    DxButton,
    DxDataGrid,
    DxColumn,
    DxGrouping,
    DxGroupPanel,
    DxPager,
    DxPaging,
    DxSearchPanel,
    DxHeaderFilter,
    DxFilterRow,
    DxToolbar,
    DxItem,
    Filters,
    AddFitoAndFertility,
  },
  methods: {
    ...mapActions({
      getWorks: "works-manager/getWorks",
      getSeasonOnParcelHasCulture: "seasonOnParcelHasCulture/getSeasonOnParcelHasCulture"
    }),
    onContentReady() {},
    refreshDataGrid() {
      this.$refs[this.gridRefName].instance.refresh();
    },
    showFiltersPopup() {
      this.showFilters = true;
    },
    hideFiltersPopup() {
      this.showFilters = false;
    },
    showAddFitoAndFertilityPopup(modelType) {
      this.addFitoAndFertilityModelType = modelType;
      this.showAddFitoAndFertility = true;
    },
    hideAddFitoAndFertilityPopup() {
      this.showAddFitoAndFertility = false;
    },
  },
  mounted() {
    this.getWorks({
      department_uuid: this.company.uuid,
    });
    // this.getSeasonOnParcelHasCulture({
    //   department_uuid: this.company.uuid,
    // });
    this.dataSource = getTestData();
  },
};
</script>

<style scoped>
.work-grid-row {
  width: 100%;
  background-color: white;
  display: flex;
  flex-wrap: wrap;
  overflow: auto;
}

.row-color-box {
  /* background-color: #64b5f6; */
  float: left;
  height: 41px;
  width: 12px;
  margin-right: 10px;
}
.row-info-box {
  float: left;
  margin: 10px;
  font-size: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

</style>
