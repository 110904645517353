<template>
  <div class="table-container position-relative">
    <div class="loader" v-if="loader">
      <loader :loading="is_loading" size="sm"></loader>
    </div>
    <p v-if="pager" class="font-weight-bold mt-3"
       :class="[{'counter': stickyHeader }]"
    >{{ $t('common.listing_results', {from: table_rows ? table_rows.length : 0, to: pager ? pager.total : 0}) }}</p>
    <div class="table-responsive" :id="tableId">
      <table class="table">
        <table-head :data="table_headers"
                    :class="thScrollClass"
                    @onSortBy="onSortBy"></table-head>
        <!-- always on header for structure -->
        <table-head :data="table_headers"
                    v-if="stickyHeader"
                    class="invisible"
                    @onSortBy="onSortBy"></table-head>
        <table-body :data="rows"
                    :headers="table_headers"
                    :permission="permission"
                    :infinite-scroll="infiniteScroll"
        ></table-body>
      </table>
    </div>
    <div class="row mt-3" v-show="rows && rows.length > 0 && paginationEnabled && !stickyHeader">
      <b-pagination
        v-model="currentPage"
        :total-rows="pager.total"
        :per-page="perPage"
        aria-controls="items"
        align="left"
        class="pl-3 pb-3"
        @change="onPageSelected"
      ></b-pagination>
    </div>
    <div class="scrollingLoad text-center" v-if="is_loading && scrollingLoad">
      <loader :loading="true" size="sm"></loader>
    </div>
  </div>
</template>

<script>
import TableHead from "@/components/AgroGrid/TableHead/TableHead";
import TableBody from "@/components/AgroGrid/TableBody/TableBody";
import {mapGetters} from "vuex";
import Loader from "@/components/Loader";

export default {
  props: {
      headers: {
          type: Array,
      },
      rows: {
          type: Array,
      },
      paginate: {
          type: Boolean,
          default: false,
      },
      permission: {
          type: Object,
          default: {},
      },
      loader: {
          type: Boolean,
          default: true,
      },
      loading: {
        type: Boolean,
        default: false,
      },
      infiniteScroll: {
        type: Boolean,
        default: false,
      },
      action: {
          type: Function | null,
      }
  },
  name: 'AgroGrid',
  components: {
    TableHead,
    TableBody,
    Loader,
  },
  data() {
    return {
      table_rows: this.rows ? this.rows : [],
      currentPage: 1,
      paginationEnabled: this.paginate ? this.paginate : false,
      pager: this.pagination ? this.pagination : {},
      perPage: this.limit ? this.limit : 25,
      is_loading: this.loading ? this.loading : false,
      windowPosition: {top: 0, bottom: 0},
      stickyHeader: false,
      scrollingLoad: false,
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
      pagination: 'common/pagination',
    }),
    table_headers: {
        get() {
        if (this.headers) {
          if( this.permission && (this.permission.edit || this.permission.delete) ) {
            this.headers.push({
              type: 'ActionControls'
            });
          }
          return this.headers;
        }

        return [];
      },

      set(value) {
        this.table_headers = value;
      }
    },
    thScrollClass() {
      if(this.stickyHeader) {
        return [
          'fixed-top',
          'bg-light',
          'mr80'
        ];
      }
    },
    tableId() {
      return 'table-'+Math.random().toString().replace('.', '');
    },
    tableScrollPosition() {
      let bounds = document.querySelector("#"+this.tableId).getBoundingClientRect();
      return {
        top: bounds.top,
        bottom: (bounds.bottom + bounds.height),
        height: bounds.height,
      };
    },
    isInfiniteScrollEnabled() {
      return this.infiniteScroll;
    }
  },
  methods: {
    onPageSelected(page_id) {
      this.is_loading = true;
      this.action(page_id);
    },
    onEdit(item) {
      this.$emit('onEdit', item);
    },
    onDelete(item) {
      this.$emit('onDelete', item);
    },
    onSortBy(data) {
      this.$emit('onSortBy', data);
    },
    onScroll(e) {
      this.windowPosition = {
          top: window.top.scrollY, /* or: e.target.documentElement.scrollTop */
          bottom: window.outerHeight,
      };
    },
    handleAutoLoad() {
      let currentPage = this.pager.current_page ?? 1;
      let totalPages = this.pager.last_page;
      let nextPage = this.pager.next_page;

      let t = this.windowPosition.top;
      let h = (this.tableScrollPosition.height / 1.50);

      if(currentPage < totalPages && (t > h) && !this.is_loading) {
        this.$set(this, 'scrollingLoad', true);
        this.onPageSelected(nextPage);
      }
    }
  },
  mounted() {
    window.addEventListener("scroll", this.onScroll, true);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.onScroll, true);
  },
  watch: {
    loading: function(newVal) {
        this.is_loading = newVal;
    },
    rows: {
      handler: function(newVal) {
        this.table_rows = newVal;

        if(!this.infiniteScroll) {
          let vm = this;
          setTimeout(function() {
            vm.is_loading = false;
          }, 1000);
        }
      },
      deep: true,
    },
    paginate: function(newVal) {
        this.paginationEnabled = newVal;
    },
    pagination: {
      handler: function(newVal) {
        this.pager = newVal;
      },
      deep: true,
    },
    action: function (newVal) {
        console.log('agro grid action?', newVal);
    },
    windowPosition: {
      handler: function (pos) {
        this.stickyHeader = (this.infiniteScroll && this.tableScrollPosition.top < pos.top);

        if(this.stickyHeader) { //and reach bottom of grid
          this.handleAutoLoad();
        }
      },
      deep: true,
    },
  }
}
</script>

<style scoped lang="scss">
.mr80 {
  margin-left: 50px;
  padding-left: 15px;
  padding-right: 50px;
}

.fixed-top {
  z-index: 0;
}

.td-text {
  width: 10%;
}

.scrollingLoad {

}

.invisible {
  visibility: hidden;
  th {
    height: 0px;
  }
}

.counter {
  position: fixed;
  top: 21px;
  z-index: 1;
  font-size: 10px;
  color: #0d6efd;
  left: 75px;
}
</style>
