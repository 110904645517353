<template>
    <div id="image-uploader" class="row mt-3">
        <div class="col-lg-12" v-if="!image">
            <div class="row">
                <div class="col-lg-12">
                    <img src="@/assets/images/upload_image.png" class="upl-img"/>
                    <input id="uploader_input" type="file" ref="file" accept="image/*" @change="onFileChange" class="d-none">
                </div>
            </div>
            <div class="row pt-3">
                <div class="col-lg-12">
                    <button class="btn btn-success" @click="showUploader">Choose image to upload</button>
                </div>
            </div>
        </div>
        <div class="col-lg-12" v-else>
            <div class="row">
                <div class="col-lg-10">
                    <img :src="image" class="thumb"/>
                    <p>
                      <button class="btn btn-sm btn-secondary" @click="removeImage">Remove image {{ info }}</button>
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import $ from 'jquery'

    export default {
        name: 'image-uploader',
        props: ['data', 'placeholder'],
        data() {
            return {
                image: '',
                info: '',
                imageObject: {}
            }
        },
        methods: {
            onFileChange(e) {
                var files = e.target.files || e.dataTransfer.files;
                if (!files.length)
                    return;
                this.createImage(files[0]);
            },
            createImage(file) {
                var reader = new FileReader();
                var vm = this;

                reader.onload = (e) => {
                    vm.image = e.target.result;

                    let img = new Image();
                    img.src = vm.image;

                    vm.imageObject.data = vm.image;

                    img.onload = function() {
                        vm.info = '('+this.width +'x' + this.height+')';

                        vm.imageObject.filename = file.name,
                        vm.imageObject.type = file.type,
                        vm.imageObject.dimensions = {
                            width: this.width,
                            height: this.height,
                        },
                        vm.new = true,

                        vm.$emit('onImageLoaded', vm.imageObject);
                        vm.$emit('onImageChanged', vm.imageObject);
                    };
                };

                reader.readAsDataURL(file);
            },
            removeImage: function (e) {
                this.image = '';
                this.$emit('onImageRemoved');
            },
            showUploader() {
                $('#uploader_input').click();
            }
        },
        mounted() {
            this.image = this.data && this.data.image ? this.data.image : '';
        } ,
        watch: {
            data: function(newVal) {
                if(newVal) {
                    this.image = newVal.image;

                    if(newVal.dimensions) {
                        this.info = '('+newVal.dimensions.width+'x'+newVal.dimensions.height+')';
                    }
                } else {
                    this.image = '';
                }
            }
        }
    }
</script>

<style scoped>
    .upl-img {
        width: 100px;
        height: 65px;
    }

  .thumb {
    max-width: 186px;
    padding-bottom: 10px;
  }
</style>
