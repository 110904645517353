export const sourceTypes = {
  EMAIL_IMPORT: {
    id: 'EMAIL_IMPORT',
  },
  API_IMPORT: {
    id: 'API_IMPORT',
  },
  MANUAL_IMPORT: {
    id: 'MANUAL_IMPORT',
  },
};

export const importSourceTypeList = Object.keys(sourceTypes).map((key) => sourceTypes[key]);