const state = () => {
    return {
      user_filters: {
        name: '',
        email: '',
        is_active: 1,
      },
      users: [],
      user_data: {},
    }
}

export default state;
