import axios from '@/plugins/axios'
import * as types from './mutation-types'
import * as endpoint from '@/store/api/endpoints'
import { errorMessage, successMessage } from '@/helpers/common'
import * as api from "@/store/api";
import * as endpoints from "@/store/api/endpoints";

export const state = () => {
  return {
    pagination: {},
  }
}

export const actions = {
  downloadExcel({commit}, payload) {
    return new Promise((resolve, reject) => {
      axios({
        url: payload.url,
        method: 'GET',
        responseType: 'blob',
        params: payload.params,
      }).then((response) => {
        if(response) {
          resolve(response.data);
          const url = window.URL.createObjectURL(new Blob([response.data], {
            type: 'application/vnd.ms-excel'
          }));
          const link = document.createElement('a');
          link.href = url;
          link.download = payload.filename+'.xlsx'
          link.target = '_blank'
          link.click();
        }
      }).catch((e) => {
        reject(e);
      });
    })
  },
  getCSVHeaders({commit}, payload) {
    return new Promise((resolve, reject) => {

      let formData = new FormData();

      if(payload) {
        Object.keys(payload).map((key) => {
          formData.append(key, payload[key]);
        });
      }

      axios.post( endpoint.CSV_HEADERS,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      ).then((response) => {
        resolve(response.data.data.data);
      }).catch((e) => {
        reject(e);
      });
    })
  },
  getCSVHeadersQuery({commit}, payload) {
    return api.fetch(endpoints.CSV_HEADERS_QUERY, payload, commit,(response) => {});
  },
  retryCSVImport({commit}, payload) {
    return api.post(endpoints.RETRY_CSV_IMPORT+'/'+payload.import_id, payload, commit,(response) => {});
  }
}

export const mutations = {
  [types.SET_PAGINATION]: (state, payload) => {
    if (payload) {
        state.pagination = payload;
    }
  },
  [types.SET_ERROR_MESSAGE]: (state, body) => {
       errorMessage(body);
    },
    [types.SET_SUCCESS_MESSAGE]: (state, body) => {
      successMessage(body);
    },
}

export const getters = {
  pagination: state => state.pagination,
}
