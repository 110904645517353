import * as api from "../../api/index";
import * as endpoints from "../../api/endpoints";
import * as types from "../../api/../mutation-types";

const actions = {
  getDxGridHiddenColumns({ commit }, payload) {
    return api.fetch(endpoints.DXGRID_HIDDEN_COLUMN, payload, commit, (response) => {
      commit(types.SET_DXGRID_HIDDEN_COLUMNS, response.data);
    });
  },
  setDxGridHiddenColumns({ commit }, payload) {
    return api.put(
      endpoints.DXGRID_HIDDEN_COLUMN,
      payload,
      commit,
      (response) => {
        commit(types.UPDATE_DXGRID_HIDDEN_COLUMNS, response.data);
      }
    );
  },
};

export default actions;
