import * as api from '../../api/index'
import * as endpoints from '../../api/endpoints'
import * as types from "../../mutation-types";

const actions = {
    getSCBonuses({commit}, payload) {
        return api.fetch(endpoints.SC_BONUS, payload, commit,(response) => {
          commit(types.SET_SC_BONUSES, response.data.data);
          commit("common/"+types.SET_PAGINATION, response.data.pagination, { root: true });
        });
    },
    getSCBonusTypes({commit}, payload) {
      return api.fetch(endpoints.SC_BONUS_TYPES, payload, commit,(response) => {
        commit(types.SET_SC_BONUS_TYPES, response.data);
      });
    },
    getSCBonusCalculationTypes({commit}, payload) {
      return api.fetch(endpoints.SC_BONUS_CALCULATION_TYPES, payload, commit,(response) => {
        commit(types.SET_SC_BONUS_CALCULATION_TYPES, response.data);
      });
    },
    getSCBonus({commit}, payload) {
      return api.fetch(endpoints.SC_BONUS+'/'+payload.uuid, payload, commit,(response) => {
        commit(types.SET_SC_BONUS, response.data);
      });
    },
    createSCBonus({commit}, payload) {
      return api.post(endpoints.SC_BONUS, payload, commit, (response) => {
        commit(types.SET_SC_BONUS, response.data);
      });
    },
    updateSCBonus({commit}, payload) {
      return api.put(endpoints.SC_BONUS+'/'+payload.uuid, payload, commit, (response) => {
        commit(types.UPDATE_SC_BONUS_ITEM, response.data);
      });
    },
    deleteSCBonus({commit}, payload) {
      return api.remove(endpoints.SC_BONUS+'/'+payload.uuid, payload, commit, (response) => {
        commit(types.DELETE_SC_BONUS_ITEM, response.data);
      });
    },
}

export default actions;
